import { IntegrationType } from '../integrations-client/integrations-client.type';
import { HistoricalSprintData, Sprint, SprintSummary } from '../sprints-client/sprints-client.type';

enum ProjectMetricsDetailsEndpointDescription {
  Velocity = 'velocity',
  Throughput = 'throughput',
  LeadTime = 'lead',
  ReactionTime = 'reaction',
  CycleTime = 'cycle',
}

enum SubprojectMethodology {
  Scrum = 'scrum',
  Kanban = 'kanban',
}

type Quarter = 1 | 2 | 3 | 4;

type BoxScoreDetails = {
  cost_per_task: number;
  cost_per_point: number;
  cost_per_day: number;
};

type ProjectsResponse = {
  id: string;
  health: number;
  name: string;
  pm?: string;
  pm_tool?: string;
  task_count?: number;
  contributor_count?: number;
  subprojects: Subproject[];
};

type Team = {
  id: string;
  name: string;
  subprojects: Board[];
};

type Board = {
  id: string;
  name: string;
  health?: number;
};

type TrailingAmount = {
  three: number | null;
  six: number | null;
  twelve: number | null;
};

type ProjectMetricsDetailsResultDataPoint = {
  [key: string]: {
    sprint_id: string;
    value: number;
  };
};

type ProjectMetricsDetailsResult = null | {
  title: string;
  description: string;
  trend: string;
  data_points: ProjectMetricsDetailsResultDataPoint;
  average: number;
  stdev: number;
  trailing_amounts?: TrailingAmount;
};

type HistoricalBurnsResult = {
  historical_burns_band: HistoricalBurnsResultDataPoint;
};

type HistoricalBurnsResultDataPoint = { x: number; y: number; y0: number }[] | [];

type ProjectSprintsResponse = {
  health: number;
  sprints: SprintSummary[];
};

type SubprojectMetricsResponse = {
  velocity?: HistoricalSprintData;
  throughput: HistoricalSprintData;
  lead_times: HistoricalSprintData;
  reaction_times: HistoricalSprintData;
  cycle_times: HistoricalSprintData;
  health: number;
};

type ProjectMetricsResponse = SubprojectMetricsResponse & {
  metrics?: SubprojectMetricsData[];
};

type SubProjectSprint = [string, string];

type SubProjectSprints = {
  [key: string]: SubProjectSprint[];
};

type ProjectBoardSprints = {
  subproject_sprints: SubProjectSprints;
};

type SubprojectViewData = {
  name: string;
  subproject_view_data: Project | Sprint;
};

type SubprojectResponse = {
  subproject_data?: SubprojectViewData[];
};

type SubprojectMetricsData = {
  subproject_id: string;
  subproject_name: string;
  details: SubprojectMetricsResponse;
};

enum ConfigurationState {
  NotConfigured = 'not_configured',
  InitiallyConfigured = 'initially_configured',
}

type Subproject = {
  id: string;
  name: string;
  configuration_state: ConfigurationState | null;
};

type Project = {
  id: string;
  name: string;
  health: number;
  integration_type: IntegrationType;
  current_sprint: Sprint;
  current_sprints: Sprint[];
  sprints: SprintSummary[];
  subprojects: Subproject[];
  pm?: string;
  sprint_length_in_days: number;
  backlog_sections: string[];
  in_progress_sprint_sections: string[];
  in_blocked_sprint_sections: string[];
  in_review_sprint_sections: string[];
  in_test_sprint_sections: string[];
  ready_for_deployment_sprint_sections: string[];
  active_sprint_sections: string[];
  sections_done: string[];
};

type ExternalProject = {
  expand: string;
  self: string;
  id: string;
  key: string;
  name: string;
  projectTypeKey: string;
  simplified: boolean;
  style: string;
  isPrivate: boolean;
  entityId?: string;
  uuid?: string;
};

type ExternalProjectLocation = {
  projectId: string;
  displayName: string;
  projectName: string;
  projectKey: string;
  projectTypeKey: string;
  avatarURI: string;
  name: string;
};

type ExternalBoard = {
  self: string;
  id: string;
  type: string;
  name: string;
  location: ExternalProjectLocation;
};

type FetchProjectFinancialsParams = {
  projectId: string;
  options: {
    fy?: string;
    fq?: Quarter;
    trailing?: number;
  } & ({ fy: string; fq?: Quarter } | { trailing: string });
};

export { ConfigurationState, ProjectMetricsDetailsEndpointDescription, SubprojectMethodology };
export type {
  Board,
  BoxScoreDetails,
  ExternalBoard,
  ExternalProject,
  FetchProjectFinancialsParams,
  HistoricalBurnsResult,
  HistoricalBurnsResultDataPoint,
  Project,
  ProjectBoardSprints,
  ProjectMetricsDetailsResult,
  ProjectMetricsResponse,
  ProjectSprintsResponse,
  ProjectsResponse,
  Quarter,
  Subproject,
  SubprojectMetricsData,
  SubprojectMetricsResponse,
  SubprojectResponse,
  SubProjectSprint,
  SubProjectSprints,
  Team,
};
