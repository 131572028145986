import { styled } from '@linaria/react';
import { Group, Tabs } from '@mantine/core';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useAdherenceStore } from '../../store/adherence-store/adherence-store';
import { AdherenceTab } from '../../store/adherence-store/adherence-store.type';
import { useGlobalStore } from '../../store/global-store/global-store';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { RiskAnalysis } from './risk-analysis/risk-analysis';
import { Targets } from './targets/targets';

export function Adherence() {
  useDocumentTitle('Adherence - Bloomfilter');
  const selectedTab = useAdherenceStore((state) => state.selectedTab);
  const setSelectedTab = useAdherenceStore((state) => state.setSelectedTab);
  const useAI = useGlobalStore((state) => state.useAI);

  const header = (
    <Group>
      <Icon name="emergency" color={secondaryBase} size={36} />
      <H2>Adherence</H2>
      <StyledTabs
        variant="pills"
        radius="xl"
        value={selectedTab}
        onChange={(value) => setSelectedTab(value as AdherenceTab)}
      >
        <Tabs.List>
          <Tabs.Tab
            value={AdherenceTab.TARGETS}
            leftSection={
              <Icon name="ads_click" size={16} color={selectedTab === AdherenceTab.TARGETS ? baseWhite : inkLight} />
            }
          >
            Targets
          </Tabs.Tab>
          {useAI && (
            <Tabs.Tab
              value={AdherenceTab.RISK_ANALYSIS}
              leftSection={
                <Icon
                  name="warning"
                  size={16}
                  color={selectedTab === AdherenceTab.RISK_ANALYSIS ? baseWhite : inkLight}
                />
              }
            >
              Risk Analysis
            </Tabs.Tab>
          )}
        </Tabs.List>
      </StyledTabs>
    </Group>
  );

  return (
    <MenuDrivenContainer header={header}>
      <>
        {selectedTab === AdherenceTab.RISK_ANALYSIS && useAI && <RiskAnalysis />}
        {selectedTab === AdherenceTab.TARGETS && <Targets />}
      </>
    </MenuDrivenContainer>
  );
}

const StyledTabs = styled(Tabs)`
  .mantine-Tabs-list {
    gap: 8px;
  }

  .mantine-Tabs-tab {
    transition: all 0.2s ease;
    font-weight: 500;

    &[data-active] {
      color: ${baseWhite};
      background: ${secondaryBase};
    }

    &:not([data-active]) {
      color: ${inkLight};
      border: 1px solid ${inkLight};

      &:hover {
        background: ${skyLightest};
        border: 1px solid ${secondaryBase};
      }
    }
  }
`;
