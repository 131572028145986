import { Initiative } from '../../api/initiative-client/initiative-client.type';

enum FilterCategory {
  Initiatives = 'initiatives',
  Epics = 'epics',
  Tasks = 'tasks',
}

enum InitiativeState {
  NoEpics = 'no_epics',
  NoEndDate = 'no_end_date',
}

enum Status {
  Empty = 'empty',
  OffTrack = 'off_track',
  AtRisk = 'at_risk',
  OnTrack = 'on_track',
  Unknown = 'unknown',
}

enum Metric {
  Tasks = 'tasks',
  Points = 'points',
}

type InitiativeRecord = Initiative & {
  contributing_teams_label: string;
};

export { FilterCategory, InitiativeState, Metric, Status };
export type { InitiativeRecord };
