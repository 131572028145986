import {
  FlowByPaceData,
  FlowByPaceDataOverTime,
  PaceStatus,
} from '../../../../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../../../../styles/colors';
import { Metric, PaceChartDataPoint } from '../../../work-periods.type';

/**
 * Filters the flow data based on the specified metric (tasks or points).
 *
 * @param flowData - The original flow data with both tasks and points metrics
 * @param viewBy - The metric to filter by (Tasks or Points)
 * @returns A filtered version of the flow data containing only the specified metric
 */
function filterPaceData(flowData: FlowByPaceDataOverTime | undefined, viewBy: Metric): FlowByPaceDataOverTime {
  if (!flowData) return {};

  return Object.fromEntries(
    Object.entries(flowData).map(([dateKey, sourceEntry]) => {
      // Filter keys based on prefix or exact match
      const filteredEntries = Object.entries(sourceEntry).filter(
        ([key]) => key.startsWith(viewBy.toLowerCase()) || ['date', 'weekend_or_holiday'].includes(key),
      );

      return [dateKey, Object.fromEntries(filteredEntries) as FlowByPaceData];
    }),
  ) as FlowByPaceDataOverTime;
}

/**
 * Transforms filtered flow data into the format required by Recharts.
 * Removes 'tasks_' or 'points_' prefixes from keys for chart display.
 *
 * @param filteredData - The filtered flow data
 * @returns An array of data points ready for use in a Recharts Line chart
 */
function transformForPaceChart(filteredData: FlowByPaceDataOverTime): PaceChartDataPoint[] {
  // Convert the filtered data to an array of objects for recharts
  const result: PaceChartDataPoint[] = [];

  // Convert the object of dates to an array of data points
  for (const date of Object.keys(filteredData)) {
    const dataPoint = { key: date, date } as PaceChartDataPoint;

    // Iterate through each key in the date's entry
    const sourceEntry = filteredData[date];
    const keys = Object.keys(sourceEntry) as Array<keyof FlowByPaceData>;

    for (const key of keys) {
      const keyStr = key.toString();
      // Remove 'tasks_' or 'points_' prefix from the key
      const cleanKey = keyStr.replace(/^(tasks_|points_)/, '');
      switch (cleanKey) {
        case 'actual':
          dataPoint.actual = Math.round(Number(sourceEntry[key]));
          break;
        case 'ideal':
          dataPoint.ideal = Math.round(Number(sourceEntry[key]));
          break;
        case 'remaining':
          dataPoint.remaining = Math.round(Number(sourceEntry[key]));
          break;
        case 'complete':
          dataPoint.complete = Math.round(Number(sourceEntry[key]));
          break;
        case 'total':
          dataPoint.total = Math.round(Number(sourceEntry[key]));
          break;
        case 'status':
          dataPoint.status = sourceEntry[key] as PaceStatus;
          break;
      }
    }
    dataPoint.statusColor = getStatusColor(dataPoint.status);

    result.push(dataPoint);
  }

  // Sort by date to ensure chronological order
  result.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return result;
}

/**
 * Get the color based on the pace status
 * @param status - The pace status
 * @returns The color code for the pace status
 */
function getStatusColor(status: PaceStatus): string {
  switch (status) {
    case PaceStatus.OnTrack:
      return newCOLORS.green;
    case PaceStatus.AtRisk:
      return newCOLORS.darkYellow;
    default:
      return newCOLORS.orangeRed;
  }
}

/**
 * Determine the pace status based on actual vs ideal values
 * @param actual - The actual value
 * @param ideal - The ideal value
 * @returns The pace status
 */
function determinePaceStatus(actual: number, ideal: number): PaceStatus {
  // Calculate the difference as a percentage of ideal
  const diff = actual - ideal;
  const percentDiff = ideal !== 0 ? (diff / ideal) * 100 : 0;

  if (percentDiff <= 10) {
    return PaceStatus.OnTrack; // Within 10% of ideal
  } else if (percentDiff <= 25) {
    return PaceStatus.AtRisk; // Between 10% and 25% off
  } else {
    return PaceStatus.OffTrack; // More than 25% off
  }
}

/**
 * Get the metadata for the tasks complete metric
 * @param chartData - The chart data
 * @param forDate - The date to get the metadata for
 * @param metric -  tasks or points
 * @returns The metadata for the tasks complete metric
 */
function getTasksCompleteMetadata(
  chartData: PaceChartDataPoint[],
  forDate: string | null,
  metric: Metric,
): {
  percent: number;
  helpText: string;
} {
  const dataPoint = chartData.find((point) => point.date === forDate);
  if (!dataPoint || !forDate) return { percent: 0, helpText: '' };
  return {
    percent: Math.round((dataPoint?.complete / dataPoint?.total) * 100),
    helpText: `${dataPoint?.complete} of ${dataPoint?.total} ${metric === Metric.Tasks ? 'tasks' : 'points'}`,
  };
}

/**
 * Get the metadata for the work period burned metric
 * @param chartData - The chart data
 * @param forDate - The date to get the metadata for
 * @returns The metadata for the work period burned metric
 */
function getWorkPeriodBurnedMetadata(
  chartData: PaceChartDataPoint[],
  forDate: string | null,
): {
  percent: number;
  helpText: string;
} {
  if (!forDate) return { percent: 0, helpText: '' };

  const dataPointIndex = chartData.findIndex((point) => point.date === forDate);
  if (dataPointIndex === -1) return { percent: 0, helpText: '' };

  const daysElapsed = dataPointIndex + 1;
  return {
    percent: Math.round((daysElapsed / chartData.length) * 100),
    helpText: `${daysElapsed} of ${chartData.length} days`,
  };
}

/**
 * Get the metadata for the scope metric
 * @param chartData - The chart data
 * @param forDate - The date to get the metadata for
 * @param metric - tasks or points
 * @returns The metadata for the scope metric
 */
function getScopeMetadata(
  chartData: PaceChartDataPoint[],
  forDate: string | null,
  metric: Metric,
): {
  percent: number;
  helpText: string;
} {
  if (!forDate) return { percent: 0, helpText: '' };

  const dataPointIndex = chartData.findIndex((point) => point.date === forDate);
  if (dataPointIndex === -1) return { percent: 0, helpText: '' };

  // get the earliest data point
  const earliestDataPoint = chartData[0];
  // calculate the total for the passed in date as a percentage of the total for the earliest date
  const total = chartData[dataPointIndex].total;
  const earliestTotal = earliestDataPoint.total;
  const percentValue = Math.round((total / earliestTotal) * 100);

  // Calculate absolute difference in tasks or points
  const difference = Math.abs(total - earliestTotal);

  // Tasks added (total > earliestTotal)
  if (total > earliestTotal) {
    return {
      percent: percentValue, // Still store the full percentage value
      helpText:
        Metric.Tasks === metric
          ? `${difference} ${difference === 1 ? 'task' : 'tasks'} added`
          : `${difference} ${difference === 1 ? 'point' : 'points'} added`,
    };
  }

  // Tasks removed (total < earliestTotal)
  if (total < earliestTotal) {
    return {
      percent: percentValue,
      helpText:
        Metric.Tasks === metric
          ? `${difference} ${difference === 1 ? 'task' : 'tasks'} removed`
          : `${difference} ${difference === 1 ? 'point' : 'points'} removed`,
    };
  }

  // No change in tasks
  return {
    percent: 100,
    helpText: 'no change in scope',
  };
}

export {
  determinePaceStatus,
  filterPaceData,
  getScopeMetadata,
  getStatusColor,
  getTasksCompleteMetadata,
  getWorkPeriodBurnedMetadata,
  transformForPaceChart,
};
