import { Flex } from '@mantine/core';
import { forwardRef, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useStrategyStore } from '../../../../../../store/strategy-store/strategy-store';
import { graphCambridgeBlue, secondaryBase } from '../../../../../../styles/design-tokens';
import { Text } from '../../../../../../ui-library/typography/typography';
import { getMetricLabel } from '../../../../strategy-v2.helpers';
import { Metric } from '../../../../strategy-v2.types';
import { LineChartDot } from './line-chart-dot';
import { LineChartLegend } from './line-chart-legend';
import { LineChartTooltip } from './line-chart-tooltip';
import { useChartData } from './line-chart.hooks';

type Props = {
  metric: Metric;
};

export const MainSectionLineChart = forwardRef<HTMLDivElement, Props>(({ metric }, ref) => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const chartData = useChartData(metric);

  if (!initiative) {
    return (
      <Flex justify="center" align="center" h="100%">
        <Text size="medium">No data available</Text>
      </Flex>
    );
  }

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{ top: 20 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#E0E0E0" />
          <XAxis
            dataKey="date"
            tick={{ fontSize: 12 }}
            axisLine={{ stroke: '#E0E0E0' }}
            tickLine={{ stroke: '#E0E0E0' }}
            padding={{ left: 40, right: 40 }}
          />
          <YAxis
            yAxisId="left"
            tick={{ fontSize: 12 }}
            axisLine={{ stroke: '#E0E0E0' }}
            tickLine={{ stroke: '#E0E0E0' }}
            label={{
              value: getMetricLabel(metric),
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' },
              offset: 10,
            }}
          />
          <RechartsTooltip content={<LineChartTooltip />} />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="total"
            name="Total Points"
            stroke={secondaryBase}
            strokeWidth={hoveredItem === null || hoveredItem === 'total' ? 1 : 0.3}
            opacity={hoveredItem === null || hoveredItem === 'total' ? 1 : 0.3}
            dot={<LineChartDot color={secondaryBase} />}
            activeDot={<LineChartDot color={secondaryBase} />}
            isAnimationActive={false}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="completed"
            name="Completed Points"
            stroke={graphCambridgeBlue}
            strokeWidth={hoveredItem === null || hoveredItem === 'completed' ? 1 : 0.3}
            opacity={hoveredItem === null || hoveredItem === 'completed' ? 1 : 0.3}
            dot={<LineChartDot color={graphCambridgeBlue} />}
            activeDot={<LineChartDot color={graphCambridgeBlue} />}
            isAnimationActive={false}
          />
          <Legend
            content={<LineChartLegend hoveredItem={hoveredItem} setHoveredItem={setHoveredItem} />}
            verticalAlign="bottom"
            height={50}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});

MainSectionLineChart.displayName = 'MainSectionLineChart';
