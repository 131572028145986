import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import i18n from '../../base-dictionary';
import { newCOLORS } from '../../styles/colors';
import { SectionContainer, Text } from './process.styled';

type Props = {
  completedTasks: number;
};

export function SectionSummary({ completedTasks }: Props) {
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <SectionContainer style={{ padding: '24px 16px' }}>
      <HeadingContainer>
        <CollapseIcon>
          <Icon
            icon={opened ? 'icon-park-solid:right-one' : 'icon-park-solid:down-one'}
            width={16}
            height={16}
            color="#5C3ECF"
            onClick={toggle}
          />
        </CollapseIcon>
        <Amount>{completedTasks}</Amount>
        <HeadingTitle>
          <Text>{i18n.t('process.map.considered_work_units', { work_units: i18n.t('common.work_units') })}</Text>
          <Text fontSize={12}>{i18n.t('process.map.scope_selected')}</Text>
        </HeadingTitle>
      </HeadingContainer>
      <Collapse in={!opened}>
        <ContentContainer>
          <Column>
            <ColumnTitle>{i18n.t('process.map.explanation.what_title')}</ColumnTitle>
            <ColumnText>{i18n.t('process.map.explanation.what_text')}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>{i18n.t('process.map.explanation.how_title')}</ColumnTitle>
            <ColumnText>{i18n.t('process.map.explanation.how_text')}</ColumnText>
            <ColumnText>{i18n.t('process.map.explanation.process_flow')}</ColumnText>
            <ColumnText>{i18n.t('process.map.explanation.status_note')}</ColumnText>
            <ColumnText>{i18n.t('process.map.explanation.future_note')}</ColumnText>
          </Column>
        </ContentContainer>
      </Collapse>
    </SectionContainer>
  );
}

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const Amount = styled(Text)`
  font-size: 50px;
  font-weight: 700;
`;

const HeadingTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 56px;
  padding: 16px 24px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const ColumnText = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 8px;
`;

const ColumnTitle = styled(ColumnText)`
  font-weight: bold;
`;
