import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { logger } from '../logger/logger';
import { State } from './integrations-store.types';

const useIntegrationsStore = create<State>()(
  devtools(
    persist(
      logger((_) => ({
        integration: null,
        integrations: [],
      })),
      {
        name: 'integrations-store',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !['integrations'].includes(key))),
      },
    ),
  ),
);

export { useIntegrationsStore };
