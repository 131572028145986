import { ComboboxItem } from '@mantine/core';
import { Portfolio } from './api/portfolio-client/portfolio-client.type';
import { Sprint, SprintBurnsAndFlows } from './api/sprints-client/sprints-client.type';

/**
 * Method to calculate the day count of the sprint
 * @param sprint Sprint object
 * @returns day count of the sprint
 */
const DayCount = (sprint: Sprint, sprintBurnsAndFlows: SprintBurnsAndFlows): number => {
  const date = new Date();
  const today = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
  );

  const dayCount =
    sprint.current_sprint && today < new Date(`${sprint.end_date}+00:00`)
      ? Math.floor((today.getTime() - new Date(`${sprint.start_date}+00:00`).getTime()) / (1000 * 3600 * 24)) + 1
      : sprintBurnsAndFlows?.burns_up?.data_points.length || 0;
  return dayCount;
};

/**
 * Method to refactor the portfolios data to be used in dropdown
 * @param portfolios Portfolios data
 * @returns refactored portfolios data
 */
const refactorDropdownData = (portfolios: Portfolio[]): ComboboxItem[] => {
  const refactoredPortfolios = portfolios.map((portfolio) => {
    return { value: portfolio.id, label: portfolio.name };
  });

  return refactoredPortfolios;
};

/**
 * Method to construct the new URL string based on selected Project
 * @param originalString Original URL string
 * @param replacementText replacement text to be inserted
 * @returns newly constructed URL string
 */
const replaceURL = (originalString: string, replacementText: string): string => {
  const parts = originalString.split('/');
  parts[3] = replacementText;
  const replacedString = parts.join('/');

  return replacedString;
};

/**
 * Method to get ISO 8601 date format
 * @param date Date object
 * @returns string in ISO 8601 format %Y-%m-%d or YYYY-MM-DD
 */
function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Method to format field names for Allocation of Cost FE
 * used to show uniformity in capitalization
 */
const formatAllocationOfCostTitle = (title: string): string => {
  if (title.length > 1) {
    return title.charAt(0).toUpperCase() + title.slice(1).replace(/_/g, ' ');
  } else {
    return title.toUpperCase();
  }
};

/**
 * Method to check if an email is valid or not.
 * @param email
 * @returns RegExpMatchArray | null
 */
const checkValidEmail = (email: string): RegExpMatchArray | null => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

/**
 * Method to get the domain part of an email
 * @param email
 * @returns string
 */
const getEmailDomain = (email: string): string => email.split('@')[1];

export {
  checkValidEmail,
  DayCount,
  formatAllocationOfCostTitle,
  formatDate,
  getEmailDomain,
  refactorDropdownData,
  replaceURL,
};
