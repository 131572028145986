import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { Team } from '../../../api/projects-client/projects-client.type';
import { usePortfolioProjects } from '../../../api/summary-client/summary-client.hooks';
import { StandardText } from '../../../styles/new-shared-styled-components/new-shared-styled-components';
import { ProcessContext } from '../context/process.context';
import { useProcessScope } from '../process.hooks';
import { commonSelectProps } from './scope.helpers';

export type TeamSelectRef = {
  getTeams: () => Team[];
};

export type TeamSelectProps = {
  handleTeamSelected?: (team: Team) => void;
  hideLabel?: boolean;
  includeAggregate?: boolean;
};

export const TeamSelect = forwardRef<TeamSelectRef, TeamSelectProps>(({ hideLabel, handleTeamSelected }, ref) => {
  const { portfolio, team, setScope } = useProcessScope();
  const { setStages } = useContext(ProcessContext);

  const { projects: teams = [] } = usePortfolioProjects(portfolio?.id, {
    enabled: Boolean(portfolio?.id),
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (!team && teams[0]) {
      setScope({
        team: teams[0],
      });
    }
  }, [team, teams, setScope]);

  useImperativeHandle(ref, () => ({
    getTeams: () => {
      return teams;
    },
  }));

  const teamSelected = (newTeam: Team) => {
    if (newTeam.id === team?.id) {
      return;
    }

    setScope({
      team: newTeam,
    });
    setStages([]);

    handleTeamSelected && handleTeamSelected(newTeam);
  };

  const refactorDropdownData = (teams: Team[]) => {
    const refactoredPortfolios = teams.map((tm) => {
      return { value: tm.id, label: tm.name };
    });
    return refactoredPortfolios;
  };

  return (
    <Fragment>
      {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Team</StandardText>}
      <Select
        data={refactorDropdownData(teams)}
        value={team?.id}
        onChange={(value) => {
          const selectedTeam = teams.find((tm) => tm.id === value);
          if (selectedTeam) {
            teamSelected(selectedTeam);
          }
        }}
        {...commonSelectProps}
      />
    </Fragment>
  );
});
