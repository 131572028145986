import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { DetailedRepository } from '../integrations/github/repositories.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/';

/**
 * Fetches a list of detailed repositories for a given organization.
 *
 * @param {string | null} organization_id - The ID of the organization to fetch repositories for.
 * @return {Promise<DetailedRepository[]>} - A promise that resolves to an array of detailed repository information.
 */

const getRepositories = async (organization_id: string | null): Promise<DetailedRepository[]> => {
  if (!organization_id) {
    return [];
  }
  return await axiosInstance
    .get(`${baseURL}repositories/`, { params: { organization_id } })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching repositories'));
};

/**
 * Maps projects to a repository.
 *
 * @param {string} repositoryId - The ID of the repository to map projects to.
 * @param {string[]} projectIds - Array of project IDs to map.
 * @param {string} organizationId - The ID of the organization to map projects to.
 * @return {Promise<void>} - A promise that resolves when the mapping is complete.
 */
const mapProjectsToRepositories = async (
  repositoryId: string,
  projectIds: string[],
  organizationId: string,
): Promise<void> => {
  return await axiosInstance
    .post(`${baseURL}repositories/${repositoryId}/mappings/?organization_id=${organizationId}`, {
      project_ids: projectIds,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while mapping projects to repositories'));
};

/**
 * Removes projects from a repository.
 *
 * @param {string} repositoryId - The ID of the repository to remove projects from.
 * @param {string[]} projectIds - Array of project IDs to remove.
 * @param {string} organizationId - The ID of the organization to remove projects from.
 * @return {Promise<void>} - A promise that resolves when the removal is complete.
 */
const removeProjectsFromRepositories = async (
  repositoryId: string,
  projectIds: string[],
  organizationId: string,
): Promise<void> => {
  return await axiosInstance
    .delete(`${baseURL}repositories/${repositoryId}/mappings/?organization_id=${organizationId}`, {
      params: { project_ids: projectIds.join(',') },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while removing projects from repositories'));
};

export { getRepositories, mapProjectsToRepositories, removeProjectsFromRepositories };
