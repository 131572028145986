import { Loader } from '@mantine/core';
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIntegrationsJDC } from '../../api/integrations-client/integrations-client';
import { useIntegrationUrl } from '../../api/integrations-client/integrations-client.hooks';
import { ExternalService } from '../../api/integrations-client/integrations-client.type';
import { useProjects } from '../../api/projects-client/projects-client.hooks';
import Checkmark from '../../assets/images/checkmark.png';
import { FileUploadPopup } from '../../components/file-upload-popup/file-upload-popup';
import { GlobalContext, UserContext } from '../../contexts';
import { trackEvent } from '../../helpers/analytics-event/analytics-event';
import { AnalyticsEventType } from '../../helpers/analytics-event/analytics-event.type';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { isValidAccessToken } from '../../helpers/storage/storage';
import { newCOLORS } from '../../styles/colors';
import { secondaryBase } from '../../styles/design-tokens';
import { StyledButton } from '../../styles/new-shared-styled-components/new-shared-styled-components';
import { ErrorSource, GlobalMessageCategory, GlobalMessageState } from '../global-message/global-message.type';
import { IntegrationsContext } from './context/integrations.context';
import { integrationViews } from './integrations.data';
import styles from './integrations.module.css';
import { IntegrationView } from './integrations.type';
import { UrlModal } from './jira/url-modal';

type Props = {
  setActiveStep: Dispatch<SetStateAction<number>>;
};

export function IntegrationCards({ setActiveStep }: Props) {
  const { user, query: userQuery } = useContext(UserContext);
  const { setInitJDC } = useContext(IntegrationsContext);
  const organizationId = useOrganizationId();

  const [showUploadPopUp, setShowUploadPopUp] = useState(false);
  const [atlassianModalOpen, setAtlassianModalOpen] = useState(false);
  const [activeIntegration, setActiveIntegration] = useState<ExternalService | null>(null);

  const { projects = [], query: projectsQuery } = useProjects({
    queryKey: ['projects'] as const,
    enabled: isValidAccessToken(),
  });

  const navigate = useNavigate();
  const { setGlobalMessage } = useContext(GlobalContext);

  const { url } = useIntegrationUrl({
    queryKey: ['integrationUrl', activeIntegration] as const,
    enabled: !!activeIntegration && activeIntegration !== ExternalService.JDC,
  });

  useEffect(() => {
    if (url) {
      const receivedUrl = new URL(url);
      const params = new URLSearchParams(receivedUrl.search);
      params.set('state', `${url.toUpperCase()},${organizationId || ''}`);
      const updatedQueryString = params.toString();

      window.location.href = `${receivedUrl.origin}${receivedUrl.pathname}?${updatedQueryString}`;
    }
  }, [url, organizationId]);

  const onClickJDC = async () => {
    if (!organizationId) {
      return;
    }

    const response = await getIntegrationsJDC(organizationId);

    if (response.length > 0) {
      setInitJDC(true);
      navigate('/application/integrations/jdc');
    } else {
      setGlobalMessage({
        type: GlobalMessageCategory.APP_ERROR,
        source: ErrorSource.JDC,
        state: GlobalMessageState.ERROR,
      });
    }
  };

  const isFetching = userQuery.isFetching || projectsQuery.isFetching;

  return (
    <main>
      <div id={styles.contentContainer}>
        <h3>Choose the platforms you would like to integrate with.</h3>
        <h4>You can always come back and add more!</h4>
        {showUploadPopUp && <FileUploadPopup closePopUp={() => setShowUploadPopUp(false)} show={showUploadPopUp} />}
        <div className={styles.integrationsCardGrid}>
          {integrationViews
            .filter((integration) => integration.enabled)
            .map((integration) => {
              if (integration.name === ExternalService.Jira) {
                return (
                  <IntegrationCard
                    key={integration.name}
                    integration={integration}
                    onClick={() => {
                      trackEvent(AnalyticsEventType.JiraIntegrationTapped, { userContext: user });
                      setActiveStep(0);
                      setAtlassianModalOpen(true);
                    }}
                    isComplete={
                      !isFetching && user ? user.subscription_status === 'unpaid' && !(projects.length === 0) : null
                    }
                  />
                );
              }
              return (
                <IntegrationCard
                  key={integration.name}
                  integration={integration}
                  onClick={() => {
                    setActiveIntegration(integration.name);

                    if (integration.name === ExternalService.JDC) {
                      onClickJDC();
                    } else {
                      setActiveStep(0);
                    }
                  }}
                />
              );
            })}
          <UrlModal isOpened={atlassianModalOpen} close={() => setAtlassianModalOpen(false)} />
        </div>
      </div>
    </main>
  );
}

function IntegrationCard({
  integration,
  onClick,
  link,
  isComplete,
}: {
  integration: IntegrationView;
  onClick: () => void;
  link?: ReactNode;
  isComplete?: boolean | null;
}) {
  const action = isComplete ? (
    <img className={styles.logo} height={35} width={35} src={Checkmark} alt={'Success'} />
  ) : (
    <StyledButton
      size={'medium'}
      type={'primary'}
      firstColor={newCOLORS.indigo}
      secondColor={newCOLORS.white}
      disable={false}
      style={{ margin: 8 }}
      onClick={onClick}
    >
      {integration.buttonText}
    </StyledButton>
  );

  return (
    <div id={styles.card}>
      <div className={styles.cardContentContainer}>
        <div className={styles.flexColumn}>
          {integration.icon}
          <h4 style={{ textAlign: 'center' }}>{integration.title}</h4>
        </div>
        <div className={styles.flexColumn}>
          {link ? link : null}
          {isComplete === null ? <Loader size={20} color={secondaryBase} /> : action}
        </div>
      </div>
    </div>
  );
}
