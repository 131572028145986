import { styled } from '@linaria/react';
import React, { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { centerUnderPointer } from '@atlaskit/pragmatic-drag-and-drop/element/center-under-pointer';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { ScrollArea, Skeleton, Stack } from '@mantine/core';
import { OrganizationStatusesByProjectsAndBoards } from '../../../../../api/organization-client/organization-client.type';
import { Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import i18n from '../../../../../base-dictionary';
import { baseWhite, primaryBase, secondaryBase, skyDark } from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import {
  filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds,
  getMappedAndUnmappedStatusesForWorkflow,
  maybeRemoveMappedStatusesFromWorkflowSteps,
} from '../workflows.helpers';
import { WorkflowAction } from './reducer';

export function Statuses({
  state,
  dispatch,
  statusesByProjectsAndBoards,
  isEditable,
}: {
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  isEditable: boolean;
}) {
  // instead of boolean, use a string to record the name of the status that is being dragged over
  // null means no status is being dragged over
  const [draggedOver, setDraggedOver] = useState<string | null>(null);
  const ref = useRef(null);

  const statusesForWorkflowSubprojects = filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds(
    statusesByProjectsAndBoards,
    state.subprojects,
  );
  const { unmappedStatuses } = getMappedAndUnmappedStatusesForWorkflow(state, statusesForWorkflowSubprojects);

  // biome-ignore lint/correctness/useExhaustiveDependencies(draggedOver): ignore
  useEffect(() => {
    const element = ref.current;
    invariant(element);

    return dropTargetForElements({
      element,
      onDragEnter: ({ source }) => {
        setDraggedOver(source.data.status as string);
      },
      onDragLeave: () => {
        setDraggedOver(null);
      },
      onDrop: ({ source }) => {
        // step 1: check if the status is already in the unmappedStatuses array
        if (unmappedStatuses.includes(source.data.status as string)) {
          setDraggedOver(null);
          return;
        }
        // step 2: if the status is not in the unmappedStatuses array, remove the status from the mappedStatuses in the workflow
        const newSteps = maybeRemoveMappedStatusesFromWorkflowSteps(state, [source.data.status as string]);
        // step 3: update the workflow
        dispatch({
          type: 'SET_WORKFLOW',
          payload: { ...state, steps: newSteps },
        });
        setDraggedOver(null);
      },
    });
  }, [unmappedStatuses, draggedOver, state, dispatch]);

  return (
    <Stack h="100%">
      <Text weight="bold">
        {i18n.t('data_management.workflows.active_statuses', {
          active: i18n.t('common.active'),
          statuses: i18n.t('common.status.statuses'),
        })}
      </Text>
      <ScrollArea mb="md" style={{ flex: 1 }} type="auto">
        <Stack
          ref={ref}
          gap="xs"
          p="md"
          style={{ border: draggedOver ? `2px solid ${primaryBase}` : 'none', borderRadius: 16 }}
        >
          {unmappedStatuses.length > 0 ? (
            unmappedStatuses.sort().reduce<React.ReactNode[]>((acc, status, index, sortedStatuses) => {
              // If we have a draggedOver status, find where it would be inserted
              if (draggedOver) {
                const shouldInsertBefore = draggedOver.localeCompare(status) < 0;
                if (shouldInsertBefore && !acc.some((node) => React.isValidElement(node) && node.type === Skeleton)) {
                  acc.push(<Skeleton key="drag-preview" height={32} radius={8} />);
                }
              }

              acc.push(<Status key={status} status={status} dispatch={dispatch} isDraggable={isEditable} />);

              // Add skeleton at the end if it's the last item and draggedOver is greater than all items
              if (draggedOver && index === sortedStatuses.length - 1 && draggedOver.localeCompare(status) > 0) {
                acc.push(<Skeleton key="drag-preview" height={32} radius={8} />);
              }

              return acc;
            }, [])
          ) : (
            <Text color={skyDark} size="small" style={{ textAlign: 'left', padding: '0' }}>
              {i18n.t('data_management.workflows.add_boards_to_view_statuses', {
                boards: i18n.t('common.boards'),
                statuses: i18n.t('common.status.statuses'),
              })}
            </Text>
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  );
}

export function Status({
  status,
  dispatch,
  backgroundColor,
  isDraggable,
}: {
  status: string;
  dispatch: (action: WorkflowAction) => void;
  backgroundColor?: string;
  isDraggable: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies(dispatch): ignore
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    return draggable({
      element,
      canDrag: () => isDraggable,
      getInitialData: () => ({ status, type: 'status' }),
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        setCustomNativeDragPreview({
          getOffset: centerUnderPointer,
          render: ({ container }) => {
            // Create a clone of the element with proper styling
            const preview = document.createElement('div');

            // Apply the same styles as your original component
            Object.assign(preview.style, {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '4px 8px',
              borderRadius: '8px', // Ensure rounded corners
              backgroundColor: backgroundColor || secondaryBase,
              color: baseWhite,
              minWidth: `${element.offsetWidth}px`,
            });

            // Add the content
            preview.textContent = status;

            // Add the preview to the container
            container.appendChild(preview);
          },
          nativeSetDragImage,
        });

        return true;
      },
      onDragStart: () => setDragging(isDraggable),
      onDrop: () => setDragging(false),
    });
  }, [status, dispatch, isDraggable, backgroundColor]);

  return (
    <FlexContainer
      ref={ref}
      backgroundColor={backgroundColor}
      isDraggable={isDraggable}
      style={{
        visibility: dragging ? 'hidden' : 'visible',
        opacity: dragging ? 0 : 1,
      }}
    >
      <Text color={baseWhite}>{status}</Text>
      <Icon name="drag_indicator" size={16} color={baseWhite} style={{ cursor: isDraggable ? 'grab' : 'default' }} />
    </FlexContainer>
  );
}

const FlexContainer = styled.div<{ backgroundColor?: string; isDraggable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor || secondaryBase};
  &:hover {
    cursor: ${({ isDraggable }) => (isDraggable ? 'grab' : 'default')};
  }
`;
