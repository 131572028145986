import { Measure } from '../../../api/work-periods-client/work-periods-client.type';

const phaseMetadataMap = {
  ready: 'backlog',
  blocked: 'blocked',
  wip: 'wip',
  'in review': 'review',
  'in test': 'test',
  'to deploy': 'deployable',
  done: 'done',
};

const MEASURES_WITH_DATE = [
  Measure.Readiness,
  Measure.Quality,
  Measure.Independence,
  Measure.Complexity,
  Measure.Strategy,
] as const;

export { MEASURES_WITH_DATE, phaseMetadataMap };
