import { Group, Stack, TextInput } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { EmptyFilterResult } from '../../../../components/filters/empty-filter-result';
import { baseWhite, inkLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Table } from '../../../data-management/components/table';
import { RiskAnalysisPullRequest } from '../risk-analysis.client.type';
import { areDataArraysDifferent, sortRiskAnalysisData } from '../risk-analysis.helpers';
import {
  getPullRequestTableColumns,
  handleDownloadPullRequestTableCSV,
} from './risk-analysis-pull-request-table.hooks';

const ITEMS_PER_PAGE = 20;

export function RiskAnalysisPullRequestTable({
  pullRequests,
  onPullRequestClick,
}: {
  pullRequests: RiskAnalysisPullRequest[];
  onPullRequestClick: (pullRequest: RiskAnalysisPullRequest) => void;
}) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [filteredPullRequests, setFilteredPullRequests] = useState(pullRequests);
  const [searchTerm, setSearchTerm] = useState('');
  const prevPullRequestsRef = useRef<RiskAnalysisPullRequest[]>(pullRequests);

  // Reset search and filtered pull requests only when pullRequests content actually changes
  useEffect(() => {
    const pullRequestsChanged = areDataArraysDifferent(prevPullRequestsRef.current, pullRequests);

    if (pullRequestsChanged) {
      setSearchTerm('');
      setFilteredPullRequests(pullRequests);
      setSelectedPage(1);

      // Update ref to current value
      prevPullRequestsRef.current = pullRequests;
    }
  }, [pullRequests]);

  const sortData = (
    data: RiskAnalysisPullRequest[],
    sortBy: keyof RiskAnalysisPullRequest,
    direction: 'asc' | 'desc',
  ): RiskAnalysisPullRequest[] => {
    return sortRiskAnalysisData(data, sortBy, direction);
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Button
          variant="secondary"
          radius="xl"
          size="sm"
          leftSection={<Icon name="format_list_bulleted" color={baseWhite} variant="filled" size={16} />}
        >
          Table View
        </Button>
        <Group>
          <TextInput
            placeholder="Search pull requests..."
            radius="xl"
            size="sm"
            value={searchTerm}
            leftSection={<Icon name="search" variant="filled" size={16} color={inkLight} />}
            style={{ width: 300 }}
            onChange={(event) => {
              const newSearchTerm = event.currentTarget.value;
              setSearchTerm(newSearchTerm);
              const filtered = pullRequests.filter(
                (pr) =>
                  pr.title.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
                  pr.body?.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
                  pr.repository.name.toLowerCase().includes(newSearchTerm.toLowerCase()),
              );
              setFilteredPullRequests(filtered);
              setSelectedPage(1);
            }}
          />
          <Button
            variant="secondary"
            radius="xl"
            size="sm"
            leftSection={<Icon name="download" color={baseWhite} variant="filled" size={16} />}
            onClick={() => handleDownloadPullRequestTableCSV(pullRequests)}
          >
            Export
          </Button>
        </Group>
      </Group>
      {filteredPullRequests.length > 0 ? (
        <Table<RiskAnalysisPullRequest>
          data={filteredPullRequests}
          columns={getPullRequestTableColumns()}
          getRowId={(pr: RiskAnalysisPullRequest) => pr.id}
          stickyHeader
          onRowClick={(pr: RiskAnalysisPullRequest) => onPullRequestClick(pr)}
          pageSize={ITEMS_PER_PAGE}
          currentPage={selectedPage}
          totalItems={filteredPullRequests.length}
          onPageChange={(page: number) => {
            setSelectedPage(page);
          }}
          customSort={sortData}
        />
      ) : (
        <EmptyFilterResult objectName="pull requests" />
      )}
    </Stack>
  );
}
