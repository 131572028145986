import { styled } from '@linaria/react';
import { Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { newCOLORS } from '../../../../styles/colors';
import { useTaskSelection } from '../../tasks.context';
import { useWorkPeriodsContext } from '../../work-periods.context';
import { Metric, WorkPeriod, WorkPeriodType } from '../../work-periods.type';
import { calculateCategoryPercentages, filterPhaseData, transformForPhaseChart } from './helpers/phase-section.helpers';

type PhaseSectionProps = { selectedWorkPeriod: WorkPeriod | null };

export function PhaseSection({ selectedWorkPeriod }: PhaseSectionProps) {
  const { selectedWorkPeriods, dateRange, selectedWorkPeriodType, flowData } = useWorkPeriodsContext();
  const { selectMeasureByDateRange, selectMeasureByWorkPeriod } = useTaskSelection();
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);

  // Add state for the currently hovered date
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);

  // Determine if we have a selected work period or date range
  const hasSelection =
    (selectedWorkPeriodType === WorkPeriodType.Defined &&
      Array.isArray(selectedWorkPeriods) &&
      selectedWorkPeriods.length > 0) ||
    (selectedWorkPeriodType === WorkPeriodType.Custom && dateRange?.startDate && dateRange?.endDate);

  // Define the phases
  const phases = [
    { id: 'backlog', name: 'Backlog', color: newCOLORS.indigo, description: 'Not started' },
    { id: 'blocked', name: 'Blocked', color: newCOLORS.coral, description: 'Held up' },
    { id: 'wip', name: 'In Progress', color: newCOLORS.darkYellow, description: 'Started' },
    { id: 'review', name: 'In Review', color: newCOLORS.lightYellow, description: 'Code review' },
    { id: 'test', name: 'In Test', color: newCOLORS.pink, description: 'Acceptance' },
    { id: 'deployable', name: 'Ready to Deploy', color: newCOLORS.aqua, description: 'Staged' },
    { id: 'done', name: 'Done', color: newCOLORS.green, description: 'Work finished' },
  ];

  const handlePhaseClick = (forDate: string, measureFilter: string, selectedWorkPeriodId?: string) => {
    const startDate = dayjs(forDate).startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs(forDate).endOf('day').format('YYYY-MM-DD');
    if (selectedWorkPeriodId) {
      // If we have a work period, use it
      selectMeasureByWorkPeriod('flow_by_phase', selectedWorkPeriodId, startDate, endDate, measureFilter);
    } else {
      // Otherwise use the end date
      selectMeasureByDateRange('flow_by_phase', startDate, endDate, measureFilter);
    }
  };

  const filteredData = useMemo(
    () => filterPhaseData(flowData?.flow_by_phase, metric),
    [flowData?.flow_by_phase, metric],
  );

  const chartData = useMemo(() => transformForPhaseChart(filteredData), [filteredData]);

  const dates = Object.keys(flowData?.flow_by_phase || {});
  const latestDateFromChartData =
    dates.length > 0 ? dates.sort((a, b) => dayjs(a).unix() - dayjs(b).unix()).pop() : null;

  // Initialize hoveredDate to latestDate when component mounts or latestDate changes
  useEffect(() => {
    if (latestDateFromChartData) {
      // Normalize with dayjs to ensure consistent format
      setHoveredDate(dayjs(latestDateFromChartData).format('YYYY-MM-DD'));
    }
  }, [latestDateFromChartData]);

  // Use hoveredDate if available, otherwise fall back to latestDateFromChartData
  const currentDateToDisplay =
    hoveredDate || (latestDateFromChartData ? dayjs(latestDateFromChartData).format('YYYY-MM-DD') : null);

  const phasePercentages = currentDateToDisplay ? calculateCategoryPercentages(chartData, currentDateToDisplay) : null;

  // Hover handler for the chart
  const handleChartMouseMove = (data: any) => {
    if (data && data.activeLabel) {
      const normalizedDate = dayjs(data.activeLabel).format('YYYY-MM-DD');
      setHoveredDate(normalizedDate);
    }
  };

  return hasSelection ? (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Work Phase Distribution
        </Text>
        <Group>
          <TabsGroup>
            <TabButton active={metric === Metric.Tasks} onClick={() => setMetric(Metric.Tasks)}>
              Tasks
            </TabButton>
            <TabButton active={metric === Metric.Points} onClick={() => setMetric(Metric.Points)}>
              Points
            </TabButton>
          </TabsGroup>
          <ExportButton>Export</ExportButton>
        </Group>
      </SectionHeader>

      <DayDetailsBox>
        <Text fw={500} size="lg" mb="md">
          Phase Distribution
          {currentDateToDisplay && <DateDisplay>{dayjs(currentDateToDisplay).format('MMM D, YYYY')}</DateDisplay>}
        </Text>
        <PhaseCardsContainer>
          {phases.map((phase) => {
            // Always make the card clickable
            const isClickable = true;

            return (
              <PhaseCard
                key={phase.id}
                $color={phase.color}
                $clickable={isClickable}
                onClick={() => hoveredDate && handlePhaseClick(hoveredDate, phase.id, selectedWorkPeriod?.id)}
              >
                <PhaseTitle>
                  {phase.name}
                  <ClickableIndicator>🔍</ClickableIndicator>
                </PhaseTitle>
                <PhaseDescription>{phase.description}</PhaseDescription>
                <PhaseValue>
                  {phasePercentages && typeof phasePercentages === 'object'
                    ? (Object.entries(phasePercentages).find(
                        ([key]) => key === phase.id.replace(/^(tasks_|points_)/, ''),
                      )?.[1] ?? '--') + '%'
                    : '--'}
                </PhaseValue>
                <PhaseLegend $color={phase.color} />
              </PhaseCard>
            );
          })}
        </PhaseCardsContainer>
      </DayDetailsBox>

      <ChartBox>
        <Text fw={500} size="lg" mb="md">
          Cumulative Flow
        </Text>
        <ChartContainer>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={chartData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                onMouseMove={handleChartMouseMove}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={(value) => dayjs(value).format('MMM D')} tick={{ fontSize: 12 }} />
                <YAxis
                  tick={{ fontSize: 12 }}
                  label={{
                    value: metric === Metric.Tasks ? 'Tasks' : 'Points',
                    angle: -90,
                    position: 'insideLeft',
                    style: { textAnchor: 'middle' },
                  }}
                />
                <Tooltip
                  formatter={(value: any, name: any) => [value, phases.find((p) => p.id === name)?.name || name]}
                  labelFormatter={(label: any) => `Date: ${dayjs(label).format('MMM D, YYYY')}`}
                  isAnimationActive={false} // Disable animation for smoother experience
                />
                <Legend />
                {phases
                  .slice()
                  .reverse()
                  .map((phase) => (
                    <Area
                      key={phase.id}
                      type="monotone"
                      dataKey={phase.id}
                      stackId="1"
                      stroke={phase.color}
                      fill={phase.color}
                      name={phase.name}
                    />
                  ))}
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <EmptyChartMessage>
              <Text>No flow data available for the selected work period.</Text>
              <Text size="sm" color="dimmed" mt="xs">
                This could be due to insufficient data in the selected time period.
              </Text>
            </EmptyChartMessage>
          )}
        </ChartContainer>
      </ChartBox>
    </SectionContainer>
  ) : (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Work Phase Distribution
        </Text>
      </SectionHeader>
      <Text>Select a work period or date range to view phase distribution data.</Text>
    </SectionContainer>
  );
}

// Styled components
const SectionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabsGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TabButton = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => (props.active ? '#4C6EF5' : '#f0f0f0')};
  color: ${(props) => (props.active ? 'white' : '#333')};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#4C6EF5' : '#e0e0e0')};
  }
`;

const DayDetailsBox = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
`;

const PhaseCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const PhaseCard = styled.div<{ $color: string; $clickable: boolean }>`
  width: 120px;
  height: 120px;
  border: 2px solid ${(props) => (props.$clickable ? props.$color : newCOLORS.lightGray)};
  border-radius: 8px;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  transition: all 0.2s ease-in-out;
  
  &:hover {
    border-color: ${(props) => (props.$clickable ? props.$color : newCOLORS.lightGray)};
    box-shadow: ${(props) => (props.$clickable ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none')};
    transform: ${(props) => (props.$clickable ? 'translateY(-2px)' : 'none')};
  }
`;

const PhaseTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
  color: ${newCOLORS.indigo};
  text-decoration: underline;
  cursor: pointer;
  z-index: 10;
  position: relative;
`;

const PhaseDescription = styled.div`
  font-size: 12px;
  color: ${newCOLORS.gray};
  text-align: center;
  margin-bottom: 8px;
`;

const PhaseValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

const PhaseLegend = styled.div<{ $color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  position: absolute;
  bottom: 8px;
`;

const ChartBox = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
`;

const ChartContainer = styled.div`
  height: 300px;
`;

const ExportButton = styled.button`
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 12px 10px 12px;
  width: 70px;
  border-radius: 4px;
  border: none;
  background-color: #4C6EF5;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 0;

  &:hover {
    background-color: #3b5bdb;
  }
`;

const ClickableIndicator = styled.span`
  font-size: 12px;
  margin-left: 4px;
  vertical-align: middle;
`;

const EmptyChartMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: ${newCOLORS.lightGray};
  border-radius: 4px;
  padding: 16px;
  text-align: center;
`;

const DateDisplay = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${newCOLORS.gray};
  margin-left: 8px;
`;
