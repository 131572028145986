import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { ProcessAnalysisInitiativeChartData } from '../../api/portfolio-client/portfolio-client.type';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { MeasureSelection } from '../../store/process-analysis-store/process-analysis-store.type';
import { TransformedTarget } from '../adherence/targets/targets-client.type';
import { getDefaultInitiativeSectionSelection } from './initiative-sections/initiative-sections.helpers';
import { InitiativeSectionSelectionAggregated } from './initiative-sections/initiative-sections.type';
import { getDefaultKeyMeasuresSelection } from './key-measures/key-measures-over-time.helpers';
import { KeyMeasuresSelectionAggregated } from './key-measures/key-measures-over-time.type';
import { SelectedTarget } from './measure-comparison/measure-comparison.type';
import {
  InitiativeSectionSelectionContext,
  KeyMeasuresSelectionContext,
  MeasureComparisonSelectionContext,
  MetricType,
  ProcessAnalysisContextType,
  Section,
  Tab,
} from './process-analysis.type';

const ProcessAnalysisContext = createContext({} as ProcessAnalysisContextType);

const ProcessAnalysisProvider = ({ children }: { children: ReactNode }) => {
  const measureComparisonSelection = useMeasureComparisonSelection();
  const initiativeCompletionSelection = useInitiativeSectionSelection();
  const initiativeFocusSelection = useInitiativeSectionSelection();
  const keyMeasuresSelection = useKeyMeasuresSelection();

  return (
    <ProcessAnalysisContext.Provider
      value={{
        [Section.MeasureComparison]: measureComparisonSelection,
        [Section.InitiativeCompletion]: initiativeCompletionSelection,
        [Section.InitiativeFocus]: initiativeFocusSelection,
        [Section.KeyMeasures]: keyMeasuresSelection,
      }}
    >
      {children}
    </ProcessAnalysisContext.Provider>
  );
};

const useMeasureComparisonSelection = (): MeasureComparisonSelectionContext => {
  const { activeTab, measureSelections, setMeasureSelections } = useProcessAnalysisStore((state) => ({
    activeTab: state.activeTab,
    measureSelections: state.measureSelections || {},
    setMeasureSelections: state.setMeasureSelections,
  }));

  const currentSelections = useMemo(() => {
    return measureSelections[activeTab] || createEmptySelections();
  }, [activeTab, measureSelections]);

  const updateSelections = useCallback(
    (updates: Partial<MeasureSelection>) => {
      const currentTabSelections = measureSelections[activeTab] || createEmptySelections();

      const newMeasureSelections = {
        measures: updates.measures !== undefined ? updates.measures : currentTabSelections.measures,
        selectedMeasures:
          updates.selectedMeasures !== undefined ? updates.selectedMeasures : currentTabSelections.selectedMeasures,
        selectedTrends:
          updates.selectedTrends !== undefined ? updates.selectedTrends : currentTabSelections.selectedTrends,
        selectedTargets:
          updates.selectedTargets !== undefined ? updates.selectedTargets : currentTabSelections.selectedTargets,
      };

      setMeasureSelections(activeTab, newMeasureSelections);
    },
    [activeTab, measureSelections, setMeasureSelections],
  );

  const removeSelection = useCallback(
    (measure: Measure) => {
      const currentTabSelections = measureSelections[activeTab] || createEmptySelections();

      const newMeasureSelections = {
        measures: currentTabSelections.measures.filter((val) => val !== measure),
        selectedMeasures: currentTabSelections.selectedMeasures.filter((val) => val !== measure),
        selectedTrends: currentTabSelections.selectedTrends.filter((val) => val !== measure),
        selectedTargets: currentTabSelections?.selectedTargets?.filter((target) => target.measureId !== measure),
      };

      setMeasureSelections(activeTab, newMeasureSelections);
    },
    [activeTab, measureSelections, setMeasureSelections],
  );

  const setMeasures = useCallback(
    (measures: Measure[]) => {
      updateSelections({ measures });
    },
    [updateSelections],
  );

  const setSelectedMeasures = useCallback(
    (selectedMeasures: Measure[]) => {
      const newTargetSelections = currentSelections?.selectedTargets?.filter((target) =>
        selectedMeasures.includes(target.measureId as Measure),
      );
      updateSelections({ selectedMeasures, selectedTargets: newTargetSelections });
    },
    [updateSelections, currentSelections.selectedTargets],
  );

  const setSelectedTrends = useCallback(
    (selectedTrends: Measure[]) => {
      updateSelections({ selectedTrends });
    },
    [updateSelections],
  );

  const setSelectedTargets = useCallback(
    (selectedTargets: SelectedTarget[]) => {
      updateSelections({ selectedTargets });
    },
    [updateSelections],
  );

  return {
    measures: currentSelections.measures,
    selectedMeasures: currentSelections.selectedMeasures,
    selectedTrends: currentSelections.selectedTrends,
    selectedTargets: currentSelections.selectedTargets || [],
    setMeasures,
    setSelectedMeasures,
    setSelectedTrends,
    setSelectedTargets,
    updateSelections,
    removeSelection,
  };
};

const useInitiativeSectionSelection = (): InitiativeSectionSelectionContext => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab as keyof InitiativeSectionSelectionAggregated);
  const [selection, setSelection] = useState<InitiativeSectionSelectionAggregated>(
    getDefaultInitiativeSectionSelection(),
  );

  const selectionTabs = [Tab.Portfolios, Tab.Teams];
  const activeTabSelection = selectionTabs.includes(activeTab) ? selection[activeTab] : null;

  const setMetric = (metric: MetricType) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], metric } }));
  };

  const setInitiatives = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], initiatives } }));
  };

  const setSelectedInitiatives = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedInitiatives: initiatives },
    }));
  };

  const setSelectedTrends = (initiatives: { id: string; name: string }[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedTrends: initiatives },
    }));
  };

  const setInitiativesData = (data: ProcessAnalysisInitiativeChartData | null) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], initiativesData: data } }));
  };

  return {
    metric: activeTabSelection?.metric || MetricType.Tasks,
    initiatives: activeTabSelection?.initiatives || [],
    selectedInitiatives: activeTabSelection?.selectedInitiatives || [],
    selectedTrends: activeTabSelection?.selectedTrends || [],
    initiativesData: activeTabSelection?.initiativesData || null,
    setMetric,
    setInitiatives,
    setSelectedInitiatives,
    setSelectedTrends,
    setInitiativesData,
  };
};

const useKeyMeasuresSelection = (): KeyMeasuresSelectionContext => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab as keyof KeyMeasuresSelectionAggregated);
  const [selection, setSelection] = useState<KeyMeasuresSelectionAggregated>(getDefaultKeyMeasuresSelection());

  const selectionTabs = [Tab.Portfolios, Tab.Teams, Tab.Boards];
  const activeTabSelection = selectionTabs.includes(activeTab) ? selection[activeTab] : null;

  const setMeasure = (measure: Measure) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], measure } }));
  };

  const setSelectedEntities = (entities: string[]) => {
    setSelection((prevState) => ({
      ...prevState,
      [activeTab]: { ...prevState[activeTab], selectedEntities: entities },
    }));
  };

  const setSelectedTrends = (trends: string[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], selectedTrends: trends } }));
  };

  const setSelectedTargets = (targets: TransformedTarget[]) => {
    setSelection((prevState) => ({ ...prevState, [activeTab]: { ...prevState[activeTab], selectedTargets: targets } }));
  };

  return {
    measure: activeTabSelection?.measure || Measure.Complexity,
    selectedEntities: activeTabSelection?.selectedEntities || [],
    selectedTrends: activeTabSelection?.selectedTrends || [],
    selectedTargets: activeTabSelection?.selectedTargets || [],
    setMeasure,
    setSelectedEntities,
    setSelectedTrends,
    setSelectedTargets,
  };
};

const createEmptySelections = (): MeasureSelection => ({
  measures: [
    Measure.Velocity as Measure,
    Measure.Throughput as Measure,
    Measure.CycleTime as Measure,
    Measure.ReactionTime as Measure,
    Measure.LeadTime as Measure,
  ],
  selectedMeasures: [
    Measure.Velocity as Measure,
    Measure.Throughput as Measure,
    Measure.CycleTime as Measure,
    Measure.ReactionTime as Measure,
    Measure.LeadTime as Measure,
  ],
  selectedTrends: [],
  selectedTargets: [],
});

export { ProcessAnalysisContext, ProcessAnalysisProvider, useMeasureComparisonSelection };
