import { useMemo } from 'react';
import { useStrategyStore } from '../../../../../../store/strategy-store/strategy-store';
import { formatDateLabel } from '../../../../strategy-v2.helpers';
import { Metric } from '../../../../strategy-v2.types';
import { LineChartDataItem } from './line-chart.types';

/**
 * Hook to generate chart data for the initiative performance line chart
 *
 * @param {Metric} metric - The metric to display data for (e.g., 'story_points', 'count')
 * @returns {LineChartDataItem[]} An array of data points containing date, completed, and total values
 */
const useChartData = (metric: Metric): LineChartDataItem[] => {
  const initiative = useStrategyStore((state) => state.initiativePerformance);

  const {
    completed_subtasks_over_time: completedDataset = { [metric]: {} },
    total_subtasks_over_time: totalDataset = { [metric]: {} },
  } = initiative || {};

  const chartData = useMemo(() => {
    if (!initiative) {
      return [];
    }

    const dates = Object.keys(completedDataset[metric] || {});

    return dates.map((date) => {
      return {
        date: formatDateLabel(date),
        completed: ((completedDataset[metric] || {}) as Record<string, number | null>)[date] || 0,
        total: ((totalDataset[metric] || {}) as Record<string, number | null>)[date] || 0,
      };
    });
  }, [completedDataset, totalDataset, metric, initiative]);

  return chartData;
};

export { useChartData };
