import {
  BurnsDataOverTime,
  FlowByPaceDataOverTime,
  FlowByPhaseDataOverTime,
  HistoricalBurnsDataOverTime,
  PaceStatus,
} from '../../api/work-periods-client/work-periods-client.type';

enum Metric {
  Tasks = 'tasks',
  Points = 'points',
}

enum WorkPeriodType {
  Defined = 'defined',
  Custom = 'custom',
}

enum WorkPeriodTense {
  Future = 'future',
  Past = 'past',
  Present = 'present',
}

type WorkPeriod = {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  type: WorkPeriodType;
  parent?: string | null;
  subproject?: string;
  external_id?: string;
  time_tense?: WorkPeriodTense;
};

type MeasureMetadata = {
  measure_name: string;
  measure_title: string;
  measure_group: string;
  measure_description: string;
  measure_units: string;
  is_zero_valid: boolean;
  is_custom?: boolean;
};

type MeasureData = {
  value: number;
  change: number;
  target: number | null;
};

type KeyProcessMeasuresData = {
  task_completion: MeasureData;
  strategy: MeasureData;
  complexity: MeasureData;
  quality: MeasureData;
  scope_creep: MeasureData;
  readiness: MeasureData;
  independence: MeasureData;
  review_time: MeasureData;
  declined_change_requests: MeasureData;
};

type ReadinessData = {
  readiness: Record<string, number>;
};

type FlowByPhase = {
  ready: number;
  blocked: number;
  wip: number;
  in_review: number;
  in_test: number;
  to_deploy: number;
  done: number;
};

type CumulativeFlowPhase = {
  id: string;
  name: string;
  color: string;
};

type PhaseChartDataPoint = {
  date: string;
  [phaseId: string]: string | number;
};

type PaceChartDataPoint = {
  key: string;
  date: string;
  actual: number;
  ideal: number;
  remaining: number;
  complete: number;
  total: number;
  status: PaceStatus;
  statusColor: string;
};

type DeliveryDataPoint = {
  date: string;
  key: string;
  completed: number;
  scope: number;
  scopeChange: number[];
};

type HistoricalBurnsDataPoint = {
  date: string;
  key: string;
  average: number;
  band: number[];
};

type DeliveryConfidenceChartDataPoint = {
  date: string;
  key: string;
  completed: number;
  scope: number;
  scopeChange: number[];
  // we don't always have the blue band data
  average?: number;
  band?: number[];
};

type FlowByPace = {
  dates: string[];
  values: number[];
};

type Burns = {
  dates: string[];
  values: number[];
};

type FlowData = {
  flow_by_phase: FlowByPhaseDataOverTime;
  flow_by_pace: FlowByPaceDataOverTime;
  burns: BurnsDataOverTime;
  historical_burns: HistoricalBurnsDataOverTime;
};

type HealthScoreData = {
  health_scores: {
    score_by_points: string;
  };
};

type TaskRecord = {
  id: string;
  name: string;
  title: string;
  description: string;
  points: number;
  type: string;
  url: string;
  created_date: string;
  transitions?: {
    status: string;
    changed_by: string;
    changed_at: string;
  }[];
  epic?: {
    id: string;
    name: string;
    title: string;
    url: string;
  } | null;
};

/**
 * Date range for ad-hoc work periods
 */
export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

type WorkPeriodsState = {
  selectedWorkPeriodType: WorkPeriodType;
  selectedWorkPeriods: WorkPeriod[];
  dateRange: DateRange;
  isLoading: boolean;
  scoreData: HealthScoreData | undefined;
  keyMeasuresData: KeyProcessMeasuresData | undefined;
  flowData: FlowData | undefined;
  customMeasures?: MeasureMetadata[];
  measureData?: Record<string, any>;
  isFetching?: boolean;
  selectedBoardId: string | null;
  teamId: string | null;
  formattedBoards: { value: string; label: string }[];
  tasksByPhase?: Record<string, TaskRecord[]>;
  tasksByMeasure?: Record<string, TaskRecord[]>;
  tasksByDate?: Record<string, TaskRecord[]>;
  workPeriods: WorkPeriod[];
};

type WorkPeriodsContextType = WorkPeriodsState & {
  /**
   * Set the team ID
   * @param teamId The team ID
   */
  setTeamId: (teamId: string | null) => void;

  /**
   * Set the selected board ID
   * @param boardId The board ID
   */
  setSelectedBoardId: (boardId: string | null) => void;

  /**
   * Set the selected work period type (Defined or Custom)
   * @param type The work period type
   */
  setSelectedWorkPeriodType: (type: WorkPeriodType) => void;

  /**
   * Set the selected work periods
   * @param workPeriods The work periods to select
   */
  setSelectedWorkPeriods: (workPeriods: WorkPeriod[]) => void;

  /**
   * Set the date range for ad-hoc work periods
   * @param dateRange The date range
   */
  setDateRange: (dateRange: DateRange) => void;

  /**
   * Fetch work periods for a board
   * @param boardId The board ID
   */
  fetchWorkPeriods: (boardId?: string | null) => Promise<void>;

  /**
   * Fetch data for the selected work periods
   */
  fetchWorkPeriodData: () => Promise<void>;

  /**
   * Handle board selection
   * @param boardId The board ID
   */
  handleBoardSelect: (boardId: string | null) => void;

  /**
   * Handle work period type selection
   * @param type The work period type
   */
  handleWorkPeriodTypeSelect: (type: WorkPeriodType) => void;

  /**
   * Handle date range selection for ad-hoc work periods
   * @param range The date range
   */
  handleDateRangeChange: (range: DateRange) => void;

  /**
   * Handle ad-hoc work period submission
   */
  handleAdHocSubmit: () => void;

  /**
   * Handle work period selection
   * @param workPeriod The work period to select
   * @param isSelected Whether the work period is selected
   */
  handleWorkPeriodSelect: (workPeriod: WorkPeriod, isSelected: boolean) => void;
};

export { Metric, WorkPeriodTense, WorkPeriodType };

export type {
  Burns,
  CumulativeFlowPhase,
  DeliveryConfidenceChartDataPoint,
  DeliveryDataPoint,
  FlowByPace,
  FlowByPhase,
  FlowData,
  HealthScoreData,
  HistoricalBurnsDataPoint,
  KeyProcessMeasuresData,
  MeasureData,
  MeasureMetadata,
  PaceChartDataPoint,
  PhaseChartDataPoint,
  ReadinessData,
  TaskRecord,
  WorkPeriod,
  WorkPeriodsContextType,
  WorkPeriodsState,
};
