import { useMutation, useQuery } from '@tanstack/react-query';
import { createWorkflow, deleteWorkflow, getWorkflows, updateWorkflow } from './workflows-client';
import { WorkflowPayload } from './workflows-client.type';

/**
 * Hook to create a workflow
 * @param {Function} onSuccess - The function to call when the mutation is successful
 * @param {string} organizationId - The organization ID
 * @returns {MutationResult} The mutation result
 */
function useCreateWorkflow({ organizationId, onSuccess }: { organizationId: string; onSuccess: () => void }) {
  const mutation = useMutation({
    mutationKey: ['createWorkflow'],
    mutationFn: ({ payload }: { payload: WorkflowPayload }) => createWorkflow({ payload, organizationId }),
    onSuccess,
  });

  return mutation;
}

/**
 * Hook to update a workflow
 *
 * @param {Function} onSuccess - The function to call when the mutation is successful
 * @param {string} organizationId - The organization ID
 * @returns {MutationResult} The mutation result
 */
function useUpdateWorkflow({ organizationId, onSuccess }: { organizationId: string; onSuccess: () => void }) {
  const mutation = useMutation({
    mutationKey: ['updateWorkflow'],
    mutationFn: ({ workflowId, payload }: { workflowId: string | null; payload: WorkflowPayload }) =>
      workflowId
        ? updateWorkflow({ workflowId, payload, organizationId })
        : Promise.reject({ error: 'Workflow ID is required' }),
    onSuccess,
  });

  return mutation;
}

/**
 * Hook to get workflows
 * @param {string | null} organizationId The organization ID
 * @returns {QueryResult} The query result
 */
function useGetWorkflows(organizationId: string | null) {
  const query = useQuery({
    queryKey: ['workflows', organizationId],
    queryFn: organizationId
      ? () => getWorkflows(organizationId)
      : () => Promise.reject({ error: 'Organization ID is required' }),
  });

  return { data: query.data, query };
}

/**
 * Hook to delete a workflow
 *
 * @param {Function} onSuccess - The function to call when the mutation is successful
 * @param {string} organizationId - The organization ID
 * @returns {MutationResult} The mutation result
 */
function useDeleteWorkflow({ organizationId, onSuccess }: { organizationId: string; onSuccess: () => void }) {
  const mutation = useMutation({
    mutationKey: ['deleteWorkflow'],
    mutationFn: ({ workflowId }: { workflowId: string }) => deleteWorkflow(workflowId, organizationId),
    onSuccess,
  });

  return mutation;
}

export { useCreateWorkflow, useDeleteWorkflow, useGetWorkflows, useUpdateWorkflow };
