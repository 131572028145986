import { Group, Select, Stack } from '@mantine/core';
import { inkLight } from '../../styles/design-tokens';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';
import { FilterGroup } from './filter-panel.types';

type Props<T extends FilterGroup> = {
  group: T;
  handleSelectChange: (group: T, value: string) => void;
  hasDivider?: boolean;
};

export const FilterPanelGroupSelect = <T extends FilterGroup>({
  group,
  handleSelectChange,
  hasDivider = true,
}: Props<T>) => {
  return (
    <Stack key={group.name} gap={16}>
      <Group justify="space-between" style={{ cursor: 'pointer' }}>
        <Group gap="xs">
          {group.icon && <Icon name={group.icon} size={16} color={inkLight} style={{ cursor: 'pointer' }} />}
          <Text weight="medium" size="small">
            {group.name}
          </Text>
        </Group>
      </Group>

      <Select
        value={group.selectedValues.length > 0 ? group.selectedValues[0] : null}
        data={group.options.map((option) => ({
          value: option.value,
          label: option.label as string,
        }))}
        onChange={(value) => handleSelectChange(group, value as string)}
        placeholder={`Select ${group.name}`}
        clearable={group.optional}
        allowDeselect={group.optional}
        radius="xl"
      />
      {hasDivider && <Divider style={{ marginTop: '8px' }} />}
    </Stack>
  );
};
