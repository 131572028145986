import i18n from '../../../../base-dictionary';
import { newCOLORS } from '../../../../styles/colors';
import { InitiativeMetricType, LegendItem } from '../initiative-performance.type';

/**
 * Returns an array of legend items based on the given metric type.
 *
 * @param {InitiativeMetricType} metric - The type of metric to generate legend items for.
 * @return {LegendItem[]} An array of legend items with titles, descriptions, and colors.
 */
const getLegendItems = (metric: InitiativeMetricType): LegendItem[] => {
  const metricLabels = {
    [InitiativeMetricType.Tasks]: i18n.t('common.work_units'),
    [InitiativeMetricType.Points]: i18n.t('common.estimation'),
  };

  return [
    {
      title: i18n.t('initiatives.initiative_performance.section_status.total_tasks', {
        metric: metricLabels[metric],
      }),
      description: i18n.t('initiatives.initiative_performance.section_status.cumulative_for_initiative', {
        initiative: i18n.t('common.initiative'),
      }),
      color: newCOLORS.blue,
    },
    {
      title: i18n.t('initiatives.initiative_performance.section_status.completed_tasks', {
        metric: metricLabels[metric],
      }),
      description: i18n.t('initiatives.initiative_performance.section_status.cumulative_for_initiative', {
        initiative: i18n.t('common.initiative'),
      }),
      color: newCOLORS.green,
    },
    {
      title: 'Projection',
      description: 'future projection',
      color: newCOLORS.darkerGray2,
    },
  ];
};

export { getLegendItems };
