import { useState } from 'react';
import { RiskAnalysisSection } from '../risk-analysis.client.type';

export const useRiskAnalysisFilters = (
  creators: { value: string; label: string }[],
  projectOptions: { value: string; label: string }[],
  taskTypes: { value: string; label: string }[],
  riskCategories: { value: string; label: string }[],
  repositoryOptions: { value: string; label: string }[],
) => {
  // Task filters
  const [creatorFilters, setCreatorFilters] = useState<string[]>([]);
  const [projectFilters, setProjectFilters] = useState<string[]>([]);
  const [typeFilters, setTypeFilters] = useState<string[]>([]);
  const [epicFilters, setEpicFilters] = useState<string[]>([]);
  const [initiativeFilters, setInitiativeFilters] = useState<string[]>([]);
  const [riskCategoryFilters, setRiskCategoryFilters] = useState<string[]>([]);
  const [includeDone, setIncludeDone] = useState(true);

  // Initiative filters
  const [activeInitiativeFilter, setActiveInitiativeFilter] = useState<string>('active');
  const [initiativeRiskCategoryFilters, setInitiativeRiskCategoryFilters] = useState<string[]>([]);

  // Epic filters
  const [epicProjectFilters, setEpicProjectFilters] = useState<string[]>([]);
  const [epicInitiativeFilters, setEpicInitiativeFilters] = useState<string[]>([]);
  const [epicRiskCategoryFilters, setEpicRiskCategoryFilters] = useState<string[]>([]);

  // Pull Request filters
  const [prRepositoryFilters, setPrRepositoryFilters] = useState<string[]>([]);
  const [prStateFilters, setPrStateFilters] = useState<string[]>(['open', 'closed']);
  const [prRiskCategoryFilters, setPrRiskCategoryFilters] = useState<string[]>([]);

  const handleResetFilters = (selectedSection: RiskAnalysisSection) => {
    if (selectedSection === RiskAnalysisSection.TASKS) {
      setCreatorFilters(creators.map((creator) => creator.value));
      setProjectFilters(projectOptions.map((project) => project.value));
      setTypeFilters(taskTypes.map((type) => type.value));
      setEpicFilters([]);
      setInitiativeFilters([]);
      setRiskCategoryFilters(riskCategories.map((riskCategory) => riskCategory.value));
      setIncludeDone(true);
    } else if (selectedSection === RiskAnalysisSection.INITIATIVES) {
      setActiveInitiativeFilter('active');
      setInitiativeRiskCategoryFilters(riskCategories.map((riskCategory) => riskCategory.value));
    } else if (selectedSection === RiskAnalysisSection.EPICS) {
      setEpicProjectFilters(projectOptions.map((project) => project.value));
      setEpicInitiativeFilters([]);
      setEpicRiskCategoryFilters(riskCategories.map((riskCategory) => riskCategory.value));
    } else if (selectedSection === RiskAnalysisSection.PULL_REQUESTS) {
      setPrRepositoryFilters(repositoryOptions.map((repository) => repository.value));
      setPrStateFilters(['open', 'closed']);
      setPrRiskCategoryFilters(riskCategories.map((riskCategory) => riskCategory.value));
    }
  };

  return {
    // Task filters
    creatorFilters,
    setCreatorFilters,
    projectFilters,
    setProjectFilters,
    typeFilters,
    setTypeFilters,
    epicFilters,
    setEpicFilters,
    initiativeFilters,
    setInitiativeFilters,
    riskCategoryFilters,
    setRiskCategoryFilters,
    includeDone,
    setIncludeDone,

    // Initiative filters
    activeInitiativeFilter,
    setActiveInitiativeFilter,
    initiativeRiskCategoryFilters,
    setInitiativeRiskCategoryFilters,

    // Epic filters
    epicProjectFilters,
    setEpicProjectFilters,
    epicInitiativeFilters,
    setEpicInitiativeFilters,
    epicRiskCategoryFilters,
    setEpicRiskCategoryFilters,

    // Pull Request filters
    prRepositoryFilters,
    setPrRepositoryFilters,
    prStateFilters,
    setPrStateFilters,
    prRiskCategoryFilters,
    setPrRiskCategoryFilters,

    // Reset handler
    handleResetFilters,
  };
};
