import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Badge, Divider, InputBase, NumberInput, Radio, Skeleton, Textarea, Tooltip } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { isNotEmpty, useForm, UseFormReturnType } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { UseMutateAsyncFunction, useQuery, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  ExtendedInitiative,
  Initiative,
  InitiativeSource,
  PreSaveInitiative,
  RelativeBusinessValueUnits,
} from '../../../api/initiative-client/initiative-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { fetchPortfolioProjects } from '../../../api/summary-client/summary-client';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { getEpicsForProjects } from '../../../api/tasks-client/tasks-client';
import { Button } from '../../../components/button/button';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { toLocalDate } from '../../../helpers/timezone/timezone';
import { setMessage } from '../../../store/strategy-store/strategy-store.actions';
import { MessageStatus } from '../../../store/strategy-store/strategy-store.type';
import { newCOLORS } from '../../../styles/colors';
import { HeadingTag } from '../../../styles/shared-styled-components';
import { FormValues, Mode } from './initiative-form.type';

type InitiativeFormProps = {
  portfolioId: string;
  initiative?: Initiative | ExtendedInitiative;
  mode: Mode;
  handleClose: (forceClose: boolean) => void;
  handleSubmit: UseMutateAsyncFunction<Initiative, unknown, PreSaveInitiative, unknown>;
};

const fetchEpics = (portfolioProjects?: ProjectsResponse[]): Promise<Epic[]> =>
  Array.isArray(portfolioProjects) && portfolioProjects.length > 0
    ? getEpicsForProjects(portfolioProjects.map((p) => p.id))
    : Promise.reject('Unable to fetch epics');

export function InitiativeForm({ portfolioId, initiative, mode, handleClose, handleSubmit }: InitiativeFormProps) {
  const [selectedEpics, setSelectedEpics] = useState<Epic[]>([]);
  const [showStartDateWarning, setShowStartDateWarning] = useState(false);

  const initialValues = initiative
    ? {
        name: initiative.name,
        description: initiative.description,
        startDate: initiative.start_date ? toLocalDate(initiative.start_date).toDate() : undefined,
        endDate: toLocalDate(initiative.end_date).toDate(),
        relativeBusinessValue: initiative.relative_business_value || 0,
        relativeBusinessValueUnits: initiative.relative_business_value_unit || RelativeBusinessValueUnits.numeric,
      }
    : {
        name: '',
        description: '',
        startDate: undefined,
        endDate: undefined,
        relativeBusinessValue: undefined,
        relativeBusinessValueUnits: RelativeBusinessValueUnits.numeric,
      };

  const form = useForm<FormValues>({
    validateInputOnChange: true,
    initialValues,
    validate: {
      name: isNotEmpty(),
      description: isNotEmpty(),
      startDate: (value, values) => {
        if (value && values.endDate && new Date(values.endDate) < new Date(value)) {
          return 'Start date must be before end date';
        }
      },
      relativeBusinessValue: (value) => {
        if (value && (value < 0 || value > 999999999)) {
          return 'Relative business value must be between 0 and 999,999,999';
        }
      },
      endDate: (value, values) => {
        isNotEmpty();
        if (dayjs(value).endOf('day').isBefore(dayjs())) {
          return 'End date must be in the future';
        }
        if (value && values.startDate && new Date(value) < new Date(values.startDate)) {
          return 'End date must be after start date';
        }
      },
    },
    transformValues: (values) => ({
      ...values,
      startDate: values.startDate ? new Date(values.startDate).toISOString() : undefined,
      endDate: values.endDate ? new Date(values.endDate).toISOString() : undefined,
    }),
  });

  const { values, clearFieldError } = form;

  useEffect(() => {
    if (values.startDate && values.endDate && values.startDate <= values.endDate) {
      clearFieldError('startDate');
      clearFieldError('endDate');
    }
  }, [values.startDate, values.endDate, clearFieldError]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (form.isDirty()) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [form]);

  const portfolioProjectsQuery = useQuery({
    queryKey: ['fetchPortfolioProjects', portfolioId],
    queryFn: () => fetchPortfolioProjects(portfolioId),
    enabled: !!portfolioId,
  } as UseQueryOptions<ProjectsResponse[], Error>);

  const getEpicsQuery = useQuery({
    queryKey: ['getEpics', portfolioProjectsQuery.data, portfolioProjectsQuery.isSuccess],
    queryFn: () => fetchEpics(portfolioProjectsQuery.data),
    enabled: portfolioProjectsQuery.isSuccess && Boolean(portfolioProjectsQuery.data),
  } as UseQueryOptions<Epic[], Error>);

  useEffect(() => {
    setSelectedEpics(initiative && 'epics' in initiative ? initiative.epics : []);
  }, [initiative]);

  const onSubmit = async (form: UseFormReturnType<FormValues, (values: FormValues) => FormValues>) => {
    const formValues = form.values;
    if (!formValues.endDate) {
      return;
    }

    const draftInitiative = {
      name: formValues.name,
      description: formValues.description,
      start_date: formValues.startDate instanceof Date ? formValues.startDate.toISOString() : formValues.startDate,
      end_date: formValues.endDate instanceof Date ? formValues.endDate.toISOString() : formValues.endDate,
      relative_business_value: formValues.relativeBusinessValue || null,
      // only send relativeBusinessValueUnits if relativeBusinessValue is set- default to numeric
      relative_business_value_unit: formValues.relativeBusinessValue
        ? (formValues.relativeBusinessValueUnits as RelativeBusinessValueUnits) || RelativeBusinessValueUnits.numeric
        : RelativeBusinessValueUnits.numeric,
      source: InitiativeSource.Bloomfilter,
      portfolio: portfolioId,
      projects: selectedEpics.flatMap((e) => e.projects.map((p) => p.id)),
      epics: selectedEpics.map((e) => e.id),
    };

    try {
      handleClose(true);
      const newInitiative = await handleSubmit(draftInitiative);

      const isInactive =
        (draftInitiative.start_date !== undefined && new Date(draftInitiative.start_date) > new Date()) ||
        (draftInitiative.end_date !== undefined && new Date(draftInitiative.end_date) < new Date());

      const action = mode === 'edit' ? 'updated' : 'created';

      if (newInitiative.message) {
        return setWarningMessage(newInitiative.message);
      }

      const message = isInactive
        ? `${draftInitiative.name} was successfully ${action}. It is an inactive initiative based on the start and end dates provided.${
            mode === 'create' ? ' To view, toggle on inactive initiatives below' : ''
          }`
        : `${draftInitiative.name} has been successfully ${action}.`;

      setSuccessMessage(message);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const setWarningMessage = (text: string) => {
    setMessage({ status: MessageStatus.Warning, text });
  };

  const setSuccessMessage = (text: string) => {
    setMessage({ status: MessageStatus.Success, text });
  };

  const handleAddEpic = (epicToAdd: Epic | null) => {
    setSelectedEpics(epicToAdd ? [...selectedEpics, epicToAdd] : selectedEpics);
  };

  const bvTooltipText = 'Provides a relative business value, or priority, for each initiative entered.';

  const handleStartDateChange = (date: Date | null) => {
    if (!date && selectedEpics.length > 0) {
      setShowStartDateWarning(true);
    } else {
      setShowStartDateWarning(false);
      form.setFieldValue('startDate', date || undefined);
    }
  };

  return (
    <form data-testid="initiative-form">
      <ContentContainer>
        <NewInitiativeInputArea>
          <InitiativeValues>
            <InputBase label="Name" maxLength={150} withAsterisk {...form.getInputProps('name')} />
            <Textarea label="Description" maxLength={1000} withAsterisk {...form.getInputProps('description')} />
            <DatePickerContainer>
              <DatePicker
                leftSection={<Icon icon="ion:calendar-outline" width={24} height={24} color={newCOLORS.blue} />}
                leftSectionPointerEvents="none"
                label="Start Date"
                placeholder="Start Date"
                error={showStartDateWarning ? 'Remove epics to remove start date.' : form.errors.startDate}
                value={form.values.startDate instanceof Date ? form.values.startDate : null}
                onChange={handleStartDateChange}
                clearable={true}
                miw="fit-content"
                w={200}
                popoverProps={{ zIndex: 600 }}
              />
              <DatePicker
                required
                leftSection={<Icon icon="ion:calendar-outline" width={24} height={24} color={newCOLORS.blue} />}
                leftSectionPointerEvents="none"
                label="End Date"
                {...{ placeholder: 'End Date' }}
                {...form.getInputProps('endDate')}
                w={200}
                popoverProps={{ zIndex: 600 }}
              />
            </DatePickerContainer>
            <BusinessValueContainer>
              <BusinessValueLabel>
                Relative Business Value
                <Tooltip multiline label={bvTooltipText} zIndex={600}>
                  <Icon icon="mdi:information-outline" width={16} height={16} color={newCOLORS.blue} />
                </Tooltip>
              </BusinessValueLabel>
              <NumberInput
                min={0}
                max={999999999}
                hideControls
                clampBehavior="strict"
                thousandSeparator=","
                leftSection={
                  form.getInputProps('relativeBusinessValueUnits').value === RelativeBusinessValueUnits.dollars
                    ? '$'
                    : null
                }
                wrapperProps={{
                  style: { width: '200%', maxWidth: '200px' },
                }}
                {...form.getInputProps('relativeBusinessValue')}
                onChange={(value) => {
                  form.setFieldValue('relativeBusinessValue', Number(`${value}`.substring(0, 9)));
                }}
              />
              <Radio.Group
                {...form.getInputProps('relativeBusinessValueUnits')}
                onChange={(value) =>
                  form.setFieldValue(
                    'relativeBusinessValueUnits',
                    (value as RelativeBusinessValueUnits) || RelativeBusinessValueUnits.numeric,
                  )
                }
              >
                <div style={{ display: 'flex' }}>
                  <Radio value={RelativeBusinessValueUnits.numeric} label="Numeric" style={{ marginRight: 15 }} />
                  <Radio value={RelativeBusinessValueUnits.dollars} label="Dollars" />
                </div>
              </Radio.Group>
            </BusinessValueContainer>
          </InitiativeValues>
          <InitiativeValueDescription>
            <p>Initiatives represent broad strategic goals and objectives for your organization.</p>
          </InitiativeValueDescription>
        </NewInitiativeInputArea>

        <Divider size="sm" style={{ margin: '16px 0px' }} />

        <div style={{ display: 'flex', gap: '3em' }}>
          <div style={{ flex: 4 }}>
            <HeadingTag>Epics contributing to this Initiative</HeadingTag>
            <RelatedEpicsList>
              {selectedEpics.map((epic, index) => (
                <Badge
                  key={index}
                  variant="light"
                  size="lg"
                  m={5}
                  pr={3}
                  rightSection={
                    <Icon
                      icon="jam:close"
                      width={24}
                      height={24}
                      color={newCOLORS.indigo}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        const remainingEpics = selectedEpics.filter((e) => e.id !== epic.id);
                        setSelectedEpics(remainingEpics);
                        if (remainingEpics.length === 0) {
                          setShowStartDateWarning(false);
                        }
                      }}
                    />
                  }
                  styles={{ root: { color: newCOLORS.indigo, maxWidth: 400 } }}
                >
                  {epic.title}: {epic.name}
                </Badge>
              ))}
            </RelatedEpicsList>
            {getEpicsQuery.isFetching ? (
              <Skeleton height={32} radius="md" />
            ) : (
              <ComboboxSelect
                options={
                  getEpicsQuery.data
                    ? getEpicsQuery.data
                        .filter((e) => !selectedEpics.map((se) => se.id).includes(e.id))
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((e) => ({ label: `${e.title}: ${e.name}`, value: e.id }))
                    : []
                }
                onChange={(value) => {
                  const epic = (getEpicsQuery.data && getEpicsQuery.data.find((epic) => epic.id === value)) || null;
                  handleAddEpic(epic);
                }}
                placeholder="Add epic"
                zIndex={600}
              />
            )}
          </div>
          <span style={{ flex: 2 }}>
            Epics are groups of work consisting of many tasks. Epics contribute to achieving Initiatives.
          </span>
        </div>

        <Divider size="sm" style={{ margin: '16px 0px' }} />

        <ModalButtons>
          <Button
            disabled={!form.values.name || !form.values.description || !form.values.endDate}
            onClick={() => onSubmit(form)}
          >
            {mode === 'create' ? 'Continue' : 'Save'}
          </Button>
          <Button variant="outline" onClick={() => handleClose(false)}>
            Cancel
          </Button>
        </ModalButtons>
      </ContentContainer>
    </form>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mantine-InputWrapper-label {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
`;

const DatePicker = styled(DatePickerInput)`
  .mantine-DatePickerInput-input {
    max-height: 32px;
  }
`;

const BusinessValueContainer = styled.div`
  display: flex;
  width: 288px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const BusinessValueLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  .mantine-Tooltip-tooltip {
    display: flex;
    padding: 16px;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 15px;
    text-align: left;
    white-space: wrap;
  }
`;

const NewInitiativeInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3em;
`;

const InitiativeValues = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InitiativeValueDescription = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
`;

const RelatedEpicsList = styled.div`
  margin: 10px 0px;
`;

const ModalButtons = styled.div`
  display: flex;
  gap: 1em;
`;
