import { styled } from '@linaria/react';
import { Box, Flex, Progress as MantineProgress } from '@mantine/core';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { newCOLORS } from '../../../styles/colors';
import {
  baseBlack,
  blueDarkest,
  blueLight,
  blueLighter,
  primaryBase,
  primaryLight,
  purpleDarkest,
  purpleLight,
  purpleLighter,
  redBase,
  secondaryBase,
  secondaryLight,
  skyDark,
} from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { H3, Text } from '../../../ui-library/typography/typography';
import { RiskAnalysisInitiative, RiskAnalysisPullRequest, RiskAnalysisTask } from './risk-analysis.client.type';
import { getRiskCategory } from './risk-analysis.helpers';

const colors = [
  secondaryBase,
  purpleDarkest,
  blueDarkest,
  blueLight,
  purpleLight,
  secondaryLight,
  blueLighter,
  purpleLighter,
] as const;

type DateFieldConfig = {
  dateField:
    | keyof Pick<RiskAnalysisTask, 'created_date' | 'done_date'>
    | keyof Pick<RiskAnalysisPullRequest, 'created_at' | 'updated_at' | 'closed_at' | 'merged_at'>;
  label: string;
};

function getAverageRiskScoreChart(
  items: (RiskAnalysisTask | RiskAnalysisPullRequest)[],
  dateConfig: DateFieldConfig = { dateField: 'created_date', label: 'Created Date' },
) {
  // Sort items by the specified date field
  const sortedItems = [...items].sort((a, b) => {
    const aDate = new Date((a as any)[dateConfig.dateField]).getTime();
    const bDate = new Date((b as any)[dateConfig.dateField]).getTime();
    return aDate - bDate;
  });

  // Group items by week and calculate average risk score
  const weeklyData = new Map<string, { sum: number; count: number }>();

  sortedItems.forEach((item) => {
    const date = new Date((item as any)[dateConfig.dateField]);
    // Get the start of the week (Sunday)
    const weekStart = new Date(date);
    weekStart.setDate(date.getDate() - date.getDay());
    const weekKey = weekStart.toISOString().split('T')[0];

    const riskScore = item.flora_annotations?.risk_score || 0;
    const existing = weeklyData.get(weekKey) || { sum: 0, count: 0 };
    weeklyData.set(weekKey, {
      sum: existing.sum + riskScore,
      count: existing.count + 1,
    });
  });

  // Convert to chart data format
  const data = Array.from(weeklyData.entries()).map(([weekStart, { sum, count }]) => ({
    date: weekStart,
    value: sum / count,
  }));

  // Calculate current week's average and last week's average
  const currentWeekAvg = data[data.length - 1]?.value || 0;
  const lastWeekAvg = data[data.length - 2]?.value || 0;

  // Calculate percentage change
  const percentageChange = lastWeekAvg ? ((currentWeekAvg - lastWeekAvg) / lastWeekAvg) * 100 : 0;
  const isDecrease = percentageChange < 0;

  // Calculate overall average
  const overallAverage = data.length > 0 ? data.reduce((sum, item) => sum + item.value, 0) / data.length : 0;

  return (
    <ChartContainer>
      <Flex align="center" gap={8}>
        <Icon
          name={isDecrease ? 'arrow_downward' : 'arrow_upward'}
          size={20}
          color={isDecrease ? primaryBase : redBase}
        />
        <Text size="tiny" color={skyDark}>
          {isDecrease ? '- ' : '+ '}
          {Math.abs(percentageChange).toFixed(1)}% from last week
        </Text>
      </Flex>
      <Flex direction="row" gap={24} style={{ marginTop: 'auto' }}>
        <ResponsiveContainer width="100%" height={80}>
          <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
            <defs>
              <linearGradient id="riskGradient" x1="0" y1="0" x2="1" y2="0">
                {data.map((entry, index) => (
                  <stop
                    key={entry.date}
                    offset={`${(index / (data.length - 1)) * 100}%`}
                    stopColor={getRiskCategory(entry.value).color}
                  />
                ))}
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              tickFormatter={(value) => {
                const date = new Date(value);
                return `${date.getMonth() + 1}/${date.getDate()}`;
              }}
              tick={false}
              axisLine={false}
            >
              <Label value={dateConfig.label} position="insideBottom" offset={10} style={{ fontSize: '14px' }} />
            </XAxis>
            <YAxis dataKey="value" hide />
            <Tooltip
              labelFormatter={(value) => {
                const date = new Date(value);
                return `Week of ${date.toLocaleDateString()}`;
              }}
              formatter={(value: number) => [value.toFixed(2), 'Avg Risk Score']}
            />
            <Line type="monotone" dataKey="value" stroke="url(#riskGradient)" strokeWidth={3} dot={false} />
          </LineChart>
        </ResponsiveContainer>
        <Flex direction="column" align="flex-end" gap={4}>
          <H3>{overallAverage.toFixed(2)}</H3>
          <Text style={{ whiteSpace: 'nowrap' }}>Avg. Risk</Text>
        </Flex>
      </Flex>
    </ChartContainer>
  );
}

function getRiskCategoryBreakdownChart(
  objects: RiskAnalysisTask[] | RiskAnalysisPullRequest[] | RiskAnalysisInitiative[],
) {
  const riskBreakdown = objects.reduce((acc: Record<string, number>, object) => {
    const riskCategory = getRiskCategory(object.flora_annotations?.risk_score).label;
    acc[riskCategory] = (acc[riskCategory] || 0) + 1;
    return acc;
  }, {});

  const data = Object.entries(riskBreakdown).map(([category, count]) => {
    const riskScore = category === 'High' ? 8 : category === 'Medium' ? 5 : category === 'Low' ? 2 : null;
    return {
      category,
      count,
      fill: getRiskCategory(riskScore).color,
    };
  });

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={120}>
        <BarChart data={data} layout="vertical" margin={{ left: 0, right: 0, top: 0, bottom: 0 }} barCategoryGap={8}>
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="category"
            axisLine={false}
            tickLine={false}
            width={60}
            tick={({ y, payload }) => (
              <text x={0} y={y} dy={4} textAnchor="start" fill={skyDark} fontSize={12} style={{ lineHeight: 14 }}>
                {payload.value}
              </text>
            )}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            type="category"
            dataKey="count"
            axisLine={false}
            tickLine={false}
            tick={{ fill: skyDark, fontSize: 12 }}
            width={40}
            tickFormatter={(value) => value.toString()}
          />
          <Bar dataKey="count" radius={[4, 4, 4, 4]} barSize={14} fillOpacity={0.8} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

function getTaskTypeAverageRiskChart(tasks: RiskAnalysisTask[]) {
  // Group tasks by type and calculate average risk score
  const typeBreakdown = tasks.reduce((acc: Record<string, { sum: number; count: number }>, task) => {
    const taskType = task.type || 'Unknown';
    if (!acc[taskType]) {
      acc[taskType] = { sum: 0, count: 0 };
    }
    acc[taskType].sum += task.flora_annotations?.risk_score || 0;
    acc[taskType].count += 1;
    return acc;
  }, {});

  // Convert to chart data format and calculate averages
  const data = Object.entries(typeBreakdown).map(([type, { sum, count }], index) => {
    const avgRisk = count > 0 ? sum / count : 0;
    return {
      name: type,
      value: avgRisk,
      fill: colors[index % colors.length],
    };
  });

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie data={data} dataKey="value" nameKey="name" cx="35%" cy="50%" outerRadius={55} innerRadius={0}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number) => [`${value.toFixed(2)}`, 'Avg. Risk Score']}
            contentStyle={{ background: 'white', border: '1px solid #ccc' }}
          />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            formatter={(value, _) => {
              const item = data.find((d) => d.name === value);
              return `${value}: ${item?.value.toFixed(2)}`;
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

function getAverageRiskByFieldChart(numbers: number[], fields: string[], showAverage = true) {
  // Create objects mapping numbers to fields
  const data = numbers.map((num, index) => ({
    field: fields[index] || 'Unknown',
    value: num,
  }));

  // Group by fields and calculate averages
  const groupedData = data.reduce(
    (acc, { field, value }) => {
      if (!acc[field]) {
        acc[field] = {
          count: 0,
          total: 0,
        };
      }
      acc[field].count += 1;
      acc[field].total += value;
      return acc;
    },
    {} as Record<string, { count: number; total: number }>,
  );

  // Convert to chart format and calculate averages
  const chartData = Object.entries(groupedData).map(([field, { count, total }], index) => ({
    field,
    average: Number((total / count).toFixed(2)),
    fill: colors[index % colors.length],
  }));

  // Calculate the overall average
  const overallAverage = Number((chartData.reduce((sum, item) => sum + item.average, 0) / chartData.length).toFixed(2));

  // Sort by average value descending
  chartData.sort((a, b) => b.average - a.average);

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData} margin={{ top: 10, right: 30, bottom: -7.5, left: 0 }}>
          <XAxis dataKey="field" interval={0} axisLine={false} />
          <YAxis
            // hide
            dataKey="average"
            domain={[0, 10]}
            axisLine={false}
          />
          <Tooltip />
          <Bar dataKey="average" name="Average Value" barSize={30}>
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Bar>
          {showAverage && <ReferenceLine y={overallAverage} stroke={skyDark} strokeWidth={2} strokeDasharray="3 3" />}
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

function getInitiativeCompletionChart(initiatives: RiskAnalysisInitiative[]) {
  // Calculate completion data
  const chartData = initiatives.map((initiative, index) => ({
    name: initiative.name,
    completion: initiative.completion_percentage || 0,
    fill: colors[index % colors.length],
  }));

  // Calculate breakdowns
  const breakdowns = [
    { label: '0-33% Completed', value: initiatives.filter((i) => (i.completion_percentage || 0) <= 33).length },
    {
      label: '34-66% Completed',
      value: initiatives.filter((i) => (i.completion_percentage || 0) > 33 && (i.completion_percentage || 0) <= 66)
        .length,
    },
    { label: '67-100% Completed', value: initiatives.filter((i) => (i.completion_percentage || 0) > 66).length },
  ];

  // Sort by completion percentage descending
  chartData.sort((a, b) => b.completion - a.completion);

  // Calculate overall average completion
  const averageCompletion = Number(
    (chartData.reduce((sum, item) => sum + item.completion, 0) / chartData.length).toFixed(2),
  );

  return (
    <ChartContainer>
      <Flex direction="column" gap={8} h="100%" style={{ marginTop: '10px', marginLeft: '10px' }}>
        <Box>
          {breakdowns.map((breakdown, index) => (
            <Text size="tiny" color={skyDark} key={index}>
              <span style={{ color: baseBlack, fontWeight: 'bold', fontSize: 16, marginRight: 4 }}>
                {breakdown.value}
              </span>{' '}
              {breakdown.label}
            </Text>
          ))}
        </Box>

        <Flex align="center" gap={24} style={{ marginTop: 'auto' }}>
          <MantineProgress value={averageCompletion} size={16} radius={4} color={primaryLight} style={{ flex: 1 }} />
          <Text size="large" weight="bold">
            {averageCompletion}%
          </Text>
        </Flex>
      </Flex>
    </ChartContainer>
  );
}

function getInitiativeStatusChart(initiatives: RiskAnalysisInitiative[]) {
  // Status color mapping
  const statusColors = {
    on_track: primaryLight,
    at_risk: redBase,
    off_track: newCOLORS.orange,
    Unknown: skyDark,
  };

  // Group initiatives by status
  const statusBreakdown = initiatives.reduce((acc: Record<string, number>, initiative) => {
    const status = initiative.status?.by_tasks?.status || 'Unknown';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Convert to chart data format with status-specific colors
  const data = Object.entries(statusBreakdown).map(([status, count]) => ({
    status:
      status === 'on_track'
        ? 'On Track'
        : status === 'at_risk'
          ? 'At Risk'
          : status === 'off_track'
            ? 'Off Track'
            : 'Unknown',
    count,
    fill: statusColors[status as keyof typeof statusColors],
  }));

  // Sort by status priority (On Track, At Risk, Off Track)
  const statusOrder = ['On Track', 'At Risk', 'Off Track', 'Unknown'];
  data.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={120}>
        <BarChart data={data} layout="vertical" margin={{ left: 0, right: 0, top: 0, bottom: 0 }} barCategoryGap={8}>
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="status"
            axisLine={false}
            tickLine={false}
            width={100}
            tick={({ y, payload }) => (
              <text x={0} y={y} dy={4} textAnchor="start" fill={skyDark} fontSize={12} style={{ lineHeight: 14 }}>
                {payload.value}
              </text>
            )}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            type="category"
            dataKey="count"
            axisLine={false}
            tickLine={false}
            tick={{ fill: skyDark, fontSize: 16 }}
            width={40}
            tickFormatter={(value) => value.toString()}
          />
          <Bar dataKey="count" radius={[4, 4, 4, 4]} barSize={14} fillOpacity={0.8}>
            {data.map((entry) => (
              <Cell key={`cell-${entry.status}`} fill={entry.fill} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

function getEpicCompletionChart(epics: RiskAnalysisTask[]) {
  // Calculate completion percentage for each epic
  const chartData = epics.map((epic, index) => {
    const completionPercentage =
      epic.not_done_subtasks !== undefined && epic.total_subtasks
        ? ((epic.total_subtasks - epic.not_done_subtasks) / epic.total_subtasks) * 100
        : 0;

    return {
      name: epic.name,
      completion: completionPercentage,
      fill: colors[index % colors.length],
    };
  });

  // Calculate breakdowns
  const breakdowns = [
    {
      label: '0-33% Completed',
      value: epics.filter((epic) => {
        if (epic.not_done_subtasks === undefined || epic.total_subtasks === undefined) return false;
        const completion = ((epic.total_subtasks - epic.not_done_subtasks) / epic.total_subtasks) * 100;
        return completion <= 33;
      }).length,
    },
    {
      label: '34-66% Completed',
      value: epics.filter((epic) => {
        if (epic.not_done_subtasks === undefined || epic.total_subtasks === undefined) return false;
        const completion = ((epic.total_subtasks - epic.not_done_subtasks) / epic.total_subtasks) * 100;
        return completion > 33 && completion <= 66;
      }).length,
    },
    {
      label: '67-100% Completed',
      value: epics.filter((epic) => {
        if (epic.not_done_subtasks === undefined || epic.total_subtasks === undefined) return false;
        const completion = ((epic.total_subtasks - epic.not_done_subtasks) / epic.total_subtasks) * 100;
        return completion > 66;
      }).length,
    },
  ];

  // Sort by completion percentage descending
  chartData.sort((a, b) => b.completion - a.completion);

  // Calculate overall average completion
  const averageCompletion = Number(
    (chartData.reduce((sum, item) => sum + item.completion, 0) / chartData.length).toFixed(2),
  );

  return (
    <ChartContainer>
      <Flex direction="column" gap={8} h="100%" style={{ marginTop: '10px', marginLeft: '10px' }}>
        <Box>
          {breakdowns.map((breakdown, index) => (
            <Text size="tiny" color={skyDark} key={index}>
              <span style={{ color: baseBlack, fontWeight: 'bold', fontSize: 16, marginRight: 4 }}>
                {breakdown.value}
              </span>{' '}
              {breakdown.label}
            </Text>
          ))}
        </Box>

        <Flex align="center" gap={24} style={{ marginTop: 'auto' }}>
          <MantineProgress value={averageCompletion} size={16} radius={4} color={primaryLight} style={{ flex: 1 }} />
          <Text size="large" weight="bold">
            {averageCompletion}%
          </Text>
        </Flex>
      </Flex>
    </ChartContainer>
  );
}

const ChartContainer = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  height: 125px;
`;

export {
  getAverageRiskByFieldChart,
  getAverageRiskScoreChart,
  getEpicCompletionChart,
  getInitiativeCompletionChart,
  getInitiativeStatusChart,
  getRiskCategoryBreakdownChart,
  getTaskTypeAverageRiskChart,
};
