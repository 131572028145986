import { styled } from '@linaria/react';
import { ReactNode } from 'react';
import { CommonContainer } from './page-layout.styles';

type Props = {
  content: ReactNode;
};

export const SideSection = ({ content }: Props) => {
  return <Container>{content}</Container>;
};

const Container = styled(CommonContainer)`
  width: 286px;
`;
