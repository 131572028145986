import { Group } from '@mantine/core';
import { primaryBase, skyDark } from '../../../styles/design-tokens';
import { TableColumn } from '../../data-management/components/table';
import { Target } from './targets-client.type';
import { getTargetComparisonSymbol, getTargetScope, getTimeAllocationTypeLabel } from './targets.helpers';

const TargetsTableColumns: TableColumn<Target>[] = [
  {
    key: 'name' as keyof Target,
    label: 'Target Name',
    width: '25%',
    sortable: true,
    render: (_: unknown, target: Target) => (
      <Group gap="xs">
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: target.is_active ? primaryBase : skyDark,
          }}
        />
        {target.name || 'Unnamed Target'}
      </Group>
    ),
  },
  {
    key: 'measure' as keyof Target,
    label: 'Measure',
    width: '15%',
    sortable: true,
  },
  {
    key: 'target_value' as keyof Target,
    label: 'Target Value',
    width: '15%',
    sortable: true,
    render: (_: unknown, target: Target) =>
      `${getTargetComparisonSymbol(target.target_comparison, target.target_value, target.target_value_upper)}`,
  },
  {
    key: 'start_date' as keyof Target,
    label: 'Start Date',
    width: '15%',
    sortable: true,
    render: (_: unknown, target: Target) => new Date(target.start_date).toLocaleDateString(),
  },
  {
    key: 'end_date' as keyof Target,
    label: 'End Date',
    width: '15%',
    sortable: true,
    render: (_: unknown, target: Target) => (target.end_date ? new Date(target.end_date).toLocaleDateString() : '-'),
  },
  {
    key: 'repeat' as keyof Target,
    label: 'Repeat',
    width: '15%',
    render: (_: unknown, target: Target) =>
      target.is_work_period_target ? 'Every Sprint' : (getTimeAllocationTypeLabel(target.time_allocation_type) ?? '-'),
  },
  {
    key: 'id' as keyof Target,
    label: 'Scope',
    width: '15%',
    render: (_: unknown, target: Target) => {
      return getTargetScope(target);
    },
  },
];

/**
 * Function to sort data based on a given key and direction
 *
 * @param {Target[]} data - The data to sort
 * @param {keyof Target} sortBy - The key to sort by
 * @param {'asc' | 'desc'} direction - The direction to sort in
 * @returns {Target[]} The sorted data
 */
const sortData = (data: Target[], sortBy: keyof Target, direction: 'asc' | 'desc'): Target[] => {
  return [...data].sort((a, b) => {
    let comparison = 0;
    const valueA = a[sortBy];
    const valueB = b[sortBy];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      comparison = valueA.localeCompare(valueB);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      comparison = valueA - valueB;
    } else if (valueA instanceof Date && valueB instanceof Date) {
      comparison = valueA.getTime() - valueB.getTime();
    }

    return direction === 'asc' ? comparison : -comparison;
  });
};

export { sortData, TargetsTableColumns };
