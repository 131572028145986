import React from 'react';
import {
  Area,
  AreaChart,
  Bar,
  Cell,
  ComposedChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { newCOLORS } from '../../../styles/colors';
import { Text } from '../../../ui-library/typography/typography';
import { TargetComparison, TransformedTarget } from '../../adherence/targets/targets-client.type';
import { getTargetComparisonSymbol } from '../../adherence/targets/targets.helpers';
import { ChartType, COLORS } from '../dashboards.types';
import { WidgetDataPoint } from '../hooks/dashboards.hooks.types';
import { getProratedAverageTotals, getTargetAreaChart } from './widget.helpers';

interface ChartWidgetProps {
  type: ChartType;
  data: WidgetDataPoint[];
  selectedTarget: TransformedTarget | null;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '8px 12px',
          border: '1px solid #e9ecef',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        }}
      >
        <p style={{ margin: 0, fontWeight: 500 }}>{label}</p>
        {payload.map((pld: any, index: number) => (
          <p key={index} style={{ margin: '4px 0 0', color: pld.color }}>
            {pld.payload.objectName ? pld.payload.objectName : pld.name}: {pld.value.toLocaleString()}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export const ChartWidget: React.FC<ChartWidgetProps> = ({ type, data, selectedTarget }) => {
  // Group data by objectId for multiple lines
  const objectIds = Array.from(new Set(data.map((item) => item.objectId)));
  const colorKeys = Object.keys(COLORS);

  // Consolidate data points by date
  const consolidatedData = data.reduce(
    (acc, curr) => {
      const existingPoint = acc.find((point) => point.date === curr.date);
      if (existingPoint) {
        existingPoint[curr.objectId] = curr.value;
        existingPoint[`${curr.objectId}Name`] = curr.objectName;
      } else {
        const newPoint: { date: string; [key: string]: number | string } = {
          date: curr.date,
          [curr.objectId]: curr.value,
          [`${curr.objectId}Name`]: curr.objectName,
        };
        acc.push(newPoint);
      }
      return acc;
    },
    [] as { date: string; [key: string]: number | string | number[] }[],
  );

  // Sort by date to ensure proper ordering
  consolidatedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  if (selectedTarget) {
    const targetId = selectedTarget.id;
    const targetKey = `target_${targetId}`;
    const isRangeTarget =
      selectedTarget.target_comparison === TargetComparison.RANGE_IN ||
      selectedTarget.target_comparison === TargetComparison.RANGE_EX;

    // Add target values to each data point
    consolidatedData.forEach((dataPoint) => {
      const date = dataPoint.date;

      const timeAllocation = selectedTarget.time_allocations[date];
      if (timeAllocation && isRangeTarget && typeof timeAllocation === 'object') {
        dataPoint[targetKey] = [timeAllocation.value_lower, timeAllocation.value_upper];
      } else if (timeAllocation && typeof timeAllocation === 'number') {
        dataPoint[targetKey] = timeAllocation;
      }
    });
  }

  const getStackedData = () => {
    return consolidatedData;
  };

  const getPieData = () => {
    if (data.length === 0) {
      return [];
    }
    let comparison: string | null = null;
    if (selectedTarget) {
      const { total_lower, total_upper } = getProratedAverageTotals(selectedTarget);
      comparison = getTargetComparisonSymbol(selectedTarget.target_comparison, total_lower, total_upper ?? null);
    }
    const pieData = objectIds.map((objectId) => {
      const objectData = data.filter((d) => d.objectId === objectId);
      if (!objectData.length) {
        return {
          id: objectId,
          name: objectData[0]?.objectName || objectId,
          value: 0,
          comparison: comparison,
        };
      }
      const total = objectData.reduce((sum, item) => sum + item.value, 0);
      return {
        id: objectId,
        name: objectData[0]?.objectName || objectId,
        value: total,
        comparison: comparison,
      };
    });
    return pieData;
  };

  const commonProps = {
    width: 500,
    height: 300,
    margin: { top: 5, right: 30, left: 0, bottom: 5 },
  };

  const renderChart = (chartType: ChartType) => {
    switch (chartType) {
      case 'line':
        return (
          <AreaChart {...commonProps} data={consolidatedData}>
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              hide={true}
              tick={{ fill: newCOLORS.darkGray, fontSize: 12 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: newCOLORS.darkGray, fontSize: 12 }} />
            <Tooltip content={<CustomTooltip />} />
            {objectIds.map((objectId, index) => {
              const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
              return (
                <Area
                  key={objectId}
                  type="monotone"
                  dataKey={objectId}
                  stroke={color.stroke}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 4, strokeWidth: 0 }}
                  name={data.find((d) => d.objectId === objectId)?.objectName || objectId}
                  fill="transparent"
                />
              );
            })}
            {selectedTarget && getTargetAreaChart(selectedTarget)}
          </AreaChart>
        );
      case 'area':
        return (
          <AreaChart {...commonProps} data={consolidatedData}>
            {objectIds.map((objectId, index) => {
              const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
              return (
                <defs key={`gradient-${objectId}`}>
                  <linearGradient id={`colorValue-${objectId}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={color.stroke} stopOpacity={0.1} />
                    <stop offset="95%" stopColor={color.stroke} stopOpacity={0} />
                  </linearGradient>
                </defs>
              );
            })}
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              hide={true}
              tick={{ fill: newCOLORS.darkGray, fontSize: 12 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: newCOLORS.darkGray, fontSize: 12 }} />
            <Tooltip content={<CustomTooltip />} />
            {objectIds.map((objectId, index) => {
              const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
              return (
                <Area
                  key={objectId}
                  type="monotone"
                  dataKey={objectId}
                  stroke={color.stroke}
                  strokeWidth={2}
                  fill={`url(#colorValue-${objectId})`}
                  fillOpacity={1}
                  name={data.find((d) => d.objectId === objectId)?.objectName || objectId}
                />
              );
            })}
            {selectedTarget && getTargetAreaChart(selectedTarget)}
          </AreaChart>
        );
      case 'bar':
        return (
          <ComposedChart {...commonProps} data={consolidatedData}>
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              hide={true}
              tick={{ fill: newCOLORS.darkGray, fontSize: 12 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: newCOLORS.darkGray, fontSize: 12 }} />
            <Tooltip content={<CustomTooltip />} />
            {objectIds.map((objectId, index) => {
              const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
              return (
                <Bar
                  key={objectId}
                  dataKey={objectId}
                  fill={color.stroke}
                  radius={[4, 4, 0, 0]}
                  maxBarSize={40}
                  name={data.find((d) => d.objectId === objectId)?.objectName || objectId}
                />
              );
            })}
            {selectedTarget && getTargetAreaChart(selectedTarget)}
          </ComposedChart>
        );
      case 'stacked_bar':
        return (
          <ComposedChart {...commonProps} data={getStackedData()}>
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              hide={true}
              tick={{ fill: newCOLORS.darkGray, fontSize: 12 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: newCOLORS.darkGray, fontSize: 12 }} />
            <Tooltip content={<CustomTooltip />} />
            {objectIds.map((objectId, index) => {
              const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
              return (
                <Bar
                  key={objectId}
                  dataKey={objectId}
                  stackId="a"
                  fill={color.stroke}
                  name={data.find((d) => d.objectId === objectId)?.objectName || objectId}
                />
              );
            })}
            {selectedTarget && getTargetAreaChart(selectedTarget)}
          </ComposedChart>
        );
      case 'pie':
        return (
          <PieChart {...commonProps}>
            <Pie
              data={getPieData()}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
              labelLine={false}
            >
              {getPieData().map((entry, index) => {
                const color = COLORS[colorKeys[index % colorKeys.length] as keyof typeof COLORS];
                return <Cell key={entry.id} fill={color.stroke} />;
              })}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const data = payload[0];
                  const value = data.value as number;
                  return (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '8px 12px',
                        border: '1px solid #e9ecef',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                      }}
                    >
                      <Text size="small" weight="medium">
                        {data.name}: {value.toLocaleString()}
                      </Text>
                      {data.payload.comparison && (
                        <Text size="tiny" color={newCOLORS.darkGray}>
                          Target: {data.payload.comparison}
                        </Text>
                      )}
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
        );
      default:
        return renderChart('line');
    }
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      {renderChart(type)}
    </ResponsiveContainer>
  );
};
