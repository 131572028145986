import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { deduplicateComboboxGroups } from '../../helpers/general-helpers';
import {
  getBoardOptions,
  useBoardManagement,
  useBoards,
  useTeams,
  useWorkPeriodsData,
  useWorkPeriodsViewData,
} from './hooks/work-periods.hooks';
import {
  FlowData,
  HealthScoreData,
  KeyProcessMeasuresData,
  WorkPeriod,
  WorkPeriodsContextType,
  WorkPeriodType,
} from './work-periods.type';

const initialState: WorkPeriodsContextType = {
  selectedWorkPeriodType: WorkPeriodType.Defined,
  selectedWorkPeriods: [],
  dateRange: {
    startDate: null,
    endDate: null,
  },
  isLoading: false,
  isFetching: false,
  scoreData: undefined,
  keyMeasuresData: undefined,
  flowData: undefined,
  customMeasures: [],
  measureData: {},
  selectedBoardId: null,
  teamId: null,
  formattedBoards: [],
  tasksByPhase: {},
  tasksByMeasure: {},
  tasksByDate: {},
  workPeriods: [],
  setTeamId: () => {},
  setSelectedBoardId: () => {},
  setSelectedWorkPeriodType: () => {},
  setSelectedWorkPeriods: () => {},
  setDateRange: () => {},
  fetchWorkPeriods: async () => {},
  fetchWorkPeriodData: async () => {},
  handleBoardSelect: () => {},
  handleWorkPeriodTypeSelect: () => {},
  handleDateRangeChange: () => {},
  handleAdHocSubmit: () => {},
  handleWorkPeriodSelect: () => {},
};

const WorkPeriodsContext = createContext<WorkPeriodsContextType>(initialState);

export function WorkPeriodsProvider({ children }: { children: ReactNode }) {
  // 1. All useState hooks first
  const [selectedWorkPeriodType, setSelectedWorkPeriodType] = useState<WorkPeriodType>(WorkPeriodType.Defined);
  const [selectedWorkPeriods, setSelectedWorkPeriods] = useState<WorkPeriod[]>([]);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [selectedBoardId, setSelectedBoardId] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>('1');
  const [_workPeriods, _setWorkPeriods] = useState<WorkPeriod[]>([]);

  // 2. Get teams from global store
  const teams = useTeams();

  // 3. Use board management hook
  useBoardManagement(selectedBoardId, setSelectedBoardId);

  // 4. Fetch all data
  const { isLoading: isLoadingBoards } = useBoards(teamId);

  const {
    data: workPeriodsData,
    isLoading: isLoadingWorkPeriods,
    isFetching: isFetchingWorkPeriods,
  } = useWorkPeriodsData(selectedBoardId);

  const {
    scoreData: apiScoreData,
    keyMeasuresData: apiKeyMeasuresData,
    flowData: apiFlowData,
    customMeasures: apiCustomMeasures,
    measureData: apiMeasureData,
    isLoading: isLoadingViewData,
  } = useWorkPeriodsViewData(selectedBoardId, selectedWorkPeriods, dateRange, selectedWorkPeriodType);

  // 7. Memoize derived values
  const formattedBoards = useMemo(() => {
    const boardOptions = getBoardOptions(teams);
    const deduplicated = deduplicateComboboxGroups(boardOptions);
    return deduplicated.flatMap((group) =>
      group.items.map((item) => {
        const comboItem = typeof item === 'string' ? { value: item, label: item } : item;
        return {
          value: comboItem.value as string,
          label: comboItem.label as string,
        };
      }),
    );
  }, [teams]);

  const scoreData = useMemo(() => apiScoreData as unknown as HealthScoreData, [apiScoreData]);
  const keyMeasuresData = useMemo(() => apiKeyMeasuresData as KeyProcessMeasuresData, [apiKeyMeasuresData]);
  const flowData = useMemo(() => apiFlowData as FlowData, [apiFlowData]);
  const customMeasures = useMemo(() => apiCustomMeasures || [], [apiCustomMeasures]);
  const measureData = useMemo(() => apiMeasureData || {}, [apiMeasureData]);

  const isLoading = useMemo(
    () => isLoadingBoards || isLoadingWorkPeriods || isLoadingViewData,
    [isLoadingBoards, isLoadingWorkPeriods, isLoadingViewData],
  );

  const isFetching = isFetchingWorkPeriods;

  /**
   * Fetches work periods for the selected board.
   * This function is called when the user wants to load work periods.
   * @param boardId - The ID of the board to fetch work periods for
   */
  const fetchWorkPeriods = useCallback(
    async (boardId?: string | null) => {
      // Use the provided boardId or the current selectedBoardId
      const boardToUse = boardId !== undefined ? boardId : selectedBoardId;

      // Update the selectedBoardId if a new boardId is provided
      if (boardId !== undefined && boardId !== selectedBoardId) {
        setSelectedBoardId(boardId);
      }

      // Reset work period selection when board changes
      setSelectedWorkPeriods([]);

      // If we have work periods data from the API and it's for the current board, update the selected work periods
      if (workPeriodsData && workPeriodsData.length > 0 && boardToUse === selectedBoardId) {
        // Convert API work periods to local work periods
        const localWorkPeriods = workPeriodsData.map((wp) => {
          // Create a new object with the correct type structure
          const workPeriod: WorkPeriod = {
            id: wp.id,
            name: wp.name,
            start_date: wp.start_date || '',
            end_date: wp.end_date || '',
            type: WorkPeriodType.Defined,
            parent: wp.parent || null,
            subproject: wp.subproject || '',
            external_id: wp.external_id || '',
          };
          return workPeriod;
        });

        // Only set selected work periods if we're not resetting
        if (localWorkPeriods.length > 0) {
          setSelectedWorkPeriods([localWorkPeriods[0]]);
        }
      }
    },
    [selectedBoardId, workPeriodsData],
  );

  /**
   * Fetches data for the selected work periods.
   * This function is called when the user selects work periods to analyze.
   * The actual data fetching is handled by the hooks based on the selected work periods.
   */
  const fetchWorkPeriodData = async () => {
    // No explicit action needed here as the data fetching is reactive
    // based on the selectedWorkPeriods, dateRange, and selectedWorkPeriodType
  };

  /**
   * Handle board selection
   * @param boardId The board ID
   */
  const handleBoardSelect = useCallback(
    (boardId: string | null) => {
      setSelectedBoardId(boardId);
      setSelectedWorkPeriods([]);
      // Reset work period selection when board changes
      if (boardId) {
        fetchWorkPeriods(boardId);
      }
    },
    [fetchWorkPeriods],
  );

  /**
   * Handle work period type selection
   * @param type The work period type
   */
  const handleWorkPeriodTypeSelect = useCallback((type: WorkPeriodType) => {
    setSelectedWorkPeriodType(type);
    setSelectedWorkPeriods([]);
    // Reset selected work periods when type changes
  }, []);

  /**
   * Handle date range selection for ad-hoc work periods
   * @param range The date range
   */
  const handleDateRangeChange = useCallback((range: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(range);
  }, []);

  /**
   * Handle ad-hoc work period submission
   */
  const handleAdHocSubmit = useCallback(() => {
    if (selectedBoardId && dateRange.startDate && dateRange.endDate) {
      // For ad-hoc work periods, we don't need to select a specific work period
      // Just fetch the data based on the date range
      fetchWorkPeriodData();
    }
  }, [selectedBoardId, dateRange]);

  /**
   * Handle work period selection
   * @param workPeriod The work period to select
   * @param isSelected Whether the work period is selected
   */
  const handleWorkPeriodSelect = useCallback(
    (workPeriod: WorkPeriod, isSelected: boolean) => {
      if (isSelected) {
        // For assessment view, we only allow one work period at a time
        if (selectedWorkPeriods.length >= 4) {
          // For comparison view, we allow up to 4 work periods
          setSelectedWorkPeriods((prevSelected) => {
            // Remove the oldest work period and add the new one
            const newSelected = [...prevSelected];
            newSelected.shift(); // Remove the first element
            newSelected.push(workPeriod);
            return newSelected;
          });
        } else {
          setSelectedWorkPeriods((prevSelected) => [...prevSelected, workPeriod]);
        }
      } else {
        // Remove the work period from the selection
        setSelectedWorkPeriods((prevSelected) => prevSelected.filter((wp) => wp.id !== workPeriod.id));
      }
    },
    [selectedWorkPeriods],
  );

  return (
    <WorkPeriodsContext.Provider
      value={{
        selectedWorkPeriodType,
        selectedWorkPeriods,
        dateRange,
        isLoading,
        isFetching,
        scoreData,
        keyMeasuresData,
        flowData,
        customMeasures,
        measureData,
        selectedBoardId,
        teamId,
        formattedBoards,
        workPeriods:
          workPeriodsData?.map((wp) => ({
            id: wp.id,
            name: wp.name,
            startDate: wp.start_date || '',
            endDate: wp.end_date || '',
            start_date: wp.start_date || '',
            end_date: wp.end_date || '',
            type: WorkPeriodType.Defined,
            parent: wp.parent || null,
            subproject: wp.subproject || '',
            external_id: wp.external_id || '',
          })) || [],
        setTeamId,
        setSelectedBoardId,
        setSelectedWorkPeriodType,
        setSelectedWorkPeriods,
        setDateRange,
        fetchWorkPeriods,
        fetchWorkPeriodData,
        handleBoardSelect,
        handleWorkPeriodTypeSelect,
        handleDateRangeChange,
        handleAdHocSubmit,
        handleWorkPeriodSelect,
      }}
    >
      {children}
    </WorkPeriodsContext.Provider>
  );
}

export function useWorkPeriodsContext() {
  return useContext(WorkPeriodsContext);
}
