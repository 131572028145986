import { Workflow } from '../../../../../api/workflows-client/workflows-client.type';

type WorkflowAction =
  | { type: 'SET_WORKFLOW'; payload: Workflow }
  | { type: 'UPDATE_FIELD'; field: keyof Workflow; value: Workflow[keyof Workflow] };

function workflowReducer(state: Workflow, action: WorkflowAction): Workflow {
  switch (action.type) {
    case 'SET_WORKFLOW':
      return action.payload;
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
}

export { workflowReducer };
export type { WorkflowAction };
