import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import {
  getSourceOptions,
  getStateOptions,
  getStatusOptions,
} from '../../containers/strategy-v2/initiative-list/filters/filters.helpers';
import {
  PerformanceTab,
  ThroughputMode,
} from '../../containers/strategy-v2/initiative-performance/initiative-performance.types';
import { isLoggingEnabled, logger } from '../logger/logger';
import { Panel, State, TreeMapMetric, View } from './strategy-store.type';

const defaultFilters = {
  status: getStatusOptions().map((option) => option.value),
  state: getStateOptions().map((option) => option.value),
  source: getSourceOptions().map((option) => option.value),
};

const useStrategyStore = create<State>()(
  devtools(
    persist(
      logger((_) => ({
        initiatives: [],
        kpiOverviewData: null,
        view: View.Table,
        page: 1,
        selectedInitiative: null,
        filters: defaultFilters,
        collapsedGroups: {
          status: false,
          state: false,
          source: false,
        },
        activePanel: Panel.Filters,
        searchTerm: '',
        treeMapMetric: TreeMapMetric.CostByInitiative,
        epics: [],
        initiativePerformance: null,
        performanceTab: PerformanceTab.Throughput,
        throughputMode: ThroughputMode.InitiativeProgress,
        message: null,
      })),
      {
        name: 'strategy-storage',
        storage: createJSONStorage(() => localStorage),
        version: 1,
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => !['page', 'searchTerm', 'performanceTab'].includes(key)),
          ),
      },
    ),
    {
      name: 'Bloomfilter - Strategy Store',
      enabled: isLoggingEnabled,
    },
  ),
);

export { defaultFilters, useStrategyStore };
