import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Flex, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Controls } from '@reactflow/controls';
import { memo, useCallback, useContext, useMemo } from 'react';
import ReactFlow, { useReactFlow } from 'reactflow';
import { GraphData, ProcessMapping, Stages } from '../../api/process-client/process-client.type';
import i18n from '../../base-dictionary';
import { newCOLORS } from '../../styles/colors';
import { FloraButton } from '../flora/flora-button';
import { ProcessContext } from './context/process.context';
import { StageEdge } from './edges/stage-edge';
import { WorkflowEdgeIn, WorkflowEdgeOut } from './edges/workflow-edge';
import { StageNode } from './nodes/stage-node';
import { WorkflowNode } from './nodes/workflow-node';
import { calculateGraph } from './process.helpers';
import { SectionContainer, Text } from './process.styled';
import { ViewType } from './process.type';
import { Legend } from './scope/legend';
import { Scope } from './scope/scope';
import { ScopeChange } from './scope/scope.type';

const nodeTypes = {
  stageNode: StageNode,
  workflowNode: WorkflowNode,
};

const edgeTypes = {
  stageEdge: StageEdge,
  workflowEdgeIn: WorkflowEdgeIn,
  workflowEdgeOut: WorkflowEdgeOut,
};

type Props = {
  graphData: GraphData | undefined;
  mapping: ProcessMapping | null;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  isComparisonView?: boolean;
  teamLabel?: string;
  onScopeChange?: (scopeChange: ScopeChange) => void;
};

export const SectionGraph = memo(function SectionGraph({
  graphData,
  mapping,
  viewType,
  setViewType,
  isComparisonView = false,
  teamLabel,
  onScopeChange,
}: Props) {
  const [opened, { toggle }] = useDisclosure(true);
  const { fitView } = useReactFlow();
  const { stages } = useContext(ProcessContext);
  const { nodes, edges } = useMemo(
    () => calculateGraph(graphData, mapping, stages as Stages[], viewType),
    [graphData, mapping, stages, viewType],
  );

  const handleToggle = useCallback(() => {
    toggle();
    setTimeout(fitView);
  }, [toggle, fitView]);

  const flowProps = useMemo(
    () => ({
      nodeOrigin: [0.5, 0.5] as [number, number],
      fitView: true,
      nodeTypes,
      edgeTypes,
      nodes,
      edges,
      proOptions: { hideAttribution: true },
      edgesFocusable: false,
      elementsSelectable: false,
    }),
    [nodes, edges],
  );

  const handleScopeChange = (scopeChange: ScopeChange) => {
    if (onScopeChange) {
      onScopeChange(scopeChange);
    }
  };

  return (
    <SectionContainer style={{ padding: '24px 16px' }}>
      {isComparisonView && teamLabel && (
        <TeamHeader>
          <Title order={3}>{teamLabel}</Title>
        </TeamHeader>
      )}
      {!isComparisonView && (
        <HeadingContainer>
          <CollapseIcon>
            <Icon
              icon={opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one'}
              width={16}
              height={16}
              color={newCOLORS.purple2}
              onClick={handleToggle}
            />
          </CollapseIcon>
          <Text fontSize={19}>
            {i18n.t('process.section_graph.title', { work_units: i18n.t('common.work_units') })}
          </Text>
          <FloraButton externalData={[graphData]} size={30} />
        </HeadingContainer>
      )}
      <Collapse in={opened} style={{ width: '100%' }}>
        {isComparisonView && graphData && (
          <MeasuresContainer>
            <ProcessMeasuresTable graphData={graphData} />
          </MeasuresContainer>
        )}
        <Text fontSize={13} style={{ marginLeft: 40 }}>
          Click on any stage box below to see skipped stages and backwards tasks. Click it again to return to this
          default view.
        </Text>
        <Flex style={{ height: isComparisonView ? 900 : 800, marginTop: 24 }}>
          <ScopeContainer isComparisonView={isComparisonView}>
            <Scope
              mapping={mapping}
              viewType={viewType}
              setViewType={setViewType}
              isComparisonView={isComparisonView}
              onScopeChange={handleScopeChange}
            />
            <Legend />
          </ScopeContainer>
          <StyledReactFlow {...flowProps}>
            <Controls position="bottom-right" showInteractive={false} />
          </StyledReactFlow>
        </Flex>
      </Collapse>
    </SectionContainer>
  );
});

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 16px;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 2px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const StyledReactFlow = styled(ReactFlow)`
  .react-flow__handle {
    opacity: 0;
    border: none;
    min-height: 0;
  }

  .react-flow__controls {
    background: #f5f5f5;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .react-flow__edge-path {
    stroke: #000;
  }
`;

const ScopeContainer = styled.div<{ isComparisonView?: boolean }>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ced4da;
  width: ${(props) => (props.isComparisonView ? '300px' : '400px')};
  padding: 24px;
  gap: 32px;
  min-width: ${(props) => (props.isComparisonView ? '300px' : '260px')};
`;

const TeamHeader = styled.div`
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${newCOLORS.lightGray};
`;

const MeasuresContainer = styled.div`
  margin: 16px 0;
  padding: 16px;
  background-color: #f0f9ff; /* Light blue background */
  border-radius: 4px;
`;

// Create a component for the process measures table
const ProcessMeasuresTable = ({ graphData }: { graphData?: GraphData }) => {
  // Calculate average cycle time from the cycle_times object if it exists
  const avgCycleTime = useMemo(() => {
    if (!graphData?.cycle_times) return 'N/A';

    const values = Object.values(graphData.cycle_times);
    if (values.length === 0) return 'N/A';

    const sum = values.reduce((acc, val) => acc + val, 0);
    return (sum / values.length).toFixed(1);
  }, [graphData]);

  return (
    <div>
      <Text fontSize={16} style={{ marginBottom: '12px', fontWeight: 600 }}>
        Key Measures
      </Text>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              Measure
            </th>
            <th style={{ textAlign: 'right', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>Cycle Time</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              {avgCycleTime} days
            </td>
          </tr>
          <tr>
            <td style={{ padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>Task Count</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              {graphData?.task_count || 0}
            </td>
          </tr>
          <tr>
            <td style={{ padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>Nodes</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              {graphData?.nodes?.length || 0}
            </td>
          </tr>
          <tr>
            <td style={{ padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>Common Paths</td>
            <td style={{ textAlign: 'right', padding: '8px', borderBottom: `1px solid ${newCOLORS.lightGray}` }}>
              {graphData?.common_paths?.length || 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
