import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { UserContext } from '../../../contexts';
import { useIntegrationsStore } from '../../../store/integrations-store/integrations-store';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';
import { JiraState } from './jira.type';

/**
 * Custom hook to handle OAuth authentication flow for Jira integration.
 * This hook manages the OAuth process by completing the authentication when valid data is available
 * and handling success/error cases appropriately.
 *
 * @param {Object} params - Hook parameters
 * @param {JiraState} params.state - Current state of the Jira integration
 * @param {(data: Record<string, unknown>) => void} params.onSuccess - Callback function called when OAuth succeeds
 * @returns {Object} Object containing the OAuth query result
 * @property {QueryObserverResult<Record<string, unknown>>} completeOauthQuery - Query result for the OAuth completion
 */
const useOauth = ({
  state,
  onSuccess,
}: {
  state: JiraState;
  onSuccess: (data: Record<string, unknown>) => void;
}): { completeOauthQuery: QueryObserverResult<Record<string, unknown>> } => {
  const showIntegrationsV2 = import.meta.env.VITE_FEATURE_FLAG_INTEGRATIONS_V2 === 'true';
  const { user } = useContext(UserContext);
  const { query: integrationsQuery } = useContext(IntegrationsContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { redirect, setRedirect } = useRedirect();
  const integrationId = useIntegrationsStore((state) => state.integration?.id);

  const completeOauthQuery = useQuery({
    queryKey: ['oauth', user?.id] as const,
    queryFn: async () => {
      const code = searchParams.get('code');
      const tempStates = searchParams.get('state')?.split(',');
      const state = tempStates ? tempStates[0] : '';
      const orgId = tempStates ? tempStates[1] : '';

      if (!user?.id || !orgId || !code) {
        throw new Error(`Invalid userId: ${user?.id} or orgId: ${orgId} or code: ${code}`);
      }

      localStorage.setItem('jiraInstance', state);
      return await completeOauth(ExternalService.Jira, user.id.toString(), orgId, code, state, integrationId || null);
    },
    enabled: !!searchParams.get('code') && !!user && !state.project?.id,
  });

  const handleSettled = useCallback(() => {
    searchParams.delete('code');
    searchParams.delete('state');
    setSearchParams(searchParams);

    if (redirect) {
      completeReauth();
      navigate(redirect);
      integrationsQuery.refetch();
      setRedirect(null);
    }
  }, [redirect, navigate, integrationsQuery, setRedirect, searchParams, setSearchParams]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess && completeOauthQuery.data) {
      onSuccess(completeOauthQuery.data);
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.data, onSuccess]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess || completeOauthQuery.isError) {
      handleSettled();
      if (showIntegrationsV2) {
        navigate('/application/integrations/success');
      }
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.isError, handleSettled, navigate]);

  return {
    completeOauthQuery,
  };
};

export { useOauth };
