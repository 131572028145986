import { styled } from '@linaria/react';
import { Group } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { getActivePiecesToken } from '../../api/automations-client/automations-client';
import { UserContext } from '../../contexts/user';
import { useGlobalStore } from '../../store/global-store/global-store';
import { secondaryBase } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';

export function Automation() {
  const currentOrganization = useGlobalStore((state) => state.organization);
  const { user } = useContext(UserContext);
  const [activepiecesToken, setActivePiecesToken] = useState<string | null>(null);

  const activePiecesInstanceUrl = import.meta.env.VITE_ACTIVEPIECES_INSTANCE_URL;

  useEffect(() => {
    const fetchActivePiecesToken = async () => {
      if (currentOrganization && currentOrganization.id) {
        try {
          const token = await getActivePiecesToken(currentOrganization.id);
          setActivePiecesToken(token);
        } catch (error) {
          console.error('Failed to get ActivePieces token:', error);
        }
      }
    };

    if (user) {
      fetchActivePiecesToken();
    }
  }, [user, currentOrganization]);

  useEffect(() => {
    if (activepiecesToken) {
      (window as any).activepieces.configure({
        prefix: '/',
        instanceUrl: activePiecesInstanceUrl,
        jwtToken: activepiecesToken,
        embedding: {
          containerId: 'activepieces-iframe',
          builder: {
            disableNavigation: false,
            hideLogo: true,
            hideFlowName: false,
          },
          dashboard: {
            hideSidebar: true,
          },
          styling: {
            fontFamily: 'Figtree',
            width: '100%',
            height: '100%',
          },
          hideFolders: false,
        },
      });
    }
  }, [activepiecesToken]);

  const header = (
    <Group>
      <Icon name="smart_toy" color={secondaryBase} size={36} />
      <H2>Automation</H2>
    </Group>
  );

  return (
    <MenuDrivenContainer header={header}>
      <IframeContainer id="activepieces-iframe" />
    </MenuDrivenContainer>
  );
}

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;
