import { Divider, Flex } from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { completeHandshake, completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { UserContext } from '../../../contexts/user';
import { StepProps } from '../integrations.type';
import { SuccessView } from '../views/success-view';
import styles from '../views/views.module.css';
import { FigmaProjectResponse, FigmaState } from './figma.type';
import { Projects } from './projects';
import { Summary } from './summary';
import { Teams } from './teams';

const initialState: FigmaState = {
  projectOptions: [],
  teamOptions: [],
  selectedProject: '',
  selectedTeam: '',
};

export const Figma = ({ activeStep = 0, setActiveStep }: StepProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const [figmaState, setFigmaState] = useState(initialState);

  const updateState = useCallback((update: Partial<FigmaState>) => {
    setFigmaState((prevState) => Object.assign({}, prevState, update));
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    const authState = searchParams.get('state');
    const userId = user?.id.toString();
    const orgId = user?.organizations[0].toString();

    if (code && authState && userId && orgId) {
      updateState({ code, authState, userId, orgId });
      searchParams.delete('code');
      searchParams.delete('state');
      setSearchParams(searchParams);
    }
  }, [searchParams, user, updateState, setSearchParams]);

  useEffect(() => {
    if (figmaState.userId && figmaState.orgId && figmaState.code && figmaState.authState && figmaState.selectedTeam) {
      completeOauth(
        ExternalService.Figma,
        figmaState.userId,
        figmaState.orgId,
        figmaState.code,
        figmaState.authState,
        '',
        {
          team: figmaState.selectedTeam,
        },
      ).then((data) => {
        updateState({ projectOptions: (data as FigmaProjectResponse).projects });
      });
    }
  }, [
    figmaState.userId,
    figmaState.orgId,
    figmaState.code,
    figmaState.authState,
    figmaState.selectedTeam,
    updateState,
  ]);

  const views = [
    <Teams updateState={updateState} key="teams" />,
    <Projects figmaState={figmaState} updateState={updateState} key="projects" />,
    <Summary figmaState={figmaState} setActiveStep={setActiveStep} key="summary" />,
    <SuccessView key="success" />,
  ];

  const finalizeIntegration = () => {
    const orgId = user?.organizations[0]?.toString();
    if (orgId) {
      completeHandshake('figma', {
        org_id: orgId,
        team_id: figmaState.selectedTeam,
        project_id: figmaState.selectedProject,
      }).then(() => {
        nextStep();
      });
    }
  };

  const isNextButtonDisabled = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return !figmaState.selectedTeam;
      default:
        return false;
    }
  };

  const nextStep = () => setActiveStep((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => setActiveStep((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 4 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 2 ? nextStep : finalizeIntegration}
                size="lg"
              >
                {activeStep < 3 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
};
