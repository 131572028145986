import { AppShell, Flex } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { GlobalScope } from '../../components/global-scope/global-scope';
import { UserContext } from '../../contexts';
import { Divider } from '../../ui-library/divider/divider';
import { Logo } from '../../ui-library/logo/logo';
import { ROUTES } from '../navigation/navigation';
import { ActionButton } from './action-button';
import { SidebarItem } from './side-bar-item';
import { SidebarContext } from './side-bar.context';
import { GeneralViewNames } from './side-bar.type';

export function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { windowViewportWidth, sidebarOpen, setSidebarOpen, navItems } = useContext(SidebarContext);

  const [activeView, setActiveView] = useState<GeneralViewNames | null>(null);

  useEffect(() => {
    if (windowViewportWidth < 992) {
      setSidebarOpen(false);
    }
  }, [windowViewportWidth, setSidebarOpen]);

  useEffect(() => {
    const activeViewRoute = ROUTES.find((route) => matchPath(`/application/${route.path}`, location.pathname));
    if (activeViewRoute?.view) {
      setActiveView(activeViewRoute.view);
    }
  }, [location.pathname]);

  return (
    <AppShell.Navbar
      w={{ sm: sidebarOpen ? 280 : 70, base: 70 }}
      p="md"
      style={{
        height: '100%',
        minHeight: 600,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        zIndex: 60,
      }}
    >
      <Logo
        width={36}
        height={36}
        onClick={() => (user?.subscription_status === 'paid' ? navigate(`/application/strategy`) : null)}
        showText={sidebarOpen}
      />
      <AppShell.Section>
        <GlobalScope enableTooltips={!sidebarOpen} />
      </AppShell.Section>
      <Flex direction="column" gap={16}>
        <Divider />
        <AppShell.Section grow style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {navItems.map((item) => (
            <SidebarItem key={item.label} item={item} sidebarOpen={sidebarOpen} active={activeView === item.id} />
          ))}
        </AppShell.Section>
      </Flex>
      {windowViewportWidth >= 992 && <ActionButton />}
    </AppShell.Navbar>
  );
}
