import { styled } from '@linaria/react';
import {
  ActiveElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { getElementAtEvent, Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../../base-dictionary';
import { createWeekendBackgroundPlugin } from '../../../../helpers/chart-plugins/weekend-background-element';
import { round } from '../../../../helpers/math-helpers/math-helpers';
import { newCOLORS } from '../../../../styles/colors';
import { HeadingTag } from '../../../../styles/shared-styled-components';
import { Metric } from '../assessment-view.type';
import { DataPoint } from '../section-pace.types';

export type Props = {
  data: DataPoint[];
  labels: string[];
  metric: Metric;
};

enum LineLabel {
  ActualWork = 'Actual Work',
  Plan = 'Plan',
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

export const FlowOfWork = ({ data, labels, metric }: Props) => {
  const chartRef = useRef<ChartJS<'line'>>(null);
  const navigate = useNavigate();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: false,
    cubicInterpolationMode: 'monotone',
    borderWidth: 5,
    aspectRatio: 2.5,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    onHover: (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
      let cursorStyle = 'default';

      const isOnElement = elements.length > 0;
      // Check if the user is hovering over the actual work dataset
      const isActualWorkDataset =
        isOnElement && chart?.data.datasets[elements[0].datasetIndex].label === LineLabel.ActualWork;
      if (isActualWorkDataset) {
        cursorStyle = 'pointer';
      }
      if (event?.native?.target) {
        (event.native.target as HTMLElement).style.cursor = cursorStyle;
      }
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: metric === Metric.Tasks ? i18n.t('common.work_units') : i18n.t('common.estimation'),
          font: {
            size: 16,
          },
        },
        afterFit: function (scaleInstance: any) {
          scaleInstance.width = scaleInstance.width + 40;
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        labels: {
          boxWidth: 2,
          boxHeight: 12,
          pointStyle: 'rect',
        },
      },

      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<'line'>) => {
            const label = context.dataset.label || '';
            const value = context.dataset.data[context.dataIndex];
            const formattedValue = typeof value === 'number' ? round(value, 1) : null;

            return `${label}: ${formattedValue}`;
          },
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
    },
  };

  const weekendOrHolidays = data.map((dataPoint) => dataPoint.weekend_or_holiday);

  const formattedData = {
    labels: labels.map((label) => dayjs(label).format('MM/DD')),
    datasets: [
      {
        label: LineLabel.ActualWork,
        order: 1,
        stack: 'Stack 0',
        data: data.map((dataPoint) => dataPoint.complete),
        hidden: false,
        borderColor: newCOLORS.magenta,
        backgroundColor: newCOLORS.magenta,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 5,
        borderWidth: 2,
      },
      {
        label: LineLabel.Plan,
        order: 2,
        stack: 'Stack 1',
        data: data.map((dataPoint) => dataPoint.ideal),
        hidden: false,
        borderColor: newCOLORS.blue,
        pointRadius: 4,
        pointStyle: 'circle',
        backgroundColor: newCOLORS.blue,
        borderWidth: 2,
      },
    ],
  };

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!chartRef.current) {
      return;
    }

    // If the user clicks outside of a point, do nothing
    const pointElement = getElementAtEvent(chartRef.current, event)[0];
    if (!pointElement) {
      return;
    }

    // Only navigate if the user clicks on the actual work line
    if (formattedData.datasets[pointElement.datasetIndex].label !== LineLabel.ActualWork) {
      return;
    }

    // Get the full date (YYYY-MM-DD) of the clicked point
    const clickedDate = labels[pointElement.index];

    if (clickedDate) {
      const params = new URLSearchParams({
        measure: 'flow_by_pace',
        factor: 'done',
        date: clickedDate,
      });

      // path is relative!
      navigate(`tasks/?${params.toString()}`);
    }
  };

  return (
    <ChartContainer>
      <CenterHeadingTag>Flow of work</CenterHeadingTag>
      <div style={{ height: '350px', display: 'flex' }}>
        <div style={{ width: '99%' }}>
          <Line
            options={options}
            data={formattedData}
            plugins={[createWeekendBackgroundPlugin(weekendOrHolidays)]}
            ref={chartRef}
            onClick={onClick}
          />
        </div>
      </div>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  margin-bottom: 25px;
`;

const CenterHeadingTag = styled(HeadingTag)`
  text-align: center;
`;
