import { useEffect, useRef } from 'react';

/**
 * Custom hook to ensure a callback is only executed once, even in React StrictMode.
 * Useful for preventing duplicate API calls or side effects.
 *
 * @param callback The function to execute once
 * @param dependencies Dependencies array that determines when the callback should be eligible to run
 * @param shouldRun Optional condition to determine if the callback should run
 * @returns void
 */
const useRunOnce = (
  callback: () => void | Promise<void>,
  dependencies: React.DependencyList,
  shouldRun = true,
): void => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (shouldRun && !hasRun.current) {
      hasRun.current = true;
      callback();
    }
  }, [callback, shouldRun, ...dependencies]);
};

export { useRunOnce };
