import { QueryObserverResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DetailedRepository } from '../integrations/github/repositories.type';
import { getRepositories, mapProjectsToRepositories, removeProjectsFromRepositories } from './data-management-client';

/**
 * Hook for fetching repositories
 * @returns Object containing:
 * - data: Array of repositories
 * - query: QueryObserverResult for the repositories
 */
const useRepositories = ({
  organization_id,
}: {
  organization_id: string | null;
}): { data: DetailedRepository[] | undefined; query: QueryObserverResult<DetailedRepository[]> } => {
  const query = useQuery({
    queryKey: ['repositories', organization_id],
    queryFn: () => getRepositories(organization_id),
    enabled: !!organization_id,
  });

  return { data: query.data, query };
};

/**
 * Hook for adding projects to a repository
 *
 * @param {string} organization_id - The ID of the organization
 * @returns Object containing:
 * - addProjectsToRepository: Function to map projects to a repository
 * - isAdding: Boolean indicating if addition is in progress
 */
const useAddProjectsToRepository = ({
  organizationId,
}: {
  organizationId: string | null;
}): {
  addProjectsToRepository: (repositoryId: string, projectId: string) => Promise<void>;
  isAdding: boolean;
} => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      repositoryId,
      projectId,
      organizationId,
    }: { repositoryId: string; projectId: string; organizationId: string }) =>
      mapProjectsToRepositories(repositoryId, [projectId], organizationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['repositories'] });
    },
  });

  return {
    addProjectsToRepository: (repositoryId: string, projectId: string) =>
      mutation.mutateAsync({ repositoryId, projectId, organizationId: organizationId as string }),
    isAdding: mutation.isPending,
  };
};

/**
 * Hook for removing projects from a repository
 *
 * @param {string} organizationId - The ID of the organization
 * @returns Object containing:
 * - removeProjectsFromRepository: Function to remove projects from a repository
 * - isRemoving: Boolean indicating if removal is in progress
 */

const useRemoveProjectsFromRepository = ({
  organizationId,
}: {
  organizationId: string | null;
}): {
  removeProjectsFromRepository: (repositoryId: string, projectId: string) => Promise<void>;
  isRemoving: boolean;
} => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      repositoryId,
      projectId,
      organizationId,
    }: { repositoryId: string; projectId: string; organizationId: string }) =>
      removeProjectsFromRepositories(repositoryId, [projectId], organizationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['repositories'] });
    },
  });

  return {
    removeProjectsFromRepository: (repositoryId: string, projectId: string) =>
      mutation.mutateAsync({ repositoryId, projectId, organizationId: organizationId as string }),
    isRemoving: mutation.isPending,
  };
};

export { useAddProjectsToRepository, useRemoveProjectsFromRepository, useRepositories };
