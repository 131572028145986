import { PerformanceTab } from './initiative-performance.types';

const tabs = [
  { id: PerformanceTab.Throughput, label: 'Throughput', icon: 'calendar_month' },
  { id: PerformanceTab.Focus, label: 'Focus', icon: 'center_focus_strong' },
  { id: PerformanceTab.FlowByPhase, label: 'Flow By Phase', icon: 'swap_calls' },
  { id: PerformanceTab.Targets, label: 'Targets', icon: 'ads_click' },
  { id: PerformanceTab.Cost, label: 'Cost', icon: 'payments' },
  { id: PerformanceTab.RelatedTasks, label: 'Related Tasks', icon: 'task_alt' },
];

export { tabs };
