import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Button, Checkbox, Divider, Group, Radio, Select } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { newCOLORS } from '../../../styles/colors';
import { useWorkPeriodsContext } from '../work-periods.context';
import type { DateRange, WorkPeriod } from '../work-periods.type';
import { WorkPeriodType } from '../work-periods.type';

/**
 * WorkPeriodsFilter component
 * Allows users to filter work periods by board, type (defined or custom), and date range
 * @param {boolean} isComparisonView - Whether this component is used in the comparison view
 */
export function WorkPeriodsFilter({ isComparisonView = false }) {
  const {
    selectedBoardId,
    selectedWorkPeriodType,
    dateRange = { startDate: null, endDate: null },
    workPeriods = [],
    selectedWorkPeriods = [],
    formattedBoards = [],
    handleBoardSelect,
    handleWorkPeriodTypeSelect,
    handleDateRangeChange,
    handleWorkPeriodSelect,
    handleAdHocSubmit,
  } = useWorkPeriodsContext();

  // Determine if submit button should be enabled for ad-hoc work periods
  const isSubmitEnabled = useMemo(() => {
    if (selectedWorkPeriodType === WorkPeriodType.Custom) {
      return (
        !!selectedBoardId && !!dateRange?.startDate && !!dateRange?.endDate && dateRange.startDate < dateRange.endDate
      );
    }
    return false;
  }, [selectedWorkPeriodType, selectedBoardId, dateRange]);

  // Handle date range change
  const onDateRangeChange = useCallback(
    (field: keyof DateRange, date: Date | null) => {
      const newDateRange = { ...dateRange, [field]: date };
      handleDateRangeChange(newDateRange);
    },
    [dateRange, handleDateRangeChange],
  );

  // Handle work period selection for radio buttons (single selection)
  const onRadioWorkPeriodSelect = useCallback(
    (workPeriodId: string) => {
      // Clear all selected work periods first
      selectedWorkPeriods.forEach((wp) => {
        handleWorkPeriodSelect(wp, false);
      });

      // Then select the new work period
      const workPeriod = workPeriods?.find((wp: { id: string }) => wp.id === workPeriodId);
      if (workPeriod) {
        handleWorkPeriodSelect(workPeriod, true);
      }
    },
    [workPeriods, handleWorkPeriodSelect, selectedWorkPeriods],
  );

  // Handle work period selection for checkboxes (multi-selection)
  const onCheckboxWorkPeriodSelect = useCallback(
    (workPeriodId: string, checked: boolean) => {
      const workPeriod = workPeriods?.find((wp: { id: string }) => wp.id === workPeriodId);
      if (!workPeriod) return;

      if (checked) {
        // If trying to check and already have 4 selected, prevent selection
        if (selectedWorkPeriods.length >= 4) {
          return; // Don't proceed with selection
        }
      }

      // Otherwise proceed with normal selection/deselection
      handleWorkPeriodSelect(workPeriod, checked);
    },
    [workPeriods, handleWorkPeriodSelect, selectedWorkPeriods],
  );

  // helper function to determine if a checkbox should be disabled
  const isCheckboxDisabled = useCallback(
    (workPeriodId: string) => {
      // If this work period is already selected, never disable (allow unchecking)
      if (selectedWorkPeriods.some((wp) => wp.id === workPeriodId)) {
        return false;
      }

      // If we already have 4 selections, disable unselected checkboxes
      return selectedWorkPeriods.length >= 4;
    },
    [selectedWorkPeriods],
  );

  // Get the currently selected work period ID for radio buttons
  const selectedWorkPeriodId = selectedWorkPeriods.length > 0 ? selectedWorkPeriods[0].id : '';

  // Helper function to check if a work period is selected for checkboxes
  const isWorkPeriodSelected = useCallback(
    (workPeriodId: string) => {
      return selectedWorkPeriods.some((wp) => wp.id === workPeriodId);
    },
    [selectedWorkPeriods],
  );

  // Helper function to format dates
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  return (
    <FilterContainer>
      <Select
        data={formattedBoards}
        value={selectedBoardId || null}
        onChange={(value: string | null) => handleBoardSelect(value)}
        placeholder="Select a board"
        searchable
        label="Board"
      />

      {selectedBoardId && (
        <>
          <Divider orientation="vertical" />
          {!isComparisonView ? (
            <Radio.Group
              value={selectedWorkPeriodType}
              onChange={(value) => handleWorkPeriodTypeSelect(value as WorkPeriodType)}
              name="workPeriodType"
              label="Work Period Type"
            >
              <Group mt="xs">
                <Radio value={WorkPeriodType.Defined} label="Pre-defined" />
                <Radio value={WorkPeriodType.Custom} label="Ad-hoc" />
              </Group>
            </Radio.Group>
          ) : null}

          {selectedWorkPeriodType === WorkPeriodType.Defined && workPeriods?.length > 0 && (
            <WorkPeriodsContainer>
              <WorkPeriodsLabel>Work Period{isComparisonView ? 's' : ''}</WorkPeriodsLabel>

              {isComparisonView ? (
                // Checkbox group for comparison view (multi-select)
                <div>
                  {workPeriods
                    .sort((a, b) => dayjs(b.start_date).diff(dayjs(a.start_date)))
                    .map((workPeriod: WorkPeriod) => (
                      <WorkPeriodItem key={workPeriod.id}>
                        <Checkbox
                          value={workPeriod.id}
                          checked={isWorkPeriodSelected(workPeriod.id)}
                          disabled={isCheckboxDisabled(workPeriod.id)}
                          onChange={(event) => onCheckboxWorkPeriodSelect(workPeriod.id, event.currentTarget.checked)}
                          label={
                            <LabelContainer>
                              <WorkPeriodName>{workPeriod.name}</WorkPeriodName>
                              <DateRangeText>
                                {formatDate(workPeriod.start_date)} - {formatDate(workPeriod.end_date)}
                              </DateRangeText>
                            </LabelContainer>
                          }
                          styles={{
                            label: {
                              width: '100%',
                            },
                          }}
                        />
                      </WorkPeriodItem>
                    ))}
                </div>
              ) : (
                // Radio group for assessment view (single-select)
                <Radio.Group value={selectedWorkPeriodId} onChange={onRadioWorkPeriodSelect} name="workPeriod">
                  {workPeriods
                    .sort((a, b) => dayjs(b.start_date).diff(dayjs(a.start_date)))
                    .map((workPeriod: WorkPeriod) => (
                      <WorkPeriodItem key={workPeriod.id}>
                        <Radio
                          value={workPeriod.id}
                          label={
                            <LabelContainer>
                              <WorkPeriodName>{workPeriod.name}</WorkPeriodName>
                              <DateRangeText>
                                {formatDate(workPeriod.start_date)} - {formatDate(workPeriod.end_date)}
                              </DateRangeText>
                            </LabelContainer>
                          }
                          styles={{
                            label: {
                              width: '100%',
                            },
                          }}
                        />
                      </WorkPeriodItem>
                    ))}
                </Radio.Group>
              )}
            </WorkPeriodsContainer>
          )}

          {selectedWorkPeriodType === WorkPeriodType.Custom && (
            <>
              <DatePickerInput
                label="Start Date"
                placeholder="Select start date"
                value={dateRange?.startDate}
                onChange={(date) => onDateRangeChange('startDate', date)}
                maxDate={dateRange?.endDate || undefined}
                leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
                leftSectionPointerEvents="none"
              />

              <DatePickerInput
                label="End Date"
                placeholder="Select end date"
                value={dateRange?.endDate}
                onChange={(date) => onDateRangeChange('endDate', date)}
                minDate={dateRange?.startDate || undefined}
                leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
                leftSectionPointerEvents="none"
              />

              <Button disabled={!isSubmitEnabled} onClick={handleAdHocSubmit} color={newCOLORS.darkIndigo}>
                Submit
              </Button>
            </>
          )}
        </>
      )}
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${newCOLORS.white};
  border-bottom: 1px solid ${newCOLORS.lightGray};
`;

const WorkPeriodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding-right: 8px;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;

const WorkPeriodsLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const WorkPeriodItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const WorkPeriodName = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

const DateRangeText = styled.span`
  font-size: 12px;
  color: ${newCOLORS.darkGray};
`;
