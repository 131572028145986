import { styled } from '@linaria/react';
import { LoadingOverlay, Title } from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  MeasureMetadata,
  MeasureUnits,
  MeasureValuesOverTime,
  WorkPeriod,
} from '../../../api/work-periods-client/work-periods-client.type';
import { ComboboxSelect } from '../../../components/combobox-select/combobox-select';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { newCOLORS } from '../../../styles/colors';
import { GetAvailableTargetsResponse, TransformedTarget } from '../../adherence/targets/targets-client.type';
import { TH } from '../key-measures/key-measures-over-time';
import { calculateAverage, calculateTrend, forecastNextValue } from '../measure-comparison/measure-comparison.helpers';
import { SelectedTarget } from '../measure-comparison/measure-comparison.type';
import { MeasureRow } from '../measure-comparison/measure-row';
import { toDisplayText } from '../process-analysis.helpers';
import { FormattedMeasureData, SPRINT_COMPARISON_COLORS } from './sprint-comparison-chart.types';
import { getLastNotNullValue } from './sprint-comparison-view.helpers';
import { SprintMeasureComparisonLineChart } from './sprint-measure-comparison-line-chart';

interface SprintMeasureComparisonChartProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
  targets: GetAvailableTargetsResponse;
}

export const SprintMeasureComparisonChart = ({
  customMeasures,
  measureData,
  workPeriods,
  targets,
}: SprintMeasureComparisonChartProps) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const [selectedMeasures, setSelectedMeasures] = useState<string[]>([]);
  const [selectedTrends, setSelectedTrends] = useState<string[]>([]);
  const [selectedTargets, setSelectedTargets] = useState<SelectedTarget[]>([]);
  const [visibleMeasures, setVisibleMeasures] = useState<string[]>([]);

  function getUniqueTargets(items: TransformedTarget[]): TransformedTarget[] {
    const seenIds = new Set<number | string>();
    return items.filter((item) => {
      if (seenIds.has(item.id)) {
        return false;
      }
      seenIds.add(item.id);
      return true;
    });
  }
  const flatTargets = getUniqueTargets(Object.values(targets).flat());

  useEffect(() => {
    const defaultMeasures = ['sprint_throughput', 'sprint_velocity'].filter((measure) =>
      customMeasures.some((m) => m.measure_name === measure),
    );
    if (defaultMeasures.length > 0 && selectedMeasures.length === 0) {
      setSelectedMeasures(defaultMeasures);
      setVisibleMeasures(defaultMeasures);
    }
  }, [customMeasures, selectedMeasures.length]);

  const measureColorMap = useMemo<Record<string, string>>(
    () =>
      customMeasures.reduce(
        (acc, measure, index) => {
          acc[measure.measure_name] = SPRINT_COMPARISON_COLORS[index % SPRINT_COMPARISON_COLORS.length];
          return acc;
        },
        {} as Record<string, string>,
      ),
    [customMeasures],
  );

  const measureMetadataMap = customMeasures.reduce(
    (acc, measure) => {
      acc[measure.measure_name] = measure;
      return acc;
    },
    {} as Record<string, MeasureMetadata>,
  );

  const measureUnitMap = customMeasures.reduce(
    (acc, measure) => {
      acc[measure.measure_name] = measure.measure_units;
      return acc;
    },
    {} as Record<string, MeasureUnits>,
  );

  // Format data for all measures, including calculations
  const formattedData = useMemo(() => {
    const data: Record<string, FormattedMeasureData> = {};

    for (const measure of selectedMeasures) {
      const values = workPeriods.map((workPeriod) => {
        const sprintData = measureData[workPeriod.id]?.[measure];
        const value = sprintData ? getLastNotNullValue(sprintData) : null;
        return value ?? 0;
      });

      const nonNullValuesOverTime: MeasureValuesOverTime = workPeriods.reduce((acc, workPeriod, index) => {
        acc[workPeriod.id] = values[index];
        return acc;
      }, {} as MeasureValuesOverTime);

      const isZeroValid = measureMetadataMap[measure]?.is_zero_valid ?? true;
      const regressionResult = calculateTrend(nonNullValuesOverTime, isZeroValid);

      data[measure] = {
        values,
        average: calculateAverage(nonNullValuesOverTime, isZeroValid),
        trend: regressionResult ? regressionResult.slope : null,
        forecast: forecastNextValue(regressionResult, nonNullValuesOverTime),
      };
    }

    return data;
  }, [selectedMeasures, measureData, workPeriods, measureMetadataMap]);

  const handleDownload = () => canvasHtmlDownload('sprint-measure-comparison', downloadRef, exceptionRef);

  const availableMeasureOptions = customMeasures
    .filter((measure) => !selectedMeasures.includes(measure.measure_name))
    .map((measure) => ({
      value: measure.measure_name,
      label: measure.measure_title,
    }));

  const handleAddMeasure = (measure: string) => {
    setSelectedMeasures([...selectedMeasures, measure]);
    setVisibleMeasures([...visibleMeasures, measure]);
  };

  const handleRemoveMeasure = (measure: string) => {
    setSelectedMeasures(selectedMeasures.filter((m) => m !== measure));
    setSelectedTrends(selectedTrends.filter((m) => m !== measure));
    setSelectedTargets(selectedTargets.filter((t) => t.measureId !== measure));
    setVisibleMeasures(visibleMeasures.filter((m) => m !== measure));
  };

  const handleToggleLineVisibility = (measure: string, checked: boolean) => {
    if (checked) {
      setVisibleMeasures([...visibleMeasures, measure]);
    } else {
      setVisibleMeasures(visibleMeasures.filter((m) => m !== measure));
    }
  };

  return (
    <div
      ref={downloadRef}
      style={{ backgroundColor: newCOLORS.white, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '15px' }}
    >
      <LoadingOverlay visible={!measureData} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
      <TitleContainer>
        <Title order={2}>Sprint Measure Comparison</Title>
        <DownloadPNGButton handleDownload={handleDownload} exceptionRef={exceptionRef} />
      </TitleContainer>
      <ContentContainer>
        <LegendContainer>
          <LegendScrollContainer>
            <LegendTable>
              <thead>
                <tr>
                  <StickyTH>Measures</StickyTH>
                  <StickyTH>Line</StickyTH>
                  <StickyTH>Average</StickyTH>
                  <StickyTH></StickyTH>
                </tr>
                <tr style={{ height: '16px' }}></tr>
              </thead>
              <tbody>
                {selectedMeasures.map((measure) => {
                  const measureMetadata = customMeasures.find((m) => m.measure_name === measure);
                  const color = measureColorMap[measure];
                  const measureData = formattedData[measure];
                  const measureTargets = flatTargets.filter((t) => t.measure === measure);
                  return (
                    <MeasureRow
                      key={measure}
                      measure={measure}
                      title={measureMetadata?.measure_title || toDisplayText(measure)}
                      description={measureMetadata?.measure_description}
                      color={color}
                      isZeroValid={measureMetadata?.is_zero_valid ?? true}
                      average={measureData.average}
                      trend={measureData.trend}
                      forecast={measureData.forecast}
                      targets={measureTargets}
                      selectedMeasures={visibleMeasures}
                      selectedTrends={selectedTrends}
                      selectedTargets={selectedTargets}
                      setSelectedMeasures={(event) => {
                        handleToggleLineVisibility(measure, event.currentTarget.checked);
                      }}
                      setSelectedTrends={(event) => {
                        const { checked } = event.currentTarget;
                        if (checked) {
                          setSelectedTrends([...selectedTrends, measure]);
                        } else {
                          setSelectedTrends(selectedTrends.filter((m) => m !== measure));
                        }
                      }}
                      setSelectedTargets={setSelectedTargets}
                      removeSelection={handleRemoveMeasure}
                    />
                  );
                })}
              </tbody>
            </LegendTable>
          </LegendScrollContainer>
          {availableMeasureOptions.length > 0 && (
            <DropdownContainer>
              <ComboboxSelect
                options={availableMeasureOptions}
                onChange={(value) => {
                  handleAddMeasure(value as string);
                }}
                placeholder="Select measure"
              />
            </DropdownContainer>
          )}
        </LegendContainer>
        <ChartContainer>
          <SprintMeasureComparisonLineChart
            formattedData={formattedData}
            workPeriods={workPeriods}
            measuresWithColor={measureColorMap}
            selectedMeasures={visibleMeasures}
            measureUnitMap={measureUnitMap}
            selectedTrends={selectedTrends}
            selectedTargets={selectedTargets}
            targets={flatTargets}
          />
        </ChartContainer>
      </ContentContainer>
    </div>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
  transition: width 300ms ease;
`;

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -16px;
`;

const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 300px;
  table-layout: fixed;
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  &:nth-child(1) {
    width: 160px;
  }
  &:nth-child(2) {
    width: 50px;
  }
  &:nth-child(3) {
    width: 50px;
  }
  &:nth-child(4) {
    width: 30px;
  }
`;
