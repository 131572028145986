import { Divider, Flex } from '@mantine/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { completeHandshake, completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { Button } from '../../../components/button/button';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { UserContext } from '../../../contexts/user';
import { StepProps } from '../integrations.type';
import { SuccessView } from '../views/success-view';
import styles from '../views/views.module.css';
import { Databases } from './databases';
import { NotionResponse, NotionState } from './notion.type';
import { Summary } from './summary';

const initialState: NotionState = {
  databaseOptions: null,
  selectedDatabases: null,
};

export const Notion = ({ activeStep = 0, setActiveStep }: StepProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const [notionState, setNotionState] = useState(initialState);

  const updateState = useCallback((update: Partial<NotionState>) => {
    setNotionState((prevState) => Object.assign({}, prevState, update));
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    const userId = user?.id.toString();
    const orgId = user?.organizations[0].toString();
    if (code && userId && orgId) {
      updateState({ code, userId, orgId });
      searchParams.delete('code');
      searchParams.delete('state');
      setSearchParams(searchParams);
    }
  }, [searchParams, user, updateState, setSearchParams]);

  useEffect(() => {
    if (!notionState.databaseOptions && notionState.userId && notionState.orgId && notionState.code) {
      completeOauth(ExternalService.Notion, notionState.userId, notionState.orgId, notionState.code, '', '', {}).then(
        (data) => {
          updateState({ databaseOptions: (data as NotionResponse).databases });
        },
      );
    }
  }, [notionState.userId, notionState.orgId, notionState.code, notionState.databaseOptions, updateState]);

  const views = [
    <Databases notionState={notionState} updateState={updateState} key="databases" />,
    <Summary notionState={notionState} setActiveStep={setActiveStep} key="summary" />,
    <SuccessView key="success" />,
  ];

  const finalizeIntegration = () => {
    const orgId = user?.organizations[0]?.toString();
    if (orgId && (notionState.selectedDatabases || []).length > 0) {
      completeHandshake('notion', {
        org_id: orgId,
        databases: notionState.selectedDatabases,
      }).then(() => {
        nextStep();
      });
    }
  };

  const isNextButtonDisabled = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (notionState.selectedDatabases || []).length === 0;
      default:
        return false;
    }
  };

  const nextStep = () => setActiveStep((current: number) => (current < views.length ? current + 1 : current));
  const previousStep = () => setActiveStep((current: number) => (current > 0 ? current - 1 : current));

  return (
    <Flex direction="column">
      <BrandedLoadingOverlay visible={!notionState.databaseOptions} />
      {views[activeStep]}
      <Divider my="sm" />
      <div id={styles.backNextContainer}>
        {activeStep < 2 ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {activeStep !== 0 ? (
              <Button variant="subtle" disabled={activeStep === 0} onClick={previousStep} size="lg">
                Back
              </Button>
            ) : null}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                disabled={isNextButtonDisabled(activeStep)}
                onClick={activeStep < 1 ? nextStep : finalizeIntegration}
                size="lg"
              >
                {activeStep < 2 ? 'Next' : "Let's go!"}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Flex>
  );
};
