import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { createInitiative } from '../../api/initiative-client/initiative-client';
import { Initiative, PreSaveInitiative } from '../../api/initiative-client/initiative-client.type';
import { Portfolio } from '../../api/portfolio-client/portfolio-client.type';

/**
 * Hook to create a new initiative mutation
 *
 * @param portfolio - The portfolio to create the initiative in, or null if no portfolio is selected
 * @param options - Optional mutation options to customize behavior
 * @returns A mutation result object containing the mutation function and state
 * @throws Error if no portfolio is selected when attempting to create
 */
const useCreateInitiativeMutation = (
  portfolio: Portfolio | null,
  options?: Partial<UseMutationOptions<Initiative, Error, PreSaveInitiative>>,
): UseMutationResult<Initiative, Error, PreSaveInitiative> => {
  return useMutation({
    mutationKey: ['createInitiative', portfolio],
    mutationFn: (initiative: PreSaveInitiative) =>
      portfolio ? createInitiative(portfolio.id, initiative) : Promise.reject('No portfolio selected'),
    ...options,
  } as UseMutationOptions<Initiative, Error, PreSaveInitiative>);
};

export { useCreateInitiativeMutation };
