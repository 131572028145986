import { LegendProps } from 'recharts';
import { graphCambridgeBlue, inkDark, secondaryBase } from '../../../../../../styles/design-tokens';

type Props = LegendProps & {
  hoveredItem: string | null;
  setHoveredItem: (item: string | null) => void;
};

export const LineChartLegend = ({ hoveredItem, setHoveredItem, ...props }: Props) => {
  const { payload } = props;

  if (!payload) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        color: inkDark,
        fontWeight: 500,
      }}
    >
      {payload.map((entry) => {
        const isHovered = hoveredItem === entry.dataKey;

        let color = entry.color;
        let bgColor = 'transparent';

        if (entry.dataKey === 'total') {
          color = secondaryBase;
          bgColor = 'rgba(92, 92, 255, 0.1)';
        } else if (entry.dataKey === 'completed') {
          color = graphCambridgeBlue;
          bgColor = 'rgba(76, 217, 100, 0.1)';
        } else if (entry.dataKey === 'projection') {
          color = '#AAAAAA';
          bgColor = 'rgba(170, 170, 170, 0.1)';
        }

        return (
          <div
            key={`legend-item-${entry.dataKey}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '6px 12px',
              borderRadius: '16px',
              backgroundColor: isHovered ? bgColor : 'transparent',
              transition: 'background-color 0.2s',
            }}
            onMouseEnter={() => setHoveredItem(entry.dataKey as string)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px',
                marginRight: '10px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: '6px',
                  width: '8px',
                  height: '2px',
                  backgroundColor: color,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: color,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  right: '6px',
                  width: '8px',
                  height: '2px',
                  backgroundColor: color,
                }}
              />
            </div>
            <span>{entry.value}</span>
          </div>
        );
      })}
    </div>
  );
};
