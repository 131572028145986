import { Group, Stack } from '@mantine/core';
import { RiskAnalysisPullRequest } from '../../containers/adherence/risk-analysis/risk-analysis.client.type';
import { inkLighter, secondaryBase, skyDark } from '../../styles/design-tokens';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  pullRequest: RiskAnalysisPullRequest | null;
  onClose: () => void;
};

export const RiskAnalysisPullRequestSideSection = ({ pullRequest, onClose }: Props) => {
  return (
    <Stack>
      <Group justify="space-between" style={{ position: 'relative' }}>
        <a
          href={pullRequest?.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          <Group gap="xs" style={{ marginRight: '32px' }}>
            <Text weight="bold" size="medium" color={secondaryBase}>
              {pullRequest?.title ? pullRequest.title.replace(/[<>]/g, '') : ''}
            </Text>
          </Group>
        </a>
        <Icon
          name="close"
          onClick={onClose}
          size={20}
          color={skyDark}
          style={{ position: 'absolute', right: 0, top: 0 }}
        />
      </Group>
      <Divider />
      <Stack gap="xs">
        {[
          { label: 'Repository', value: pullRequest?.repository.name },
          { label: 'State', value: pullRequest?.state },
          { label: 'Created Date', value: pullRequest?.created_at?.split('T')[0] || '-' },
          { label: 'Closed Date', value: pullRequest?.closed_at?.split('T')[0] || '-' },
        ].map(({ label, value }) => (
          <Group key={label} justify="space-between">
            <Text size="small" color={inkLighter}>
              {label}
            </Text>
            <Text size="small" color={inkLighter}>
              {value}
            </Text>
          </Group>
        ))}
      </Stack>
      <Divider />
      <Text weight="medium" size="medium">
        Risk Summary
      </Text>
      <Text size="small">{pullRequest?.flora_annotations?.executive_summary || 'N/A'}</Text>
      <Divider />
      <Text weight="medium" size="medium">
        Action Plan
      </Text>
      <Text size="small">{pullRequest?.flora_annotations?.action_plan || 'N/A'}</Text>
    </Stack>
  );
};
