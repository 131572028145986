import { Flex } from '@mantine/core';
import { ReactNode } from 'react';
import { MainSection } from './main-section';
import { OverviewSection } from './overview-section';
import { SideFilters } from './side-filters';
import { SideSection } from './side-section';

type Props = {
  controlBar: ReactNode;
  overview?: {
    title?: string;
    content: ReactNode;
    collapsible?: boolean;
    isEmpty?: boolean;
    emptyLabel?: string;
  };
  tabs?: ReactNode;
  sideFilters: {
    title?: string;
    content: ReactNode;
    show?: boolean;
    setShow?: (show: boolean) => void;
  };
  mainSection: ReactNode;
  sideSection?: ReactNode;
  showSideSection?: boolean;
};

export const PageLayout = ({
  controlBar,
  overview,
  tabs,
  sideFilters,
  mainSection,
  sideSection,
  showSideSection = false,
}: Props) => {
  return (
    <Flex direction="column" gap={16} miw={1000} h="100%">
      {controlBar}
      {overview && (
        <OverviewSection
          title={overview.title}
          content={overview.content}
          collapsible={overview.collapsible}
          isEmpty={overview.isEmpty}
          emptyLabel={overview.emptyLabel}
        />
      )}
      {tabs}
      <Flex direction="row" gap={16} style={{ flex: 1, minHeight: 0 }}>
        <SideFilters
          title={sideFilters.title}
          content={sideFilters.content}
          show={sideFilters.show}
          setShow={sideFilters.setShow}
        />
        <MainSection content={mainSection} />
        {showSideSection && sideSection && <SideSection content={sideSection} />}
      </Flex>
    </Flex>
  );
};
