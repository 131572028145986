import { QueryObserverResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useProcessGraph, useProcessMapping } from '../../api/process-client/process-client.hooks';
import { GraphData, ProcessMapping } from '../../api/process-client/process-client.type';
import { TaskType } from '../tasks-table/tasks-table.type';
import { ProcessContext } from './context/process.context';
import { ComparisonBoard, ComparisonTeam, ProcessViewMode } from './process.type';

/**
 * Returns the process scope from the ProcessContext.
 *
 * @returns {Object} The process scope object and setter.
 */
const useProcessScope = () => {
  const { scope, setScope } = useContext(ProcessContext);

  return {
    ...scope,
    setScope,
  };
};

/**
 * Hook for fetching data in single view mode.
 * Retrieves the process mapping and graph data for a single team view.
 *
 * @param {Object} params - The parameters for fetching process data
 * @param {Object|null} params.workflow - The selected workflow object with id
 * @param {Object|null} params.team - The selected team object with id
 * @param {Object|null} params.board - The selected board object with id
 * @param {TaskType|null} params.taskType - Type of tasks to filter by
 * @param {Object|null} params.epic - The selected epic with id
 * @param {string|null} params.startDate - Start date for the date range filter
 * @param {string|null} params.endDate - End date for the date range filter
 * @param {boolean} params.useBloomfilterSections - Whether to use bloomfilter sections
 * @returns {Object} Object containing mapping, graphData and query objects
 */
const useSingleViewData = (params: {
  team?: { id: string } | null;
  board?: { id: string } | null;
  taskType: TaskType | null;
  epic?: { id: string } | null;
  workflow?: { id: string } | null;
  startDate: string | null;
  endDate: string | null;
  useBloomfilterSections: boolean;
}): {
  mapping: ProcessMapping | undefined;
  graphData: GraphData | undefined;
  query: QueryObserverResult<GraphData>;
} => {
  const { team, board, taskType, epic, workflow, startDate, endDate, useBloomfilterSections } = params;

  // Format dates properly for the API, using the same approach as in scope.tsx
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined;
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined;

  // Handle null values for taskType and epicId explicitly like in scope.tsx
  const formattedTaskType = taskType || undefined;
  const formattedEpicId = epic?.id || undefined;

  // Mapping for single view
  const { mapping } = useProcessMapping({
    teamId: team?.id,
    workflowId: workflow?.id,
    options: {
      enabled: !!team?.id || !!workflow?.id,
      queryKey: ['process-mapping', team?.id, workflow?.id] as const,
    },
  });

  // Graph data for single view with proper handling of null values
  const { graphData, query } = useProcessGraph({
    teamId: team?.id,
    workflowId: workflow?.id,
    boardId: board?.id || undefined,
    taskType: formattedTaskType,
    epicId: formattedEpicId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    useBloomfilterSections: useBloomfilterSections,
    options: {
      enabled: !!team?.id || !!workflow?.id,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      queryKey: [
        'process-graph',
        team?.id,
        workflow?.id,
        board?.id || null,
        formattedEpicId,
        formattedTaskType,
        formattedStartDate,
        formattedEndDate,
        useBloomfilterSections,
      ] as const,
    },
  });

  return {
    mapping,
    graphData,
    query,
  };
};

/**
 * Hook for fetching data for the left side in comparison view.
 * Retrieves process mapping and graph data for the left team in comparison mode.
 *
 * @param {Object} params - The parameters for fetching left side comparison data
 * @param {ComparisonTeam|null} params.team - The selected left team
 * @param {ComparisonBoard|null} params.board - The selected left board
 * @param {TaskType|null} params.taskType - Type of tasks to filter by
 * @param {Object|null} params.epic - The selected epic with id
 * @param {string|null} params.startDate - Start date for the date range filter
 * @param {string|null} params.endDate - End date for the date range filter
 * @param {ProcessViewMode} params.viewMode - The current view mode (single or comparison)
 * @returns {Object} Object containing mapping, graphData and query objects for the left team
 */
const useComparisonLeftData = (params: {
  team: ComparisonTeam | null;
  board: ComparisonBoard | null;
  taskType: TaskType | null;
  epic?: { id: string } | null;
  startDate: string | null;
  endDate: string | null;
  viewMode: ProcessViewMode;
}): {
  mapping: any;
  graphData: any;
  query: any;
} => {
  const { team, board, taskType, epic, startDate, endDate, viewMode } = params;

  // Format dates properly for the API
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined;
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined;

  // Handle null values
  const formattedTaskType = taskType || undefined;
  const formattedEpicId = epic?.id || undefined;

  // Mapping for left team in comparison view
  const { mapping } = useProcessMapping({
    teamId: team?.id,
    options: {
      enabled: !!team?.id && viewMode === ProcessViewMode.COMPARISON,
      staleTime: 1000 * 60 * 5,
      queryKey: ['process-mapping', team?.id] as const,
    },
  });

  // Graph data for left team in comparison view
  const { graphData, query } = useProcessGraph({
    teamId: team?.id,
    boardId: board?.id || undefined,
    taskType: formattedTaskType,
    epicId: formattedEpicId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    options: {
      enabled: !!team?.id && viewMode === ProcessViewMode.COMPARISON,
      staleTime: 1000 * 60 * 5,
      queryKey: [
        'process-graph',
        team?.id,
        board?.id,
        formattedTaskType,
        formattedStartDate,
        formattedEndDate,
      ] as const,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  });

  return {
    mapping,
    graphData,
    query,
  };
};

/**
 * Hook for fetching data for the right side in comparison view.
 * Retrieves process mapping and graph data for the right team in comparison mode.
 *
 * @param {Object} params - The parameters for fetching right side comparison data
 * @param {ComparisonTeam|null} params.team - The selected right team
 * @param {ComparisonBoard|null} params.board - The selected right board
 * @param {TaskType|null} params.taskType - Type of tasks to filter by
 * @param {Object|null} params.epic - The selected epic with id
 * @param {string|null} params.startDate - Start date for the date range filter
 * @param {string|null} params.endDate - End date for the date range filter
 * @param {ProcessViewMode} params.viewMode - The current view mode (single or comparison)
 * @returns {Object} Object containing mapping, graphData and query objects for the right team
 */
const useComparisonRightData = (params: {
  team: ComparisonTeam | null;
  board: ComparisonBoard | null;
  taskType: TaskType | null;
  epic?: { id: string } | null;
  startDate: string | null;
  endDate: string | null;
  viewMode: ProcessViewMode;
}): {
  mapping: any;
  graphData: any;
  query: any;
} => {
  const { team, board, taskType, epic, startDate, endDate, viewMode } = params;

  // Format dates properly for the API
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined;
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined;

  // Handle null values
  const formattedTaskType = taskType || undefined;
  const formattedEpicId = epic?.id || undefined;

  // Mapping for right team in comparison view
  const { mapping } = useProcessMapping({
    teamId: team?.id,
    options: {
      enabled: !!team?.id && viewMode === ProcessViewMode.COMPARISON,
      staleTime: 1000 * 60 * 5,
      queryKey: ['process-mapping', team?.id] as const,
    },
  });

  // Graph data for right team in comparison view
  const { graphData, query } = useProcessGraph({
    teamId: team?.id,
    boardId: board?.id || undefined,
    taskType: formattedTaskType,
    epicId: formattedEpicId,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    options: {
      enabled: !!team?.id && viewMode === ProcessViewMode.COMPARISON,
      staleTime: 1000 * 60 * 5,
      queryKey: [
        'process-graph',
        team?.id,
        board?.id,
        formattedTaskType,
        formattedStartDate,
        formattedEndDate,
      ] as const,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  });

  return {
    mapping,
    graphData,
    query,
  };
};

export { useComparisonLeftData, useComparisonRightData, useProcessScope, useSingleViewData };
