import { ReactNode, createContext, useContext, useState } from 'react';

type CommentsSidebarContextType = {
  isCommentsSidebarVisible: boolean;
  setIsCommentsSidebarVisible: (isVisible: boolean) => void;
  toggleCommentsSidebar: () => void;
};

const CommentsSidebarContext = createContext<CommentsSidebarContextType | undefined>(undefined);

export const CommentsSidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isCommentsSidebarVisible, setIsCommentsSidebarVisible] = useState(false);

  const toggleCommentsSidebar = () => {
    setIsCommentsSidebarVisible(!isCommentsSidebarVisible);
  };

  return (
    <CommentsSidebarContext.Provider
      value={{
        isCommentsSidebarVisible,
        setIsCommentsSidebarVisible,
        toggleCommentsSidebar,
      }}
    >
      {children}
    </CommentsSidebarContext.Provider>
  );
};

export const useCommentsSidebar = () => {
  const context = useContext(CommentsSidebarContext);
  if (context === undefined) {
    throw new Error('useCommentsSidebar must be used within a CommentsSidebarProvider');
  }
  return context;
};
