import { styled } from '@linaria/react';
import { Loader, Text } from '@mantine/core';
import { useState } from 'react';
import { newCOLORS } from '../../../styles/colors';
import { TaskRecord } from '../../tasks-table/tasks-table.type';
import { TaskDetail } from './task-detail';
import { TaskList } from './task-list';

export type TaskSideSectionProps = {
  tasks: TaskRecord[];
  categoryTitle: string;
  onClose: () => void;
  isLoading?: boolean;
};

export const TaskSideSection = ({ tasks, categoryTitle, onClose, isLoading = false }: TaskSideSectionProps) => {
  const [selectedTask, setSelectedTask] = useState<TaskRecord | null>(null);

  const handleSelectTask = (task: TaskRecord) => {
    setSelectedTask(task);
  };

  const handleBackToList = () => {
    setSelectedTask(null);
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader size="md" color={newCOLORS.blue} />
        <Text size="sm" color={newCOLORS.darkGray}>
          Loading task details...
        </Text>
      </LoadingContainer>
    );
  }

  if (selectedTask) {
    return <TaskDetail task={selectedTask} onClose={onClose} onBack={handleBackToList} />;
  }

  return <TaskList tasks={tasks} title={categoryTitle} onSelectTask={handleSelectTask} onClose={onClose} />;
};

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
  padding: 10px;
  background-color: ${newCOLORS.white};
  border-left: 1px solid ${newCOLORS.lightGray};
`;
