import { Initiative, InitiativeSource } from '../../../api/initiative-client/initiative-client.type';
import { icons } from '../../../assets/icons/icons';
import { iconsIntegrations } from '../../integrations/assets';

/**
 * Checks if an initiative is active based on portfolio settings.
 *
 * @param {Initiative} initiative - The initiative to check
 * @param {boolean} useEndDate - Whether to use end date for activity check
 * @return {boolean} Returns true if the initiative is active based on portfolio settings
 */
const isActiveInitiative = ({ is_active }: Initiative): boolean => {
  return is_active;
};

/**
 * Returns the icon corresponding to the provided source.
 *
 * @param {InitiativeSource} source - The source of the initiative.
 * @return {string} The icon corresponding to the provided source.
 */
const getSourceIcon = (source: InitiativeSource): string => {
  const sourceIcons = {
    [InitiativeSource.Bloomfilter]: icons.iconBloomfilter,
    [InitiativeSource.Jira]: iconsIntegrations.iconJiraCloud,
    [InitiativeSource.JDC]: iconsIntegrations.iconJiraDataCenter,
    [InitiativeSource.ADO]: iconsIntegrations.iconADO,
    [InitiativeSource.Github]: icons.iconGithub,
  };

  return sourceIcons[source];
};

/**
 * Returns the label corresponding to the provided source.
 *
 * @param {InitiativeSource} source - The source of the initiative.
 * @return {string} The label corresponding to the provided source.
 */
const getSourceLabel = (source: InitiativeSource): string => {
  const sourceLabels = {
    [InitiativeSource.Bloomfilter]: 'Bloomfilter',
    [InitiativeSource.Jira]: 'Atlassian Jira Software Cloud',
    [InitiativeSource.JDC]: 'Atlassian Jira Software Data Center',
    [InitiativeSource.ADO]: 'Microsoft Azure',
    [InitiativeSource.Github]: 'GitHub',
  };

  return sourceLabels[source];
};

export { getSourceIcon, getSourceLabel, isActiveInitiative };
