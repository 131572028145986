import * as Sentry from '@sentry/browser';
import { QueryObserverResult } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntegrations } from '../../api/integrations-client/integrations-client.hooks';
import { useOrganizations } from '../../api/organization-client/organization-client.hooks';
import { usePortfolios } from '../../api/portfolio-client/portfolio-client.hooks';
import { usePortfolioProjects } from '../../api/summary-client/summary-client.hooks';
import { UserContext } from '../../contexts';
import { isValidAccessToken } from '../../helpers/storage/storage';
import { useAuthStore } from '../../store/auth-store/auth-store';
import { useGlobalStore } from '../../store/global-store/global-store';
import { initGlobalState, setTeams } from '../../store/global-store/global-store.actions';
import { setIntegrations } from '../../store/integrations-store/integrations-store.actions';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import { initProcessAnalysisState } from '../../store/process-analysis-store/process-analysis-store.actions';

/**
 * Initializes the data for the application.
 *
 * @return {void}
 */
const useDataInitializer = (): void => {
  const { user } = useContext(UserContext);
  const initializedGlobalState = useGlobalStore((state) => state.initialized);
  const signUpInitialized = useAuthStore((state) => state.signUpInitialized);

  const isValidAccess = isValidAccessToken();
  const dataFetchingEnabled =
    isValidAccess && !initializedGlobalState && !!user?.terms_and_conditions_accepted && !signUpInitialized;

  const { organizations = [], query: organizationsQuery } = useOrganizations({ enabled: dataFetchingEnabled });
  const { portfolios = [], query: portfoliosQuery } = usePortfolios({ enabled: dataFetchingEnabled });

  // Initialize global state
  useEffect(() => {
    if (organizations.length && portfolios.length && !initializedGlobalState) {
      initGlobalState(organizations, portfolios);
    }
  }, [organizations, portfolios, initializedGlobalState]);

  useHandleErrors(organizationsQuery);
  useHandleErrors(portfoliosQuery);

  useTeamsInitializer();
  useProcessAnalysisInitializer();
  useIntegrationsInitializer();
};

/**
 * Initializes the teams for the selected portfolio.
 *
 * @return {void}
 */
const useTeamsInitializer = (): void => {
  const portfolio = useGlobalStore((state) => state.portfolio);

  const isValidAccess = isValidAccessToken();

  const { projects: teams, query } = usePortfolioProjects(portfolio?.id, {
    enabled: Boolean(portfolio?.id) && isValidAccess,
    staleTime: 1000 * 60 * 5,
  });

  // Initialize portfolio teams
  useEffect(() => {
    if (teams) {
      setTeams(teams);
    }
  }, [teams]);

  useHandleErrors(query);
};

/**
 * Initializes the process analysis state by setting the information based on teams and marking the state as initialized.
 *
 * @return {void}
 */
const useProcessAnalysisInitializer = (): void => {
  const initializedProcessAnalysisState = useProcessAnalysisStore((state) => state.initialized);
  const teams = useGlobalStore((state) => state.teams);

  const shouldInitialize = teams.length > 0 && !initializedProcessAnalysisState;

  // Initialize process analysis state
  useEffect(() => {
    if (shouldInitialize) {
      initProcessAnalysisState(teams);
    }
  }, [shouldInitialize, teams]);
};

/**
 * Initializes the integrations by fetching the integrations from the server and setting them in the integrations store.
 *
 * @return {void}
 */
const useIntegrationsInitializer = (): void => {
  const signUpInitialized = useAuthStore((state) => state.signUpInitialized);

  const isValidAccess = isValidAccessToken();
  const { data: integrations, query } = useIntegrations({ enabled: isValidAccess && !signUpInitialized });

  useEffect(() => {
    if (integrations) {
      setIntegrations(integrations);
    }
  }, [integrations]);

  useEffect(() => {
    if (query.isError) {
      Sentry.captureException(query.error);
    }
  }, [query.isError, query.error]);
};

/**
 * Handles errors from a query by redirecting to the out-of-bloom page with the error state.
 *
 * @param {QueryObserverResult<T>} query - The result of the query.
 * @return {void}
 */
const useHandleErrors = <T>(query: QueryObserverResult<T>): void => {
  const navigate = useNavigate();

  useEffect(() => {
    if (query.isError) {
      const error = query.error as Error;
      const errorState = {
        errorMessage: error?.message || 'Unknown error',
        errorStatus: 500,
      };
      navigate(`/application/out-of-bloom`, { state: errorState });
    }
  }, [query, navigate]);
};

export { useDataInitializer };
