import { TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';

enum TargetComparison {
  LT = 'lt',
  LTE = 'lte',
  EQ = 'eq',
  GTE = 'gte',
  GT = 'gt',
  RANGE_IN = 'range_in',
  RANGE_EX = 'range_ex',
}

type Relationship = {
  id: string;
  name: string;
  start_date?: string;
  end_date?: string;
};

type Target = {
  id: string;
  organization: string;
  name: string | null;
  start_date: string;
  end_date: string | null;
  time_allocation_type: TimeAllocationType | null;
  is_active: boolean;
  use_transform: boolean;
  measure: string;
  target_value: number;
  target_value_upper: number | null;
  target_comparison: TargetComparison;
  is_work_period_target: boolean;
  portfolios: Relationship[];
  projects: Relationship[];
  subprojects: Relationship[];
  initiatives: Relationship[];
};

type TargetPayload = Omit<Target, 'portfolios' | 'projects' | 'subprojects' | 'initiatives'> & {
  portfolios: string[];
  projects: string[];
  subprojects: string[];
  initiatives: string[];
};

type TransformedTarget = {
  id: string;
  name: string | null;
  measure: string;
  start_date: string;
  end_date: string | null;
  use_transform: boolean;
  target_comparison: TargetComparison;
  target_value: number;
  target_value_upper: number | null;
  time_allocation_type: TimeAllocationType | null;
  time_allocations: Record<string, number | null | { value_lower: number; value_upper: number }>;
};

type GetAvailableTargetsResponse = Record<string, TransformedTarget[]>;

type GetAvailableTargetsPayload = {
  organization_id: string;
  portfolio_ids?: string;
  project_ids?: string;
  subproject_ids?: string;
  work_period_ids?: string;
  initiative_ids?: string;
  start_date: string;
  end_date: string;
  time_allocation_type: TimeAllocationType;
  measure?: string;
};

export { TargetComparison };
export type {
  GetAvailableTargetsPayload,
  GetAvailableTargetsResponse,
  Relationship,
  Target,
  TargetPayload,
  TransformedTarget,
};
