import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { AuditLogResponse, TaskFlowHistoryType } from '../../../api/sprints-client/sprints-client.type';
import { TaskAuditLogEntry } from '../../../api/tasks-client/task-client.type';
import { fetchTaskAuditLog } from '../../../api/tasks-client/tasks-client';
import { useWorkPeriodsMeasureSingular } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  HealthScoreData,
  Measure,
  ModuleType,
  Transformer,
  UseWorkPeriodsMeasurePayloadSingular,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useHistoricalBurns } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { WorkPeriodType } from '../process-analysis.type';
import { getLastNotNullValue } from '../sprint-comparison-view/sprint-comparison-view.helpers';
import { phaseMetadataMap } from './assessment-view.data';
import { FlowData, KeyProcessMeasuresData, StateKey } from './assessment-view.type';
/**
 * Custom hook to fetch data for the assessment view.
 *
 * @returns {object} - An object containing the score data, key measures data, flow data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if any of the data is being fetched.
 */
const useAssessmentViewData = (): {
  scoreData: HealthScoreData | undefined;
  keyMeasuresData: KeyProcessMeasuresData | undefined;
  flowData: FlowData | undefined;
  isLoading: boolean;
} => {
  const { data: scoreData, isFetching: isFetchingScoreData } = useSprintPerformanceScoreData();
  const { data: keyMeasuresData, isFetching: isFetchingKeyMeasuresData } = useKeyProcessMeasuresData();
  const { data: flowData, isFetching: isFetchingFlowData } = useFlowData();

  const isLoading = isFetchingScoreData || isFetchingKeyMeasuresData || isFetchingFlowData;

  return {
    scoreData,
    keyMeasuresData,
    flowData,
    isLoading,
  };
};

/**
 * Custom hook to fetch the sprint performance score data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useSprintPerformanceScoreData = (): { data: HealthScoreData | undefined; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const payload = usePayload([Measure.HealthScores]);
  const { data, query } = useWorkPeriodsMeasureSingular<HealthScoreData>({
    payload: {
      ...payload,
      transformer: Transformer.SingleLastNonNull,
    },
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch the key process measures data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useKeyProcessMeasuresData = (): { data: KeyProcessMeasuresData; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const options = { enabled: !!workPeriodBoardId };

  const lastValueIndexedPayload1 = usePayload([Measure.Strategy, Measure.Complexity]);
  const { data: lastValueIndexedData1, query: lastValueIndexedQuery1 } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...lastValueIndexedPayload1,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  const lastValueIndexedPayload2 = usePayload([Measure.Quality, Measure.Independence]);
  const { data: lastValueIndexedData2, query: lastValueIndexedQuery2 } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...lastValueIndexedPayload2,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  const singleTransformerPayload = usePayload([Measure.ReviewTime, Measure.DeclinedChangeRequests]);
  const { data: singleTransformerData, query: singleTransformerQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...singleTransformerPayload,
      transformer: Transformer.SingleFractionalValue,
    },
    options,
  });

  const cumulativeTransformerPayload = usePayload([Measure.ScopeCreep]);
  const { data: cumulativeTransformerData, query: cumulativeTransformerQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...cumulativeTransformerPayload,
      transformer: Transformer.CumulativeSingularValue,
    },
    options,
  });

  const readinessPayload = usePayload([Measure.Readiness]);
  const { data: readinessData, query: readinessQuery } = useWorkPeriodsMeasureSingular<
    Record<string, Record<string, number>>
  >({
    payload: {
      ...readinessPayload,
      is_custom: true,
      module_type: ModuleType.WorkPeriod,
      transformer: Transformer.ReturnFirstMetricOnly,
    },
    options,
  });
  const lastReadinessValueRaw = readinessData?.readiness as Record<string, number>;
  let lastReadinessValue = null;
  if (lastReadinessValueRaw) {
    lastReadinessValue = getLastNotNullValue(lastReadinessValueRaw);
  }

  const isFetching =
    lastValueIndexedQuery1.isFetching ||
    lastValueIndexedQuery2.isFetching ||
    singleTransformerQuery.isFetching ||
    cumulativeTransformerQuery.isFetching ||
    readinessQuery.isFetching;
  const data = {
    ...lastValueIndexedData1,
    ...lastValueIndexedData2,
    ...singleTransformerData,
    ...cumulativeTransformerData,
    ...{ [Measure.Readiness]: lastReadinessValue },
  } as KeyProcessMeasuresData;

  return { data, isFetching };
};

/**
 * Custom hook to fetch the flow data.
 *
 * @returns {object} - An object containing the fetched data and an isLoading flag.
 * The data is fetched from the API and is only returned if the fetching is completed.
 * The isLoading flag is true if the data is being fetched.
 */
const useFlowData = (): { data: FlowData | undefined; isFetching: boolean } => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const showHistoricalBurns = useHistoricalBurns();

  const secondaryMeasures = [Measure.HistoricalBurns];

  const flowByPhasePayload = usePayload([Measure.FlowByPhase]);
  const { data: flowByPhaseData, query: flowByPhaseQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...flowByPhasePayload,
    },
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  const flowByPacePayload = usePayload([Measure.FlowByPace]);
  const { data: flowByPaceData, query: flowByPaceQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...flowByPacePayload,
    },
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  const burnsPayload = usePayload([Measure.Burns]);
  const { data: burnsData, query: burnsQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...burnsPayload,
    },
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  const primaryData = { ...flowByPhaseData, ...flowByPaceData, ...burnsData };
  const primaryQuery = {
    isFetching: flowByPhaseQuery.isFetching || flowByPaceQuery.isFetching || burnsQuery.isFetching,
  };

  const secondaryMeasuresPayload = usePayload(secondaryMeasures);
  const { data: secondaryData, query: secondaryQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...secondaryMeasuresPayload,
    },
    options: {
      enabled: !!workPeriodBoardId && showHistoricalBurns,
    },
  });

  const data = { ...primaryData, ...secondaryData };
  const isFetching = primaryQuery.isFetching || secondaryQuery.isFetching;

  return { data, isFetching };
};

/**
 * Custom hook to fetch the flow filters as the endpoint expects few
 * extra parameters.
 *
 * @param {Measure[]} measures - The measures to fetch the filters for.
 * @returns {object | null} - An object containing the filters.
 */
const useFlowFilters = (measures: Measure[]): { measure_filter: string } | null => {
  const [searchParams] = useSearchParams();
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);

  if (measures.includes(Measure.FlowByPhase) || measures.includes(Measure.FlowByPace)) {
    const factor = searchParams.get('factor') || 'ready';
    const date = searchParams.get('date') || workPeriod?.start_date;

    const factor_map = phaseMetadataMap;

    const stateFilter = factor_map[factor as StateKey];

    const filters = {
      measure_filter: `${stateFilter},${date}`,
    };

    return filters;
  }

  return null;
};

/**
 * Custom hook to fetch the task flow data.
 *
 * @returns {object} An object containing the fetched data and an isFetching flag.
 * @property {TaskFlowHistoryType | undefined} data - The task flow data fetched from the API.
 * @property {boolean} isFetching - A flag indicating whether the data is currently being fetched.
 *
 * This hook uses the useWorkPeriodsMeasureSingular hook to fetch the TaskFlow measure.
 * It is enabled only when a workPeriodBoardId is available.
 */
const useTaskFlowData = (): { data: TaskFlowHistoryType | undefined; isFetching: boolean } => {
  const payload = usePayload([Measure.TaskFlow]);
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const { data, query } = useWorkPeriodsMeasureSingular<TaskFlowHistoryType>({
    payload: {
      ...payload,
    },
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch the audit log data.
 *
 * @returns {object} An object containing the fetched data and an isFetching flag.
 * @property {AuditLogResponse | undefined} data - The audit log data fetched from the API.
 * @property {boolean} isFetching - A flag indicating whether the data is currently being fetched.
 *
 * This hook uses the useWorkPeriodsMeasureSingular hook to fetch the AuditLog measure.
 * It is enabled only when a workPeriodBoardId is available.
 */

const useAuditLogData = (): { data: AuditLogResponse | undefined; isFetching: boolean } => {
  const payload = usePayload([Measure.AuditLog]);
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const { data, query } = useWorkPeriodsMeasureSingular<AuditLogResponse>({
    payload,
    options: {
      enabled: !!workPeriodBoardId,
    },
  });

  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch the task audit log data.
 *
 * @param {string} taskId - The ID of the task to fetch audit log data for.
 * @returns {object} An object containing the fetched data and an isFetching flag.
 * @property {TaskAuditLogEntry[] | undefined} data - The task audit log data fetched from the API.
 * @property {boolean} isFetching - A flag indicating whether the data is currently being fetched.
 *
 * This hook uses the useQuery hook to fetch the AuditLog for a specific task.
 * It is enabled only when a valid taskId is provided.
 */

const useTaskAuditLogData = (taskId: string): { data: TaskAuditLogEntry[] | undefined; isFetching: boolean } => {
  const query = useQuery({
    queryKey: ['taskAuditLog', taskId] as const,
    queryFn: async () => {
      if (!taskId) {
        throw new Error('Task ID is required');
      }
      const data = await fetchTaskAuditLog(taskId);
      if (!data) {
        throw new Error('Error while fetching task audit log');
      }
      return data;
    },
    enabled: !!taskId,
    staleTime: 1000 * 60 * 5,
  });
  return { data: query.data, isFetching: query.isFetching };
};

/**
 * Custom hook to create the payload for fetching measures data.
 * The payload depends on the work period type.
 * If the work period type is predefined (e.g. sprint, increment etc),
 * the payload contains the work period ID and its start and end dates.
 * Otherwise, the payload contains the start and end dates of the custom period date range.
 *
 * @param {Measure[]} - An array of measures to be fetched.
 * @returns {UseWorkPeriodsMeasurePayloadSingular} - The payload for fetching measures data.
 */
const usePayload = (measures: Measure[]): UseWorkPeriodsMeasurePayloadSingular => {
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);

  const { startDate, endDate } = useDateRange();

  const defaultPayload = {
    subproject_id: workPeriodBoardId,
    measure_name: measures,
  };

  const customPayload =
    workPeriodType === WorkPeriodType.Defined
      ? {
          work_period_id: workPeriod?.id,
          start_date: dayjs(workPeriod?.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(workPeriod?.end_date).format('YYYY-MM-DD'),
        }
      : {
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        };

  const payload = {
    ...defaultPayload,
    ...customPayload,
  } as UseWorkPeriodsMeasurePayloadSingular;

  return payload;
};

export { useAssessmentViewData, useAuditLogData, useFlowFilters, useTaskAuditLogData, useTaskFlowData };
