import { Group, Switch } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { useGetWorkflows } from '../../api/workflows-client/workflows.client.hooks';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { PageLayout } from '../../components/page-layout/page-layout';
import { useGlobalStore } from '../../store/global-store/global-store';
import { baseWhite, secondaryBase, skyDark } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Icon } from '../../ui-library/icon/icon';
import { Tooltip } from '../../ui-library/tooltip/tooltip';
import { H2, Text } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { ProcessProvider } from '../process/context/process.context';
import { filterProcessMapping, getViewTypeTitle } from '../process/process.helpers';
import { useProcessScope, useSingleViewData } from '../process/process.hooks';
import { ViewType } from '../process/process.type';
import { ViewToggle } from '../process/scope/view-toggle';
import { WorkflowViewToggle } from '../process/scope/workflow-view-toggle';
import { ProcessV2FilterPanel } from './filters/process-v2-filter-panel';
import { ProcessOverview } from './process-overview';
import { SectionGraph } from './section-graph';

const VALUE_TYPE_ICONS = {
  [ViewType.Count]: 'looks_one',
  [ViewType.Percentage]: 'percent',
  [ViewType.CycleTime]: 'access_time',
  [ViewType.Cost]: 'attach_money',
};

export function ProcessV2() {
  const organizationId = useGlobalStore((state) => state.organization)?.id || '';
  const { portfolio, team, workflow, board, taskType, epic, startDate, endDate, useWorkflow, useGroups, setScope } =
    useProcessScope();

  // Local state to store selected dates before applying
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(startDate ? dayjs(startDate).toDate() : null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDate ? dayjs(endDate).toDate() : null);
  const [isDatesDirty, setIsDatesDirty] = useState(false);
  const [displayType, setDisplayType] = useState<'map' | 'table'>('map');

  const { data: workflows } = useGetWorkflows(organizationId);

  // Update local state when scope dates change
  useEffect(() => {
    setSelectedStartDate(startDate ? dayjs(startDate).toDate() : null);
    setSelectedEndDate(endDate ? dayjs(endDate).toDate() : null);
  }, [startDate, endDate]);

  // Handle date selection without immediately updating scope
  const handleDateChange = (value: [Date | null, Date | null]) => {
    setSelectedStartDate(value[0]);
    setSelectedEndDate(value[1]);
    setIsDatesDirty(true);
  };

  // Apply the selected dates to the scope when Apply button is clicked
  const handleApplyDates = () => {
    const formattedStartDate = selectedStartDate ? dayjs(selectedStartDate).toISOString() : null;
    let formattedEndDate = null;

    if (selectedEndDate) {
      const endDateRaw = dayjs(selectedEndDate);
      const isCurrentMonth = endDateRaw.isSame(dayjs(), 'month');
      formattedEndDate = isCurrentMonth ? dayjs().toISOString() : endDateRaw.endOf('month').toISOString();
    }

    setScope({ startDate: formattedStartDate, endDate: formattedEndDate });
    setIsDatesDirty(false);
  };

  const header = (
    <Group>
      <Icon name="map" color={secondaryBase} size={36} />
      <H2>Process</H2>

      <MonthPickerInput
        type="range"
        radius="xl"
        style={{ width: '300px' }}
        leftSection={<Icon name="calendar_month" color={secondaryBase} size={16} />}
        leftSectionPointerEvents="none"
        placeholder="Pick date range"
        value={[selectedStartDate, selectedEndDate]}
        onChange={handleDateChange}
        maxDate={dayjs().toDate()}
        clearable
      />
      <Button
        onClick={handleApplyDates}
        variant="secondary"
        radius="xl"
        size="xs"
        disabled={!isDatesDirty}
        style={{ border: !isDatesDirty ? `1px solid ${skyDark}` : 'none' }}
      >
        Apply Dates
      </Button>
    </Group>
  );

  const paramWorkflow = useWorkflow
    ? workflow
    : board && !useGroups
      ? (workflows?.find((w) => w.subprojects.includes(board?.id)) ?? null)
      : null;

  // Use useSingleViewData with proper parameters based on useWorkflow mode
  const { mapping, graphData, query } = useSingleViewData({
    team: useWorkflow ? null : paramWorkflow !== null ? null : team,
    board: board,
    taskType: taskType,
    epic: epic,
    workflow: paramWorkflow,
    startDate,
    endDate,
    useBloomfilterSections: useGroups,
  });

  const filteredMapping = useMemo(() => filterProcessMapping(mapping), [mapping]);

  const [viewType, setViewType] = useState<ViewType>(ViewType.Count);

  // The initialScope with complete and properly typed objects
  const initialProcessScope = useMemo(
    () => ({
      portfolio,
      team: team ? { ...team } : null,
      board: board ? { ...board } : null,
      epic: epic ? { ...epic } : null,
      workflow: workflow ? { ...workflow } : null,
      startDate: startDate || '',
      endDate: endDate || '',
      taskType,
      useWorkflow,
    }),
    [portfolio, team, board, epic, startDate, endDate, taskType, workflow, useWorkflow],
  );

  // Create a unique key for the ProcessProvider to force remounting when team, board, or workflow changes
  const providerKey = useMemo(
    () =>
      `process-provider-${useWorkflow ? 'workflow' : 'team'}-${useWorkflow ? workflow?.id || 'no-workflow' : team?.id || 'no-team'}-${board?.id || 'no-board'}`,
    [team?.id, board?.id, workflow?.id, useWorkflow],
  );

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={query.isPending} variant="colored" />
      <PageLayout
        controlBar={null}
        overview={{
          title: 'KPIs Overview',
          content: <ProcessOverview graphData={graphData} />,
        }}
        sideFilters={{ content: <ProcessV2FilterPanel displayType={displayType} setDisplayType={setDisplayType} /> }}
        mainSection={
          <ProcessProvider key={providerKey} initialScope={initialProcessScope}>
            <Group style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Group gap="xs" style={{ marginBottom: 16 }}>
                {Object.values(ViewType).map((value) => (
                  <Button
                    size="xs"
                    radius="xl"
                    key={value}
                    variant={value === viewType ? 'secondary' : 'outline'}
                    onClick={() => setViewType(value)}
                    leftSection={
                      <Icon
                        name={VALUE_TYPE_ICONS[value]}
                        color={value === viewType ? baseWhite : secondaryBase}
                        size={16}
                      />
                    }
                  >
                    {getViewTypeTitle(value)}
                  </Button>
                ))}
              </Group>
              <Group gap="xs">
                <Tooltip
                  variant="black"
                  label={
                    !useWorkflow && !board
                      ? 'Select a Workflow or Board to view without groups.'
                      : 'Toggle to view with or without workflow groups'
                  }
                >
                  <Group gap="xs">
                    <Text size="tiny">Use Groups</Text>

                    <Switch
                      checked={useGroups}
                      onChange={(event: any) => setScope({ useGroups: event.currentTarget.checked })}
                      color={secondaryBase}
                      disabled={!useWorkflow && !board}
                    />
                  </Group>
                </Tooltip>

                <Text size="tiny">Full View</Text>
                {mapping &&
                  (useWorkflow ? (
                    <WorkflowViewToggle mapping={mapping} useGroups={useGroups} />
                  ) : (
                    <ViewToggle mapping={mapping} />
                  ))}
              </Group>
            </Group>
            <ReactFlowProvider>
              <div style={{ height: 'calc(100% - 45px)' }}>
                <SectionGraph
                  graphData={graphData}
                  mapping={filteredMapping}
                  viewType={viewType}
                  useGroups={useGroups}
                />
              </div>
            </ReactFlowProvider>
          </ProcessProvider>
        }
      />
    </MenuDrivenContainer>
  );
}
