import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, isValidElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../../assets/icons/icons';
import { newCOLORS } from '../../../styles/colors';

type Props = {
  title: string;
  url?: string;
  onClick?: () => void;
  score: React.ReactNode;
  data: { label: string; value: string | React.ReactNode }[];
  isOpened?: boolean;
  epicId?: string;
};

export function CollapsibleTile({ title, url, score, data, isOpened, epicId, onClick }: Props) {
  const [opened, { toggle }] = useDisclosure(isOpened);
  const navigate = useNavigate();
  const icon = opened ? 'icon-park-solid:down-one' : 'icon-park-solid:right-one';
  return (
    <Container>
      <CollapseIcon>
        <Icon icon={icon} width={16} height={16} color={newCOLORS.indigo} onClick={toggle} />
      </CollapseIcon>
      <ContentContainer>
        <HeaderContainer>
          <Title>
            {epicId ? (
              <Fragment>
                <TitleText onClick={() => navigate(`tasks/?filter=epic&epicId=${epicId}`)}>{title}</TitleText>
                <img
                  alt="icon"
                  src={icons.iconShare}
                  onClick={() => {
                    window.open(url, '_blank', 'noopener, noreferrer');
                  }}
                />
              </Fragment>
            ) : (
              <TitleText onClick={() => (url ? navigate(url) : onClick ? onClick() : null)}>{title}</TitleText>
            )}
          </Title>
          {score}
        </HeaderContainer>
        <Collapse in={opened}>
          <DataContainer>
            {data.map((item, index) => (
              <DataItem key={`${item.label}-${index}`}>
                <DataLabel>{item.label}</DataLabel>
                {item.label === 'Completed tasks' && epicId ? (
                  <ClickableDate onClick={() => navigate(`tasks/?filter=epic&epicId=${epicId}`)}>
                    {isValidElement(item.value) ? item.value : <DataValue>{item.value}</DataValue>}
                  </ClickableDate>
                ) : isValidElement(item.value) ? (
                  item.value
                ) : (
                  <DataValue>{item.value}</DataValue>
                )}
              </DataItem>
            ))}
          </DataContainer>
        </Collapse>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 8px;
  align-items: start;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid ${newCOLORS.lightGray};
  box-sizing: border-box;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex: 1;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const TitleText = styled.div`
  color: ${newCOLORS.indigo};
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  max-width: 280px;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  font-weight: 800;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataLabel = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  text-transform: uppercase;
`;

const DataValue = styled.div`
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
`;

const ClickableDate = styled.div`
  cursor: pointer;
`;
