import { styled } from '@linaria/react';
import { Radio as MantineRadio, RadioProps } from '@mantine/core';
import { forwardRef } from 'react';
import { secondaryBase } from '../../styles/design-tokens';

interface Props extends RadioProps {
  label?: React.ReactNode;
  error?: React.ReactNode;
  description?: React.ReactNode;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ color = secondaryBase, label, error, description, ...props }, ref) => {
    return (
      <StyledRadio
        variant="outline"
        ref={ref}
        color={color}
        label={label}
        error={error}
        description={description}
        {...props}
      />
    );
  },
);

const StyledRadio = styled(MantineRadio)`
  .mantine-Radio-radio {
    cursor: pointer;
    
    &:checked {
      background-color: var(--base-white);
      border-color: var(--secondary-base);
      border: 2px solid var(--secondary-base);
    }
    
    &:hover {
      border-color: var(--secondary-light);
    }
  }
  
  .mantine-Radio-inner {
    position: relative;
    
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      fill: var(--secondary-base);
    }
  }
  
  .mantine-Radio-label {
    cursor: pointer;
    padding-left: 8px;
  }
  
  &[data-disabled] {
    .mantine-Radio-radio, .mantine-Radio-label {
      cursor: not-allowed;
    }
    
    .mantine-Radio-radio {
      background-color: var(--sky-lighter);
      border-color: var(--sky-base);
    }
  }
`;
