import { CommentUser } from '../../api/comments-client/comments.type';
import { UserInfo } from '../../api/user-client/user-client.type';

/**
 * Map a user to a comment user
 *
 * @param {UserInfo | null} user - The user to map to a comment user
 * @param {string | undefined} organizationId - The ID of the organization to map the user to
 * @returns {CommentUser} The mapped comment user
 */
const mapUserToCommentUser = (user: UserInfo | null, organizationId: string | undefined): CommentUser => {
  return {
    userId: user?.id || '',
    displayName: user?.name || '',
    email: user?.email || '',
    organizationId: organizationId || '',
  };
};

export { mapUserToCommentUser };
