import { Group } from '@mantine/core';
import { Dispatch } from 'react';
import { OrganizationStatusesByProjectsAndBoards } from '../../../../../../api/organization-client/organization-client.type';
import { Workflow, WorkflowPayload } from '../../../../../../api/workflows-client/workflows-client.type';
import i18n from '../../../../../../base-dictionary';
import { useGlobalStore } from '../../../../../../store/global-store/global-store';
import { Button } from '../../../../../../ui-library/button/button';
import { Icon } from '../../../../../../ui-library/icon/icon';
import { Text } from '../../../../../../ui-library/typography/typography';
import { EditableTextField } from '../../../../editable-text-field';
import { WorkflowAction } from '../../shared-components/reducer';
import { HeaderContainer } from '../../shared-components/styles';
import { getNewWorkflowName } from '../../workflows.helpers';

export function CreateWorkflowHeader({
  state,
  dispatch,
  setPageState,
  isDirty,
  handleSelectWorkflow,
  statusesByProjectsAndBoards,
  workflows,
  createWorkflow,
}: {
  state: Workflow;
  dispatch: Dispatch<WorkflowAction>;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
  isDirty: boolean;
  handleSelectWorkflow: (
    workflow: Workflow,
    statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined,
    dispatch: Dispatch<WorkflowAction>,
  ) => void;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
  createWorkflow: (payload: WorkflowPayload, organizationId: string) => void;
}) {
  const organization = useGlobalStore((state) => state.organization);

  return (
    <HeaderContainer>
      <EditableTextField
        value={(() => {
          if (state.name) {
            return state.name;
          }
          return getNewWorkflowName(workflows || []);
        })()}
        canEdit
        isOpen
        onSave={(value: string) => {
          dispatch({
            type: 'UPDATE_FIELD',
            field: 'name',
            value,
          });
        }}
        onCancel={() => {}}
      />
      <Group>
        <Button
          variant="outline"
          size="xs"
          radius="xl"
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={() => {
            if (isDirty) {
              if (
                window.confirm(
                  "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
                )
              ) {
                const selectedWorkflow = (workflows || []).find((workflow) => workflow.id === state.id) || workflows[0];
                dispatch({
                  type: 'SET_WORKFLOW',
                  payload: selectedWorkflow ?? state,
                });
                setPageState('idle');
                if (selectedWorkflow) {
                  handleSelectWorkflow(selectedWorkflow, statusesByProjectsAndBoards, dispatch);
                }
              }
            } else {
              const selectedWorkflow = (workflows || []).find((workflow) => workflow.id === state.id) || workflows[0];
              dispatch({
                type: 'SET_WORKFLOW',
                payload: selectedWorkflow ?? state,
              });
              setPageState('idle');
              if (selectedWorkflow) {
                handleSelectWorkflow(selectedWorkflow, statusesByProjectsAndBoards, dispatch);
              }
            }
          }}
        >
          <Text>{i18n.t('common.actions.cancel')}</Text>
        </Button>
        <Button
          disabled={!isDirty || !state.name}
          variant="primary"
          size="xs"
          radius="xl"
          leftSection={<Icon name="check_circle_outline" variant="filled" size={16} color="white" />}
          style={{ padding: '8px 16px', gap: '8px' }}
          onClick={() => {
            createWorkflow(state, organization?.id || '');
          }}
        >
          <Text>{i18n.t('common.actions.save')}</Text>
        </Button>
      </Group>
    </HeaderContainer>
  );
}
