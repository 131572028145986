import { Group, Stack } from '@mantine/core';
import { RiskAnalysisTask } from '../../containers/adherence/risk-analysis/risk-analysis.client.type';
import { inkLighter, secondaryBase, skyDark } from '../../styles/design-tokens';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  task: RiskAnalysisTask | null;
  onClose: () => void;
};
export function RiskAnalysisTaskSideSection({ task, onClose }: Props) {
  return (
    <Stack>
      <Group justify="space-between">
        <a
          href={task?.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          <Group gap="xs">
            <Text weight="bold" size="medium" color={secondaryBase}>
              {task?.code}
            </Text>
            <Icon name="arrow_outward" size={16} color={secondaryBase} />
          </Group>
        </a>
        <Icon name="close" onClick={onClose} size={20} color={skyDark} />
      </Group>
      <Divider />
      <Text weight="medium" size="medium">
        {task?.name}
      </Text>
      <Divider />
      <Stack gap="xs">
        {[
          { label: 'Owner', value: task?.reporter?.name },
          { label: 'Created', value: task?.created_date },
          { label: 'Type', value: task?.type },
          { label: 'Points', value: task?.points || '-' },
        ].map(({ label, value }) => (
          <Group key={label} justify="space-between">
            <Text size="small" color={inkLighter}>
              {label}
            </Text>
            <Text size="small" color={inkLighter}>
              {value}
            </Text>
          </Group>
        ))}
      </Stack>
      <Divider />
      <Text weight="medium" size="medium">
        Risk Summary
      </Text>
      <Text size="small">{task?.flora_annotations?.executive_summary || 'N/A'}</Text>
      <Divider />
      <Text weight="medium" size="medium">
        Action Plan
      </Text>
      <Text size="small">{task?.flora_annotations?.action_plan || 'N/A'}</Text>
    </Stack>
  );
}
