import { useState } from 'react';

import { Group, Modal, Stack, TextInput } from '@mantine/core';

import { styled } from '@linaria/react';

import i18n from '../../../../../../base-dictionary';
import { baseWhite, inkLight, redBase, skyDark } from '../../../../../../styles/design-tokens';
import { Button } from '../../../../../../ui-library/button/button';
import { Icon } from '../../../../../../ui-library/icon/icon';
import { Text } from '../../../../../../ui-library/typography/typography';
export function DeleteWorkflowModal({
  workflowName,
  opened,
  onClose,
  onConfirm,
}: {
  workflowName: string;
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [value, setValue] = useState('');
  const deleteButtonDisabled = value !== workflowName;
  return (
    <CustomModal title="Delete Workflow" opened={opened} onClose={onClose} centered>
      <Stack>
        <Text>To confirm the deletion of your workflow, please type its name:</Text>
        <Text color={redBase} weight="medium">
          {workflowName}
        </Text>
        <TextInput
          required
          placeholder={`Enter ${i18n.t('data_management.workflows.workflow')} Name`}
          label={`${i18n.t('data_management.workflows.workflow')} Name`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Group style={{ marginTop: 24 }}>
          <Button
            variant="outline"
            size="sm"
            radius="xl"
            style={{ flex: 1, padding: '8px 16px', gap: '8px' }}
            leftSection={<Icon name="highlight_off" size={16} color={inkLight} />}
            onClick={() => {
              setValue('');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="warning"
            disabled={deleteButtonDisabled}
            size="sm"
            radius="xl"
            style={{ flex: 2, padding: '8px 16px', gap: '8px' }}
            leftSection={<Icon name="delete_outline" size={16} color={deleteButtonDisabled ? skyDark : baseWhite} />}
            onClick={() => {
              setValue('');
              onConfirm();
            }}
          >
            Confirm and Delete
          </Button>
        </Group>
      </Stack>
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .mantine-Modal-header {
    padding: 0;
    h2 {
      font-size: 1.5em;
      font-weight: 600;
    }
  }

  .mantine-Modal-body {
    padding: 0;
  }

  .mantine-Modal-content {
    padding: 40px;
    border-radius: 16px;
  }
`;
