import { MeasureMetadata, MeasureUnits } from '../../../../api/work-periods-client/work-periods-client.type';
import { TargetComparison } from '../../../adherence/targets/targets-client.type';

/**
 * Returns the appropriate stroke dash array for a target line based on the comparison type
 *
 * @param {string | undefined} comparison The target comparison type
 * @returns {string} Stroke dash array for the target line
 */
const getTargetStrokeDashArray = (comparison: string | undefined): string => {
  if (!comparison) return '0, 0';

  switch (comparison) {
    case TargetComparison.GT:
    case TargetComparison.LT:
    case TargetComparison.RANGE_EX:
      return '5 5';
    default:
      return '0, 0';
  }
};

/**
 * Returns the appropriate fill for a target area based on the comparison type
 *
 * @param {string} comparison The target comparison type
 * @param {string} color The base color for the fill
 * @returns {string | undefined} Fill configuration for the target area
 */
const getTargetFill = (comparison: string | undefined, color: string): string | undefined => {
  if (!comparison) return undefined;

  if (comparison === TargetComparison.EQ) return 'transparent';

  // Add opacity (33 = 20% opacity in hex)
  const fillColor = `${color}33`;

  return fillColor;
};

/**
 * Returns the appropriate fill position for a target area based on the comparison type
 *
 * @param {string} comparison The target comparison type
 * @returns {string | undefined} Fill position ('above' or 'below')
 */
const getTargetFillPosition = (comparison: string | undefined): 'above' | 'below' | undefined => {
  if (!comparison) return undefined;

  switch (comparison) {
    case TargetComparison.GT:
    case TargetComparison.GTE:
      return 'below';
    case TargetComparison.LT:
    case TargetComparison.LTE:
      return 'above';
    default:
      return undefined;
  }
};

/**
 * Creates chart data by flattening measure data and adding target values
 *
 * @param {Record<string, Record<string, number | null>>[]} data Array of records containing measure data organized by measure name and date
 * @param {MeasureMetadata[]} measureMetadata Array of metadata for each measure including target information
 * @returns {Record<string, any>[]} Array of data points with measure values and target values organized by date
 */
const createChartData = (
  data: (Record<string, Record<string, number | null>> | undefined)[],
  measureMetadata: MeasureMetadata[],
): Record<string, any>[] => {
  const flattenedData: Record<string, Record<string, number | null>> = {};
  data.forEach((dataItem) => {
    if (dataItem) {
      Object.entries(dataItem).forEach(([measureName, dateValues]) => {
        flattenedData[measureName] = dateValues;
      });
    }
  });

  const allDates = new Set<string>();
  Object.values(flattenedData || {}).forEach((dateValues) => {
    Object.keys(dateValues || {}).forEach((date) => allDates.add(date));
  });

  const sortedDates = Array.from(allDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const chartData = sortedDates.map((date) => {
    const dataPoint: Record<string, any> = { date };

    Object.entries(flattenedData).forEach(([measureName, dateValues]) => {
      dataPoint[measureName] = dateValues[date] ?? null;
    });

    return dataPoint;
  });

  measureMetadata.forEach((measure) => {
    measure?.targets?.forEach((target) => {
      if (
        target.target_comparison === TargetComparison.RANGE_IN ||
        target.target_comparison === TargetComparison.RANGE_EX
      ) {
        Object.entries(target.time_allocations).forEach(([date, timeAllocation]) => {
          chartData.forEach((dataPoint) => {
            if (dataPoint.date === date) {
              if (typeof timeAllocation === 'object' && timeAllocation !== null) {
                dataPoint[`${measure.measure_name}_${target.id}`] = [
                  timeAllocation.value_lower,
                  timeAllocation.value_upper,
                ];
              }
            }
          });
        });
      } else {
        Object.entries(target.time_allocations).forEach(([date, timeAllocation]) => {
          chartData.forEach((dataPoint) => {
            if (dataPoint.date === date) {
              dataPoint[`${measure.measure_name}_${target.id}`] = timeAllocation;
            }
          });
        });
      }
    });
  });

  return chartData;
};

/**
 * Determines which Y-axis to use based on measure units
 *
 * @param {string} measureName The name of the measure
 * @param {Record<string, MeasureUnits>} measureUnitMap Map of measure names to their units
 * @returns {string} Y-axis ID ('left' for percentage, 'right' for other units)
 */
const getYAxisId = (measureName: string, measureUnitMap: Record<string, MeasureUnits>): string => {
  const measureUnit = measureUnitMap[measureName];
  return measureUnit === MeasureUnits.Percentage ? 'left' : 'right';
};

/**
 * Calculates trend line points for a measure
 *
 * @param measureName The name of the measure
 * @param {Record<string, { slope: number; intercept: number }>} trendLines Map of trend lines with slope and intercept
 * @param {Record<string, any>[]} chartData The chart data array
 * @returns {Record<string, any>[] | null} Array of data points for the trend line or null if no trend line exists
 */
const getTrendLinePoints = (
  measureName: string,
  trendLines: Record<string, { slope: number; intercept: number }>,
  chartData: any[],
): Record<string, any>[] | null => {
  if (!trendLines[measureName]) return null;

  const { slope, intercept } = trendLines[measureName];

  return chartData.map((_, index) => ({
    date: chartData[index].date,
    [`${measureName}_trend`]: Math.round((slope * index + intercept) * 100) / 100,
  }));
};

export {
  createChartData,
  getTargetFill,
  getTargetFillPosition,
  getTargetStrokeDashArray,
  getTrendLinePoints,
  getYAxisId,
};
