import { Measure } from '../../../api/work-periods-client/work-periods-client.type';
import { Tab } from '../process-analysis.type';
import { KeyMeasuresSelectionAggregated } from './key-measures-over-time.type';

/**
 * Returns the default selection for the key measures section.
 *
 * @return {KeyMeasuresSelectionAggregated} The default selection for the key measures section.
 */
const getDefaultKeyMeasuresSelection = (): KeyMeasuresSelectionAggregated => {
  const selection = {
    measure: Measure.Complexity,
    selectedEntities: [],
    selectedTrends: [],
    selectedTargets: [],
  };

  return {
    [Tab.Portfolios]: selection,
    [Tab.Teams]: selection,
    [Tab.Boards]: selection,
  };
};

export { getDefaultKeyMeasuresSelection };
