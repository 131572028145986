import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { ActionIcon, Loader, Paper, Text, TextInput, Tooltip, UnstyledButton } from '@mantine/core';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { icons } from '../../assets/icons/icons';
import { ChatHistoryPanel } from '../../components/chat-history-panel/chat-history-panel';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { useGlobalStore } from '../../store/global-store/global-store';
import { newCOLORS } from '../../styles/colors';
import { H3 } from '../../ui-library/typography/typography';
import { useWebsocketHandlers } from './flora-hooks';
import { useFlora } from './flora.context';
import { ChatHistory } from './flora.types';
export const FloraAside = ({ onClose }: { onClose?: () => void }) => {
  const { useAI } = useGlobalStore();
  const orgId = useOrganizationId();
  const [isActiveQuestion, setIsActiveQuestion] = useState(false);
  const [messages, setMessages] = useState<string[]>(['']);
  const [question, setQuestion] = useState('');
  const [threadId, setThreadId] = useState('');
  const [questions, setQuestions] = useState<string[]>([]);
  const [isWaitingForFirstResponse, setIsWaitingForFirstResponse] = useState(false);
  const [chatHistories, setChatHistories] = useState<ChatHistory[]>([]);
  const [isHistoryOpen, { open: openHistory, close: closeHistory }] = useDisclosure(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const clipboard = useClipboard();
  const { currentExternalQuestion, setCurrentExternalQuestion } = useFlora();

  // biome-ignore lint/correctness/useExhaustiveDependencies(setThreadId): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(setQuestions): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(setMessages): ignore
  const handleNewChat = useCallback(() => {
    setThreadId('');
    setQuestions([]);
    setMessages(['']);
    setCurrentExternalQuestion(null);
  }, [setThreadId, setQuestions, setMessages, setCurrentExternalQuestion]);

  const { runAgent, fetchChatHistories, handleDeleteHistory, handleEditHistory } = useWebsocketHandlers(
    orgId,
    useAI,
    threadId,
    setThreadId,
    setChatHistories,
    setIsActiveQuestion,
    setIsWaitingForFirstResponse,
    setMessages,
    handleNewChat,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(isHistoryOpen): ignore
  useEffect(() => {
    const cleanupFetch = fetchChatHistories();
    if (cleanupFetch) {
      return cleanupFetch;
    }
  }, [isHistoryOpen, fetchChatHistories]);

  useEffect(() => {
    if (threadId && chatHistories.length > 0) {
      const selectedHistory = chatHistories.find((history) => history.id === threadId);
      if (selectedHistory) {
        const previousQuestions = selectedHistory.messages
          .filter((msg) => msg.role === 'user')
          .map((msg) =>
            msg.content.includes('Analyze the following data and provide a summary of the key findings.')
              ? 'Analyzing your data...'
              : msg.content,
          );
        const previousAnswers = selectedHistory.messages
          .filter((msg) => msg.role === 'assistant')
          .map((msg) => msg.content);
        setQuestions(previousQuestions);
        setMessages(previousAnswers.concat(['']));
      }
    }
  }, [threadId, chatHistories]);

  const autoScroll = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies(messages): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(questions): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(autoScroll): ignore
  useEffect(() => {
    autoScroll();
  }, [messages, questions]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(setQuestions): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(setQuestion): ignore
  const submitQuestion = useCallback(
    async (externalQuestion?: string) => {
      if (question || externalQuestion) {
        const cleanupAgent = runAgent(externalQuestion || question);
        if (question) {
          setQuestions([...questions, question]);
        }
        if (externalQuestion) {
          setQuestions([...questions, 'Analyzing your data...']);
        }
        setQuestion('');
        if (cleanupAgent) {
          return cleanupAgent;
        }
      }
    },
    [runAgent, questions, setQuestions, setQuestion, question],
  );

  // Using useRef to prevent double execution of the effect in React.StrictMode
  // React.StrictMode intentionally double-invokes effects in development to help
  // find bugs, but in this case we want the submitQuestion to run only once
  // when there's an external question. No other solutions (like useEffect cleanup
  // or dependencies array modification) help prevent the double execution.
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current && currentExternalQuestion && !isActiveQuestion) {
      setCurrentExternalQuestion(null);
      submitQuestion(currentExternalQuestion);
      hasRun.current = true;
    }
  }, [currentExternalQuestion, isActiveQuestion, submitQuestion, setCurrentExternalQuestion]);

  return (
    <FloraAsideContainer>
      <Header>
        <TitleSection>
          <img src={icons.iconFlora} alt="Flora AI Assistant" />
          <H3 style={{ fontWeight: 1000, fontSize: 20, color: newCOLORS.black }}>Flora</H3>
          <Text style={{ fontSize: 10, color: newCOLORS.gray, marginTop: 4 }}>AI Assistant</Text>
        </TitleSection>
        <ControlsSection>
          <Tooltip label={isActiveQuestion ? 'Please wait for response...' : 'New Chat'} zIndex={600}>
            <ControlButton onClick={handleNewChat} disabled={isActiveQuestion}>
              <Icon icon="material-symbols:add-circle-outline" />
            </ControlButton>
          </Tooltip>
          <Tooltip label={isActiveQuestion ? 'Please wait for response...' : 'Chat History'} zIndex={600}>
            <ControlButton onClick={isHistoryOpen ? closeHistory : openHistory} disabled={isActiveQuestion}>
              <Icon icon="material-symbols:schedule-outline" />
            </ControlButton>
          </Tooltip>
          <Tooltip label="Close Flora" zIndex={600}>
            <ControlButton onClick={onClose}>
              <Icon icon="material-symbols:close" />
            </ControlButton>
          </Tooltip>
        </ControlsSection>
      </Header>
      <MessageContainer>
        {questions.map((q, index) => (
          <MessageWrapper key={index}>
            <QuestionPaper>
              <Icon icon="material-symbols:chat-bubble-outline" style={{ color: newCOLORS.gray3 }} />
              <Text size="sm">{q}</Text>
            </QuestionPaper>
            {messages[index] && (
              <AnswerPaper>
                <Tooltip label={clipboard.copied ? 'Copied!' : 'Copy to clipboard'}>
                  <CopyButton onClick={() => clipboard.copy(messages[index])}>
                    <Icon
                      icon={clipboard.copied ? 'material-symbols:check' : 'material-symbols:content-copy-outline'}
                      style={{
                        color: '#666',
                        width: 16,
                        height: 16,
                      }}
                    />
                  </CopyButton>
                </Tooltip>
                <ReactMarkdown skipHtml>{messages[index]}</ReactMarkdown>
              </AnswerPaper>
            )}
          </MessageWrapper>
        ))}
        {isWaitingForFirstResponse && (
          <LoadingWrapper>
            <Loader size="sm" color={newCOLORS.purple2} />
            <LoadingText>Flora is thinking...</LoadingText>
          </LoadingWrapper>
        )}
        {!isActiveQuestion && <div ref={messagesEndRef} />}
      </MessageContainer>
      <InputContainer>
        <StyledTextInput
          size="md"
          onChange={(e) => setQuestion(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isActiveQuestion) {
              submitQuestion();
            }
          }}
          value={question}
          placeholder="Ask a question..."
          rightSection={
            <SendButton
              disabled={isActiveQuestion}
              color={newCOLORS.green}
              variant="filled"
              onClick={() => submitQuestion()}
            >
              <Icon icon="material-symbols:send" style={{ color: 'white' }} />
            </SendButton>
          }
        />
      </InputContainer>
      <ChatHistoryContainer isOpen={isHistoryOpen}>
        <ChatHistoryPanel
          histories={chatHistories}
          closeHistory={closeHistory}
          setThreadId={setThreadId}
          onDeleteHistory={handleDeleteHistory}
          onEditHistory={handleEditHistory}
        />
        <BackButton onClick={closeHistory}>
          <Icon icon="material-symbols:arrow-back" />
          Back
        </BackButton>
      </ChatHistoryContainer>
    </FloraAsideContainer>
  );
};

const FloraAsideContainer = styled.div`
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 16px;
  border-top: 0px;
  border-bottom: 1px solid #e9ecef;
  background: white;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
    font-weight: normal;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const ControlsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const ControlButton = styled(UnstyledButton)`
  width: 28px;
  height: 28px;
  font-size: 23px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background: #f5f5f5;
  }

  &:disabled {
    color: ${newCOLORS.gray};
    cursor: not-allowed;
  }
`;

const MessageContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding: 16px;
  background: white;
`;

const MessageWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const QuestionPaper = styled(Paper)`
  max-width: 85%;
  margin-left: auto;
  margin-right: 0;
  border-radius: 24px;
  background: white;
  color: #333;
  box-shadow: none;
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AnswerPaper = styled(Paper)`
  max-width: 85%;
  margin-right: auto;
  margin-left: 0;
  border-radius: 24px;
  background: white;
  padding: 12px 16px;
  position: relative;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white), linear-gradient(90deg, #ff8a65, #ff5252, #ea80fc, #8c9eff);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  p {
    margin: 0;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
  }

  strong {
    color: ${newCOLORS.purple2};
  }
`;

const InputContainer = styled.div`
  padding: 16px;
  background: white;
  border-top: 1px solid #e0e0e0;
`;

const StyledTextInput = styled(TextInput)`
  .mantine-TextInput-input {
    border-radius: 24px;
    border: 1px solid #e0e0e0;
    padding: 12px 48px 12px 16px;
    background: white;
    font-size: 14px;

    &:focus {
      border-color: ${newCOLORS.green};
    }

    &::placeholder {
      color: #999;
    }
  }
`;

const SendButton = styled(ActionIcon)`
  background: ${newCOLORS.darkGreen};
  border-radius: 50%;
  width: 100%;
  height: 20px;
  margin-right: 8px;

  &:hover {
    background: ${newCOLORS.green};
    opacity: 0.9;
  }

  &:disabled {
    background: #e0e0e0;
  }
`;

const CopyButton = styled(ActionIcon)`
  float: right;
  background-color: transparent;

  &:hover {
    background-color: transparent !important;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  max-width: 80%;
  margin-right: auto;
`;

const LoadingText = styled(Text)`
  color: ${newCOLORS.gray};
  font-size: 14px;
  font-style: italic;
`;

interface ChatHistoryContainerProps {
  isOpen: boolean;
}

const ChatHistoryContainer = styled.div<ChatHistoryContainerProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 200;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BackButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${newCOLORS.purple2};
  color: ${newCOLORS.white};
  justify-content: center;
  font-size: 13px;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  font-weight: 500;

  &:hover {
    background: ${newCOLORS.purple2};
    opacity: 0.7;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
