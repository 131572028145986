import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Tabs } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../../base-dictionary';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useDataManagementStore } from '../../store/data-management-store/data-management-store';
import { Tab } from '../../store/data-management-store/data-management-store.type';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../styles/design-tokens';
import { MenuDrivenContainerNoNavbar } from '../menu-driven-container/menu-driven-container-no-navbar';
import { BoardSettings } from './components/board-settings-tab/board-settings';
import { DataManagementHeader } from './components/data-management-header';
import { DataSources } from './components/data-sources-tab/data-sources';
import { Portfolios } from './components/portfolios-tab/portfolios';
import { Workflows } from './components/workflows-tab/workflows';

export function DataManagement() {
  useDocumentTitle('Data Management - Bloomfilter');

  const navigate = useNavigate();
  const activeTab = useDataManagementStore((state) => state.activeTab);
  const setActiveTab = useDataManagementStore((state) => state.setActiveTab);
  const [dataManagementIsDirty, setDataManagementIsDirty] = useState(false);

  function handleTabChange(value: Tab) {
    if (dataManagementIsDirty) {
      if (
        window.confirm(
          "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
        )
      ) {
        setDataManagementIsDirty(false);
        setActiveTab(value);
      }
    } else {
      setActiveTab(value);
    }
  }

  function handleClickBack() {
    if (dataManagementIsDirty) {
      if (
        window.confirm(
          "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
        )
      ) {
        setDataManagementIsDirty(false);
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  }

  return (
    <MenuDrivenContainerNoNavbar
      header={
        <DataManagementHeader
          title={i18n.t('data_management.data_management')}
          icon="account_tree"
          onClickBack={handleClickBack}
        />
      }
    >
      <StyledTabs variant="pills" radius="xl" value={activeTab} onChange={(value) => handleTabChange(value as Tab)}>
        <Tabs.List>
          <Tabs.Tab
            value={Tab.DataSources}
            leftSection={<Icon icon="material-symbols:data-object" />}
            color={secondaryBase}
          >
            {i18n.t('data_management.data_sources.data_sources')}
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.Portfolios}
            leftSection={<Icon icon="material-symbols:business-center" />}
            color={secondaryBase}
          >
            {i18n.t('common.portfolios')}
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.Workflows}
            leftSection={<Icon icon="material-symbols:account-tree" />}
            color={secondaryBase}
          >
            {i18n.t('data_management.workflows.workflows')}
          </Tabs.Tab>
          <Tabs.Tab
            value={Tab.BoardSettings}
            leftSection={<Icon icon="material-symbols:text-snippet" />}
            color={secondaryBase}
          >
            {i18n.t('data_management.board_settings.board_settings', {
              board: i18n.t('common.board'),
              settings: i18n.t('common.settings'),
            })}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={Tab.DataSources}>
          <DataSources />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.Portfolios}>
          <Portfolios />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.Workflows}>
          <Workflows isDirty={dataManagementIsDirty} setIsDirty={setDataManagementIsDirty} />
        </Tabs.Panel>
        <Tabs.Panel value={Tab.BoardSettings}>
          <BoardSettings />
        </Tabs.Panel>
      </StyledTabs>
    </MenuDrivenContainerNoNavbar>
  );
}

const StyledTabs = styled(Tabs)`
  .mantine-Tabs-list {
    gap: 8px;
  }

  .mantine-Tabs-tab {
    transition: all 0.2s ease;
    font-weight: 500;

    &[data-active] {
      color: ${baseWhite};
      background: ${secondaryBase};
    }

    &:not([data-active]) {
      color: ${inkLight};
      border: 1px solid ${inkLight};

      &:hover {
        background: ${skyLightest};
        border: 1px solid ${secondaryBase};
      }
    }
  }
`;
