import { newCOLORS } from '../../../../../styles/colors';
import { KeyProcessMeasuresData } from '../../../work-periods.type';

/**
 * Formats the key measures data for the key measures section
 * @param keyMeasuresData - The key measures data to format
 * @returns The formatted key measures data
 */
function formatKeyMeasuresData(keyMeasuresData: KeyProcessMeasuresData | undefined) {
  if (!keyMeasuresData) return [];

  return [
    {
      id: 'strategy',
      name: 'Strategy',
      value: keyMeasuresData.strategy != null ? `${keyMeasuresData.strategy}%` : '--',
      description: '% Tied to an epic',
      color: newCOLORS.indigo,
    },
    {
      id: 'complexity',
      name: 'Complexity',
      value: keyMeasuresData.complexity != null ? `${keyMeasuresData.complexity}%` : '--',
      description: 'Avg. estimate for pointed tasks',
      color: newCOLORS.coral,
    },
    {
      id: 'quality',
      name: 'Quality',
      value: keyMeasuresData.quality != null ? `${keyMeasuresData.quality}%` : '--',
      description: 'Bugs to share',
      color: newCOLORS.darkYellow,
    },
    {
      id: 'scope',
      name: 'Scope',
      value: keyMeasuresData.scope_creep != null ? `${keyMeasuresData.scope_creep}%` : '--',
      description: 'Tasks +/- to plan',
      color: newCOLORS.lightYellow,
    },
    {
      id: 'readiness',
      name: 'Readiness',
      value: keyMeasuresData.readiness != null ? `${keyMeasuresData.readiness}%` : '--',
      description: 'Defined tasks',
      color: newCOLORS.pink,
    },
    {
      id: 'independence',
      name: 'Independence',
      value: keyMeasuresData.independence != null ? `${keyMeasuresData.independence}%` : '--',
      description: 'Tasks with zero dependency',
      color: newCOLORS.aqua,
    },
    {
      id: 'review_time',
      name: 'Review Time',
      value: keyMeasuresData.review_time != null ? `${keyMeasuresData.review_time}%` : '--',
      description: 'Avg. days PR is open',
      color: newCOLORS.green,
    },
    {
      id: 'declined_change_requests',
      name: 'Declined Change Requests',
      value: keyMeasuresData.declined_change_requests != null ? `${keyMeasuresData.declined_change_requests}%` : '--',
      description: 'Declined change requests (%)',
      color: newCOLORS.blue,
    },
  ];
}

export { formatKeyMeasuresData };
