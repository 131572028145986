import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useProcessTasks } from '../../../api/process-client/process-client.hooks';
import { ProcessTask, WorkflowStatus } from '../../../api/process-client/process-client.type';
import i18n from '../../../base-dictionary';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageHeaderMessage } from '../../../components/page-header-message/page-header-message';
import { Title } from '../../../components/title/title';
import { useDocumentTitle } from '../../../helpers/general-helpers';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import { TasksTable } from '../../tasks-table/tasks-table';
import { TaskType } from '../../tasks-table/tasks-table.type';
import { ProcessWorkflow } from '../context/process.context.type';
import { getStageTitle } from '../process.helpers';
import { PageContainer } from '../process.styled';
import { formatTasks } from './task-details.helpers';

export function TaskDetails() {
  useDocumentTitle('Task Details - Bloomfilter');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const teamId = searchParams.get('teamId');
  const boardId = searchParams.get('boardId');
  const taskType = searchParams.get('taskType');
  const epicId = searchParams.get('epicId');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const workflowString = searchParams.get('workflow');
  const workflow: ProcessWorkflow | null = workflowString ? JSON.parse(decodeURIComponent(workflowString)) : null;

  const { taskData: tasks = [], isFetching = false } = useProcessTasks(
    {
      teamId: teamId,
      boardId: boardId,
      taskType: taskType as TaskType,
      epicId: epicId,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
      mapping: workflow?.mapping,
    },
    {
      enabled: Boolean(teamId),
      staleTime: 1000 * 60 * 5,
    },
  );

  const deduplicateTasks = (tasks: ProcessTask[]) => {
    const uniqueIds: { [key: string]: boolean } = {};
    return tasks.filter((task: ProcessTask) => {
      if (!(task.id in uniqueIds)) {
        uniqueIds[task.id] = true;
        return true;
      } else {
        return false;
      }
    });
  };

  const getHeading = useCallback(() => {
    if (!workflow) {
      return 'Task Details';
    }

    if (workflow.status === WorkflowStatus.Completed) {
      return 'Completed Task Paths';
    }

    return `${workflow.status} from ${getStageTitle(workflow.source)} to ${getStageTitle(workflow.destination)}`;
  }, [workflow]);

  useEffect(() => {
    if (!workflow) {
      navigate('/application/process');
    }
  }, [navigate, workflow]);

  const filteredTasks = deduplicateTasks(tasks);

  const showLocalError = !isFetching && !filteredTasks.length;

  const header = <Title title={getHeading()} />;

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay visible={isFetching} transitionDuration={30} variant="colored" />
      <PageContainer>
        {showLocalError && (
          <PageHeaderMessage
            message={i18n.t('financials.no_work_units', { work_units: i18n.t('common.work_units') })}
            color="red"
          />
        )}
        <TasksTable tasks={formatTasks(filteredTasks)} />
      </PageContainer>
    </MenuDrivenContainer>
  );
}
