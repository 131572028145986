import { RiskAnalysisSection } from '../risk-analysis.client.type';

import { useMemo } from 'react';
import { FilterGroup } from '../../../../components/filters/filter-panel.types';
import { RiskAnalysisInitiative, RiskAnalysisPullRequest, RiskAnalysisTask } from '../risk-analysis.client.type';
import { getRiskCategory } from '../risk-analysis.helpers';

/**
 * Custom hook that filters data based on various filter criteria
 *
 * @param {RiskAnalysisTask[] | undefined} tasks - Array of RiskAnalysisTask objects to filter
 * @param {RiskAnalysisInitiative[] | undefined} initiatives - Array of RiskAnalysisInitiative objects to filter
 * @param {RiskAnalysisTask[] | undefined} epics - Array of RiskAnalysisTask objects representing epics to filter
 * @param {RiskAnalysisPullRequest[] | undefined} pullRequests - Array of RiskAnalysisPullRequest objects to filter
 * @param {Object} filters - Object containing all filter criteria
 * @returns {Object} Object containing filtered data for tasks, initiatives, epics and pull requests
 */
const useFilteredData = (
  tasks: RiskAnalysisTask[] | undefined,
  initiatives: RiskAnalysisInitiative[] | undefined,
  epics: RiskAnalysisTask[] | undefined,
  pullRequests: RiskAnalysisPullRequest[] | undefined,
  filters: {
    creatorFilters: string[];
    projectFilters: string[];
    typeFilters: string[];
    epicFilters: string[];
    initiativeFilters: string[];
    riskCategoryFilters: string[];
    includeDone: boolean;
    activeInitiativeFilter: string;
    initiativeRiskCategoryFilters: string[];
    epicProjectFilters: string[];
    epicInitiativeFilters: string[];
    epicRiskCategoryFilters: string[];
    prRepositoryFilters: string[];
    prStateFilters: string[];
    prRiskCategoryFilters: string[];
  },
): {
  filteredTasks: RiskAnalysisTask[];
  filteredInitiatives: RiskAnalysisInitiative[];
  filteredEpics: RiskAnalysisTask[];
  filteredPullRequests: RiskAnalysisPullRequest[];
} => {
  const filteredTasks = useMemo(
    () =>
      (tasks ?? []).filter((task) => {
        const creatorMatch =
          filters.creatorFilters.length > 0 &&
          task.reporter?.name &&
          filters.creatorFilters.includes(task.reporter.name);

        const projectMatch = filters.projectFilters.length > 0 && filters.projectFilters.includes(task.project.name);

        const typeMatch = filters.typeFilters.length > 0 && filters.typeFilters.includes(task.type);

        // If no epic filters are selected, show all tasks. Otherwise, only show tasks with matching epics
        const epicMatch =
          filters.epicFilters.length === 0 || (task.epic?.name && filters.epicFilters.includes(task.epic.name));

        // If no initiative filters are selected, show all tasks. Otherwise, only show tasks with matching initiatives
        const initiativeMatch =
          filters.initiativeFilters.length === 0 ||
          (task.initiatives &&
            task.initiatives.some((initiative) => filters.initiativeFilters.includes(initiative.id)));

        const riskCategoryMatch =
          filters.riskCategoryFilters.length > 0 &&
          filters.riskCategoryFilters.includes(getRiskCategory(task.flora_annotations?.risk_score).label);

        const doneMatch = filters.includeDone ? true : task.done_date === null;

        return (
          creatorMatch && projectMatch && typeMatch && epicMatch && initiativeMatch && riskCategoryMatch && doneMatch
        );
      }),
    [tasks, filters],
  );

  const filteredInitiatives = useMemo(() => {
    return (initiatives ?? []).filter((initiative) => {
      const now = new Date();
      const startDate = initiative.start_date ? new Date(initiative.start_date) : null;
      const endDate = initiative.end_date ? new Date(initiative.end_date) : null;

      const isActive = (startDate && (!endDate || endDate > now) && startDate <= now) || initiative.is_active;
      const statusMatch = filters.activeInitiativeFilter === 'active' ? isActive : !isActive;

      const riskCategoryMatch =
        filters.initiativeRiskCategoryFilters.length === 0 ||
        filters.initiativeRiskCategoryFilters.includes(getRiskCategory(initiative.flora_annotations?.risk_score).label);

      return statusMatch && riskCategoryMatch;
    });
  }, [initiatives, filters.activeInitiativeFilter, filters.initiativeRiskCategoryFilters]);

  const filteredEpics = useMemo(() => {
    return (epics ?? []).filter((epic) => {
      const projectMatch =
        filters.epicProjectFilters.length > 0 && filters.epicProjectFilters.includes(epic.project.name);

      // If no initiative filters are selected, show all epics. Otherwise, only show epics with matching initiatives
      const initiativeMatch =
        filters.epicInitiativeFilters.length === 0 ||
        (epic.initiatives &&
          epic.initiatives.some((initiative) => filters.epicInitiativeFilters.includes(initiative.id)));

      const riskCategoryMatch =
        filters.epicRiskCategoryFilters.length > 0 &&
        filters.epicRiskCategoryFilters.includes(getRiskCategory(epic.flora_annotations?.risk_score).label);

      return projectMatch && initiativeMatch && riskCategoryMatch;
    });
  }, [epics, filters.epicProjectFilters, filters.epicInitiativeFilters, filters.epicRiskCategoryFilters]);

  const filteredPullRequests = useMemo(() => {
    return (pullRequests ?? []).filter((pr) => {
      // If no repositories are selected, show no pull requests
      if (filters.prRepositoryFilters.length === 0) {
        return false;
      }

      // If no risk categories are selected, show no pull requests
      if (filters.prRiskCategoryFilters.length === 0) {
        return false;
      }

      const repositoryMatch = filters.prRepositoryFilters.includes(pr.repository.name);
      const stateMatch = filters.prStateFilters.includes(pr.state);
      const riskCategoryMatch = filters.prRiskCategoryFilters.includes(
        getRiskCategory(pr.flora_annotations?.risk_score).label,
      );

      return repositoryMatch && stateMatch && riskCategoryMatch;
    });
  }, [pullRequests, filters.prRepositoryFilters, filters.prStateFilters, filters.prRiskCategoryFilters]);

  return {
    filteredTasks,
    filteredInitiatives,
    filteredEpics,
    filteredPullRequests,
  };
};

/**
 * Generates filter groups based on the selected section
 *
 * @param {RiskAnalysisSection} selectedSection - The section to generate filters for
 * @param {Object} params - Object containing all filter options and filters
 * @returns {FilterGroup[]} Array of filter groups
 */
const getFilterGroups = (
  selectedSection: RiskAnalysisSection,
  {
    projectOptions,
    taskTypes,
    riskCategories,
    creators,
    initiativesFilterOptions,
    epicsFilterOptions,
    initiativeFilterOptionsForEpic,
    repositoryOptions,
    filters,
  }: {
    projectOptions: { value: string; label: string }[];
    taskTypes: { value: string; label: string }[];
    riskCategories: { value: string; label: string }[];
    creators: { value: string; label: string }[];
    initiativesFilterOptions: { value: string; label: string }[];
    epicsFilterOptions: { value: string; label: string }[];
    initiativeFilterOptionsForEpic: { value: string; label: string }[];
    repositoryOptions: { value: string; label: string }[];
    filters: {
      projectFilters: string[];
      typeFilters: string[];
      riskCategoryFilters: string[];
      creatorFilters: string[];
      initiativeFilters: string[];
      epicFilters: string[];
      includeDone: boolean;
      activeInitiativeFilter: string;
      initiativeRiskCategoryFilters: string[];
      epicProjectFilters: string[];
      epicInitiativeFilters: string[];
      epicRiskCategoryFilters: string[];
      prRepositoryFilters: string[];
      prStateFilters: string[];
      prRiskCategoryFilters: string[];
      setProjectFilters: (filters: string[]) => void;
      setTypeFilters: (filters: string[]) => void;
      setRiskCategoryFilters: (filters: string[]) => void;
      setCreatorFilters: (filters: string[]) => void;
      setInitiativeFilters: (filters: string[]) => void;
      setEpicFilters: (filters: string[]) => void;
      setIncludeDone: (includeDone: boolean) => void;
      setActiveInitiativeFilter: (filter: string) => void;
      setInitiativeRiskCategoryFilters: (filters: string[]) => void;
      setEpicProjectFilters: (filters: string[]) => void;
      setEpicInitiativeFilters: (filters: string[]) => void;
      setEpicRiskCategoryFilters: (filters: string[]) => void;
      setPrRepositoryFilters: (filters: string[]) => void;
      setPrStateFilters: (filters: string[]) => void;
      setPrRiskCategoryFilters: (filters: string[]) => void;
    };
  },
): FilterGroup[] => {
  const taskFilterGroups = [
    {
      name: 'Projects',
      icon: 'house_siding',
      options: projectOptions,
      selectedValues: filters.projectFilters,
      onSelectionChange: filters.setProjectFilters,
    },
    {
      name: 'Types',
      icon: 'type_specimen',
      options: taskTypes,
      selectedValues: filters.typeFilters,
      onSelectionChange: filters.setTypeFilters,
    },
    {
      name: 'Risk Categories',
      icon: 'warning_amber',
      options: riskCategories,
      selectedValues: filters.riskCategoryFilters,
      onSelectionChange: filters.setRiskCategoryFilters,
    },
    {
      name: 'Creators',
      icon: 'person',
      options: creators,
      selectedValues: filters.creatorFilters,
      onSelectionChange: filters.setCreatorFilters,
    },
    {
      name: 'Initiatives',
      icon: 'ads_click',
      options: initiativesFilterOptions,
      selectedValues: filters.initiativeFilters,
      onSelectionChange: filters.setInitiativeFilters,
      optional: true,
    },
    {
      name: 'Epics',
      icon: 'electric_bolt',
      options: epicsFilterOptions,
      selectedValues: filters.epicFilters,
      onSelectionChange: filters.setEpicFilters,
      optional: true,
    },
    {
      name: 'General',
      icon: 'settings',
      showAll: false,
      options: [
        {
          value: 'include_done_tasks',
          label: 'Include Done Tasks',
        },
      ],
      selectedValues: filters.includeDone ? ['include_done_tasks'] : [],
      onSelectionChange: (value: string[]) => {
        filters.setIncludeDone(value.includes('include_done_tasks'));
      },
    },
  ];

  const initiativeFilterGroups = [
    {
      name: 'Status',
      icon: 'schedule',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
      ],
      selectedValues: [filters.activeInitiativeFilter],
      onSelectionChange: (values: string[]) => {
        filters.setActiveInitiativeFilter(values.length ? values.join(',') : 'active');
      },
      showAll: false,
    },
    {
      name: 'Risk Categories',
      icon: 'warning_amber',
      options: riskCategories,
      selectedValues: filters.initiativeRiskCategoryFilters,
      onSelectionChange: filters.setInitiativeRiskCategoryFilters,
    },
  ];

  const epicFilterGroups = [
    {
      name: 'Projects',
      icon: 'house_siding',
      options: projectOptions,
      selectedValues: filters.epicProjectFilters,
      onSelectionChange: filters.setEpicProjectFilters,
    },
    {
      name: 'Initiatives',
      icon: 'ads_click',
      options: initiativeFilterOptionsForEpic,
      selectedValues: filters.epicInitiativeFilters,
      onSelectionChange: filters.setEpicInitiativeFilters,
      optional: true,
    },
    {
      name: 'Risk Categories',
      icon: 'warning_amber',
      options: riskCategories,
      selectedValues: filters.epicRiskCategoryFilters,
      onSelectionChange: filters.setEpicRiskCategoryFilters,
    },
  ];

  const pullRequestFilterGroups = [
    {
      name: 'Repositories',
      icon: 'code',
      options: repositoryOptions,
      selectedValues: filters.prRepositoryFilters,
      onSelectionChange: filters.setPrRepositoryFilters,
    },
    {
      name: 'State',
      icon: 'check_circle',
      options: [
        { value: 'open', label: 'Open' },
        { value: 'closed', label: 'Closed' },
      ],
      selectedValues: filters.prStateFilters,
      onSelectionChange: filters.setPrStateFilters,
    },
    {
      name: 'Risk Categories',
      icon: 'warning_amber',
      options: riskCategories,
      selectedValues: filters.prRiskCategoryFilters,
      onSelectionChange: filters.setPrRiskCategoryFilters,
    },
  ];

  switch (selectedSection) {
    case RiskAnalysisSection.TASKS:
      return taskFilterGroups;
    case RiskAnalysisSection.INITIATIVES:
      return initiativeFilterGroups;
    case RiskAnalysisSection.EPICS:
      return epicFilterGroups;
    case RiskAnalysisSection.PULL_REQUESTS:
      return pullRequestFilterGroups;
    default:
      return [];
  }
};

export { getFilterGroups, useFilteredData };
