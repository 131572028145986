import { styled } from '@linaria/react';
import { Badge } from '@mantine/core';
import { baseWhite, primaryBase } from '../../styles/design-tokens';
import { Text } from '../typography/typography';

export const Beta = () => {
  return (
    <StyledBadge size="sm" color={primaryBase}>
      <Text size="tiny" lineHeight="none" color={baseWhite}>
        Beta
      </Text>
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  text-transform: none;
`;
