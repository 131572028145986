import { styled } from '@linaria/react';
import { AppShell } from '@mantine/core';
import { useIdentify, useSetDocumentId, VeltNotificationsTool } from '@veltdev/react';
import { Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVeltJwtToken } from '../../api/comments-client/comments-client';
import { User } from '../../components/user/user';
import { UserContext } from '../../contexts/user';
import { isString } from '../../helpers/string-helpers/string-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { secondaryBase, skyLighter } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { FloraButton } from '../flora/flora-button';
import { CommentsSidebarButton } from '../menu-driven-container/comments-sidebar-button';
import { mapUserToCommentUser } from './header.helpers';

type Props = {
  header?: string | ReactNode;
  actions?: ReactNode;
};

const isCommentsEnabled = import.meta.env.VITE_FEATURE_FLAG_COMMENTS === 'true';

export function HeaderBar({ header, actions }: Props) {
  const [veltToken, setVeltToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const currentOrganization = useGlobalStore((state) => state.organization);
  const headerComponent = isString(header) ? <H2>{header}</H2> : header;

  useEffect(() => {
    const fetchVeltToken = async () => {
      if (currentOrganization && currentOrganization.id) {
        try {
          const token = await getVeltJwtToken(currentOrganization.id);
          setVeltToken(token);
        } catch (error) {
          console.error('Failed to get Velt token:', error);
        }
      }
    };

    if (user) {
      fetchVeltToken();
    }
  }, [user, currentOrganization]);

  useIdentify(mapUserToCommentUser(user, currentOrganization?.id), { authToken: veltToken || '' });
  useSetDocumentId(currentOrganization?.id || '');
  const navigateToComment = (notificationData: any) => {
    navigate(notificationData.url);
  };

  return (
    <AppShell.Header style={{ backgroundColor: skyLighter, maxWidth: '100%', zIndex: 400 }}>
      <Container style={{ justifyContent: header ? 'space-between' : 'flex-end', gap: 8 }}>
        {headerComponent}
        <ProfileContainer>
          {actions}
          {isCommentsEnabled && (
            <Fragment>
              <CommentsSidebarButton />
              <VeltNotificationsTool
                onNotificationClick={(notification: any) =>
                  navigateToComment(notification.displayHeadlineMessageTemplateData)
                }
              />
            </Fragment>
          )}
          <FloraButton />
          <Icon name="help_outline" color={secondaryBase} size={28} onClick={() => navigate(`/application/help`)} />
          <User user={user} />
        </ProfileContainer>
      </Container>
    </AppShell.Header>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 24px 16px 0px 16px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1em;
`;
