import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { styled } from '@linaria/react';
import { Group, Skeleton, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';

import { OrganizationStatusesByProjectsAndBoards } from '../../../../../api/organization-client/organization-client.type';
import { Mapping, Step, Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import i18n from '../../../../../base-dictionary';
import { DropShadowCard } from '../../../../../components/drop-shadow-card/drop-shadow-card';
import {
  baseWhite,
  primaryBase,
  redBase,
  secondaryBase,
  skyDark,
  skyLighter,
  yellowBase,
} from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import {
  addStepToSteps,
  filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds,
  getMappedAndUnmappedStatusesForWorkflow,
  maybeRemoveMappedStatusesFromWorkflowSteps,
} from '../workflows.helpers';
import { WorkflowAction } from './reducer';
import { Status } from './statuses';

const DEFAULT_WORKFLOW_STEPS = ['Backlog', 'In Progress', 'In Review', 'In Test', 'Deployable', 'Done'];

export function WorkflowConfiguration({
  state,
  dispatch,
  statusesByProjectsAndBoards,
  isEditable,
}: {
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  isEditable: boolean;
}) {
  const statusesForWorkflowSubprojects = filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds(
    statusesByProjectsAndBoards,
    state.subprojects,
  );
  const { mappedStatuses, unmappedStatuses } = getMappedAndUnmappedStatusesForWorkflow(
    state,
    statusesForWorkflowSubprojects,
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack>
        <Group justify="space-between" style={{ paddingBottom: '16px' }}>
          <Text size="large" weight="bold">
            {i18n.t('data_management.workflows.workflow_map')}
          </Text>
          {unmappedStatuses.length > 0 ? (
            <Text
              weight="bold"
              color={redBase}
            >{`${i18n.t('data_management.workflows.unmapped')} ${i18n.t('common.status.statuses')}: (${unmappedStatuses.length})`}</Text>
          ) : mappedStatuses.length > 0 ? (
            <Group>
              <Text weight="bold" color={primaryBase}>
                {i18n.t('common.calibrated')}
              </Text>
              <Icon name="check_circle" size={24} color={primaryBase} />
            </Group>
          ) : null}
        </Group>
        <Group justify="space-between">
          <Text size="small" color={skyDark}>
            {mappedStatuses.length} {i18n.t('common.status.statuses')} {i18n.t('data_management.workflows.mapped')}
          </Text>
          {state.metadata_updated_at && (
            <Text size="small" color={skyDark}>
              {i18n.t('common.last_updated')}: {dayjs(state.metadata_updated_at).format('MMM D, YYYY [at] h:mm A')}
            </Text>
          )}
        </Group>
      </Stack>
      <div
        style={{
          flex: 1,
          display: 'flex',
          minHeight: 0,
          height: '100vh',
          overflow: 'auto',
          padding: '16px 8px',
          marginBottom: '32px',
          backgroundColor: skyLighter,
        }}
      >
        <WorkflowContainer>
          {DEFAULT_WORKFLOW_STEPS.map((stepName, index) => (
            <WorkflowStep
              key={stepName}
              stepName={stepName}
              ordinalPosition={
                index === 0 ? 'first' : index === DEFAULT_WORKFLOW_STEPS.length - 1 ? 'last' : 'internal'
              }
              state={state}
              dispatch={dispatch}
              isEditable={isEditable}
              mappings={(state.steps || []).find((step) => step.name === stepName)?.mappings}
            />
          ))}
        </WorkflowContainer>
        <WorkflowContainer>
          <WorkflowStep
            stepName="Waiting"
            ordinalPosition="waiting"
            state={state}
            dispatch={dispatch}
            isEditable={isEditable}
            mappings={(state.steps || []).find((step) => step.name === 'Waiting')?.mappings}
          />
        </WorkflowContainer>
      </div>
    </div>
  );
}

function WorkflowStep({
  stepName,
  ordinalPosition,
  mappings,
  isEditable,
  state,
  dispatch,
}: {
  stepName: string;
  ordinalPosition: 'first' | 'internal' | 'last' | 'waiting';
  mappings: Mapping[] | undefined;
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  isEditable: boolean;
}) {
  const ref = useRef(null);
  // instead of boolean, use a string to record the name of the status that is being dragged over
  // null means no status is being dragged over
  const [draggedOver, setDraggedOver] = useState<string | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(ref): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(draggedOver): ignore
  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return dropTargetForElements({
      element: el,
      getData: () => ({ stepName }),
      onDragEnter: ({ source }) => {
        setDraggedOver(source.data.status as string);
      },
      onDragLeave: () => {
        setDraggedOver(null);
      },
      onDrop: ({ source, location }) => {
        // If the drop target is not found, do not allow the drop
        if (!location.current.dropTargets[0]) {
          return;
        }

        // If the source status is found in another step, remove it from that step
        const newSteps = maybeRemoveMappedStatusesFromWorkflowSteps(state, [source.data.status as string]);

        // If the targeted step is not found, create a new step
        let targetedStep = (newSteps || []).find((step) => step && step.name === stepName);
        if (!targetedStep) {
          targetedStep = {
            name: stepName as string,
            mappings: [] as Mapping[],
          } as Step;
        }

        // If the source status is already in the target step, do not allow the drop to prevent duplicates
        if (targetedStep.mappings.some((mapping) => mapping.external_name === source.data.status)) {
          return;
        }

        // Add the source status to the target bucket step
        targetedStep.mappings.push({ external_name: source.data.status as string } as Mapping);

        dispatch({
          type: 'SET_WORKFLOW',
          payload: {
            ...state,
            steps: addStepToSteps(newSteps, targetedStep, ['Waiting', ...DEFAULT_WORKFLOW_STEPS]),
          },
        });
        setDraggedOver(null);
      },
    });
  }, [state, dispatch, stepName, ref, draggedOver]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BucketIcon ordinalPosition={ordinalPosition} />
      <div style={{ height: '2px', width: '16px', backgroundColor: skyDark }} />
      {!['last', 'waiting'].includes(ordinalPosition) ? (
        <div
          style={{
            position: 'relative',
            top: '88px',
            right: '28px',
            height: '150px',
            width: '2px',
            borderLeft: `2px dashed ${secondaryBase}`,
          }}
        >
          <Icon
            name="keyboard_arrow_down"
            variant="filled"
            size={24}
            color={secondaryBase}
            style={{ position: 'relative', top: '140px', right: '13px' }}
          />
        </div>
      ) : null}
      <DropShadowCard
        ref={ref}
        style={{
          backgroundColor: baseWhite,
          border: draggedOver ? `2px solid ${primaryBase}` : 'none',
        }}
      >
        <WorkflowStepContainer>
          <Text weight="bold" style={{ alignSelf: 'start' }}>
            {stepName}
          </Text>
          {(mappings && mappings.length) || draggedOver ? (
            (mappings || []).reduce<React.ReactNode[]>((acc, status) => {
              acc.push(
                <Status
                  key={status.id || status.external_name}
                  status={status.external_name}
                  dispatch={dispatch}
                  isDraggable={isEditable}
                />,
              );

              // Add skeleton at the end since its always pushed to last item
              if (draggedOver) {
                acc.push(<Skeleton key="drag-preview" height={32} radius={8} />);
              }

              return acc;
            }, [])
          ) : (
            <EmptyState>Empty</EmptyState>
          )}
        </WorkflowStepContainer>
      </DropShadowCard>
    </div>
  );
}

function BucketIcon({ ordinalPosition }: { ordinalPosition: 'first' | 'internal' | 'last' | 'waiting' }) {
  if (ordinalPosition === 'first') {
    return <Icon name="play_circle_outline" variant="filled" size={24} color={primaryBase} />;
  }
  if (ordinalPosition === 'last') {
    return <Icon name="check_circle_outline" variant="filled" size={24} color={secondaryBase} />;
  }
  if (ordinalPosition === 'waiting') {
    return <Icon name="pause_circle_outline" variant="filled" size={24} color={yellowBase} />;
  }
  return <Icon name="adjust" variant="filled" size={24} color={secondaryBase} />;
}

const WorkflowStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100px;
  min-width: 150px;
  padding: 8px;
`;

const EmptyState = styled.div`
  padding: 4px 8px;
  border: 1px dashed ${skyDark};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
`;

const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  background-color: ${skyLighter};
  padding: 8px;
  height: fit-content;
`;
