import { styled } from '@linaria/react';
import { Select } from '@mantine/core';
import { Portfolio } from '../../../api/portfolio-client/portfolio-client.type';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { baseWhite, skyDark } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { DetailedRepository } from '../../integrations/github/repositories.type';
import { useAddProjectsToRepository, useRemoveProjectsFromRepository } from '../data-management-client.hooks';
import { DataManagementDropShadowContainer } from './data-management-header';
import { usePortfolioProjects } from './portfolios-tab/portfolios.hooks';

type RelatedItemsSectionProps = {
  title: string;
  availableItems: Array<{ id: string; name: string }>;
  selectedItems: Array<{ id: string; name: string }>;
  onAdd: (value: string) => Promise<void>;
  onRemove: (item: any) => Promise<void>;
  isUpdating: boolean;
  addPlaceholder: string;
  nothingFoundMessage: string;
};

function RelatedItemsSection({
  title,
  availableItems,
  selectedItems,
  onAdd,
  onRemove,
  isUpdating,
  addPlaceholder,
  nothingFoundMessage,
}: RelatedItemsSectionProps) {
  return (
    <>
      <Text>{title}</Text>
      <Select
        data={availableItems.map((item) => ({ value: item.id, label: item.name }))}
        onChange={async (value) => {
          if (value) {
            await onAdd(value);
          }
        }}
        value=""
        placeholder={addPlaceholder}
        clearable
        radius="xl"
        size="sm"
        rightSection={<Icon name="add_circle_outlined" size={20} color={skyDark} />}
        style={{ marginTop: 8 }}
        nothingFoundMessage={nothingFoundMessage}
      />
      <ItemList>
        {selectedItems.map((item) => (
          <Button
            key={item.id}
            variant="secondary"
            radius="xl"
            size="sm"
            style={{
              width: 'fit-content',
              cursor: isUpdating ? 'not-allowed' : 'pointer',
              opacity: isUpdating ? 0.5 : 1,
            }}
            rightSection={
              <Icon
                name="clear"
                size={16}
                color={baseWhite}
                style={{ cursor: isUpdating ? 'not-allowed' : 'pointer', opacity: isUpdating ? 0.5 : 1 }}
              />
            }
            onClick={() => !isUpdating && onRemove(item)}
          >
            {item.name}
          </Button>
        ))}
      </ItemList>
    </>
  );
}

type ProjectInfoPanelProps = {
  project: ProjectsResponse;
  portfolios: Portfolio[];
  currentPortfolio: Portfolio | null;
  setSelectedProject: ((project: ProjectsResponse | null) => void) | ((project: string | null) => void);
  repositories: DetailedRepository[];
};

export function ProjectInfoPanel({
  project,
  portfolios,
  currentPortfolio,
  setSelectedProject,
  repositories,
}: ProjectInfoPanelProps) {
  const projectPortfolios = portfolios.filter((portfolio) => portfolio.projects.some((p) => p.id === project.id));
  const availablePortfolios = portfolios.filter((portfolio) => !projectPortfolios.some((p) => p.id === portfolio.id));
  const { addProjectToPortfolio, removeProjectFromPortfolio, isUpdating: isRemovingPortfolio } = usePortfolioProjects();

  const projectRepositories = repositories.filter((repository) => repository.projects.some((p) => p.id === project.id));
  const availableRepositories = repositories.filter(
    (repository) => !projectRepositories.some((p) => p.id === repository.id),
  );
  const currentOrganization = useGlobalStore((state) => state.organization);
  const { addProjectsToRepository, isAdding } = useAddProjectsToRepository({
    organizationId: currentOrganization?.id as string,
  });
  const { removeProjectsFromRepository, isRemoving } = useRemoveProjectsFromRepository({
    organizationId: currentOrganization?.id as string,
  });

  const handleAddProjectToRepository = async (repositoryId: string) => {
    try {
      await addProjectsToRepository(repositoryId, project.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveProjectFromRepository = async (repositoryId: string) => {
    try {
      await removeProjectsFromRepository(repositoryId, project.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveProjectFromPortfolio = async (portfolio: Portfolio, project_id: string) => {
    await removeProjectFromPortfolio(portfolio, project_id);
    if (currentPortfolio?.id === portfolio.id) {
      setSelectedProject(null);
    }
  };

  const isUpdating = isRemovingPortfolio || isRemoving || isAdding;

  return (
    <ProjectInfoPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          {project.name}
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedProject(null);
          }}
        />
      </HeaderContainer>
      <MetadataContainer>
        <Divider style={{ marginTop: 30, marginBottom: 16 }} />
        <RelatedItemsSection
          title="Related Portfolios"
          availableItems={availablePortfolios}
          selectedItems={projectPortfolios}
          onAdd={async (value) => {
            const portfolio = availablePortfolios.find((p) => p.id === value);
            if (portfolio) {
              await addProjectToPortfolio(portfolio, project.id);
            }
          }}
          onRemove={async (portfolio) => handleRemoveProjectFromPortfolio(portfolio, project.id)}
          isUpdating={isUpdating}
          addPlaceholder="Add a portfolio..."
          nothingFoundMessage="No available portfolios to add"
        />
        <Divider style={{ marginTop: 24, marginBottom: 16 }} />
        <RelatedItemsSection
          title="Related Repositories"
          availableItems={availableRepositories}
          selectedItems={projectRepositories}
          onAdd={handleAddProjectToRepository}
          onRemove={(repository) => handleRemoveProjectFromRepository(repository.id)}
          isUpdating={isUpdating}
          addPlaceholder="Add a repository..."
          nothingFoundMessage="No available repositories to add"
        />
      </MetadataContainer>
    </ProjectInfoPanelContainer>
  );
}

const ProjectInfoPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding-bottom: 16px;
`;

const ItemList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
