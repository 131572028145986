import { OrganizationStatusesByProjectsAndBoards } from '../../../../../../api/organization-client/organization-client.type';
import { Workflow, WorkflowPayload } from '../../../../../../api/workflows-client/workflows-client.type';
import { DataManagementDropShadowContainer } from '../../../data-management-header';
import { WorkflowAction } from '../../shared-components/reducer';
import { CreateWorkflowContent } from './create-workflow-content';
import { CreateWorkflowHeader } from './create-workflow-header';

export function CreateWorkflow({
  state,
  dispatch,
  setPageState,
  isDirty,
  handleSelectWorkflow,
  statusesByProjectsAndBoards,
  workflows,
  createWorkflow,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
  isDirty: boolean;
  handleSelectWorkflow: (
    workflow: Workflow,
    statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined,
    dispatch: React.Dispatch<WorkflowAction>,
  ) => void;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
  createWorkflow: (payload: WorkflowPayload, organizationId: string) => void;
}) {
  return (
    <DataManagementDropShadowContainer>
      <CreateWorkflowHeader
        state={state}
        dispatch={dispatch}
        setPageState={setPageState}
        isDirty={isDirty}
        handleSelectWorkflow={handleSelectWorkflow}
        statusesByProjectsAndBoards={statusesByProjectsAndBoards}
        workflows={workflows}
        createWorkflow={createWorkflow}
      />
      <CreateWorkflowContent
        state={state}
        dispatch={dispatch}
        statusesByProjectsAndBoards={statusesByProjectsAndBoards}
      />
    </DataManagementDropShadowContainer>
  );
}
