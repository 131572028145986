import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { ReactNode, createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Stages } from '../../../api/process-client/process-client.type';
import { TaskType } from '../../tasks-table/tasks-table.type';
import { CachedProcessScope, ProcessContextType, ProcessScope, ProcessWorkflow } from './process.context.type';

const ProcessContext = createContext({} as ProcessContextType);

interface ProcessProviderProps {
  children: ReactNode;
  initialScope?: Partial<ProcessScope>;
}

const ProcessProvider = ({ children, initialScope }: ProcessProviderProps) => {
  const [cachedScope, setCachedScope] = useState<CachedProcessScope>(
    JSON.parse(localStorage.getItem('process-scope') as string) || {
      portfolio: null,
      team: null,
      board: null,
      epic: null,
    },
  );

  const [useWorkflow, setUseWorkflow] = useState(false);

  const [startDate, setStartDate] = useState<string | null>(
    initialScope?.startDate || dayjs().subtract(1, 'month').startOf('month').toISOString(),
  );
  const [endDate, setEndDate] = useState<string | null>(initialScope?.endDate || dayjs().toISOString());
  const [taskType, setTaskType] = useState<TaskType | null>(initialScope?.taskType || null);
  const [stages, setStages] = useState<Stages[]>([]);
  const [workflow, setWorkflow] = useState<ProcessWorkflow | null>(null);
  const [useGroups, setuseGroups] = useState(true);
  // Use a ref to track initialScope changes
  const initialScopeRef = useRef(initialScope);

  // Initialize with initialScope if provided - only run once
  useEffect(() => {
    if (initialScope) {
      const newCachedScope: CachedProcessScope = { ...cachedScope };

      if (initialScope.portfolio !== undefined) {
        newCachedScope.portfolio = initialScope.portfolio;
      }

      if (initialScope.team !== undefined) {
        newCachedScope.team = initialScope.team;
      }

      if (initialScope.board !== undefined) {
        newCachedScope.board = initialScope.board;
      }

      if (initialScope.epic !== undefined) {
        newCachedScope.epic = initialScope.epic;
      }

      if (!isEqual(newCachedScope, cachedScope)) {
        setCachedScope(newCachedScope);
      }

      if (initialScope.startDate !== undefined) {
        setStartDate(initialScope.startDate);
      }

      if (initialScope.endDate !== undefined) {
        setEndDate(initialScope.endDate);
      }

      if (initialScope.taskType !== undefined) {
        setTaskType(initialScope.taskType);
      }

      if (initialScope.useWorkflow !== undefined) {
        setUseWorkflow(initialScope.useWorkflow);
      }

      if (initialScope.useGroups !== undefined) {
        setuseGroups(initialScope.useGroups);
      }

      // Store initial values
      initialScopeRef.current = initialScope;
    }
  }, [initialScope, cachedScope]); // Only run once on mount

  // Update when initialScope changes
  useEffect(() => {
    if (initialScope && initialScopeRef.current !== initialScope) {
      // Check if any values have actually changed
      let hasChanged = false;

      // Check for team/board changes
      if (JSON.stringify(initialScope.team) !== JSON.stringify(initialScopeRef.current?.team)) {
        setCachedScope((prev) => ({ ...prev, team: initialScope.team || null }));
        hasChanged = true;
      }

      if (JSON.stringify(initialScope.board) !== JSON.stringify(initialScopeRef.current?.board)) {
        setCachedScope((prev) => ({ ...prev, board: initialScope.board || null }));
        hasChanged = true;
      }

      if (JSON.stringify(initialScope.epic) !== JSON.stringify(initialScopeRef.current?.epic)) {
        setCachedScope((prev) => ({ ...prev, epic: initialScope.epic || null }));
        hasChanged = true;
      }

      // Check for date/task type changes
      if (initialScope.startDate !== initialScopeRef.current?.startDate) {
        setStartDate(initialScope.startDate || null);
        hasChanged = true;
      }

      if (initialScope.endDate !== initialScopeRef.current?.endDate) {
        setEndDate(initialScope.endDate || null);
        hasChanged = true;
      }

      if (initialScope.taskType !== initialScopeRef.current?.taskType) {
        setTaskType(initialScope.taskType || null);
        hasChanged = true;
      }

      if (initialScope.useWorkflow !== initialScopeRef.current?.useWorkflow) {
        setUseWorkflow(initialScope.useWorkflow || false);
        hasChanged = true;
      }

      if (initialScope.useGroups !== initialScopeRef.current?.useGroups) {
        setuseGroups(initialScope.useGroups || false);
        hasChanged = true;
      }

      if (hasChanged) {
        initialScopeRef.current = initialScope;
      }
    }
  }, [initialScope]);

  const setScope = useCallback(
    (newScope: Partial<ProcessScope>) => {
      let newCachedScope = { ...cachedScope };
      let hasChanged = false;

      // Handle portfolio changes
      if ('portfolio' in newScope) {
        newCachedScope = {
          ...newCachedScope,
          portfolio: newScope.portfolio === undefined ? null : newScope.portfolio,
          team: null,
          board: null,
          epic: null,
        };
        hasChanged = true;
      }

      // Handle team changes (including setting to null)
      if ('team' in newScope) {
        const newTeam = newScope.team === undefined ? null : newScope.team;
        newCachedScope = {
          ...newCachedScope,
          team: newTeam,
          // Clear dependent fields when team is null
          board: newTeam ? newCachedScope.board : null,
          epic: newTeam ? newCachedScope.epic : null,
        };
        hasChanged = true;
      }

      // Handle board changes (including setting to null)
      if ('board' in newScope) {
        const newBoard = newScope.board === undefined ? null : newScope.board;
        newCachedScope = {
          ...newCachedScope,
          board: newBoard,
          // Clear epic when board is null
          epic: newBoard ? newCachedScope.epic : null,
        };
        hasChanged = true;
      }

      // Handle epic changes (including setting to null)
      if ('epic' in newScope) {
        const newEpic = newScope.epic === undefined ? null : newScope.epic;
        newCachedScope = {
          ...newCachedScope,
          epic: newEpic,
        };
        hasChanged = true;
      }

      // Handle workflow changes
      if ('workflow' in newScope) {
        const newWorkflow = newScope.workflow === undefined ? null : newScope.workflow;
        newCachedScope = {
          ...newCachedScope,
          workflow: newWorkflow,
        };
        hasChanged = true;
      }

      // Update cached scope if changed
      if (hasChanged) {
        setCachedScope(newCachedScope);
        localStorage.setItem('process-scope', JSON.stringify(newCachedScope));
      }

      // Handle taskType changes (including null)
      if ('taskType' in newScope) {
        const newTaskType = newScope.taskType === undefined ? null : newScope.taskType;
        setTaskType(newTaskType);
      }

      // Handle date changes
      if (newScope.startDate !== undefined) {
        setStartDate(newScope.startDate);
      }

      if (newScope.endDate !== undefined) {
        setEndDate(newScope.endDate);
      }

      if (newScope.useWorkflow !== undefined) {
        setUseWorkflow(newScope.useWorkflow || false);
      }

      if (newScope.useGroups !== undefined) {
        setuseGroups(newScope.useGroups);
      }
    },
    [cachedScope],
  );

  return (
    <ProcessContext.Provider
      value={{
        scope: {
          ...cachedScope,
          startDate,
          endDate,
          taskType,
          useWorkflow,
          useGroups,
        },
        setScope,
        stages,
        setStages,
        workflow,
        setWorkflow,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export { ProcessContext, ProcessProvider };
