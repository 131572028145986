import { CostsToDate } from '../../../../api/initiative-client/initiative-client.type';
import { Board, ProjectsResponse as Team } from '../../../../api/projects-client/projects-client.type';
import { Epic } from '../../../../api/tasks-client/task-client.type';
import i18n from '../../../../base-dictionary';
import { InitiativeMetricType, InitiativeScope } from '../initiative-performance.type';

/**
 * Method that determines contributors ordering. The first one should be a contributor
 * with the most tasks in the related epics
 * @param contributors - Array of contributors (teams or boards)
 * @param epics - Array of epics
 * @returns Array of sorted contributors
 */
const sortContributors = (contributors: Team[] | Board[], epics: Epic[]): Team[] | Board[] => {
  const firstContributorAmountData = contributors.reduce(
    (result: { id: string | null; amount: number }, contributor: Team | Board) => {
      const tasksAmount = getTasksAmountForContributor(contributor.id, epics);

      if (tasksAmount > result.amount) {
        return {
          id: contributor.id,
          amount: tasksAmount,
        };
      }

      return result;
    },
    { id: null, amount: 0 },
  );

  const firstContributor = contributors.find(
    (contributor: Team | Board) => contributor.id === firstContributorAmountData.id,
  );

  if (!firstContributor) {
    return contributors;
  }

  return [
    firstContributor,
    ...contributors.filter((contributor: Team | Board) => contributor.id !== firstContributorAmountData.id),
  ];
};

/**
 * Calculates amount of contributor's tasks in the related epics
 * @param contributorId - Id of the contributor (team or board)
 * @param epics - Array of epics
 * @returns Amount of tasks for the contributor (team or board)
 */
const getTasksAmountForContributor = (contributorId: string, epics: Epic[]): number => {
  const relatedEpics = epics.filter((epic: Epic) => epic.projects.map((project) => project.id).includes(contributorId));

  return relatedEpics.reduce((result, epic) => result + epic.subtasks_count, 0);
};

/**
 * Method to determine cost for the team or board
 *
 * @param contributorId string - team or board id
 * @param costs_to_date object - costs to date objects for all the contributors
 * @returns cost for the contributor
 */
function getCost(contributorId: string, costs_to_date: CostsToDate): number | undefined {
  return Object.keys(costs_to_date).length !== 0
    ? Object.keys(costs_to_date?.teams).includes(contributorId)
      ? costs_to_date?.teams?.[contributorId]
      : undefined
    : undefined;
}

/**
 * Returns the chart title based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the chart title for.
 * @return {string} The chart title corresponding to the provided metric type.
 */
const getChartTitle = (metric: InitiativeMetricType, scope: InitiativeScope): string => {
  const titles = {
    [InitiativeMetricType.Tasks]: i18n.t('common.work_units').toLowerCase(),
    [InitiativeMetricType.Points]: i18n.t('common.estimation').toLowerCase(),
  };

  return `Completed ${titles[metric]} from contributing ${scope} (monthly)`;
};

/**
 * Returns the label for the y-axis based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the y-axis label for.
 * @return {string} The y-axis label corresponding to the provided metric type.
 */
const getYAxisLabel = (metric: InitiativeMetricType): string => {
  const labels = {
    [InitiativeMetricType.Tasks]: i18n.t('common.work_units'),
    [InitiativeMetricType.Points]: i18n.t('common.estimation'),
  };

  return `${labels[metric]} per month`;
};

export { getChartTitle, getCost, getTasksAmountForContributor, getYAxisLabel, sortContributors };
