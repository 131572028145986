import { Controls } from '@reactflow/controls';
import { memo, useContext, useMemo } from 'react';
import ReactFlow from 'reactflow';

import { styled } from '@linaria/react';
import { GraphData, ProcessMapping, Stages } from '../../api/process-client/process-client.type';
import { ProcessContext } from '../process/context/process.context';
import { StageEdge } from '../process/edges/stage-edge';
import { WorkflowEdgeIn, WorkflowEdgeOut } from '../process/edges/workflow-edge';
import { StageNode } from '../process/nodes/stage-node';
import { WorkflowNode } from '../process/nodes/workflow-node';
import { ViewType } from '../process/process.type';
import { calculateGraphV2 } from '../process/processv2.helpers';

const nodeTypes = {
  stageNode: StageNode,
  workflowNode: WorkflowNode,
};

const edgeTypes = {
  stageEdge: StageEdge,
  workflowEdgeIn: WorkflowEdgeIn,
  workflowEdgeOut: WorkflowEdgeOut,
};

type Props = {
  graphData: GraphData | undefined;
  mapping: ProcessMapping | null;
  viewType: ViewType;
  isComparisonView?: boolean;
  useGroups: boolean;
};
export const SectionGraph = memo(function SectionGraph({ graphData, mapping, viewType, useGroups }: Props) {
  const { stages } = useContext(ProcessContext);

  const { nodes, edges } = useMemo(
    () => calculateGraphV2(graphData, mapping, stages as Stages[], viewType, useGroups),
    [graphData, mapping, stages, viewType, useGroups],
  );

  const flowProps = useMemo(
    () => ({
      nodeOrigin: [0.5, 0.5] as [number, number],
      fitView: true,
      nodeTypes,
      edgeTypes,
      nodes,
      edges,
      proOptions: { hideAttribution: true },
      edgesFocusable: false,
      elementsSelectable: false,
    }),
    [nodes, edges],
  );

  return (
    <>
      <StyledReactFlow {...flowProps}>
        <Controls position="bottom-right" showInteractive={false} />
      </StyledReactFlow>
    </>
  );
});

const StyledReactFlow = styled(ReactFlow)`
  .react-flow__handle {
    opacity: 0;
    border: none;
    min-height: 0;
  }

  .react-flow__controls {
    background: #f5f5f5;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  }

  .react-flow__edge-path {
    stroke: #000;
  }
`;
