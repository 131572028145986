import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  size?: 'large' | 'medium' | 'regular' | 'small' | 'tiny';
  lineHeight?: 'none' | 'tight' | 'normal';
  weight?: 'bold' | 'medium' | 'regular';
  color?: string;
  style?: React.CSSProperties;
  textKey?: string;
};

const Text = ({ children, size, lineHeight, weight, color, style, textKey, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledText
      size={size || 'regular'}
      lineHeight={lineHeight || 'normal'}
      weight={weight || 'regular'}
      style={{ color, ...style }}
      {...rest}
    >
      {textKey ? t(textKey) : children}
    </StyledText>
  );
};

const StyledText = styled.div<{
  size: 'large' | 'medium' | 'regular' | 'small' | 'tiny';
  lineHeight: 'none' | 'tight' | 'normal';
  weight: 'bold' | 'medium' | 'regular';
}>`
  font-family: ${({ weight }) => {
    switch (weight) {
      case 'bold':
        return 'Figtree-bold';
      case 'medium':
        return 'Figtree-semi-bold';
      case 'regular':
        return 'Figtree';
    }
  }};
  font-size: ${({ size }) => {
    switch (size) {
      case 'large':
        return 'var(--font-size-large)';
      case 'medium':
        return 'var(--font-size-medium)';
      case 'regular':
        return 'var(--font-size-regular)';
      case 'small':
        return 'var(--font-size-small)';
      case 'tiny':
        return 'var(--font-size-xsmall)';
    }
  }};
  line-height: ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return 'var(--line-height-medium)';
      case 'tight':
        return 'var(--line-height-xmedium)';
      case 'normal':
        return 'var(--line-height-large)';
    }
  }};
  font-weight: ${({ weight }) => {
    switch (weight) {
      case 'bold':
        return 'var(--font-weight-bold)';
      case 'medium':
        return 'var(--font-weight-medium)';
      case 'regular':
        return 'var(--font-weight-regular)';
    }
  }};
`;

const H1 = styled.h1`
  font-family: var(--headline-h1-font-family);
  font-weight: var(--headline-h1-font-weight);
  line-height: var(--headline-h1-line-height);
  font-size: var(--headline-h1-font-size);
  margin: 0;
`;

const H2 = styled.h2`
  font-family: var(--headline-h2-font-family);
  font-weight: var(--headline-h2-font-weight);
  line-height: var(--headline-h2-line-height);
  font-size: var(--headline-h2-font-size);
  margin: 0;
`;

const H3 = styled.h3`
  font-family: var(--headline-h3-font-family);
  font-weight: var(--headline-h3-font-weight);
  line-height: var(--headline-h3-line-height);
  font-size: var(--headline-h3-font-size);
  margin: 0;
`;

export { H1, H2, H3, Text };
