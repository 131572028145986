import { styled } from '@linaria/react';
import { Fragment } from 'react';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { SprintComparisonTable } from './sprint-comparison-table';
import { useSprintAvailableTargets, useSprintComparisonViewData } from './sprint-comparison-view.hooks';
import { SprintMeasureComparisonChart } from './sprint-measure-comparison-chart';
import { SprintMeasuresOverTimeChart } from './sprint-measures-over-time-chart';

export const SprintComparisonView = () => {
  const { customMeasures, measureData, isFetching, workPeriods, hasWorkPeriods } = useSprintComparisonViewData();
  const reversedWorkPeriods = [...workPeriods].reverse();

  const availableTargets = useSprintAvailableTargets(workPeriods);

  if (!hasWorkPeriods) {
    return (
      <Fragment>
        <BrandedLoadingOverlay visible={isFetching} variant="colored" />
        <EmptyStateContainer>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
            <Icon name="info" size={40} color={skyDark} />
            <Text weight="bold" size="large" color={skyDark}>
              Please select sprints to compare
            </Text>
          </div>
        </EmptyStateContainer>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <BrandedLoadingOverlay visible={isFetching} variant="colored" />
      <SprintComparisonTable
        customMeasures={customMeasures}
        measureData={measureData}
        workPeriods={reversedWorkPeriods}
      />
      <SprintMeasureComparisonChart
        customMeasures={customMeasures}
        measureData={measureData}
        workPeriods={reversedWorkPeriods}
        targets={availableTargets ?? {}}
      />
      <SprintMeasuresOverTimeChart
        customMeasures={customMeasures}
        measureData={measureData}
        workPeriods={reversedWorkPeriods}
      />
    </Fragment>
  );
};

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
