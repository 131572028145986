import { styled } from '@linaria/react';
import { Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { H2 } from '../../ui-library/typography/typography';

interface Props {
  title: string;
  maxWidth?: string;
}

export const Title = ({ title, maxWidth = '100%' }: Props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const titleRef = useRef<HTMLHeadingElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(title): ignore
  useEffect(() => {
    const checkOverflow = () => {
      const current = titleRef.current;
      if (current) {
        setIsOverflowing(current.scrollWidth > current.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [title]);

  return (
    <Tooltip label={isOverflowing ? title : ''} disabled={!isOverflowing} position="bottom" withArrow>
      <OverflowHeading ref={titleRef} maxWidth={maxWidth}>
        {title}
      </OverflowHeading>
    </Tooltip>
  );
};

const OverflowHeading = styled(H2)<{ maxWidth: string }>`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  margin-bottom: 0;
`;
