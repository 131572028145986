import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { isValidAccessToken } from '../../helpers/storage/storage';
import { getOrganizations, getOrganizationStatuses } from './organization-client';
import { OrganizationResponse, OrganizationStatusesByProjectsAndBoards } from './organization-client.type';

/**
 * Query manager for fetching organizations for the current user.
 *
 * @param {Partial<UseQueryOptions<OrganizationResponse[]>>} options - Optional query options for the useQuery hook.
 * @return {{ organizations: OrganizationResponse[] | undefined; query: QueryObserverResult<OrganizationResponse[]> }} An object containing the organizations data and the query result.
 */
const useOrganizations = (
  options?: Partial<UseQueryOptions<OrganizationResponse[]>>,
): { organizations: OrganizationResponse[] | undefined; query: QueryObserverResult<OrganizationResponse[]> } => {
  const query = useQuery({
    queryKey: ['organizations'],
    queryFn: getOrganizations,
    enabled: isValidAccessToken(),
    // Cache organization data indefinitely throughout the user session
    // Can be overridden with options
    staleTime: Infinity,
    ...options,
  });

  return { organizations: query.data, query };
};

/**
 * Query manager for fetching a list of statuses for the given organization
 *
 * @param {string | null} organizationId organization id to fetch statuses for
 * @param {UseQueryOptions<{ workflow_available_statuses_by_project_and_subproject: OrganizationStatusesByProjectsAndBoards; }>} options UseQueryOptions
 * @returns a nested object of statuses by projects and subprojects along with the query manager
 */
function useOrganizationStatusesByProjectsAndBoards(
  organizationId: string | null,
  options?: UseQueryOptions<{
    workflow_available_statuses_by_project_and_subproject: OrganizationStatusesByProjectsAndBoards;
  }>,
): {
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  query: QueryObserverResult<{
    workflow_available_statuses_by_project_and_subproject: OrganizationStatusesByProjectsAndBoards;
  }>;
} {
  const query = useQuery({
    queryKey: ['organizationStatuses', organizationId],
    staleTime: 1000 * 60 * 5,
    queryFn: () =>
      organizationId ? getOrganizationStatuses(organizationId) : Promise.reject('Organization ID is required'),
    ...options,
  });

  return { statusesByProjectsAndBoards: query.data?.workflow_available_statuses_by_project_and_subproject, query };
}

export { useOrganizations, useOrganizationStatusesByProjectsAndBoards };
