import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { Workflow, WorkflowPayload } from './workflows-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/workflows/';

/**
 * Creates a workflow
 *
 * @param {WorkflowPayload} payload The workflow payload
 * @param {string} organizationId The organization ID
 * @returns {Promise<Workflow>} The created workflow
 */
async function createWorkflow({
  payload,
  organizationId,
}: {
  payload: WorkflowPayload;
  organizationId: string;
}): Promise<Workflow> {
  const params = new URLSearchParams({
    organization_id: organizationId,
  });
  const apiURL = baseURL + '?' + params.toString();
  const response = await axiosInstance.post(apiURL, payload);
  return response.data;
}

/**
 * Updates a workflow
 *
 * @param {string} workflowId The workflow ID
 * @param {WorkflowPayload} payload The workflow payload
 * @param {string} organizationId The organization ID
 * @returns {Promise<Workflow>} The updated workflow
 */
async function updateWorkflow({
  workflowId,
  payload,
  organizationId,
}: {
  workflowId: string;
  payload: WorkflowPayload;
  organizationId: string;
}): Promise<Workflow> {
  const params = new URLSearchParams({
    organization_id: organizationId,
  });
  const apiURL = `${baseURL}${workflowId}/` + '?' + params.toString();
  const response = await axiosInstance.put(apiURL, payload);
  return response.data;
}

/**
 * Fetches all workflows for a given portfolio
 * @param {string} organization The organization ID
 * @returns {Promise<Workflow[]>} The list of workflows
 */
async function getWorkflows(organization: string): Promise<Workflow[]> {
  const params = new URLSearchParams({
    organization_id: organization,
  });
  const apiURL = baseURL + '?' + params.toString();
  const response = await axiosInstance.get(apiURL);
  return response.data;
}

/**
 * Deletes a workflow
 *
 * @param {string} workflowId The workflow ID
 * @param {string} organizationId The organization ID
 * @returns {Promise<void>}
 */
async function deleteWorkflow(workflowId: string, organizationId: string): Promise<void> {
  const params = new URLSearchParams({
    organization_id: organizationId,
  });
  const apiURL = `${baseURL}${workflowId}/` + '?' + params.toString();
  await axiosInstance.delete(apiURL);
}

export { createWorkflow, deleteWorkflow, getWorkflows, updateWorkflow };
