import { styled } from '@linaria/react';
import { Group, Tabs } from '@mantine/core';
import { useMemo, useRef, useState } from 'react';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { AssessmentView } from './assessment-view/assessment-view';
import { ComparisonView } from './comparison-view/comparison-view';
import { TaskSelectionProvider } from './tasks.context';
import { WorkPeriodsProvider } from './work-periods.context';

enum WorkPeriodsTab {
  ASSESSMENT = 'assessment',
  COMPARISON = 'comparison',
}

export function WorkPeriods() {
  useDocumentTitle('Work Periods - Bloomfilter');
  const [selectedTab, setSelectedTab] = useState<WorkPeriodsTab>(WorkPeriodsTab.ASSESSMENT);

  const assessmentTabRef = useRef<HTMLButtonElement | null>(null);
  const comparisonTabRef = useRef<HTMLButtonElement | null>(null);

  const assessmentIcon = useMemo(
    () => <Icon name="assessment" size={16} color={selectedTab === WorkPeriodsTab.ASSESSMENT ? baseWhite : inkLight} />,
    [selectedTab],
  );

  const comparisonIcon = useMemo(
    () => <Icon name="compare" size={16} color={selectedTab === WorkPeriodsTab.COMPARISON ? baseWhite : inkLight} />,
    [selectedTab],
  );

  const header = useMemo(
    () => (
      <Group>
        <Icon name="calendar_month" color={secondaryBase} size={36} />
        <H2>Work Periods</H2>
        <StyledTabs
          variant="pills"
          radius="xl"
          value={selectedTab}
          onChange={(value) => setSelectedTab(value as WorkPeriodsTab)}
        >
          <Tabs.List>
            <Tabs.Tab value={WorkPeriodsTab.ASSESSMENT} ref={assessmentTabRef} leftSection={assessmentIcon}>
              Assessment
            </Tabs.Tab>
            <Tabs.Tab value={WorkPeriodsTab.COMPARISON} ref={comparisonTabRef} leftSection={comparisonIcon}>
              Comparison
            </Tabs.Tab>
          </Tabs.List>
        </StyledTabs>
      </Group>
    ),
    [selectedTab, assessmentIcon, comparisonIcon],
  );

  return (
    <WorkPeriodsProvider>
      <TaskSelectionProvider>
        <MenuDrivenContainer header={header}>
          {selectedTab === WorkPeriodsTab.ASSESSMENT && <AssessmentView />}
          {selectedTab === WorkPeriodsTab.COMPARISON && <ComparisonView />}
        </MenuDrivenContainer>
      </TaskSelectionProvider>
    </WorkPeriodsProvider>
  );
}

const StyledTabs = styled(Tabs)`
  .mantine-Tabs-list {
    gap: 8px;
  }

  .mantine-Tabs-tab {
    transition: all 0.2s ease;
    font-weight: 500;

    &[data-active] {
      color: ${baseWhite};
      background: ${secondaryBase};
    }

    &:not([data-active]) {
      color: ${inkLight};
      border: 1px solid ${inkLight};

      &:hover {
        background: ${skyLightest};
        border: 1px solid ${secondaryBase};
      }
    }
  }
`;
