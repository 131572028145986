import { QueryObserverResult, UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  getInitiative,
  getInitiativeContributions,
  getInitiativeFocus,
  getInitiativeTasks,
  getInitiatives,
  getInitiativesChartData,
  searchInitiatives,
} from './initiative-client';
import {
  ExtendedInitiative,
  FocusData,
  Initiative,
  InitiativeTaskResponse,
  InitiativeTasksConfig,
  KPIOverviewData,
  Metric,
} from './initiative-client.type';

/**
 * Method to fetch initiatives for a portfolio
 *
 * @param portfolioId portfolio id
 * @param options query options
 * @returns an object with initiatives and query manager
 */
const useInitiatives = (
  portfolioId: string | undefined,
  options?: UseQueryOptions<Initiative[]>,
): {
  initiatives: Initiative[] | undefined;
  query: QueryObserverResult<Initiative[]>;
} => {
  const query = useQuery({
    queryKey: ['initiatives', portfolioId],
    queryFn: () => (portfolioId ? getInitiatives(portfolioId) : Promise.reject('No portfolio selected')),
    ...options,
  });

  return { initiatives: query.data, query };
};

/**
 * Query manager for fetching KPI overview data for the strategy page.
 *
 * @param {string | undefined} portfolioId - The ID of the portfolio
 * @param {UseQueryOptions<KPIOverviewData>} options - Additional options for the query
 * @returns {{ kpiOverviewData: KPIOverviewData | undefined; query: QueryObserverResult<KPIOverviewData> }} The KPI overview data and its query result
 */
const useKPIOverviewData = (portfolioId: string | undefined, options?: UseQueryOptions<KPIOverviewData>) => {
  const query = useQuery({
    queryKey: ['kpi-overview-data', portfolioId],
    queryFn: () => (portfolioId ? getInitiativesChartData(portfolioId) : Promise.reject('No portfolio selected')),
    ...options,
  });

  return { kpiOverviewData: query.data, query };
};

/**
 * Query manager for fetching an extended initiative.
 *
 * @param {string | undefined} portfolioId - The ID of the portfolio
 * @param {string | undefined} initiativeId - The ID of the initiative
 * @param {UseQueryOptions<ExtendedInitiative>} options - Additional options for the query
 * @returns {{ initiative: ExtendedInitiative | undefined; query: QueryObserverResult<ExtendedInitiative> }} The extended initiative and its query result
 */
const useInitiative = (
  portfolioId: string | undefined,
  initiativeId: string | undefined,
  options?: UseQueryOptions<ExtendedInitiative>,
): { initiative: ExtendedInitiative | undefined; query: QueryObserverResult<ExtendedInitiative> } => {
  const query = useQuery({
    queryKey: ['initiative', portfolioId, initiativeId],
    queryFn: () =>
      portfolioId && initiativeId ? getInitiative(portfolioId, initiativeId) : Promise.reject('Cannot get initiative'),
    ...options,
  });

  return { initiative: query.data, query };
};

/**
 * Query manager for fetching contributing epics for an initiative
 * @param portfolioId - portfolio id
 * @param initiativeId - initiative id
 * @param scope - epic, project, or subproject
 * @returns Record of contributing epics by tasks and points, organized by id; and the raw query
 */
function useInitiativeContributions(
  portfolioId: string | undefined,
  initiativeId: string | undefined,
  scope: 'epic' | 'project' | 'subproject',
  options?: UseQueryOptions<Record<string, Metric>>,
): { contributions: Record<string, Metric> | undefined; query: QueryObserverResult<Record<string, Metric>> } {
  const query = useQuery({
    queryKey: ['contributions', portfolioId, initiativeId, scope],
    queryFn: () =>
      portfolioId && initiativeId
        ? getInitiativeContributions(portfolioId, initiativeId, scope)
        : Promise.reject('Portfolio and initiative are required to fetch contributions'),
    ...options,
  });
  return { contributions: query.data, query };
}

/**
 * Query manager for fetching focus data for an initiative
 * @param portfolioId - portfolio id
 * @param initiativeId - initiative id
 * @param scope - project or subproject
 * @returns focus data and the raw query
 */
function useInitiativeFocus(
  portfolioId: string | undefined,
  initiativeId: string | undefined,
  scope: 'project' | 'subproject',
  options?: UseQueryOptions<FocusData>,
): { focus: FocusData | undefined; query: QueryObserverResult<FocusData> } {
  const query = useQuery({
    queryKey: ['getInitiativeFocus', portfolioId, initiativeId, scope],
    queryFn: () =>
      portfolioId && initiativeId
        ? getInitiativeFocus(portfolioId, initiativeId, scope)
        : Promise.reject('Portfolio and initiative are required to fetch focus'),
    ...options,
  });
  return { focus: query.data, query };
}

/**
 * Query manager for fetching initiative tasks.
 *
 * @param {InitiativeTasksConfig} config - Configuration object containing parameters for fetching initiative tasks
 * @param {UseQueryOptions<InitiativeTaskResponse[]>} options - Additional options for the query
 * @return {{ data: InitiativeTaskResponse[] | undefined; query: QueryObserverResult<InitiativeTaskResponse[]> }} Object containing data and query result
 */
const useInitiativeTasks = (
  { portfolioId, initiativeId, filter, date, epicId, projectId }: InitiativeTasksConfig,
  options?: UseQueryOptions<InitiativeTaskResponse[]>,
): { data: InitiativeTaskResponse[] | undefined; query: QueryObserverResult<InitiativeTaskResponse[]> } => {
  const query = useQuery({
    queryKey: ['initiative-tasks', portfolioId, initiativeId, filter, date, epicId, projectId],
    queryFn: () =>
      portfolioId && initiativeId && filter
        ? getInitiativeTasks({
            portfolioId,
            initiativeId,
            filter,
            date: date || undefined,
            epicId: epicId || undefined,
            projectId: projectId || undefined,
          })
        : Promise.reject('Cannot get initiative tasks'),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for searching initiatives within a portfolio.
 *
 * @param {string | undefined} portfolioId - The ID of the portfolio to search within
 * @param {string} searchTerm - The search term to filter initiatives
 * @param {Partial<UseQueryOptions<Initiative[]>>} options - Additional options for the query
 * @returns {{ data: Initiative[] | undefined; query: QueryObserverResult<Initiative[]> }} Object containing matched initiatives and query result
 */
const useSearchInitiatives = (
  portfolioId: string | undefined,
  searchTerm: string,
  options?: Partial<UseQueryOptions<Initiative[]>>,
): { data: Initiative[] | undefined; query: QueryObserverResult<Initiative[]> } => {
  const query = useQuery({
    queryKey: ['search-initiatives', portfolioId, searchTerm],
    queryFn: () => (portfolioId ? searchInitiatives(portfolioId, searchTerm) : Promise.reject('No portfolio selected')),
    ...options,
  });

  return { data: query.data, query };
};

export {
  useInitiative,
  useInitiativeContributions,
  useInitiativeFocus,
  useInitiativeTasks,
  useInitiatives,
  useKPIOverviewData,
  useSearchInitiatives,
};
