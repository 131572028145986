import { styled } from '@linaria/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FlowByPaceDataOverTime,
  FlowByPhaseDataOverTime,
} from '../../../api/work-periods-client/work-periods-client.type';
import i18n from '../../../base-dictionary';
import { CumulativeFlowChart } from '../../../components/burn-flow-chart/burn-flow-chart';
import { ViewByType } from '../../../components/burn-flow-chart/burn-flow-chart.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDefaultDay, useWorkPeriodLength } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { ContentTag, HeadingTag } from '../../../styles/shared-styled-components';
import { WorkPeriodType } from '../process-analysis.type';
import { calculateMeasurePercentage, formatFlowByPhaseData, spliceByDay } from './assessment-view.helpers';
import { AssessmentChart, Metric } from './assessment-view.type';
import { PhaseCard } from './cards/phase-card';
import { ChartContainer } from './chart-container/chart-container';
import { calculateDate } from './section-phase.helpers';

type Props = {
  data?: { flow_by_phase?: FlowByPhaseDataOverTime; flow_by_pace?: FlowByPaceDataOverTime };
};

export const SectionPhase = ({ data }: Props) => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriodLength = useWorkPeriodLength();
  const defaultDay = useDefaultDay();
  const navigate = useNavigate();
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);
  const [activeDay, setActiveDay] = useState<string | null>(defaultDay);
  const endDate = useProcessAnalysisStore((state) => state.endDate);

  const phaseMetadata = [
    {
      id: 'backlog',
      label: i18n.t('process_analysis.assessment.phase.ready'),
      description: 'not started',
      color: newCOLORS.indigo,
    },
    {
      id: 'blocked',
      label: i18n.t('process_analysis.assessment.phase.blocked'),
      description: 'held up',
      color: newCOLORS.coral,
    },
    {
      id: 'wip',
      label: i18n.t('process_analysis.assessment.phase.wip'),
      description: 'started',
      color: newCOLORS.darkYellow,
    },
    {
      id: 'review',
      label: i18n.t('process_analysis.assessment.phase.review'),
      description: 'code review',
      color: newCOLORS.lightYellow,
    },
    {
      id: 'test',
      label: i18n.t('process_analysis.assessment.phase.test'),
      description: 'acceptance',
      color: newCOLORS.pink,
    },
    {
      id: 'deployable',
      label: i18n.t('process_analysis.assessment.phase.deployable'),
      description: 'staged',
      color: newCOLORS.aqua,
    },
    {
      id: 'done',
      label: i18n.t('process_analysis.assessment.phase.done'),
      description: 'work finished',
      color: newCOLORS.green,
    },
  ];
  useEffect(() => {
    setActiveDay(defaultDay);
  }, [defaultDay]);

  const burnsAndFlows = formatFlowByPhaseData(data?.flow_by_phase, data?.flow_by_pace);

  const title = (
    <>
      <LargeHeadingTag>
        <LargeHeadingTagAnchor
          onClick={() => navigate('/application/process-analysis/task-flow')}
          style={{ textDecoration: 'underline' }}
        >
          Flow of work, by phase
        </LargeHeadingTagAnchor>
      </LargeHeadingTag>
      <ContentTag>How each planned task is flowing through the phases of work in this work period.</ContentTag>
    </>
  );

  return (
    <ChartContainer
      chartId={AssessmentChart.Phase}
      title={title}
      day={activeDay}
      setDay={setActiveDay}
      showDaySelect={workPeriodType === WorkPeriodType.Defined}
      metric={metric}
      setMetric={setMetric}
      data={data}
    >
      <DayDetailsBox>
        {activeDay && <LargeHeadingTag>{`Day ${activeDay} of ${workPeriodLength}`}</LargeHeadingTag>}
        <Cards>
          {phaseMetadata.map((item) => (
            <PhaseCard
              key={item.id}
              label={item.label}
              description={item.description}
              legend
              color={item.color}
              suffix={'%'}
              value={calculateMeasurePercentage(
                item.id,
                metric === Metric.Tasks
                  ? burnsAndFlows.flows_tasks.data_points
                  : burnsAndFlows.flows_points.data_points,
                Number(activeDay),
              )}
              date={
                workPeriodType === WorkPeriodType.Defined
                  ? calculateDate(workPeriod, activeDay, workPeriodLength)
                  : dayjs(endDate).format('YYYY-MM-DD')
              }
            />
          ))}
        </Cards>
      </DayDetailsBox>
      <CumulativeFlowChart
        cumulative_flow_points={spliceByDay(burnsAndFlows.flows_points, activeDay)}
        cumulative_flow_tasks={spliceByDay(burnsAndFlows.flows_tasks, activeDay)}
        burnsAndFlows={burnsAndFlows}
        legend={false}
        header={false}
        dataType={metric as unknown as ViewByType}
      />
    </ChartContainer>
  );
};

const LargeHeadingTag = styled(HeadingTag)`
  font-size: 20px;
`;

const LargeHeadingTagAnchor = styled.span`
  color: ${newCOLORS.indigo};
  text-decoration: underline;
  cursor: pointer;
`;

const DayDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
