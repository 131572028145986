import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IntegrationType } from '../../../api/integrations-client/integrations-client.type';
import { WorkflowPayload } from '../../../api/workflows-client/workflows-client.type';
import { iconsIntegrations } from '../../integrations/assets';

dayjs.extend(advancedFormat);
/**
 * Gets the icon URL for a given integration type
 * @param {IntegrationType} source The integration type to get the icon for
 * @returns {string} The URL of the icon image for the integration type, or empty string if not found
 */
const getDataSourceIcon = (source: IntegrationType): string => {
  const sourceIcons: Record<IntegrationType, string> = {
    [IntegrationType.ADO]: iconsIntegrations.iconADO,
    [IntegrationType.JIRA]: iconsIntegrations.iconJiraCloud,
    [IntegrationType.JDC]: iconsIntegrations.iconJiraDataCenter,
    [IntegrationType.GITHUB]: iconsIntegrations.iconGithub,
  };
  return sourceIcons[source] || '';
};

/**
 * Gets a human-readable label for a given integration type
 * @param {IntegrationType} source The integration type to get the label for
 * @returns {string} The display label for the integration type, or empty string if not found
 */
const getDataSourceLabel = (source: IntegrationType): string => {
  const sourceLabels: Record<IntegrationType, string> = {
    [IntegrationType.JIRA]: 'Jira',
    [IntegrationType.ADO]: 'ADO',
    [IntegrationType.JDC]: 'Jira Data Center',
    [IntegrationType.GITHUB]: 'Github',
  };
  return sourceLabels[source] || '';
};

/**
 * Formats a datetime string to the local timezone
 * @param {string | undefined} datetime The datetime string to format
 * @returns {string} The formatted datetime string, or empty string if not found
 */
const formatDatetimeToLocal = (datetime: string | undefined): string => {
  if (!datetime) return '';
  return dayjs.utc(datetime).local().format('M/D/YYYY HH:mm A z');
};

/**
 * Formats a time string to the local timezone
 * @param {string | undefined} time The time string to format
 * @returns {string} The formatted time string, or empty string if not found
 */
const formatTimeToLocal = (time: string | undefined) => {
  if (!time) return '';
  return dayjs.utc(`2000-01-01T${time}`).local().format('HH:mm');
};

/**
 * Formats a time string from local timezone to UTC
 * @param {string | undefined} time The local time string to format
 * @returns {string} The UTC formatted time string, or empty string if not found
 */
const formatTimeToUTC = (time: string | undefined) => {
  if (!time) return '';
  return dayjs(`2000-01-01T${time}`).utc().format('HH:mm');
};

/**
 * Formats a time string to the display format
 * @param {string | undefined} time The time string to format
 * @returns {string} The formatted time string, or empty string if not found
 */
const formatTimeToDisplay = (time: string | undefined) => {
  if (!time) return '';
  return dayjs(`2000-01-01T${time}`).format('h:mm A z');
};

/**
 * Helper function to help place a status in the status list, in the correct position.
 *
 * Parameters:
 * - statuses: The list of statuses.
 * - target: The status that is being dropped onto the target.
 * - targetEdge: The edge of the target that the source is being dropped onto.
 *
 * Returns:
 * - The correct index of the source status in the status list.
 */
function getStatusIndex(statuses: string[], target: string | null, targetEdge: 'top' | 'bottom'): number {
  const targetIndex = statuses.findIndex((status) => status === target);
  // If we have no target index, place it at the end
  if (targetIndex === -1) {
    return statuses.length;
  }

  // If we have a target index, and the edge is top, the source will replace the target
  if (targetEdge === 'top') {
    return targetIndex;
  }

  // If we have a target index, and the edge is bottom, place the source after the target
  return targetIndex + 1;
}

/**
 * Builds a workflow payload for the API
 * @param {string} name The name of the workflow
 * @param {string[]} subprojects The subprojects to include in the workflow
 * @param {Record<string, string[]>} mappedStatuses The statuses to map to buckets
 * @returns {WorkflowPayload} The workflow payload
 */
function buildWorkflowPayload(
  name: string,
  organization: string,
  subprojects: string[],
  mappedStatuses: { [key: string]: string[] },
): WorkflowPayload {
  const steps = Object.entries(mappedStatuses).map(([bucketName, statuses]) => ({
    name: bucketName,
    mappings: statuses.map((status) => ({ external_name: status })),
  }));
  return {
    name,
    organization,
    steps,
    subprojects,
  };
}

export {
  buildWorkflowPayload,
  formatDatetimeToLocal,
  formatTimeToDisplay,
  formatTimeToLocal,
  formatTimeToUTC,
  getDataSourceIcon,
  getDataSourceLabel,
  getStatusIndex,
};
