import { styled } from '@linaria/react';
import { ReactNode } from 'react';
import { skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

interface Props {
  title: string | ReactNode;
  icon?: string | ReactNode;
  content: ReactNode;
}

export const OverviewCard = ({ title, icon, content }: Props) => {
  const getIcon = () => {
    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <Icon name={icon} size={20} color={skyDark} />;
    }

    return icon;
  };

  return (
    <Container>
      <Header>
        {getIcon()}
        <Text size="regular" lineHeight="none">
          {title}
        </Text>
      </Header>
      {content}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: var(--base-white);
  border: 1px solid var(--sky-light);
  border-radius: 8px;
  flex: 1 1 140px;
  min-width: 200px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
