import { Area } from 'recharts';
import { secondaryBase } from '../../../styles/design-tokens';
import { TargetComparison, TransformedTarget } from '../../adherence/targets/targets-client.type';
import {
  getTargetFill,
  getTargetStrokeDashArray,
} from '../../strategy/initiative-performance/section-targets/initiative-target.helpers';

/**
 * Generates reference lines and areas for target visualization in recharts charts
 * @param {TransformedTarget} target - The transformed target object containing comparison type and time allocations
 * @returns {JSX.Element | null} JSX elements for reference lines/areas or null if invalid target values
 */
const getTargetAreaChart = (target: TransformedTarget): JSX.Element => {
  const targetFillColor = getTargetFill(target.target_comparison, secondaryBase);
  const targetStrokeDashArray = getTargetStrokeDashArray(target.target_comparison);
  const targetBase =
    target.target_comparison === TargetComparison.GTE || target.target_comparison === TargetComparison.GT
      ? 'dataMax'
      : 'dataMin';
  return (
    <Area
      type="monotone"
      dataKey={`target_${target.id}`}
      name={`${target.name} (Target)`}
      stroke={secondaryBase}
      strokeDasharray={targetStrokeDashArray}
      fill={targetFillColor}
      dot={false}
      baseValue={targetBase}
    />
  );
};

/**
 * Calculates the average and total values for a target, considering the target comparison type
 * @param {TransformedTarget} target - The transformed target object containing comparison type and time allocations
 * @returns {Object} An object with average_lower, average_upper, total_lower, and total_upper values
 */
const getProratedAverageTotals = (
  target: TransformedTarget,
): { average_lower: number; average_upper?: number; total_lower: number; total_upper?: number } => {
  if (
    target.target_comparison === TargetComparison.RANGE_EX ||
    target.target_comparison === TargetComparison.RANGE_IN
  ) {
    const values = Object.values(target.time_allocations);
    const lower = values.map((value) =>
      typeof value === 'object' && value !== null && 'value_lower' in value ? value.value_lower : 0,
    );
    const upper = values.map((value) =>
      typeof value === 'object' && value !== null && 'value_upper' in value ? value.value_upper : 0,
    );
    const totalLower = lower.reduce((acc, curr) => acc + curr, 0);
    const totalUpper = upper.reduce((acc, curr) => acc + curr, 0);
    const averageLower = lower.length ? Number((totalLower / lower.length).toFixed(2)) : 0;
    const averageUpper = upper.length ? Number((totalUpper / upper.length).toFixed(2)) : 0;
    return {
      average_lower: averageLower,
      average_upper: averageUpper,
      total_lower: Number(totalLower.toFixed(2)),
      total_upper: Number(totalUpper.toFixed(2)),
    };
  }
  const values = Object.values(target.time_allocations).map((value) => (typeof value === 'number' ? value : 0));
  const total = Number(values.reduce((acc, curr) => acc + curr, 0).toFixed(2));
  const average = values.length ? Number((total / values.length).toFixed(2)) : 0;
  return {
    average_lower: average,
    total_lower: total,
  };
};

export { getProratedAverageTotals, getTargetAreaChart };
