import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Collapse, Loader, Select, Tooltip } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAllocationOfCostsMeasures } from '../../api/financials-client/financials-client.hooks.ts';
import {
  AllocationOfCostsCategory,
  AllocationOfCostsSummary,
  ViewBy,
} from '../../api/financials-client/financials-client.type.ts';
import { icons } from '../../assets/icons/icons.tsx';
import i18n from '../../base-dictionary';
import { ChartContainer } from '../../containers/financials/financials.styled.ts';
import { FloraButton } from '../../containers/flora/flora-button.tsx';
import { BoardContext } from '../../contexts/index.tsx';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader.ts';
import {
  getAllocationOfCostsCategory,
  getAllocationOfCostsUnit,
  setAllocationOfCostsCategory,
  setAllocationOfCostsUnit,
} from '../../helpers/storage/storage.ts';
import { snakeCaseToTitleCase } from '../../helpers/string-helpers/string-helpers.ts';
import { newCOLORS } from '../../styles/colors.ts';
import {
  SmallerHeading,
  StandardText,
} from '../../styles/new-shared-styled-components/new-shared-styled-components.ts';
import { DownloadPNGButton } from '../download-png-button.tsx';
import { getScopeNameSuffix } from '../edit-financial-data/edit-financial-data.helper.ts';
import { FinancialsScope } from '../financials-scope/financials-scope.type.tsx';
import { AllocationOfCostsChart } from './allocation-of-costs-chart';
import { AllocationOfCostsItemList } from './allocation-of-costs-item-list';
import { ShowAllocationOfCostsChartLine } from './allocation-of-costs.type.tsx';

export const UNCHECKED_COLOR = newCOLORS.lightGray;
const UNDEFINED_COLOR = newCOLORS.gray;

// listed in the opposite order of default order
export const DEFAULT_COLORS = [
  newCOLORS.blue,
  newCOLORS.darkerGreen,
  newCOLORS.lighterGreen,
  newCOLORS.orange,
  newCOLORS.darkYellow,
  newCOLORS.indigo,
  newCOLORS.teal,
  newCOLORS.aqua,
  newCOLORS.magenta,
  newCOLORS.tangerine,
  newCOLORS.violet,
  newCOLORS.purple,
];

export const AllocationOfCosts = ({ startDate, endDate, portfolio, team, setQueryFinished }: FinancialsScope) => {
  const CHART_UNITS = [
    { value: 'tasks_in_usd', label: i18n.t('common.work_units') + ' in USD' },
    { value: 'points_in_usd', label: i18n.t('common.estimation') + ' in USD' },
    { value: 'tasks', label: i18n.t('common.work_units') },
    { value: 'points', label: i18n.t('common.estimation') },
  ];
  const [opened, { toggle }] = useDisclosure(true);
  const [chartUnit, setUnit] = useState<ViewBy>(getAllocationOfCostsUnit());
  const [category, setCategory] = useState<AllocationOfCostsCategory>(getAllocationOfCostsCategory());
  const downloadRef = useRef<HTMLDivElement>(null);
  const exceptionRef = useRef<HTMLDivElement>(null);

  const [availableColors, setAvailableColors] = useState<string[]>(Object.assign([], DEFAULT_COLORS));
  const [showAllocationOfCostsChartLines, setShowAllocationOfCostsChartLines] = useState<
    ShowAllocationOfCostsChartLine[]
  >([]);
  const [showError, setShowError] = useState<boolean>(false);
  const errorRef = useClickOutside(() => setShowError(false));

  const updateCategory = (category: AllocationOfCostsCategory) => {
    setAllocationOfCostsCategory(category);
    setCategory(category);
  };

  const updateUnit = (unit: ViewBy) => {
    setAllocationOfCostsUnit(unit);
    setUnit(unit);
  };

  const updateShowAllocationOfCostsChartLines = (checked: boolean, field: string, currentColor: string) => {
    // We are doing the below line because we are manually changing the field name from 'undefined' to 'None'
    // in the frontend for display purposes, so the acutal list in the memory is still 'undefined' which won't
    // match the field name in the data we are getting from the backend
    if (field === 'None') {
      field = 'undefined';
    }

    if (availableColors.length == 0 && checked == true) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    if (checked == false && currentColor !== UNCHECKED_COLOR && currentColor !== UNDEFINED_COLOR) {
      setAvailableColors((prevColors) => [...prevColors, currentColor]);
    }

    if (checked == true && availableColors.length == 0 && field.toLowerCase() != 'undefined') {
      return;
    }

    let newColor = UNCHECKED_COLOR;
    if (field.toLowerCase() === 'null') {
      newColor = UNDEFINED_COLOR;
    } else if (checked && availableColors.length > 0) {
      newColor = availableColors.pop() || newCOLORS.red;
    }

    setShowAllocationOfCostsChartLines((prevShowLines) => {
      return prevShowLines.map((line) => {
        if (line.field === field) {
          return {
            ...line,
            show: checked,
            color: checked ? newColor || UNCHECKED_COLOR : UNCHECKED_COLOR,
          };
        }
        return line;
      });
    });
  };

  const initShowAllocationOfCostsChartLines = (data: AllocationOfCostsSummary[]) => {
    setShowAllocationOfCostsChartLines(
      data.map((item) => {
        let color = UNCHECKED_COLOR;
        if (item.field.toLowerCase() === 'null') {
          color = UNDEFINED_COLOR;
        } else if (availableColors.length > 0) {
          color = availableColors.pop() || newCOLORS.green;
        }
        return {
          field: item.field,
          show: item.field.toLowerCase() === 'null' ? false : availableColors.length > 0,
          color,
        } as ShowAllocationOfCostsChartLine;
      }),
    );
  };

  const { teamId = '', subprojectId = '' } = useParams<{ portfolioId: string; teamId: string; subprojectId: string }>();

  const { board } = useContext(BoardContext);

  const { chartData, query } = useAllocationOfCostsMeasures(
    {
      portfolioId: portfolio ? portfolio?.id : null,
      teamId: teamId || null,
      subprojectId: subprojectId || null,
      startDate: startDate,
      endDate: endDate,
    },
    {
      queryKey: ['allocationOfCostsMeasures', portfolio?.id, teamId, subprojectId, startDate, endDate] as const,
      enabled: !!portfolio?.id && !!category && !!startDate && !!endDate,
    },
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(initShowAllocationOfCostsChartLines): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(setQueryFinished): ignore
  useEffect(() => {
    if (chartData && category in chartData) {
      setAvailableColors(Object.assign([], DEFAULT_COLORS));

      const categoryOptions = chartData[category as AllocationOfCostsCategory].options;

      if (!categoryOptions) {
        initShowAllocationOfCostsChartLines([]);
      } else {
        initShowAllocationOfCostsChartLines(
          Object.entries(chartData[category as AllocationOfCostsCategory].options)
            // The first 12 or so items in the list get auto-selected,
            // so we sort them here by total to show the highest values first
            .sort(([_ak, av], [_bk, bv]) => bv.total[chartUnit] - av.total[chartUnit])
            .map(([k, _]) => {
              return { field: k } as AllocationOfCostsSummary;
            }),
        );
      }

      setQueryFinished?.(true);
    } else {
      initShowAllocationOfCostsChartLines([]);
      setQueryFinished?.(true);
    }
  }, [chartData, category, chartUnit]);

  return (
    <AllocationOfCostsContainer ref={downloadRef}>
      <CollapseHeader>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <CollapseIcon>
            {opened ? (
              <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            ) : (
              <Icon icon="icon-park-solid:right-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
            )}
          </CollapseIcon>
          <SmallerHeading>Allocation of costs{getScopeNameSuffix(team, portfolio, board)}</SmallerHeading>
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          {opened && chartData?.[category as AllocationOfCostsCategory] && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StandardText>Value</StandardText>
              <Select
                label=""
                size="xs"
                style={{ width: 125, paddingLeft: '5px', paddingRight: '10px' }}
                value={chartUnit as ViewBy}
                data={CHART_UNITS}
                onChange={(allocationOfCostsUnit) => updateUnit(allocationOfCostsUnit as ViewBy)}
                allowDeselect={false}
              />
            </div>
          )}
          <div ref={exceptionRef} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <FloraButton externalData={[chartData]} size={30} />
            {opened && (
              <DownloadPNGButton
                handleDownload={() => canvasHtmlDownload('Allocation of costs', downloadRef, exceptionRef)}
                exceptionRef={exceptionRef}
              />
            )}
          </div>
        </div>
      </CollapseHeader>
      <Collapse in={opened} ref={errorRef}>
        <CollapseContent>
          {chartData?.[category as AllocationOfCostsCategory] && (
            <Select
              label=""
              size="xs"
              placeholder={i18n.t('common.placeholders.select_category')}
              style={{ width: 150, padding: '10px' }}
              value={category}
              data={Object.values(AllocationOfCostsCategory).map((category) => ({
                value: category,
                label: snakeCaseToTitleCase(category),
              }))}
              onChange={(category) => updateCategory(category as AllocationOfCostsCategory)}
              allowDeselect={false}
            />
          )}
          {showError && (
            <StandardText style={{ paddingLeft: '10px', color: 'red' }}>
              Chart can only display 12 defined values at a time
            </StandardText>
          )}
          {query.isPending ? (
            <LoaderContainer>
              <Loader color={newCOLORS.indigo} size="lg" />
            </LoaderContainer>
          ) : chartData?.[category as AllocationOfCostsCategory]?.options ? (
            <AllocationOfCostsDataContainer>
              <AllocationOfCostsItemBox>
                <AllocationOfCostsItemHeader>
                  <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                    TOTAL{' '}
                    <Tooltip
                      multiline
                      w={300}
                      position="right"
                      label={'Total dollars for the date range selected'}
                      style={{ textAlign: 'left' }}
                    >
                      <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                    </Tooltip>
                  </div>
                  <div style={{ width: 125, textAlign: 'right', fontSize: '11px', fontWeight: 500 }}>
                    AVERAGE{' '}
                    <Tooltip
                      multiline
                      w={300}
                      position="right"
                      label={'Total dollars divided by number of months from the range selected'}
                      style={{ textAlign: 'left' }}
                    >
                      <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                    </Tooltip>
                  </div>
                </AllocationOfCostsItemHeader>
                <AllocationOfCostsItemList
                  totalSummary={Object.entries(chartData[category as AllocationOfCostsCategory].options).map(
                    ([k, v]) => ({
                      field: k,
                      ...v.total,
                    }),
                  )}
                  averageSummary={Object.entries(chartData[category as AllocationOfCostsCategory].options).map(
                    ([k, v]) => ({
                      field: k,
                      ...v.average,
                    }),
                  )}
                  value={chartUnit}
                  showAllocationOfCostsChartLines={showAllocationOfCostsChartLines}
                  updateShowChartLines={updateShowAllocationOfCostsChartLines}
                />
              </AllocationOfCostsItemBox>
              <ChartContainer>
                <AllocationOfCostsChart
                  showAllocationOfCostsChartLines={showAllocationOfCostsChartLines}
                  chartData={chartData[category as AllocationOfCostsCategory].chart_data || null}
                  viewBy={chartUnit as ViewBy}
                  category={category}
                  portfolioId={portfolio?.id}
                />
              </ChartContainer>
            </AllocationOfCostsDataContainer>
          ) : (
            <EmptyStateContainer>
              <img style={{ paddingTop: '125px' }} src={icons.iconWindowGraph} height={100} />
              <StandardText style={{}}>
                There are no completed tasks for this date range. Please select a different date range or team.
              </StandardText>
            </EmptyStateContainer>
          )}
        </CollapseContent>
      </Collapse>
    </AllocationOfCostsContainer>
  );
};

const AllocationOfCostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const AllocationOfCostsItemBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const AllocationOfCostsItemHeader = styled.div`
  width: 400px;
  display: flex;
  justify-content: end;
`;

const CollapseContent = styled.div`
  padding: 12px;
  gap: 1em;
  height: 475px;
`;

const AllocationOfCostsDataContainer = styled.div`
  display: flex;
  padding: 12px;
  gap: 1em;
  height: 400px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 375px;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const EmptyStateContainer = styled.div`
  padding: 12px;
  gap: 1em;
  height: 375px;
  text-align: center;
`;
