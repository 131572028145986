import { styled } from '@linaria/react';
import { Group, Select, Tabs } from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { useBoardsByTeam } from '../../api/projects-client/projects-client.hooks';
import { Board, Team } from '../../api/projects-client/projects-client.type';
import { Epic } from '../../api/tasks-client/task-client.type';
import { BrandedLoadingOverlay } from '../../components/loader/branded-loader';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { useGlobalStore } from '../../store/global-store/global-store';
import { baseWhite, inkLight, secondaryBase } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { ProcessV2 } from '../process-v2/process-v2';
import { TaskType } from '../tasks-table/tasks-table.type';
import { ProcessProvider } from './context/process.context';
import { filterProcessMapping } from './process.helpers';
import { useComparisonLeftData, useComparisonRightData, useProcessScope, useSingleViewData } from './process.hooks';
import { PageContainer } from './process.styled';
import { ComparisonBoard, ComparisonTeam, ProcessViewMode, ViewType } from './process.type';
import { SectionGraph } from './section-graph';
import { SectionSummary } from './section-summary';

const showComparisonView = import.meta.env.VITE_FEATURE_FLAG_PROCESS_MAP_COMPARISON === 'true';
const processV2Enabled = import.meta.env.VITE_FEATURE_FLAG_PROCESS_MAP_V2 === 'true';

export function ProcessPage() {
  useDocumentTitle('Process Map - Bloomfilter');

  const globalPortfolio = useGlobalStore((state) => state.portfolio);
  const { portfolio, team, board, taskType, epic, startDate, endDate, setScope } = useProcessScope();

  // Add state for view mode (single or comparison)
  const [viewMode, setViewMode] = useState<ProcessViewMode>(ProcessViewMode.SINGLE);

  // Add state for the first team/board in comparison mode (left side)
  const [leftTeam, setLeftTeam] = useState<ComparisonTeam | null>(null);
  const [leftBoard, setLeftBoard] = useState<ComparisonBoard | null>(null);

  // Add state for the second team/board in comparison mode (right side)
  const [rightTeam, setRightTeam] = useState<ComparisonTeam | null>(null);
  const [rightBoard, setRightBoard] = useState<ComparisonBoard | null>(null);

  // Initialize left team/board with current team/board when switching to comparison mode
  useEffect(() => {
    if (viewMode === ProcessViewMode.COMPARISON && team && !leftTeam) {
      setLeftTeam({
        id: team.id,
        name: team.name,
      });

      if (board) {
        setLeftBoard({
          id: board.id,
          name: board.name,
          team_id: team.id,
        });
      }
    }
  }, [viewMode, team, board, leftTeam]);

  const [viewType, setViewType] = useState<ViewType>(ViewType.Count);

  // Use the new hooks for data fetching
  const { mapping, graphData, query } = useSingleViewData({
    team,
    board,
    taskType,
    epic,
    startDate,
    endDate,
    useBloomfilterSections: true,
  });

  const {
    mapping: leftMapping,
    graphData: leftGraphData,
    query: leftQuery,
  } = useComparisonLeftData({
    team: leftTeam,
    board: leftBoard,
    taskType,
    epic,
    startDate,
    endDate,
    viewMode,
  });

  const {
    mapping: rightMapping,
    graphData: rightGraphData,
    query: rightQuery,
  } = useComparisonRightData({
    team: rightTeam,
    board: rightBoard,
    taskType,
    epic,
    startDate,
    endDate,
    viewMode,
  });

  // Store refetch functions in refs to avoid dependency issues
  const refetchFunctionsRef = useRef({
    left: leftQuery.refetch,
    right: rightQuery.refetch,
  });

  // Update refs when query objects change
  useEffect(() => {
    refetchFunctionsRef.current = {
      left: leftQuery.refetch,
      right: rightQuery.refetch,
    };
  }, [leftQuery.refetch, rightQuery.refetch]);

  // Initialize lastScopeRef with initial values
  const lastScopeRef = useRef({
    taskType,
    startDate,
    endDate,
    epicId: epic?.id,
    leftTeamId: leftTeam?.id,
    leftBoardId: leftBoard?.id,
    rightTeamId: rightTeam?.id,
    rightBoardId: rightBoard?.id,
  });

  // Memoize the current scope to avoid unnecessary re-renders
  const currentScope = useMemo(
    () => ({
      taskType,
      startDate,
      endDate,
      epicId: epic?.id,
    }),
    [taskType, startDate, endDate, epic?.id],
  );

  // Memoize the current team/board selections
  const currentSelections = useMemo(
    () => ({
      leftTeamId: leftTeam?.id,
      leftBoardId: leftBoard?.id,
      rightTeamId: rightTeam?.id,
      rightBoardId: rightBoard?.id,
    }),
    [leftTeam?.id, leftBoard?.id, rightTeam?.id, rightBoard?.id],
  );

  // Trigger refetch when scope changes in comparison view
  useEffect(() => {
    if (viewMode === ProcessViewMode.COMPARISON) {
      // Check if scope has actually changed to avoid unnecessary refetches
      const scopeChanged =
        currentScope.taskType !== lastScopeRef.current.taskType ||
        currentScope.startDate !== lastScopeRef.current.startDate ||
        currentScope.endDate !== lastScopeRef.current.endDate ||
        currentScope.epicId !== lastScopeRef.current.epicId;

      if (scopeChanged) {
        lastScopeRef.current = {
          ...lastScopeRef.current,
          taskType: currentScope.taskType,
          startDate: currentScope.startDate,
          endDate: currentScope.endDate,
          epicId: currentScope.epicId,
        };

        if (leftTeam?.id) {
          refetchFunctionsRef.current.left();
        }
        if (rightTeam?.id) {
          refetchFunctionsRef.current.right();
        }
      }
    }
  }, [viewMode, leftTeam?.id, rightTeam?.id, currentScope]);

  // Trigger refetch when team/board selections change
  useEffect(() => {
    if (viewMode === ProcessViewMode.COMPARISON) {
      // Check if team/board selections have changed
      const selectionsChanged =
        currentSelections.leftTeamId !== lastScopeRef.current.leftTeamId ||
        currentSelections.leftBoardId !== lastScopeRef.current.leftBoardId ||
        currentSelections.rightTeamId !== lastScopeRef.current.rightTeamId ||
        currentSelections.rightBoardId !== lastScopeRef.current.rightBoardId;

      if (selectionsChanged) {
        lastScopeRef.current = {
          ...lastScopeRef.current,
          leftTeamId: currentSelections.leftTeamId,
          leftBoardId: currentSelections.leftBoardId,
          rightTeamId: currentSelections.rightTeamId,
          rightBoardId: currentSelections.rightBoardId,
        };

        if (
          currentSelections.leftTeamId !== undefined &&
          (currentSelections.leftTeamId !== lastScopeRef.current.leftTeamId ||
            currentSelections.leftBoardId !== lastScopeRef.current.leftBoardId)
        ) {
          refetchFunctionsRef.current.left();
        }

        if (
          currentSelections.rightTeamId !== undefined &&
          (currentSelections.rightTeamId !== lastScopeRef.current.rightTeamId ||
            currentSelections.rightBoardId !== lastScopeRef.current.rightBoardId)
        ) {
          refetchFunctionsRef.current.right();
        }
      }
    }
  }, [viewMode, currentSelections]);

  useEffect(() => {
    if (globalPortfolio?.id && globalPortfolio?.id !== portfolio?.id) {
      setScope({ portfolio: globalPortfolio });
    }
    if (team === null && globalPortfolio?.projects?.length && globalPortfolio?.projects?.length > 0) {
      setScope({ team: globalPortfolio.projects[0] });
    }
  }, [portfolio, setScope, globalPortfolio, team]);

  const filteredMapping = useMemo(() => filterProcessMapping(mapping), [mapping]);
  const filteredLeftMapping = useMemo(() => (leftMapping ? filterProcessMapping(leftMapping) : null), [leftMapping]);
  const filteredRightMapping = useMemo(
    () => (rightMapping ? filterProcessMapping(rightMapping) : null),
    [rightMapping],
  );

  const header = (
    <Group>
      <Icon name="timeline" color={secondaryBase} size={36} />
      <H2>Process Map</H2>
      {showComparisonView ? (
        <StyledTabs
          variant="pills"
          radius="xl"
          value={viewMode}
          onChange={(value) => setViewMode(value as ProcessViewMode)}
        >
          <Tabs.List>
            <Tabs.Tab
              value={ProcessViewMode.SINGLE}
              leftSection={
                <Icon name="view_agenda" size={16} color={viewMode === ProcessViewMode.SINGLE ? baseWhite : inkLight} />
              }
            >
              Single View
            </Tabs.Tab>
            <Tabs.Tab
              value={ProcessViewMode.COMPARISON}
              leftSection={
                <Icon name="compare" size={16} color={viewMode === ProcessViewMode.COMPARISON ? baseWhite : inkLight} />
              }
            >
              Comparison View
            </Tabs.Tab>
          </Tabs.List>
        </StyledTabs>
      ) : null}
    </Group>
  );

  const isComparisonLoading =
    viewMode === ProcessViewMode.COMPARISON && (leftQuery?.isFetching || rightQuery?.isFetching);

  const handleScopeChange = (scopeChange: {
    startDate?: string;
    endDate?: string;
    taskType?: TaskType | null;
    epic?: Epic | null;
    team?: Team | null;
    board?: Board | null;
  }) => {
    setScope(scopeChange);
  };

  return (
    <MenuDrivenContainer header={header}>
      <BrandedLoadingOverlay
        visible={query.isFetching || isComparisonLoading}
        transitionDuration={30}
        variant="colored"
      />
      <PageContainer>
        {viewMode === ProcessViewMode.SINGLE ? (
          // Single view mode
          <>
            <ReactFlowProvider>
              <ProcessProvider
                initialScope={{
                  portfolio,
                  team,
                  board,
                  epic,
                  startDate,
                  endDate,
                  taskType,
                }}
              >
                <SectionGraph
                  onScopeChange={handleScopeChange}
                  graphData={graphData}
                  mapping={filteredMapping}
                  viewType={viewType}
                  setViewType={setViewType}
                />
                <SectionSummary completedTasks={graphData?.task_count || 0} />
              </ProcessProvider>
            </ReactFlowProvider>
          </>
        ) : (
          // Comparison view mode
          <>
            <ComparisonSelectionRow>
              <ComparisonTeamSelector
                title="Team 1"
                selectedTeam={leftTeam}
                onSelectTeam={setLeftTeam}
                selectedBoard={leftBoard}
                onSelectBoard={setLeftBoard}
              />
              <ComparisonTeamSelector
                title="Team 2"
                selectedTeam={rightTeam}
                onSelectTeam={setRightTeam}
                selectedBoard={rightBoard}
                onSelectBoard={setRightBoard}
              />
            </ComparisonSelectionRow>
            <ComparisonContainer>
              <ComparisonColumn>
                {leftTeam && leftGraphData && (
                  <ReactFlowProvider
                    key={`left-flow-${leftTeam.id}-${leftBoard?.id || 'no-board'}-${startDate}-${endDate}-${taskType || 'no-type'}`}
                  >
                    <ProcessProvider
                      key={`left-provider-${leftTeam.id}-${leftBoard?.id || 'no-board'}-${startDate}-${endDate}-${taskType || 'no-type'}`}
                      initialScope={{
                        portfolio,
                        team: {
                          ...leftTeam,
                          subprojects: [], // Required property for Team type
                        },
                        board: leftBoard,
                        epic,
                        startDate: startDate || '',
                        endDate: endDate || '',
                        taskType,
                      }}
                    >
                      <SectionGraph
                        graphData={leftGraphData}
                        mapping={filteredLeftMapping}
                        viewType={viewType}
                        setViewType={setViewType}
                        isComparisonView={true}
                        teamLabel={leftTeam?.name || 'Team 1'}
                        onScopeChange={handleScopeChange}
                      />
                    </ProcessProvider>
                  </ReactFlowProvider>
                )}
              </ComparisonColumn>
              <ComparisonColumn>
                {rightTeam && rightGraphData && (
                  <ReactFlowProvider
                    key={`right-flow-${rightTeam.id}-${rightBoard?.id || 'no-board'}-${startDate}-${endDate}-${taskType || 'no-type'}`}
                  >
                    <ProcessProvider
                      key={`right-provider-${rightTeam.id}-${rightBoard?.id || 'no-board'}-${startDate}-${endDate}-${taskType || 'no-type'}`}
                      initialScope={{
                        portfolio,
                        team: {
                          ...rightTeam,
                          subprojects: [], // Required property for Team type
                        },
                        board: rightBoard,
                        epic,
                        startDate: startDate || '',
                        endDate: endDate || '',
                        taskType,
                      }}
                    >
                      <SectionGraph
                        graphData={rightGraphData}
                        mapping={filteredRightMapping}
                        viewType={viewType}
                        setViewType={setViewType}
                        isComparisonView={true}
                        teamLabel={rightTeam?.name || 'Team 2'}
                        onScopeChange={handleScopeChange}
                      />
                    </ProcessProvider>
                  </ReactFlowProvider>
                )}
              </ComparisonColumn>
            </ComparisonContainer>
          </>
        )}
      </PageContainer>
    </MenuDrivenContainer>
  );
}

// Styled components for the comparison view
const StyledTabs = styled(Tabs)`
  .mantine-Tabs-tab {
    color: ${inkLight};
    font-weight: 500;
  }

  .mantine-Tabs-tab[data-active] {
    background-color: ${secondaryBase};
    color: ${baseWhite};
  }
`;

const ComparisonSelectionRow = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin-bottom: 16px;
`;

const ComparisonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  height: 100%;
  min-height: 900px; /* Ensure enough vertical space for the taller process maps */
  overflow-y: auto; /* Add scrolling if needed */
`;

const ComparisonColumn = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Component for team selection in comparison view
const ComparisonTeamSelector = ({
  title,
  selectedTeam,
  onSelectTeam,
  selectedBoard,
  onSelectBoard,
}: {
  title: string;
  selectedTeam: ComparisonTeam | null;
  onSelectTeam: (team: ComparisonTeam | null) => void;
  selectedBoard: ComparisonBoard | null;
  onSelectBoard: (board: ComparisonBoard | null) => void;
}) => {
  // Get teams from global store
  const teams = useGlobalStore((state) => state.teams || []) as ComparisonTeam[];

  // Fetch boards for the selected team using the useBoardsByTeam hook
  const { boards } = useBoardsByTeam(selectedTeam?.id || '', {
    enabled: Boolean(selectedTeam?.id),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Map the fetched boards to the ComparisonBoard type
  const teamBoards: ComparisonBoard[] = useMemo(() => {
    if (!selectedTeam || !boards) return [];

    return boards.map((board) => ({
      id: board.id,
      name: board.name,
      team_id: selectedTeam.id,
    }));
  }, [selectedTeam, boards]);

  return (
    <SelectorContainer>
      <h3>Select {title}</h3>
      <Select
        label="Team"
        placeholder="Select a team"
        data={teams.map((team: ComparisonTeam) => ({ value: team.id, label: team.name }))}
        value={selectedTeam?.id || null}
        onChange={(value) => {
          const team = teams.find((t: ComparisonTeam) => t.id === value);
          onSelectTeam(team || null);
          onSelectBoard(null);
        }}
        searchable
        clearable
      />

      {selectedTeam && (
        <Select
          label="Board"
          placeholder="Select a board"
          data={teamBoards.map((board: ComparisonBoard) => ({ value: board.id, label: board.name }))}
          value={selectedBoard?.id || null}
          onChange={(value) => {
            const board = teamBoards.find((b: ComparisonBoard) => b.id === value);
            onSelectBoard(board || null);
          }}
          searchable
          clearable
          disabled={teamBoards.length === 0}
        />
      )}
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div`
  padding: 16px;
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #e9ecef;
  
  h3 {
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .mantine-Select-root {
    margin-bottom: 16px;
  }
`;

export function Process() {
  return processV2Enabled ? <ProcessV2 /> : <ProcessPage />;
}
