import { styled } from '@linaria/react';
import { Group, Indicator } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { primaryBase, secondaryLight } from '../../styles/design-tokens';
import { Beta } from '../../ui-library/beta/beta';
import { Icon } from '../../ui-library/icon/icon';
import { Tooltip } from '../../ui-library/tooltip/tooltip';
import { Text } from '../../ui-library/typography/typography';
import { NavItem } from './side-bar.type';

type Props = {
  item: NavItem;
  sidebarOpen: boolean;
  active: boolean;
};

export const SidebarItem = ({ item, sidebarOpen, active }: Props) => {
  const navigate = useNavigate();

  if (!item.show) {
    return null;
  }

  const onNavItemClicked = (event: React.MouseEvent, clickedItem: NavItem) => {
    event.preventDefault();
    navigate(clickedItem.path);
  };

  const icon = <Icon name={item.icon} color={secondaryLight} style={{ cursor: 'pointer' }} />;
  const style = active
    ? { border: '1px solid var(--secondary-light)', background: 'var(--sky-lightest)' }
    : { border: '1px solid transparent' };

  return (
    <Indicator disabled={sidebarOpen || !item.isBeta} size={6} offset={5.5} color={primaryBase}>
      <Container data-active={active} key={item.label} onClick={(event) => onNavItemClicked(event, item)} style={style}>
        {sidebarOpen ? (
          <Group w="100%" justify="space-between">
            <Group gap={16}>
              {icon}
              <Text weight="medium">{item.label}</Text>
            </Group>
            {item.isBeta && <Beta />}
          </Group>
        ) : (
          <Tooltip label={item.label} variant="white" position="right" offset={12}>
            {icon}
          </Tooltip>
        )}
      </Container>
    </Indicator>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 6px;
  text-decoration: none;
  color: var(--base-black);
  border-radius: 32px;
  cursor: pointer;

  &:hover {
    background: var(--sky-lightest);
  }
`;
