import { MeasureMetadata, TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';
import { Target, TargetComparison, TargetPayload, TransformedTarget } from './targets-client.type';
import { TargetForm, TargetFormObject } from './targets.types';

/**
 * Returns a formatted string representation of a target comparison
 *
 * @param {TargetComparison} comparison - The type of target comparison
 * @param {number} value - The target value
 * @param {number | null} upperValue - The upper target value for range comparisons
 * @returns {string} Formatted string representation of the comparison
 */
const getTargetComparisonSymbol = (comparison: TargetComparison, value: number, upperValue: number | null): string => {
  switch (comparison) {
    case TargetComparison.LT:
      return `<${value}`;
    case TargetComparison.LTE:
      return `≤${value}`;
    case TargetComparison.EQ:
      return `=${value}`;
    case TargetComparison.GTE:
      return `≥${value}`;
    case TargetComparison.GT:
      return `>${value}`;
    case TargetComparison.RANGE_IN:
      return `[${value}, ${upperValue}]`;
    case TargetComparison.RANGE_EX:
      return `(${value}, ${upperValue})`;
    default:
      return `=${value}`;
  }
};

/**
 * Returns a human-readable label for a target comparison type
 *
 * @param {TargetComparison} comparison - The type of target comparison
 * @returns {string} Human-readable label for the comparison type
 */
const getTargetComparisonLabel = (comparison: TargetComparison): string => {
  switch (comparison) {
    case TargetComparison.LT:
      return 'Less than (<)';
    case TargetComparison.LTE:
      return 'Less than or equal (≤)';
    case TargetComparison.EQ:
      return 'Equal (=)';
    case TargetComparison.GTE:
      return 'Greater than or equal (≥)';
    case TargetComparison.GT:
      return 'Greater than (>)';
    case TargetComparison.RANGE_IN:
      return 'Between (inclusive)';
    case TargetComparison.RANGE_EX:
      return 'Between (exclusive)';
    default:
      return 'Equal (=)';
  }
};

/**
 * Converts a Target object to a TargetForm object for use in forms
 *
 * @param {Target} target - The target object from the API
 * @returns {TargetForm} Form-friendly representation of the target
 */
const convertTargetToForm = (target: Target): TargetForm => {
  // Determine object type and objects based on which relationship array is populated
  let object_type: 'portfolio' | 'project' | 'board' | 'initiative' = 'board';
  let objects: TargetFormObject[] = [];

  if (target.portfolios?.length > 0) {
    object_type = 'portfolio';
    objects = target.portfolios.map((p) => ({ id: p.id, name: p.name }));
  } else if (target.projects?.length > 0) {
    object_type = 'project';
    objects = target.projects.map((p) => ({ id: p.id, name: p.name }));
  } else if (target.subprojects?.length > 0) {
    object_type = 'board';
    objects = target.subprojects.map((p) => ({ id: p.id, name: p.name }));
  } else if (target.initiatives?.length > 0) {
    object_type = 'initiative';
    objects = target.initiatives.map((i) => ({
      id: i.id,
      name: i.name,
      start_date: i.start_date,
      end_date: i.end_date,
    }));
  }

  const formatDate = (dateString: string | null) => {
    if (!dateString) return null;
    return new Date(dateString).toISOString().split('T')[0];
  };

  return {
    id: target.id,
    name: target.name || '',
    measure: target.measure || '',
    target_value: target.target_value,
    target_value_upper: target.target_value_upper,
    target_comparison: target.target_comparison,
    use_transform: target.use_transform,
    is_active: target.is_active ?? true,
    start_date: formatDate(target.start_date) ?? '',
    end_date: formatDate(target.end_date),
    time_allocation_type: target.time_allocation_type,
    is_work_period_target: target.is_work_period_target ?? false,
    object_type,
    objects,
  };
};

/**
 * Converts a TargetForm object to a TargetPayload object for API requests
 *
 * @param {TargetForm} form - The form data to convert
 * @returns {Partial<TargetPayload>} API-friendly representation of the target
 */
const convertFormToTargetPayload = (form: TargetForm): Partial<TargetPayload> => {
  const target: Partial<TargetPayload> = {
    id: form.id,
    name: form.name,
    measure: form.measure,
    target_value: form.target_value,
    target_value_upper: form.target_value_upper,
    target_comparison: form.target_comparison,
    is_active: form.is_active,
    start_date: form.start_date ?? undefined,
    end_date: form.end_date ?? undefined,
    time_allocation_type: form.time_allocation_type,
    is_work_period_target: form.is_work_period_target,
    use_transform: form.use_transform,
    portfolios: form.object_type === 'portfolio' ? form.objects.map((o) => o.id) : [],
    projects: form.object_type === 'project' ? form.objects.map((o) => o.id) : [],
    subprojects: form.object_type === 'board' ? form.objects.map((o) => o.id) : [],
    initiatives: form.object_type === 'initiative' ? form.objects.map((o) => o.id) : [],
  };
  return target;
};

/**
 * Determines the scope of a target (Sprint, Portfolio, Project, or Board)
 *
 * @param {Target} target - The target to determine the scope for
 * @returns {string} The scope of the target
 */
const getTargetScope = (target: Target): string => {
  if (target.is_work_period_target) return 'Sprint';
  if (target.portfolios?.length > 0) return 'Portfolio';
  if (target.projects?.length > 0) return 'Project';
  if (target.subprojects?.length > 0) return 'Board';
  if (target.initiatives?.length > 0) return 'Initiative';
  return '-';
};

/**
 * Returns a human-readable label for a time allocation type
 *
 * @param {TimeAllocationType | null} timeAllocationType - The time allocation type
 * @returns {string | null} Human-readable label for the time allocation type or null if not provided
 */
const getTimeAllocationTypeLabel = (timeAllocationType: TimeAllocationType | null): string | null => {
  switch (timeAllocationType) {
    case TimeAllocationType.Daily:
      return 'Daily';
    case TimeAllocationType.Weekly:
      return 'Weekly';
    case TimeAllocationType.BiWeekly:
      return 'Bi-Weekly';
    case TimeAllocationType.Monthly:
      return 'Monthly';
    default:
      return null;
  }
};

/**
 * Returns the options for time allocation type based on the object type
 *
 * @param {string | null} objectType - The type of object (portfolio, project, board, initiative)
 * @returns {Array<{ value: TimeAllocationType; label: string }>} Array of options for time allocation type
 */
const getTimeAllocationTypeOptions = (objectType: string | null): { value: TimeAllocationType; label: string }[] => {
  return Object.values(TimeAllocationType)
    .filter((type) => {
      // If object type is initiative, only allow biweekly
      if (objectType === 'initiative') {
        return type === TimeAllocationType.BiWeekly;
      }
      return true;
    })
    .map((type) => ({
      value: type,
      label: getTimeAllocationTypeLabel(type) as string,
    }));
};

/**
 * Returns the fill configuration for target lines based on the target comparison type.
 * For greater than comparisons, fills below the line.
 * For less than comparisons, fills above the line.
 * For range comparisons, fills between the lines if a fillTarget is provided.
 *
 * @param {TargetComparison} target_comparison - The type of target comparison
 * @param {string} color - The color to use for the fill, with 33 opacity
 * @param {string} [fillTarget] - Optional ID of target to fill to for range comparisons
 * @returns {boolean | object} Fill configuration object or false if no fill needed
 */
const getTargetLineFill = (
  target_comparison: TargetComparison,
  color: string,
  fillTarget?: string,
): boolean | object => {
  switch (target_comparison) {
    case TargetComparison.GT:
    case TargetComparison.GTE:
      return {
        target: 'end',
        below: `${color}33`,
      };
    case TargetComparison.LT:
    case TargetComparison.LTE:
      return {
        target: 'origin',
        above: `${color}33`,
      };
    case TargetComparison.RANGE_EX:
    case TargetComparison.RANGE_IN:
      if (fillTarget) {
        return {
          target: '+1',
          below: `${color}33`,
        };
      }
      return false;
    default:
      return false;
  }
};

/**
 * Returns the border dash for the target line based on the target comparison.
 * Inclusive targets are dashed, exclusive targets are solid.
 *
 * @param {TransformedTarget} target - The target data
 * @returns {number[]} The border dash for the target line
 */
const getTargetLineBorderDash = (target: TransformedTarget): number[] => {
  switch (target.target_comparison) {
    case TargetComparison.GT:
    case TargetComparison.LT:
    case TargetComparison.RANGE_EX:
      return [5, 5];
    default:
      return [0, 0];
  }
};

/**
 * Generates chart datasets for target lines
 *
 * @param {TransformedTarget} targetData - The transformed target data
 * @param {MeasureMetadata} measureMetadata - Metadata for the measure
 * @param {string} color - The color to use for the target lines
 * @param {string} yAxisId - The ID of the y-axis to use
 * @returns {any[]} Array of chart dataset configurations
 */
const getTargetLineDatasets = (
  targetData: TransformedTarget,
  measureMetadata: MeasureMetadata,
  color: string,
  yAxisId: string,
): any[] => {
  if (
    targetData.target_comparison === TargetComparison.RANGE_EX ||
    targetData.target_comparison === TargetComparison.RANGE_IN
  ) {
    const sortedTimeAllocations = Object.keys(targetData.time_allocations)
      .sort()
      .map((key) => targetData.time_allocations[key]);

    const lower = sortedTimeAllocations.map((value) =>
      typeof value === 'object' && value !== null && 'value_lower' in value ? value.value_lower : null,
    );
    const upper = sortedTimeAllocations.map((value) =>
      typeof value === 'object' && value !== null && 'value_upper' in value ? value.value_upper : null,
    );

    const lowerDatasetId = `${measureMetadata.measure_name}-lower`;
    const upperDatasetId = `${measureMetadata.measure_name}-upper`;

    return [
      {
        label: `${measureMetadata.measure_title} Target Lower`,
        id: lowerDatasetId,
        data: lower,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(targetData),
        yAxisID: yAxisId,
        tension: 0,
        fill: getTargetLineFill(targetData.target_comparison, color, upperDatasetId),
      },
      {
        label: `${measureMetadata.measure_title} Target Upper`,
        id: upperDatasetId,
        data: upper,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(targetData),
        yAxisID: yAxisId,
        tension: 0,
      },
    ];
  } else {
    const data = Object.keys(targetData.time_allocations)
      .sort()
      .map((key) => targetData.time_allocations[key]);

    return [
      {
        label: `${measureMetadata.measure_title} Target`,
        data: data,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(targetData),
        yAxisID: yAxisId,
        tension: 0,
        fill: getTargetLineFill(targetData.target_comparison, color),
      },
    ];
  }
};

export {
  convertFormToTargetPayload,
  convertTargetToForm,
  getTargetComparisonLabel,
  getTargetComparisonSymbol,
  getTargetLineBorderDash,
  getTargetLineDatasets,
  getTargetLineFill,
  getTargetScope,
  getTimeAllocationTypeLabel,
  getTimeAllocationTypeOptions,
};
