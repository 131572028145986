import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useImperativeHandle } from 'react';
import { useEpics } from '../../../api/process-client/process-client.hooks';
import { Epic } from '../../../api/tasks-client/task-client.type';
import { StandardText } from '../../../styles/new-shared-styled-components/new-shared-styled-components';
import { ProcessContext } from '../context/process.context';
import { useProcessScope } from '../process.hooks';
import { commonSelectProps } from './scope.helpers';

export type EpicSelectRef = {
  getEpics: () => Epic[];
};

export type EpicSelectProps = {
  handleEpicSelected?: (epic: Epic | null) => void;
  hideLabel?: boolean;
  includeAggregate?: boolean;
};

export const EpicSelect = forwardRef<EpicSelectRef, EpicSelectProps>(({ hideLabel, handleEpicSelected }, ref) => {
  const { portfolio, team, board, epic, setScope } = useProcessScope();
  const { setStages } = useContext(ProcessContext);

  const { epics } = useEpics(
    { portfolioId: portfolio?.id || null, projectId: team?.id || null, subprojectId: board?.id || null },
    {
      queryKey: ['epics', portfolio?.id, team?.id, board?.id],
      enabled: Boolean(team?.id),
      staleTime: 1000 * 60 * 5,
    },
  );

  const epicSelected = (newEpic: Epic | null) => {
    if (newEpic && epic && newEpic.id === epic.id) {
      return;
    }

    setScope({
      epic: newEpic,
    });
    setStages([]);

    handleEpicSelected && handleEpicSelected(newEpic);
  };

  useImperativeHandle(ref, () => ({
    getEpics: () => epics || [],
  }));

  function refactorDropdownData(epics: Epic[]) {
    return (epics || []).map((e) => ({ value: e.id, label: e.name }));
  }

  return (
    <Fragment>
      {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Epic</StandardText>}
      <Select
        placeholder="Select an epic"
        data={refactorDropdownData(epics || [])}
        // explicitly set null to clear the value from mantine select. Setting undefined will keep the value visible!
        value={epic ? epic.id : null}
        onChange={(value) => {
          if (value) {
            const selectedEpic = (epics || []).find((e) => e.id === value);
            if (selectedEpic) {
              epicSelected(selectedEpic);
            }
          } else {
            epicSelected(null);
          }
        }}
        clearable
        searchable
        allowDeselect={false}
        {...commonSelectProps}
      />
    </Fragment>
  );
});
