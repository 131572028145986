import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Flex, LoadingOverlay, ScrollArea, Table, UnstyledButton } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState, useTransition } from 'react';
import { useExportTasks } from '../../api/tasks-client/tasks-client.hooks';
import i18n from '../../base-dictionary';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { toLocalDate } from '../../helpers/timezone/timezone';
import { newCOLORS } from '../../styles/colors';
import { Text } from '../../ui-library/typography/typography';
import { icons } from './assets';
import { BackToTopButton, CollapseAllButton } from './buttons';
import { GroupingSelect } from './grouping-select';
import { EpicCell, InitiativeCell } from './tasks-table-cells';
import { groupingData } from './tasks-table.data';
import {
  formatRecords,
  getGroupTitle,
  getGroups,
  getInformationLabel,
  getMappedPercentage,
  getStoryPointsText,
  getTaskTypeText,
  sortGroupKeys,
  sortRecords,
} from './tasks-table.helpers';
import { useCollapseState } from './tasks-table.hooks';
import {
  CollapseState,
  Grouping,
  SortedColumn,
  TaskRecord,
  TasksTableProps,
  ToggleCollapseValue,
} from './tasks-table.type';

export function TasksTable({ tasks, dateRange }: TasksTableProps) {
  useDocumentTitle('Task Details - Bloomfilter');

  const [isPending, startTransition] = useTransition();
  const [grouping, setGrouping] = useState<Grouping | null>(null);
  const [backToTopVisible, setBackToTopVisible] = useState<boolean>(false);
  const { mutateAsync: exportTasks } = useExportTasks();

  const groups = getGroups(grouping, tasks);
  const { collapseState, setCollapseState, expandAll, collapseAll } = useCollapseState(groups);

  const viewport = useRef<HTMLDivElement>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies(grouping): ignore
  useEffect(() => {
    viewport.current!.scrollTo({ top: 0, behavior: 'instant' });
  }, [grouping]);

  const allGroupsExpanded = Object.keys(collapseState).every(
    (key) => !collapseState[key as keyof typeof collapseState],
  );

  const updateGrouping = (grouping: Grouping | null) => startTransition(() => setGrouping(grouping));
  const updateCollapseState = (value: ToggleCollapseValue) => {
    if (value === ToggleCollapseValue.EXPAND_ALL) {
      expandAll();
    } else {
      collapseAll();
    }
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Flex gap={16}>
            <Title>{i18n.t('common.related_tasks', { work_units: i18n.t('common.work_units') })}</Title>
            {dateRange && <Text>{`(${dateRange})`}</Text>}
          </Flex>
          <Information grouping={grouping} tasks={tasks} />
        </TitleContainer>
        <Flex align="center" gap={24}>
          <GroupingSelect grouping={grouping} setGrouping={updateGrouping} />
          <Icon
            icon="humbleicons:download"
            color={newCOLORS.darkGray}
            style={{ cursor: 'pointer', width: '36px', height: '36px', margin: '0 8px' }}
            onClick={async () => {
              if (tasks.length === 0) {
                return;
              }
              try {
                const taskIds = tasks.map((task) => task.id.toString());
                const blob = await exportTasks(taskIds);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `task_details_${dayjs().format('YYYY_MM_DD')}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              } catch (error) {
                console.error('Failed to export tasks:', error);
              }
            }}
          />
          {grouping && (
            <CollapseAllButton
              value={allGroupsExpanded ? ToggleCollapseValue.COLLAPSE_ALL : ToggleCollapseValue.EXPAND_ALL}
              onClick={updateCollapseState}
            />
          )}
        </Flex>
      </Header>
      <LoadingOverlay visible={isPending} loaderProps={{ color: newCOLORS.indigo2 }} />
      <StyledScrollArea
        h="100%"
        style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}
        type="always"
        scrollbars="y"
        scrollbarSize={10}
        viewportRef={viewport}
        onScrollPositionChange={(position) => setBackToTopVisible(position.y > 0)}
      >
        <Groups
          groups={groups}
          grouping={grouping}
          tasks={formatRecords(tasks)}
          collapseState={collapseState}
          setCollapseState={setCollapseState}
        />
      </StyledScrollArea>
      <BackToTopButton
        onClick={() => viewport.current!.scrollTo({ top: 0, behavior: 'smooth' })}
        visible={backToTopVisible}
      />
    </Container>
  );
}

const Information = ({ grouping, tasks }: { grouping: Grouping | null; tasks: TaskRecord[] }) => {
  if (!grouping) {
    return null;
  }

  const percentage = getMappedPercentage(grouping, tasks);
  const label = getInformationLabel(grouping);

  return (
    <InformationLabel>
      <img src={icons.iconInformationCircle} width={12} height={12} />
      <span>{`${percentage}% ${label}`}</span>
    </InformationLabel>
  );
};

const Groups = ({
  groups,
  grouping,
  tasks,
  collapseState,
  setCollapseState,
}: {
  groups: Record<string, TaskRecord[]>;
  grouping: Grouping | null;
  tasks: TaskRecord[];
  collapseState: CollapseState;
  setCollapseState: React.Dispatch<React.SetStateAction<CollapseState>>;
}) => {
  if (!grouping) {
    return <DataTable tasks={tasks} />;
  }
  const sortedGroupKeys = sortGroupKeys(grouping, Object.keys(groups));

  return (
    <Flex direction={'column'} gap={16}>
      {sortedGroupKeys.map((groupKey) => (
        <GroupSection
          grouping={grouping}
          groupKey={groupKey}
          tasks={groups[groupKey]}
          key={`${grouping}-${groupKey}`}
          collapseState={collapseState}
          setCollapseState={setCollapseState}
        />
      ))}
    </Flex>
  );
};

const GroupSection = ({
  grouping,
  groupKey,
  tasks,
  collapseState,
  setCollapseState,
}: {
  grouping: Grouping | null;
  groupKey: string;
  tasks: TaskRecord[];
  collapseState: CollapseState;
  setCollapseState: React.Dispatch<React.SetStateAction<CollapseState>>;
}) => {
  const groupCollapsed = collapseState[groupKey];

  const groupingDataObject = groupingData.find((data) => data.value === grouping);

  if (!grouping || !groupingDataObject) {
    return null;
  }

  const groupIcon = groupKey === 'unmapped' ? icons.iconGroupingUnmapped : groupingDataObject.icon;
  const collapseIcon = groupCollapsed ? icons.iconGroupingCollapseDown : icons.iconGroupingCollapseUp;

  const bgColor = groupKey === 'unmapped' ? newCOLORS.lighterGray2 : groupingDataObject.bgColor;
  const labelColor = groupKey === 'unmapped' ? newCOLORS.darkerGray2 : groupingDataObject.labelColor;

  return (
    <GroupContainer>
      <GroupHeader style={{ backgroundColor: bgColor }}>
        <Flex align="center" gap={16}>
          <img src={groupIcon} height={14} width={14} />
          <GroupHeaderLabel style={{ color: labelColor, width: 900 }}>
            {getGroupTitle(grouping, groupKey)}
          </GroupHeaderLabel>
        </Flex>
        <Flex align="center" gap={24}>
          <GroupHeaderLabel
            style={{ color: newCOLORS.darkerGray2, fontSize: 12 }}
          >{`Tasks: ${tasks.length}`}</GroupHeaderLabel>
          <img
            src={collapseIcon}
            onClick={() => {
              setCollapseState({
                ...collapseState,
                [groupKey]: !groupCollapsed,
              });
            }}
            width={8}
            height={8}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      </GroupHeader>
      <Collapse in={!groupCollapsed}>
        <DataTable tasks={tasks} />
      </Collapse>
    </GroupContainer>
  );
};

const DataTable = ({ tasks }: TasksTableProps) => {
  const [sortBy, setSortBy] = useState<SortedColumn>('name');
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  const setSorting = useCallback(
    (field: SortedColumn) => {
      const desc = field === sortBy ? !sortDesc : false;
      setSortDesc(desc);
      setSortBy(field);
    },
    [sortBy, sortDesc],
  );

  return (
    <StyledTable
      style={{ boxSizing: 'border-box' }}
      withTableBorder
      withColumnBorders
      highlightOnHover
      verticalSpacing="md"
    >
      <Colgroup />
      <Table.Thead>
        <Table.Tr>
          <SortingTableHeader onSort={() => setSorting('title')} isActive={sortBy === 'title'} desc={sortDesc}>
            {i18n.t('common.work_unit') + ' ID'}
          </SortingTableHeader>
          <SortingTableHeader onSort={() => setSorting('name')} isActive={sortBy === 'name'} desc={sortDesc}>
            {i18n.t('common.name')}
          </SortingTableHeader>
          <SortingTableHeader onSort={() => setSorting('epics')} isActive={sortBy === 'epics'} desc={sortDesc}>
            {i18n.t('common.parent_task') + ' Key'}
          </SortingTableHeader>
          <SortingTableHeader onSort={() => setSorting('epics')} isActive={sortBy === 'epics'} desc={sortDesc}>
            {i18n.t('common.parent_task')}
          </SortingTableHeader>
          <SortingTableHeader
            onSort={() => setSorting('initiatives')}
            isActive={sortBy === 'initiatives'}
            desc={sortDesc}
          >
            {i18n.t('common.initiative')}
          </SortingTableHeader>
          <SortingTableHeader onSort={() => setSorting('points')} isActive={sortBy === 'points'} desc={sortDesc}>
            {i18n.t('common.estimation')}
          </SortingTableHeader>
          <SortingTableHeader onSort={() => setSorting('type')} isActive={sortBy === 'type'} desc={sortDesc}>
            {i18n.t('common.type')}
          </SortingTableHeader>
          <SortingTableHeader
            onSort={() => setSorting('created')}
            isActive={sortBy === 'created'}
            desc={sortDesc}
            style={{ flexDirection: 'row-reverse' }}
          >
            {i18n.t('common.created')}
          </SortingTableHeader>
          <SortingTableHeader
            onSort={() => setSorting('transitions')}
            isActive={sortBy === 'transitions'}
            desc={sortDesc}
          >
            {i18n.t('common.status_history')}
          </SortingTableHeader>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{tasks.length > 0 && <Rows tasks={sortRecords(tasks, { sortBy, sortDesc })} />}</Table.Tbody>
    </StyledTable>
  );
};

const Colgroup = () => {
  return (
    <colgroup>
      <col style={{ width: '8%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '12%' }} />
      <col style={{ width: '10%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '6%' }} />
      <col style={{ width: '6%' }} />
      <col style={{ width: '22%' }} />
    </colgroup>
  );
};

const SortingTableHeader = ({
  children,
  isActive,
  onSort,
  desc,
  style,
}: {
  children: React.ReactNode;
  isActive: boolean;
  onSort: () => void;
  desc: boolean;
  style?: React.CSSProperties;
}) => (
  <Table.Th>
    <UnstyledButton
      component="div"
      onClick={onSort}
      style={{ ...style, display: 'flex', alignItems: 'center', gap: 4 }}
    >
      {children}
      <SortableColumnIndicator isActive={isActive} desc={desc} />
    </UnstyledButton>
  </Table.Th>
);

export function SortableColumnIndicator({ isActive, desc }: { isActive: boolean; desc: boolean }) {
  if (!isActive) {
    return <img src={icons.iconSortingArrowDown} width={16} height={16} />;
  }

  return <img src={desc ? icons.iconSortingArrowDownActive : icons.iconSortingArrowUpActive} width={16} height={16} />;
}

const Rows = ({ tasks }: TasksTableProps) => {
  const { ref: epicRef, width: epicWidth } = useElementSize();
  const { ref: initiativeRef, width: initiativeWidth } = useElementSize();

  return tasks.map((task) => (
    <Table.Tr key={task.id}>
      <Table.Td style={{ color: newCOLORS.darkGray2 }}>
        <Flex
          direction="row"
          align="center"
          gap={4}
          onClick={() => {
            window.open(task.url, '_blank', 'noopener, noreferrer');
          }}
          style={{ cursor: 'pointer', color: newCOLORS.indigo2, width: 120 }}
        >
          {task.title}
          <img alt="icon" height={16} width={16} src={icons.iconShare} style={{ paddingBottom: 4 }} />
        </Flex>
      </Table.Td>
      <Table.Td>{task.name}</Table.Td>
      <Table.Td>
        {task.epic?.title ? (
          <Flex
            direction="row"
            align="center"
            gap={4}
            onClick={() => {
              window.open(task.epic?.url, '_blank', 'noopener, noreferrer');
            }}
            style={{ cursor: 'pointer', color: newCOLORS.indigo2, width: 120 }}
          >
            {task.epic?.title}
            <img alt="icon" height={16} width={16} src={icons.iconShare} style={{ paddingBottom: 4 }} />
          </Flex>
        ) : (
          <EpicCell epic={task.epic} parentWidth={epicWidth} />
        )}
      </Table.Td>
      <Table.Td ref={epicRef} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <EpicCell epic={task.epic} parentWidth={epicWidth} />
      </Table.Td>
      <Table.Td ref={initiativeRef} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <InitiativeCell initiatives={task.initiatives} parentWidth={initiativeWidth} />
      </Table.Td>
      <Table.Td style={{ color: task.points || task.points === 0 ? newCOLORS.darkGray2 : newCOLORS.gray2 }}>
        {getStoryPointsText(task.points)}
      </Table.Td>
      <Table.Td style={{ color: task.type ? newCOLORS.darkGray2 : newCOLORS.gray2 }}>
        {getTaskTypeText(task.type)}
      </Table.Td>
      <Table.Td style={{ textAlign: 'right' }}>{toLocalDate(task.created_date).format('MM/DD/YYYY')}</Table.Td>
      <Table.Td>
        <Flex direction="column" gap={4}>
          {task.transitions?.map((transition, index) => {
            return (
              <div key={index} style={{ color: newCOLORS.darkGray2 }}>
                {`${transition.status} - ${transition.changed_by ? transition.changed_by : '-'} (${toLocalDate(
                  transition.changed_at,
                ).format('MM/DD/YYYY')})`}
              </div>
            );
          })}
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));
};

const Container = styled.div`
  display: inline-flex;
  padding: 32px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  border-radius: 8px;
  border: 1px solid var(--sky-light);
  background: var(--base-white);
  max-width: 99%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  color: ${newCOLORS.darkGray2};
  font-family: Figtree;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const InformationLabel = styled.div`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: ${newCOLORS.lighterPurple2};
  color: ${newCOLORS.indigo2};
  font-family: Figtree;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

const StyledTable = styled(Table)`
  .mantine-Table-thead {
    background: ${newCOLORS.lighterGray2};
    color: ${newCOLORS.darkGray2};
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  .mantine-Table-tbody {
    color: ${newCOLORS.darkGray2};
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 99 %;
  max-width: 99%;
`;

const GroupHeader = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
`;

const GroupHeaderLabel = styled.div`
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

const StyledScrollArea = styled(ScrollArea)`
  .mantine-ScrollArea-scrollbar {
    background: transparent;
  }

  .mantine-ScrollArea-thumb {
    cursor: pointer;
  }
`;
