import { ExternalService } from '../../../../api/integrations-client/integrations-client.type';
import { FilterOption } from '../../../../components/filters/filter-panel.types';
import { primaryBase, skyDark, yellowBase } from '../../../../styles/design-tokens';
import { InitiativeState, Status } from '../../strategy-v2.types';
import { StatusLabel } from './status-label';

/**
 * Returns an array of filter options for status filtering
 * Each option contains a value from the Status enum and a styled StatusLabel component
 *
 * Note: The status options are currently hardcoded and may need to be made configurable in the future
 *
 * @returns {FilterOption[]} Array of filter options with status values and styled labels
 */
const getStatusOptions = (): FilterOption[] => {
  return [
    {
      value: Status.OnTrack,
      label: <StatusLabel color={primaryBase} label="On Track" />,
    },
    {
      value: Status.AtRisk,
      label: <StatusLabel color={yellowBase} label="At Risk" />,
    },
    {
      value: Status.OffTrack,
      label: <StatusLabel color={skyDark} label="Off Track" />,
    },
  ];
};

/**
 * Returns an array of filter options for initiative state filtering
 * Each option contains a value from the InitiativeState enum and a display label
 *
 * @returns {FilterOption[]} Array of filter options with initiative state values and display labels
 */
const getStateOptions = (): FilterOption[] => {
  return [
    {
      value: InitiativeState.NoEpics,
      label: 'No Epics',
    },
    {
      value: InitiativeState.NoEndDate,
      label: 'No End Date',
    },
  ];
};

/**
 * Returns an array of filter options for source filtering
 * Each option contains a value (either 'bloomfilter' or an ExternalService enum value) and a display label
 *
 * Note: The source options are currently hardcoded and may need to be made configurable in the future
 *
 * @returns {FilterOption[]} Array of filter options with source values and display labels
 */
const getSourceOptions = (): FilterOption[] => {
  return [
    { value: 'bloomfilter', label: 'Bloomfilter' },
    { value: ExternalService.Jira, label: 'Atlassian Jira Software Cloud' },
    { value: ExternalService.JDC, label: 'Atlassian Jira Data Center' },
    { value: ExternalService.ADO, label: 'Microsoft Azure' },
    { value: ExternalService.Github, label: 'GitHub' },
  ];
};

export { getSourceOptions, getStateOptions, getStatusOptions };
