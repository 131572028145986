import dayjs from 'dayjs';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { Epic } from '../../../../api/tasks-client/task-client.type';
import { FlowByPhaseDataOverTime } from '../../../../api/work-periods-client/work-periods-client.type';
import { InitiativeMetricType } from '../initiative-performance.type';

/**
 * Returns the chart title based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the chart title for.
 * @return {string} The chart title corresponding to the provided metric type.
 */
const getChartTitle = (metric: InitiativeMetricType): string => {
  const titles = {
    [InitiativeMetricType.Tasks]: 'Completed tasks',
    [InitiativeMetricType.Points]: 'Completed points',
  };

  return `${titles[metric]} from contributing epics (monthly)`;
};

/**
 * Returns the label for the y-axis based on the provided metric type.
 *
 * @param {InitiativeMetricType} metric - The metric type to get the y-axis label for.
 * @return {string} The y-axis label corresponding to the provided metric type.
 */
const getYAxisLabel = (metric: InitiativeMetricType): string => {
  const labels = {
    [InitiativeMetricType.Tasks]: 'Tasks',
    [InitiativeMetricType.Points]: 'Points',
  };

  return `${labels[metric]} per month`;
};

/**
 * Calculates the progress of an epic based on the number of completed subtasks and the total number of subtasks.
 *
 * @param {Epic} epic - The epic object containing the subtasks information.
 * @return {number} The progress of the epic as a percentage.
 */
const getEpicProgress = (epic: Epic): number => {
  const subtasksCompleted = epic?.subtasks_completed_count || 0;
  const subtasksTotal = epic?.subtasks_count || 0;

  if (subtasksTotal > 0) {
    return Math.floor((subtasksCompleted / subtasksTotal) * 100);
  }

  return 0;
};

/**
 * Returns the date options for the initiative phase chart.
 *
 * @param {FlowByPhaseDataOverTime} data - The data to get the date options for.
 * @return {Array} The date options for the initiative phase chart.
 */
const getDateOptions = (data: FlowByPhaseDataOverTime): { value: string; label: string }[] | [] => {
  // Check if data is null, undefined, or not an object
  if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
    return [];
  }

  try {
    return Object.entries(data)
      .filter(([_, values]) => {
        // Only show dates that have actual data
        if (!values || typeof values !== 'object') {
          return false;
        }

        return Object.entries(values).some(([_, value]) => {
          return value !== null && typeof value === 'number' && value > 0;
        });
      })
      .map(([date]) => ({
        value: date,
        label: dayjs(date).format('MMM DD, YYYY'),
      }))
      .sort((a, b) => dayjs(b.value).diff(dayjs(a.value)));
  } catch (error) {
    console.error('Error processing date options:', error);
    return [];
  }
};

/**
 * Returns the epic options for the initiative phase chart.
 *
 * @param {ExtendedInitiative} initiative - The initiative to get the epic options for.
 * @return {Array} The epic options for the initiative phase chart.
 */
const getEpicOptions = (initiative: ExtendedInitiative): { value: string; label: string }[] | [] => {
  return initiative.epics
    ? initiative.epics.map((epic) => ({
        value: epic.id,
        label: epic.name,
      }))
    : [];
};

export { getChartTitle, getDateOptions, getEpicOptions, getEpicProgress, getYAxisLabel };
