/**
 * Gets the last non-null value from a time series data object.
 *
 * @param timeSeriesData - Object mapping dates to numeric values, or null/undefined
 * @returns The last non-null value as a number, or null if no valid value found
 *
 * @example
 * const data = {'2023-01-01': 42.5, '2023-01-02': null, '2023-01-03': 45.7};
 * getLastValue(data, 'Percentage'); // Returns 45.7
 * getLastValue(null, 'Percentage'); // Returns null
 */

import { WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { TargetComparison, TransformedTarget } from '../../adherence/targets/targets-client.type';
import { getTargetLineBorderDash, getTargetLineFill } from '../../adherence/targets/targets.helpers';

const getLastNotNullValue = (timeSeriesData: Record<string, number> | undefined | null): number | null => {
  if (!timeSeriesData) return null;
  const dates = Object.keys(timeSeriesData).sort();
  for (let i = dates.length - 1; i >= 0; i--) {
    const value = timeSeriesData[dates[i]];
    if (value !== null && value !== undefined) {
      return value;
    }
  }

  return null;
};

/**
 * Constructs target line data for a series of work periods based on target value and date range
 *
 * @param {number | null} value - The target value to use for work periods within the date range
 * @param {string} startDate - The start date of the target's validity period
 * @param {string | null} endDate - The end date of the target's validity period, or null if no end date
 * @param {WorkPeriod[]} workPeriods - Array of work periods to generate target data for
 * @returns {(number | null)[]} Array of target values for each work period, null for periods outside the target date range
 */
const constructTargetLineData = (
  value: number | null,
  startDate: string,
  endDate: string | null,
  workPeriods: WorkPeriod[],
) => {
  if (value === null) return Array(workPeriods.length).fill(null);

  return workPeriods.map((workPeriod) => {
    const workPeriodStart = new Date(workPeriod.start_date);
    const workPeriodEnd = new Date(workPeriod.end_date);
    const targetStart = new Date(startDate);
    const targetEnd = endDate ? new Date(endDate) : null;

    // Check if work period falls within target date range
    if (workPeriodStart >= targetStart && (!targetEnd || workPeriodEnd <= targetEnd)) {
      return value;
    }
    return null;
  });
};

/**
 * Generates chart datasets for target lines in sprint comparison view
 *
 * @param {TransformedTarget} target - The transformed target data
 * @param {WorkPeriod[]} workPeriods - Array of work periods to generate target data for
 * @param {string} color - The color to use for the target lines
 * @param {string} yAxisId - The ID of the y-axis to use
 * @returns {any[]} Array of chart dataset configurations for the target lines
 */
const getSprintComparisonTargetLineDatasets = (
  target: TransformedTarget,
  workPeriods: WorkPeriod[],
  color: string,
  yAxisId: string,
) => {
  if (
    target.target_comparison === TargetComparison.RANGE_EX ||
    target.target_comparison === TargetComparison.RANGE_IN
  ) {
    const lower = constructTargetLineData(target.target_value, target.start_date, target.end_date, workPeriods);
    const upper = constructTargetLineData(target.target_value_upper, target.start_date, target.end_date, workPeriods);

    const lowerDatasetId = `${target.measure}-lower`;
    const upperDatasetId = `${target.measure}-upper`;

    return [
      {
        label: `${target.measure} Target Lower`,
        id: lowerDatasetId,
        data: lower,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(target),
        yAxisID: yAxisId,
        tension: 0,
        fill: getTargetLineFill(target.target_comparison, color, upperDatasetId),
      },
      {
        label: `${target.measure} Target Upper`,
        id: upperDatasetId,
        data: upper,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(target),
        yAxisID: yAxisId,
        tension: 0,
      },
    ];
  } else {
    const data = constructTargetLineData(target.target_value, target.start_date, target.end_date, workPeriods);
    return [
      {
        label: `${target.measure} Target`,
        data: data,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
        pointRadius: 0,
        borderDash: getTargetLineBorderDash(target),
        yAxisID: yAxisId,
        tension: 0,
        fill: getTargetLineFill(target.target_comparison, color),
      },
    ];
  }
};

export { getLastNotNullValue, getSprintComparisonTargetLineDatasets };
