import { MinifiedTask } from '../../../../api/tasks-client/task-client.type';
import { removeDuplicates } from '../../../tasks-table/tasks-table.helpers';
import { TaskRecord, TaskType } from '../../../tasks-table/tasks-table.type';

const convertToTaskRecord = (task: MinifiedTask): TaskRecord => ({
  id: task.id,
  name: task.name,
  title: task.title,
  url: task.url,
  created_date: task.created_date,
  points: task.points,
  type: task.type as TaskType,
  epic: task.epic,
  initiatives: removeDuplicates(task.initiatives),
  transitions: task.transitions,
});

export { convertToTaskRecord };
