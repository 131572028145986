import AxiosInstance from '../../../../helpers/axios-instance/axios-instance';
import { BoardSetting, CreateBoardSettingPayload, EditBoardSettingPayload } from './board-settings-client.types';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/subproject-settings/';

/**
 * Fetches board settings for a given organization.
 *
 * @param {string} organization_id - The ID of the organization to fetch board settings for.
 * @return {Promise<BoardSetting[]>} - A promise that resolves to an array of board settings.
 */
const getBoardSettings = async (organization_id: string | null): Promise<BoardSetting[]> => {
  if (!organization_id) {
    return [];
  }
  return await axiosInstance
    .get(`${baseURL}`, { params: { organization_id } })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching board settings'));
};

/**
 * Creates a new board setting for an organization.
 *
 * @param {CreateBoardSettingPayload} payload - The payload containing the data to create the board setting.
 * @return {Promise<BoardSetting>} - A promise that resolves to the created board setting.
 */
const createBoardSetting = async (payload: CreateBoardSettingPayload): Promise<BoardSetting> => {
  return await axiosInstance
    .post(`${baseURL}`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while creating board setting'));
};

/**
 * Deletes a board setting.
 *
 * @param {string} id - The ID of the board setting to delete.
 * @return {Promise<void>} - A promise that resolves when the deletion is complete.
 */

const deleteBoardSetting = async (id: string): Promise<void> => {
  return await axiosInstance
    .delete(`${baseURL}${id}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while deleting board setting'));
};

/**
 * Edits a board setting for a given organization.
 *
 * @param {string} id - The ID of the board setting to edit.
 * @param {EditBoardSettingPayload} payload - The payload containing the data to edit the board setting.
 * @return {Promise<BoardSetting>} - A promise that resolves to the edited board setting.
 */
const editBoardSetting = async (id: string, payload: EditBoardSettingPayload): Promise<BoardSetting> => {
  return await axiosInstance
    .patch(`${baseURL}${id}/`, payload)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while editing board setting'));
};

/**
 * Maps subprojects to a board setting.
 *
 * @param {string} boardSettingId - The ID of the board setting to map subprojects to.
 * @param {string} organizationId - The ID of the organization to map subprojects to.
 * @param {string[]} subprojectIds - Array of subproject IDs to map.
 * @return {Promise<void>} - A promise that resolves when the mapping is complete.
 */
const mapSubprojectsToBoardSetting = async (
  boardSettingId: string,
  subprojectIds: string[],
  organizationId: string,
): Promise<void> => {
  return await axiosInstance
    .post(`${baseURL}${boardSettingId}/mappings/?organization_id=${organizationId}`, { subproject_ids: subprojectIds })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while mapping subprojects to board setting'));
};

/**
 * Removes subprojects from a board setting.
 *
 * @param {string} boardSettingId - The ID of the board setting to remove subprojects from.
 * @param {string} organizationId - The ID of the organization to remove subprojects from.
 * @param {string[]} subprojectIds - Array of subproject IDs to remove.
 * @return {Promise<void>} - A promise that resolves when the removal is complete.
 */
const removeSubprojectsFromBoardSetting = async (
  boardSettingId: string,
  subprojectIds: string[],
  organizationId: string,
): Promise<void> => {
  return await axiosInstance
    .post(`${baseURL}${boardSettingId}/remove-mappings/?organization_id=${organizationId}`, {
      subproject_ids: subprojectIds,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while removing subprojects from board setting'));
};

export {
  createBoardSetting,
  deleteBoardSetting,
  editBoardSetting,
  getBoardSettings,
  mapSubprojectsToBoardSetting,
  removeSubprojectsFromBoardSetting,
};
