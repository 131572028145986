import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Accordion, Divider, Flex, Text, Title } from '@mantine/core';
import dayjs from 'dayjs';
import { newCOLORS } from '../../../styles/colors';
import { TaskRecord } from '../../tasks-table/tasks-table.type';
import { getTypeColor } from './task-detail.helpers';

type TaskDetailProps = {
  task: TaskRecord;
  onClose: () => void;
  onBack?: () => void;
};

export const TaskDetail = ({ task, onClose, onBack }: TaskDetailProps) => {
  return (
    <DetailContainer>
      <Header>
        <Flex justify="space-between" align="center" w="100%">
          <Flex align="center" gap={8}>
            {onBack && (
              <BackButton onClick={onBack}>
                <Icon icon="mdi:arrow-left" width={16} height={16} />
              </BackButton>
            )}
            <Title order={4}>Task Details</Title>
          </Flex>
          <CloseButton onClick={onClose}>
            <Icon icon="mdi:close" width={20} height={20} />
          </CloseButton>
        </Flex>
      </Header>

      <Content>
        <TaskHeader>
          <TaskTitle>{task.name}</TaskTitle>
          <TaskIdBadge>{task.id}</TaskIdBadge>
        </TaskHeader>

        {task.type && <TypeBadge color={getTypeColor(task.type)}>{task.type}</TypeBadge>}

        <Divider my="md" />

        <Section>
          <SectionTitle>Details</SectionTitle>
          <DetailGrid>
            <DetailItem>
              <DetailLabel>Points</DetailLabel>
              <DetailValue>
                {task.points !== null ? (
                  <PointsBadge>
                    {task.points} {task.points === 1 ? 'point' : 'points'}
                  </PointsBadge>
                ) : (
                  'N/A'
                )}
              </DetailValue>
            </DetailItem>
            <DetailItem>
              <DetailLabel>Created</DetailLabel>
              <DetailValue>
                {task.created_date ? (
                  <Flex align="center" gap={4}>
                    <Icon icon="mdi:calendar" width={14} height={14} color={newCOLORS.darkGray} />
                    <Text size="sm">{dayjs(task.created_date).format('MMM DD, YYYY')}</Text>
                  </Flex>
                ) : (
                  'N/A'
                )}
              </DetailValue>
            </DetailItem>
            {task.epic && (
              <DetailItem>
                <DetailLabel>Epic</DetailLabel>
                <DetailValue>
                  <Flex align="center" gap={4}>
                    <Icon icon="mdi:bookmark-outline" width={14} height={14} color={newCOLORS.indigo} />
                    <Text size="sm" color={newCOLORS.indigo}>
                      {task.epic.name}
                    </Text>
                  </Flex>
                </DetailValue>
              </DetailItem>
            )}
          </DetailGrid>
        </Section>

        {task.initiatives && task.initiatives.length > 0 && (
          <>
            <Divider my="md" />
            <Section>
              <SectionTitle>Initiatives</SectionTitle>
              <InitiativesList>
                {task.initiatives.map((initiative) => (
                  <InitiativeItem key={initiative.id}>
                    <Icon icon="mdi:flag-outline" width={14} height={14} color={newCOLORS.darkGray} />
                    <Text size="sm">{initiative.name}</Text>
                  </InitiativeItem>
                ))}
              </InitiativesList>
            </Section>
          </>
        )}

        {task.transitions && task.transitions.length > 0 && (
          <>
            <Divider my="md" />
            <Accordion variant="separated" radius="md">
              <Accordion.Item value="task-log">
                <Accordion.Control>
                  <Flex align="center" gap={8}>
                    <Icon icon="mdi:history" width={16} height={16} />
                    <Text fw={500}>Task Transitions</Text>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <TransitionsList>
                    {task.transitions.map((transition) => (
                      <TransitionItem key={`${transition.status}-${transition.changed_at}`}>
                        <TransitionStatus>
                          <Icon icon="mdi:arrow-right" width={14} height={14} />
                          <Text size="sm" fw={500}>
                            {transition.status}
                          </Text>
                        </TransitionStatus>
                        <TransitionMeta>
                          <Icon icon="mdi:clock-outline" width={12} height={12} color={newCOLORS.gray} />
                          <Text size="xs" color={newCOLORS.gray}>
                            {transition.changed_at
                              ? dayjs(transition.changed_at).format('MMM DD, YYYY [at] h:mm a')
                              : 'N/A'}
                          </Text>
                          <Text size="xs" color={newCOLORS.gray}>
                            by {transition.changed_by}
                          </Text>
                        </TransitionMeta>
                      </TransitionItem>
                    ))}
                  </TransitionsList>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </>
        )}
      </Content>
    </DetailContainer>
  );
};

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${newCOLORS.white};
`;

const Header = styled.div`
  border-bottom: 1px solid ${newCOLORS.lightGray};
  background-color: ${newCOLORS.white};
  padding: 10px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${newCOLORS.darkGray};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${newCOLORS.lighterGray};
    color: ${newCOLORS.black};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${newCOLORS.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${newCOLORS.lighterGray};
    color: ${newCOLORS.black};
  }
`;

const Content = styled.div`
  padding: 16px;
  overflow-y: auto;
  flex: 1;
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const TaskTitle = styled.h2`
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;
  color: ${newCOLORS.darkerGray};
  flex: 1;
`;

const TaskIdBadge = styled.div`
  font-size: 12px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.lighterGray2};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
`;

const TypeBadge = styled.div<{ color: string }>`
  font-size: 12px;
  color: white;
  background-color: ${(props) => props.color};
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 16px;
`;

const PointsBadge = styled.div`
  font-size: 12px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.lighterGray2};
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 12px 0;
  color: ${newCOLORS.darkerGray};
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DetailLabel = styled.div`
  font-size: 12px;
  color: ${newCOLORS.gray};
  font-weight: 500;
`;

const DetailValue = styled.div`
  font-size: 14px;
  color: ${newCOLORS.darkGray};
`;

const InitiativesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InitiativeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: ${newCOLORS.lighterGray};
  border-radius: 4px;
`;

const TransitionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TransitionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  background-color: ${newCOLORS.lighterGray};
  border-radius: 4px;
`;

const TransitionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TransitionMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 22px;
`;
