import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { completeOauth } from '../../../api/integrations-client/integrations-client';
import { ExternalService } from '../../../api/integrations-client/integrations-client.type';
import { UserContext } from '../../../contexts';
import { IntegrationsContext } from '../context/integrations.context';
import { completeReauth } from '../integrations.helpers';
import { useRedirect } from '../integrations.hooks';

/**
 * Custom hook to handle OAuth authentication flow for GitHub integration
 *
 * @param {Object} params - Hook parameters
 * @param {function} params.onSuccess - Callback function called when OAuth flow completes successfully
 * @returns {Object} Object containing the OAuth completion query result
 * @property {QueryObserverResult<Record<string, unknown>>} completeOauthQuery - Query result for the OAuth completion request
 */
const useOauth = ({
  onSuccess,
}: {
  onSuccess: (data: Record<string, unknown>) => void;
}): { completeOauthQuery: QueryObserverResult<Record<string, unknown>> } => {
  const { user } = useContext(UserContext);
  const { query: integrationsQuery } = useContext(IntegrationsContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { redirect, setRedirect } = useRedirect();

  const completeOauthQuery = useQuery({
    queryKey: ['oauthGithub', user] as const,
    queryFn: async () => {
      const code = searchParams.get('code');
      const tempStates = searchParams.get('state')?.split(',');
      const state = tempStates ? tempStates[0] : '';
      const orgId = tempStates ? tempStates[1] : '';

      setSearchParams(searchParams);

      if (!user?.id || !orgId || !code) {
        throw new Error('Missing required parameters');
      }

      return await completeOauth(ExternalService.Github, user.id.toString(), orgId, code, state, '', {});
    },
    enabled: !!searchParams.get('code') && !!user,
  });

  const handleSettled = useCallback(() => {
    searchParams.delete('code');
    searchParams.delete('state');
    setSearchParams(searchParams);

    if (redirect) {
      completeReauth();
      navigate(redirect);
      integrationsQuery.refetch();
      setRedirect(null);
    }
  }, [redirect, navigate, integrationsQuery, setRedirect, searchParams, setSearchParams]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess && completeOauthQuery.data) {
      onSuccess(completeOauthQuery.data);
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.data, onSuccess]);

  useEffect(() => {
    if (completeOauthQuery.isSuccess || completeOauthQuery.isError) {
      handleSettled();
    }
  }, [completeOauthQuery.isSuccess, completeOauthQuery.isError, handleSettled]);

  return {
    completeOauthQuery,
  };
};

export { useOauth };
