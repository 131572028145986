import { Icon } from '@iconify/react';
import { styled } from '@linaria/react';
import { Collapse, Divider, Loader, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCostVsOutputChartData } from '../../api/financials-client/financials-client.ts';
import {
  // CostVsBudgetChartData,
  CostVsBudgetChartData,
  CostVsBudgetChartResponse,
  CostVsBudgetSummary,
} from '../../api/financials-client/financials-client.type.ts';
import i18n from '../../base-dictionary.tsx';
import { ChartContainer } from '../../containers/financials/financials.styled.ts';
import { FloraButton } from '../../containers/flora/flora-button.tsx';
import { BoardContext } from '../../contexts/index.tsx';
import { canvasHtmlDownload } from '../../helpers/image-downloader/image-downloader.ts';
import { newCOLORS } from '../../styles/colors.ts';
import {
  SmallerHeading,
  UppercaseCallout,
} from '../../styles/new-shared-styled-components/new-shared-styled-components.ts';
import { DownloadPNGButton } from '../download-png-button.tsx';
import { getScopeNameSuffix } from '../edit-financial-data/edit-financial-data.helper.ts';
import { FinancialsScope } from '../financials-scope/financials-scope.type.tsx';
import { CostsVsOutputChart } from './costs-vs-output-chart.tsx';
import { CostsVsOutputItem } from './costs-vs-output-item.tsx';
import { CostsVsOutputChartItemLabels, ShowCostsVsOutputChartLines } from './costs-vs-output.types';

export const CostsVsOutput = ({ startDate, endDate, portfolio, team, setQueryFinished }: FinancialsScope) => {
  const [opened, { toggle }] = useDisclosure(true);
  const downloadRef = useRef<HTMLDivElement>(null);

  const { board } = useContext(BoardContext);
  const exceptionRef = useRef<HTMLDivElement>(null);
  const [showCostsVsOutputChartLines, setShowCostsVsOutputChartLines] = useState<ShowCostsVsOutputChartLines>({
    showCost: false,
    showCostTrend: false,
    showDailyBurn: true,
    showDailyBurnTrend: true,
    showThroughPut: true,
    showThroughPutTrend: false,
    showTasksCommited: true,
    showTasksCommitedTrend: false,
    showCostPerTask: true,
    showCostPerTaskTrend: false,
    showVelocity: false,
    showVelocityTrend: false,
    showPointsCommitted: false,
    showPointsCommittedTrend: false,
    showCostPerPoint: false,
    showCostPerPointTrend: false,
  });

  const [lastSummary, setLastSummary] = useState<CostVsBudgetSummary | null>(null);
  const [averageSummary, setAverageSummary] = useState<CostVsBudgetSummary | null>(null);
  const [chartData, setChartData] = useState<CostVsBudgetChartData[] | null>(null);

  const { teamId = '', subprojectId = '' } = useParams<{ portfolioId: string; teamId: string; subprojectId: string }>();

  const { data, isPending, isError } = useQuery<CostVsBudgetChartResponse>({
    queryKey: ['getCostVsOutputChartData', startDate, endDate, portfolio?.id, teamId, subprojectId] as const,
    queryFn: async () => {
      if (!portfolio || !startDate || !endDate || !team) {
        throw new Error('Missing required parameters');
      }

      return getCostVsOutputChartData(
        startDate,
        endDate,
        portfolio.id ? (teamId === 'aggregate' ? null : portfolio.id) : null,
        teamId || undefined,
        subprojectId || undefined,
      );
    },
    enabled: !!(portfolio && startDate && endDate && team),
  });

  useEffect(() => {
    if (data && !isError) {
      setLastSummary(data.last);
      setAverageSummary(data.average);
      setChartData(data.chart_data);
    }

    if (setQueryFinished) {
      setQueryFinished(true);
    }
  }, [data, isError, setQueryFinished]);

  const updateShowCostsVsOutputChartLines = (checked: boolean, label: CostsVsOutputChartItemLabels) => {
    setShowCostsVsOutputChartLines((prevShowCostsVsOutputChartLines) => ({
      ...prevShowCostsVsOutputChartLines,
      [`show${label}`]: checked,
    }));
  };

  return (
    <Fragment>
      <CostsVsOutputContainer ref={downloadRef}>
        <CollapseHeader>
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <CollapseIcon>
              {opened ? (
                <Icon icon="icon-park-solid:down-one" width={16} height={16} color={newCOLORS.black} onClick={toggle} />
              ) : (
                <Icon
                  icon="icon-park-solid:right-one"
                  width={16}
                  height={16}
                  color={newCOLORS.black}
                  onClick={toggle}
                />
              )}
            </CollapseIcon>
            <SmallerHeading>Costs vs output{getScopeNameSuffix(team, portfolio, board)}</SmallerHeading>
          </div>
          <div ref={exceptionRef} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <FloraButton externalData={[chartData]} size={30} />
            {opened && (
              <DownloadPNGButton
                handleDownload={() => canvasHtmlDownload('Costs vs output', downloadRef, exceptionRef)}
                exceptionRef={exceptionRef}
              />
            )}
          </div>
        </CollapseHeader>
        <Collapse in={opened}>
          {isPending ? (
            <LoaderContainer>
              <Loader color={newCOLORS.indigo} size="lg" />
            </LoaderContainer>
          ) : (
            <CollapseContent>
              <CostsVsOutputItemBox>
                <CostsVsOutputItemHeaderWrapper>
                  <CostsVsOutputItemHeaderLeft>
                    <UppercaseCallout style={{ marginLeft: '9px' }}>Line</UppercaseCallout>
                    <UppercaseCallout style={{ marginLeft: '9px' }}>Trend</UppercaseCallout>
                  </CostsVsOutputItemHeaderLeft>
                  <CostsVsOutputItemHeaderRight>
                    <UppercaseCallout style={{ width: 125, textAlign: 'right' }}>
                      Last{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Result for the most recently completed month, independent of range selected above'}
                        style={{ textAlign: 'left', textTransform: 'none' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </UppercaseCallout>
                    <UppercaseCallout style={{ width: 125, textAlign: 'right' }}>
                      Average{' '}
                      <Tooltip
                        multiline
                        w={300}
                        position="right"
                        label={'Total for selected item(s) divided by number of months from the range selected'}
                        style={{ textAlign: 'left', textTransform: 'none' }}
                      >
                        <Icon icon="mdi:information-outline" width={18} height={18} color={newCOLORS.blue} />
                      </Tooltip>
                    </UppercaseCallout>
                  </CostsVsOutputItemHeaderRight>
                </CostsVsOutputItemHeaderWrapper>

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.cost}
                  color={newCOLORS.green}
                  title={i18n.t('financials.cost')}
                  last={lastSummary?.cost || 0}
                  average={averageSummary?.cost || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.dailyBurn}
                  color={newCOLORS.coral}
                  title={i18n.t('financials.daily_spend')}
                  last={lastSummary?.daily_burn || 0}
                  average={averageSummary?.daily_burn || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.throughPut}
                  color={newCOLORS.blue}
                  title={i18n.t('financials.throughput')}
                  description={i18n.t('financials.tasks_completed', { work_units: i18n.t('common.work_units') })}
                  last={lastSummary?.tasks_completed || 0}
                  average={averageSummary?.tasks_completed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.tasksCommited}
                  color={newCOLORS.aqua}
                  title={i18n.t('financials.tasks_committed', { work_units: i18n.t('common.work_units') })}
                  last={lastSummary?.tasks_committed || 0}
                  average={averageSummary?.tasks_committed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.costPerTask}
                  color={newCOLORS.lighterGreen}
                  title={i18n.t('financials.cost_per_task', { work_units: i18n.t('common.work_unit') })}
                  last={lastSummary?.cost_per_task || 0}
                  average={averageSummary?.cost_per_task || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.velocity}
                  color={newCOLORS.pink}
                  title={i18n.t('financials.velocity')}
                  description={i18n.t('financials.points_completed', { estimation: i18n.t('common.estimation') })}
                  last={lastSummary?.points_completed || 0}
                  average={averageSummary?.points_completed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.pointsCommitted}
                  color={newCOLORS.yellow}
                  title={i18n.t('financials.points_committed', { estimation: i18n.t('common.estimation') })}
                  last={lastSummary?.points_committed || 0}
                  average={averageSummary?.points_committed || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={false}
                  disabled={false}
                />
                <CostsVsOutputItem
                  label={CostsVsOutputChartItemLabels.costPerPoint}
                  color={newCOLORS.orangeRed}
                  title={i18n.t('financials.cost_per_point', {
                    estimation_singular: i18n.t('common.estimation_singular'),
                  })}
                  last={lastSummary?.cost_per_point || 0}
                  average={averageSummary?.cost_per_point || 0}
                  showCostsVsOutputChartLines={showCostsVsOutputChartLines}
                  updateShowCostsVsOutputChartLines={updateShowCostsVsOutputChartLines}
                  currency={true}
                  disabled={false}
                />

                <Divider size="xs" style={{ margin: '8px 0px' }} />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 400,
                    margin: '8px 0px',
                  }}
                ></div>
              </CostsVsOutputItemBox>

              <ChartContainer>
                <CostsVsOutputChart showCostsVsOutputChartLines={showCostsVsOutputChartLines} chartData={chartData} />
              </ChartContainer>
            </CollapseContent>
          )}
        </Collapse>
      </CostsVsOutputContainer>
    </Fragment>
  );
};

const CostsVsOutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 16px;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 6px;
  font-weight: 800;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 12px;
  display: flex;
  gap: 1em;
  width: 100%;
  flex-wrap: nowrap;
`;

const CostsVsOutputItemBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const CostsVsOutputItemHeaderWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
`;

const CostsVsOutputItemHeaderLeft = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
`;

const CostsVsOutputItemHeaderRight = styled.div`
  display: flex;
  justify-content: end;
  flex-grow: 1;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 545px;
`;
