import { styled } from '@linaria/react';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { WorkPeriodsFilter } from '../filter/work-periods-filter';
import { TaskSideSection } from '../task-detail/task-side-section';
import { useWorkPeriodsContext } from '../work-periods.context';
import { ChartFilterControls } from './components/chart-filter-controls';
import { ComparisonTable } from './components/comparison-table';
import { ComparisonTab, ComparisonTabs } from './components/comparison-tabs';
import { MeasureComparisonChart } from './components/measure-comparison-chart';
import { MeasuresOverTimeChart } from './components/measures-over-time-chart';

/**
 * ComparisonView component that allows users to compare multiple work periods
 * Only allows selection of predefined work periods with multi-selection up to four
 */
export function ComparisonView() {
  // Extract all context values at the top level to ensure consistent hook ordering
  const workPeriodsContext = useWorkPeriodsContext();

  const {
    selectedWorkPeriods = [],
    fetchWorkPeriods,
    customMeasures = [],
    measureData = {},
    isFetching,
    isLoading,
    selectedBoardId,
    formattedBoards = [],
  } = workPeriodsContext;

  // Define all state hooks at the top level
  const [activeTab, setActiveTab] = useState<ComparisonTab>(ComparisonTab.TABLE);
  const [isFilterPanelOpen, { toggle: toggleFilterPanel }] = useDisclosure(true);
  const [showSideSection, setShowSideSection] = useState(false);
  const [sideSectionTasks, setSideSectionTasks] = useState<any[]>([]);
  const [sideSectionTitle, setSideSectionTitle] = useState('');

  // Add state for chart controls
  const [selectedMeasures, setSelectedMeasures] = useState<string[]>(
    customMeasures.length > 0 ? [customMeasures[0].measure_name] : [],
  );
  const [selectedMeasure, setSelectedMeasure] = useState<string>(
    customMeasures.length > 0 ? customMeasures[0].measure_name : '',
  );
  const [showTrendLine, setShowTrendLine] = useState<boolean>(false);
  const [showDots, setShowDots] = useState<boolean>(true);

  const handleTabChange = useCallback((tab: ComparisonTab) => {
    setActiveTab(tab);
  }, []);

  // Use useEffect with a cleanup function to prevent memory leaks
  useEffect(() => {
    let isMounted = true;

    // Fetch work periods when component mounts
    const initializeBoard = async () => {
      try {
        if (!selectedBoardId && formattedBoards.length > 0) {
          // If no board is selected but we have boards, select the first one
          const firstBoardId = formattedBoards[0].value;
          if (isMounted) {
            await fetchWorkPeriods(firstBoardId);
          }
        } else if (selectedBoardId) {
          // If a board is already selected, fetch its work periods
          if (isMounted) {
            await fetchWorkPeriods(selectedBoardId);
          }
        }
      } catch (error) {
        console.error('Error initializing board:', error);
      }
    };

    initializeBoard();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [fetchWorkPeriods, selectedBoardId, formattedBoards]);

  // Memoize the chart components to prevent unnecessary re-renders
  const tableComponent = useMemo(() => {
    if (selectedWorkPeriods.length === 0) return null;

    return (
      <ComparisonTable
        customMeasures={customMeasures || []}
        measureData={measureData || {}}
        workPeriods={selectedWorkPeriods.sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))}
      />
    );
  }, [customMeasures, measureData, selectedWorkPeriods]);

  // Handle measure selection for comparison chart
  const handleMeasuresChange = useCallback((value: string[]) => {
    setSelectedMeasures(value);
  }, []);

  // Handle measure selection for over time chart
  const handleMeasureChange = useCallback((value: string | null) => {
    if (value) {
      setSelectedMeasure(value);
    }
  }, []);

  const handleShowTrendLineChange = useCallback((value: boolean) => {
    setShowTrendLine(value);
  }, []);

  const handleShowDotsChange = useCallback((value: boolean) => {
    setShowDots(value);
  }, []);

  const measureComparisonComponent = useMemo(() => {
    if (selectedWorkPeriods.length === 0) return null;

    const reversedWorkPeriods = [...selectedWorkPeriods].reverse();
    return (
      <MeasureComparisonChart
        customMeasures={customMeasures || []}
        measureData={measureData || {}}
        workPeriods={reversedWorkPeriods}
        onBarClick={() => {}}
        externalSelectedMeasures={selectedMeasures}
        externalShowLabels={true}
      />
    );
  }, [customMeasures, measureData, selectedWorkPeriods, selectedMeasures]);

  const measuresOverTimeComponent = useMemo(() => {
    if (selectedWorkPeriods.length === 0) return null;

    const reversedWorkPeriods = [...selectedWorkPeriods].reverse();
    return (
      <MeasuresOverTimeChart
        customMeasures={customMeasures || []}
        measureData={measureData || {}}
        workPeriods={reversedWorkPeriods}
        externalSelectedMeasure={selectedMeasure}
        externalShowTrendLine={showTrendLine}
        externalShowDots={showDots}
      />
    );
  }, [customMeasures, measureData, selectedWorkPeriods, selectedMeasure, showTrendLine, showDots]);

  // Render the main section content
  const renderMainSection = useCallback(() => {
    if (isLoading) {
      return (
        <Fragment>
          <BrandedLoadingOverlay visible={true} variant="colored" />
          <EmptyStateContainer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <Text weight="bold" size="large" color={skyDark}>
                Loading work periods...
              </Text>
            </div>
          </EmptyStateContainer>
        </Fragment>
      );
    }

    if (selectedWorkPeriods.length === 0) {
      return (
        <Fragment>
          <BrandedLoadingOverlay visible={isFetching || false} variant="colored" />
          <EmptyStateContainer>
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', height: '100%' }}
            >
              <Icon name="info" size={40} color={skyDark} />
              <Text weight="bold" size="large" color={skyDark}>
                Select one or more work periods to compare
              </Text>
            </div>
          </EmptyStateContainer>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <BrandedLoadingOverlay visible={isFetching || false} variant="colored" />
        <ComparisonTabs activeTab={activeTab} onTabChange={handleTabChange}>
          {activeTab === ComparisonTab.TABLE && tableComponent}
          {activeTab === ComparisonTab.MEASURE_COMPARISON && measureComparisonComponent}
          {activeTab === ComparisonTab.MEASURES_OVER_TIME && measuresOverTimeComponent}
        </ComparisonTabs>
      </Fragment>
    );
  }, [
    isLoading,
    isFetching,
    selectedWorkPeriods,
    activeTab,
    handleTabChange,
    tableComponent,
    measureComparisonComponent,
    measuresOverTimeComponent,
  ]);

  // Render the side filters with board selection and work periods
  const renderSideFilters = useCallback(() => {
    return (
      <SideFiltersContainer>
        {activeTab === ComparisonTab.MEASURE_COMPARISON || activeTab === ComparisonTab.MEASURES_OVER_TIME ? (
          <FilterSection>
            {activeTab === ComparisonTab.MEASURE_COMPARISON && (
              <ChartFilterControls
                customMeasures={customMeasures}
                selectedMeasure={selectedMeasures}
                onMeasureChange={handleMeasuresChange}
                isMultiSelect={true}
              />
            )}

            {activeTab === ComparisonTab.MEASURES_OVER_TIME && (
              <ChartFilterControls
                customMeasures={customMeasures}
                selectedMeasure={selectedMeasure}
                onMeasureChange={handleMeasureChange}
                showTrendLine={showTrendLine}
                onShowTrendLineChange={handleShowTrendLineChange}
                showDots={showDots}
                onShowDotsChange={handleShowDotsChange}
              />
            )}
          </FilterSection>
        ) : null}
        <SideFiltersContainer>
          <WorkPeriodsFilter isComparisonView={true} />
        </SideFiltersContainer>
      </SideFiltersContainer>
    );
  }, [
    activeTab,
    customMeasures,
    selectedMeasures,
    handleMeasuresChange,
    selectedMeasure,
    handleMeasureChange,
    showTrendLine,
    handleShowTrendLineChange,
    showDots,
    handleShowDotsChange,
  ]);

  // Handle closing the side section
  const handleCloseSideSection = useCallback(() => {
    setShowSideSection(false);
    setSideSectionTasks([]);
    setSideSectionTitle('');
  }, []);

  // Memoize the entire page layout to prevent unnecessary re-renders
  const pageLayout = useMemo(
    () => (
      <PageLayout
        controlBar={null}
        sideFilters={{
          content: renderSideFilters(),
          show: isFilterPanelOpen,
          setShow: toggleFilterPanel,
        }}
        mainSection={renderMainSection()}
        sideSection={
          showSideSection ? (
            <TaskSideSection
              categoryTitle={sideSectionTitle}
              tasks={sideSectionTasks}
              onClose={handleCloseSideSection}
              isLoading={false}
            />
          ) : null
        }
      />
    ),
    [
      renderSideFilters,
      renderMainSection,
      isFilterPanelOpen,
      toggleFilterPanel,
      showSideSection,
      sideSectionTitle,
      sideSectionTasks,
      handleCloseSideSection,
    ],
  );

  return pageLayout;
}

// Styled components
const SideFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FilterSection = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-radius: 8px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
`;
