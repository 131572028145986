import { styled } from '@linaria/react';
import i18n from '../../../base-dictionary';
import { baseWhite, secondaryBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { H2 } from '../../../ui-library/typography/typography';
import { DropShadowContainer } from '../../process-analysis/initiative-sections/initiative-overview';

export function DataManagementHeader({
  title,
  icon,
  onClickBack,
}: {
  title: string;
  icon: string;
  onClickBack: () => void;
}) {
  return (
    <DataManagementHeaderContainer>
      <Button
        radius="xl"
        variant="secondary"
        onClick={onClickBack}
        leftSection={<Icon name="keyboard_backspace" color={baseWhite} />}
      >
        {i18n.t('common.actions.back')}
      </Button>
      <Icon style={{ marginLeft: 8 }} name={icon} color={secondaryBase} variant="outlined" size={28} />
      <H2>{title}</H2>
    </DataManagementHeaderContainer>
  );
}

const DataManagementHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DataManagementDropShadowContainer = styled(DropShadowContainer)`
  border-radius: 8px;
  width: 100%;
  height: calc(100vh - 200px);
  padding: 24px 16px 16px 16px;
`;
