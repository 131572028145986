import { Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { OverviewCard } from '../../../../components/overview-card/overview-card';
import { skyDark } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import {
  getAverageRiskByFieldChart,
  getAverageRiskScoreChart,
  getRiskCategoryBreakdownChart,
} from '../risk-analysis-chart.helpers';
import { RiskAnalysisPullRequest } from '../risk-analysis.client.type';

export function RiskAnalysisPullRequestOverview({ pullRequests }: { pullRequests: RiskAnalysisPullRequest[] }) {
  const [riskCategoryBreakdownChart, setRiskCategoryBreakdownChart] = useState<JSX.Element | null>(null);
  const [averageRiskbyRepositoryChart, setAverageRiskbyRepositoryChart] = useState<JSX.Element | null>(null);
  const [averageRiskOverTimeChart, setAverageRiskOverTimeChart] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const riskCategoryBreakdownChart = getRiskCategoryBreakdownChart(pullRequests);
    setRiskCategoryBreakdownChart(riskCategoryBreakdownChart);

    const averageRiskbyRepositoryChart = getAverageRiskByFieldChart(
      pullRequests.map((pr) => pr.flora_annotations?.risk_score ?? 0),
      pullRequests.map((pr) => pr.repository.name),
    );
    setAverageRiskbyRepositoryChart(averageRiskbyRepositoryChart);

    const averageRiskOverTimeChart = getAverageRiskScoreChart(pullRequests, {
      dateField: 'created_at',
      label: 'Created Date',
    });
    setAverageRiskOverTimeChart(averageRiskOverTimeChart);
  }, [pullRequests]);

  return (
    <Group>
      <OverviewCard
        title="Average Risk Score Over Time"
        icon={<Icon name="show_chart" size={20} color={skyDark} />}
        content={averageRiskOverTimeChart}
      />
      <OverviewCard
        title="Risk Category Breakdown"
        icon={<Icon name="bar_chart" size={20} color={skyDark} />}
        content={riskCategoryBreakdownChart}
      />
      <OverviewCard
        title="Average Risk Score by Repository"
        icon={<Icon name="bar_chart" size={20} color={skyDark} />}
        content={averageRiskbyRepositoryChart}
      />
    </Group>
  );
}
