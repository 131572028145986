import { useMemo } from 'react';
import { FilterGroup } from '../../../../components/filters/filter-panel.types';
import { Target } from '../targets-client.type';
import { getTargetScope } from '../targets.helpers';
import { SCOPE_OPTIONS } from './targets-filters.hooks';

/**
 * Hook to filter targets based on provided filter criteria
 *
 * @param {Target[] | undefined} targets - The targets to filter
 * @param {Object} filters - The filter criteria
 * @param {string[]} filters.scopeFilters - Array of selected scope filter values
 * @param {string[]} filters.activeFilter - Array of selected active status filter values
 * @returns {Object} Object containing:
 *   - filteredTargets: Array of targets that match the filter criteria
 */
const useFilteredData = (
  targets: Target[] | undefined,
  filters: {
    scopeFilters: string[];
    activeFilter: string[];
  },
): {
  filteredTargets: Target[];
} => {
  const filteredTargets = useMemo(() => {
    return (targets ?? []).filter((target) => {
      // Filter by scope - if no filters selected, show no targets
      const scope = getTargetScope(target);
      const scopeMatch = filters.scopeFilters.includes(scope);

      // Filter by active status - if 'all' is selected, show all statuses
      const isActive = target.is_active ? 'active' : 'inactive';
      const statusMatch = filters.activeFilter.includes(isActive);

      return scopeMatch && statusMatch;
    });
  }, [targets, filters]);

  return {
    filteredTargets,
  };
};

/**
 * Generates filter group configurations for the filter panel
 *
 * @param {Object} filters - The filter state and setter functions
 * @param {string[]} filters.scopeFilters - Array of selected scope filter values
 * @param {string[]} filters.activeFilter - Array of selected active status filter values
 * @param {Function} filters.setScopeFilters - Function to update scope filters
 * @param {Function} filters.setActiveFilter - Function to update active status filters
 * @returns {FilterGroup[]} Array of filter group configurations
 */
const getFilterGroups = (filters: {
  scopeFilters: string[];
  activeFilter: string[];
  setScopeFilters: (filters: string[]) => void;
  setActiveFilter: (filter: string[]) => void;
}): FilterGroup[] => {
  return [
    {
      name: 'Scope',
      icon: 'track_changes',
      options: SCOPE_OPTIONS,
      selectedValues: filters.scopeFilters,
      onSelectionChange: filters.setScopeFilters,
    },
    {
      name: 'Status',
      icon: 'schedule',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
      ],
      selectedValues: filters.activeFilter,
      onSelectionChange: filters.setActiveFilter,
    },
  ];
};

export { getFilterGroups, useFilteredData };
