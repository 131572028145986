import { Table } from '@mantine/core';
import React, { useMemo } from 'react';
import { TransformedTarget } from '../../adherence/targets/targets-client.type';
import { getTargetComparisonSymbol } from '../../adherence/targets/targets.helpers';

interface TableWidgetProps {
  data: {
    id: string;
    name: string;
    date: string;
    value: number;
  }[];
  selectedTarget: TransformedTarget | null;
}

export const TableWidget: React.FC<TableWidgetProps> = ({ data, selectedTarget }) => {
  const tableData = useMemo(
    () =>
      data.map((row) => {
        const targetValue = selectedTarget?.time_allocations?.[row.date];
        if (selectedTarget && targetValue && typeof targetValue === 'number') {
          return {
            ...row,
            target_value: `${targetValue}`,
          };
        } else if (selectedTarget && targetValue && typeof targetValue === 'object') {
          return {
            ...row,
            target_value: getTargetComparisonSymbol(
              selectedTarget.target_comparison,
              targetValue.value_lower,
              targetValue.value_upper,
            ),
          };
        }
        return {
          ...row,
          target_value: null,
        };
      }),
    [data, selectedTarget],
  );

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Object Name</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Value</Table.Th>
          {selectedTarget && <Table.Th>Target Value</Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {tableData.map(
          (row) =>
            row.value > 0 && (
              <Table.Tr key={`${row.id}-${row.date}`}>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row.date}</Table.Td>
                <Table.Td>{row.value.toLocaleString()}</Table.Td>
                {selectedTarget && <Table.Td>{row.target_value}</Table.Td>}
              </Table.Tr>
            ),
        )}
      </Table.Tbody>
    </Table>
  );
};
