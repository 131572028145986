import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStartIntegrationProcess } from '../../api/integrations-client/integrations-client.hooks';
import { ExternalService } from '../../api/integrations-client/integrations-client.type';
import { isString } from '../../helpers/string-helpers/string-helpers';
import { useRunOnce } from '../../hooks/useRunOnce';
import { useOrgId } from '../../store/global-store/global-store.hooks';
import { useIntegrationsStore } from '../../store/integrations-store/integrations-store';
import { parseRedirect } from './integrations.helpers';

/**
 * Custom hook to manage the redirect value in local storage.
 *
 * @return {{ redirect: string | null; setRedirect: (path: string | null) => void }} An object with a redirect value and a function to set the redirect value.
 */
const useRedirect = (): { redirect: string | null; setRedirect: (path: string | null) => void } => {
  const redirect = parseRedirect();
  const isValidRedirect = isString(redirect) && redirect.startsWith('/');

  const setRedirect = (path: string | null) => {
    if (!path) {
      return localStorage.removeItem('integrations-redirect');
    }

    return localStorage.setItem('integrations-redirect', path);
  };

  return {
    redirect: isValidRedirect ? redirect : null,
    setRedirect,
  };
};

/**
 * Custom hook to handle error parameters in the URL search params.
 * If an error parameter is present, redirects the user to the integrations page.
 *
 * @returns {void}
 */
const useHandleErrors = (): void => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');

    if (error) {
      navigate('/application/integrations');
    }
  }, [searchParams, navigate]);
};

/**
 * Custom hook to handle basic authentication for integrations.
 * This hook manages the integration process by starting it when valid data is available
 * and handling success/error cases appropriately.
 *
 * @template T - The type of data returned on successful authentication
 * @param {Object} params - Hook parameters
 * @param {ExternalService} params.service - The external service to authenticate with
 * @param {(data: T) => void} params.onSuccess - Callback function called when authentication succeeds
 * @returns {Object} Object containing loading state
 * @property {boolean} isLoading - Whether the authentication process is currently loading
 */
const useBasicAuth = <T>({
  service,
  onSuccess,
}: {
  service: ExternalService;
  onSuccess: (data: T) => void;
}): { isLoading: boolean } => {
  const navigate = useNavigate();

  const integration = useIntegrationsStore((state) => state.integration);
  const orgId = useOrgId();
  const isValidData = service && orgId && integration?.baseUrl && integration?.system_access_id && integration?.id;

  const {
    mutateAsync: startIntegrationProcess,
    isPending,
    isSuccess,
    isError,
  } = useStartIntegrationProcess({
    onSuccess,
    onError: () => {
      navigate('/application/integrations');
    },
  });

  useRunOnce(() => {
    const handleStartIntegrationProcess = async () => {
      if (!isValidData || isPending || isSuccess || isError) {
        return;
      }

      await startIntegrationProcess({
        service,
        state: integration?.baseUrl as string,
        organization_id: orgId,
        system_access_id: integration?.system_access_id as string,
        integration_id: integration?.id as string,
      });
    };

    handleStartIntegrationProcess();
  }, [
    isValidData,
    isPending,
    isSuccess,
    startIntegrationProcess,
    integration?.baseUrl,
    integration?.system_access_id,
    orgId,
    service,
    integration?.id,
    isError,
  ]);

  return {
    isLoading: isPending,
  };
};

export { useBasicAuth, useHandleErrors, useRedirect };
