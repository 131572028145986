import { QueryObserverResult, useQueries } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { getWorkPeriodsMeasure } from '../../../../api/work-periods-client/work-periods-client';
import { useAvailableMeasures } from '../../../../api/work-periods-client/work-periods-client.hooks';
import {
  GetWorkPeriodsMeasurePayload,
  MeasureDataResponse,
  MeasureMetadata,
  ModuleType,
  TimeAllocationType,
  Transformer,
} from '../../../../api/work-periods-client/work-periods-client.type';
import { useOrganizationId } from '../../../../helpers/auth-helpers/auth.hooks';
import { useAvailableTargets } from '../../../adherence/targets/targets-client.hooks';
import { GetAvailableTargetsResponse, TransformedTarget } from '../../../adherence/targets/targets-client.type';
import { timeAllocationTransformerMeasures } from '../../../process-analysis/comparison-view/comparison-view.hooks';

/**
 * Hook to fetch available targets for a specific initiative
 *
 * @param {Object} params - The params object
 * @param {ExtendedInitiative} params.initiative - The initiative to fetch targets for
 * @returns {Object} Object containing:
 *   - data: The available targets data organized by entity ID
 *   - query: The React Query result object
 */

const useInitiativeAvailableTargets = ({
  initiative,
}: {
  initiative: ExtendedInitiative;
}): { data: TransformedTarget[] | undefined; query: QueryObserverResult<GetAvailableTargetsResponse> } => {
  const organizationId = useOrganizationId();
  const { data, query } = useAvailableTargets({
    payload: {
      organization_id: organizationId || '',
      initiative_ids: initiative.id,
      start_date: dayjs(initiative.start_date ?? '2000-01-01').format('YYYY-MM-DD'),
      end_date: dayjs(initiative.end_date).format('YYYY-MM-DD'),
      time_allocation_type: TimeAllocationType.BiWeekly,
    },
  });

  return { data: data?.[initiative.id], query };
};

/**
 * Hook to fetch available measures for a specific initiative
 *
 * @param {Object} params - The params object
 * @param {ExtendedInitiative} params.initiative - The initiative to fetch measures for
 * @returns {Object} Object containing:
 *   - data: The available measures data
 */
const useInitiativeAvailableMeasures = ({
  initiative,
}: {
  initiative: ExtendedInitiative;
}): { data: MeasureMetadata[] | undefined } => {
  const { data } = useAvailableMeasures({
    payload: {
      scope: ModuleType.ProcessAnalysis,
      project_id: initiative.projects?.[0]?.id || undefined,
    },
    options: {
      enabled: !!initiative.projects?.[0]?.id,
      staleTime: Infinity,
    },
  });

  return { data: data?.[ModuleType.ProcessAnalysis]?.measures || [] };
};

/**
 * Hook to fetch measure data for a specific initiative
 *
 * @param {Object} params - The params object
 * @param {ExtendedInitiative} params.initiative - The initiative to fetch measure data for
 * @param {MeasureMetadata[]} params.measureMetadata - The measure metadata to fetch data for
 * @returns {Object} Object containing:
 *   - data: The measure data
 *   - isLoading: Whether the measure data is loading
 */
const useInitiativeMeasureData = ({
  initiative,
  measureMetadata,
}: {
  initiative: ExtendedInitiative;
  measureMetadata: MeasureMetadata[];
}): { data: (MeasureDataResponse | undefined)[]; isLoading: boolean } => {
  const queryConfigs = measureMetadata.map((measure) => {
    const isCustom = measure.is_custom ?? false;
    const payload: GetWorkPeriodsMeasurePayload = {
      start_date: dayjs(initiative.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(initiative.end_date).format('YYYY-MM-DD'),
      initiative_id: initiative.id,
      measure_name: [measure.measure_name],
      force_recalculation: false,
      is_custom: isCustom,
      time_allocation_type: TimeAllocationType.BiWeekly,
    };
    if (isCustom) {
      payload.transformer = Transformer.ReturnFirstMetricOnly;
    } else if (timeAllocationTransformerMeasures.includes(measure.measure_name)) {
      payload.transformer = Transformer.TimeAllocationValuesOnly;
    }
    return {
      queryKey: ['initiative-measure-data', initiative.id, measure.measure_name] as const,
      queryFn: () => getWorkPeriodsMeasure<MeasureDataResponse>(payload),
      staleTime: 1000 * 60 * 5, // Cache for 5 minutes
      gcTime: 1000 * 60 * 30, // Keep in cache for 30 minutes
      retry: false,
    };
  });

  const queries = useQueries({ queries: queryConfigs });
  const isLoading = queries.some((query) => query.isLoading);
  const data = queries.map((query) => query.data);
  return { data, isLoading };
};

export { useInitiativeAvailableMeasures, useInitiativeAvailableTargets, useInitiativeMeasureData };
