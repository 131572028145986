import { Card, Checkbox, Flex, Group, MultiSelect, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Component } from '../../../api/pages-client/pages-client.type';
import { useAvailableMeasures } from '../../../api/work-periods-client/work-periods-client.hooks';
import { ModuleType } from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { secondaryBase, skyBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Select } from '../../../ui-library/select/select';
import { Text } from '../../../ui-library/typography/typography';
import { DATE_RANGES, OBJECT_TYPES, WIDGET_SIZES, WIDGET_TYPES } from '../dashboards.types';
import { DashboardModalStyled } from './dashboard-modal';
import { DashboardWidget } from './dashboard-widget';

interface AddWidgetModalProps {
  opened: boolean;
  onClose: () => void;
  onSave: (widget: Component) => void;
  initialData?: Component;
  isEditing?: boolean;
}

export const AddWidgetModal: React.FC<AddWidgetModalProps> = ({
  opened,
  onClose,
  onSave,
  initialData,
  isEditing = false,
}) => {
  const organizationId = useGlobalStore((state) => state.organization?.id);
  const portfolios = useGlobalStore((state) => state.portfolios).filter((p) => p.organization?.id === organizationId);
  const teams = useGlobalStore((state) => state.teams);

  const baseForm = {
    name: '',
    description: '',
    metric: '',
    dateRange: 'last_30_days',
    objectType: '',
    objects: [] as string[],
    widgetType: 'line',
    widgetSize: 'standard',
    showTitle: true,
  };
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(baseForm);
  const [metrics, setMetrics] = useState<Array<{ value: string; label: string }>>([]);

  // Handle available measures data first
  const { data: availableMeasuresData } = useAvailableMeasures({
    payload: {
      scope: ModuleType.ProcessAnalysis,
      portfolio_id: formData.objectType === 'portfolio' ? formData.objects[0] : undefined,
      project_id: formData.objectType === 'project' ? formData.objects[0] : undefined,
      subproject_id: formData.objectType === 'board' ? formData.objects[0] : undefined,
    },
    options: {
      enabled: !!formData.objectType && formData.objects.length === 1,
      staleTime: Infinity,
    },
  });

  // Compute objects based on current object type
  const availableObjects = React.useMemo(() => {
    if (formData.objectType === 'portfolio') {
      return portfolios.map((p) => ({
        value: p.id,
        label: p.name,
      }));
    } else if (formData.objectType === 'project') {
      return teams.map((team) => ({
        value: team.id,
        label: team.name,
      }));
    } else if (formData.objectType === 'board') {
      return teams.flatMap((team) =>
        team.subprojects.map((board) => ({
          value: board.id,
          label: `${team.name} - ${board.name}`,
        })),
      );
    }
    return [];
  }, [formData.objectType, portfolios, teams]);

  // Update metrics when available measures data changes
  useEffect(() => {
    if (availableMeasuresData && formData.objects.length === 1) {
      const measures = availableMeasuresData[ModuleType.ProcessAnalysis]?.measures || [];
      setMetrics(
        measures.map((measure) => ({
          value: measure.measure_name,
          label: measure.measure_title,
        })),
      );
    } else {
      setMetrics([]);
    }
  }, [availableMeasuresData, formData.objects.length]);

  // Initialize form data when editing
  useEffect(() => {
    if (initialData && isEditing) {
      const componentData = initialData.component_data?.[0];
      if (componentData) {
        setFormData({
          name: initialData.name,
          description: initialData.description || '',
          metric: componentData.measure,
          dateRange: componentData.range_type,
          objectType: componentData.object_type,
          objects: componentData.object_ids,
          widgetType: initialData.sub_type || 'line',
          widgetSize: 'standard',
          showTitle: initialData.show_title,
        });
      }
    }
  }, [initialData, isEditing]);

  const getPreviewComponent = (): Component => ({
    id: initialData?.id || '',
    page: initialData?.page || '',
    name: formData.name,
    description: formData.description,
    type: formData.widgetType === 'table' ? 'table' : formData.widgetType === 'tile' ? 'tile' : 'chart',
    sub_type: ['table', 'tile'].includes(formData.widgetType) ? undefined : formData.widgetType,
    show_title: formData.showTitle,
    component_data: [
      {
        id: initialData?.component_data?.[0]?.id || 'preview',
        measure: formData.metric,
        range_type: formData.dateRange,
        object_type: formData.objectType,
        object_ids: formData.objects,
      },
    ],
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (step === 1) {
      setStep(2);
      return;
    }
    setFormData(baseForm);
    setStep(1);
    onSave(getPreviewComponent());
  };

  const renderStep1 = () => (
    <>
      <Text size="regular" color="dimmed" style={{ marginBottom: '1rem' }}>
        Easily create a new widget to tailor your dashboard to your needs.
      </Text>
      <Flex direction="column" gap="md">
        <TextInput
          label="Widget Name"
          placeholder="QA Test Project"
          required
          value={formData.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, name: event.target.value })
          }
        />

        <Textarea
          label="Description"
          placeholder="Measures the average time taken by the QA team to respond to assigned tasks or issues."
          value={formData.description}
          onChange={(event) => setFormData({ ...formData, description: event.target.value })}
          minRows={2}
        />

        <Group grow>
          <div>
            <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
              Select Object Type{' '}
            </Text>
            <Select
              options={OBJECT_TYPES}
              value={formData.objectType}
              onChange={(value) => {
                setFormData({ ...formData, objectType: value, objects: [], metric: '', dateRange: '' });
              }}
              placeholder="Select object type"
              searchable
            />
          </div>
          <div>
            <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
              Select Objects{' '}
            </Text>
            <MultiSelect
              value={formData.objects}
              data={availableObjects}
              styles={{
                input: {
                  borderRadius: '16px',
                },
                dropdown: {
                  zIndex: 1000,
                  borderRadius: '16px',
                  fontSize: '16px',
                  fontWeight: '800',
                },
              }}
              onChange={(value) => setFormData({ ...formData, objects: value })}
              placeholder="Select objects"
              searchable
              disabled={!formData.objectType}
            />
          </div>
        </Group>

        {formData.objectType && formData.objects.length > 0 && (
          <Group grow>
            <div>
              <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
                Select Metric{' '}
              </Text>
              <Select
                options={metrics}
                value={formData.metric}
                onChange={(value) => setFormData({ ...formData, metric: value || '' })}
                placeholder="Select a metric"
                searchable
              />
            </div>
            <div>
              <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
                Select Date Range{' '}
              </Text>
              <Select
                options={DATE_RANGES}
                value={formData.dateRange}
                onChange={(value) => setFormData({ ...formData, dateRange: value })}
                placeholder="Last 30 Days"
                searchable
              />
            </div>
          </Group>
        )}
      </Flex>
    </>
  );

  const renderStep2 = () => (
    <>
      <Text size="regular" color="dimmed" style={{ marginBottom: '1rem' }}>
        Widget Preview
      </Text>
      <Flex direction="column" gap="md">
        <Group grow>
          <div>
            <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
              Widget Type{' '}
            </Text>
            <Select
              value={formData.widgetType}
              options={WIDGET_TYPES}
              onChange={(value) => setFormData({ ...formData, widgetType: value })}
              placeholder="Select widget type"
              searchable
            />
          </div>
          <div>
            <Text size="regular" weight="bold" style={{ marginBottom: '0.25rem' }}>
              Widget Size{' '}
            </Text>
            <Select
              value={formData.widgetSize}
              options={WIDGET_SIZES}
              onChange={(value) => setFormData({ ...formData, widgetSize: value })}
              placeholder="Select widget size"
              searchable
            />
          </div>
        </Group>
        <Group>
          <Checkbox
            label="Show Title"
            checked={formData.showTitle}
            color={secondaryBase}
            onChange={(event) => setFormData({ ...formData, showTitle: event.currentTarget.checked })}
          />
        </Group>
        <Card withBorder>
          <Text size="regular" weight="bold" style={{ marginBottom: '1rem' }}>
            Preview
          </Text>
          <DashboardWidget component={getPreviewComponent()} onEdit={() => {}} onDelete={() => {}} />
        </Card>
      </Flex>
    </>
  );

  return (
    <DashboardModalStyled
      opened={opened}
      onClose={onClose}
      title={
        <div style={{ display: 'flex', gap: '8px' }}>
          <Text size="large" weight="bold">
            {isEditing ? 'Edit Widget' : 'Add Widget'}
          </Text>
          <Text size="large" weight="medium" color={skyBase}>
            (Step {step} of 2)
          </Text>
        </div>
      }
      size="xl"
      padding="lg"
      centered
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {step === 1 ? renderStep1() : renderStep2()}
        <Divider style={{ marginTop: '32px' }} />
        <Flex justify="space-between" mt="xl">
          <div>
            {step === 2 && (
              <Button
                variant="outline"
                radius="xl"
                onClick={() => setStep(1)}
                leftSection={<Icon name="arrow_back" size={16} color={skyBase} />}
              >
                Back
              </Button>
            )}
          </div>
          <Flex gap="md">
            <Button variant="outline" radius="xl" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              radius="xl"
              variant="secondary"
              rightSection={<Icon name={step === 1 ? 'arrow_forward' : 'check_circle'} size={16} color="white" />}
            >
              {step === 1 ? 'Next Step' : isEditing ? 'Save Changes' : 'Create Widget'}
            </Button>
          </Flex>
        </Flex>
      </form>
    </DashboardModalStyled>
  );
};
