import dayjs from 'dayjs';
import {
  CustomMeasureDataResponse,
  Measure,
  MeasureDataResponse,
  MeasureMetadata,
  MeasureUnits,
  MeasureValuesOverTime,
  TimeAllocationType,
} from '../../../api/work-periods-client/work-periods-client.type';
import { simpleLinearRegression } from '../../../helpers/math-helpers/math-helpers';
import { RegressionResult } from '../../../helpers/math-helpers/math-helpers.type';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { TransformedTarget } from '../../adherence/targets/targets-client.type';
import { MEASURE_COLOR_MAP } from '../process-analysis.helpers';
import { Tab } from '../process-analysis.type';
import { MeasureComparisonSelectionAggregated, MeasuresWithColor } from './measure-comparison.type';

/** determines whether the Y axis is in use
 *
 * @param measureNames - a list of measure names
 * @param trendNames - a list of trend names
 * @param measureUnitMap - a map of measure names to their units
 * @returns boolean - whether the Y axis is in use
 */
function yAxisInUse(measureNames: Measure[], trendNames: Measure[], measureUnitMap: MeasureUnitMap): boolean {
  const percentageMeasures = Object.entries(measureUnitMap)
    .filter(([_, unit]) => unit === 'Percentage')
    .map(([name]) => name);

  return (
    measureNames.some((name) => percentageMeasures.includes(name)) ||
    trendNames.some((name) => percentageMeasures.includes(name))
  );
}

/** determines whether the Y1 axis is in use
 *
 * @param measureNames - a list of measure names
 * @param trendNames - a list of trend names
 * @param measureUnitMap - a map of measure names to their units
 * @returns boolean - whether the Y1 axis is in use
 */
function y1AxisInUse(measureNames: Measure[], trendNames: Measure[], measureUnitMap: MeasureUnitMap): boolean {
  const nonPercentageMeasures = Object.entries(measureUnitMap)
    .filter(([_, unit]) => unit !== 'Percentage')
    .map(([name]) => name);
  return (
    measureNames.some((name) => nonPercentageMeasures.includes(name)) ||
    trendNames.some((name) => nonPercentageMeasures.includes(name))
  );
}

/**
 * Returns the label for the Y1 axis based on the measures and trends in use.
 *
 * @param {Measure[]} measureNames - An array of measure names.
 * @param {Measure[]} trendNames - An array of trend names.
 * @param {MeasureUnitMap} measureUnitMap - A map of measure names to their units.
 * @return {MeasureUnits | 'Units'} The label for the Y1 axis.
 */
function y1AxisLabel(
  measureNames: Measure[],
  trendNames: Measure[],
  measureUnitMap: MeasureUnitMap,
): MeasureUnits | 'Units' {
  const nonPercentageMeasures = Object.entries(measureUnitMap).filter(([_, unit]) => unit !== 'Percentage');

  const uniqueNonPercentageMeasureUnitsInUse = new Set(
    nonPercentageMeasures
      .filter(([name, _]) => measureNames.concat(trendNames).includes(name as Measure))
      .map(([_, unit]) => unit),
  );

  return uniqueNonPercentageMeasureUnitsInUse.size !== 1
    ? 'Units'
    : Array.from(uniqueNonPercentageMeasureUnitsInUse)[0];
}

/**
 * Calculates the average value of the given monthly data.
 *
 * @param {MeasureValuesOverTime} data - The data to calculate the average from.
 * @param {boolean} isZeroValid - Whether zero values should be included in the calculation
 * @return {number | null} The average value of the data. Returns null if the data is empty or all values are null.
 */
const calculateAverage = (data: MeasureValuesOverTime, isZeroValid: boolean): number | null => {
  const validValues = Object.values(data).filter((value) => value !== null && (isZeroValid || value !== 0));

  if (validValues.length === 0) {
    return null;
  }

  return validValues.reduce((acc, value) => acc + value, 0) / validValues.length;
};

/**
 * Returns an array of month labels between the given start and end dates.
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @return {string[]} An array of month labels in the format 'MMM YY'.
 */
const getMonthLabels = (startDate: Date, endDate: Date): string[] => {
  const labels = [];
  let current = dayjs(startDate);

  while (current.isBefore(endDate)) {
    labels.push(current.format('MMM YY'));
    current = current.add(1, 'month');
  }

  return labels;
};

/**
 * Returns an array of chart labels based on the provided measure values and time allocation.
 *
 * @param {MeasureDataResponse} measureData - The measure data to be transformed.
 * @param {TimeAllocationType} timeAllocation - The time allocation for the chart labels.
 * @return {string[]} An array of chart labels in the format determined by the time allocation.
 */
const getChartLabels = (
  measureData: MeasureDataResponse | CustomMeasureDataResponse,
  timeAllocation: TimeAllocationType,
): string[] => {
  const measures = Object.keys(measureData) as Measure[];
  const defaultMeasure = measures.length > 0 ? measures.find((measure) => measureData[measure] !== null) : null;

  if (!defaultMeasure) {
    return [];
  }

  const measureValues = measureData[defaultMeasure];

  const rawLabels = Object.keys(measureValues)
    .map(dayjs)
    .sort((a, b) => a.diff(b));

  const customFormat = timeAllocation === TimeAllocationType.Monthly ? 'YYYY MMM' : 'YYYY MMM D';

  return rawLabels.map((label) => label.format(customFormat).toLocaleUpperCase());
};

/**
 * Returns an object mapping measure names to their corresponding colors.
 *
 * @param {Measure[]} measures - An array of measure names.
 * @return {MeasuresWithColor} An object containing measure names as keys and their corresponding colors as values.
 */
const getMeasuresWithColors = (measures: Measure[]): MeasuresWithColor => {
  return measures.reduce((acc, curr) => {
    if (curr in MEASURE_COLOR_MAP) {
      acc[curr as keyof typeof acc] = MEASURE_COLOR_MAP[curr as keyof typeof MEASURE_COLOR_MAP];
    } else {
      acc[curr as keyof typeof acc] = MEASURE_COLOR_MAP[Measure.Custom];
    }
    return acc;
  }, {} as MeasuresWithColor);
};

/**
 * Sorts the given measure data by date in ascending order.
 *
 * @param {MeasureValuesOverTime} data - The measure data to be sorted.
 * @return {MeasureValuesOverTime} The sorted measure data.
 */
const sortMeasureDataOverTime = (data: MeasureValuesOverTime): MeasureValuesOverTime => {
  const sortedLabels = Object.keys(data).sort((a, b) => dayjs(a).diff(dayjs(b)));

  return sortedLabels.reduce((acc, label) => {
    acc[label] = data[label];

    return acc;
  }, {} as MeasureValuesOverTime);
};

/**
 * Returns an array of default measures for the measure comparison view.
 *
 * @return {Measure[]} An array of default measures.
 */
const getDefaultMeasures = (): Measure[] => {
  return [Measure.CycleTime, Measure.LeadTime, Measure.ReactionTime, Measure.Throughput, Measure.Velocity];
};

/**
 * Returns the default selection for the process analysis page.
 *
 * @return {MeasureComparisonSelectionAggregated} The default selection for the process analysis page.
 */
const getDefaultMeasureComparisonSelection = (): MeasureComparisonSelectionAggregated => {
  const measures = getDefaultMeasures();
  const selection = {
    measures,
    selectedMeasures: [measures[0]],
    selectedTrends: [],
    selectedTargets: [],
  };

  return {
    [Tab.Portfolios]: selection,
    [Tab.Teams]: selection,
    [Tab.Boards]: selection,
  };
};

/**
 * Calculates the trend of the measure values over time.
 * Uses simple linear regression to determine the trend direction and magnitude.
 *
 * @param {MeasureValuesOverTime} data - The measure data points over time
 * @param {boolean} isZeroValid - Whether zero values should be included in the calculation
 * @returns {RegressionResult | null} - The trend line, or null if insufficient data
 */
const calculateTrend = (data: MeasureValuesOverTime, isZeroValid: boolean): RegressionResult | null => {
  const validValues = Object.values(sortMeasureDataOverTime(data)).filter(
    (value) => value !== null && (isZeroValid || value !== 0),
  );

  if (validValues.length < 2) {
    return null;
  }

  const xAxis = Array.from({ length: validValues.length }, (_, index) => index);
  const line = simpleLinearRegression(xAxis, validValues);

  if (!line) {
    return null;
  }

  return line;
};

/**
 * Calculates the forecast for the next value based on the trend and the data.
 *
 * @param {RegressionResult | null} trend - The trend line
 * @param {MeasureValuesOverTime} data - The measure data points over time
 * @returns {number | null} - The forecast for the next value, or null if the trend is null
 */
const forecastNextValue = (trend: RegressionResult | null, data: MeasureValuesOverTime): number | null => {
  if (!trend) {
    return null;
  } else if (Object.keys(data).length === 0) {
    return trend.intercept;
  }

  return trend.slope * (Object.keys(data).length + 1) + trend.intercept;
};

/**
 * Formats a numeric value for display
 *
 * @param {number | null} value - The value to format
 * @returns {string} - Formatted string representation
 */
const formatValue = (value: number | null | undefined): string => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value.toFixed(1);
};

/**
 * Merges measure metadata with their corresponding targets.
 *
 * @param {MeasureMetadata[]} measureMetaData - Array of measure metadata objects
 * @param {TransformedTarget[]} targets - Array of transformed target objects
 * @param {boolean} [onlyWithTargets=false] - If true, only include measures that have targets
 * @returns {MeasureMetadata[]} Array of measure metadata with targets merged in
 */
const mergeMeasureMetaDataAndTargets = (
  measureMetaData: MeasureMetadata[],
  targets: TransformedTarget[],
  onlyWithTargets = false,
): MeasureMetadata[] => {
  const measureMetaDataMap = measureMetaData.reduce(
    (acc, metadata) => {
      acc[metadata.measure_name] = { ...metadata, targets: [] };
      return acc;
    },
    {} as Record<string, MeasureMetadata>,
  );

  targets.forEach((target) => {
    if (measureMetaDataMap[target.measure]) {
      measureMetaDataMap[target.measure].targets?.push(target);
    }
  });

  if (onlyWithTargets) {
    return Object.values(measureMetaDataMap).filter((metadata) => metadata.targets && metadata.targets.length > 0);
  }

  return Object.values(measureMetaDataMap);
};

export {
  calculateAverage,
  calculateTrend,
  forecastNextValue,
  formatValue,
  getChartLabels,
  getDefaultMeasureComparisonSelection,
  getDefaultMeasures,
  getMeasuresWithColors,
  getMonthLabels,
  mergeMeasureMetaDataAndTargets,
  sortMeasureDataOverTime,
  y1AxisInUse,
  y1AxisLabel,
  yAxisInUse,
};
