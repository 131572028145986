import {
  QueryObserverResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  completeOauth,
  createSystemAccess,
  getBoardStatuses,
  getBoardStatusesJDC,
  getIntegrationEntries,
  getIntegrationUrl,
  getIntegrations,
  getIntegrationsJDC,
  refreshIntegration,
  startIntegrationProcess,
} from './integrations-client';
import {
  ExternalService,
  GetBoardStatusesPayload,
  GetBoardStatusesResponse,
  Integration,
  IntegrationEntry,
  IntegrationJDC,
  IntegrationRefreshData,
  StartIntegrationProcessPayload,
} from './integrations-client.type';

/**
 * Hook to fetch and manage integrations data.
 *
 * @param {Partial<UseQueryOptions<Integration[], Error, Integration[], readonly ['integrations']>>} [options] - Optional query configuration options
 * @returns {{ data: Integration[] | undefined; query: QueryObserverResult<Integration[]> }} Object containing:
 *   - data: Array of Integration objects if query successful, undefined otherwise
 *   - query: Full query result object with loading/error states
 */
const useIntegrations = (
  options?: Partial<UseQueryOptions<Integration[], Error, Integration[], readonly ['integrations']>>,
): { data: Integration[] | undefined; query: QueryObserverResult<Integration[]> } => {
  const query = useQuery({
    queryKey: ['integrations'],
    queryFn: () => getIntegrations(),
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Hook to create system access for an integration.
 *
 * @returns {UseMutationResult} Mutation result object containing mutation function and status
 */
const useSystemAccessCreate = (): UseMutationResult<
  { id: string; message: string },
  Error,
  { id: string; orgId: string; token: string }
> => {
  return useMutation({
    mutationFn: ({ id, orgId, token }: { id: string; orgId: string; token: string }) =>
      createSystemAccess(id, orgId, token),
  });
};

/**
 * Hook to start an integration process.
 *
 * @returns {UseMutationResult} Mutation result object containing mutation function and status
 */
const useStartIntegrationProcess = <T>(
  options?: Partial<UseMutationOptions<T, Error, StartIntegrationProcessPayload>>,
): UseMutationResult<T, Error, StartIntegrationProcessPayload> => {
  return useMutation({
    mutationFn: (payload: StartIntegrationProcessPayload) => startIntegrationProcess(payload) as Promise<T>,
    ...options,
  });
};

/**
 * Returns the integration URL for a given service.
 *
 * @param {UseQueryOptions<{ url: string }, Error, { url: string }, readonly ['integrationUrl', ExternalService | null]>} options - The options for the query.
 * @returns {{ url: string | undefined, query: QueryObserverResult<{ url: string }> }} The integration URL and the query object.
 */
const useIntegrationUrl = (
  options: UseQueryOptions<
    { url: string },
    Error,
    { url: string },
    readonly ['integrationUrl', ExternalService | null]
  >,
): { url: string | undefined; query: QueryObserverResult<{ url: string }> } => {
  const query = useQuery({
    queryFn: () => {
      const service = options.queryKey[1];
      return service ? getIntegrationUrl(service) : Promise.reject('Cannot get integration url');
    },
    ...options,
  });

  return { url: query.data?.url, query };
};

/**
 * Query manager for the OAuth flow completion using the provided parameters.
 *
 * @param {UseQueryOptions<Record<string, unknown>, Error, Record<string, unknown>, readonly ['complete-oauth', ExternalService, string | null, string | null, string | null, string | null, string | null]>} options - The options for the useQuery hook.
 * @return {{ data: Record<string, unknown> | undefined; query: QueryObserverResult<Record<string, unknown>> }} The fetched data and the query result.
 */
const useCompleteOauth = (
  options: UseQueryOptions<
    Record<string, unknown>,
    Error,
    Record<string, unknown>,
    readonly [
      'complete-oauth',
      ExternalService,
      string | null,
      string | null,
      string | null,
      string | null,
      string | null,
    ]
  >,
): { data: Record<string, unknown> | undefined; query: QueryObserverResult<Record<string, unknown>> } => {
  const query = useQuery({
    queryFn: () => {
      const [_, service, userId, organizationId, code, state, integrationId] = options.queryKey;
      if (!userId || !organizationId || !code || !state) {
        throw new Error('Missing required parameters for OAuth completion');
      }
      return completeOauth(service, userId, organizationId, code, state, integrationId);
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching board statuses.
 *
 * @param {UseQueryOptions<GetBoardStatusesResponse, Error, GetBoardStatusesResponse, readonly ['board-statuses', GetBoardStatusesPayload]>} options - optional query options
 * @return {{data: GetBoardStatusesResponse, query: QueryObserverResult<GetBoardStatusesResponse>}} the data and the query object
 */
const useBoardStatuses = (
  options: UseQueryOptions<
    GetBoardStatusesResponse,
    Error,
    GetBoardStatusesResponse,
    readonly ['board-statuses', GetBoardStatusesPayload]
  >,
): { data: GetBoardStatusesResponse | undefined; query: QueryObserverResult<GetBoardStatusesResponse> } => {
  const query = useQuery({
    queryFn: () => {
      const [_, payload] = options.queryKey;
      const isValidPayload = () => {
        if (payload.service === ExternalService.Jira) {
          return !!payload.project_id && !!payload.board_id && !!payload.system_access_id;
        }

        if (payload.service === ExternalService.ADO) {
          return !!payload.project_id && !!payload.organization_name && !!payload.system_access_id && !!payload.team_id;
        }

        return false;
      };

      return isValidPayload() ? getBoardStatuses(payload) : Promise.reject('Cannot get board statuses');
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching integration entries.
 *
 * @param {UseQueryOptions<IntegrationEntry[]>} options - The options for the query.
 * @returns {{ data: IntegrationEntry[], query: QueryObserverResult<IntegrationEntry[]> }} - An object containing the fetched data and the query result.
 */
const useIntegrationEntries = <TData = IntegrationEntry[]>(
  options: UseQueryOptions<TData, Error, TData, readonly ['integration-entries', string | null]>,
): { data: TData | undefined; query: QueryObserverResult<TData> } => {
  const query = useQuery({
    queryFn: async () => {
      const organizationId = options.queryKey[1];
      if (!organizationId) {
        throw new Error('Cannot get integration entries: Missing organization ID');
      }
      return getIntegrationEntries(organizationId) as Promise<TData>;
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for refreshing integration data.
 *
 * @param {UseQueryOptions<IntegrationRefreshData, Error, IntegrationRefreshData, readonly ['integration-refresh', string | null]>} options - The options for the query.
 * @returns {{ data: IntegrationRefreshData | undefined; query: QueryObserverResult<IntegrationRefreshData>; }} The integration refresh data and query result.
 */
const useIntegrationRefresh = (
  options: UseQueryOptions<
    IntegrationRefreshData,
    Error,
    IntegrationRefreshData,
    readonly ['integration-refresh', string | null]
  >,
): {
  data: IntegrationRefreshData | undefined;
  query: QueryObserverResult<IntegrationRefreshData>;
} => {
  const query = useQuery({
    queryFn: () => {
      const integrationId = options.queryKey[1];
      return integrationId ? refreshIntegration(integrationId) : Promise.reject('Cannot refresh integration');
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching JDC integrations.
 *
 * @param {UseQueryOptions<IntegrationJDC[], Error, IntegrationJDC[], readonly ['integrations-jdc', string | null]>} options - The options for the query.
 * @return {{ data: IntegrationJDC[] | undefined; query: QueryObserverResult<IntegrationJDC[]> }} The fetched data and the query result object.
 */
const useIntegrationsJDC = (
  options: UseQueryOptions<IntegrationJDC[], Error, IntegrationJDC[], readonly ['integrations-jdc', string | null]>,
): { data: IntegrationJDC[] | undefined; query: QueryObserverResult<IntegrationJDC[]> } => {
  const query = useQuery({
    queryFn: () => {
      const organizationId = options.queryKey[1];
      return organizationId ? getIntegrationsJDC(organizationId) : Promise.reject('Cannot get JDC integrations');
    },
    ...options,
  });

  return { data: query.data, query };
};

/**
 * Query manager for fetching JDC board statuses based on the board ID.
 *
 * @param {UseQueryOptions<{ statuses: string[] }, Error, { statuses: string[] }, readonly ['board-statuses-jdc', string | null]>} [options] - Additional options for the query
 * @return {{ data: { statuses: string[] }, query: QueryObserverResult<{ statuses: string[] }> }} The data and query object
 */
const useBoardStatusesJDC = (
  options: UseQueryOptions<
    { statuses: string[] },
    Error,
    { statuses: string[] },
    readonly ['board-statuses-jdc', string | null]
  >,
): { data: { statuses: string[] } | undefined; query: QueryObserverResult<{ statuses: string[] }> } => {
  const query = useQuery({
    queryFn: () => {
      const boardId = options.queryKey[1];
      return boardId ? getBoardStatusesJDC(boardId) : Promise.reject('Cannot get JDC board statuses');
    },
    ...options,
  });

  return { data: query.data, query };
};

export {
  getIntegrationEntries,
  useBoardStatuses,
  useBoardStatusesJDC,
  useCompleteOauth,
  useIntegrationEntries,
  useIntegrationRefresh,
  useIntegrationUrl,
  useIntegrations,
  useIntegrationsJDC,
  useStartIntegrationProcess,
  useSystemAccessCreate,
};
