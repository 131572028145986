import { newCOLORS } from '../../../styles/colors';

const SPRINT_COMPARISON_COLORS = [
  newCOLORS.indigo,
  newCOLORS.pink,
  newCOLORS.green,
  newCOLORS.orange,
  newCOLORS.purple,
  newCOLORS.blue,
  newCOLORS.red,
  newCOLORS.teal,
  newCOLORS.coral,
];

interface SelectedWorkPeriod {
  id: string;
  name: string;
}

interface FormattedMeasureData {
  values: number[];
  average: number | null;
  trend: number | null;
  forecast: number | null;
}

export { SPRINT_COMPARISON_COLORS };
export type { FormattedMeasureData, SelectedWorkPeriod };
