import { TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';
import { TargetComparison } from './targets-client.type';

type TargetFormObject = {
  id: string;
  name: string;
  start_date?: string;
  end_date?: string;
};

type TargetForm = {
  id?: string;
  name: string;
  measure: string;
  target_value: number;
  target_value_upper: number | null;
  target_comparison: TargetComparison;
  is_active: boolean;
  use_transform: boolean;
  start_date: string | null;
  end_date: string | null;
  time_allocation_type: TimeAllocationType | null;
  is_work_period_target: boolean;
  object_type: 'portfolio' | 'project' | 'board' | 'initiative' | null;
  objects: TargetFormObject[];
};

const OBJECT_TYPES = [
  { value: 'board', label: 'Board' },
  { value: 'project', label: 'Project' },
  { value: 'portfolio', label: 'Portfolio' },
  { value: 'initiative', label: 'Initiative' },
];

export { OBJECT_TYPES };
export type { TargetForm, TargetFormObject };
