import { AppShell } from '@mantine/core';
import { useVeltClient, VeltCommentsSidebar } from '@veltdev/react';
import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloraAside } from '../flora/flora';
import { useFlora } from '../flora/flora.context';
import { HeaderBar } from '../header/header';
import { SideBar } from '../side-bar/side-bar';
import { SidebarContext } from '../side-bar/side-bar.context';
import { useCommentsSidebar } from './comments-sidebar.context';
type MenuDrivenContainerProps = {
  children: ReactNode;
  header?: string | ReactNode;
  actions?: ReactNode;
};

export const MenuDrivenContainer = ({ children, header, actions }: MenuDrivenContainerProps) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const { isFloraVisible, toggleFlora } = useFlora();
  const { isCommentsSidebarVisible, setIsCommentsSidebarVisible } = useCommentsSidebar();
  const navigate = useNavigate();
  const client = useVeltClient();

  useEffect(() => {
    if (client?.client && isCommentsSidebarVisible) {
      client.client.getCommentElement().openCommentSidebar();
    } else if (client?.client && !isCommentsSidebarVisible) {
      client.client.getCommentElement().closeCommentSidebar();
    }
  }, [client, isCommentsSidebarVisible]);

  const navbarWidth = sidebarOpen ? 280 : 70;
  const floraWidth = 325;
  const commentsSidebarWidth = 380;

  return (
    <AppShell
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      layout="alt"
      header={{ height: 68 }}
      navbar={{
        width: navbarWidth,
        breakpoint: 'never',
        collapsed: { mobile: false, desktop: false },
      }}
      aside={{
        width: isFloraVisible ? floraWidth + 10 : isCommentsSidebarVisible ? commentsSidebarWidth + 10 : 0,
        breakpoint: 'never',
      }}
      padding={16}
      withBorder={false}
      styles={{
        main: {
          flex: 1,
          overflowY: 'auto',
          minHeight: 0,
          transition: 'padding-right 0.3s ease',
          height: 'calc(100vh - 68px)',
        },
        aside: {
          transition: 'transform 0.3s ease',
          marginTop: 24,
          height: 'calc(100vh - 42px)',
          marginRight: '10px',
          background: 'transparent',
          border: 'none',
        },
      }}
    >
      <HeaderBar header={header} actions={actions} />
      <SideBar />
      {isFloraVisible && (
        <AppShell.Aside w={floraWidth} style={{ zIndex: 500 }}>
          <FloraAside onClose={toggleFlora} />
        </AppShell.Aside>
      )}
      {isCommentsSidebarVisible && (
        <AppShell.Aside w={commentsSidebarWidth} style={{ zIndex: 500 }}>
          <VeltCommentsSidebar
            urlNavigation={true}
            shadowDom={false}
            onSidebarClose={() => setIsCommentsSidebarVisible(false)}
            onCommentClick={(comment: any) => {
              navigate(comment.location.id);
            }}
          />
        </AppShell.Aside>
      )}
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};
