import { OrganizationStatusesByProjectsAndBoards } from '../../../../api/organization-client/organization-client.type';
import { Step, Workflow } from '../../../../api/workflows-client/workflows-client.type';

/**
 * Gets the statuses for the selected boards
 * @param {OrganizationStatusesByProjectsAndBoards} OrganizationStatusesByProjectsAndBoards The portfolio statuses by projects and boards
 * @param {string[]} selectedBoards The selected boards
 * @returns {string[]} The statuses for the selected boards
 */
function getStatusesForSelectedBoards(
  OrganizationStatusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards,
  selectedBoards: string[],
): string[] {
  const statuses = Object.entries(OrganizationStatusesByProjectsAndBoards)
    .map(([_, project]) => project.subprojects)
    .flatMap((subprojects) => Object.entries(subprojects))
    .filter(([subprojectId, _]) => selectedBoards.includes(subprojectId))
    .flatMap(([_, subproject]) => subproject.statuses);
  return Array.from(new Set(statuses));
}

/**
 * Gets the mapped and unmapped statuses for a subproject
 * @param {string[]} allStatusesForSubproject The list of all statuses for the subproject
 * @param {Record<string, string[]>} workflowMappedStatusBuckets The list of mapped statuses for the workflow
 * @returns {Object} The mapped and unmapped statuses
 */
function getMappedAndUnmappedStatusesForSubproject(
  allStatusesForSubproject: string[],
  workflowMappedStatusBuckets: { [key: string]: string[] },
): { mappedStatuses: string[]; unmappedStatuses: string[] } {
  const allStatusesSet = new Set(allStatusesForSubproject);
  const flattenedMappedStatuses = Object.values(workflowMappedStatusBuckets).flat();

  const mappedStatuses = flattenedMappedStatuses.filter((status) => allStatusesSet.has(status));
  const unmappedStatuses = allStatusesForSubproject.filter((status) => !flattenedMappedStatuses.includes(status));

  return { mappedStatuses, unmappedStatuses };
}

/**
 * Gets the new workflow name
 * @param {Workflow[]} workflows The list of workflows
 * @returns {string} The new workflow name
 */
function getNewWorkflowName(workflows: Workflow[]): string {
  const matches = workflows.filter((workflow) => workflow && workflow.name.match(/^New Workflow(?:\s*\((\d+)\))?$/));
  if (matches.length > 0) {
    //sort the matches and pull the number from the last match using regex
    const sortedMatches = matches.sort();
    const lastMatch = sortedMatches[sortedMatches.length - 1];
    const lastMatchNumber = parseInt(lastMatch.name.match(/(\d+)/)?.[0] || (sortedMatches.length - 1).toString());
    return `New Workflow (${lastMatchNumber + 1})`;
  } else {
    return 'New Workflow';
  }
}

/**
/**
 * Gets the mapped and unmapped statuses for a workflow.
 * @param workflow - The workflow to get the mapped and unmapped statuses for.
 * @param statusesByProjectsAndBoards - The portfolio statuses by projects and boards.
 * @returns An object with the mapped and unmapped statuses for the workflow.
 */
function getMappedAndUnmappedStatusesForWorkflow(
  workflow: Workflow,
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined,
): { mappedStatuses: string[]; unmappedStatuses: string[] } {
  if (!statusesByProjectsAndBoards) {
    return { mappedStatuses: [], unmappedStatuses: [] };
  }
  const workflowMappings = new Set(
    (workflow.steps || []).flatMap((step) => step.mappings.map((mapping) => mapping.external_name)),
  );

  const mappedStatusesSet = new Set<string>();
  const unmappedStatusesSet = new Set<string>();

  for (const [_projectId, project] of Object.entries(statusesByProjectsAndBoards)) {
    for (const [_subprojectId, subproject] of Object.entries(project.subprojects)) {
      for (const status of subproject.statuses) {
        if (workflowMappings.has(status)) {
          mappedStatusesSet.add(status);
        } else {
          unmappedStatusesSet.add(status);
        }
      }
    }
  }
  return {
    mappedStatuses: Array.from(mappedStatusesSet),
    unmappedStatuses: Array.from(unmappedStatusesSet),
  };
}

/**
 * Filters the portfolio statuses by projects and boards by a list of subproject ids
 * @param {OrganizationStatusesByProjectsAndBoards} OrganizationStatusesByProjectsAndBoards The portfolio statuses by projects and boards
 * @param {string[]} subprojectIds The subproject ids to filter by
 * @returns {OrganizationStatusesByProjectsAndBoards} The portfolio statuses by projects and boards filtered by subproject ids
 */
function filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds(
  OrganizationStatusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined,
  subprojectIds: string[],
): OrganizationStatusesByProjectsAndBoards | undefined {
  if (!OrganizationStatusesByProjectsAndBoards) {
    return undefined;
  }
  const result: OrganizationStatusesByProjectsAndBoards = {};
  for (const [projectId, project] of Object.entries(OrganizationStatusesByProjectsAndBoards)) {
    const filteredSubprojectData = Object.fromEntries(
      Object.entries(project.subprojects).filter(([subprojectId]) => (subprojectIds || []).includes(subprojectId)),
    );
    if (Object.keys(filteredSubprojectData).length > 0) {
      result[projectId] = {
        ...project,
        subprojects: filteredSubprojectData,
      };
    }
  }
  return result;
}
/**
 * Filters the workflow steps to only include the mapped statuses that are in the statusOptions array
 * @param {Workflow} workflow The workflow to filter
 * @param {string[]} statusOptions The statuses to retain in the workflow steps
 * @returns {Step[]} The workflow steps with any extra mapped statuses removed
 */
function maybeReplaceMappedStatusesFromWorkflowSteps(workflow: Workflow, statusOptionsToRetain: string[]): Step[] {
  const { steps = [] } = workflow;
  const newSteps: Step[] = [];
  steps.forEach((step) => {
    const newMappings = step.mappings.filter(
      (mapping) => mapping.external_name && statusOptionsToRetain.includes(mapping.external_name),
    );
    newSteps.push({ ...step, mappings: newMappings });
  });
  return newSteps;
}

/**
 * Filters the workflow steps to only include the mapped statuses that are not in the statusOptions array
 * @param {Workflow} workflow The workflow to filter
 * @param {string[]} statusOptionsToRemove The statuses to remove from the workflow steps
 * @returns {Step[]} The workflow steps with any extra mapped statuses removed
 */
function maybeRemoveMappedStatusesFromWorkflowSteps(workflow: Workflow, statusOptionsToRemove: string[]): Step[] {
  const { steps = [] } = workflow;
  const newSteps: Step[] = [];
  steps.forEach((step) => {
    const newMappings = step.mappings.filter((mapping) => !statusOptionsToRemove.includes(mapping.external_name));
    newSteps.push({ ...step, mappings: newMappings });
  });
  return newSteps;
}

/**
 * Adds a step to an array of steps and sorts them according to a specified order
 *
 * @param {Step[]} steps - The existing array of workflow steps
 * @param {Step} step - The new step to add
 * @param {string[]} stepOrder - Array defining the desired order of step names
 * @returns {Step[]} New array of steps with the added step, sorted by the stepOrder
 */

function addStepToSteps(steps: Step[], step: Step, stepOrder: string[]): Step[] {
  const newSteps = [...steps.filter((s) => s.name !== step.name), step];

  return newSteps.sort((a, b) => stepOrder.indexOf(a.name) - stepOrder.indexOf(b.name));
}

export {
  addStepToSteps,
  filterOrganizationStatusesByProjectsAndBoardsBySubprojectIds,
  getMappedAndUnmappedStatusesForSubproject,
  getMappedAndUnmappedStatusesForWorkflow,
  getNewWorkflowName,
  getStatusesForSelectedBoards,
  maybeRemoveMappedStatusesFromWorkflowSteps,
  maybeReplaceMappedStatusesFromWorkflowSteps,
};
