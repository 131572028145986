import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Switch } from '@mantine/core';
import { useCallback, useContext } from 'react';
import { ProcessMapping } from '../../../api/process-client/process-client.type';
import { baseWhite, secondaryBase } from '../../../styles/design-tokens';
import { ProcessContext } from '../context/process.context';
import { getStages, isComplexView } from '../process.helpers';

type Props = {
  mapping: ProcessMapping;
};

export function ViewToggle({ mapping }: Props) {
  const { stages, setStages } = useContext(ProcessContext);

  const toggle = useCallback(() => {
    if (isComplexView(stages, mapping)) {
      setStages([]);
    } else {
      setStages(getStages(mapping));
    }
  }, [stages, setStages, mapping]);

  return (
    <StyledSwitch
      checked={isComplexView(stages, mapping)}
      onChange={toggle}
      thumbIcon={
        isComplexView(stages, mapping) ? (
          <Icon icon="ic:baseline-check" color={secondaryBase} width={16} height={16} />
        ) : null
      }
    />
  );
}

export const StyledSwitch = styled(Switch)`
  .mantine-Switch-track {
    height: 25px;
    width: 45px;
    padding: 2px 8px;
    border-radius: 100px;
    border: 2px solid rgba(29, 27, 32, 0.12);
    background: rgba(231, 224, 236, 0.12);
    cursor: pointer;
    box-sizing: border-box;
  }

  .mantine-Switch-thumb {
    height: 14px;
    width: 14px;
    border-radius: 24px;
    margin-left: 2px;
    opacity: 0.38;
    background: #1d1b20;
  }

  input {
    &:checked + .mantine-Switch-track {
      border: ${secondaryBase};
      background: ${secondaryBase};

      .mantine-Switch-thumb {
        height: 16px;
        width: 16px;
        background: ${baseWhite};
        z-index: 1;
        left: 22px;
        opacity: 1;
      }
    }
  }
`;
