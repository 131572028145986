import { styled } from '@linaria/react';
import { Table as MantineTable, Pagination } from '@mantine/core';
import { useState } from 'react';
import {
  blueLightest,
  inkLight,
  primaryBase,
  secondaryBase,
  skyDark,
  skyLightest,
} from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';

export type TableColumn<T extends object> = {
  key: keyof T;
  label: string;
  render?: (value: T[keyof T], row: T) => React.ReactNode;
  sortable?: boolean;
  width?: string;
};

export type TableProps<T extends object> = {
  data: T[];
  columns: TableColumn<T>[];
  onRowClick?: (row: T) => void;
  selectedRowId?: string | number;
  getRowId: (row: T) => string | number;
  stickyHeader?: boolean;
  isChildRow?: (row: T) => boolean;
  getParentId?: (row: T) => string | number;
  highlightParentRows?: boolean;
  pageSize?: number;
  currentPage?: number;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  customSort?: (data: T[], sortBy: keyof T, direction: 'asc' | 'desc') => T[];
};

function SortableTableHeader({
  children,
  reversed,
  sorted,
  onSort,
  sortable = true,
  style,
}: {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  sortable?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <MantineTable.Th style={style}>
      <HeaderContent onClick={sortable ? onSort : undefined} sortable={sortable}>
        <Text weight="bold" size="regular">
          {children}
        </Text>
        {sortable && (
          <Icon
            name={sorted ? (reversed ? 'north' : 'south') : 'north'}
            color={sorted ? primaryBase : skyDark}
            size={16}
            style={{
              cursor: 'pointer',
              opacity: sorted ? 1 : 0.5,
            }}
          />
        )}
      </HeaderContent>
    </MantineTable.Th>
  );
}

export function Table<T extends object>({
  data,
  columns,
  onRowClick,
  selectedRowId,
  getRowId,
  stickyHeader = false,
  isChildRow = () => false,
  getParentId = () => '',
  highlightParentRows = false,
  pageSize,
  currentPage = 1,
  totalItems,
  onPageChange,
  customSort,
}: TableProps<T>) {
  const [sortedBy, setSortedBy] = useState<keyof T | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof T) => {
    const reversed = field === sortedBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortedBy(field);
  };

  const sortData = (data: T[]): T[] => {
    if (!sortedBy) {
      return data;
    }

    if (customSort) {
      return customSort(data, sortedBy, reverseSortDirection ? 'desc' : 'asc');
    }

    // Group parent rows with their children
    const parentRows = data.filter((row) => !isChildRow(row));
    const childrenByParent = data.reduce(
      (acc, row) => {
        if (isChildRow(row)) {
          const parentId = getParentId(row);
          if (!acc[parentId]) {
            acc[parentId] = [];
          }
          acc[parentId].push(row);
        }
        return acc;
      },
      {} as Record<string | number, T[]>,
    );

    // Sort parent rows
    const sortedParents = [...parentRows].sort((a, b) => {
      const aValue = a[sortedBy];
      const bValue = b[sortedBy];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return reverseSortDirection ? -comparison : comparison;
      }

      const comparison = aValue < bValue ? -1 : 1;
      return reverseSortDirection ? -comparison : comparison;
    });

    // Reconstruct the array with children following their parents
    return sortedParents.flatMap((parent) => {
      const children = childrenByParent[getRowId(parent)] || [];
      return [parent, ...children];
    });
  };

  const sortedData = sortData(data);

  // Apply pagination to the sorted data if pagination props are provided
  const paginatedData = pageSize ? sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize) : sortedData;

  return (
    <StyledTable stickyHeader={stickyHeader}>
      <MantineTable>
        <MantineTable.Thead
          style={{ position: stickyHeader ? 'sticky' : 'static', top: 0, backgroundColor: 'white', zIndex: 1 }}
        >
          <MantineTable.Tr>
            {columns.map((column) => (
              <SortableTableHeader
                key={column.key.toString()}
                sorted={sortedBy === column.key}
                reversed={reverseSortDirection}
                onSort={() => setSorting(column.key)}
                sortable={column.sortable !== false}
                style={{ width: column.width }}
              >
                {column.label}
              </SortableTableHeader>
            ))}
          </MantineTable.Tr>
        </MantineTable.Thead>
        <MantineTable.Tbody>
          {paginatedData.map((row) => (
            <TableRow
              key={getRowId(row)}
              onClick={() => onRowClick?.(row)}
              data-selected={selectedRowId === getRowId(row)}
              data-child={isChildRow(row)}
              data-is-project={highlightParentRows && !isChildRow(row)}
              style={{ cursor: onRowClick ? 'pointer' : 'default' }}
            >
              {columns.map((column) => (
                <MantineTable.Td key={column.key.toString()} style={{ width: column.width }}>
                  {column.render ? column.render(row[column.key], row) : <Text>{row[column.key]?.toString()}</Text>}
                </MantineTable.Td>
              ))}
            </TableRow>
          ))}
        </MantineTable.Tbody>
      </MantineTable>
      {pageSize && totalItems && onPageChange && totalItems > pageSize && (
        <PaginationContainer>
          <Pagination total={Math.ceil(totalItems / pageSize)} value={currentPage} onChange={onPageChange} size="sm" />
        </PaginationContainer>
      )}
    </StyledTable>
  );
}

const HeaderContent = styled.div<{ sortable?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 8px 4px;
  margin: 0;
  text-align: left;
  border-radius: 8px;
  cursor: ${(props) => (props.sortable ? 'pointer' : 'default')};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.sortable ? skyLightest : 'transparent')};
  }
`;

const StyledTable = styled.div<{ stickyHeader?: boolean }>`
  .mantine-Table-root {
    width: 100%;
  }

  .mantine-Table-root thead tr th {
    transition: background-color 0.2s ease;
    padding: 12px 0px;
    border-bottom: none;
    text-align: left;
  }

  .mantine-Table-root tbody tr[data-child='true'] td {
    border-bottom: none;
  }

  .mantine-Table-root tbody tr[data-is-project='true'] td {
    background-color: ${skyLightest};
    cursor: default;
  }
`;

const TableRow = styled(MantineTable.Tr)`
  height: 48px;
  transition: background-color 0.2s ease;

  td {
    padding: 8px 16px;
    transition: background-color 0.2s ease;
    background-color: transparent;
  }

  &:hover td {
    background-color: ${skyLightest};
  }

  &:hover td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover td:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[data-selected='true'] td {
    background-color: ${blueLightest};
  }

  &[data-selected='true'] td:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &[data-selected='true'] td:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[data-selected='true']:hover td {
    background-color: ${blueLightest};
  }

  &[data-is-project='true'] td {
    background-color: ${skyLightest};
  }

  &[data-is-project='true']:hover td {
    background-color: ${skyLightest};
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  border-top: 1px solid ${skyLightest};

  .mantine-Pagination-root {
    .mantine-Pagination-control {
      border-radius: 8px;
      padding: 4px 8px;
            
      &[data-active] {
        background-color: ${secondaryBase};
      }

      &:not([data-active]) {
        // border-color: ${skyDark};
        color: ${inkLight};
      }

      &:not([data-active]):hover {
        background-color: ${skyLightest};
      }
  } 
`;
