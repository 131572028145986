import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MinifiedTask } from '../../../api/tasks-client/task-client.type';
import { getWorkPeriodsTasks } from '../../../api/work-periods-client/work-periods-client';
import { Measure } from '../../../api/work-periods-client/work-periods-client.type';

/**
 * Type for task query response
 */
type TaskQueryResponse = {
  data: MinifiedTask[];
  query: UseQueryResult<MinifiedTask[], Error>;
};

/**
 * Single unified hook for fetching tasks that can handle both work period and date-based queries
 */
export const useTasksData = (
  measureName: string | null,
  boardId: string | null,
  startDate: string | null,
  endDate: string | null,
  options: {
    workPeriodId?: string | null;
    measureFilter?: string | null;
  },
  forceEnabled = true,
): TaskQueryResponse => {
  const { workPeriodId, measureFilter } = options;

  // Determine query type and create appropriate query key
  const isWorkPeriodQuery = !!workPeriodId;
  const queryStartDate = startDate || '';
  const queryEndDate = endDate || '';

  // Create query key based on query type
  const queryKey = isWorkPeriodQuery
    ? ['tasks', measureName, 'workPeriod', workPeriodId, boardId, queryStartDate, queryEndDate, measureFilter]
    : ['tasks', measureName, 'date', queryStartDate, queryEndDate, boardId, measureFilter];

  // Determine if query should be enabled
  const baseEnabled =
    !!measureName &&
    !!boardId &&
    ((isWorkPeriodQuery && !!workPeriodId) || (!isWorkPeriodQuery && !!startDate && !!endDate));

  // Only enable the query if all parameters are valid AND forceEnabled is true
  const enabled = baseEnabled && forceEnabled;

  // Execute the query
  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      try {
        // Fetch data based on query type
        const tasks = await getWorkPeriodsTasks({
          ...(isWorkPeriodQuery ? { work_period_id: workPeriodId } : {}),
          subproject_id: boardId || '',
          measure_name: [(measureName || '') as Measure],
          start_date: queryStartDate,
          end_date: queryEndDate,
          measure_filter: measureFilter ? `${measureFilter},${queryEndDate}` : '',
        });

        return Array.isArray(tasks) ? tasks : [];
      } catch (err) {
        console.error('Error fetching tasks:', err);
        return [];
      }
    },
    enabled,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data: queryResult.data || [],
    query: queryResult,
  };
};
