import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { FilterPanel } from '../../../components/filters/filter-panel';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { TargetSideSection } from '../../../components/side-section/target-side-section';
import { DeleteConfirmationModal } from '../../data-management/components/delete-confirmation-modal';
import { getFilterGroups, useFilteredData } from './filters/targets-filter-groups.helpers';
import { useTargetsFilters } from './filters/targets-filters.hooks';
import { TargetModal } from './target-modal';
import { useCreateTarget, useDeleteTarget, useTargetsData, useUpdateTarget } from './targets-client.hooks';
import { Target, TargetPayload } from './targets-client.type';
import { TargetsTable } from './targets-table';

export function Targets() {
  const [selectedTarget, setSelectedTarget] = useState<Target | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data, query } = useTargetsData();
  const { createTarget, isCreating } = useCreateTarget();
  const { updateTarget, isUpdating } = useUpdateTarget();
  const { deleteTarget, isDeleting } = useDeleteTarget();
  const filters = useTargetsFilters();
  const { filteredTargets } = useFilteredData(data, filters);

  const queryClient = useQueryClient();

  const handleCreateTarget = async (target: Partial<TargetPayload>) => {
    try {
      await createTarget(target);
      setIsModalOpen(false);
      filters.handleResetFilters();
    } catch (error) {
      console.error('Failed to create target:', error);
    }
  };

  const handleUpdateTarget = async (target: Partial<TargetPayload>) => {
    try {
      await updateTarget(target);
      setIsModalOpen(false);
      setSelectedTarget(null);
      filters.handleResetFilters();
    } catch (error) {
      console.error('Failed to update target:', error);
    }
  };

  const handleTargetUpdate = (updatedTarget: Target) => {
    setSelectedTarget(updatedTarget);
    // Also update the target in the table data if it exists
    if (data) {
      const updatedData = data.map((t) => (t.id === updatedTarget.id ? updatedTarget : t));
      queryClient.setQueryData(['targets'], updatedData);
    }
  };

  const handleDeleteTarget = async () => {
    if (!selectedTarget) return;

    try {
      await deleteTarget(selectedTarget.id);
      setIsDeleteModalOpen(false);
      setSelectedTarget(null);
    } catch (error) {
      console.error('Failed to delete target:', error);
    }
  };

  const handleEditTarget = () => {
    if (!selectedTarget) return;
    setSelectedTarget(selectedTarget);
    setIsModalOpen(true);
  };

  const onCreateTarget = () => {
    setSelectedTarget(null);
    setIsModalOpen(true);
  };

  const onDeleteTarget = () => {
    setIsDeleteModalOpen(true);
  };

  return (
    <Fragment>
      <BrandedLoadingOverlay visible={query.isFetching} variant="colored" />
      <PageLayout
        controlBar={null}
        sideFilters={{
          content: <FilterPanel filterGroups={getFilterGroups(filters)} onResetFilters={filters.handleResetFilters} />,
        }}
        mainSection={
          filteredTargets && (
            <TargetsTable targets={filteredTargets} onTargetClick={setSelectedTarget} onCreateTarget={onCreateTarget} />
          )
        }
        showSideSection={!!selectedTarget}
        sideSection={
          selectedTarget && (
            <TargetSideSection
              target={selectedTarget}
              onClose={() => setSelectedTarget(null)}
              onEdit={handleEditTarget}
              onDelete={onDeleteTarget}
              onUpdate={handleTargetUpdate}
            />
          )
        }
      />
      <TargetModal
        opened={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedTarget(null);
        }}
        onSave={selectedTarget ? handleUpdateTarget : handleCreateTarget}
        initialData={selectedTarget || undefined}
        isCreating={!selectedTarget}
        isLoading={isCreating || isUpdating}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedTarget(null);
        }}
        onDelete={handleDeleteTarget}
        title="Delete Target"
        itemName={selectedTarget?.name || 'this target'}
        isDeleting={isDeleting}
      />
    </Fragment>
  );
}
