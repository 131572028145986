import dayjs from 'dayjs';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { sanitizeString } from '../../../helpers/string-helpers/string-helpers';
import { SortedColumn } from './initiatives-table.type';

/**
 * Sorts the list of initiatives based on the specified column and order.
 *
 * @param {Initiative[]} initiatives - The list of initiatives to be sorted
 * @param {Object} options - The sorting options including the column to sort by and the sort order
 * @param {SortedColumn} options.sortBy - The column to sort by
 * @param {boolean} options.sortDesc - The sort order, true for descending and false for ascending
 * @returns {Initiative[]} - The sorted list of initiatives
 */
const sortInitiatives = (
  initiatives: Initiative[],
  { sortBy, sortDesc }: { sortBy: SortedColumn; sortDesc: boolean },
): Initiative[] => {
  return (initiatives || []).toSorted((a, b) => {
    if (sortBy === 'name') {
      const aValue = sanitizeString(a.name);
      const bValue = sanitizeString(b.name);

      return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (sortBy === 'status') {
      const statusOrder = {
        on_track: 1,
        at_risk: 2,
        off_track: 3,
        unknown: 4,
      };

      const aValueOrder = statusOrder[a.status?.by_tasks.status || 'off_track'];
      const bValueOrder = statusOrder[b.status?.by_tasks.status || 'off_track'];

      return sortDesc ? bValueOrder - aValueOrder : aValueOrder - bValueOrder;
    }

    if (sortBy === 'completion') {
      const aValue = a.status?.by_tasks.percent_subtasks_complete || 0;
      const bValue = b.status?.by_tasks.percent_subtasks_complete || 0;
      return sortDesc ? bValue - aValue : aValue - bValue;
    }

    if (sortBy === 'relative_business_value') {
      const aValue = a[sortBy] || 0;
      const bValue = b[sortBy] || 0;
      return sortDesc ? bValue - aValue : aValue - bValue;
    }

    if (sortBy === 'start_date') {
      const aValue = dayjs(a[sortBy] || new Date(0));
      const bValue = dayjs(b[sortBy] || new Date(0));
      return sortDesc ? bValue.diff(aValue) : aValue.diff(bValue);
    }

    if (sortBy === 'end_date') {
      const aValue = dayjs(a[sortBy]);
      const bValue = dayjs(b[sortBy]);
      return sortDesc ? bValue.diff(aValue) : aValue.diff(bValue);
    }

    return 0;
  });
};

export { sortInitiatives };
