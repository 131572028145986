/**
 * Returns the appropriate icon name for a given widget type and subtype
 * @param type - The main widget type ('chart', 'table', 'tile', 'metric')
 * @param subType - Optional subtype for chart widgets ('line', 'area', 'bar', 'stacked-bar', 'pie')
 * @returns The icon name string to be used with the Icon component
 */

import { ComponentData } from '../../api/pages-client/pages-client.type';

const getWidgetIcon = (type: string, subType?: string): string => {
  switch (type) {
    case 'chart':
      switch (subType) {
        case 'line':
          return 'show_chart';
        case 'area':
          return 'area_chart';
        case 'bar':
          return 'bar_chart';
        case 'stacked-bar':
          return 'stacked_bar_chart';
        case 'pie':
          return 'pie_chart';
        default:
          return 'analytics';
      }
    case 'table':
      return 'table_chart';
    case 'tile':
      return 'numbers';
    case 'metric':
      return 'speed';
    default:
      return 'dashboard';
  }
};

/**
 * Calculates the date range for a dashboard component based on its range type
 * @param {ComponentData} componentData - The component data containing the range_type
 * @returns {{ startDate: string, endDate: string }} Object containing ISO date strings for start and end dates
 *
 * @example
 * const component = { range_type: 'last_30_days' };
 * const { startDate, endDate } = getComponentDateRange(component);
 * // Returns dates 30 days apart with endDate as today
 */

const getComponentDateRange = (componentData: ComponentData): { startDate: string; endDate: string } => {
  const endDate = new Date().toISOString().split('T')[0];
  let days = 30;
  switch (componentData.range_type) {
    case 'last_7_days':
      days = 7;
      break;
    case 'last_14_days':
      days = 14;
      break;
    case 'last_30_days':
      days = 30;
      break;
    case 'last_60_days':
      days = 60;
      break;
    case 'last_90_days':
      days = 90;
      break;
  }
  const startDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
  return { startDate, endDate };
};

export { getComponentDateRange, getWidgetIcon };
