import { styled } from '@linaria/react';

const CommonContainer = styled.div`
  box-sizing: border-box;
  background: var(--base-white);
  height: 100%;
  min-height: 0;
  border-radius: 8px;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export { CommonContainer };
