import { styled } from '@linaria/react';
import { Collapse, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';
import { inkLight, skyDark } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  title?: string;
  content: ReactNode;
  collapsible?: boolean;
  isEmpty?: boolean;
  emptyLabel?: string;
};

export const OverviewSection = ({ title, content, collapsible = true, isEmpty = false, emptyLabel }: Props) => {
  const [isOpen, { toggle }] = useDisclosure(true);

  const titleComponent = title ? (
    <Text size="medium" weight="bold">
      {title}
    </Text>
  ) : null;

  if (isEmpty) {
    return (
      <Container>
        <Flex style={{ position: 'relative' }}>
          {titleComponent}
          <EmptyMessageWrapper>
            <Text size="small" color={skyDark}>
              {emptyLabel}
            </Text>
          </EmptyMessageWrapper>
        </Flex>
      </Container>
    );
  }

  return (
    <Container>
      <Flex justify="space-between" align="center">
        {titleComponent}
        {collapsible && (
          <CollapseButton
            onClick={toggle}
            data-testid="measure-info-box-toggle"
            role="button"
            aria-label={`${isOpen ? 'Collapse' : 'Expand'} measure info box`}
            aria-expanded={isOpen}
          >
            <Icon
              name={isOpen ? 'unfold_less' : 'unfold_more'}
              color={inkLight}
              size={14}
              style={{ cursor: 'pointer' }}
            />
          </CollapseButton>
        )}
      </Flex>
      <Collapse in={isOpen}>{content}</Collapse>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: 16px 24px;
  background: var(--base-white);
  border-radius: 8px;
  min-width: fit-content;
`;

const CollapseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 30px;
  border-radius: 32px;
  cursor: pointer;
  border: 1px solid var(--sky-base);

  &:hover {
    border: 1px solid var(--secondary-base);
  }
`;

const EmptyMessageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
