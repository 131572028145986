import { Divider, PasswordInput, Stack, TextInput } from '@mantine/core';
import i18n from '../../../../base-dictionary';
import { LoginForm as LoginFormType } from './login.type';

type Props = {
  form: LoginFormType;
  showPassword: boolean;
};

export const LoginForm = ({ form, showPassword }: Props) => (
  <Stack gap={24} role="form" aria-label={i18n.t('common.form.login_form')}>
    <Divider />
    <TextInput
      label={i18n.t('common.form.email')}
      placeholder={i18n.t('common.placeholders.email')}
      value={form.values.email}
      onChange={(event) => form.setFieldValue('email', event.currentTarget.value.trim())}
      error={form.errors.email}
      w="100%"
      autoComplete="email"
      required
    />
    {showPassword && (
      <PasswordInput
        autoFocus
        label={i18n.t('common.form.password')}
        placeholder={i18n.t('common.placeholders.password')}
        value={form.values.password}
        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
        error={form.errors.password}
        w="100%"
        autoComplete="current-password"
        required
      />
    )}
  </Stack>
);
