import { ComboboxItem } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Measure,
  MeasureMetadata,
  TimeAllocation,
  TimeAllocationType,
} from '../../api/work-periods-client/work-periods-client.type';
import { Tab, ViewType, WorkPeriodType } from '../../containers/process-analysis/process-analysis.type';
import { useProcessAnalysisStore } from './process-analysis-store';
import {
  applyBoardsFilter,
  applyDateFilter,
  applyPortfoliosFilter,
  applyTeamsFilter,
  setActiveTab,
  setShowSprintComparison,
} from './process-analysis-store.actions';
import { MeasureUnitMap } from './process-analysis-store.type';

/**
 * Shared filter to exclude unwanted measures.
 *
 * @param {MeasureMetadata} measure - The measure object to filter.
 * @returns {boolean} - Returns true if the measure is not unwanted.
 */
const filterUnwantedMeasures = (measure: MeasureMetadata): boolean => {
  const unwantedMeasures: Array<Measure | string> = [
    'workload_distribution_capacity',
    Measure.ScopeCreep,
    Measure.FlowByPace,
    Measure.FlowByPhase,
  ];
  return !unwantedMeasures.includes(measure.measure_name);
};

/**
 * Returns true if the measure is custom. Returns false if the measure is not custom.
 *
 * @param {MeasureMetadata} measure - The measure object to filter.
 * @returns {boolean} - Returns true if the measure is custom.
 */
const filterCustomMeasures = (measure: MeasureMetadata): boolean => {
  return measure.is_custom ?? false;
};

/**
 * Returns the view type based on the active tab and other state variables.
 *
 * @return {ViewType} The view type, which can be one of the following:
 *                    - ViewType.Comparison: when there are multiple portfolios, teams, or boards.
 *                    - ViewType.Single: when there is only one portfolio, team, or board.
 *                    - ViewType.Assessment: a special view for the work periods tab.
 */
const useViewType = (): ViewType => {
  const { activeTab, portfolioIds, teamIds, boardIds } = useProcessAnalysisStore();

  switch (activeTab) {
    case Tab.Portfolios:
      return portfolioIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.Teams:
      return teamIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.Boards:
      return boardIds.length > 1 ? ViewType.Comparison : ViewType.Single;
    case Tab.WorkPeriods:
      return ViewType.Assessment;
    default:
      return ViewType.Comparison;
  }
};

/**
 * Returns a list of entities based on the active tab in the process analysis store.
 *
 * @return {string[]} A list of entity IDs (e.g., portfolio IDs, team IDs, or board IDs)
 */
const useEntities = (): string[] => {
  const { activeTab, portfolioIds, teamIds, boardIds } = useProcessAnalysisStore();

  switch (activeTab) {
    case Tab.Portfolios:
      return portfolioIds || [];
    case Tab.Teams:
      return teamIds || [];
    case Tab.Boards:
      return boardIds || [];
    default:
      return [];
  }
};

/**
 * Returns an object containing the start and end dates converted to Date objects.
 *
 * @return {{ startDate: Date; endDate: Date }} An object with the start and end dates.
 */
const useDateRange = (): { startDate: Date; endDate: Date } => {
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate).toDate());
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate).toDate());

  return { startDate, endDate };
};

/**
 * Returns the time allocations for the current work period.
 *
 * @return {TimeAllocation[]} The time allocations for the current work period.
 */
const useTimeAllocations = (): TimeAllocation[] => {
  const timeAllocations = useProcessAnalysisStore((state) => state.timeAllocations);

  return timeAllocations;
};

/**
 * Calculates the time allocation based on the difference between the start and end dates.
 *
 * @return {TimeAllocationType} The time allocation, which can be Daily, Weekly, BiWeekly, or Monthly.
 */
const useTimeAllocation = (): TimeAllocationType => {
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate));
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate));

  const diff = endDate.diff(startDate, 'day') + 1;

  switch (true) {
    case diff < 25:
      return TimeAllocationType.Daily;
    case diff < 51:
      return TimeAllocationType.Weekly;
    case diff < 101:
      return TimeAllocationType.BiWeekly;
    default:
      return TimeAllocationType.Monthly;
  }
};

/**
 * Returns the start and end dates of the work period.
 * If the work period is of type "Defined", it returns the start and end dates of the defined work period.
 * If the work period is of type "Custom", it returns the start and end dates of the custom date range.
 *
 * @returns {{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }} An object with the start and end dates of the work period.
 */
const useWorkPeriodDates = (): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const startDate = useProcessAnalysisStore((state) => dayjs(state.startDate));
  const endDate = useProcessAnalysisStore((state) => dayjs(state.endDate));

  if (workPeriodType === WorkPeriodType.Defined && workPeriod) {
    return { startDate: dayjs(workPeriod.start_date), endDate: dayjs(workPeriod.end_date) };
  }

  return { startDate, endDate };
};

/**
 * Returns the length of the work period in days.
 * If the work period is of type "Custom", it calculates the length by subtracting the start date from the end date.
 * If the work period is of type "Defined", it calculates the length by subtracting the start date of the defined work period from its end date.
 *
 * @returns {number} The length of the work period in days.
 */
const useWorkPeriodLength = (): number => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const { startDate, endDate } = useDateRange();

  if (workPeriodType === WorkPeriodType.Custom) {
    return dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
  }

  if (!workPeriod) {
    return 0;
  }

  return dayjs(workPeriod.end_date).startOf('day').diff(dayjs(workPeriod.start_date).startOf('day'), 'day') + 1;
};

/**
 * Returns an array of combobox items representing each day of the selected work period.
 * Each item has a value, label, disabled state, and a date associated with it.
 * The value is the day number, the label is the day number and date in the format 'DD/MM',
 * the disabled state is true if the date is after the current date, and the date is the
 * date of the day in the selected work period.
 *
 * @returns {ComboboxItem[]} An array of combobox items representing each day of the selected work period.
 */
const useWorkPeriodDayOptions = (): ComboboxItem[] => {
  const workPeriodLength = useWorkPeriodLength();
  const { startDate } = useWorkPeriodDates();
  const now = dayjs();

  return Array.from({ length: workPeriodLength }).map((_, index) => {
    const date = startDate.add(index, 'day');
    const disabled = date.isAfter(now);

    return { value: `${index + 1}`, label: `${index + 1} (${date.format('MM/DD')})`, disabled, date };
  });
};

/**
 * Returns the last valid day of the selected work period. If there are no valid days, it returns null.
 *
 * @returns {string | null} The last valid day of the selected work period, or null if there are no valid days.
 */
const useDefaultDay = (): string | null => {
  const dayOptions = useWorkPeriodDayOptions();

  const validDayOptions = dayOptions.filter((option) => !option.disabled);

  if (validDayOptions.length === 0) {
    return null;
  }

  return validDayOptions[validDayOptions.length - 1].value;
};

/**
 * Returns a boolean indicating whether the selected work period is of type "Defined".
 * This is used to determine whether to use historical_burns measure.
 *
 * @returns {boolean} True if the selected work period is of type "Defined", false otherwise.
 */
const useHistoricalBurns = (): boolean => {
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);

  return workPeriodType === WorkPeriodType.Defined;
};

/**
 * Returns an array of measure names based on the availableMeasures array in the store.
 *
 * @returns {Measure[]} An array of measure names.
 */
const useAvailableMeasureNames = (): Measure[] => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures
    .filter(filterUnwantedMeasures)
    .filter((measure) => !filterCustomMeasures(measure))
    .map(({ measure_name }) => measure_name as Measure);
};

/**
 * Returns an array of combobox items representing the available measures in the store.
 * Each item has a value and label, where the value is the measure name and the label is the measure title.
 *
 * @returns {ComboboxItem[]} An array of combobox items representing the available measures.
 */
const useAvailableMeasureOptions = (): ComboboxItem[] => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures
    .filter(filterUnwantedMeasures)
    .filter((measure) => (measure.measure_name as string) !== 'workload_distribution_capacity')
    .map(({ measure_name, measure_title }) => ({ value: measure_name, label: measure_title }));
};

/**
 * Returns an object with the measure names as keys and the measure units as values.
 *
 * @returns {{ [key: string]: string }} An object with the measure names as keys and the measure units as values.
 */
const useAvailableMeasureUnits = (): MeasureUnitMap => {
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures);

  return availableMeasures.filter(filterUnwantedMeasures).reduce<MeasureUnitMap>((acc, curr) => {
    acc[curr.measure_name] = curr.measure_units;
    return acc;
  }, {} as MeasureUnitMap);
};

/**
 * Hook to synchronize process analysis store with URL query parameters
 * Handles bidirectional sync for: startDate, endDate, portfolioIds, boardIds, teamIds, activeTab, showSprintComparison
 *
 * @returns {void}
 */
const useProcessAnalysisURLSync = (): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Track if we're currently processing a URL or store update
  const isProcessingRef = useRef(false);
  // Track previous values to detect actual changes
  const prevValuesRef = useRef({
    searchParams: searchParams.toString(),
    storeValues: {
      startDate: '',
      endDate: '',
      portfolioIds: [] as string[],
      boardIds: [] as string[],
      teamIds: [] as string[],
      activeTab: '',
      showSprintComparison: false,
    },
  });

  const { startDate, endDate, portfolioIds, boardIds, teamIds, activeTab, showSprintComparison } =
    useProcessAnalysisStore();

  // Load URL parameters into the store on mount or when URL changes
  useEffect(() => {
    // Skip if we're already processing a store update or if URL hasn't actually changed
    if (isProcessingRef.current || searchParams.toString() === prevValuesRef.current.searchParams) {
      return;
    }

    isProcessingRef.current = true;
    prevValuesRef.current.searchParams = searchParams.toString();

    try {
      const urlStartDate = searchParams.get('startDate');
      const urlEndDate = searchParams.get('endDate');
      const urlPortfolioIds = searchParams.get('portfolioIds');
      const urlBoardIds = searchParams.get('boardIds');
      const urlTeamIds = searchParams.get('teamIds');
      const urlActiveTab = searchParams.get('activeTab');
      const urlShowSprintComparison = searchParams.get('showSprintComparison');

      // Only update if values exist in URL and differ from store
      if (urlStartDate && urlStartDate !== startDate) {
        applyDateFilter({ startDate: urlStartDate, endDate });
      }

      if (urlEndDate && urlEndDate !== endDate) {
        applyDateFilter({ startDate, endDate: urlEndDate });
      }

      if (urlPortfolioIds) {
        const parsedIds = urlPortfolioIds.split(',');
        if (!arrayEquals(parsedIds, portfolioIds)) {
          applyPortfoliosFilter({ ids: parsedIds });
        }
      }

      if (urlBoardIds) {
        const parsedIds = urlBoardIds.split(',');
        if (!arrayEquals(parsedIds, boardIds)) {
          applyBoardsFilter({ ids: parsedIds });
        }
      }

      if (urlTeamIds) {
        const parsedIds = urlTeamIds.split(',');
        if (!arrayEquals(parsedIds, teamIds)) {
          applyTeamsFilter({ ids: parsedIds });
        }
      }

      if (urlActiveTab && Object.values(Tab).includes(urlActiveTab as Tab) && urlActiveTab !== activeTab) {
        setActiveTab(urlActiveTab as Tab);
      }

      if (urlShowSprintComparison !== null) {
        const shouldShowSprintComparison = urlShowSprintComparison === 'true';
        if (shouldShowSprintComparison !== showSprintComparison) {
          setShowSprintComparison(shouldShowSprintComparison);
        }
      }

      // Save the current store values
      prevValuesRef.current.storeValues = {
        startDate,
        endDate,
        portfolioIds: [...portfolioIds],
        boardIds: [...boardIds],
        teamIds: [...teamIds],
        activeTab,
        showSprintComparison,
      };
    } finally {
      isProcessingRef.current = false;
    }
  }, [searchParams, startDate, endDate, portfolioIds, boardIds, teamIds, activeTab, showSprintComparison]);

  // Update URL from store changes
  useEffect(() => {
    // Skip if we're already processing a URL update
    if (isProcessingRef.current) {
      return;
    }

    // Skip URL update during initial render
    if (!startDate || !endDate) {
      return;
    }

    // Check if there are actual store changes to sync to URL
    const hasStoreChanges =
      startDate !== prevValuesRef.current.storeValues.startDate ||
      endDate !== prevValuesRef.current.storeValues.endDate ||
      !arrayEquals(portfolioIds, prevValuesRef.current.storeValues.portfolioIds) ||
      !arrayEquals(boardIds, prevValuesRef.current.storeValues.boardIds) ||
      !arrayEquals(teamIds, prevValuesRef.current.storeValues.teamIds) ||
      activeTab !== prevValuesRef.current.storeValues.activeTab ||
      showSprintComparison !== prevValuesRef.current.storeValues.showSprintComparison;

    if (!hasStoreChanges) {
      return;
    }

    isProcessingRef.current = true;

    try {
      const newParams = new URLSearchParams(searchParams);

      // Only add parameters if they have values
      if (startDate) {
        newParams.set('startDate', startDate);
      }

      if (endDate) {
        newParams.set('endDate', endDate);
      }

      if (portfolioIds.length > 0) {
        newParams.set('portfolioIds', portfolioIds.join(','));
      } else {
        newParams.delete('portfolioIds');
      }

      if (boardIds.length > 0) {
        newParams.set('boardIds', boardIds.join(','));
      } else {
        newParams.delete('boardIds');
      }

      if (teamIds.length > 0) {
        newParams.set('teamIds', teamIds.join(','));
      } else {
        newParams.delete('teamIds');
      }

      if (activeTab) {
        newParams.set('activeTab', activeTab);
      }

      // Add showSprintComparison parameter
      newParams.set('showSprintComparison', showSprintComparison.toString());

      // Only update if different from current URL params
      if (searchParamsToString(newParams) !== searchParamsToString(searchParams)) {
        // Debug log for development
        if (process.env.NODE_ENV === 'development') {
          console.debug('URL Params Sync:', {
            oldParams: searchParamsToString(searchParams),
            newParams: searchParamsToString(newParams),
            state: {
              startDate,
              endDate,
              portfolioIds,
              boardIds,
              teamIds,
              activeTab,
              showSprintComparison,
            },
          });
        }

        setSearchParams(newParams);
        prevValuesRef.current.searchParams = newParams.toString();
      }

      // Update the stored values with current store values
      prevValuesRef.current.storeValues = {
        startDate,
        endDate,
        portfolioIds: [...portfolioIds],
        boardIds: [...boardIds],
        teamIds: [...teamIds],
        activeTab,
        showSprintComparison,
      };
    } finally {
      isProcessingRef.current = false;
    }
  }, [
    startDate,
    endDate,
    portfolioIds,
    boardIds,
    teamIds,
    activeTab,
    showSprintComparison,
    searchParams,
    setSearchParams,
  ]);

  // Helper function to compare arrays for equality
  function arrayEquals(a: string[], b: string[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  // Helper to convert URLSearchParams to string for comparison
  function searchParamsToString(params: URLSearchParams): string {
    return params.toString();
  }
};

export {
  filterCustomMeasures,
  filterUnwantedMeasures,
  useAvailableMeasureNames,
  useAvailableMeasureOptions,
  useAvailableMeasureUnits,
  useDateRange,
  useDefaultDay,
  useEntities,
  useHistoricalBurns,
  useProcessAnalysisURLSync,
  useTimeAllocation,
  useTimeAllocations,
  useViewType,
  useWorkPeriodDates,
  useWorkPeriodDayOptions,
  useWorkPeriodLength,
};
