import { styled } from '@linaria/react';
import { Checkbox, MultiSelect, Select, Title } from '@mantine/core';
import { MeasureMetadata } from '../../work-periods.type';

interface ChartFilterControlsProps {
  customMeasures: MeasureMetadata[];
  selectedMeasure: string | string[];
  onMeasureChange: (value: any) => void;
  showTrendLine?: boolean;
  onShowTrendLineChange?: (value: boolean) => void;
  showDots?: boolean;
  onShowDotsChange?: (value: boolean) => void;
  isMultiSelect?: boolean;
}

export const ChartFilterControls = ({
  customMeasures,
  selectedMeasure,
  onMeasureChange,
  showTrendLine,
  onShowTrendLineChange,
  showDots,
  onShowDotsChange,
  isMultiSelect = false,
}: ChartFilterControlsProps) => {
  // Format measures for the select component
  const measureOptions = customMeasures.map((measure) => ({
    value: measure.measure_name,
    label: measure.measure_title || measure.measure_name,
  }));

  return (
    <FilterControlsContainer>
      <FilterSection>
        <Title order={5}>Measure</Title>
        {isMultiSelect ? (
          <MultiSelect
            data={measureOptions}
            value={selectedMeasure as string[]}
            onChange={onMeasureChange}
            placeholder="Select measures"
            searchable
            clearable
          />
        ) : (
          <Select
            data={measureOptions}
            value={selectedMeasure as string}
            onChange={onMeasureChange}
            placeholder="Select a measure"
            searchable
            clearable
          />
        )}
      </FilterSection>

      <FilterSection>
        <Title order={5}>Chart Options</Title>
        <OptionsContainer>
          {onShowTrendLineChange && (
            <Checkbox
              label="Show Trend Lines"
              checked={showTrendLine}
              onChange={(event) => onShowTrendLineChange(event.currentTarget.checked)}
            />
          )}

          {onShowDotsChange && (
            <Checkbox
              label="Show Dots"
              checked={showDots}
              onChange={(event) => onShowDotsChange(event.currentTarget.checked)}
            />
          )}
        </OptionsContainer>
      </FilterSection>
    </FilterControlsContainer>
  );
};

const FilterControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
