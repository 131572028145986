import { Measure, MeasureValuesOverTime } from '../../../api/work-periods-client/work-periods-client.type';
import { TransformedTarget } from '../../adherence/targets/targets-client.type';
import { Tab } from '../process-analysis.type';

type MeasureDataExtended = {
  average: number;
  last_month: number;
  months: MeasureValuesOverTime;
};

type MeasureDataAggregated = Record<string, MeasureDataExtended>;

type MeasuresWithColor = Record<Measure, string>;

type SelectedTarget = {
  measureId: Measure | string;
  targetId: string;
};

type MeasureComparisonSelection = {
  measures: Measure[];
  selectedMeasures: Measure[];
  selectedTrends: Measure[];
  selectedTargets: SelectedTarget[];
};

type MeasureComparisonSelectionAggregated = {
  [Tab.Portfolios]: MeasureComparisonSelection;
  [Tab.Teams]: MeasureComparisonSelection;
  [Tab.Boards]: MeasureComparisonSelection;
};

type MeasureRowProps = {
  title: string;
  measure: Measure | string;
  color: string;
  description?: string;
  isZeroValid: boolean;
  targets?: TransformedTarget[];
  average: number | null | undefined;
  trend: number | null | undefined;
  forecast: number | null | undefined;
  selectedMeasures: Measure[] | string[];
  selectedTrends: Measure[] | string[];
  selectedTargets?: SelectedTarget[];
  setSelectedMeasures: (event: any) => void;
  setSelectedTrends: (event: any) => void;
  setSelectedTargets?: (targets: SelectedTarget[]) => void;
  removeSelection?: (measure: Measure) => void;
};

type MeasureRowDetailInfo = {
  icon: string;
  label: string;
  value: string;
  tooltipMessageZeroValid?: string;
  tooltipMessageNotZeroValid?: string;
};

enum MeasureComparisonType {
  Default = 'default',
  Transformer = 'transformer',
}

export { MeasureComparisonType };
export type {
  MeasureComparisonSelection,
  MeasureComparisonSelectionAggregated,
  MeasureDataAggregated,
  MeasureDataExtended,
  MeasureRowDetailInfo,
  MeasureRowProps,
  MeasuresWithColor,
  SelectedTarget,
};
