import { RiskAnalysisSection } from '../../containers/adherence/risk-analysis/risk-analysis.client.type';

export enum AdherenceTab {
  RISK_ANALYSIS = 'risk-analysis',
  TARGETS = 'targets',
}

interface State {
  selectedRiskAnalysisSection: RiskAnalysisSection;
  setSelectedRiskAnalysisSection: (section: RiskAnalysisSection) => void;
  selectedTab: AdherenceTab;
  setSelectedTab: (tab: AdherenceTab) => void;
}

export type { State };
