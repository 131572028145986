import { styled } from '@linaria/react';
import { ReactNode } from 'react';

type Props = {
  content: ReactNode;
};

export const MainSection = ({ content }: Props) => {
  return (
    <Container>
      <Content>{content}</Content>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  padding: 16px 24px;
  flex: 1;
  background-color: var(--base-white);
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
