import { ExtendedInitiative, Initiative, KPIOverviewData } from '../../api/initiative-client/initiative-client.type';
import { Epic } from '../../api/tasks-client/task-client.type';
import {
  PerformanceTab,
  ThroughputMode,
} from '../../containers/strategy-v2/initiative-performance/initiative-performance.types';

enum MessageStatus {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type Message = {
  status: MessageStatus;
  text: string;
};

type Filters = {
  status: string[];
  state: string[];
  source: string[];
};

enum View {
  Table = 'table',
  TreeMap = 'tree-map',
}

enum TreeMapMetric {
  CostByInitiative = 'cost_by_initiative',
  EffortByInitiative = 'effort_by_initiative',
}

enum Panel {
  Filters = 'filters',
  Side = 'side',
  OtherInitiatives = 'other-initiatives',
}

type State = {
  initiatives: Initiative[];
  kpiOverviewData: KPIOverviewData | null;
  view: View;
  page: number;
  selectedInitiative: Initiative | null;
  initiativePerformance: ExtendedInitiative | null;
  performanceTab: PerformanceTab;
  filters: Filters;
  collapsedGroups: Record<string, boolean>;
  activePanel: Panel | null;
  searchTerm: string;
  treeMapMetric: TreeMapMetric;
  epics: Epic[];
  message: Message | null;
  throughputMode: ThroughputMode;
};

export { MessageStatus, Panel, TreeMapMetric, View };
export type { Filters, Message, State };
