import { Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { OverviewCard } from '../../../../components/overview-card/overview-card';
import { skyDark } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import {
  getInitiativeCompletionChart,
  getInitiativeStatusChart,
  getRiskCategoryBreakdownChart,
} from '../risk-analysis-chart.helpers';
import { RiskAnalysisInitiative } from '../risk-analysis.client.type';

export function RiskAnalysisInitiativeOverview({ initiatives }: { initiatives: RiskAnalysisInitiative[] }) {
  const [riskCategoryBreakdownChart, setRiskCategoryBreakdownChart] = useState<JSX.Element | null>(null);
  const [initiativeCompletionChart, setInitiativeCompletionChart] = useState<JSX.Element | null>(null);
  const [initiativeStatusChart, setInitiativeStatusChart] = useState<JSX.Element | null>(null);
  useEffect(() => {
    const riskCategoryBreakdownChart = getRiskCategoryBreakdownChart(initiatives);
    setRiskCategoryBreakdownChart(riskCategoryBreakdownChart);
    const initiativeCompletionChart = getInitiativeCompletionChart(initiatives);
    setInitiativeCompletionChart(initiativeCompletionChart);
    const initiativeStatusChart = getInitiativeStatusChart(initiatives);
    setInitiativeStatusChart(initiativeStatusChart);
  }, [initiatives]);

  return (
    <Group>
      <OverviewCard
        title="Initiatives by Status"
        icon={<Icon name="insert_chart_outlined" size={20} color={skyDark} />}
        content={initiativeStatusChart}
      />
      <OverviewCard
        title="Risk Category Breakdown"
        icon={<Icon name="bar_chart" size={20} color={skyDark} />}
        content={riskCategoryBreakdownChart}
      />
      <OverviewCard
        title="Average Completion"
        icon={<Icon name="check_circle" size={20} color={skyDark} />}
        content={initiativeCompletionChart}
      />
    </Group>
  );
}
