import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Tabs } from '@mantine/core';
import { Fragment } from 'react';
import { findClosestMantineColor } from '../../../helpers/color-helpers/color-helpers';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  setActiveTab,
  setAvailableMeasures,
} from '../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../styles/colors';
import { Text } from '../../../ui-library/typography/typography';
import { DeletePortfolioConfirmation } from '../portfolio-modal/delete-portfolio-confirmation';
import { PortfolioModal } from '../portfolio-modal/portfolio-modal';
import { Tab, WorkPeriodType } from '../process-analysis.type';
import { BoardForm } from './board-form';
import { DateAndSprintDisplay } from './date-and-sprint-display';
import { PortfolioForm } from './portfolio-form';
import { useCreatePortfolioModal, useDeletePortfolioModal, useEditPortfolioModal } from './scope-explorer.hooks';
import { TeamForm } from './team-form';
import { WorkPeriodForm } from './work-period-form';

export function ScopeExplorer() {
  const { portfolio, portfolios } = useGlobalStore();
  const { activeTab, portfolioIds, workPeriodType, workPeriod } = useProcessAnalysisStore();

  const { createPortfolioModalOpened, createPortfolioModal, createPortfolioModalSubmit } = useCreatePortfolioModal();
  const { editPortfolioModalOpened, editPortfolioModal, editPortfolioModalSubmit } = useEditPortfolioModal();
  const { deletePortfolioModalOpened, deletePortfolioModal, deletePortfolioModalSubmit } = useDeletePortfolioModal();

  return (
    <DropShadowContainer>
      <ContentContainer>
        <TabsContainer>
          <CustomTabs
            color={findClosestMantineColor(newCOLORS.indigo)}
            defaultValue={Tab.Portfolios}
            value={activeTab}
            onChange={(value: string | null) => {
              if (value !== activeTab) {
                setActiveTab(value as Tab);
                setAvailableMeasures([]);
              }
            }}
          >
            <Tabs.List>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <Tabs.Tab value={Tab.Portfolios}>
                    <Text textKey="common.portfolios">Portfolios</Text>
                  </Tabs.Tab>
                  <Tabs.Tab value={Tab.Teams}>
                    <Text textKey="common.teams">Teams</Text>
                  </Tabs.Tab>
                  <Tabs.Tab value={Tab.Boards}>
                    <Text textKey="common.boards">Boards</Text>
                  </Tabs.Tab>
                  <Tabs.Tab value={Tab.WorkPeriods}>
                    <Text textKey="common.work_periods">Work Periods</Text>
                  </Tabs.Tab>
                </div>
                {activeTab === Tab.WorkPeriods && workPeriodType === WorkPeriodType.Defined && workPeriod && (
                  <DateAndSprintDisplay />
                )}
                {activeTab === Tab.Portfolios && (
                  <ButtonContainer>
                    <AddButton onClick={createPortfolioModal.open}>
                      <ButtonIcon icon="mdi:plus-circle" width={30} height={30} type="button" />
                    </AddButton>
                    {portfolioIds && portfolioIds.length === 1 && (
                      <Fragment>
                        <AddButton onClick={editPortfolioModal.open}>
                          <ButtonIcon icon="mdi:edit-circle" width={30} height={30} type="button" />
                        </AddButton>
                        {portfolios.length > 1 && (
                          <AddButton onClick={deletePortfolioModal.open}>
                            <DeleteButtonIcon icon="mdi:delete-circle" width={30} height={30} />
                          </AddButton>
                        )}
                      </Fragment>
                    )}
                  </ButtonContainer>
                )}
              </div>
            </Tabs.List>
            <Tabs.Panel value={Tab.Portfolios} pt="xs">
              <PortfolioForm />
            </Tabs.Panel>
            <Tabs.Panel value={Tab.Teams} pt="xs">
              <TeamForm />
            </Tabs.Panel>
            <Tabs.Panel value={Tab.Boards} pt="xs">
              <BoardForm />
            </Tabs.Panel>
            <Tabs.Panel value={Tab.WorkPeriods} pt="xs">
              <WorkPeriodForm />
            </Tabs.Panel>
          </CustomTabs>
        </TabsContainer>
      </ContentContainer>
      <PortfolioModal
        opened={createPortfolioModalOpened}
        title={'New Portfolio'}
        mode={'create'}
        handleClose={createPortfolioModal.close}
        handleSubmit={createPortfolioModalSubmit}
      />
      <PortfolioModal
        opened={editPortfolioModalOpened}
        title={portfolio?.name || 'Edit Portfolio'}
        mode={'edit'}
        handleClose={editPortfolioModal.close}
        handleSubmit={editPortfolioModalSubmit}
      />
      <DeletePortfolioConfirmation
        opened={deletePortfolioModalOpened}
        close={deletePortfolioModal.close}
        onSubmit={deletePortfolioModalSubmit}
      />
    </DropShadowContainer>
  );
}

const DropShadowContainer = styled.div`
  height: fit-content;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 0.025;
`;

const AddButton = styled.button`
  color: ${newCOLORS.darkIndigo};
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  float: right;
`;

const ButtonIcon = styled(Icon)`
  color: ${newCOLORS.darkIndigo};
  &:hover {
    color: ${newCOLORS.gray};
  }
`;

const DeleteButtonIcon = styled(Icon)`
  color: ${newCOLORS.darkIndigo};
  &:hover {
    color: ${newCOLORS.red};
  }
`;

const CustomTabs = styled(Tabs)`
  .mantine-Tabs-tabsList {
    border: none;
  }
`;

const TabsContainer = styled.div`
  flex: 1;
  background-color: ${newCOLORS.white};
  padding: 24px;
`;
