import { downloadCSV } from '../../../../helpers/csv-download';

// Calculate trend (simple linear regression)
function calculateTrend(data: number[]) {
  if (data.length < 2) return null;

  const n = data.length;
  const indices = Array.from({ length: n }, (_, i) => i);

  const sumX = indices.reduce((sum, x) => sum + x, 0);
  const sumY = data.reduce((sum, y) => sum + y, 0);
  const sumXY = indices.reduce((sum, x, i) => sum + x * data[i], 0);
  const sumXX = indices.reduce((sum, x) => sum + x * x, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
}

function forecastNextValue(data: number[]) {
  const trend = calculateTrend(data);
  if (!trend) return null;

  const { slope, intercept } = trend;
  return intercept + slope * data.length;
}

// Get the last value from time series data
function getLastValue(timeSeriesData: Record<string, number> | undefined | null) {
  if (!timeSeriesData) return null;

  const dates = Object.keys(timeSeriesData).sort();
  if (dates.length === 0) return null;

  const lastDate = dates[dates.length - 1];
  return timeSeriesData[lastDate];
}

function handleDownload(selectedMeasures: string[], chartData: any) {
  const csvData = [
    ['Work Period', ...selectedMeasures],
    ...chartData.map((dataPoint: any) => [
      dataPoint.name,
      ...selectedMeasures.map((measureName) => {
        return dataPoint[measureName] !== undefined ? dataPoint[measureName] : '';
      }),
    ]),
  ];

  downloadCSV(csvData, 'measure-comparison.csv');
}

export { forecastNextValue, getLastValue, handleDownload };
