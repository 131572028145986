import { ProcessAnalysisInitiativeChartData } from '../../api/portfolio-client/portfolio-client.type';
import { Measure } from '../../api/work-periods-client/work-periods-client.type';
import { MeasureSelection } from '../../store/process-analysis-store/process-analysis-store.type';
import { TransformedTarget } from '../adherence/targets/targets-client.type';
import { SelectedTarget } from './measure-comparison/measure-comparison.type';

enum Tab {
  Portfolios = 'portfolios',
  Teams = 'teams',
  Boards = 'boards',
  WorkPeriods = 'work_periods',
}

enum ViewType {
  Single = 'single',
  Comparison = 'comparison',
  Assessment = 'assessment',
}

type Entities = { [key: string]: string };

enum MetricType {
  Tasks = 'tasks',
  Points = 'points',
}

enum WorkPeriodType {
  Defined = 'defined',
  Custom = 'custom',
}

enum Section {
  MeasureComparison = 'measureComparison',
  InitiativeCompletion = 'initiativeCompletion',
  InitiativeFocus = 'initiativeFocus',
  KeyMeasures = 'keyMeasures',
  SprintComparison = 'sprintComparison',
}

type MeasureComparisonSelectionContext = {
  measures: Measure[];
  selectedMeasures: Measure[];
  selectedTrends: Measure[];
  selectedTargets: SelectedTarget[];
  setMeasures: (measures: Measure[]) => void;
  setSelectedMeasures: (measures: Measure[]) => void;
  setSelectedTrends: (measures: Measure[]) => void;
  setSelectedTargets: (targets: SelectedTarget[]) => void;
  updateSelections: (updates: Partial<MeasureSelection>) => void;
  removeSelection: (measure: Measure) => void;
};

type InitiativeSectionSelectionContext = {
  metric: MetricType;
  initiatives: { id: string; name: string }[];
  selectedInitiatives: { id: string; name: string }[];
  selectedTrends: { id: string; name: string }[];
  initiativesData: ProcessAnalysisInitiativeChartData | null;
  setMetric: (metric: MetricType) => void;
  setInitiatives: (initiatives: { id: string; name: string }[]) => void;
  setSelectedInitiatives: (initiatives: { id: string; name: string }[]) => void;
  setSelectedTrends: (initiatives: { id: string; name: string }[]) => void;
  setInitiativesData: (data: ProcessAnalysisInitiativeChartData | null) => void;
};

type KeyMeasuresSelectionContext = {
  measure: Measure;
  selectedEntities: string[];
  selectedTrends: string[];
  selectedTargets: TransformedTarget[];
  setMeasure: (measure: Measure) => void;
  setSelectedEntities: (entities: string[]) => void;
  setSelectedTrends: (trends: string[]) => void;
  setSelectedTargets: (targets: TransformedTarget[]) => void;
};

type ProcessAnalysisContextType = {
  [Section.MeasureComparison]: MeasureComparisonSelectionContext;
  [Section.InitiativeCompletion]: InitiativeSectionSelectionContext;
  [Section.InitiativeFocus]: InitiativeSectionSelectionContext;
  [Section.KeyMeasures]: KeyMeasuresSelectionContext;
};

export { MetricType, Section, Tab, ViewType, WorkPeriodType };
export type {
  Entities,
  InitiativeSectionSelectionContext,
  KeyMeasuresSelectionContext,
  MeasureComparisonSelectionContext,
  ProcessAnalysisContextType,
};
