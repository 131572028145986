import { ChangeLogEntry, ChangeLogStatus } from './side-section.types';

const mockChangeLog: ChangeLogEntry[] = [
  {
    status: ChangeLogStatus.Completed,
    date: new Date('2023-09-13T17:02:00'),
  },
  {
    status: ChangeLogStatus.InProgress,
    date: new Date('2023-09-13T17:04:00'),
  },
  {
    status: ChangeLogStatus.NotActive,
    date: new Date('2023-09-13T17:04:00'),
  },
  {
    status: ChangeLogStatus.NotStarted,
    date: new Date('2023-09-13T17:02:00'),
  },
  {
    status: ChangeLogStatus.NotActive,
    date: new Date('2023-09-13T17:02:00'),
  },
  {
    status: ChangeLogStatus.Created,
    date: new Date('2023-09-13T17:02:00'),
  },
];

export { mockChangeLog };
