import { styled } from '@linaria/react';
import { Divider, Group, Checkbox as MantineCheckbox, Stack } from '@mantine/core';
import { inkLight } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';
import { FilterGroup } from './filter-panel.types';

type Props<T extends FilterGroup> = {
  group: T;
  toggleCollapsed: (group: T) => void;
  isCollapsed: boolean;
  isAllSelected: boolean;
  handleAllChange: (group: T, isChecked: boolean) => void;
  handleIndividualChange: (group: T, value: string, isChecked: boolean) => void;
  hasDivider?: boolean;
};

export const FilterPanelGroup = <T extends FilterGroup>({
  group,
  toggleCollapsed,
  isCollapsed,
  isAllSelected,
  handleAllChange,
  handleIndividualChange,
  hasDivider = true,
}: Props<T>) => {
  return (
    <Stack key={group.name} gap={16}>
      <Group justify="space-between" style={{ cursor: 'pointer' }} onClick={() => toggleCollapsed(group)}>
        <Group gap="xs">
          {group.icon && <Icon name={group.icon} size={16} color={inkLight} style={{ cursor: 'pointer' }} />}
          <Text weight="medium" size="small">
            {group.name}
          </Text>
        </Group>
        <Icon
          name={isCollapsed ? 'unfold_more' : 'unfold_less'}
          size={16}
          color={inkLight}
          style={{ cursor: 'pointer' }}
        />
      </Group>

      {!isCollapsed && (
        <>
          {group.showAll !== false && (
            <Checkbox
              label={group.showAllLabel ?? 'All'}
              checked={isAllSelected}
              onChange={(event) => handleAllChange(group, event.currentTarget.checked)}
            />
          )}
          {group.options.map((option) => (
            <Checkbox
              key={option.value}
              label={option.label}
              checked={group.selectedValues.includes(option.value)}
              onChange={(event) => handleIndividualChange(group, option.value, event.currentTarget.checked)}
            />
          ))}
        </>
      )}
      {hasDivider && <Divider style={{ marginTop: '8px' }} />}
    </Stack>
  );
};

const Checkbox = styled(MantineCheckbox)`
  .mantine-Checkbox-input,
  .mantine-Checkbox-label {
    cursor: pointer;
  }
  .mantine-Checkbox-input:checked {
    background-color: var(--secondary-base);
    border-color: var(--secondary-base);
  }
  .mantine-Checkbox-input:indeterminate {
    background-color: var(--secondary-base);
    border-color: var(--secondary-base);
  }
  .mantine-Checkbox-input:disabled {
    background-color: var(--sky-light);
    border-color: var(--sky-light);
    opacity: 0.5;
  }
  .mantine-Checkbox-input:disabled:checked {
    background-color: var(--sky-dark);
    border-color: var(--sky-dark);
  }
  .mantine-Checkbox-label:has(+ .mantine-Checkbox-input:disabled) {
    color: var(--sky-dark);
  }
`;
