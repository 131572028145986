import { createContext, ReactNode, useContext, useState } from 'react';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';

type TaskSelectionState = {
  selectedMeasure: string | null;
  selectedMeasureFilter: string | null;
  selectedStartDate: string | null;
  selectedEndDate: string | null;
  selectedWorkPeriodId: string | null;
  sideSectionVisible: boolean;
  tasks: MinifiedTask[];
  isLoadingTasks: boolean;
};

type TaskSelectionContextType = {
  // State
  selection: TaskSelectionState;

  // Actions
  selectMeasureByDateRange: (measureName: string, startDate: string, endDate: string, measureFilter?: string) => void;
  selectMeasureByWorkPeriod: (
    measureName: string,
    workPeriodId: string,
    startDate: string,
    endDate: string,
    measureFilter?: string,
  ) => void;
  closeSideSection: () => void;
  setTasks: (tasks: MinifiedTask[]) => void;
  setIsLoadingTasks: (isLoading: boolean) => void;
};

// Create context with default values
const TaskSelectionContext = createContext<TaskSelectionContextType | undefined>(undefined);

// Provider component
export function TaskSelectionProvider({ children }: { children: ReactNode }) {
  const [selection, setSelection] = useState<TaskSelectionState>({
    selectedMeasure: null,
    selectedMeasureFilter: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectedWorkPeriodId: null,
    sideSectionVisible: false,
    tasks: [],
    isLoadingTasks: false,
  });

  // Action to select a measure by date range
  const selectMeasureByDateRange = (
    measureName: string,
    startDate: string,
    endDate: string,
    measureFilter?: string,
  ) => {
    setSelection((prev) =>
      measureFilter
        ? {
            ...prev,
            selectedMeasure: measureName,
            selectedMeasureFilter: measureFilter,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            selectedWorkPeriodId: null, // Clear work period ID when selecting by date
            sideSectionVisible: true,
          }
        : {
            ...prev,
            selectedMeasure: measureName,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            selectedWorkPeriodId: null, // Clear work period ID when selecting by date
            sideSectionVisible: true,
          },
    );
  };

  // Action to select a measure by work period
  const selectMeasureByWorkPeriod = (
    measureName: string,
    workPeriodId: string,
    startDate: string,
    endDate: string,
    measureFilter?: string,
  ) => {
    setSelection((prev) =>
      measureFilter
        ? {
            ...prev,
            selectedMeasure: measureName,
            selectedMeasureFilter: measureFilter,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            selectedWorkPeriodId: workPeriodId,
            sideSectionVisible: true,
          }
        : {
            ...prev,
            selectedMeasure: measureName,
            selectedStartDate: startDate,
            selectedEndDate: endDate,
            selectedWorkPeriodId: workPeriodId,
            sideSectionVisible: true,
          },
    );
  };

  // Action to close side section
  const closeSideSection = () => {
    setSelection((prev) => ({
      ...prev,
      sideSectionVisible: false,
    }));
  };

  // Action to update tasks
  const setTasks = (tasks: MinifiedTask[]) => {
    setSelection((prev) => ({
      ...prev,
      tasks,
    }));
  };

  // Action to update loading state
  const setIsLoadingTasks = (isLoading: boolean) => {
    setSelection((prev) => ({
      ...prev,
      isLoadingTasks: isLoading,
    }));
  };

  return (
    <TaskSelectionContext.Provider
      value={{
        selection,
        selectMeasureByDateRange,
        selectMeasureByWorkPeriod,
        closeSideSection,
        setTasks,
        setIsLoadingTasks,
      }}
    >
      {children}
    </TaskSelectionContext.Provider>
  );
}

// Custom hook to use the context
export function useTaskSelection() {
  const context = useContext(TaskSelectionContext);
  if (context === undefined) {
    throw new Error('useTaskSelection must be used within a TaskSelectionProvider');
  }
  return context;
}
