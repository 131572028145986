import { styled } from '@linaria/react';
import { Divider, Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IntegrationType, Subproject } from '../../../api/integrations-client/integrations-client.type';
import { Workflow } from '../../../api/workflows-client/workflows-client.type';
import { useGetWorkflows, useUpdateWorkflow } from '../../../api/workflows-client/workflows.client.hooks';
import i18n from '../../../base-dictionary';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Select } from '../../../ui-library/select/select';
import { Text } from '../../../ui-library/typography/typography';
import {
  useAddSubprojectToBoardSetting,
  useBoardSettings,
  useRemoveSubprojectFromBoardSetting,
} from './board-settings-tab/board-settings-client.hooks';
import { DataManagementDropShadowContainer } from './data-management-header';
import { getDataSourceIcon } from './data-management.helpers';
import { getDataSourceName } from './data-sources-tab/data-sources.helpers';
type SubprojectInfoPanelProps = {
  subproject: Subproject;
  setSelectedSubprojectId: (id: string | null) => void;
  integrationSource: IntegrationType | null;
};

export function SubprojectInfoPanel({
  subproject,
  setSelectedSubprojectId,
  integrationSource,
}: SubprojectInfoPanelProps) {
  const organizationId = useOrganizationId();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | null>(null);
  const [selectedBoardSettingsId, setSelectedBoardSettingsId] = useState<string | null>(null);

  const { data: workflows } = useGetWorkflows(organizationId || null);
  const { data: boardSettings = [] } = useBoardSettings({ organization_id: organizationId });
  const { addSubprojectToBoardSetting } = useAddSubprojectToBoardSetting();
  const { removeSubprojectFromBoardSetting } = useRemoveSubprojectFromBoardSetting();

  const { mutate: updateWorkflow } = useUpdateWorkflow({
    organizationId: organizationId as string,
    onSuccess: () => {},
  });

  useEffect(() => {
    if (workflows) {
      const workflow = workflows.find((workflow) => workflow.subprojects.includes(subproject.id));
      setSelectedWorkflowId(workflow?.id || null);
    }
  }, [subproject, workflows]);

  useEffect(() => {
    if (boardSettings) {
      const boardSetting = boardSettings.find((boardSetting) => boardSetting.id === subproject.subproject_settings_id);
      setSelectedBoardSettingsId(boardSetting?.id || null);
    }
  }, [subproject, boardSettings]);

  /**
   * Handles the selection of a workflow for a subproject.
   * @param {Workflow[]} workflows - The list of workflows.
   * @param {string | null} oldWorkflowId - The ID of the old workflow.
   * @param {string | null} newWorkflowId - The ID of the new workflow.
   */
  function handleSelectWorkflow(workflows: Workflow[], oldWorkflowId: string | null, newWorkflowId: string | null) {
    if (!workflows.length) {
      return;
    }

    if (oldWorkflowId) {
      const oldWorkflow = workflows.find((workflow) => workflow.id === oldWorkflowId);
      if (oldWorkflow) {
        oldWorkflow.subprojects = oldWorkflow.subprojects.filter((id) => id !== subproject.id);
        updateWorkflow({ workflowId: oldWorkflow.id, payload: oldWorkflow });
      }
    }

    if (newWorkflowId) {
      const newWorkflow = workflows.find((workflow) => workflow.id === newWorkflowId);
      if (newWorkflow) {
        newWorkflow.subprojects.push(subproject.id);
        updateWorkflow({ workflowId: newWorkflow.id, payload: newWorkflow });
        setSelectedWorkflowId(newWorkflowId);
      }
    }
  }

  /**
   * Handles the selection of a board setting for a subproject.
   * @param {Subproject} subproject - The subproject.
   * @param {string | null} oldBoardSettingId - The ID of the old board setting.
   * @param {string | null} newBoardSettingId - The ID of the new board setting.
   */
  async function handleSelectBoardSetting(
    subproject: Subproject,
    oldBoardSettingId: string | null,
    newBoardSettingId: string | null,
  ) {
    if (oldBoardSettingId) {
      await removeSubprojectFromBoardSetting(oldBoardSettingId, subproject.id, organizationId as string);
    }

    if (newBoardSettingId) {
      await addSubprojectToBoardSetting(newBoardSettingId, subproject.id, organizationId as string);
      setSelectedBoardSettingsId(newBoardSettingId);
    }
  }

  return (
    <SubprojectInfoPanelContainer>
      <HeaderContainer>
        <Text weight="bold" size="large">
          {subproject.name}
        </Text>
        <Icon
          size={24}
          name="close"
          color={skyDark}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedSubprojectId(null);
          }}
        />
      </HeaderContainer>
      <Divider style={{ marginTop: 30, marginBottom: 24 }} />
      <Text size="small">Assigned Workflow</Text>
      <Select
        placeholder={i18n.t('common.placeholders.select_workflow')}
        value={selectedWorkflowId || ''}
        options={workflows?.map((workflow) => ({ label: workflow.name, value: workflow.id })) || []}
        onChange={(value) => {
          handleSelectWorkflow(workflows || [], selectedWorkflowId, value);
        }}
        size="md"
      />
      <Text size="small" style={{ marginTop: 24 }}>
        Assigned Board Setting
      </Text>
      <Select
        placeholder="Select a board setting"
        value={selectedBoardSettingsId || ''}
        options={boardSettings?.map((boardSetting) => ({ label: boardSetting.name, value: boardSetting.id })) || []}
        onChange={(value) => {
          handleSelectBoardSetting(subproject, selectedBoardSettingsId, value);
        }}
        size="md"
      />
      {integrationSource && (
        <>
          <Divider style={{ marginTop: 24, marginBottom: 24 }} />
          <Text weight="bold" size="large">
            Integration Source
          </Text>
          <Group style={{ marginTop: 24 }}>
            <img src={getDataSourceIcon(integrationSource)} width={24} height={24} alt={integrationSource} />
            <Text weight="medium" size="small">
              {getDataSourceName(integrationSource)}
            </Text>
          </Group>
        </>
      )}
    </SubprojectInfoPanelContainer>
  );
}

const SubprojectInfoPanelContainer = styled(DataManagementDropShadowContainer)`
  max-width: 285px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
