import { styled } from '@linaria/react';
import { Title, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { DownloadPNGButton } from '../../../../components/download-png-button';
import { downloadCSV } from '../../../../helpers/csv-download';
import { newCOLORS } from '../../../../styles/colors';
import { Icon } from '../../../../ui-library/icon/icon';
import { MeasureMetadata, WorkPeriod } from '../../work-periods.type';

interface ComparisonTableProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
}

export const ComparisonTable = ({ customMeasures, measureData, workPeriods }: ComparisonTableProps) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const formatDate = (date: string) => dayjs(date).format('MMM D');

  const getLastValue = (timeSeriesData: Record<string, number> | undefined | null, measureUnits: string) => {
    if (!timeSeriesData) return '-';

    const dates = Object.keys(timeSeriesData).sort();
    if (dates.length === 0) return '-';

    const lastDate = dates[dates.length - 1];
    const lastValue = timeSeriesData[lastDate];

    if (lastValue === null || lastValue === undefined) return '-';
    return measureUnits === 'Percentage' ? lastValue.toFixed(1) : lastValue.toFixed(0);
  };

  const getUnitsLabel = (units: string) => {
    switch (units) {
      case 'Percentage':
        return '%';
      case 'Hours':
        return 'hrs';
      case 'Days':
        return 'days';
      case 'Points':
        return 'pts';
      case 'Tasks':
        return 'tasks';
      default:
        return '';
    }
  };

  // Calculate trend indicators
  const getTrendIndicator = (currentValue: number, previousValue: number) => {
    if (currentValue > previousValue) {
      return <TrendIcon name="trending_up" size={16} color={newCOLORS.green} />;
    } else if (currentValue < previousValue) {
      return <TrendIcon name="trending_down" size={16} color={newCOLORS.red} />;
    }
    return <TrendIcon name="trending_flat" size={16} color={newCOLORS.gray} />;
  };

  // Get next period value for trend calculation
  // With the new sorting order, oldest periods are first and newest are last
  const getNextValue = (measure: string, currentPeriodIndex: number) => {
    const sortedWorkPeriods = workPeriods.sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)));
    if (currentPeriodIndex <= 0) return null;

    const previousPeriod = sortedWorkPeriods[currentPeriodIndex - 1];
    const value = measureData[previousPeriod.id]?.[measure];
    if (!value) return null;

    const dates = Object.keys(value).sort();
    if (dates.length === 0) return null;

    const lastDate = dates[dates.length - 1];
    return value[lastDate];
  };

  const handleDownload = () => {
    const sortedWorkPeriods = workPeriods.sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)));
    try {
      const csvData = [
        ['Measure', ...sortedWorkPeriods.map((wp) => wp.name)],
        ...customMeasures.map((measure) => [
          measure.measure_title,
          ...sortedWorkPeriods.map((workPeriod) =>
            getLastValue(measureData[workPeriod.id]?.[measure.measure_name], measure.measure_units),
          ),
        ]),
      ];

      downloadCSV(csvData, 'sprint-comparison.csv');
    } catch (error) {
      console.error('Error generating CSV data:', error);
    }
  };

  return (
    <DropShadowContainer ref={tableRef}>
      <TitleContainer>
        <Title order={2}>Sprint Comparison</Title>
        <DownloadPNGButton handleDownload={handleDownload} exceptionRef={downloadRef} />
      </TitleContainer>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Measure</TableHeader>
              <TableHeader>Average</TableHeader>
              {workPeriods
                .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
                .map((wp) => (
                  <TableHeader key={wp.id}>
                    <HeaderContainer>
                      <SprintName>{wp.name}</SprintName>
                      <DateRange>
                        {formatDate(wp.start_date)} - {formatDate(wp.end_date)}
                      </DateRange>
                    </HeaderContainer>
                  </TableHeader>
                ))}
            </tr>
          </thead>
          <tbody>
            {customMeasures.map((measure) => (
              <tr key={measure.measure_name}>
                <TableCell>
                  <CellContentWrapper>
                    <Tooltip label={measure.measure_description} position="top-start" withArrow multiline maw={250}>
                      <MeasureLabelContainer>
                        <MeasureLabel>{measure.measure_title}</MeasureLabel>
                        <InfoIcon name="info" size={14} color={newCOLORS.gray} />
                      </MeasureLabelContainer>
                    </Tooltip>
                  </CellContentWrapper>
                </TableCell>
                <TableCell>
                  <CellContentWrapper>
                    {(() => {
                      const values = workPeriods
                        .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
                        .map((workPeriod) => {
                          const value = measureData[workPeriod.id]?.[measure.measure_name];
                          if (!value) return null;
                          const lastValue = getLastValue(value, measure.measure_units);
                          return lastValue === '-' ? null : parseFloat(lastValue);
                        })
                        .filter((v): v is number => v !== null);

                      if (values.length === 0) return '-';
                      const avg = values.reduce((a, b) => a + b, 0) / values.length;
                      const formattedAvg = measure.measure_units === 'Percentage' ? avg.toFixed(1) : avg.toFixed(0);

                      return (
                        <>
                          <span>{formattedAvg}</span>
                          <UnitLabel>{getUnitsLabel(measure.measure_units)}</UnitLabel>
                        </>
                      );
                    })()}
                  </CellContentWrapper>
                </TableCell>
                {workPeriods
                  .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
                  .map((workPeriod, index) => {
                    const value = measureData[workPeriod.id]?.[measure.measure_name];
                    const displayValue = getLastValue(value, measure.measure_units);
                    const numericValue = displayValue !== '-' ? parseFloat(displayValue) : null;

                    const previousValue = getNextValue(measure.measure_name, index);
                    const showTrend = numericValue !== null && previousValue !== null;

                    return (
                      <TableCell key={workPeriod.id}>
                        <CellContentWrapper>
                          <ValueContainer>
                            <span>{displayValue}</span>
                            <UnitLabel>{getUnitsLabel(measure.measure_units)}</UnitLabel>
                            {showTrend && getTrendIndicator(numericValue!, previousValue!)}
                          </ValueContainer>
                        </CellContentWrapper>
                      </TableCell>
                    );
                  })}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </DropShadowContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SprintName = styled.div`
  font-weight: 600;
  color: darkgray;
`;

const DateRange = styled.div`
  font-size: 12px;
  color: gray;
  font-weight: 500;
`;

// Removed unused styled component

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const UnitLabel = styled.div`
  color: gray;
  background-color: white;
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid lightgray;
  color: darkgray;
  font-weight: 600;
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid lightgray;
  color: darkgray;
  font-weight: 500;
  gap: 4px;
`;

const CellContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MeasureLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: help;
`;

const MeasureLabel = styled.div`
  font-weight: 600;
  color: darkgray;
`;

const InfoIcon = styled(Icon)`
  opacity: 0.7;
`;

const TrendIcon = styled(Icon)`
  margin-left: 4px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
