import { styled } from '@linaria/react';
import { Flex, Group, Stack, Switch } from '@mantine/core';
import { useToggleTargetActive } from '../../containers/adherence/targets/targets-client.hooks';
import { Target } from '../../containers/adherence/targets/targets-client.type';
import { getTargetComparisonSymbol, getTargetScope } from '../../containers/adherence/targets/targets.helpers';
import { baseWhite, inkLighter, primaryBase, redBase, skyDark } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Tooltip } from '../../ui-library/tooltip/tooltip';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  target: Target;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
  onUpdate: (updatedTarget: Target) => void;
};

export function TargetSideSection({ target, onEdit, onDelete, onClose, onUpdate }: Props) {
  const { toggleTargetActive, isToggling } = useToggleTargetActive();
  const scope = getTargetScope(target);

  const handleToggleActive = async () => {
    try {
      const updatedTarget = await toggleTargetActive(target);
      onUpdate(updatedTarget);
    } catch (error) {
      console.error('Failed to toggle target active status:', error);
    }
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Text weight="bold" size="medium">
          {target.name}
        </Text>
        <Icon name="close" onClick={onClose} size={20} color={skyDark} />
      </Group>
      <Divider />

      <Tooltip
        label="Toggle target active status. Inactive targets will not be displayed."
        multiline
        w={200}
        variant="black"
        position="right"
        zIndex={1000}
      >
        <Group gap="sm">
          <Text size="small">Active</Text>
          <Switch
            color={primaryBase}
            checked={target.is_active}
            onChange={handleToggleActive}
            disabled={isToggling}
            style={{ minWidth: 100 }}
          />
        </Group>
      </Tooltip>

      <Divider />
      <Flex direction="row" justify="space-between" gap="xs" style={{ width: '100%' }}>
        <Button
          variant="secondary"
          size="sm"
          radius="xl"
          leftSection={<Icon name="edit" size={16} color={baseWhite} />}
          onClick={onEdit}
          style={{ flex: 1 }}
        >
          Edit
        </Button>
        <Button
          size="sm"
          radius="xl"
          leftSection={<Icon name="delete" size={16} color={baseWhite} />}
          onClick={onDelete}
          style={{ flex: 1, backgroundColor: redBase }}
        >
          Delete
        </Button>
      </Flex>
      <Divider />
      <Stack gap="xs">
        {[
          { label: 'Measure', value: target.measure },
          {
            label: 'Target Value',
            value: `${getTargetComparisonSymbol(target.target_comparison, target.target_value, target.target_value_upper)}`,
          },
          { label: 'Start Date', value: new Date(target.start_date).toLocaleDateString() },
          {
            label: target.end_date ? 'End Date' : 'Repeat',
            value: target.end_date
              ? new Date(target.end_date).toLocaleDateString()
              : target.is_work_period_target
                ? 'Every Sprint'
                : target.time_allocation_type,
          },
        ].map(({ label, value }) => (
          <Group key={label} justify="space-between">
            <Text size="small" color={inkLighter}>
              {label}
            </Text>
            <Text size="small" color={inkLighter}>
              {value}
            </Text>
          </Group>
        ))}
      </Stack>
      <Divider />
      <Text size="medium" weight="medium">
        Assigned {scope === 'Sprint' ? 'Board' : scope}s
      </Text>
      <Stack>
        {target.portfolios.map((portfolio) => (
          <RelatedObject key={portfolio.id} size="small">
            {portfolio.name}
          </RelatedObject>
        ))}
        {target.projects.map((project) => (
          <RelatedObject key={project.id} size="small">
            {project.name}
          </RelatedObject>
        ))}
        {target.subprojects.map((subproject) => (
          <RelatedObject key={subproject.id} size="small">
            {subproject.name}
          </RelatedObject>
        ))}
      </Stack>
    </Stack>
  );
}

const RelatedObject = styled(Text)`
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid ${inkLighter};
`;
