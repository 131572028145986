import { styled } from '@linaria/react';
import { Divider } from '@mantine/core';

import { OrganizationStatusesByProjectsAndBoards } from '../../../../../../api/organization-client/organization-client.type';
import { Workflow } from '../../../../../../api/workflows-client/workflows-client.type';
import i18n from '../../../../../../base-dictionary';
import { skyDark } from '../../../../../../styles/design-tokens';
import { Icon } from '../../../../../../ui-library/icon/icon';
import { Text } from '../../../../../../ui-library/typography/typography';
import { Boards } from '../../shared-components/boards';
import { WorkflowAction } from '../../shared-components/reducer';
import { GridItem } from '../../shared-components/styles';
import { WorkflowConfiguration } from '../../shared-components/workflow-configuration';

export function ViewWorkflowContent({
  state,
  dispatch,
  statusesByProjectsAndBoards,
  workflows,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
}) {
  return workflows && workflows.length > 0 ? (
    <IdleGridContainer>
      <GridItem>
        <Boards state={state} statusesByProjectsAndBoards={statusesByProjectsAndBoards} />
      </GridItem>
      <Divider orientation="vertical" />
      <GridItem>
        <WorkflowConfiguration
          state={state}
          dispatch={dispatch}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          isEditable={false}
        />
      </GridItem>
    </IdleGridContainer>
  ) : (
    <IdleGridContainerNoWorkflows>
      <EmptyStateContainer>
        <Icon name="add_circle_outline" size={40} color={skyDark} />
        <Text size="large" color={skyDark}>
          {i18n.t('data_management.workflows.add_new_workflow')}
        </Text>
      </EmptyStateContainer>
    </IdleGridContainerNoWorkflows>
  );
}

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const IdleGridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2px 3fr;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const IdleGridContainerNoWorkflows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
