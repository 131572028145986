import { QueryObserverResult, UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useProcessScope } from '../../containers/process/process.hooks';
import { getEpicsByPortfolioId } from '../portfolio-client/portfolio-client';
import { getEpicsByProjectId, getEpicsByProjectIdAndSubprojectId } from '../projects-client/projects-client';
import { Epic } from '../tasks-client/task-client.type';
import { getProcessGraph, getProcessMapping, getProcessTasks } from './process-client';
import {
  GraphData,
  ProcessGraphConfig,
  ProcessMapping,
  ProcessTask,
  ProcessTasksConfig,
  ProcessTasksData,
  Stages,
} from './process-client.type';

/**
 * Query manager for fetching process map graph data
 *
 * @param {ProcessGraphConfig} config - Configuration object for the query
 * @param {UseQueryOptions<GraphData>} options - Additional options for the query (optional)
 * @return {{ graphData: GraphData, query: QueryObserverResult<GraphData> }} - An object containing the graph data and the query
 */
const useProcessGraph = ({
  teamId,
  workflowId,
  boardId,
  taskType,
  epicId,
  graphType,
  startDate,
  endDate,
  useBloomfilterSections,
  options,
}: ProcessGraphConfig & {
  options?: UseQueryOptions<GraphData>;
}): { graphData: GraphData | undefined; query: QueryObserverResult<GraphData> } => {
  const query = useQuery({
    queryKey: ['process-graph', teamId, workflowId, boardId, taskType, epicId, graphType, startDate, endDate] as const,
    queryFn: () => {
      if (teamId || workflowId) {
        return getProcessGraph({
          project_id: teamId,
          workflow_id: workflowId,
          subproject_id: boardId,
          task_type: taskType,
          epic_id: epicId,
          graph_type: graphType,
          start_date: startDate,
          end_date: endDate,
          bloomfilter_sections: useBloomfilterSections,
        });
      }
      return Promise.reject('Cannot get process graph data: either teamId or workflowId is required');
    },
    ...options,
  });

  return { graphData: query.data, query };
};

/**
 * Query manager for fetching bf/customer stages mapping data
 *
 * @param {string | undefined} teamId - the ID of the team
 * @param {UseQueryOptions<ProcessMapping>} options - additional options for the query (optional)
 * @return {{ mapping: ProcessMapping, query: QueryObserverResult<ProcessMapping> }} - the mapping data and the query object.
 */
const useProcessMapping = ({
  teamId,
  workflowId,
  options,
}: {
  teamId?: string;
  workflowId?: string;
  options?: UseQueryOptions<ProcessMapping>;
}): { mapping: ProcessMapping | undefined; query: QueryObserverResult<ProcessMapping> } => {
  const query = useQuery({
    queryKey: ['process-mapping', teamId, workflowId] as const,
    queryFn: () => {
      if (teamId) {
        return getProcessMapping(teamId);
      } else if (workflowId) {
        // Use workflow ID for mapping if teamId is not provided
        return getProcessMapping(workflowId, true);
      }
      return Promise.reject('Cannot get process mapping: either teamId or workflowId is required');
    },
    ...options,
  });

  return { mapping: query.data, query };
};

/**
 * Query manager for fetching process tasks
 *
 * @param {ProcessTasksConfig} config - Configuration object for the query
 * @param {Partial<UseQueryOptions<ProcessTasksData>>} options - Additional options for the query (optional)
 * @return {{ taskData: ProcessTasksData | undefined, query: QueryObserverResult<ProcessTasksData> }} - An object containing the tasks and the query result
 */
const useProcessTasks = (
  { teamId, taskType, boardId, epicId, startDate, endDate, mapping }: ProcessTasksConfig,
  options?: Partial<UseQueryOptions<ProcessTasksData>>,
) => {
  const permutations = getCustomerStagesPermutations(mapping || ({} as Record<Stages, string[]>));

  const queries = useQueries({
    queries: permutations.map((permutation) => ({
      queryKey: ['process-tasks', teamId, boardId, taskType, epicId, startDate, endDate, permutation],
      queryFn: () =>
        teamId
          ? getProcessTasks(teamId, boardId, taskType, epicId, startDate, endDate, permutation)
          : Promise.reject('Cannot get process tasks'),
      ...options,
    })),
  });

  const taskData = queries.reduce((acc, queryResult) => {
    const {
      data: { tasks = [] } = {},
    } = queryResult;

    if (tasks.length > 0) {
      const filteredTasks = tasks.filter((task) => !acc.find((t) => t.id === task.id));

      return [...acc, ...filteredTasks];
    }

    return acc;
  }, [] as ProcessTask[]);

  const isFetching = queries.some((query) => query.isPending);

  return { taskData, isFetching };
};

/**
 * Generates an array of permutations between source and destination stages based on a given mapping.
 *
 * @param {Record<Stages, string[]>} mapping - The mapping of stages.
 * @returns {string[]} - An array of permutations between source and destination stages.
 */
const getCustomerStagesPermutations = (mapping: Record<Stages, string[]>): string[] => {
  const customerStagesMappings = Object.values(mapping);
  const [sourceStages = [], destinationStages = []] = customerStagesMappings;

  return sourceStages.reduce((acc, sourceStage) => {
    destinationStages.forEach((destinationStage) => {
      acc.push(`${sourceStage},${destinationStage}`);
    });

    return acc;
  }, [] as string[]);
};

/**
 * Query manager for fetching epics for a given scope.
 *
 * @param {AtLeastOne<{ portfolioId?: string; projectId?: string; subprojectId?: string }>} ids - IDs for fetching epics; at least one of these IDs must be provided
 * @param {UseQueryOptions<Epic[]>} options - Options for the useQuery hook
 * @return {{ data: Epic[] | undefined; query: QueryObserverResult<Epic[]> }} Object containing data and query result
 */
const useEpics = (
  ids: { portfolioId: string | null; projectId: string | null; subprojectId: string | null },
  options?: UseQueryOptions<Epic[]>,
): { epics: Epic[] | undefined; query: QueryObserverResult<Epic[]> } => {
  const { portfolio, team, board } = useProcessScope();
  const { portfolioId, projectId, subprojectId } = ids;
  const query = useQuery<Epic[], Error>({
    queryKey: ['epics', portfolioId, projectId, subprojectId, portfolio, team, board],
    queryFn: () => {
      if (ids.subprojectId) {
        if (!ids.projectId) {
          return Promise.reject('Project ID is required if subproject ID is provided');
        }
        return getEpicsByProjectIdAndSubprojectId(ids.projectId, ids.subprojectId);
      }

      if (ids.projectId) {
        return getEpicsByProjectId(ids.projectId);
      }

      if (ids.portfolioId) {
        return getEpicsByPortfolioId(ids.portfolioId);
      }

      return Promise.reject('No valid ID provided');
    },
    ...options,
  });

  return { epics: query.data, query };
};

export { useEpics, useProcessGraph, useProcessMapping, useProcessTasks };
