import { Group, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { RiskAnalysisInitiative } from '../../containers/adherence/risk-analysis/risk-analysis.client.type';
import { useGlobalStore } from '../../store/global-store/global-store';
import { baseWhite, inkLighter, skyDark } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

export function RiskAnalysisInitiativeSideSection({
  initiative,
  onClose,
}: {
  initiative: RiskAnalysisInitiative | null;
  onClose: () => void;
}) {
  const portfolioId = useGlobalStore((state) => state.portfolio?.id);
  const navigate = useNavigate();

  const handleMoreDetailsClick = () => {
    navigate(`/application/strategy/${portfolioId}/${initiative?.id}`);
  };

  return (
    <Stack>
      <Group justify="space-between" style={{ position: 'relative' }}>
        <Group gap="xs" style={{ marginRight: '32px' }}>
          <Text weight="bold" size="medium">
            {initiative?.name}
          </Text>
        </Group>
        <Icon
          name="close"
          onClick={onClose}
          size={20}
          color={skyDark}
          style={{ position: 'absolute', right: 0, top: 0 }}
        />
      </Group>
      <Divider />
      <Stack gap="xs">
        {[
          { label: 'Start Date', value: initiative?.start_date },
          { label: 'End Date', value: initiative?.end_date },
          { label: 'Risk Score', value: initiative?.flora_annotations?.risk_score || '-' },
        ].map(({ label, value }) => (
          <Group key={label} justify="space-between">
            <Text size="small" color={inkLighter}>
              {label}
            </Text>
            <Text size="small" color={inkLighter}>
              {value}
            </Text>
          </Group>
        ))}
      </Stack>
      <Divider />
      <Button
        variant="secondary"
        size="sm"
        radius="xl"
        leftSection={<Icon name="visibility" size={16} color={baseWhite} />}
        onClick={handleMoreDetailsClick}
      >
        More Initiative Details
      </Button>
      <Divider />
      <Text weight="medium" size="medium">
        Risk Summary
      </Text>
      <Text size="small">{initiative?.flora_annotations?.executive_summary || 'N/A'}</Text>
      <Divider />
      <Text weight="medium" size="medium">
        Action Plan
      </Text>
      <Text size="small">{initiative?.flora_annotations?.action_plan || 'N/A'}</Text>
    </Stack>
  );
}
