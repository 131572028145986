import { newCOLORS } from '../../../styles/colors';
import { TaskType } from '../../tasks-table/tasks-table.type';

function getTypeColor(type: TaskType | null): string {
  if (!type) return newCOLORS.gray;

  switch (type) {
    case TaskType.Bug:
      return newCOLORS.red;
    case TaskType.Story:
      return newCOLORS.green;
    case TaskType.Task:
      return newCOLORS.blue;
    case TaskType.Subtask:
      return newCOLORS.purple;
    case TaskType.Spike:
      return newCOLORS.orange;
    default:
      return newCOLORS.gray;
  }
}

export { getTypeColor };
