import { QueryObserverResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { createTarget, deleteTarget, fetchAvailableTargets, fetchTargets, updateTarget } from './targets-client';
import { GetAvailableTargetsPayload, GetAvailableTargetsResponse, Target, TargetPayload } from './targets-client.type';
import { convertFormToTargetPayload, convertTargetToForm } from './targets.helpers';

/**
 * Hook to fetch targets for an organization
 *
 * @returns {Object} Object containing:
 *   - data: The targets data
 *   - query: The React Query result object
 */
const useTargetsData = (): {
  data: Target[] | undefined;
  query: QueryObserverResult<Target[]>;
} => {
  const organizationId = useOrganizationId();

  const query = useQuery({
    queryKey: ['targets', organizationId],
    queryFn: () => fetchTargets(organizationId || ''),
    enabled: !!organizationId,
  });

  return { data: query.data, query };
};

/**
 * Hook for creating new targets
 *
 * @returns Object containing:
 * - createTarget: Function to create a new target
 * - isCreating: Boolean indicating if creation is in progress
 */
const useCreateTarget = (): {
  createTarget: (target: Partial<TargetPayload>) => Promise<Target>;
  isCreating: boolean;
} => {
  const queryClient = useQueryClient();
  const organizationId = useOrganizationId();

  const mutation = useMutation({
    mutationFn: (target: Partial<TargetPayload>) => createTarget({ ...target, organization: organizationId || '' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['targets'] });
      queryClient.invalidateQueries({ queryKey: ['available-targets'] });
    },
  });

  return {
    createTarget: (target: Partial<TargetPayload>) => mutation.mutateAsync(target),
    isCreating: mutation.isPending,
  };
};

/**
 * Hook for updating a target
 *
 * @returns Object containing:
 * - updateTarget: Function to update a target
 * - isUpdating: Boolean indicating if update is in progress
 */
const useUpdateTarget = (): {
  updateTarget: (target: Partial<TargetPayload>) => Promise<Target>;
  isUpdating: boolean;
} => {
  const queryClient = useQueryClient();
  const organizationId = useOrganizationId();

  const mutation = useMutation({
    mutationFn: (target: Partial<TargetPayload>) => updateTarget({ ...target, organization: organizationId || '' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['targets'] });
      queryClient.invalidateQueries({ queryKey: ['available-targets'] });
    },
  });

  return {
    updateTarget: (target: Partial<TargetPayload>) => mutation.mutateAsync(target),
    isUpdating: mutation.isPending,
  };
};

/**
 * Hook for deleting a target
 *
 * @returns Object containing:
 * - deleteTarget: Function to delete a target
 * - isDeleting: Boolean indicating if deletion is in progress
 */
const useDeleteTarget = (): {
  deleteTarget: (targetId: string) => Promise<void>;
  isDeleting: boolean;
} => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteTarget,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['targets'] });
      queryClient.invalidateQueries({ queryKey: ['available-targets'] });
    },
  });

  return {
    deleteTarget: (targetId: string) => mutation.mutateAsync(targetId),
    isDeleting: mutation.isPending,
  };
};

/**
 * Hook for toggling a target's active status
 *
 * @returns Object containing:
 * - toggleTargetActive: Function to toggle a target's active status
 * - isToggling: Boolean indicating if toggle is in progress
 */
const useToggleTargetActive = (): {
  toggleTargetActive: (target: Target) => Promise<Target>;
  isToggling: boolean;
} => {
  const { updateTarget, isUpdating } = useUpdateTarget();

  const toggleTargetActive = async (target: Target) => {
    const payload = convertFormToTargetPayload(convertTargetToForm(target));
    return updateTarget({ ...payload, is_active: !target.is_active });
  };

  return {
    toggleTargetActive,
    isToggling: isUpdating,
  };
};

/**
 * Hook to fetch available targets based on provided criteria
 *
 * @param {Object} options - The options object
 * @param {GetAvailableTargetsPayload} options.payload - Payload containing filter criteria
 * @returns {Object} Object containing:
 *   - data: The available targets data
 *   - query: The React Query result object
 */
const useAvailableTargets = ({
  payload,
}: {
  payload: GetAvailableTargetsPayload;
}): {
  data: GetAvailableTargetsResponse | undefined;
  query: QueryObserverResult<GetAvailableTargetsResponse>;
} => {
  const query = useQuery({
    queryKey: [
      'available-targets',
      payload.organization_id,
      payload.portfolio_ids,
      payload.project_ids,
      payload.subproject_ids,
      payload.start_date,
      payload.end_date,
      payload.time_allocation_type,
      payload.measure,
    ],
    queryFn: () => fetchAvailableTargets(payload),
    enabled: !!payload.organization_id,
  });
  return { data: query.data, query };
};

export {
  useAvailableTargets,
  useCreateTarget,
  useDeleteTarget,
  useTargetsData,
  useToggleTargetActive,
  useUpdateTarget,
};
