import { styled } from '@linaria/react';
import { ChartData, CoreScaleOptions, Scale, Tick } from 'chart.js';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import { Measure, MeasureUnits, WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { MeasureUnitMap } from '../../../store/process-analysis-store/process-analysis-store.type';
import { newCOLORS } from '../../../styles/colors';
import { TransformedTarget } from '../../adherence/targets/targets-client.type';
import { y1AxisInUse, y1AxisLabel, yAxisInUse } from '../measure-comparison/measure-comparison.helpers';
import { SelectedTarget } from '../measure-comparison/measure-comparison.type';
import { createLine } from '../process-analysis-chart.helpers';
import { getUnitsLabel } from '../process-analysis.helpers';
import { FormattedMeasureData } from './sprint-comparison-chart.types';
import { getSprintComparisonTargetLineDatasets } from './sprint-comparison-view.helpers';

interface SprintMeasureComparisonLineChartProps {
  formattedData: Record<string, FormattedMeasureData>;
  workPeriods: WorkPeriod[];
  measuresWithColor: Record<string, string>;
  selectedMeasures: string[];
  measureUnitMap: Record<string, MeasureUnits>;
  selectedTrends: string[];
  selectedTargets: SelectedTarget[];
  targets: TransformedTarget[];
}

export const SprintMeasureComparisonLineChart = ({
  formattedData,
  workPeriods,
  measuresWithColor,
  selectedMeasures,
  measureUnitMap,
  selectedTrends,
  selectedTargets,
  targets,
}: SprintMeasureComparisonLineChartProps) => {
  const formatDate = (date: string) => dayjs(date).format('MMM D');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: true,
    aspectRatio: 2.5,
    spanGaps: true,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    scales: {
      x: {
        type: 'category' as const,
        title: {
          display: true,
          text: 'Sprints',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          callback: function (this: Scale<CoreScaleOptions>, tickValue: string | number, _: number, _ticks: Tick[]) {
            const sprint = workPeriods[Number(tickValue)];
            return sprint ? `${sprint.name}` : '';
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'linear' as const,
        beginAtZero: true,
        position: 'left' as const,
        suggestedMax: 100,
        title: {
          display: true,
          text: '%',
          color: yAxisInUse(selectedMeasures as Measure[], [], measureUnitMap as MeasureUnitMap)
            ? undefined
            : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: yAxisInUse(selectedMeasures as Measure[], [], measureUnitMap as MeasureUnitMap)
            ? undefined
            : newCOLORS.gray,
        },
      },
      y1: {
        type: 'linear' as const,
        beginAtZero: true,
        position: 'right' as const,
        title: {
          display: true,
          text: y1AxisLabel(selectedMeasures as Measure[], [], measureUnitMap as MeasureUnitMap),
          color: y1AxisInUse(selectedMeasures as Measure[], [], measureUnitMap as MeasureUnitMap)
            ? undefined
            : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: y1AxisInUse(selectedMeasures as Measure[], [], measureUnitMap as MeasureUnitMap)
            ? undefined
            : newCOLORS.gray,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            const sprint = workPeriods[context[0].dataIndex];
            return sprint ? `${sprint.name}\n${formatDate(sprint.start_date)} - ${formatDate(sprint.end_date)}` : '';
          },
          label: (context: any) => {
            const value = context.raw;
            const datasetLabel = context.dataset.label || '';

            // Check if this is a target line
            if (datasetLabel.includes('Target')) {
              const measureName = datasetLabel.split('(')[1]?.replace(')', '');
              const isUpperTarget = datasetLabel.includes('Upper');
              const targetName = datasetLabel.split('(')[0].trim().replace('Target: ', '');

              return `${isUpperTarget ? 'Upper Target' : 'Target'} - ${targetName}: ${value}${
                measureUnitMap[measureName] ? ` ${getUnitsLabel(measureUnitMap[measureName])}` : ''
              }`;
            }

            // Regular measure line
            const measure = selectedMeasures[context.datasetIndex];
            return `${value}${measureUnitMap[measure] ? ` ${getUnitsLabel(measureUnitMap[measure])}` : ''}`;
          },
        },
      },
    },
  } as const;

  const formatData = (): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels: workPeriods.map((wp) => wp.name),
      datasets: [],
    };

    for (const measure of selectedMeasures) {
      const measureData = formattedData[measure];
      if (!measureData) continue;

      chartData.datasets.push(
        createLine(
          measure,
          measureData.values,
          measuresWithColor[measure] ?? newCOLORS.black,
          false,
          measureUnitMap[measure] === MeasureUnits.Percentage ? 'y' : 'y1',
        ),
      );

      if (selectedTrends.includes(measure) && measureData.average !== null) {
        const roundedAverage = Number(measureData.average.toFixed(2));
        const averageLine = Array(workPeriods.length).fill(roundedAverage);
        chartData.datasets.push(
          createLine(
            `${measure}_average`,
            averageLine,
            measuresWithColor[measure] ?? newCOLORS.black,
            true,
            measureUnitMap[measure] === MeasureUnits.Percentage ? 'y' : 'y1',
          ),
        );
      }

      // Add selected target lines for this measure
      const measureTargetSelections = selectedTargets.filter((target) => target.measureId === measure);

      for (const targetSelection of measureTargetSelections) {
        const target = targets.find((target) => target.id === targetSelection.targetId);
        if (!target) continue;
        const datasets = getSprintComparisonTargetLineDatasets(
          target,
          workPeriods,
          measuresWithColor[measure] ?? newCOLORS.black,
          'y1',
        );
        chartData.datasets.push(...datasets);
      }
    }

    return chartData;
  };

  return (
    <ChartContainer>
      <Line data={formatData()} options={options} />
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
`;
