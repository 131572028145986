import { InitiativeStatusData } from '../../../api/initiative-client/initiative-client.type';

type FloraAnnotation = {
  risk_score: number;
  executive_summary: string;
  action_plan: string;
};

type RiskAnalysisTask = {
  id: string;
  code: string;
  name: string;
  description: string;
  points: number;
  type: string;
  url: string;
  created_date: string;
  done_date: string | null;
  flora_annotations: FloraAnnotation | null;
  project: {
    id: string;
    name: string;
  };
  reporter?: {
    id: string;
    name: string;
  } | null;
  epic?: {
    id: string;
    name: string;
  } | null;
  initiatives?:
    | {
        id: string;
        name: string;
      }[]
    | null;
  total_subtasks?: number;
  not_done_subtasks?: number;
};

type RiskAnalysisInitiative = {
  id: string;
  name: string;
  description: string;
  start_date: string | null;
  end_date: string;
  status: InitiativeStatusData;
  is_active: boolean;
  completion_percentage: number;
  flora_annotations: FloraAnnotation | null;
};

type RiskAnalysisPullRequest = {
  id: string;
  title: string;
  repository: {
    id: string;
    name: string;
  };
  url: string;
  body: string;
  state: 'open' | 'closed';
  created_at: string;
  updated_at: string;
  closed_at: string;
  merged_at: string;
  changed_files: number;
  additions: number;
  deletions: number;
  commits: number;
  flora_annotations: FloraAnnotation | null;
};

type RiskAnalysisProject = {
  id: string;
  name: string;
  average_risk_score: number | null;
};

enum RiskAnalysisSection {
  TASKS = 'Tasks',
  INITIATIVES = 'Initiatives',
  EPICS = 'Epics',
  PULL_REQUESTS = 'Pull Requests',
}

export { RiskAnalysisSection };
export type { RiskAnalysisInitiative, RiskAnalysisProject, RiskAnalysisPullRequest, RiskAnalysisTask };
