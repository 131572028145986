import { styled } from '@linaria/react';
import { Checkbox, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { FlowByPhaseData } from '../../../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../../../styles/colors';
import { ContentTag, HeadingTag } from '../../../../styles/shared-styled-components';

type PhaseCardsProps = {
  data: FlowByPhaseData;
  metric: 'tasks' | 'points';
  initiative: ExtendedInitiative;
  phaseMetadata: Array<{
    id: string;
    label: string;
    description: string;
    color: string;
  }>;
  date?: string;
  initiativeId?: string;
  epicId?: string | null;
  visiblePhases?: Record<string, boolean>;
  togglePhaseVisibility?: (phaseId: string) => void;
};

export const PhaseCards = ({
  data,
  metric,
  initiative,
  epicId,
  phaseMetadata,
  date,
  visiblePhases = {},
  togglePhaseVisibility = () => {},
}: PhaseCardsProps) => {
  const navigate = useNavigate();
  if (!data) return null;

  const total = Object.entries(data).reduce((acc, [key, value]) => {
    if (key.startsWith(metric) && value !== null) {
      return acc + value;
    }
    return acc;
  }, 0);

  return (
    <CardsContainer>
      {phaseMetadata.map((phase) => {
        const key = `${metric}_${phase.id}` as keyof FlowByPhaseData;
        const value = data[key];
        const percentage = total > 0 ? (value / total) * 100 : 0;

        const searchParams = new URLSearchParams({
          measure: 'flow_by_phase',
          factor: phase.id.toLowerCase(),
          start_date: dayjs(initiative.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(initiative.end_date).format('YYYY-MM-DD'),
          current_date: dayjs(date).format('YYYY-MM-DD'),
        });

        if (initiative.id) {
          searchParams.append('initiative_id', initiative.id);
        }
        if (epicId) {
          searchParams.append('epic_id', epicId);
        }

        const visible = visiblePhases && phase.id in visiblePhases ? visiblePhases[phase.id] : true;

        return (
          <Card key={phase.id}>
            <CardHeader>
              <CenterHeadingTag
                onClick={() =>
                  navigate(`/application/process-analysis/initiative-phase-tasks?${searchParams.toString()}`)
                }
              >
                {phase.label}
              </CenterHeadingTag>
              <CheckboxContainer>
                <Tooltip
                  label={`${visible ? 'Hide' : 'Show'} in chart`}
                  position="top"
                  withArrow
                  transitionProps={{
                    transition: 'fade',
                    duration: 200,
                  }}
                >
                  <Checkbox
                    checked={visible}
                    onChange={() => togglePhaseVisibility(phase.id)}
                    size="xs"
                    color={phase.color}
                  />
                </Tooltip>
              </CheckboxContainer>
            </CardHeader>
            <SmallContentTag>{phase.description}</SmallContentTag>
            <Tooltip
              label={`${Math.round(value || 0)} (${percentage.toFixed(1)}%)`}
              position="bottom"
              transitionProps={{
                transition: 'fade',
                duration: 200,
              }}
            >
              <CenterContentTag>
                <Value>{Math.round(value || 0)}</Value>
                <Percentage>({percentage.toFixed(1)}%)</Percentage>
              </CenterContentTag>
            </Tooltip>
            <LegendBox>
              <Legend color={phase.color} />
            </LegendBox>
          </Card>
        );
      })}
    </CardsContainer>
  );
};

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px 5px;
  margin-bottom: 24px;
`;

const Card = styled.div`
  position: relative;
  width: 125px;
  height: 125px;
  border: 2px solid #e0e0e0;
  margin-right: 30px;
  margin-bottom: 5px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 5px;
  justify-content: center;
  gap: 10px;
`;

const CheckboxContainer = styled.div`
  margin-top: 2px;
`;

const CenterHeadingTag = styled(HeadingTag)`
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  color: ${newCOLORS.indigo};
`;

const SmallContentTag = styled(ContentTag)`
  font-size: 12px;
  text-align: center;
`;

const CenterContentTag = styled(ContentTag)`
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
`;

const Value = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-right: 4px;
`;

const Percentage = styled.span`
  font-size: 12px;
  color: #666;
`;

const LegendBox = styled.div`
  position: absolute;
  bottom: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Legend = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;
