import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { RiskAnalysisSection } from '../../containers/adherence/risk-analysis/risk-analysis.client.type';
import { AdherenceTab, State } from './adherence-store.type';

export const useAdherenceStore = create<State>()(
  persist(
    (set) => ({
      selectedRiskAnalysisSection: RiskAnalysisSection.TASKS,
      setSelectedRiskAnalysisSection: (section: RiskAnalysisSection) => set({ selectedRiskAnalysisSection: section }),
      selectedTab: AdherenceTab.TARGETS,
      setSelectedTab: (tab: AdherenceTab) => set({ selectedTab: tab }),
    }),
    {
      name: 'adherence-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
