import { styled } from '@linaria/react';
import { Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { newCOLORS } from '../../../../styles/colors';
import { useTaskSelection } from '../../tasks.context';
import { useWorkPeriodsContext } from '../../work-periods.context';
import { WorkPeriod, WorkPeriodType } from '../../work-periods.type';
import {
  buildDeliveryChartTooltip,
  filterDeliveryData,
  filterHistoricalBurnsData,
  mergeDeliveryDataWithHistoricalBurnsData,
  transformForDeliveryChart,
  transformHistoricalBurnsData,
} from './helpers/delivery-section.helpers';

// Define metrics enum to match process analysis
enum Metric {
  Tasks = 'tasks',
  Points = 'points',
}

export function DeliverySection({ selectedWorkPeriod }: { selectedWorkPeriod: WorkPeriod }) {
  const { selectedWorkPeriods, dateRange, selectedWorkPeriodType, flowData } = useWorkPeriodsContext();
  const { selectMeasureByWorkPeriod } = useTaskSelection();
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);

  const filteredDeliveryData = filterDeliveryData(flowData?.burns, metric);
  const deliveryData = transformForDeliveryChart(filteredDeliveryData, metric);

  const filteredHistoricalBurnsData = filterHistoricalBurnsData(flowData?.historical_burns, metric);
  const historicalBurnsData = transformHistoricalBurnsData(filteredHistoricalBurnsData, metric);

  const deliveryConfidenceChartData = mergeDeliveryDataWithHistoricalBurnsData(deliveryData, historicalBurnsData);

  // Determine if we have a selected work period or date range
  const hasSelection =
    (selectedWorkPeriodType === WorkPeriodType.Defined && selectedWorkPeriods.length > 0) ||
    (selectedWorkPeriodType === WorkPeriodType.Custom && dateRange.startDate && dateRange.endDate);

  const dates = Object.keys(flowData?.burns || {});
  const latestDateFromChartData =
    dates.length > 0 ? dates.sort((a, b) => dayjs(a).unix() - dayjs(b).unix()).pop() : null;

  // Initialize hoveredDate to latestDate when component mounts or latestDate changes
  useEffect(() => {
    if (latestDateFromChartData) {
      // Normalize with dayjs to ensure consistent format
      setHoveredDate(dayjs(latestDateFromChartData).format('YYYY-MM-DD'));
    }
  }, [latestDateFromChartData]);

  // Hover handler for the chart
  const handleChartMouseMove = (data: any) => {
    if (data && data.activeLabel) {
      const normalizedDate = dayjs(data.activeLabel).format('YYYY-MM-DD');
      setHoveredDate(normalizedDate);
    }
  };

  const handleMeasureClick = (forDate: string, measureFilter: string, selectedWorkPeriodId: string) => {
    const startDate = dayjs(forDate).startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs(forDate).endOf('day').format('YYYY-MM-DD');
    if (selectedWorkPeriodId) {
      // If we have a work period, use it
      selectMeasureByWorkPeriod('flow_by_pace', selectedWorkPeriodId, startDate, endDate, measureFilter);
    }
  };

  return hasSelection && flowData ? (
    <SectionContainer>
      <SectionHeader>
        <div>
          <Text fw={500} size="lg">
            Flow of work, delivery confidence
          </Text>
          <Text size="sm" color="dimmed">
            How work in this work period is or isn't keeping up with needed pace, based on historical performance.
          </Text>
        </div>
        <Group>
          <TabsGroup>
            <TabButton active={metric === Metric.Tasks} onClick={() => setMetric(Metric.Tasks)}>
              Tasks
            </TabButton>
            <TabButton active={metric === Metric.Points} onClick={() => setMetric(Metric.Points)}>
              Points
            </TabButton>
          </TabsGroup>
          <ExportButton>Export</ExportButton>
        </Group>
      </SectionHeader>

      <ChartContainer>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={deliveryConfidenceChartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            onMouseMove={handleChartMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={(value) => dayjs(value).format('MMM D')} tick={{ fontSize: 12 }} />
            <YAxis
              tick={{ fontSize: 12 }}
              label={{
                value: metric === Metric.Tasks ? 'Tasks Completed' : 'Points Completed',
                angle: -90,
                position: 'insideLeft',
                style: { textAnchor: 'middle' },
              }}
            />
            <Tooltip
              formatter={(value, name) => buildDeliveryChartTooltip(value, name, metric)}
              labelFormatter={(label) => `Date: ${new Date(label).toLocaleDateString()}`}
            />
            <Legend
              payload={[
                { value: 'Total Scope', color: newCOLORS.purple, type: 'line' },
                { value: 'Completed', color: newCOLORS.green, type: 'line' },
                { value: 'Confidence Band', color: newCOLORS.lighterBlue, type: 'square' },
                { value: 'Scope Change', color: newCOLORS.lightPurple, type: 'square' },
              ]}
            />
            <Line
              type="monotone"
              dataKey="scope"
              name="Total Scope"
              stroke={newCOLORS.purple}
              strokeWidth={2}
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
            />
            <Area
              type="monotone"
              dataKey="scopeChange"
              stroke="#4C6EF5"
              strokeWidth={0}
              fill="#4C6EF5"
              fillOpacity={0.3}
              name="Scope Change"
            />
            <Area
              type="monotone"
              dataKey="band"
              stroke={newCOLORS.lighterBlue}
              strokeWidth={0}
              fill={newCOLORS.lighterBlue}
              fillOpacity={0.3}
              name="Confidence Band"
            />
            <Line
              type="monotone"
              dataKey="completed"
              name="Completed"
              stroke={newCOLORS.green}
              strokeWidth={2}
              dot={{ r: 3 }}
              activeDot={(props: any) => {
                const { cx, cy } = props;
                return (
                  <circle
                    cx={cx}
                    cy={cy}
                    r={7}
                    fill={newCOLORS.green}
                    stroke={newCOLORS.green}
                    strokeWidth={2}
                    cursor="pointer"
                    onClick={() => hoveredDate && handleMeasureClick(hoveredDate, 'done', selectedWorkPeriod.id)}
                  />
                );
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartContainer>
    </SectionContainer>
  ) : (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Delivery Confidence
        </Text>
      </SectionHeader>
      <Text>Select a work period or date range to view delivery confidence data.</Text>
    </SectionContainer>
  );
}

// Styled components
const SectionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TabsGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TabButton = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => (props.active ? '#4C6EF5' : '#f0f0f0')};
  color: ${(props) => (props.active ? 'white' : '#333')};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#4C6EF5' : '#e0e0e0')};
  }
`;

const ChartContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
`;

const ExportButton = styled.button`
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 12px 10px 12px;
  width: 70px;
  border-radius: 4px;
  border: none;
  background-color: #4C6EF5;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 0;

  &:hover {
    background-color: #3b5bdb;
  }
`;
