import { styled } from '@linaria/react';

export const StatusLabel = ({ color, label }: { color: string; label: string }) => {
  return <Label color={color}>{label}</Label>;
};

export const Label = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: '';
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${(props) => props.color};
  }
`;
