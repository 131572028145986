import { ChartDataset, Point } from 'chart.js';
import { useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { MeasureUnits, WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { getOrCreateTooltip } from '../../../components/costs-vs-output/costs-vs-output-tooltip';
import { Position } from '../../../components/costs-vs-output/costs-vs-output.types';
import { round } from '../../../helpers/math-helpers/math-helpers';
import { newCOLORS } from '../../../styles/colors';
import { calculateTrend } from '../measure-comparison/measure-comparison.helpers';
import { getUnitsLabel } from '../process-analysis.helpers';
import { SelectedWorkPeriod, SPRINT_COMPARISON_COLORS } from './sprint-comparison-chart.types';

interface SprintMeasuresOverTimeLineChartProps {
  measureData: Record<string, Record<string, Record<string, number | null>>>;
  selectedMeasure: string;
  selectedWorkPeriods: SelectedWorkPeriod[];
  selectedTrends: SelectedWorkPeriod[];
  workPeriods: WorkPeriod[];
  unitLabel: MeasureUnits | undefined;
  showAverage: boolean;
}

type ChartLine = ChartDataset<'line', (number | Point | null)[]>;

export const SprintMeasuresOverTimeLineChart = ({
  measureData,
  selectedMeasure,
  selectedWorkPeriods,
  selectedTrends,
  workPeriods,
  unitLabel,
  showAverage,
}: SprintMeasuresOverTimeLineChartProps) => {
  const navigate = useNavigate();
  const hideTooltipTimeoutRef = useRef<NodeJS.Timeout>();
  const chartRef = useRef<any>(null);

  // Cleanup tooltip and timeout on unmount
  useEffect(() => {
    return () => {
      if (hideTooltipTimeoutRef.current) {
        clearTimeout(hideTooltipTimeoutRef.current);
      }
      if (chartRef.current) {
        const tooltipEl = getOrCreateTooltip(chartRef.current);
        if (tooltipEl) {
          tooltipEl.remove();
        }
      }
    };
  }, []);

  const chartData = useMemo(() => {
    const datasets: ChartLine[] = [];

    // Add average line if enabled
    if (showAverage) {
      // Get max length of any dataset
      const maxLength = Math.max(
        ...workPeriods.map((wp) => {
          const sortedData = Object.entries(measureData[wp.id]?.[selectedMeasure] || {})
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce(
              (obj, [key, value]) => {
                obj[key] = value;
                return obj;
              },
              {} as Record<string, number | null>,
            );
          return Object.values(sortedData).length;
        }),
      );

      // Calculate average at each index position
      const averageData = Array(maxLength)
        .fill(null)
        .map((_, index) => {
          const valuesAtIndex: number[] = [];
          workPeriods.forEach((workPeriod) => {
            const sortedData = Object.entries(measureData[workPeriod.id]?.[selectedMeasure] || {})
              .sort(([a], [b]) => a.localeCompare(b))
              .reduce(
                (obj, [key, value]) => {
                  obj[key] = value;
                  return obj;
                },
                {} as Record<string, number | null>,
              );
            const values = Object.values(sortedData);
            const value = values[index];
            if (typeof value === 'number') {
              valuesAtIndex.push(value);
            }
          });

          return valuesAtIndex.length > 0
            ? valuesAtIndex.reduce((sum, val) => sum + val, 0) / valuesAtIndex.length
            : null;
        });

      if (averageData.some((v) => v !== null)) {
        datasets.push({
          label: 'Average',
          data: averageData,
          fill: false,
          borderColor: newCOLORS.darkGray2,
          backgroundColor: newCOLORS.darkGray2,
          borderDash: [5, 5],
          borderWidth: 3,
          tension: 0.1,
          pointRadius: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          spanGaps: true,
        });
      }
    }

    selectedWorkPeriods.forEach((workPeriod) => {
      const index = workPeriods.findIndex((wp) => wp.id === workPeriod.id);
      const color = SPRINT_COMPARISON_COLORS[index % SPRINT_COMPARISON_COLORS.length];
      const sortedData = Object.entries(measureData[workPeriod.id]?.[selectedMeasure] || {})
        .sort(([a], [b]) => a.localeCompare(b))
        .reduce(
          (obj, [key, value]) => {
            obj[key] = value;
            return obj;
          },
          {} as Record<string, number | null>,
        );
      const values = Object.values(sortedData);
      const data = values.map((v) => (typeof v === 'number' ? v : null));

      datasets.push({
        label: workPeriod.name,
        data,
        fill: false,
        borderColor: color,
        backgroundColor: color,
        tension: 0.1,
        spanGaps: true,
        borderDash: [],
        pointRadius: 3,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBackgroundColor: newCOLORS.white,
      });
    });

    // Add trend lines
    selectedTrends.forEach((workPeriod) => {
      const index = workPeriods.findIndex((wp) => wp.id === workPeriod.id);
      const color = SPRINT_COMPARISON_COLORS[index % SPRINT_COMPARISON_COLORS.length];
      const sortedData = Object.entries(measureData[workPeriod.id]?.[selectedMeasure] || {})
        .sort(([a], [b]) => a.localeCompare(b))
        .reduce(
          (obj, [key, value]) => {
            obj[key] = value;
            return obj;
          },
          {} as Record<string, number | null>,
        );
      const values = Object.values(sortedData);
      const data = values.map((v) => (typeof v === 'number' ? v : null));
      const validData = data.filter((v): v is number => typeof v === 'number');

      if (validData.length > 1) {
        const xAxis = Array.from({ length: validData.length }, (_, i) => i);
        const line = calculateTrend(
          xAxis.reduce((acc, x, i) => ({ ...acc, [x]: validData[i] }), {}),
          true,
        );

        if (line) {
          const { slope, intercept } = line;
          const trendData = xAxis.map((x) => slope * x + intercept);

          const fullTrendData = data.map((v) => (v === null ? null : trendData[validData.indexOf(v as number)]));

          datasets.push({
            label: `${workPeriod.name} (Trend)`,
            data: fullTrendData,
            borderColor: color,
            backgroundColor: color,
            borderDash: [5, 5],
            tension: 0,
            pointRadius: 0,
          });
        }
      }
    });

    return {
      labels: Array.from({ length: Math.max(...datasets.map((d) => d.data.length)) }, (_, i) => i + 1),
      datasets,
    };
  }, [measureData, selectedMeasure, selectedWorkPeriods, selectedTrends, workPeriods, showAverage]);

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      pointStyle: true,
      cubicInterpolationMode: 'monotone',
      aspectRatio: 2.5,
      layout: {
        padding: {
          top: 20,
          right: 0,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Sprint Day',
            font: {
              size: 16,
            },
          },
          ticks: {
            font: {
              size: 14,
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          position: 'left' as Position,
          title: {
            display: true,
            text: unitLabel,
            font: {
              size: 16,
            },
          },
          ticks: {
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          common: {
            drawTime: 'afterDraw',
          },
        },
        filler: {
          propagate: true,
          drawTime: 'beforeDatasetsDraw' as const,
        },
        tooltip: {
          enabled: false,
          external: (context: { chart: any; tooltip: any }) => {
            const { chart, tooltip } = context;
            chartRef.current = chart;
            const tooltipEl = getOrCreateTooltip(chart);

            if (!tooltipEl) return;

            if (hideTooltipTimeoutRef.current) {
              clearTimeout(hideTooltipTimeoutRef.current);
            }

            const activePointExists = !!chart.getActiveElements().length;

            if (tooltip.opacity === 0 || !activePointExists) {
              hideTooltipTimeoutRef.current = setTimeout(() => {
                if (tooltipEl) {
                  tooltipEl.style.opacity = '0';
                  tooltipEl.style.pointerEvents = 'none';
                }
              }, 3000);
              return;
            }

            if (!tooltip.body) return;

            const tableHead = document.createElement('thead');
            const titleRow = document.createElement('tr');
            titleRow.style.borderWidth = '0';

            const titleTH = document.createElement('td');
            titleTH.style.borderWidth = '0';
            titleTH.style.fontSize = '12px';
            titleTH.style.fontWeight = 'bold';

            const dataPoint = tooltip.dataPoints[0];
            const isTrend = dataPoint.dataset.borderDash && dataPoint.dataset.borderDash.length > 0;
            const text = document.createTextNode(
              isTrend ? `${dataPoint.dataset.label} (Trend)` : dataPoint.dataset.label,
            );

            titleTH.appendChild(text);
            titleRow.appendChild(titleTH);
            tableHead.appendChild(titleRow);

            const tableBody = document.createElement('tbody');
            const tr = document.createElement('tr');
            tr.style.borderWidth = '0';

            const td = document.createElement('td');
            td.style.borderWidth = '0';
            td.style.fontSize = '12px';

            const value = round(dataPoint.raw as number, 1);
            const linkAnchor = document.createElement('div');
            linkAnchor.style.display = 'inline';

            if (!isTrend && dataPoint.dataset.label !== 'Average') {
              const sprintDay = dataPoint.label;
              const workPeriod = selectedWorkPeriods.find((wp) => wp.name === dataPoint.dataset.label);
              const measureDataForPeriod = measureData[workPeriod?.id || '']?.[selectedMeasure];
              const date = measureDataForPeriod ? Object.keys(measureDataForPeriod)[sprintDay - 1] : undefined;
              if (workPeriod && date) {
                const linkUrl = `/application/process-analysis/tasks?measure=${selectedMeasure}&date=${date}&work_period_id=${workPeriod.id}`;
                linkAnchor.onclick = () => navigate(linkUrl);
                linkAnchor.setAttribute('role', 'button');
                linkAnchor.setAttribute('tabindex', '0');
                linkAnchor.setAttribute('aria-label', `View details for ${value}`);
                linkAnchor.style.color = newCOLORS.lightPurple;
                linkAnchor.style.cursor = 'pointer';
                linkAnchor.style.textDecoration = 'underline';
                linkAnchor.style.textDecorationStyle = 'dashed';
              }
            }

            linkAnchor.appendChild(document.createTextNode(value.toString()));
            td.appendChild(linkAnchor);
            td.appendChild(document.createTextNode(` ${unitLabel ? getUnitsLabel(unitLabel) : 'Units'}`));

            tr.appendChild(td);
            tableBody.appendChild(tr);

            const tableRoot = tooltipEl.querySelector('table');
            if (tableRoot) {
              while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
              }
              tableRoot.appendChild(tableHead);
              tableRoot.appendChild(tableBody);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
            const tooltipOffsetPadding = 10;

            tooltipEl.style.opacity = '1';
            tooltipEl.style.pointerEvents = 'auto';
            tooltipEl.style.left = tooltip.caretX + positionX - tooltipEl.offsetWidth / 2 - tooltipOffsetPadding + 'px';
            tooltipEl.style.top = tooltip.caretY + positionY - tooltipEl.offsetHeight + tooltipOffsetPadding + 'px';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding = '3px';
            tooltipEl.style.zIndex = '400';
          },
        },
      },
    }),
    [unitLabel, selectedMeasure, selectedWorkPeriods, navigate, measureData],
  );

  return <Line data={chartData} options={options} />;
};
