import { styled } from '@linaria/react';
import { icons } from '../../assets/icons/icons';
import { ChatHistory } from '../../containers/flora/flora.types';
import { newCOLORS } from '../../styles/colors';
import { H3, Text } from '../../ui-library/typography/typography';
import { ChatHistoryItem } from './chat-history-item';
import { groupHistoriesByDate } from './chat-history-panel.helpers';

type ChatHistoryPanelProps = {
  histories: ChatHistory[];
  closeHistory: () => void;
  setThreadId: React.Dispatch<React.SetStateAction<string>>;
  onDeleteHistory: (id: string) => void;
  onEditHistory: (id: string, newDescription: string) => void;
};

export const ChatHistoryPanel = ({
  histories,
  closeHistory,
  setThreadId,
  onDeleteHistory,
  onEditHistory,
}: ChatHistoryPanelProps) => {
  const groupedHistories = groupHistoriesByDate(histories);

  return (
    <ChatHistoryPanelContainer>
      <Header>
        <TitleSection>
          <img src={icons.iconFlora} />
          <H3 style={{ fontWeight: 1000, fontSize: 20, color: newCOLORS.black }}>Flora</H3>
          <Text style={{ fontSize: 10, color: newCOLORS.gray, marginTop: 4 }} textKey="common.ai.chat_history">
            Chat History
          </Text>
        </TitleSection>
      </Header>
      <HistoryList>
        {groupedHistories.map((group) => (
          <HistoryGroup key={group.label}>
            <GroupLabel>{group.label}</GroupLabel>
            {group.histories.map((history) => (
              <ChatHistoryItem
                key={history.id}
                history={history}
                onClick={() => {
                  setThreadId(history.id);
                  closeHistory();
                }}
                onDelete={onDeleteHistory}
                onEdit={onEditHistory}
              />
            ))}
          </HistoryGroup>
        ))}
      </HistoryList>
    </ChatHistoryPanelContainer>
  );
};

const ChatHistoryPanelContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 16px;
  border-top: 0px;
  border-bottom: 1px solid #e9ecef;
  background: white;
`;

const HistoryList = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 16px;
`;

const HistoryGroup = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const GroupLabel = styled(Text)`
  font-size: 15px;
  font-weight: 1000;
  color: ${newCOLORS.black};
  margin-left: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
    font-weight: normal;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
