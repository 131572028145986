import { Stack } from '@mantine/core';
import { downloadCSV } from '../../../../helpers/csv-download';
import { formatDate } from '../../../../helpers/timezone/timezone';
import { DateTemplate } from '../../../../helpers/timezone/timezone.type';
import { primaryBase, skyLight } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { TableColumn } from '../../../data-management/components/table';
import { RiskBadge } from '../risk-analysis';
import { RiskAnalysisInitiative } from '../risk-analysis.client.type';
import { getRiskCategory } from '../risk-analysis.helpers';
import { TruncatedText } from '../truncated-text';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
function CompletionBar({ percentage }: { percentage: number }) {
  return (
    <Stack gap={1}>
      <Text size="small" weight="medium">
        {percentage}%
      </Text>
      <div
        style={{
          width: '100%',
          height: '4px',
          backgroundColor: skyLight,
          borderRadius: '2px',
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            height: '100%',
            backgroundColor: primaryBase,
            borderRadius: '2px',
            transition: 'width 0.3s ease',
          }}
        />
      </div>
    </Stack>
  );
}

const getInitiativeTableColumns = (): TableColumn<RiskAnalysisInitiative>[] => {
  return [
    {
      key: 'name',
      label: 'Initiative Name',
      width: '20%',
      sortable: true,
      render: (_, initiative) => <Text weight="medium">{initiative.name}</Text>,
    },
    {
      key: 'description',
      label: 'Description',
      width: '20%',
      render: (_, initiative) => <TruncatedText text={initiative.description} onToggle={() => {}} />,
    },
    {
      key: 'completion_percentage',
      label: 'Completion',
      width: '12%',
      sortable: true,
      render: (_, initiative) => <CompletionBar percentage={initiative.completion_percentage} />,
    },
    {
      key: 'start_date',
      label: 'Start Date',
      width: '10%',
      sortable: true,
      render: (_, initiative) =>
        initiative.start_date ? formatDate(initiative.start_date, timezone, DateTemplate.MMDDYYYY) : 'No date',
    },
    {
      key: 'end_date',
      label: 'End Date',
      width: '10%',
      sortable: true,
      render: (_, initiative) =>
        initiative.end_date ? formatDate(initiative.end_date, timezone, DateTemplate.MMDDYYYY) : 'No date',
    },
    {
      key: 'flora_annotations.risk_category' as keyof RiskAnalysisInitiative,
      label: 'Risk Category',
      width: '13%',
      sortable: true,
      render: (_, initiative) => {
        const riskCategory = getRiskCategory(initiative.flora_annotations?.risk_score);
        return <RiskBadge color={riskCategory.color}>{riskCategory.label}</RiskBadge>;
      },
    },
    {
      key: 'flora_annotations.risk_score' as keyof RiskAnalysisInitiative,
      label: 'Risk Score',
      width: '10%',
      sortable: true,
      render: (_, initiative) => initiative.flora_annotations?.risk_score?.toFixed(0) ?? 'N/A',
    },
  ];
};

const handleDownloadInitiativeTableCSV = (initiatives: RiskAnalysisInitiative[]) => {
  try {
    const headers = [
      'Initiative Name',
      'Description',
      'Completion %',
      'Start Date',
      'End Date',
      'Risk Category',
      'Risk Score',
    ];

    const rows = initiatives.map((initiative) => [
      initiative.name,
      initiative.description,
      initiative.completion_percentage,
      initiative.start_date ? formatDate(initiative.start_date, timezone, DateTemplate.MMDDYYYY) : 'No date',
      initiative.end_date ? formatDate(initiative.end_date, timezone, DateTemplate.MMDDYYYY) : 'No date',
      getRiskCategory(initiative.flora_annotations?.risk_score).label,
      initiative.flora_annotations?.risk_score?.toFixed(0) ?? 'N/A',
    ]);

    downloadCSV([headers, ...rows], 'initiatives.csv');
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

export { CompletionBar, getInitiativeTableColumns, handleDownloadInitiativeTableCSV };
