import { useDisclosure } from '@mantine/hooks';
import { useEffect, useReducer, useState } from 'react';
import { useOrganizationStatusesByProjectsAndBoards } from '../../../../api/organization-client/organization-client.hooks';
import { Workflow } from '../../../../api/workflows-client/workflows-client.type';
import {
  useCreateWorkflow,
  useDeleteWorkflow,
  useGetWorkflows,
  useUpdateWorkflow,
} from '../../../../api/workflows-client/workflows.client.hooks';
import { useGlobalStore } from '../../../../store/global-store/global-store';
import { WorkflowAction, workflowReducer } from './shared-components/reducer';
import { WorkflowsContainer } from './shared-components/styles';
import { WorkflowsList } from './shared-components/workflows-list';
import { CreateWorkflow } from './workflow/create/create-workflow';
import { DeleteWorkflowModal } from './workflow/edit/delete-workflow-modal';
import { EditWorkflow } from './workflow/edit/edit-workflow';
import { ViewWorkflow } from './workflow/view/view-workflow';
import { getNewWorkflowName } from './workflows.helpers';

export function Workflows({ isDirty, setIsDirty }: { isDirty: boolean; setIsDirty: (isDirty: boolean) => void }) {
  const organization = useGlobalStore((state) => state.organization);

  const [pageState, setPageState] = useState<'update' | 'create' | 'idle'>('idle');
  const [deleteWorkflowModalOpened, { open: openDeleteWorkflowModal, close: closeDeleteWorkflowModal }] =
    useDisclosure(false);

  const [state, dispatch] = useReducer<React.Reducer<Workflow, WorkflowAction>>(workflowReducer, {} as Workflow);

  const { data: workflows, query: workflowsQuery } = useGetWorkflows(organization?.id || '');
  const { statusesByProjectsAndBoards, query: statusesByProjectsAndBoardsQuery } =
    useOrganizationStatusesByProjectsAndBoards(organization?.id || '');

  const { mutate: createWorkflow } = useCreateWorkflow({
    organizationId: organization?.id || '',
    onSuccess: () => {
      workflowsQuery.refetch();
      setPageState('idle');
    },
  });

  const { mutate: updateWorkflow } = useUpdateWorkflow({
    organizationId: organization?.id || '',
    onSuccess: () => {
      workflowsQuery.refetch();
      setPageState('idle');
    },
  });

  const { mutate: deleteWorkflow } = useDeleteWorkflow({
    organizationId: organization?.id || '',
    onSuccess: () => {
      workflowsQuery.refetch();
      setPageState('idle');
    },
  });

  useEffect(() => {
    if (pageState === 'idle') {
      setIsDirty(false);
    }
  }, [pageState, setIsDirty]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(state): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(pageState): ignore
  useEffect(() => {
    if (pageState === 'create' || pageState === 'update') {
      setIsDirty(true);
    }
  }, [state, setIsDirty]);

  // If the page is idle, select the first workflow if one is not already selected
  // biome-ignore lint/correctness/useExhaustiveDependencies(state.id): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(pageState): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(statusesByProjectsAndBoardsQuery.isSuccess): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(statusesByProjectsAndBoards): ignore
  useEffect(() => {
    if (
      pageState === 'idle' &&
      workflowsQuery.isSuccess &&
      workflows &&
      workflows.length &&
      statusesByProjectsAndBoardsQuery.isSuccess
    ) {
      if (state.id === null) {
        dispatch({
          type: 'SET_WORKFLOW',
          payload: workflows[0],
        });
      } else {
        dispatch({
          type: 'SET_WORKFLOW',
          payload: workflows.find((workflow) => workflow.id === state.id) ?? workflows[0],
        });
      }
    }
  }, [workflows, workflowsQuery.isSuccess, statusesByProjectsAndBoards, statusesByProjectsAndBoardsQuery.isSuccess]);

  return (
    <WorkflowsContainer>
      <WorkflowsList
        onCreate={
          pageState === 'idle'
            ? () => {
                dispatch({
                  type: 'SET_WORKFLOW',
                  payload: { organization: organization?.id || '' } as Workflow,
                });
                setPageState('create');
              }
            : undefined
        }
        selectedWorkflowId={state.id}
        onSelectWorkflow={(workflow) => {
          if (isDirty) {
            if (
              window.confirm(
                "This page is asking you to confirm that you want to leave - information you've entered may not be saved.",
              )
            ) {
              dispatch({
                type: 'SET_WORKFLOW',
                payload: workflow,
              });
              setPageState('idle');
            }
          } else {
            dispatch({
              type: 'SET_WORKFLOW',
              payload: workflow,
            });
            setPageState('idle');
          }
        }}
        workflows={(() => {
          if (pageState === 'create') {
            const newWorkflowName = getNewWorkflowName(workflows || []);
            return [...(workflows || []), { name: newWorkflowName }] as Workflow[];
          }
          return workflows || [];
        })()}
      />
      {pageState === 'idle' && (
        <ViewWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          workflowsQuery={workflowsQuery}
        />
      )}
      {pageState === 'update' && (
        <EditWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          isDirty={isDirty}
          handleSelectWorkflow={(workflow: Workflow) => dispatch({ type: 'SET_WORKFLOW', payload: workflow })}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          updateWorkflow={updateWorkflow}
          workflowsQuery={workflowsQuery}
          openDeleteWorkflowModal={openDeleteWorkflowModal}
        />
      )}
      {pageState === 'create' && (
        <CreateWorkflow
          state={state}
          dispatch={dispatch}
          setPageState={setPageState}
          isDirty={isDirty}
          handleSelectWorkflow={(workflow: Workflow) => dispatch({ type: 'SET_WORKFLOW', payload: workflow })}
          statusesByProjectsAndBoards={statusesByProjectsAndBoards}
          workflows={workflows || []}
          createWorkflow={(payload) => createWorkflow({ payload })}
        />
      )}
      <DeleteWorkflowModal
        opened={deleteWorkflowModalOpened}
        workflowName={state.name}
        onClose={closeDeleteWorkflowModal}
        onConfirm={() => {
          closeDeleteWorkflowModal();
          if (!state.id) {
            return;
          }
          deleteWorkflow(
            { workflowId: state.id },
            {
              onSuccess: () => {
                dispatch({
                  type: 'SET_WORKFLOW',
                  payload: {} as Workflow,
                });
                setPageState('idle');
                workflowsQuery.refetch();
              },
            },
          );
        }}
      />
    </WorkflowsContainer>
  );
}
