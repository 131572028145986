import React, { useMemo } from 'react';
import { Area, AreaChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Measure, MeasureMetadata, MeasureUnits } from '../../../../api/work-periods-client/work-periods-client.type';
import { TargetComparison } from '../../../adherence/targets/targets-client.type';
import { calculateTrend } from '../../../process-analysis/measure-comparison/measure-comparison.helpers';
import { SelectedTarget } from '../../../process-analysis/measure-comparison/measure-comparison.type';
import { getMeasureColor, getUnitsLabel } from '../../../process-analysis/process-analysis.helpers';
import {
  createChartData,
  getTargetFill,
  getTargetStrokeDashArray,
  getTrendLinePoints,
  getYAxisId,
} from './initiative-target.helpers';

export function InitiativeTargetChart({
  measureData,
  measureMetadata,
  selectedMeasures = [],
  selectedTrends = [],
  selectedTargets = [],
}: {
  measureData: (Record<string, Record<string, number | null>> | undefined)[];
  measureMetadata: MeasureMetadata[];
  selectedMeasures?: string[];
  selectedTrends?: string[];
  selectedTargets?: SelectedTarget[];
}) {
  const chartData = useMemo(() => createChartData(measureData, measureMetadata), [measureData, measureMetadata]);

  const measureMetadataMap = useMemo(() => {
    const metadataMap: Record<string, MeasureMetadata> = {};
    measureMetadata.forEach((metadata) => {
      metadataMap[metadata.measure_name] = metadata;
    });
    return metadataMap;
  }, [measureMetadata]);

  const measureNames = useMemo(() => {
    return selectedMeasures.length > 0 ? selectedMeasures : [];
  }, [selectedMeasures]);

  const measureUnitMap = useMemo(() => {
    const unitMap: Record<string, MeasureUnits> = {};
    measureMetadata.forEach((metadata) => {
      unitMap[metadata.measure_name] = metadata.measure_units;
    });
    return unitMap;
  }, [measureMetadata]);

  const isYAxisInUse = useMemo(() => {
    const percentageMeasures = Object.entries(measureUnitMap)
      .filter(([_, unit]) => unit === MeasureUnits.Percentage)
      .map(([name]) => name);

    return (
      selectedMeasures.some((name) => percentageMeasures.includes(name)) ||
      selectedTrends.some((name) => percentageMeasures.includes(name))
    );
  }, [selectedMeasures, selectedTrends, measureUnitMap]);

  const isY1AxisInUse = useMemo(() => {
    const nonPercentageMeasures = Object.entries(measureUnitMap)
      .filter(([_, unit]) => unit !== MeasureUnits.Percentage)
      .map(([name]) => name);

    return (
      selectedMeasures.some((name) => nonPercentageMeasures.includes(name)) ||
      selectedTrends.some((name) => nonPercentageMeasures.includes(name))
    );
  }, [selectedMeasures, selectedTrends, measureUnitMap]);

  const y1Label = useMemo(() => {
    const nonPercentageMeasures = Object.entries(measureUnitMap).filter(
      ([_, unit]) => unit !== MeasureUnits.Percentage,
    );

    const uniqueNonPercentageMeasureUnitsInUse = new Set(
      nonPercentageMeasures
        .filter(([name, _]) => selectedMeasures.concat(selectedTrends).includes(name))
        .map(([_, unit]) => unit),
    );

    return uniqueNonPercentageMeasureUnitsInUse.size !== 1
      ? 'Units'
      : Array.from(uniqueNonPercentageMeasureUnitsInUse)[0];
  }, [selectedMeasures, selectedTrends, measureUnitMap]);

  const trendLines = useMemo(() => {
    if (selectedTrends.length === 0 || chartData.length === 0) {
      return {};
    }

    const trends: Record<string, { slope: number; intercept: number }> = {};

    selectedTrends.forEach((measureName) => {
      const measureValues: Record<string, number> = {};
      chartData.forEach((dataPoint, index) => {
        if (dataPoint[measureName] !== null && dataPoint[measureName] !== undefined) {
          measureValues[index.toString()] = dataPoint[measureName];
        }
      });

      if (Object.keys(measureValues).length >= 2) {
        const measureMeta = measureMetadataMap[measureName];
        const trendLine = calculateTrend(measureValues, measureMeta?.is_zero_valid ?? true);

        if (trendLine) {
          trends[measureName] = {
            slope: trendLine.slope,
            intercept: trendLine.intercept,
          };
        }
      }
    });

    return trends;
  }, [chartData, selectedTrends, measureMetadataMap]);

  const targetValues = useMemo(() => {
    if (selectedTargets.length === 0) {
      return [];
    }

    const targets: { measureId: string; targetId: string; value: number; label: string }[] = [];

    selectedTargets.forEach((selectedTarget) => {
      const measure = measureMetadataMap[selectedTarget.measureId];
      if (measure?.targets) {
        const target = measure.targets.find((t) => t.id === selectedTarget.targetId);
        if (target) {
          targets.push({
            measureId: selectedTarget.measureId,
            targetId: selectedTarget.targetId,
            value: target.target_value,
            label: `${measure.measure_title || selectedTarget.measureId} Target: ${target.target_value}`,
          });
        }
      }
    });

    return targets;
  }, [selectedTargets, measureMetadataMap]);

  if (chartData.length === 0) {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <span style={{ color: '#999', fontSize: '14px' }}>No data available</span>
        </div>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 20, bottom: 40 }}>
        <XAxis dataKey="date" label={{ value: 'Date', position: 'bottom', offset: 0 }} tick={{ fontSize: 12 }} />

        <YAxis
          yAxisId="left"
          label={{
            value: '%',
            angle: -90,
            position: 'left',
            offset: 0,
            style: { fontSize: 12 },
          }}
          tick={{ fontSize: 12 }}
          hide={!isYAxisInUse}
        />

        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: typeof y1Label === 'string' ? y1Label : getUnitsLabel(y1Label),
            angle: 90,
            position: 'right',
            offset: 0,
            style: { fontSize: 12 },
          }}
          tick={{ fontSize: 12 }}
          hide={!isY1AxisInUse}
        />

        <Tooltip
          formatter={(value: any, name: string) => {
            if (name.endsWith('_trend')) {
              const baseName = name.replace('_trend', '');
              const measure = measureMetadataMap[baseName];
              return [value, `${measure?.measure_title || baseName} (Trend)`];
            }

            const measure = measureMetadataMap[name];
            const unit = measure?.measure_units ? ` ${getUnitsLabel(measure.measure_units)}` : '';
            return [`${value}${unit}`, `${measure?.measure_title || name}`];
          }}
        />

        {measureNames.map((measureName) => (
          <Area
            key={measureName}
            type="monotone"
            dataKey={measureName}
            name={measureMetadata.find((m) => m.measure_name === measureName)?.measure_title || measureName}
            stroke={getMeasureColor(measureName as Measure)}
            strokeWidth={2}
            activeDot={{ r: 8 }}
            connectNulls={true}
            yAxisId={getYAxisId(measureName, measureUnitMap)}
            fill="transparent"
            dot={true}
            isAnimationActive={false}
          />
        ))}

        {selectedTrends.map((measureName) => {
          const trendData = getTrendLinePoints(measureName, trendLines, chartData);
          if (!trendData) return null;

          return (
            <Line
              key={`${measureName}_trend`}
              type="monotone"
              data={trendData}
              dataKey={`${measureName}_trend`}
              name={`${measureMetadataMap[measureName]?.measure_title || measureName} (Trend)`}
              stroke={getMeasureColor(measureName as Measure)}
              strokeDasharray="5 5"
              dot={false}
              activeDot={false}
              isAnimationActive={false}
              yAxisId={getYAxisId(measureName, measureUnitMap)}
              fill="transparent"
            />
          );
        })}

        {targetValues.map((target, targetIndex) => {
          const measure = measureMetadataMap[target.measureId];
          const targetData = measure?.targets?.find((t) => t.id === target.targetId);

          if (!targetData) return null;

          const color = getMeasureColor(targetData.measure as Measure);
          const strokeDash = getTargetStrokeDashArray(targetData.target_comparison);
          const fillColor = getTargetFill(targetData.target_comparison, color);
          if (
            targetData.target_comparison === TargetComparison.RANGE_EX ||
            targetData.target_comparison === TargetComparison.RANGE_IN
          ) {
            return (
              <React.Fragment key={`target-${target.measureId}-${target.targetId}-${targetIndex}`}>
                <Area
                  type="monotone"
                  name={`Target ${measure.measure_title}`}
                  dataKey={`${target.measureId}_${target.targetId}`}
                  yAxisId={getYAxisId(target.measureId, measureUnitMap)}
                  fill={fillColor}
                  stroke={color}
                  strokeDasharray={strokeDash}
                  isAnimationActive={false}
                />
              </React.Fragment>
            );
          } else {
            const renderFillAbove =
              targetData.target_comparison === TargetComparison.GTE ||
              targetData.target_comparison === TargetComparison.GT;
            return (
              <React.Fragment key={`target-${target.measureId}-${target.targetId}-${targetIndex}`}>
                <Area
                  key={`${target.measureId}_${target.targetId}`}
                  type="monotone"
                  dataKey={`${target.measureId}_${target.targetId}`}
                  name={`Target ${measure.measure_title}`}
                  stroke={color}
                  fill={targetData.target_comparison === TargetComparison.EQ ? 'transparent' : fillColor}
                  strokeDasharray={strokeDash}
                  yAxisId={getYAxisId(target.measureId, measureUnitMap)}
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                  baseValue={renderFillAbove ? 'dataMax' : 'dataMin'}
                />
              </React.Fragment>
            );
          }
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}
