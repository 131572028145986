import { styled } from '@linaria/react';
import { ReactNode } from 'react';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';

export enum ComparisonTab {
  TABLE = 'table',
  MEASURE_COMPARISON = 'measure_comparison',
  MEASURES_OVER_TIME = 'measures_over_time',
}

interface ComparisonTabsProps {
  activeTab: ComparisonTab;
  onTabChange: (tab: ComparisonTab) => void;
  children: ReactNode;
}

export const ComparisonTabs = ({ activeTab, onTabChange, children }: ComparisonTabsProps) => {
  return (
    <ChartTabsContainer>
      <ChartTabsHeader>
        <StyledChartTab active={activeTab === ComparisonTab.TABLE} onClick={() => onTabChange(ComparisonTab.TABLE)}>
          <Icon name="table_chart" size={16} color={activeTab === ComparisonTab.TABLE ? baseWhite : inkLight} />
          <TabText>Table View</TabText>
        </StyledChartTab>
        <StyledChartTab
          active={activeTab === ComparisonTab.MEASURE_COMPARISON}
          onClick={() => onTabChange(ComparisonTab.MEASURE_COMPARISON)}
        >
          <Icon
            name="insights"
            size={16}
            color={activeTab === ComparisonTab.MEASURE_COMPARISON ? baseWhite : inkLight}
          />
          <TabText>Measure Comparison</TabText>
        </StyledChartTab>
        <StyledChartTab
          active={activeTab === ComparisonTab.MEASURES_OVER_TIME}
          onClick={() => onTabChange(ComparisonTab.MEASURES_OVER_TIME)}
        >
          <Icon
            name="trending_up"
            size={16}
            color={activeTab === ComparisonTab.MEASURES_OVER_TIME ? baseWhite : inkLight}
          />
          <TabText>Measures Over Time</TabText>
        </StyledChartTab>
      </ChartTabsHeader>

      <ChartTabContent>{children}</ChartTabContent>
    </ChartTabsContainer>
  );
};

const ChartTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const ChartTabsHeader = styled.div`
  display: flex;
  padding: 8px 16px;
  gap: 8px;
`;

const StyledChartTab = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${(props) => (props.active ? secondaryBase : 'transparent')};
  color: ${(props) => (props.active ? baseWhite : inkLight)};
  border: ${(props) => (props.active ? 'none' : `1px solid ${inkLight}`)};

  &:hover {
    background-color: ${(props) => (props.active ? secondaryBase : skyLightest)};
    border: ${(props) => (props.active ? 'none' : `1px solid ${secondaryBase}`)};
  }
`;

const TabText = styled.span`
  font-size: 14px;
`;

const ChartTabContent = styled.div`
  padding: 16px;
`;
