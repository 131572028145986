import { styled } from '@linaria/react';
import { Card, Text } from '@mantine/core';
import { HealthGauge } from '../../../../components/health-gauge/health-gauge';
import { newCOLORS } from '../../../../styles/colors';
import { useWorkPeriodsContext } from '../../work-periods.context';

export function HealthScoreSection() {
  const { scoreData } = useWorkPeriodsContext();

  // Show empty state if no data is available
  if (!scoreData) {
    return (
      <Card p="md" withBorder>
        <Text fw={500} size="lg" mb="xs">
          Health Score
        </Text>
        <Text color="dimmed">Select a work period or date range to view health score.</Text>
      </Card>
    );
  }

  // Safely access score_by_points with fallback
  const performanceScore = scoreData?.health_scores?.score_by_points || '0';

  return (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Health Score
        </Text>
        <Text size="sm" color="dimmed">
          Overall health of the work period
        </Text>
      </SectionHeader>

      <ScoreContainer>
        <HealthGauge
          heading="Performance Score"
          tooltip="Overall health score based on key process measures"
          health={parseInt(performanceScore)}
          showNumber={true}
          shouldAnimate={true}
          status="success"
        />
        <ScoreDescription>
          <Text fw={500} size="lg">
            Performance Score
          </Text>
          <Text size="sm" color="dimmed">
            Overall health of the work period based on key process measures
          </Text>
        </ScoreDescription>
      </ScoreContainer>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
`;

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid ${newCOLORS.lightGray};
`;

const ScoreDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
