import AxiosInstance from '../../../helpers/axios-instance/axios-instance';
import { GetAvailableTargetsPayload, GetAvailableTargetsResponse, Target, TargetPayload } from './targets-client.type';

const axiosInstance = AxiosInstance();
const targetsBaseURL = import.meta.env.VITE_APP_API_URL + '/api/targets/';

/**
 * Fetches targets based on provided filters
 *
 * @param {string} organizationId - The ID of the organization
 * @param {string} [portfolioId] - Optional portfolio ID filter
 * @param {string} [projectId] - Optional project ID filter
 * @param {string} [subprojectId] - Optional subproject ID filter
 * @returns {Promise<Target[]>} - A promise that resolves to an array of targets
 */
const fetchTargets = async (
  organizationId: string,
  portfolioId?: string,
  projectId?: string,
  subprojectId?: string,
): Promise<Target[]> => {
  let apiURL = targetsBaseURL + '?organization_id=' + organizationId;

  if (portfolioId) {
    apiURL += '&portfolio_id=' + portfolioId;
  }

  if (projectId) {
    apiURL += '&project_id=' + projectId;
  }

  if (subprojectId) {
    apiURL += '&subproject_id=' + subprojectId;
  }

  return await axiosInstance
    .get(apiURL)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching targets'));
};

/**
 * Creates a new target
 *
 * @param {Partial<TargetPayload>} target - The target data to create
 * @returns {Promise<Target>} - A promise that resolves to the created target
 */
const createTarget = async (target: Partial<TargetPayload>): Promise<Target> => {
  return await axiosInstance
    .post(targetsBaseURL, target)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while creating target'));
};

/**
 * Updates an existing target
 *
 * @param {Partial<TargetPayload>} target - The target data to update (must include id)
 * @returns {Promise<Target>} - A promise that resolves to the updated target
 */
const updateTarget = async (target: Partial<TargetPayload>): Promise<Target> => {
  return await axiosInstance
    .put(`${targetsBaseURL}${target.id}/`, target)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while updating target'));
};

/**
 * Deletes a target by ID
 *
 * @param {string} targetId - The ID of the target to delete
 * @returns {Promise<void>} - A promise that resolves when the target is deleted
 */
const deleteTarget = async (targetId: string): Promise<void> => {
  return await axiosInstance
    .delete(`${targetsBaseURL}${targetId}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while deleting target'));
};

/**
 * Fetches available targets based on provided payload
 *
 * @param {GetAvailableTargetsPayload} payload - The payload containing filter criteria
 * @returns {Promise<GetAvailableTargetsResponse>} - A promise that resolves to the available targets response
 */
const fetchAvailableTargets = async (payload: GetAvailableTargetsPayload): Promise<GetAvailableTargetsResponse> => {
  return await axiosInstance
    .get(targetsBaseURL + 'available_targets/', { params: payload })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching available targets'));
};

export { createTarget, deleteTarget, fetchAvailableTargets, fetchTargets, updateTarget };
