import {
  FlowByPhaseData,
  FlowByPhaseDataOverTime,
} from '../../../../../api/work-periods-client/work-periods-client.type';
import { Metric, PhaseChartDataPoint } from '../../../work-periods.type';

/**
 * Filters the flow data based on the specified metric (tasks or points).
 *
 * @param flowData - The original flow data with both tasks and points metrics
 * @param viewBy - The metric to filter by (Tasks or Points)
 * @returns A filtered version of the flow data containing only the specified metric
 */
function filterPhaseData(flowData: FlowByPhaseDataOverTime | undefined, viewBy: Metric): FlowByPhaseDataOverTime {
  const filteredData: FlowByPhaseDataOverTime = {};

  if (!flowData) return filteredData;

  for (const dateKey in flowData) {
    filteredData[dateKey] = {} as FlowByPhaseData;
    const sourceEntry = flowData[dateKey];
    const keys = Object.keys(sourceEntry) as Array<keyof FlowByPhaseData>;

    for (const key of keys) {
      const keyStr = key.toString();

      if (keyStr.startsWith(viewBy)) {
        filteredData[dateKey][key] = sourceEntry[key];
      }
    }
  }

  return filteredData;
}

/**
 * Transforms filtered flow data into the format required by Recharts for a cumulative flow diagram.
 * Removes 'tasks_' or 'points_' prefixes from keys for chart display.
 *
 * @param filteredData - The filtered flow data from generateCumulativeFlowData
 * @returns An array of data points ready for use in a Recharts AreaChart
 */
function transformForPhaseChart(filteredData: FlowByPhaseDataOverTime): PhaseChartDataPoint[] {
  // Convert the filtered data to an array of objects for recharts
  const result: PhaseChartDataPoint[] = [];

  // Convert the object of dates to an array of data points
  for (const date of Object.keys(filteredData)) {
    const dataPoint: PhaseChartDataPoint = {
      date,
    };

    // Iterate through each key in the date's entry
    const sourceEntry = filteredData[date];
    const keys = Object.keys(sourceEntry) as Array<keyof FlowByPhaseData>;

    for (const key of keys) {
      const keyStr = key.toString();
      // Remove 'tasks_' or 'points_' prefix from the key
      const cleanKey = keyStr.replace(/^(tasks_|points_)/, '');
      dataPoint[cleanKey] = sourceEntry[key];
    }

    result.push(dataPoint);
  }

  // Sort by date to ensure chronological order
  result.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return result;
}

/**
 * Calculates the percentage of items in each category for a specific date.
 * Each category value is converted to a percentage of the total across all categories.
 *
 * @param chartData - Array of chart data points
 * @param targetDate - The date to calculate percentages for
 * @returns An object with the same structure but values converted to percentages rounded to 1 decimal place
 */
function calculateCategoryPercentages(
  chartData: PhaseChartDataPoint[],
  targetDate: string,
): PhaseChartDataPoint | null {
  // Find the data point for the given date
  const dataPoint = chartData.find((point) => point.date === targetDate);

  if (!dataPoint) {
    return null;
  }

  // Create a new object to store percentages
  const percentages: PhaseChartDataPoint = { date: dataPoint.date };

  // Get all category keys (excluding the dateKey)
  const categoryKeys = Object.keys(dataPoint).filter((key) => key !== 'date');

  // Calculate the total value across all categories
  const total = categoryKeys.reduce((sum, key) => sum + (Number(dataPoint[key]) || 0), 0);

  // If total is 0, avoid division by zero
  if (total === 0) {
    categoryKeys.forEach((key) => {
      percentages[key] = 0;
    });
    return percentages;
  }

  // Calculate percentage for each category
  categoryKeys.forEach((key) => {
    const value = Number(dataPoint[key]) || 0;
    // Calculate percentage and round to 1 decimal place
    percentages[key] = Math.round((value / total) * 1000) / 10;
  });

  return percentages;
}

export { calculateCategoryPercentages, filterPhaseData, transformForPhaseChart };
