import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const baseDictionary = {
  en: {
    translation: {
      navigation: {
        dashboards: 'Dashboards',
        strategy: 'Strategy',
        financials: 'Financials',
        process: 'Process',
        process_analysis: 'Process Analysis',
        adherence: 'Adherence',
        integrations: 'Integrations',
        automation: 'Automation',
        work_periods: 'Work Periods',
      },
      common: {
        // Common terms used across multiple modules
        active: 'Active',
        inactive: 'Inactive',
        calibrated: 'Calibrated',
        work_units: 'Tasks',
        work_unit: 'Task',
        parent_task: 'Epic',
        estimation: 'Points',
        estimation_singular: 'Point',
        work_unit_details: 'Work Unit Details',
        name: 'Name',
        type: 'Type',
        created: 'Created',
        status_history: 'Status History',
        initiative: 'Initiative',
        bug: 'Bug',
        debt: 'Debt',
        teams: 'Teams',
        filters: 'Filters',
        last_updated: 'Last Updated',
        portfolio: 'Portfolio',
        portfolios: 'Portfolios',
        project: 'Project',
        projects: 'Projects',
        board: 'Board',
        boards: 'Boards',
        work_periods: 'Work Periods',
        assigned_boards: 'Assigned Boards',
        no_boards_selected: 'No boards selected',
        related_tasks: 'Related {{work_units}}',
        settings: 'Settings',
        integrations: {
          teams_select: 'Below Teams, select the team you would like to integrate.',
        },
        upload: {
          xlsx: 'Upload XLSX File',
        },
        actions: {
          add: 'Add',
          add_new: 'Add New',
          back: 'Back',
          edit: 'Edit',
          delete: 'Delete',
          download: 'Download',
          save: 'Save',
          search: 'Search',
          cancel: 'Cancel',
        },
        status: {
          status: 'Status',
          statuses: 'Statuses',
          done: 'Done',
          in_progress: 'In Progress',
          blocked: 'Blocked',
        },
        ai: {
          assistant: 'AI Assistant',
          chat_history: 'Chat History',
        },
        dashboard: {
          loading: 'Loading...',
          error_loading: 'Error loading data',
          create_widget_help: 'Easily create a new widget to tailor your dashboard to your needs.',
          create_title: 'Create New Dashboard',
          name_label: 'Dashboard Name',
          name_placeholder: 'Costs vs. Output Dashboard',
          delete_initiative: 'Delete Initiative',
          delete_initiative_message:
            'This will delete the {name} initiative from Bloomfilter. It does not impact any integrated source systems.',
          continue: 'Continue',
          cancel: 'Cancel',
          description: 'Description',
          description_placeholder:
            'Measures the average time taken by the QA team to respond to assigned work units or issues.',
          data_pool: 'Data Pool',
          data_pool_placeholder: 'Purple Portfolio',
          create_button: 'Create Dashboard',
        },
        measures: 'Measures',
        form: {
          email: 'Email',
          name: 'Name',
          password: 'Password',
          confirm_password: 'Confirm Password',
          create_account_form: 'Create Account Form',
          login_form: 'Login Form',
          forgot_password_form: 'Forgot Password Form',
          forgot_password: 'Forgot Your Password?',
          reset_password: 'Reset Password',
          remember_password: 'Remember your password?',
          login_to_bloomfilter: 'Log in to Bloomfilter',
          reset_help: "Don't worry! We'll help you reset it quickly.",
          enter_email: 'Enter the email associated with your Bloomfilter account.',
          create_organization: 'Create Organization',
          organization_name: 'Organization Name',
          organization_description:
            'Organizations are spaces where you can access and view data from your integrations.',
        },
        placeholders: {
          select_sprint: 'Select a Sprint',
          email: 'Enter your email address',
          name: 'Enter your full name',
          password: 'Enter your password',
          confirm_password: 'Confirm your password',
          organization: 'Enter your organization',
          select_category: 'Select category',
          select_team: 'Select Team',
          select_measure: 'Select measure',
          select_workflow: 'Select a workflow',
          pick_dates: 'Pick dates range',
          pick_year: 'Pick year',
        },
      },
      process_analysis: {
        workload_distribution: {
          capacity_work_units: 'Capacity ({{work_units}})',
          capacity_estimation: 'Capacity ({{estimation}})',
          workload_distribution: 'Workload Distribution',
          tooltips: {
            capacity: 'Total number of completed {{work_units}} over the time period',
            estimation: 'Total {{estimation}} completed over the time period',
            distribution: 'Distribution of work across teams',
          },
        },
        assessment: {
          phase: {
            ready: 'Ready',
            blocked: 'Blocked',
            wip: 'WIP',
            review: 'In Review',
            test: 'In Test',
            deployable: 'To Deploy',
            done: 'Done',
          },
          pace: {
            title: 'Work pace explorer',
          },
          burnt_title: 'Burnt',
          burnt_help: '% of work period elapsed',
          done_help: 'work finished',
          work_unit_process_map: 'Work Unit Process Map',
          considered_work_units: 'Considered {{work_units}}',
          scope_selected: 'for the scope selected',
        },
        initiative_completion: {
          title: 'Initiative Completion',
          initiatives: 'Initiatives',
          line: 'Line',
          trend: 'Trend',
        },
        initiative_focus: {
          title: 'Initiative Focus',
          initiatives: 'Initiatives',
          line: 'Line',
          trend: 'Trend',
        },
        key_measures: {
          readiness_label:
            'The number of work units which have a name, description, and {{estimation}}. Note: {{estimation}} intentionally set to 0 are considered estimated.',
          complexity_label: 'The average {{estimation}} of the work units in the scope selected.',
        },
        scope: {
          title: 'Scope',
          legend: 'Legend',
        },
      },
      strategy: {
        initiatives: {
          by_status: 'Initiatives by Statuses',
          completion: 'Overall Initiatives Completion',
          work_progress: 'Work Progress',
          strategic_progress: 'Strategic Progress',
        },
      },
      process: {
        section_graph: {
          title: '{{work_units}} Process Map',
        },
        scope: {
          task_type: '{{work_units}} Type',
          task_type_placeholder: 'Select a {{work_units}} type',
        },
        map: {
          considered_work_units: 'Considered {{work_units}}',
          scope_selected: 'for the scope selected',
          explanation: {
            what_title: 'What does this map do?',
            what_text:
              'The map details the number of work units (stories, work units, bugs, subtasks) that were completed for the selected time period and filters.',
            how_title: 'How is the map drawn?',
            how_text: 'Each major box matches the project status that was mapped to Bloomfilter during onboarding.',
            process_flow:
              'Lines connect each step vertically to complete the expected path. The thickness of each line visualizes the volume of completed work units that travelled that part of the process flow for the time period selected.',
            status_note:
              'Work unit transitions can cause discrepancy from their source system when one or more customer statuses are used on a work unit in a desired time period.',
            future_note:
              'In future iterations, Bloomfilter will make the Process Map available to the board/filter level, but at this time it is only available to analyze the Portfolio and Team (project) levels.',
          },
        },
      },
      financials: {
        details: '{{work_units}} Details',
        cost_per_task: 'Cost per {{work_units}}',
        cost: 'Cost',
        daily_spend: 'Daily Spend',
        tasks_committed: '{{work_units}} Committed',
        points_committed: '{{estimation}} Committed',
        velocity: 'Velocity',
        throughput: 'Throughput',
        tasks_completed: '{{work_units}} Completed',
        points_completed: '{{estimation}} Completed',
        cost_per_point: 'Cost per {{estimation_singular}}',
        related_work_units: 'Related {{work_units}}',
        no_work_units: 'There are no related {{work_units}} for this selection.',
        tooltips: {
          capacity: 'Total number of completed {{work_units}} over the time period',
          estimation: 'Total {{estimation}} completed over the time period',
          distribution: 'Distribution of work across teams',
        },
      },
      initiatives: {
        section_status: {
          no_end_date: 'The end date is generated because no end date was provided',
        },
        initiative_performance: {
          section_status: {
            cumulative_for_initiative: 'cumulative for {{initiative}}',
            total_tasks: 'Total {{metric}}',
            completed_tasks: 'Completed {{metric}}',
          },
        },
      },
      data_management: {
        data_management: 'Data Management',
        workflows: {
          workflows: 'Workflows',
          workflow: 'Workflow',
          assigned_boards: 'Assigned {{boards}}',
          project_board_name: '{{project}} / {{board}} Name',
          active_inactive_statuses: '{{active}} / {{inactive}} {{statuses}}',
          no_boards_selected: 'No {{boards}} selected',
          add_new_workflow: 'Add New Workflow',
          workflow_map: 'Workflow Map',
          mapped: 'Mapped',
          unmapped: 'Unmapped',
          search_and_add_boards: '{{search}} and {{add}} {{boards}}',
          active_statuses: '{{active}} {{statuses}}',
          add_boards_to_view_statuses: 'Add {{boards}} to view {{statuses}} here',
        },
        data_sources: {
          data_sources: 'Data Sources',
        },
        board_settings: {
          board_settings: '{{board}} {{settings}}',
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources: baseDictionary,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'loaded languageChanged',
    bindI18nStore: 'added',
    useSuspense: true,
  },
});

export default i18n;
