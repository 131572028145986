import { GraphData, ProcessMapping } from '../../api/process-client/process-client.type';

enum ViewType {
  Count = 'count',
  Percentage = 'percentage',
  CycleTime = 'cycle_time',
  Cost = 'cost',
}

enum ProcessViewMode {
  SINGLE = 'single',
  COMPARISON = 'comparison',
}

interface ProcessGraphProps {
  data: GraphData | undefined;
  mapping: ProcessMapping | null;
  activeStages: string[];
  viewType: ViewType;
}

interface ComparisonTeam {
  id: string;
  name: string;
}

interface ComparisonBoard {
  id: string;
  name: string;
  team_id: string;
}

export { ProcessViewMode, ViewType };
export type { ComparisonBoard, ComparisonTeam, ProcessGraphProps };
