import { useQuery } from '@tanstack/react-query';
import { format, startOfWeek, subMonths } from 'date-fns';
import { useMemo } from 'react';
import { getRiskAnalysisData } from './risk-analysis.client';
import { RiskAnalysisInitiative, RiskAnalysisPullRequest, RiskAnalysisTask } from './risk-analysis.client.type';
import { getRiskCategory } from './risk-analysis.helpers';

/**
 * Custom hook to fetch risk analysis data for a portfolio
 * @param portfolioId ID of the portfolio to fetch data for
 * @param key Key indicating which type of risk analysis data to fetch
 * @returns Object containing the fetched data and loading state
 * @template T Type of the returned data
 */
const useRiskAnalysisData = <T = unknown>(portfolioId: string, key: string) => {
  const { data, isFetching, error } = useQuery<T>({
    queryKey: ['risk-analysis-data', portfolioId, key],
    queryFn: () => getRiskAnalysisData(portfolioId, key),
    enabled: !!portfolioId,
  });

  return { data, isFetching, error };
};

/**
 * Custom hook to fetch risk analysis tasks for a portfolio
 * @param portfolioId ID of the portfolio to fetch tasks for
 * @returns Object containing the fetched task data and loading state
 */
const useRiskAnalysisTasks = (portfolioId: string): { data: RiskAnalysisTask[] | undefined; isFetching: boolean } => {
  return useRiskAnalysisData<RiskAnalysisTask[]>(portfolioId, 'tasks');
};

/**
 * Custom hook to fetch risk analysis initiatives for a portfolio
 * @param portfolioId ID of the portfolio to fetch initiatives for
 * @returns Object containing the fetched initiative data and loading state
 */
const useRiskAnalysisInitiatives = (
  portfolioId: string,
): { data: RiskAnalysisInitiative[] | undefined; isFetching: boolean } => {
  return useRiskAnalysisData<RiskAnalysisInitiative[]>(portfolioId, 'initiatives');
};

/**
 * Custom hook to fetch risk analysis epics for a portfolio
 * @param portfolioId ID of the portfolio to fetch epics for
 * @returns Object containing the fetched epic data and loading state
 */
const useRiskAnalysisEpics = (portfolioId: string): { data: RiskAnalysisTask[] | undefined; isFetching: boolean } => {
  return useRiskAnalysisData<RiskAnalysisTask[]>(portfolioId, 'epics');
};

const useRiskAnalysisPullRequests = (
  portfolioId: string,
): { data: RiskAnalysisPullRequest[] | undefined; isFetching: boolean } => {
  return useRiskAnalysisData<RiskAnalysisPullRequest[]>(portfolioId, 'change_requests');
};

/**
 * Custom hook to calculate weekly risk scores for a list of tasks
 * @param tasks List of tasks to calculate risk scores for
 * @returns Array of objects containing weekly risk scores
 */
const useWeeklyRiskScores = (tasks: RiskAnalysisTask[]): { date: Date; averageScore: number | null }[] => {
  return useMemo(() => {
    const weeklyScores: { [key: string]: number[] } = {};
    const today = new Date();
    const sixMonthsAgo = subMonths(today, 5);

    let currentDate = sixMonthsAgo;
    while (currentDate <= today) {
      const weekKey = format(startOfWeek(currentDate), 'yyyy-MM-dd');
      weeklyScores[weekKey] = [];
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    }

    tasks.forEach((task) => {
      if (!task.created_date) {
        return;
      }

      const taskDate = new Date(task.created_date);
      const weekStart = startOfWeek(taskDate);
      const weekKey = format(weekStart, 'yyyy-MM-dd');

      if (weeklyScores[weekKey]) {
        const riskScore = task.flora_annotations?.risk_score;
        if (riskScore !== undefined && riskScore !== null) {
          weeklyScores[weekKey].push(riskScore);
        }
      }
    });

    const averages = Object.entries(weeklyScores)
      .map(([weekKey, scores]) => ({
        date: new Date(weekKey),
        averageScore: scores.length > 0 ? scores.reduce((sum, score) => sum + score, 0) / scores.length : null,
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .filter((point) => point.averageScore !== null);

    return averages;
  }, [tasks]);
};

/**
 * Custom hook to fetch risk analysis task types for filtering
 * @param tasks List of tasks to fetch task types for
 * @returns Array of objects containing task type data
 */
const useRiskAnalysisTaskTypes = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(
    () =>
      Array.from(new Set((tasks ?? []).map((task) => task.type)))
        .sort()
        .map((type) => ({ value: type, label: type })),
    [tasks],
  );
};

/**
 * Custom hook to fetch risk analysis epics for filtering
 * @param tasks List of tasks to fetch epics for
 * @returns Array of objects containing epic data
 */
const useRiskAnalysisEpicsFilterOptions = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(
    () =>
      Array.from(new Set((tasks ?? []).map((task) => task.epic?.name)))
        .filter((epic): epic is string => !!epic)
        .sort()
        .map((epic) => ({ value: epic, label: epic })),
    [tasks],
  );
};

/**
 * Custom hook to fetch risk analysis creators for filtering
 * @param tasks List of tasks to fetch creators for
 * @returns Array of objects containing creator data
 */
const useRiskAnalysisCreators = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(
    () =>
      Array.from(new Set((tasks ?? []).map((task) => task.reporter?.name)))
        .filter((creator): creator is string => !!creator)
        .sort()
        .map((creator) => ({ value: creator, label: creator })),
    [tasks],
  );
};

/**
 * Custom hook to fetch risk analysis projects ffor filtering
 * @param tasks List of tasks to fetch projects for
 * @returns Array of objects containing project data
 */
const useRiskAnalysisProjectOptions = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(
    () =>
      Array.from(new Set((tasks ?? []).map((task) => task.project.name)))
        .sort()
        .map((project) => ({ value: project, label: project })),
    [tasks],
  );
};

/**
 * Custom hook to fetch risk analysis initiatives for filtering
 * @param tasks List of tasks to fetch initiatives for
 * @returns Array of objects containing initiative data
 */
const useRiskAnalysisInitiativesOptions = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(() => {
    const uniqueInitiatives = new Map();
    (tasks ?? []).forEach((task) => {
      (task.initiatives ?? []).forEach((initiative) => {
        uniqueInitiatives.set(initiative.id, initiative);
      });
    });

    return Array.from(uniqueInitiatives.values())
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((initiative) => ({
        value: initiative.id,
        label: initiative.name,
      }));
  }, [tasks]);
};

/**
 * Custom hook to get risk categories for filtering
 * @param tasks List of tasks to get risk categories from
 * @returns Array of objects containing risk category data
 */
const useRiskAnalysisRiskCategories = (tasks: RiskAnalysisTask[]): { value: string; label: string }[] => {
  return useMemo(() => {
    const categories = new Set(tasks.map((task) => getRiskCategory(task.flora_annotations?.risk_score).label));
    return Array.from(categories)
      .sort()
      .map((category) => ({
        value: category,
        label: category,
      }));
  }, [tasks]);
};

/**
 * Custom hook to get repository options from pull requests
 * @param pullRequests List of pull requests to get repositories from
 * @returns Array of objects containing repository data
 */
const useRiskAnalysisRepositoryOptions = (
  pullRequests: RiskAnalysisPullRequest[],
): { value: string; label: string }[] => {
  return useMemo(() => {
    const repositories = new Set(pullRequests.map((pr) => pr.repository.name));
    return Array.from(repositories)
      .sort()
      .map((repository) => ({
        value: repository,
        label: repository,
      }));
  }, [pullRequests]);
};

export {
  useRiskAnalysisCreators,
  useRiskAnalysisData,
  useRiskAnalysisEpics,
  useRiskAnalysisEpicsFilterOptions,
  useRiskAnalysisInitiatives,
  useRiskAnalysisInitiativesOptions,
  useRiskAnalysisProjectOptions,
  useRiskAnalysisPullRequests,
  useRiskAnalysisRepositoryOptions,
  useRiskAnalysisRiskCategories,
  useRiskAnalysisTasks,
  useRiskAnalysisTaskTypes,
  useWeeklyRiskScores,
};
