import { Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { secondaryBase } from '../../../styles/design-tokens';
import { Text } from '../../../ui-library/typography/typography';
import { TransformedTarget } from '../../adherence/targets/targets-client.type';
import { getTargetComparisonSymbol } from '../../adherence/targets/targets.helpers';
import { getProratedAverageTotals } from './widget.helpers';

interface TileWidgetProps {
  data: {
    average: number;
    total: number;
  };
  selectedTarget: TransformedTarget | null;
}

export const TileWidget: React.FC<TileWidgetProps> = ({ data, selectedTarget }) => {
  const { average_lower, average_upper, total_lower, total_upper } = useMemo(
    () =>
      selectedTarget
        ? getProratedAverageTotals(selectedTarget)
        : { average_lower: 0, average_upper: 0, total_lower: 0, total_upper: 0 },
    [selectedTarget],
  );

  return (
    <Stack gap="xs">
      <div>
        <Text size="large" weight="bold" color={secondaryBase}>
          {data.average.toLocaleString()}
        </Text>
        {selectedTarget && (
          <Text size="tiny">{`Target: ${getTargetComparisonSymbol(selectedTarget.target_comparison, average_lower, average_upper ?? null)}`}</Text>
        )}
      </div>
      <div>
        <Text size="small" weight="medium">
          Total: {data.total.toLocaleString()}
        </Text>
        {selectedTarget && (
          <Text size="tiny">{`Target: ${getTargetComparisonSymbol(selectedTarget.target_comparison, total_lower, total_upper ?? null)}`}</Text>
        )}
      </div>
    </Stack>
  );
};
