import { styled } from '@linaria/react';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, useEffect, useRef, useState } from 'react';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { skyDark } from '../../../styles/design-tokens';
import { Text } from '../../../ui-library/typography/typography';
import { TaskRecord as TasksTableTaskRecord } from '../../tasks-table/tasks-table.type';
import { ChartTab, ChartTabs } from '../components/chart-tabs';
import { WorkPeriodsFilter } from '../filter/work-periods-filter';
import { useTasksData } from '../hooks/task-details.hooks';
import { TaskSideSection } from '../task-detail/task-side-section';
import { useTaskSelection } from '../tasks.context';
import { useWorkPeriodsContext } from '../work-periods.context';
import { WorkPeriodType } from '../work-periods.type';
import { DeliverySection } from './sections/delivery-section';
import { HealthScoreSection } from './sections/health-score-section';
import { KeyMeasuresSection } from './sections/key-measures-section';
import { PaceSection } from './sections/pace-section';
import { PhaseSection } from './sections/phase-section';

// Main component now consumes the context
export function AssessmentView() {
  const {
    selectedWorkPeriodType,
    selectedWorkPeriods,
    dateRange,
    fetchWorkPeriods,
    selectedBoardId,
    formattedBoards,
    isLoading,
    isFetching,
  } = useWorkPeriodsContext();

  const { selection, closeSideSection, setTasks, setIsLoadingTasks } = useTaskSelection();

  const selectedWorkPeriod = selectedWorkPeriods.length === 1 ? selectedWorkPeriods[0] : null;
  const [activeChartTab, setActiveChartTab] = useState<ChartTab>(ChartTab.PHASE);
  const [isFilterPanelOpen, { toggle: toggleFilterPanel }] = useDisclosure(true);

  // Keep track of previous data to avoid infinite loops
  const prevDataRef = useRef<{
    tasks: any[] | null;
    isLoading: boolean | null;
    queryKey: string | null;
  }>({
    tasks: null,
    isLoading: null,
    queryKey: null,
  });

  // Check if we have data to show based on selection
  const hasWorkPeriodData =
    (selectedWorkPeriodType === WorkPeriodType.Defined && selectedWorkPeriod) ||
    (selectedWorkPeriodType === WorkPeriodType.Custom && dateRange.startDate && dateRange.endDate);

  // Only enable the query when we have a measure selected AND the side section is visible
  const queryEnabled = selection.sideSectionVisible && !!selection.selectedMeasure;

  // Use the unified query hook with the appropriate options
  const { data: tasksData, query: tasksQuery } = useTasksData(
    selection.selectedMeasure,
    selectedBoardId,
    selection.selectedStartDate,
    selection.selectedEndDate,
    {
      workPeriodId: selection.selectedWorkPeriodId,
      measureFilter: selection.selectedMeasureFilter,
    },
    queryEnabled, // Pass queryEnabled parameter to the hook
  );

  // Generate a stable string representation of the current query key
  const currentQueryKey = JSON.stringify({
    measure: selection.selectedMeasure,
    boardId: selectedBoardId,
    workPeriodId: selection.selectedWorkPeriodId,
    startDate: selection.selectedStartDate,
    endDate: selection.selectedEndDate,
    measureFilter: selection.selectedMeasureFilter,
  });

  // Effect to update tasks in context when query results change
  useEffect(() => {
    // Skip the effect if the tasks data hasn't actually changed,
    // or if the query key is the same
    if (
      !queryEnabled ||
      !tasksData ||
      (prevDataRef.current.tasks === tasksData &&
        prevDataRef.current.isLoading === tasksQuery.isLoading &&
        prevDataRef.current.queryKey === currentQueryKey)
    ) {
      return;
    }

    // Update the context state with new tasks data
    setTasks(tasksData);
    setIsLoadingTasks(tasksQuery.isLoading);

    // Update the ref to avoid further unnecessary updates
    prevDataRef.current = {
      tasks: tasksData,
      isLoading: tasksQuery.isLoading,
      queryKey: currentQueryKey,
    };
  }, [tasksData, tasksQuery, setTasks, setIsLoadingTasks, currentQueryKey, queryEnabled]);

  // Effect to load work periods when component mounts or board changes
  useEffect(() => {
    // Fetch work periods when component mounts
    if (!selectedBoardId && formattedBoards.length > 0) {
      // If no board is selected but we have boards, select the first one
      const firstBoardId = formattedBoards[0].value;
      fetchWorkPeriods(firstBoardId);
    } else if (selectedBoardId) {
      // If a board is already selected, fetch its work periods
      fetchWorkPeriods(selectedBoardId);
    }
  }, [fetchWorkPeriods, selectedBoardId, formattedBoards]);

  /**
   * Handles chart tab change
   */
  const handleTabChange = (tab: ChartTab) => {
    setActiveChartTab(tab);
  };

  // Get side section title based on selected measure
  const getSideSectionTitle = () => {
    if (!selection.selectedMeasure) return 'Task Details';
    return `${selection.selectedMeasure} Tasks`;
  };

  // Render the main section content
  const renderMainSection = () => {
    if (isLoading) {
      return (
        <Fragment>
          <BrandedLoadingOverlay visible={true} variant="colored" />
          <EmptyStateContainer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <Text weight="bold" size="large" color={skyDark}>
                Loading work periods...
              </Text>
            </div>
          </EmptyStateContainer>
        </Fragment>
      );
    }

    // Show key measures and chart tabs if a work period is selected or ad-hoc date range is provided
    if (hasWorkPeriodData) {
      return (
        <MainContentContainer>
          <BrandedLoadingOverlay visible={isFetching || false} variant="colored" />
          <KeyMeasuresContainer>
            <HealthScoreSection />
            <KeyMeasuresSection selectedWorkPeriod={selectedWorkPeriod} />
          </KeyMeasuresContainer>
          <ChartTabs activeTab={activeChartTab} onTabChange={handleTabChange}>
            {activeChartTab === ChartTab.PHASE && <PhaseSection selectedWorkPeriod={selectedWorkPeriod} />}
            {activeChartTab === ChartTab.PACE && <PaceSection selectedWorkPeriod={selectedWorkPeriod} />}
            {activeChartTab === ChartTab.DELIVERY && selectedWorkPeriod && (
              <DeliverySection selectedWorkPeriod={selectedWorkPeriod} />
            )}
          </ChartTabs>
        </MainContentContainer>
      );
    }

    // Default empty state
    return (
      <EmptyStateContainer>
        <Text weight="bold" size="large" color={skyDark}>
          Select a work period or provide a date range to view data
        </Text>
      </EmptyStateContainer>
    );
  };

  // Render the side filters with work period selection
  const renderSideFilters = () => {
    return (
      <SideFiltersContainer>
        <WorkPeriodsFilter />
      </SideFiltersContainer>
    );
  };

  return (
    <PageLayout
      controlBar={null}
      sideFilters={{
        content: renderSideFilters(),
        show: isFilterPanelOpen,
        setShow: toggleFilterPanel,
      }}
      mainSection={renderMainSection()}
      sideSection={
        selection.sideSectionVisible ? (
          <TaskSideSection
            categoryTitle={getSideSectionTitle()}
            tasks={selection.tasks as TasksTableTaskRecord[]}
            onClose={closeSideSection}
            isLoading={selection.isLoadingTasks}
          />
        ) : null
      }
      showSideSection={selection.sideSectionVisible}
    />
  );
}

// Styled components
const SideFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const KeyMeasuresContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
`;
