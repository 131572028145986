import { styled } from '@linaria/react';
import {
  Checkbox,
  Divider,
  Flex,
  Group,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseQueryOptions } from '@tanstack/react-query';
import React from 'react';
import { useInitiatives } from '../../../api/initiative-client/initiative-client.hooks';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { TimeAllocationType } from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { secondaryBase, skyBase } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Tooltip } from '../../../ui-library/tooltip/tooltip';
import { Text } from '../../../ui-library/typography/typography';
import {
  useAvailableObjects,
  useProcessedMeasures,
  useTargetAvailableMeasures,
  useTargetForm,
} from './target-modal.hooks';
import { Target, TargetComparison, TargetPayload } from './targets-client.type';
import { getTargetComparisonLabel, getTimeAllocationTypeOptions } from './targets.helpers';
import { OBJECT_TYPES } from './targets.types';

interface TargetModalProps {
  opened: boolean;
  onClose: () => void;
  onSave: (data: Partial<TargetPayload>) => void;
  initialData?: Partial<Target>;
  isCreating?: boolean;
  isLoading?: boolean;
}

export const TargetModal: React.FC<TargetModalProps> = ({
  opened,
  onClose,
  onSave,
  initialData,
  isCreating = false,
  isLoading = false,
}) => {
  const {
    step,
    setStep,
    formData,
    setFormData,
    formErrors,
    handleClose,
    handleSubmit,
    handleObjectTypeChange,
    isWorkPeriodTarget,
  } = useTargetForm(opened, initialData as Target, onSave, onClose);

  const portfolio = useGlobalStore((state) => state.portfolio);
  const { data: rawAvailableMeasuresData } = useTargetAvailableMeasures(isWorkPeriodTarget, formData);
  const availableMeasuresData = useProcessedMeasures(rawAvailableMeasuresData, isWorkPeriodTarget);
  const { initiatives = [] } = useInitiatives(portfolio?.id, {
    enabled: !!portfolio?.id,
  } as UseQueryOptions<Initiative[]>);
  const availableObjects = useAvailableObjects(formData.object_type, initiatives);
  const comparisonOptions = Object.values(TargetComparison).map((comparison) => ({
    value: comparison,
    label: getTargetComparisonLabel(comparison),
  }));

  const timeAllocationOptions = getTimeAllocationTypeOptions(formData.object_type);

  const renderStep1 = () => {
    return (
      <Stack gap="md">
        <Text size="regular">
          {isCreating ? 'Create a new target to track your metrics.' : 'Update your target settings below.'}
        </Text>
        <TextInput
          label="Target Name"
          value={formData.name || ''}
          onChange={(e) => setFormData({ ...formData, name: e.currentTarget.value })}
          placeholder="Sprint Velocity Target"
        />
        <Group grow>
          <Select
            comboboxProps={{ withinPortal: true, zIndex: 1000, position: 'top' }}
            label="Object Type"
            data={OBJECT_TYPES}
            value={formData.object_type}
            onChange={handleObjectTypeChange}
            placeholder="Select object type"
            error={formErrors.object_type}
            required
          />
          <MultiSelect
            label="Objects"
            data={availableObjects.map((object) => ({
              value: object.id,
              label: object.name,
            }))}
            value={formData.objects.map((object) => object.id)}
            onChange={(value) =>
              setFormData({
                ...formData,
                objects: value.map((id) => {
                  const foundObject = availableObjects.find((object) => object.id === id);
                  return {
                    id,
                    name: foundObject ? foundObject.name : '',
                    start_date: foundObject ? foundObject.start_date : undefined,
                    end_date: foundObject ? foundObject.end_date : undefined,
                  };
                }),
              })
            }
            placeholder="Select objects"
            radius="lg"
            disabled={!formData.object_type}
            error={formErrors.objects}
            searchable
            comboboxProps={{
              withinPortal: true,
              zIndex: 1000,
              position: 'top',
            }}
          />
        </Group>
        {formData.object_type === 'board' && (
          <Tooltip
            variant="black"
            label="If this is a sprint target, this target will be applied to all sprints in the selected board."
            multiline
            w={250}
            zIndex={1000}
          >
            <Checkbox
              label="Is Sprint Target"
              checked={formData.is_work_period_target}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_work_period_target: e.currentTarget.checked,
                  measure: '',
                })
              }
            />
          </Tooltip>
        )}
        {formData.objects.length > 0 && (
          <Group justify="space-between" grow>
            <Select
              label="Measure"
              data={availableMeasuresData?.map((measure) => ({
                value: measure.measure_name,
                label: measure.measure_title,
              }))}
              value={formData.measure}
              onChange={(value) => setFormData({ ...formData, measure: value as string })}
              placeholder="Select measure"
              radius="xl"
              required
              error={formErrors.measure}
              disabled={!formData.object_type}
              comboboxProps={{ withinPortal: true, position: 'top', zIndex: 1000 }}
            />
          </Group>
        )}
      </Stack>
    );
  };

  const renderStep2 = () => {
    return (
      <Stack gap="md">
        {formData.object_type === 'initiative' &&
          formData.objects.map((object) => (
            <Group align="bottom" gap="xs" key={object.id}>
              <Text size="small" weight="medium">
                {object.name}:
              </Text>
              <Text size="small">
                {new Date(object.start_date ?? '').toLocaleDateString()} -{' '}
                {new Date(object.end_date ?? '').toLocaleDateString()}
              </Text>
            </Group>
          ))}

        <Tooltip
          variant="black"
          label="A prorated target will adjust based on the selected frequency. If turned off, the target value will be diplayed as is."
          multiline
          w={250}
          zIndex={1000}
          position="left"
        >
          <div>
            <Switch
              label="Prorate Target"
              checked={formData.use_transform}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  use_transform: e.currentTarget.checked,
                  time_allocation_type: null,
                })
              }
              color={secondaryBase}
            />
          </div>
        </Tooltip>

        {!isWorkPeriodTarget && (
          <Select
            comboboxProps={{ withinPortal: true, zIndex: 1000, position: 'bottom' }}
            label="Target Frequency"
            value={formData.time_allocation_type}
            onChange={(value) =>
              value && setFormData({ ...formData, time_allocation_type: value as TimeAllocationType | null })
            }
            placeholder="Select target frequency"
            data={timeAllocationOptions}
            required
            error={formErrors.time_allocation_type}
            disabled={formData.is_work_period_target || !formData.use_transform}
          />
        )}

        <Group grow>
          <Select
            comboboxProps={{ withinPortal: true, zIndex: 1000, position: 'top' }}
            maxDropdownHeight={150}
            label="Comparison Type"
            value={formData.target_comparison}
            onChange={(value) => value && setFormData({ ...formData, target_comparison: value as TargetComparison })}
            data={comparisonOptions}
            required
          />
          <NumberInput
            label="Target Value"
            placeholder="Enter target value"
            value={formData.target_value}
            onChange={(value) => setFormData({ ...formData, target_value: Number(value) })}
            required
            error={formErrors.target_value}
          />
          {(formData.target_comparison === TargetComparison.RANGE_IN ||
            formData.target_comparison === TargetComparison.RANGE_EX) && (
            <NumberInput
              label="Upper Target Value"
              placeholder="Enter upper target value"
              value={formData.target_value_upper ?? undefined}
              onChange={(value) => setFormData({ ...formData, target_value_upper: Number(value) })}
              required
              error={formErrors.target_value_upper}
              min={formData.target_value || 0}
            />
          )}
        </Group>
        <Group justify="space-between" grow>
          <DatePickerInput
            required
            radius="xl"
            label="Start Date"
            placeholder="Select start date"
            leftSection={<Icon name="calendar_month" color={secondaryBase} size={16} />}
            leftSectionPointerEvents="none"
            value={formData.start_date ? new Date(formData.start_date) : undefined}
            maxDate={formData.end_date ? new Date(formData.end_date) : undefined}
            onChange={(value) => setFormData({ ...formData, start_date: value?.toISOString() ?? '' })}
            pointer
            miw="fit-content"
            popoverProps={{ withinPortal: true, zIndex: 1001, position: 'top' }}
          />
          <DatePickerInput
            radius="xl"
            label="End Date"
            placeholder="Select end date"
            leftSection={<Icon name="calendar_month" color={secondaryBase} size={16} />}
            leftSectionPointerEvents="none"
            value={formData.end_date ? new Date(formData.end_date) : undefined}
            minDate={formData.start_date ? new Date(formData.start_date) : undefined}
            onChange={(value) => setFormData({ ...formData, end_date: value?.toISOString() ?? null })}
            pointer
            miw="fit-content"
            clearable
            defaultValue={
              formData.object_type === 'initiative' ? new Date(formData.objects?.[0]?.end_date ?? '') : undefined
            }
            popoverProps={{ withinPortal: true, zIndex: 1001, position: 'top' }}
          />
        </Group>
      </Stack>
    );
  };
  return (
    <TargetModalStyled
      opened={opened}
      onClose={handleClose}
      title={
        <Stack gap="xs" style={{ marginTop: step === 2 ? '16px' : '0px' }}>
          <Flex align="center" gap="sm">
            <Text weight="bold" size="large">
              {isCreating ? 'Create New Target' : 'Edit Target'}
            </Text>
            <Text size="large" weight="medium" color={skyBase}>
              (Step {step} of 2)
            </Text>
          </Flex>
          {step === 2 && (
            <Text size="medium" weight="medium">
              {formData.name}
            </Text>
          )}
        </Stack>
      }
      size="lg"
      centered
    >
      <form onSubmit={handleSubmit}>
        {step === 1 ? renderStep1() : renderStep2()}
        <Divider style={{ marginTop: '32px' }} />
        <Flex justify="space-between" mt="xl">
          <div>
            {step === 2 && (
              <Button
                variant="outline"
                radius="xl"
                onClick={() => setStep(1)}
                leftSection={<Icon name="arrow_back" size={16} color={skyBase} />}
              >
                Back
              </Button>
            )}
          </div>
          <Flex gap="md">
            <Button variant="outline" radius="xl" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              radius="xl"
              variant="secondary"
              rightSection={<Icon name={step === 1 ? 'arrow_forward' : 'check_circle'} size={16} color="white" />}
              loading={isLoading}
              disabled={isLoading}
            >
              {step === 1 ? 'Next Step' : isCreating ? 'Create Target' : 'Save Changes'}
            </Button>
          </Flex>
        </Flex>
      </form>
    </TargetModalStyled>
  );
};

const TargetModalStyled = styled(Modal)`
  .mantine-Modal-content {
    border-radius: 12px;
    background: white;
    border: 1px solid #e9ecef;
  }

  .mantine-Modal-header {
    padding: 12px 24px 0 24px;
  }

  .mantine-Modal-body {
    padding: 24px;
  }

  .mantine-Modal-close {
    color: #868e96;
    &:hover {
      background-color: #f1f3f5;
    }
  }

  .mantine-TextInput-input,
  .mantine-Select-input,
  .mantine-NumberInput-input {
    border-radius: 16px;
    transition: border-color 0.2s ease;
    z-index: 1000;

    &:focus {
      border-color: ${secondaryBase};
    }
  }
`;
