import { styled } from '@linaria/react';
import { Checkbox, Divider, Select, Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { MeasureMetadata, WorkPeriod } from '../../../api/work-periods-client/work-periods-client.type';
import { DownloadPNGButton } from '../../../components/download-png-button';
import { canvasHtmlDownload } from '../../../helpers/image-downloader/image-downloader';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { newCOLORS } from '../../../styles/colors';
import { StickyTH } from '../initiative-sections/initiative-completion';
import { handleChangeEntity, handleChangeTrend } from '../initiative-sections/initiative-sections.helpers';
import { calculateAverage, calculateTrend, forecastNextValue } from '../measure-comparison/measure-comparison.helpers';
import { MeasureRow } from '../measure-comparison/measure-row';
import { SelectedWorkPeriod, SPRINT_COMPARISON_COLORS } from './sprint-comparison-chart.types';
import { SprintMeasuresOverTimeLineChart } from './sprint-comparison-over-time-line-chart';

interface SprintMeasuresOverTimeChartProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
}

export const SprintMeasuresOverTimeChart = ({
  customMeasures,
  measureData,
  workPeriods,
}: SprintMeasuresOverTimeChartProps) => {
  const defaultMeasure = customMeasures.length > 0 ? customMeasures[0].measure_name : 'sprint_throughput';
  const downloadRef = useRef<HTMLDivElement>(null);

  const selectedSprintMeasure = useProcessAnalysisStore((state) => state.selectedSprintMeasure);
  const setSelectedSprintMeasure = useProcessAnalysisStore((state) => state.setSelectedSprintMeasure);

  const [showAverage, setShowAverage] = useState(false);
  const [selectedWorkPeriods, setSelectedWorkPeriods] = useState<SelectedWorkPeriod[]>(
    workPeriods.map((wp) => ({ id: wp.id, name: wp.name })),
  );
  const [selectedTrends, setSelectedTrends] = useState<SelectedWorkPeriod[]>([]);

  // Initialize selected measure if not set
  useEffect(() => {
    if (!selectedSprintMeasure && customMeasures.length > 0) {
      setSelectedSprintMeasure(defaultMeasure);
    }
  }, [selectedSprintMeasure, customMeasures, defaultMeasure, setSelectedSprintMeasure]);

  const handleDownload = () => {
    if (downloadRef.current) {
      try {
        const measureTitle =
          customMeasures.find((m) => m.measure_name === selectedSprintMeasure)?.measure_title || 'Sprint Comparison';
        canvasHtmlDownload(`${measureTitle}_comparison`, downloadRef);
      } catch (error) {
        console.error('Error downloading chart', error);
      }
    }
  };

  const handleMeasureChange = (value: string | null) => {
    setSelectedSprintMeasure(value || defaultMeasure);
  };

  if (!selectedSprintMeasure) {
    return null;
  }

  return (
    <DropShadowContainer ref={downloadRef}>
      <TitleContainer>
        <Title order={2}>Sprint Measures Over Time</Title>
        <DownloadPNGButton handleDownload={handleDownload} exceptionRef={downloadRef} />
      </TitleContainer>
      <ContentContainer>
        <LegendContainer>
          <Select
            data={customMeasures.map((m) => ({ value: m.measure_name, label: m.measure_title }))}
            value={selectedSprintMeasure}
            onChange={handleMeasureChange}
          />
          <Divider size="sm" style={{ marginTop: '16px', marginBottom: '16px' }} />
          <AverageCheckboxContainer>
            <Checkbox
              label="Show Average Line"
              checked={showAverage}
              onChange={(event) => setShowAverage(event.currentTarget.checked)}
              styles={{
                label: {
                  color: newCOLORS.black,
                  fontWeight: 500,
                },
              }}
            />
          </AverageCheckboxContainer>
          <Divider size="sm" style={{ marginTop: '16px', marginBottom: '16px' }} />
          <LegendScrollContainer>
            <LegendTable>
              <thead>
                <tr>
                  <StickyTH>Sprint</StickyTH>
                  <StickyTH>Line</StickyTH>
                  <StickyTH>Trend</StickyTH>
                </tr>
              </thead>
              <tbody>
                {workPeriods.map((workPeriod, index) => {
                  const workPeriodData = measureData[workPeriod.id]?.[selectedSprintMeasure];
                  const color = SPRINT_COMPARISON_COLORS[index % SPRINT_COMPARISON_COLORS.length];
                  const measureMetadata = customMeasures.find((m) => m.measure_name === selectedSprintMeasure);
                  const trendLine = workPeriodData
                    ? calculateTrend(workPeriodData, measureMetadata?.is_zero_valid ?? true)
                    : null;
                  const forecast = workPeriodData ? forecastNextValue(trendLine, workPeriodData) : null;
                  return (
                    <MeasureRow
                      key={workPeriod.id}
                      title={workPeriod.name}
                      measure={workPeriod.id}
                      color={color}
                      isZeroValid={measureMetadata?.is_zero_valid ?? true}
                      average={
                        workPeriodData ? calculateAverage(workPeriodData, measureMetadata?.is_zero_valid ?? true) : null
                      }
                      trend={trendLine?.slope ?? null}
                      forecast={forecast}
                      selectedMeasures={selectedWorkPeriods.map((wp) => wp.id)}
                      selectedTrends={selectedTrends.map((wp) => wp.id)}
                      setSelectedMeasures={(event) => {
                        const { checked } = event.currentTarget;
                        handleChangeEntity(
                          checked,
                          { id: workPeriod.id, name: workPeriod.name },
                          selectedWorkPeriods,
                          setSelectedWorkPeriods,
                        );
                      }}
                      setSelectedTrends={(event) => {
                        const { checked } = event.currentTarget;
                        handleChangeTrend(
                          checked,
                          { id: workPeriod.id, name: workPeriod.name },
                          selectedTrends,
                          setSelectedTrends,
                        );
                      }}
                    />
                  );
                })}
              </tbody>
            </LegendTable>
          </LegendScrollContainer>
        </LegendContainer>
        <ChartContainer>
          <SprintMeasuresOverTimeLineChart
            measureData={measureData}
            selectedMeasure={selectedSprintMeasure}
            selectedWorkPeriods={selectedWorkPeriods}
            selectedTrends={selectedTrends}
            workPeriods={workPeriods}
            unitLabel={customMeasures.find((m) => m.measure_name === selectedSprintMeasure)?.measure_units}
            showAverage={showAverage}
          />
        </ChartContainer>
      </ContentContainer>
    </DropShadowContainer>
  );
};

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
`;

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 330px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 300px;
  table-layout: fixed;

  th:first-child {
    width: 200px;
  }

  th:not(:first-child) {
    width: 40px;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
`;

const AverageCheckboxContainer = styled.div`
  padding: 0 8px;
`;
