import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Flex, Select } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { useWorkPeriodsMeasureSingular } from '../../../../api/work-periods-client/work-periods-client.hooks';
import {
  FlowByPhaseDataOverTime,
  Measure,
  TimeAllocationType,
} from '../../../../api/work-periods-client/work-periods-client.type';
import { newCOLORS } from '../../../../styles/colors';
import { CollapsibleSection } from '../collapsible-section';
import { InitiativeMetricType } from '../initiative-performance.type';
import { InitiativePhaseChart } from './initiative-phase-chart';
import { getDateOptions, getEpicOptions } from './section-phase.helpers';

type Props = {
  initiative: ExtendedInitiative;
};

export function SectionPhase({ initiative }: Props) {
  const [metric, setMetric] = useState<InitiativeMetricType>(InitiativeMetricType.Tasks);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedEpic, setSelectedEpic] = useState<string | null>(null);

  const { data } = useWorkPeriodsMeasureSingular<{ flow_by_phase: FlowByPhaseDataOverTime }>({
    payload: {
      start_date: dayjs(initiative.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(initiative.end_date).format('YYYY-MM-DD'),
      initiative_id: selectedEpic ? undefined : initiative.id,
      measure_name: [Measure.FlowByPhase],
      time_allocation_type: TimeAllocationType.BiWeekly,
      epic_id: selectedEpic || undefined,
    },
  });

  // Reset selected date when epic changes or data changes
  // biome-ignore lint/correctness/useExhaustiveDependencies(selectedEpic): ignore
  // biome-ignore lint/correctness/useExhaustiveDependencies(data): ignore
  useEffect(() => {
    setSelectedDate(null);
  }, [selectedEpic, data]);

  // Set initial selected date to the latest date with actual data
  useEffect(() => {
    if (data?.flow_by_phase && !selectedDate) {
      try {
        const dates = Object.entries(data.flow_by_phase)
          .filter(([_, values]) => {
            // Check if any phase has a non-null value greater than 0
            if (!values || typeof values !== 'object') return false;

            return Object.entries(values).some(([_, value]) => {
              return value !== null && typeof value === 'number' && value > 0;
            });
          })
          .map(([date]) => date)
          .sort((a, b) => dayjs(b).diff(dayjs(a)));

        if (dates.length > 0) {
          setSelectedDate(dates[0]);
        }
      } catch (error) {
        console.error('Error setting initial date:', error);
      }
    }
  }, [data?.flow_by_phase, selectedDate]);

  const handleEpicChange = (value: string | null) => {
    setSelectedEpic(value);
  };

  const controls = (
    <Flex gap="md" align="center">
      <SelectContainer>
        <SelectLabel>Epic</SelectLabel>
        <Select
          value={selectedEpic}
          onChange={handleEpicChange}
          data={[{ value: '', label: 'All Epics' }, ...getEpicOptions(initiative)]}
          placeholder="Select epic"
          size="xs"
          style={{ width: 200 }}
          clearable
        />
      </SelectContainer>
      <SelectContainer>
        <SelectLabel>Date</SelectLabel>
        <Select
          value={selectedDate}
          onChange={setSelectedDate}
          data={data?.flow_by_phase ? getDateOptions(data.flow_by_phase) : []}
          placeholder="Select date"
          size="xs"
          style={{ width: 150 }}
        />
      </SelectContainer>
    </Flex>
  );

  return (
    <CollapsibleSection
      titleElement={<Title />}
      title="Phase"
      metric={metric}
      setMetric={setMetric}
      additionalControls={controls}
    >
      <div style={{ width: '100%', minHeight: '600px' }}>
        {data && data.flow_by_phase && Object.keys(data.flow_by_phase).length > 0 ? (
          <InitiativePhaseChart
            initiative={initiative}
            data={data}
            metric={metric === InitiativeMetricType.Tasks ? 'tasks' : 'points'}
            selectedDate={selectedDate}
          />
        ) : (
          <NoDataMessage>
            <Icon icon="material-symbols:info-outline" width={24} height={24} color={newCOLORS.darkGray} />
            <span>No Flow Data Found</span>
          </NoDataMessage>
        )}
      </div>
    </CollapsibleSection>
  );
}

const Title = () => {
  return (
    <TitleContainer>
      <Icon icon="material-symbols:airwave" width={20} height={20} color={newCOLORS.darkYellow} />
      <TitleText>Initiative - Flow By Phase</TitleText>
    </TitleContainer>
  );
};

const TitleText = styled.div`
  font-size: 16px;
  font-weight: 1000;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectLabel = styled.div`
  color: #666;
  font-size: 13px;
  font-weight: 400;
`;

const NoDataMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
`;
