import { styled } from '@linaria/react';
import { memo, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getStraightPath } from 'reactflow';
import { Stages, WorkflowStatus } from '../../../api/process-client/process-client.type';
import { newCOLORS } from '../../../styles/colors';
import { ProcessContext } from '../context/process.context';
import { getAnimatedDotRadius } from '../helpers/edges.helpers';
import { getSearchParams } from '../process.helpers';
import { useProcessScope } from '../process.hooks';

const StageEdge = memo(
  ({ data, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }: EdgeProps) => {
    const navigate = useNavigate();
    const { team, board, taskType, epic, startDate, endDate } = useProcessScope();
    const { setWorkflow } = useContext(ProcessContext);

    const [edgePath, labelX, labelY] = useMemo(
      () =>
        getStraightPath({
          sourceX,
          sourceY: sourceY - 5,
          targetX,
          targetY: targetY + 3,
        }),
      [sourceX, sourceY, targetX, targetY],
    );

    // Only render the animated dot if we have a valid path and value
    const showAnimatedDot = data.value > 0 && edgePath;

    // Calculate relative path for animation starting from 0,0
    const relativePath = useMemo(() => {
      if (!edgePath) {
        return '';
      }

      const [endX, endY] = [targetX - sourceX, targetY + 3 - (sourceY - 5)];

      return `M0,0 L${endX},${endY}`;
    }, [sourceX, sourceY, targetX, targetY, edgePath]);

    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
        {showAnimatedDot && (
          <g transform={`translate(${sourceX},${sourceY - 5})`}>
            <circle r={getAnimatedDotRadius(style.strokeWidth)} fill={style.color}>
              <animateMotion dur="2s" repeatCount="indefinite" path={relativePath} begin={`${Math.random() * 2}s`} />
            </circle>
          </g>
        )}
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(10%, -50%) translate(${labelX + 30}px,${labelY}px)`,
              pointerEvents: 'all',
              cursor: 'pointer',
            }}
            className="nodrag nopan"
            onClick={() => {
              const workflow = {
                source: data.sourceStage,
                destination: data.destinationStage,
                status: WorkflowStatus.Expected,
                mapping: data.mapping,
              };

              setWorkflow(workflow);

              const params = getSearchParams(
                workflow,
                team?.id || null,
                board?.id || null,
                taskType,
                epic?.id || null,
                startDate,
                endDate,
              );

              if (params) {
                navigate({
                  pathname: `/application/process/task-details`,
                  search: `?${params}`,
                });
              }
            }}
          >
            <EdgeLabel data={data} />
          </div>
        </EdgeLabelRenderer>
      </>
    );
  },
  (prev, next) => {
    // Custom comparison to prevent unnecessary rerenders
    return (
      prev.sourceX === next.sourceX &&
      prev.sourceY === next.sourceY &&
      prev.targetX === next.targetX &&
      prev.targetY === next.targetY &&
      prev.data.value === next.data.value &&
      prev.style?.stroke === next.style?.stroke &&
      prev.style?.strokeWidth === next.style?.strokeWidth
    );
  },
);

const EdgeLabel = ({ data }: { data: EdgeProps['data'] }) => {
  const borderColor = data.sourceStage === Stages.Blocked ? newCOLORS.purple2 : newCOLORS.black2;
  const bgColor = data.sourceStage === Stages.Blocked ? newCOLORS.white : newCOLORS.black2;
  const textColor = data.sourceStage === Stages.Blocked ? newCOLORS.purple2 : newCOLORS.white;

  return (
    <Label borderColor={borderColor} bgColor={bgColor} textColor={textColor}>
      <Text>{data.value}</Text>
    </Label>
  );
};

const Label = styled.div<{ borderColor: string; bgColor: string; textColor: string }>`
  width: 60px;
  height: 30px;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;

const Text = styled.div`
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

export { EdgeLabel, StageEdge };
