import { styled } from '@linaria/react';
import { Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ProgressMeasure } from '../../../../components/progress-bar/progress-bar-measure';
import { newCOLORS } from '../../../../styles/colors';
import { useTaskSelection } from '../../tasks.context';
import { useWorkPeriodsContext } from '../../work-periods.context';
import { Metric, WorkPeriod, WorkPeriodType } from '../../work-periods.type';
import {
  filterPaceData,
  getScopeMetadata,
  getTasksCompleteMetadata,
  getWorkPeriodBurnedMetadata,
  transformForPaceChart,
} from './helpers/pace-section.helpers';
// Define pace status enum to match process analysis
enum PaceStatus {
  OnTrack = 'On Track',
  AtRisk = 'At Risk',
  OffTrack = 'Off Track',
}

export function PaceSection({ selectedWorkPeriod }: { selectedWorkPeriod: WorkPeriod | null }) {
  const { selectedWorkPeriods, dateRange, selectedWorkPeriodType, flowData } = useWorkPeriodsContext();
  const { selectMeasureByDateRange, selectMeasureByWorkPeriod } = useTaskSelection();
  const [metric, setMetric] = useState<Metric>(Metric.Tasks);

  // Add state for the currently hovered date
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);

  // Determine if we have a selected work period or date range
  const hasSelection =
    (selectedWorkPeriodType === WorkPeriodType.Defined &&
      Array.isArray(selectedWorkPeriods) &&
      selectedWorkPeriods.length > 0) ||
    (selectedWorkPeriodType === WorkPeriodType.Custom && dateRange?.startDate && dateRange?.endDate);

  const filteredData = filterPaceData(flowData?.flow_by_pace, metric);
  const chartData = transformForPaceChart(filteredData);

  const dates = Object.keys(flowData?.flow_by_phase || {});
  const latestDateFromChartData =
    dates.length > 0 ? dates.sort((a, b) => dayjs(a).unix() - dayjs(b).unix()).pop() : null;

  // Initialize hoveredDate to latestDate when component mounts or latestDate changes
  useEffect(() => {
    if (latestDateFromChartData) {
      // Normalize with dayjs to ensure consistent format
      setHoveredDate(dayjs(latestDateFromChartData).format('YYYY-MM-DD'));
    }
  }, [latestDateFromChartData]);

  // Use hoveredDate if available, otherwise fall back to latestDateFromChartData
  const currentDateToDisplay =
    hoveredDate || (latestDateFromChartData ? dayjs(latestDateFromChartData).format('YYYY-MM-DD') : null);

  // Hover handler for the chart
  const handleChartMouseMove = (data: any) => {
    if (data && data.activeLabel) {
      const normalizedDate = dayjs(data.activeLabel).format('YYYY-MM-DD');
      setHoveredDate(normalizedDate);
    }
  };

  const handleMeasureClick = (forDate: string, measureFilter: string, selectedWorkPeriodId?: string) => {
    const startDate = dayjs(forDate).startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs(forDate).endOf('day').format('YYYY-MM-DD');
    if (selectedWorkPeriodId) {
      // If we have a work period, use it
      selectMeasureByWorkPeriod('flow_by_pace', selectedWorkPeriodId, startDate, endDate, measureFilter);
    } else {
      // Otherwise use the end date
      selectMeasureByDateRange('flow_by_pace', startDate, endDate, measureFilter);
    }
  };

  return hasSelection ? (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Work Pace
        </Text>
        <Group>
          <TabsGroup>
            <TabButton active={metric === Metric.Tasks} onClick={() => setMetric(Metric.Tasks)}>
              Tasks
            </TabButton>
            <TabButton active={metric === Metric.Points} onClick={() => setMetric(Metric.Points)}>
              Points
            </TabButton>
          </TabsGroup>
          <ExportButton>Export</ExportButton>
        </Group>
      </SectionHeader>

      <MainContentContainer>
        <SideMetricsContainer>
          <ProgressMeasure
            title="Done"
            percent={getTasksCompleteMetadata(chartData, currentDateToDisplay, metric).percent}
            icon="mdi:check-circle"
            color="#40C057"
            helpText={getTasksCompleteMetadata(chartData, currentDateToDisplay, metric).helpText}
          />
          <ProgressMeasure
            title="Burnt"
            percent={getWorkPeriodBurnedMetadata(chartData, currentDateToDisplay).percent}
            icon="mdi:fire"
            color="#4C6EF5"
            helpText={getWorkPeriodBurnedMetadata(chartData, currentDateToDisplay).helpText}
          />
          <ProgressMeasure
            title="Scope"
            percent={getScopeMetadata(chartData, currentDateToDisplay, metric).percent}
            icon="mdi:flag-checkered"
            color="#312e81"
            helpText={getScopeMetadata(chartData, currentDateToDisplay, metric).helpText}
          />
        </SideMetricsContainer>

        <ChartContainer>
          <Text fw={500} size="lg" mb="md">
            Flow of Work
          </Text>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={chartData}
                onMouseMove={handleChartMouseMove}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={(value) => dayjs(value).format('MMM D')} tick={{ fontSize: 12 }} />
                <YAxis
                  tick={{ fontSize: 12 }}
                  label={{
                    value: metric === Metric.Tasks ? 'Tasks Remaining' : 'Points Remaining',
                    angle: -90,
                    position: 'insideLeft',
                    style: { textAnchor: 'middle' },
                  }}
                />
                <Tooltip
                  formatter={(value, name) => {
                    if (name === 'actual' || name === 'ideal') {
                      return [`${value} ${metric === Metric.Tasks ? 'tasks' : 'points'}`, name];
                    }
                    return [value, name];
                  }}
                  labelFormatter={(label) => `Date: ${dayjs(label).locale('en').format('MMM D, YYYY')}`}
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      if (!data) return null;
                      const statusText =
                        data.status === PaceStatus.OnTrack
                          ? 'On Track'
                          : data.status === PaceStatus.AtRisk
                            ? 'At Risk'
                            : 'Off Track';

                      return (
                        <TooltipContainer>
                          <TooltipHeader>{`Date: ${dayjs(label).locale('en').format('MMM D, YYYY')}`}</TooltipHeader>
                          <TooltipRow>
                            <TooltipLabel>Total:</TooltipLabel>
                            <TooltipValue>{`${data.total} ${metric === Metric.Tasks ? 'tasks' : 'points'}`}</TooltipValue>
                          </TooltipRow>
                          <TooltipRow>
                            <TooltipLabel>Actual:</TooltipLabel>
                            <TooltipValue>{`${data.complete} ${metric === Metric.Tasks ? 'tasks' : 'points'}`}</TooltipValue>
                          </TooltipRow>
                          <TooltipRow>
                            <TooltipLabel>Ideal:</TooltipLabel>
                            <TooltipValue>{`${data.ideal} ${metric === Metric.Tasks ? 'tasks' : 'points'}`}</TooltipValue>
                          </TooltipRow>
                          <TooltipRow>
                            <TooltipLabel>Status:</TooltipLabel>
                            <TooltipStatus color={data.statusColor}>{statusText}</TooltipStatus>
                          </TooltipRow>
                        </TooltipContainer>
                      );
                    }
                    return null;
                  }}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="complete"
                  name="Actual"
                  stroke="#4C6EF5"
                  strokeWidth={2}
                  dot={(props: any) => {
                    const { cx, cy, payload } = props;
                    return <circle cx={cx} cy={cy} r={5} fill={payload.statusColor} stroke="#4C6EF5" strokeWidth={2} />;
                  }}
                  activeDot={(props: any) => {
                    const { cx, cy, payload } = props;
                    return (
                      <circle
                        cx={cx}
                        cy={cy}
                        r={7}
                        fill={payload.statusColor}
                        stroke="#4C6EF5"
                        strokeWidth={2}
                        cursor="pointer"
                        onClick={() => hoveredDate && handleMeasureClick(hoveredDate, 'done', selectedWorkPeriod?.id)}
                      />
                    );
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="ideal"
                  name="Ideal"
                  stroke="#40C057"
                  strokeWidth={2}
                  strokeDasharray="5 5"
                  dot={false}
                />
                <Line type="monotone" dataKey="total" name="Total" stroke="#312e81" strokeWidth={2} dot={false} />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <EmptyChartMessage>
              <Text>No flow data available for the selected work period.</Text>
              <Text size="sm" color="dimmed" mt="xs">
                This could be due to insufficient data in the selected time period.
              </Text>
            </EmptyChartMessage>
          )}
          <StatusLegend>
            <StatusItem>
              <StatusColor color="#40C057" />
              <StatusText>On Track</StatusText>
            </StatusItem>
            <StatusItem>
              <StatusColor color="#FCC419" />
              <StatusText>At Risk</StatusText>
            </StatusItem>
            <StatusItem>
              <StatusColor color="#FA5252" />
              <StatusText>Off Track</StatusText>
            </StatusItem>
          </StatusLegend>
        </ChartContainer>
      </MainContentContainer>

      <SummaryContainer>
        <SummaryCard>
          <SummaryTitle>Burnt</SummaryTitle>
          <SummaryValue>{getWorkPeriodBurnedMetadata(chartData, currentDateToDisplay).percent}%</SummaryValue>
          <SummaryDescription>Time elapsed in the work period</SummaryDescription>
        </SummaryCard>

        <SummaryCard
          $clickable
          onClick={() => hoveredDate && handleMeasureClick(hoveredDate, 'done', selectedWorkPeriod?.id)}
        >
          <SummaryTitle>Done</SummaryTitle>
          <SummaryValue>{getTasksCompleteMetadata(chartData, currentDateToDisplay, metric).percent}%</SummaryValue>
          <SummaryDescription>Work completed in the work period</SummaryDescription>
        </SummaryCard>

        <SummaryCard>
          <SummaryTitle>Scope</SummaryTitle>
          <SummaryValue>{getScopeMetadata(chartData, currentDateToDisplay, metric).percent}%</SummaryValue>
          <SummaryDescription>Scope change since the start of the work period</SummaryDescription>
        </SummaryCard>
      </SummaryContainer>
    </SectionContainer>
  ) : (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Work Pace
        </Text>
      </SectionHeader>
      <Text>Select a work period or date range to view pace data.</Text>
    </SectionContainer>
  );
}

// Styled components
const SectionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabsGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TabButton = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => (props.active ? '#4C6EF5' : '#f0f0f0')};
  color: ${(props) => (props.active ? 'white' : '#333')};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.active ? '#4C6EF5' : '#e0e0e0')};
  }
`;

const MainContentContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const SideMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 250px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
`;

const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TooltipHeader = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 1px solid #eee;
  padding-bottom: 4px;
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const TooltipLabel = styled.span`
  font-weight: 500;
  margin-right: 8px;
`;

const TooltipValue = styled.span`
  font-weight: normal;
`;

const TooltipStatus = styled.span<{ color: string }>`
  font-weight: bold;
  color: ${(props) => props.color};
`;

const StatusLegend = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
`;

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${newCOLORS.lighterGray};
  }
`;

const StatusColor = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

const StatusText = styled.span`
  font-size: 14px;
  color: #333;
`;

const ExportButton = styled.button`
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 12px 10px 12px;
  width: 70px;
  border-radius: 4px;
  border: none;
  background-color: #4C6EF5;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 0;

  &:hover {
    background-color: #3b5bdb;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const SummaryCard = styled.div<{ $color?: string; $clickable?: boolean }>`
  flex: 1;
  background-color: ${newCOLORS.lighterGray};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;
  border: 2px solid ${(props) => (props.$clickable && props.$color ? props.$color : newCOLORS.lightGray)};
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  transition: all 0.2s ease-in-out;
  
  &:hover {
    border-color: ${(props) => (props.$clickable && props.$color ? props.$color : newCOLORS.lightGray)};
    box-shadow: ${(props) => (props.$clickable ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none')};
    transform: ${(props) => (props.$clickable ? 'translateY(-2px)' : 'none')};
  }
`;

const SummaryTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const SummaryValue = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const SummaryDescription = styled.div`
  font-size: 14px;
  color: ${newCOLORS.gray};
`;

const EmptyChartMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: ${newCOLORS.lightGray};
  border-radius: 4px;
  padding: 16px;
  text-align: center;
`;
