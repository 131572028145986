import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { MinifiedTask } from '../tasks-client/task-client.type';
import {
  AvailableMeasuresResponse,
  GetAvailableMeasuresPayload,
  GetTimeAllocationsPayload,
  GetWorkPeriodsMeasurePayload,
  GetWorkPeriodsPayload,
  MeasureDetail,
  TimeAllocation,
  WorkPeriod,
} from './work-periods-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/work-periods/';

/**
 * Fetches a list of available measures for the given scope and entity.
 *
 * @param {GetAvailableMeasuresPayload} payload - The payload that is passed to the API call.
 * @param {string} payload.scope - The scope of the available measures.
 * @param {string} [payload.portfolio_id] - The ID of the portfolio.
 * @param {string} [payload.project_id] - The ID of the project.
 * @param {string} [payload.subproject_id] - The ID of the subproject.
 * @param {string} [payload.work_period_id] - The ID of the work period.
 * @return {Promise<AvailableMeasuresResponse>} - A promise that resolves to the list of available measures.
 */
const getAvailableMeasures = async ({
  scope,
  portfolio_id,
  project_id,
  subproject_id,
  work_period_id,
  organization_id,
}: GetAvailableMeasuresPayload): Promise<AvailableMeasuresResponse> => {
  const params = { scope, portfolio_id, project_id, subproject_id, work_period_id, organization_id };

  return await axiosInstance
    .get(`${baseURL}available-measures/`, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching available measures'));
};

/**
 * Fetches time allocations based on the provided parameters.
 *
 * @param {GetTimeAllocationsPayload} params - The parameters for fetching time allocations.
 * @param {string} params.start_date - The start date for the time allocations.
 * @param {string} params.end_date - The end date for the time allocations.
 * @param {TimeAllocationType} params.time_allocation_type - The time allocation type.
 * @return {Promise<TimeAllocation[]>} - A promise that resolves to an array of time allocations.
 */
const getTimeAllocations = async ({
  start_date,
  end_date,
  time_allocation_type,
}: GetTimeAllocationsPayload): Promise<TimeAllocation[]> => {
  const params = { start_date, end_date, time_allocation_type };

  return await axiosInstance
    .get(`${baseURL}time-allocations/`, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching time allocations'));
};

/**
 * Fetches an array of work periods based on the provided parameters.
 *
 * @param {GetWorkPeriodsPayload} params - The parameters for fetching work periods.
 * @param {string} params.subproject_id - The ID of the subproject.
 * @param {string} [params.parent] - The ID of the parent work period.
 * @param {string} [params.external_id] - The external ID of the work period.
 * @param {string} [params.time_span] - The time span for the work periods.
 * @param {string} [params.time_tense] - The time tense for the work periods.
 * @return {Promise<WorkPeriod[]>} - A promise that resolves to an array of work periods.
 */
const getWorkPeriods = async ({
  subproject_id,
  parent,
  external_id,
  time_span,
  time_tense,
}: GetWorkPeriodsPayload): Promise<WorkPeriod[]> => {
  const params = { subproject_id, parent, external_id, time_span, time_tense };

  return await axiosInstance
    .get(baseURL, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching work periods'));
};

/**
 * Fetches a work period measure based on the provided parameters.
 *
 * @param {GetWorkPeriodsMeasurePayload} work_period_id - The ID of the work period.
 * @param {string} subproject_id - The ID of the subproject.
 * @param {string} project_id - The ID of the project.
 * @param {string} portfolio_id - The ID of the portfolio.
 * @param {string} initiative_id - The ID of the initiative.
 * @param {string} epic_id - The ID of the epic.
 * @param {string} start_date - The start date for the measure.
 * @param {string} end_date - The end date for the measure.
 * @param {string} measure_name - The name of the measure.
 * @param {string} time_allocation_type - The time allocation type.
 * @param {string} transformer - The transformer for the measure.
 * @param {boolean} force_recalculation - Flag to force recalculation.
 * @param {AbortSignal} signal - The signal to abort the request.
 * @param {boolean} is_custom - Optional flag to indicate if the measure is custom.
 * @return {Promise<T>} A promise that resolves to the fetched work period measure data.
 */
const getWorkPeriodsMeasure = async <T>({
  work_period_id,
  subproject_id,
  project_id,
  portfolio_id,
  initiative_id,
  epic_id,
  start_date,
  end_date,
  measure_name,
  time_allocation_type,
  transformer,
  force_recalculation,
  signal,
  is_custom,
  module_type,
}: GetWorkPeriodsMeasurePayload & { signal?: AbortSignal }): Promise<T> => {
  const params = {
    work_period_id,
    subproject_id,
    project_id,
    portfolio_id,
    initiative_id,
    start_date,
    end_date,
    measure_name: measure_name.join(','),
    time_allocation_type,
    transformer,
    force_recalculation,
    epic_id,
    module_type,
  };

  const url = is_custom ? `${baseURL}custom-measure/` : `${baseURL}measure/`;

  return await axiosInstance
    .get(url, { params, signal })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching work periods measure'));
};

/**
 * Fetches a work period calculation tasks based on the provided parameters.
 *
 * @param {string} [payload.work_period_id] - The ID of the work period.
 * @param {string} payload.subproject_id - The ID of the subproject.
 * @param {string} [payload.project_id] - The ID of the project.
 * @param {string} [payload.portfolio_id] - The ID of the portfolio.
 * @param {string} [payload.initiative_id] - The ID of the initiative.
 * @param {string} [payload.epic_id] - The ID of the epic.
 * @param {string} payload.start_date - The start date for fetching tasks.
 * @param {string} payload.end_date - The end date for fetching tasks.
 * @param {string} payload.measure_name - The names of the measures to fetch tasks for.
 * @param {string} [payload.time_allocation_type] - The time allocation type.
 * @param {string} [payload.transformer] - The transformer for the measure.
 * @param {boolean} [payload.force_recalculation] - Flag to force recalculation.
 * @param {boolean | null} [payload.is_custom] - Flag to indicate if the measure is custom.
 * @param {string | null} [payload.module_type] - The module type.
 * @return {Promise<MinifiedTask[]>} A promise that resolves to the fetched work period tasks data.
 * @throws {Error} If there's an error while fetching work period tasks.
 */

const getWorkPeriodsTasks = async ({
  work_period_id,
  subproject_id,
  project_id,
  portfolio_id,
  initiative_id,
  epic_id,
  start_date,
  end_date,
  measure_name,
  time_allocation_type,
  transformer,
  force_recalculation,
  measure_filter,
  is_custom,
  module_type,
}: GetWorkPeriodsMeasurePayload): Promise<MinifiedTask[]> => {
  const params = {
    work_period_id,
    subproject_id,
    project_id,
    portfolio_id,
    initiative_id,
    epic_id,
    start_date,
    end_date,
    measure_name: measure_name.join(','),
    time_allocation_type,
    transformer,
    force_recalculation,
    measure_filter,
    module_type,
  };

  const url = is_custom ? `${baseURL}custom-tasks/` : `${baseURL}tasks/`;

  return await axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching work periods measure'));
};

/**
 * Fetches detailed information about a specific measure.
 *
 * @param {string} measureName - The name of the measure to get details for
 * @return {Promise<MeasureDetail>} A promise that resolves to the measure details
 */
const getMeasureDetail = async (measureName: string): Promise<MeasureDetail> => {
  return await axiosInstance
    .get(`${baseURL}measure/${measureName}/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching measure details'));
};

export {
  getAvailableMeasures,
  getMeasureDetail,
  getTimeAllocations,
  getWorkPeriods,
  getWorkPeriodsMeasure,
  getWorkPeriodsTasks,
};
