import { VeltComments, VeltProvider } from '@veltdev/react';

export function VeltProviderWrapper({ children }: { children: React.ReactNode }) {
  const apiKey = import.meta.env.VITE_VELT_API_KEY;

  return (
    <VeltProvider apiKey={apiKey}>
      <VeltComments />
      {children}
    </VeltProvider>
  );
}
