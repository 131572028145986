import { ExtendedInitiative, Initiative, KPIOverviewData } from '../../api/initiative-client/initiative-client.type';
import { Epic } from '../../api/tasks-client/task-client.type';
import {
  PerformanceTab,
  ThroughputMode,
} from '../../containers/strategy-v2/initiative-performance/initiative-performance.types';
import { defaultFilters, useStrategyStore } from './strategy-store';
import { Filters, Message, Panel, TreeMapMetric, View } from './strategy-store.type';

/**
 * Sets the initiatives in the Strategy store.
 *
 * @param {Initiative[]} initiatives - The initiatives to set
 * @return {void}
 */
const setInitiatives = (initiatives: Initiative[]): void => {
  useStrategyStore.setState({ initiatives }, false, '[Strategy Store] setInitiatives');
};

/**
 * Sets the KPI overview data in the Strategy store.
 *
 * @param {KPIOverviewData | null} kpiOverviewData - The KPI overview data to set
 * @return {void}
 */
const setKPIOverviewData = (kpiOverviewData: KPIOverviewData | null): void => {
  useStrategyStore.setState({ kpiOverviewData }, false, '[Strategy Store] setKPIOverviewData');
};

/**
 * Sets the view in the Strategy store.
 *
 * @param {View} view - The view to set
 * @return {void}
 */
const setView = (view: View): void => {
  useStrategyStore.setState({ view }, false, '[Strategy Store] setView');
};

/**
 * Sets the page in the Strategy store.
 *
 * @param {number} page - The page to set
 * @return {void}
 */
const setPage = (page: number): void => {
  useStrategyStore.setState({ page }, false, '[Strategy Store] setPage');
};

/**
 * Resets the page in the Strategy store to page 1 if the current page is greater than 1.
 *
 * @return {void}
 */
const resetPage = (): void => {
  const currentPage = useStrategyStore.getState().page;

  if (currentPage > 1) {
    setPage(1);
  }
};

/**
 * Sets the selected initiative in the Strategy store.
 *
 * @param {Initiative | null} initiative - The initiative to set, or null to clear
 * @return {void}
 */
const setSelectedInitiative = (initiative: Initiative | null): void => {
  useStrategyStore.setState({ selectedInitiative: initiative }, false, '[Strategy Store] setSelectedInitiative');

  if (initiative) {
    setActivePanel(Panel.Side);
  }
};

/**
 * Sets the initiative performance in the Strategy store.
 *
 * @param {ExtendedInitiative | null} initiativePerformance - The initiative performance to set, or null to clear
 * @return {void}
 */
const setInitiativePerformance = (initiativePerformance: ExtendedInitiative | null): void => {
  useStrategyStore.setState({ initiativePerformance }, false, '[Strategy Store] setInitiativePerformance');
};

/**
 * Sets the performance tab in the Strategy store.
 *
 * @param {PerformanceTab} performanceTab - The performance tab to set
 * @return {void}
 */
const setPerformanceTab = (performanceTab: PerformanceTab): void => {
  useStrategyStore.setState({ performanceTab }, false, '[Strategy Store] setPerformanceTab');
};

/**
 * Sets the message in the Strategy store.
 *
 * @param {Message | null} message - The message to set, or null to clear
 * @return {void}
 */
const setMessage = (message: Message | null): void => {
  useStrategyStore.setState({ message }, false, '[Strategy Store] setMessage');
};

/**
 * Sets the filters in the Strategy store.
 *
 * @param {Filters} filters - The filters to set
 * @return {void}
 */
const setFilters = (filters: Filters): void => {
  useStrategyStore.setState({ filters }, false, '[Strategy Store] setFilters');
  resetPage();
};

/**
 * Sets the status filters in the Strategy store.
 *
 * @param {string[]} statusFilters - The status filters to set
 * @return {void}
 */
const setStatusFilters = (statusFilters: string[]): void => {
  setFilters({ ...useStrategyStore.getState().filters, status: statusFilters });
};

/**
 * Sets the state filters in the Strategy store.
 *
 * @param {string[]} stateFilters - The state filters to set
 * @return {void}
 */
const setStateFilters = (stateFilters: string[]): void => {
  setFilters({ ...useStrategyStore.getState().filters, state: stateFilters });
};

/**
 * Sets the source filters in the Strategy store.
 *
 * @param {string[]} sourceFilters - The source filters to set
 * @return {void}
 */
const setSourceFilters = (sourceFilters: string[]): void => {
  setFilters({ ...useStrategyStore.getState().filters, source: sourceFilters });
};

/**
 * Resets the filters in the Strategy store.
 *
 * @return {void}
 */
const resetFilters = (): void => {
  setFilters(defaultFilters);
};

/**
 * Sets the collapsed groups in the Strategy store.
 *
 * @param {Record<string, boolean>} collapsedGroups - The collapsed groups to set
 * @return {void}
 */
const setCollapsedGroups = (collapsedGroups: Record<string, boolean>): void => {
  useStrategyStore.setState({ collapsedGroups }, false, '[Strategy Store] setCollapsedState');
};

/**
 * Sets the collapsed group in the Strategy store.
 *
 * @param {string} group - The group to set
 * @param {boolean} collapsed - Whether the group is collapsed
 * @return {void}
 */
const setCollapsedGroup = (group: string, collapsed: boolean): void => {
  useStrategyStore.setState(
    { collapsedGroups: { ...useStrategyStore.getState().collapsedGroups, [group]: collapsed } },
    false,
    '[Strategy Store] setCollapsedGroup',
  );
};

/**
 * Toggles the collapsed state of a group in the Strategy store.
 *
 * @param {string} group - The group to toggle
 * @return {void}
 */
const toggleCollapsedGroup = (group: string): void => {
  useStrategyStore.setState(
    {
      collapsedGroups: {
        ...useStrategyStore.getState().collapsedGroups,
        [group]: !useStrategyStore.getState().collapsedGroups[group],
      },
    },
    false,
    '[Strategy Store] toggleCollapsedGroup',
  );
};

/**
 * Resets the collapsed groups in the Strategy store.
 *
 * @return {void}
 */
const resetCollapsedGroups = (): void => {
  useStrategyStore.setState(
    { collapsedGroups: { status: false, source: false } },
    false,
    '[Strategy Store] resetCollapsedGroups',
  );
};

/**
 * Sets the active panel in the Strategy store.
 *
 * @param {Panel | null} panel - The panel to set, or null to clear
 * @return {void}
 */
const setActivePanel = (panel: Panel | null): void => {
  useStrategyStore.setState({ activePanel: panel }, false, '[Strategy Store] setActivePanel');
};

/**
 * Sets the search term in the Strategy store.
 *
 * @param {string} searchTerm - The search term to set
 * @return {void}
 */
const setSearchTerm = (searchTerm: string): void => {
  useStrategyStore.setState({ searchTerm }, false, '[Strategy Store] setSearchTerm');
  resetPage();
};

/**
 * Sets the tree map metric in the Strategy store.
 *
 * @param {TreeMapMetric} treeMapMetric - The tree map metric to set
 * @return {void}
 */
const setTreeMapMetric = (treeMapMetric: TreeMapMetric): void => {
  useStrategyStore.setState({ treeMapMetric }, false, '[Strategy Store] setTreeMapMetric');
};

/**
 * Sets the epics in the Strategy store.
 *
 * @param {Epic[]} epics - The epics to set
 * @return {void}
 */
const setEpics = (epics: Epic[]): void => {
  useStrategyStore.setState({ epics }, false, '[Strategy Store] setEpics');
};

/**
 * Sets the throughput mode in the Strategy store.
 *
 * @param {ThroughputMode} throughputMode - The throughput mode to set
 * @return {void}
 */
const setThroughputMode = (throughputMode: ThroughputMode): void => {
  useStrategyStore.setState({ throughputMode }, false, '[Strategy Store] setThroughputMode');
};

export {
  resetCollapsedGroups,
  resetFilters,
  resetPage,
  setActivePanel,
  setCollapsedGroup,
  setCollapsedGroups,
  setEpics,
  setFilters,
  setInitiativePerformance,
  setInitiatives,
  setKPIOverviewData,
  setMessage,
  setPage,
  setPerformanceTab,
  setSearchTerm,
  setSelectedInitiative,
  setSourceFilters,
  setStateFilters,
  setStatusFilters,
  setThroughputMode,
  setTreeMapMetric,
  setView,
  toggleCollapsedGroup,
};
