import dayjs from 'dayjs';
import { downloadCSV } from '../../../../helpers/csv-download';
import { WorkPeriod } from '../../work-periods.type';

/**
 * Transforms the measure data into a format suitable for the measures over time chart.
 * @param measureData The measure data to transform.
 * @param workPeriods The work periods to include in the chart.
 * @param selectedMeasure The measure to include in the chart.
 * @returns The transformed measure data.
 */
function transformMeasureData(
  measureData: Record<string, Record<string, Record<string, number>>>,
  workPeriods: WorkPeriod[],
  selectedMeasure: string,
): { dayKey: string; [key: string]: string | number }[] {
  const chartDataByDay: Record<string, Record<string, number>> = {};
  const allDayKeys = new Set<string>();

  // Process each work period
  workPeriods.forEach((workPeriod) => {
    const measureValues = measureData[workPeriod.id]?.[selectedMeasure];
    if (!measureValues) return;

    // Get dates in chronological order
    const dates = Object.keys(measureValues).sort();
    if (dates.length === 0) return;

    // Use the first date as the reference point
    const firstDate = dates[0];

    dates.forEach((date) => {
      // Calculate days elapsed from the first date
      const daysElapsed = _calculateDaysElapsed(firstDate, date);
      const dayKey = `day ${daysElapsed}`;
      allDayKeys.add(dayKey);

      // Initialize the day entry if it doesn't exist
      chartDataByDay[dayKey] = chartDataByDay[dayKey] || {};

      // Add this work period's value to the existing day entry
      chartDataByDay[dayKey][workPeriod.id] = measureValues[date];
    });
  });

  // Sort day keys numerically
  const dayKeysInOrder = Array.from(allDayKeys).sort((a, b) => {
    const numA = parseInt(a.replace('day ', ''));
    const numB = parseInt(b.replace('day ', ''));
    return numA - numB;
  });

  // Convert to array format for Recharts
  return dayKeysInOrder.map((dayKey) => ({
    dayKey,
    ...chartDataByDay[dayKey],
  }));
}

/**
 * Calculates the number of days elapsed between two dates using dayjs.
 * @param startDate The start date in string format.
 * @param endDate The end date in string format.
 * @returns The number of days elapsed.
 */
function _calculateDaysElapsed(startDate: string, endDate: string): number {
  return Math.abs(dayjs(endDate).diff(dayjs(startDate), 'day'));
}

/**
 * Builds a dataset of trend data for each work period.
 * @param chartData The chart data to build the trend datasets from.
 * @param workPeriods The work periods to include in the trend datasets.
 * @returns A dataset of trend data for each work period.
 */
function buildTrendDatasets(
  chartData: { dayKey: string; [key: string]: string | number }[],
  workPeriods: WorkPeriod[],
) {
  const datasets: Record<string, { dayKey: string; [key: string]: string | number }[]> = {};

  if (chartData.length < 2) {
    return datasets;
  }

  workPeriods.forEach((workPeriod) => {
    const trendData = calculateTrendData(chartData, workPeriod.id);
    if (trendData) {
      datasets[workPeriod.id] = trendData;
    }
  });

  return datasets;
}

/**
 * Calculates the trend data for a given work period.
 * @param data The chart data to calculate the trend data from.
 * @param dataKey The key of the data to calculate the trend data from.
 * @returns The trend data for the given work period or empty array if insufficient data.
 */
function calculateTrendData(
  data: {
    [key: string]: string | number;
    dayKey: string;
  }[],
  dataKey: string,
): {
  dayKey: string;
  [key: string]: string | number;
}[] {
  // Early return if insufficient data
  if (!data || data.length < 2) {
    return [];
  }

  // Extract valid points with numerical values
  const points = extractValidDataPoints(data, dataKey);

  if (points.length < 2) {
    return [];
  }

  // Calculate regression that passes through the first point
  const { slope, intercept } = calculateRegressionThroughPoint(points, points[0]);

  // Create trendline dataset with exact same keys as main dataset
  return data.map((entry, index) => ({
    dayKey: entry.dayKey,
    [`${dataKey}_trend`]: slope * index + intercept,
  }));
}

/**
 * Extracts valid data points from the input data.
 * @param data The input data array.
 * @param dataKey The key to extract values from.
 * @returns Array of valid data points.
 */
function extractValidDataPoints(
  data: {
    [key: string]: string | number;
    dayKey: string;
  }[],
  dataKey: string,
): {
  x: number;
  y: number;
}[] {
  return data
    .map((entry, index) => {
      const value = entry[dataKey];
      return typeof value === 'number' ? { x: index, y: value } : null;
    })
    .filter(
      (
        point,
      ): point is {
        x: number;
        y: number;
      } => point !== null,
    );
}

/**
 * Calculates a linear regression line that passes through a specified point.
 * @param points Array of data points.
 * @param fixedPoint The point the regression line must pass through.
 * @returns Object containing slope and intercept.
 */
function calculateRegressionThroughPoint(
  points: {
    x: number;
    y: number;
  }[],
  fixedPoint: {
    x: number;
    y: number;
  },
): { slope: number; intercept: number } {
  // Center the data around the fixed point
  const centeredPoints = points.map((p) => ({
    x: p.x - fixedPoint.x,
    y: p.y - fixedPoint.y,
  }));

  // For a line passing through the origin (0,0), the formula simplifies to:
  // slope = sum(x*y) / sum(x^2)
  let sumXY = 0;
  let sumXX = 0;

  for (const point of centeredPoints) {
    sumXY += point.x * point.y;
    sumXX += point.x * point.x;
  }

  // Calculate slope (prevent division by zero)
  const slope = sumXX !== 0 ? sumXY / sumXX : 0;

  // Calculate intercept to pass through the fixed point
  const intercept = fixedPoint.y - slope * fixedPoint.x;

  return { slope, intercept };
}

/**
 * Calculates the appropriate Y-axis domain to prevent excessive whitespace.
 * @param chartData The chart data to calculate the Y-axis domain from.
 * @param workPeriods The work periods to include in the Y-axis domain calculation.
 * @param showTrendLine Whether to include trend lines in the Y-axis domain calculation.
 * @param trendDatasets The trend datasets to include in the Y-axis domain calculation.
 * @returns The Y-axis domain.
 */
function calculateYDomain(
  chartData: any[],
  workPeriods: WorkPeriod[],
  showTrendLine: boolean,
  trendDatasets: Record<string, { [key: string]: string | number; dayKey: string }[]>,
): [number, number] {
  // Start with default minimum of 0 (makes sense for throughput/velocity)
  let min = 0;
  let max = 0;

  // Find the maximum value across all datasets (both regular and trend lines)
  chartData.forEach((dataPoint) => {
    workPeriods.forEach((workPeriod) => {
      const value = dataPoint[workPeriod.id];
      if (typeof value === 'number' && value > max) {
        max = value;
      }
    });
  });

  // Also check trendline values that might exceed regular data points
  if (showTrendLine) {
    Object.values(trendDatasets).forEach((dataset) => {
      dataset.forEach((dataPoint) => {
        Object.entries(dataPoint).forEach(([key, value]) => {
          if (key.includes('_trend') && typeof value === 'number' && value > max) {
            max = value;
          }
        });
      });
    });
  }

  // Add some padding to the top
  max = max * 1.1;

  // Round to a whole number to avoid decimal values in axis
  max = Math.ceil(max);

  return [min, max];
}

function buildAndDownloadCSV(
  chartData: { dayKey: string; [key: string]: string | number }[],
  workPeriods: WorkPeriod[],
): void {
  // Prepare CSV data
  const csvData = [
    ['Day', ...workPeriods.map((wp) => wp.name)],
    ...chartData.map((dataPoint) => [
      dataPoint.dayKey,
      ...workPeriods.map((wp) => {
        return dataPoint[wp.id] !== undefined ? dataPoint[wp.id] : '';
      }),
    ]),
  ];

  // Download CSV
  downloadCSV(csvData, 'measures-over-time.csv');
}

export { buildAndDownloadCSV, buildTrendDatasets, calculateTrendData, calculateYDomain, transformMeasureData };
