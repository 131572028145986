import { Group } from '@mantine/core';
import { inkLight } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

type Props = {
  onResetFilters: () => void;
};

export const FilterPanelHeader = ({ onResetFilters }: Props) => {
  return (
    <Group justify="space-between">
      <Text weight="medium" textKey="common.filters">
        Filters
      </Text>
      <Button
        variant="outline"
        size="xs"
        radius="xl"
        leftSection={<Icon name="restart_alt" size={16} color={inkLight} />}
        onClick={onResetFilters}
        style={{ padding: '8px 16px' }}
      >
        <Text size="small" lineHeight="none" color={inkLight}>
          Reset
        </Text>
      </Button>
    </Group>
  );
};
