import AxiosInstance from '../../helpers/axios-instance/axios-instance';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Get the ActivePieces token for the given organization ID
 *
 * @param {string} organizationId - The ID of the organization to get the ActivePieces token for
 * @returns {Promise<string>} The ActivePieces token for the given organization ID
 */
const getActivePiecesToken = async (organizationId: string): Promise<string> => {
  try {
    const apiUrl = baseURL + '/api/automations/activepieces/jwt/';
    const response = await axiosInstance.get(apiUrl, {
      params: { organization_id: organizationId },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data.token;
  } catch (error) {
    console.error('Error getting ActivePieces token:', error);
    throw error;
  }
};

export { getActivePiecesToken };
