import { toLocalDate } from '../../helpers/timezone/timezone';
import { Metric } from './strategy-v2.types';

/**
 * Formats an initiative date string to a human-readable format
 *
 * @param {string | undefined | null} date - The date string to format
 * @returns {string} The formatted date string
 */
const formatInitiativeDate = (date: string | undefined | null): string => {
  if (!date) {
    return '-';
  }

  return toLocalDate(date).format('MMM D, YYYY');
};

/**
 * Returns a human-readable label for a given metric
 *
 * @param {Metric} metric - The metric to get a label for
 * @returns {string} The human-readable label for the metric
 */
const getMetricLabel = (metric: Metric): string => {
  switch (metric) {
    case Metric.Tasks:
      return 'Tasks';
    case Metric.Points:
      return 'Points';
    default:
      return metric;
  }
};

/**
 * Formats a date string to a human-readable format
 *
 * @param {string} date - The date string to format
 * @returns {string} The formatted date string
 */
const formatDateLabel = (date: string): string => {
  const [year, month] = date.split('-');
  const monthStr = new Date(0, Number(month) - 1).toLocaleString('default', { month: 'short' });

  return `${monthStr} '${year.toString().slice(-2)}`;
};

export { formatDateLabel, formatInitiativeDate, getMetricLabel };
