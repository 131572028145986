import { Workflow } from '../../../../../../api/workflows-client/workflows-client.type';
import i18n from '../../../../../../base-dictionary';
import { Button } from '../../../../../../ui-library/button/button';
import { Text } from '../../../../../../ui-library/typography/typography';
import { HeaderContainer } from '../../shared-components/styles';

export function ViewWorkflowHeader({
  state,
  setPageState,
}: {
  state: Workflow;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
}) {
  return (
    <HeaderContainer>
      <Text size="large">{state.name}</Text>
      <Button
        variant="primary"
        size="xs"
        radius="xl"
        style={{ padding: '8px 16px', gap: '8px' }}
        onClick={() => setPageState('update')}
      >
        <Text>
          {i18n.t('common.actions.edit')} {i18n.t('data_management.workflows.workflow')}
        </Text>
      </Button>
    </HeaderContainer>
  );
}
