import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Flex, ScrollArea, Text, Title } from '@mantine/core';
import { newCOLORS } from '../../../styles/colors';
import { TaskRecord } from '../../tasks-table/tasks-table.type';
import { getTypeColor } from './task-detail.helpers';

type TaskListProps = {
  tasks: TaskRecord[];
  title: string;
  onSelectTask: (task: TaskRecord) => void;
  onClose: () => void;
};

export const TaskList = ({ tasks, title, onSelectTask, onClose }: TaskListProps) => {
  const handleTaskClick = (task: TaskRecord) => {
    onSelectTask(task);
  };

  return (
    <ListContainer>
      <Header>
        <Flex justify="space-between" align="center" w="100%">
          <Title order={4}>
            {title} ({tasks.length})
          </Title>
          <CloseButton onClick={onClose}>
            <Icon icon="mdi:close" width={20} height={20} />
          </CloseButton>
        </Flex>
      </Header>

      <ScrollArea h="calc(100% - 60px)" type="auto" scrollbarSize={8}>
        <Content>
          {tasks.length === 0 ? (
            <EmptyState>
              <Icon icon="mdi:file-document-outline" width={32} height={32} color={newCOLORS.gray} />
              <Text size="sm" color={newCOLORS.gray}>
                No tasks found
              </Text>
            </EmptyState>
          ) : (
            tasks.map((task) => (
              <TaskItem key={task.id} onClick={() => handleTaskClick(task)}>
                <TaskHeader>
                  <TaskTitle>{task.name}</TaskTitle>
                </TaskHeader>

                <TaskMeta>
                  {task.type && <TypeBadge color={getTypeColor(task.type)}>{task.type}</TypeBadge>}

                  {task.points !== null && (
                    <PointsBadge>
                      {task.points} {task.points === 1 ? 'point' : 'points'}
                    </PointsBadge>
                  )}
                </TaskMeta>

                {task.epic && (
                  <TaskEpic>
                    <Icon icon="mdi:bookmark-outline" width={14} height={14} color={newCOLORS.indigo} />
                    <Text size="xs" color={newCOLORS.indigo}>
                      {task.epic.name}
                    </Text>
                  </TaskEpic>
                )}
              </TaskItem>
            ))
          )}
        </Content>
      </ScrollArea>
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${newCOLORS.white};
`;

const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${newCOLORS.lightGray};
  background-color: ${newCOLORS.white};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${newCOLORS.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${newCOLORS.lighterGray};
    color: ${newCOLORS.black};
  }
`;

const Content = styled.div`
  padding: 16px;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  gap: 12px;
`;

const TaskItem = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${newCOLORS.lighterGray};
  border: 1px solid ${newCOLORS.lightGray};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    border-color: ${newCOLORS.lightBlue};
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const TaskTitle = styled.div`
  font-weight: 600;
  color: ${newCOLORS.darkerGray};
  font-size: 15px;
  flex: 1;
`;

const TaskMeta = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`;

const TypeBadge = styled.div<{ color: string }>`
  font-size: 12px;
  color: white;
  background-color: ${(props) => props.color};
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: capitalize;
`;

const PointsBadge = styled.div`
  font-size: 12px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.lighterGray2};
  padding: 2px 8px;
  border-radius: 4px;
`;

const TaskEpic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
