import { newCOLORS } from '../../../styles/colors';
import { primaryBase, redBase } from '../../../styles/design-tokens';
import { RiskAnalysisTask } from './risk-analysis.client.type';

/**
 * Determines the risk category and associated color based on a risk score.
 *
 * @param {number | undefined | null} score - The risk score to categorize
 * @returns {{ label: string; color: string }} An object containing:
 *   - label: The risk category label ('High', 'Medium', 'Low', or 'N/A')
 *   - color: The color associated with the risk category from the color palette
 */
const getRiskCategory = (score: number | undefined | null): { label: string; color: string } => {
  if (score === undefined || score === null) {
    return { label: 'N/A', color: newCOLORS.gray };
  }

  if (score > 7) {
    return { label: 'High', color: redBase };
  }
  if (score >= 4) {
    return { label: 'Medium', color: newCOLORS.orange };
  }
  return { label: 'Low', color: primaryBase };
};

/**
 * Returns a placeholder string if the provided array is empty, undefined otherwise.
 *
 * @template T The type of elements in the array
 * @param {T[]} value - The array to check for emptiness
 * @param {string} placeholder - The placeholder text to return if array is empty
 * @returns {string | undefined} The placeholder string if array is empty, undefined otherwise
 */
const getPlaceholder = <T>(value: T[], placeholder: string): string | undefined => {
  return value.length > 0 ? undefined : placeholder;
};

/**
 * Generic function to check if two arrays of items with IDs are different in content
 * Can be used for tasks, initiatives, pull requests, or any entity with an id field
 *
 * @template T Type with an id field
 * @param {T[]} arrayA - First array to compare
 * @param {T[]} arrayB - Second array to compare
 * @returns {boolean} True if arrays have different content, false if same content
 */
export function areDataArraysDifferent<T extends { id: string }>(arrayA: T[], arrayB: T[]): boolean {
  if (arrayA.length !== arrayB.length) return true;

  // Create a map of IDs for faster lookup
  const idsMap = new Map(arrayA.map((item) => [item.id, true]));

  // Check if all items in arrayB exist in arrayA
  return arrayB.some((item) => !idsMap.has(item.id));
}

/**
 * Generic sort function for risk analysis data types
 * @param data Array of items to sort
 * @param sortBy Key to sort by
 * @param direction Sort direction ('asc' or 'desc')
 * @returns Sorted array
 */
export function sortRiskAnalysisData<T extends { flora_annotations?: { risk_score?: number | null } | null }>(
  data: T[],
  sortBy: keyof T,
  direction: 'asc' | 'desc',
): T[] {
  return [...data].sort((a, b) => {
    let comparison = 0;

    if (
      sortBy === ('flora_annotations.risk_score' as keyof T) ||
      sortBy === ('flora_annotations.risk_category' as keyof T)
    ) {
      // Sort by risk score when sorting risk-related columns
      const scoreA = a.flora_annotations?.risk_score || 0;
      const scoreB = b.flora_annotations?.risk_score || 0;
      comparison = scoreA - scoreB;
    } else {
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparison = valueA.localeCompare(valueB);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        comparison = valueA - valueB;
      } else if (valueA instanceof Date && valueB instanceof Date) {
        comparison = valueA.getTime() - valueB.getTime();
      }
    }

    return direction === 'asc' ? comparison : -comparison;
  });
}

/**
 * Calculates the completion percentage of a task
 * @param {RiskAnalysisTask} task Task object
 * @returns {number} Completion percentage
 */
const calculateCompletionPercentage = (task: RiskAnalysisTask): number => {
  if (task.not_done_subtasks === undefined || task.total_subtasks === undefined || task.total_subtasks === 0) {
    return 0;
  }
  return Math.round(((task.total_subtasks - task.not_done_subtasks) / task.total_subtasks) * 100);
};

export { calculateCompletionPercentage, getPlaceholder, getRiskCategory };
