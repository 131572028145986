import { styled } from '@linaria/react';
import { Title } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DownloadPNGButton } from '../../../../components/download-png-button';
import { newCOLORS } from '../../../../styles/colors';
import { MeasureMetadata, WorkPeriod } from '../../work-periods.type';
import {
  buildAndDownloadCSV,
  buildTrendDatasets,
  calculateYDomain,
  transformMeasureData,
} from '../helpers/measures-over-time.helpers';

// Define chart colors
const CHART_COLORS = [
  newCOLORS.blue,
  newCOLORS.orangeRed,
  newCOLORS.green,
  newCOLORS.darkYellow,
  newCOLORS.pinkishPurple,
  newCOLORS.tangerine,
  newCOLORS.skyLighter,
  newCOLORS.orange,
];

interface MeasuresOverTimeChartProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
  onPointClick?: (date: string, measureName: string, tasks: any[]) => void;
}

export const MeasuresOverTimeChart = ({
  customMeasures,
  measureData,
  workPeriods,
  externalSelectedMeasure,
  externalShowTrendLine,
  externalShowDots,
}: MeasuresOverTimeChartProps & {
  externalSelectedMeasure?: string;
  externalShowTrendLine?: boolean;
  externalShowDots?: boolean;
}) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  // Use external controls if provided, otherwise use internal state
  const [internalSelectedMeasure, setInternalSelectedMeasure] = useState<string>(
    customMeasures.length > 0 ? customMeasures[0].measure_name : '',
  );
  const [internalShowTrendLine, setInternalShowTrendLine] = useState<boolean>(false);
  const [internalShowDots, setInternalShowDots] = useState<boolean>(true);

  // Use external values if provided, otherwise use internal state
  const selectedMeasure = externalSelectedMeasure !== undefined ? externalSelectedMeasure : internalSelectedMeasure;
  const showTrendLine = externalShowTrendLine !== undefined ? externalShowTrendLine : internalShowTrendLine;
  const showDots = externalShowDots !== undefined ? externalShowDots : internalShowDots;

  // Keep internal state in sync with external props
  useEffect(() => {
    if (externalSelectedMeasure !== undefined) {
      setInternalSelectedMeasure(externalSelectedMeasure);
    }
  }, [externalSelectedMeasure]);

  useEffect(() => {
    if (externalShowTrendLine !== undefined) {
      setInternalShowTrendLine(externalShowTrendLine);
    }
  }, [externalShowTrendLine]);

  useEffect(() => {
    if (externalShowDots !== undefined) {
      setInternalShowDots(externalShowDots);
    }
  }, [externalShowDots]);

  // Process chart data
  const chartData = useMemo(
    () => transformMeasureData(measureData, workPeriods, selectedMeasure),
    [measureData, selectedMeasure, workPeriods],
  );

  const trendDatasets = useMemo(() => buildTrendDatasets(chartData, workPeriods), [chartData, workPeriods]);

  // Handle download
  const handleDownload = () => buildAndDownloadCSV(chartData, workPeriods);

  // Custom tooltip for the chart
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipHeader>{label}</TooltipHeader>
          <TooltipContent>
            {payload
              .filter((entry: any) => !entry.dataKey.includes('_trend')) // Filter out trend lines from tooltip
              .map((entry: any, index: number) => (
                <TooltipItem key={`tooltip-item-${index}`}>
                  <TooltipLabel style={{ color: entry.color }}>{entry.name}:</TooltipLabel>
                  <TooltipValue>{entry.value !== null ? entry.value.toFixed(2) : 'N/A'}</TooltipValue>
                </TooltipItem>
              ))}
          </TooltipContent>
        </TooltipContainer>
      );
    }

    return null;
  };

  return (
    <ChartContainer ref={downloadRef}>
      <ChartHeader>
        <Title order={3}>Measures Over Time</Title>
        <DownloadButtonsContainer>
          <DownloadPNGButton handleDownload={handleDownload} exceptionRef={downloadRef} />
        </DownloadButtonsContainer>
      </ChartHeader>
      <ChartContent ref={chartRef}>
        {chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dayKey" type="category" allowDuplicatedCategory={false} />
              <YAxis
                domain={calculateYDomain(chartData, workPeriods, showTrendLine, trendDatasets)}
                tickFormatter={(value) => Math.round(value).toString()}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              {workPeriods.map((workPeriod, index) => {
                const color = CHART_COLORS[index % CHART_COLORS.length];
                return (
                  <React.Fragment key={workPeriod.id}>
                    {/* Regular data line */}
                    <Line
                      type="monotone"
                      dataKey={workPeriod.id}
                      name={workPeriod.name}
                      stroke={color}
                      dot={showDots ? { r: 4, fill: color } : false}
                      connectNulls
                      isAnimationActive={false}
                    />

                    {/* Trendline with its own dataset */}
                    {showTrendLine && trendDatasets[workPeriod.id] && (
                      <Line
                        data={trendDatasets[workPeriod.id]}
                        type="linear"
                        dataKey={`${workPeriod.id}_trend`}
                        name={`${workPeriod.name} Trend`}
                        stroke={color}
                        strokeDasharray="3 3"
                        strokeWidth={2}
                        dot={false}
                        activeDot={false}
                        label={false}
                        connectNulls
                        isAnimationActive={false}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState>
            <Text>Select a measure and work periods to view the chart.</Text>
          </EmptyState>
        )}
      </ChartContent>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ChartContent = styled.div`
  height: 400px;
`;

const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TooltipHeader = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TooltipItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const TooltipLabel = styled.span`
  font-weight: bold;
`;

const TooltipValue = styled.span`
  font-weight: normal;
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
`;
