import { Edge, Node as ReactFlowNode } from 'reactflow';
import { GraphData, GraphNode, ProcessMapping } from '../../api/process-client/process-client.type';
import { baseBlack, secondaryBase } from '../../styles/design-tokens';
import { ViewType } from './process.type';

/**
 * Find a graph node by name from an array of nodes
 *
 * @param {GraphNode[]} nodes - The array of graph nodes to search through
 * @param {string} name - The name of the node to find
 * @returns {GraphNode | undefined} - The found node or undefined
 */
const findNodeByName = (nodes: GraphNode[], name: string): GraphNode | undefined =>
  nodes.find((node) => node.name === name);

/**
 * Find a graph node by order from an array of nodes
 *
 * @param {GraphNode[]} nodes - The array of graph nodes to search through
 * @param {number} order - The order value to match
 * @returns {GraphNode | undefined} - The found node or undefined
 */
const findNodeByOrder = (nodes: GraphNode[], order: number): GraphNode | undefined =>
  nodes.find((node) => node.order === order);

/**
 * Get stage names from the mapping object
 *
 * @param {ProcessMapping} mapping - The process mapping
 * @returns {string[]} - Array of stage names
 */
const getMappedStageNames = (mapping: ProcessMapping): string[] => {
  return Object.keys(mapping.bf_to_customer);
};

/**
 * Get stage names in the correct order from graph data
 *
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @param {boolean} useGroups - Whether to use buckets
 * @returns {string[]} - Array of stage names in the correct order
 */
const getOrderedStageNames = (graphData: GraphData, mapping: ProcessMapping, useGroups: boolean): string[] => {
  // Get all mapped stage names
  const mappedStageNames = getMappedStageNames(mapping);

  // Filter and sort graph nodes by order, then map to names
  let names = graphData.nodes;
  if (useGroups) {
    names = names.filter((node) => mappedStageNames.includes(node.name));
  }
  const orderedNames = names.sort((a, b) => a.order - b.order).map((node) => node.name);

  // If we have ordered names, use them; otherwise fall back to mapping order
  return orderedNames.length > 0 ? orderedNames : mappedStageNames;
};

/**
 * Get title for a stage
 *
 * @param {string} stage - The stage identifier
 * @returns {string} - Formatted stage title
 */
const getStageDisplayTitle = (stage: string): string => {
  // Format the stage for display - capitalize first letter and handle special cases
  const formattedStage = stage.toLowerCase();

  // Handle special cases
  if (formattedStage === 'wip') return 'In Progress';
  if (formattedStage === 'review') return 'In Review';
  if (formattedStage === 'test') return 'In Test';

  // General case: capitalize first letter of each word
  return formattedStage
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Get the index of a stage in the ordered stages from graph data
 *
 * @param {string} stage - The stage to find
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @returns {number} - The index of the stage
 */
const getStageIndexOrdered = (
  stage: string,
  graphData: GraphData,
  mapping: ProcessMapping,
  useGroups: boolean,
): number => getOrderedStageNames(graphData, mapping, useGroups).indexOf(stage);

/**
 * Calculate the Y position for stages in the graph
 *
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @returns {Record<string, number>} - Object mapping each stage to its Y position
 */
const calculateGridY = (graphData: GraphData, mapping: ProcessMapping, useGroups: boolean): Record<string, number> => {
  const Y_GRID_STEP = useGroups ? 100 : 125;
  const stageNames = getOrderedStageNames(graphData, mapping, useGroups);

  return stageNames.reduce(
    (acc, stage, index) => {
      const prevStage = stageNames[index - 1];
      const prevStageY = acc[prevStage] || 0;
      const base = prevStageY + Y_GRID_STEP;
      const offset =
        (mapping.bf_to_customer[stage]?.length || 0) * 10 + (mapping.bf_to_customer[prevStage]?.length || 0) * 10;

      return {
        ...acc,
        [stage]: base + offset,
      };
    },
    {} as Record<string, number>,
  );
};

/**
 * Calculate the stroke width based on counts
 *
 * @param {number} count - The count value
 * @param {number} totalCount - The total count
 * @returns {number} - The calculated stroke width
 */
const calculateStrokeWidth = (count: number, totalCount: number): number => {
  const MAX_STROKE_WIDTH = parseInt(import.meta.env.VITE_APP_PROCESS_MAP_MAX_EDGE_STROKE_WIDTH || '20');

  if (totalCount <= 0 || count <= 0) {
    return 0;
  }

  if (count > totalCount) {
    return MAX_STROKE_WIDTH;
  }

  const ratio = count / totalCount;
  return ratio * MAX_STROKE_WIDTH;
};

/**
 * Get display value based on view type
 *
 * @param {number} count - The count value
 * @param {number} total - The total count
 * @param {string} source - Source stage name
 * @param {string} target - Target stage name
 * @param {ViewType} viewType - The view type
 * @returns {number | string} - The formatted value
 */
const getDisplayValue = (
  count: number,
  graphData: GraphData,
  source: string,
  target: string,
  viewType: ViewType,
): number | string => {
  switch (viewType) {
    case ViewType.Count:
      return count;
    case ViewType.Percentage:
      return `${((count / graphData.task_count) * 100).toFixed(2)}%`;
    case ViewType.CycleTime: {
      const cycleKey = `${source}->${target}`;
      const cycleTime = graphData?.cycle_times?.[cycleKey];
      if (cycleTime !== undefined) {
        return `${cycleTime} days`;
      }
      return `-`;
    }
    case ViewType.Cost: {
      const costKey = `${source}->${target}`;
      const cost = graphData.costs?.[costKey];
      return cost !== undefined ? `$${cost.toLocaleString()}` : `${count}`;
    }
    default:
      return `-`;
  }
};

/**
 * Calculate offset X position based on distance
 *
 * @param {number} distance - The distance between nodes
 * @returns {number} - The calculated X offset
 */
const calculateOffsetX = (): number => {
  const X_GRID_STEP = 120;
  return X_GRID_STEP;
};

/**
 * Generate stage nodes for the graph
 *
 * @param {string[]} stageNames - Array of stage names
 * @param {ProcessMapping} mapping - The process mapping
 * @param {Record<string, number>} gridY - Y positions for each stage
 * @returns {ReactFlowNode[]} - Array of stage nodes
 */
const generateStageNodes = (
  stageNames: string[],
  mapping: ProcessMapping,
  gridY: Record<string, number>,
  useGroups: boolean,
): ReactFlowNode[] => {
  return stageNames.map((stage) => {
    return {
      id: `node-stage-${stage}`,
      type: 'stageNode',
      position: { x: 0, y: gridY[stage] },
      data: {
        title: useGroups ? getStageDisplayTitle(stage) : mapping.customer_to_bf[stage] || stage,
        stage,
        customerStages: useGroups ? mapping.bf_to_customer[stage] || [] : [stage],
      },
    };
  });
};

/**
 * Generate stage edges for the graph
 *
 * @param {string[]} stageNames - Array of stage names
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @param {ViewType} viewType - The view type
 * @returns {Edge[]} - Array of stage edges
 */
const generateStageEdges = (
  stageNames: string[],
  graphData: GraphData,
  mapping: ProcessMapping,
  viewType: ViewType,
): Edge[] => {
  const newCOLORS = {
    purple2: secondaryBase,
    black2: baseBlack,
  };

  const edges: Edge[] = [];

  // Create edges between consecutive stages in the ordered list
  for (let i = 0; i < stageNames.length - 1; i++) {
    const sourceStage = stageNames[i];
    const destinationStage = stageNames[i + 1];

    // Get edge data
    const sourceNode = findNodeByName(graphData.nodes, sourceStage);
    const destinationNode = findNodeByName(graphData.nodes, destinationStage);

    let edgeData: { taskCount: number | string; strokeWidth: number } = {
      taskCount: 0,
      strokeWidth: 1,
    };

    if (sourceNode && destinationNode) {
      const count = graphData.edges[sourceNode.order]?.[destinationNode.order] || 0;
      const displayValue = getDisplayValue(count, graphData, sourceNode.name, destinationNode.name, viewType);
      const strokeWidth = calculateStrokeWidth(
        typeof displayValue === 'string' ? parseFloat(displayValue) : displayValue,
        graphData.task_count,
      );

      edgeData = { taskCount: displayValue, strokeWidth: strokeWidth > 1 ? strokeWidth : 1 };
    }

    const workflowMapping = {
      [sourceStage]: mapping.bf_to_customer[sourceStage],
      [destinationStage]: mapping.bf_to_customer[destinationStage],
    };

    // Determine color (special case for 'blocked' stage)
    const color = sourceStage.toLowerCase() === 'blocked' ? newCOLORS.purple2 : newCOLORS.black2;

    edges.push({
      id: `edge-stage-${i}`,
      type: 'stageEdge',
      source: `node-stage-${sourceStage}`,
      target: `node-stage-${destinationStage}`,
      data: {
        value: edgeData.taskCount,
        sourceStage,
        destinationStage,
        mapping: workflowMapping,
      },
      style: { stroke: color, strokeWidth: edgeData.strokeWidth > 1 ? edgeData.strokeWidth : 1 },
    });
  }

  return edges;
};

/**
 * Generate skipped workflow nodes for the graph
 *
 * @param {string} stage - The source stage
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @param {Record<string, number>} gridY - Y positions for each stage
 * @param {ViewType} viewType - The view type
 * @returns {ReactFlowNode[]} - Array of skipped workflow nodes
 */
const generateSkippedNodes = (
  stage: string,
  graphData: GraphData,
  mapping: ProcessMapping,
  gridY: Record<string, number>,
  viewType: ViewType,
  useGroups: boolean,
): ReactFlowNode[] => {
  const sourceIndex = getStageIndexOrdered(stage, graphData, mapping, useGroups);
  const sourceNode = findNodeByName(graphData.nodes, stage);

  if (!sourceNode) {
    return [];
  }

  const relations = graphData.edges[sourceNode.order] || {};

  const destinations = Object.keys(relations).filter((nodeOrder) => {
    const destination = findNodeByOrder(graphData.nodes, Number(nodeOrder));
    const destinationIndex = destination ? getStageIndexOrdered(destination.name, graphData, mapping, useGroups) : -1;

    if (!destination || destinationIndex < 0) {
      return false;
    }

    return destinationIndex > sourceIndex + 1;
  });

  return destinations
    .map((nodeOrder) => {
      const destination = findNodeByOrder(graphData.nodes, Number(nodeOrder));
      const taskCount = graphData.edges[sourceNode.order]?.[Number(nodeOrder)] || 0;

      if (!destination) {
        return null;
      }

      const value = getDisplayValue(taskCount, graphData, sourceNode.name, destination.name, viewType);

      if (value === 0 || value === '0') {
        return null;
      }

      const destinationStage = destination.name;

      const y = (gridY[destinationStage] - gridY[stage]) / 2 + gridY[stage];
      const destinationIndex = getStageIndexOrdered(destinationStage, graphData, mapping, useGroups);
      const distance = destinationIndex - sourceIndex;
      const x = calculateOffsetX() * distance;

      const workflowMapping = {
        [stage]: mapping.bf_to_customer[stage],
        [destinationStage]: mapping.bf_to_customer[destinationStage],
      };

      return {
        id: `node-skip-${stage}-${destinationStage}`,
        type: 'workflowNode',
        position: { x, y },
        style: { width: '60px', height: '30px' },
        data: {
          value,
          status: 'Skipped',
          source: `node-stage-${stage}`,
          target: `node-stage-${destinationStage}`,
          sourceStage: stage,
          destinationStage,
          mapping: workflowMapping,
        },
      };
    })
    .filter(Boolean) as ReactFlowNode[];
};

/**
 * Generate backwards workflow nodes for the graph
 *
 * @param {string} stage - The source stage
 * @param {GraphData} graphData - The graph data
 * @param {ProcessMapping} mapping - The process mapping
 * @param {Record<string, number>} gridY - Y positions for each stage
 * @param {ViewType} viewType - The view type
 * @returns {ReactFlowNode[]} - Array of backwards workflow nodes
 */
const generateBackwardsNodes = (
  stage: string,
  graphData: GraphData,
  mapping: ProcessMapping,
  gridY: Record<string, number>,
  viewType: ViewType,
  useGroups: boolean,
): ReactFlowNode[] => {
  const X_GRID_STEP = 120;
  const sourceIndex = getStageIndexOrdered(stage, graphData, mapping, useGroups);
  const sourceNode = findNodeByName(graphData.nodes, stage);

  if (!sourceNode) {
    return [];
  }

  const relations = graphData.edges[sourceNode.order] || {};

  const destinations = Object.keys(relations).filter((nodeOrder) => {
    const destination = findNodeByOrder(graphData.nodes, Number(nodeOrder));

    if (!destination) {
      return false;
    }

    const destinationIndex = getStageIndexOrdered(destination.name, graphData, mapping, useGroups);

    if (destinationIndex < 0) {
      return false;
    }

    return destinationIndex < sourceIndex;
  });

  return destinations
    .map((nodeOrder) => {
      const destination = findNodeByOrder(graphData.nodes, Number(nodeOrder));
      const taskCount = graphData.edges[sourceNode.order]?.[Number(nodeOrder)] || 0;

      if (!destination) {
        return null;
      }

      const value = getDisplayValue(taskCount, graphData, sourceNode.name, destination.name, viewType);

      if (value === 0 || value === '0') {
        return null;
      }

      const destinationStage = destination.name;

      const y = (gridY[stage] - gridY[destinationStage]) / 2 + gridY[destinationStage];
      const destinationIndex = getStageIndexOrdered(destinationStage, graphData, mapping, useGroups);
      const distance = sourceIndex - destinationIndex;

      const offset = distance === 1 ? -X_GRID_STEP + 20 : -calculateOffsetX();
      const x = offset * distance;

      const workflowMapping = {
        [stage]: mapping.bf_to_customer[stage],
        [destinationStage]: mapping.bf_to_customer[destinationStage],
      };

      return {
        id: `node-backwards-${stage}-${destinationStage}`,
        type: 'workflowNode',
        position: { x, y },
        data: {
          value,
          status: 'Backwards',
          source: `node-stage-${stage}`,
          target: `node-stage-${destination.name}`,
          sourceStage: stage,
          destinationStage,
          mapping: workflowMapping,
        },
      };
    })
    .filter(Boolean) as ReactFlowNode[];
};

/**
 * Generate workflow nodes for the graph
 *
 * @param {GraphData} graphData - The graph data
 * @param {string[]} activeStages - Array of active stages
 * @param {ProcessMapping} mapping - The process mapping
 * @param {Record<string, number>} gridY - Y positions for each stage
 * @param {ViewType} viewType - The view type
 * @returns {ReactFlowNode[]} - Array of workflow nodes
 */
const generateWorkflowNodes = (
  graphData: GraphData,
  activeStages: string[],
  mapping: ProcessMapping,
  gridY: Record<string, number>,
  viewType: ViewType,
  useGroups: boolean,
): ReactFlowNode[] => {
  return activeStages.reduce((resultNodes, stage) => {
    const skipped = generateSkippedNodes(stage, graphData, mapping, gridY, viewType, useGroups);
    const backwards = generateBackwardsNodes(stage, graphData, mapping, gridY, viewType, useGroups);

    return [...resultNodes, ...skipped, ...backwards];
  }, [] as ReactFlowNode[]);
};

/**
 * Generate workflow edges for the graph
 *
 * @param {ReactFlowNode[]} workflowNodes - Array of workflow nodes
 * @param {GraphData} graphData - The graph data
 * @returns {Edge[]} - Array of workflow edges
 */
const generateWorkflowEdges = (workflowNodes: ReactFlowNode[], graphData: GraphData): Edge[] => {
  const newCOLORS = {
    purple2: '#8569F0',
  };

  return workflowNodes
    .map((node) => {
      const strokeWidth = calculateStrokeWidth(node.data.value, graphData.task_count);

      const incoming = {
        id: `edge-${node.id}-in`,
        type: 'workflowEdgeIn',
        source: node.data.source,
        target: node.id,
        sourceHandle: node.data.status === 'Backwards' ? 'left' : 'right',
        targetHandle: node.data.status === 'Backwards' ? 'bottom' : 'top',
        style: { stroke: newCOLORS.purple2, strokeWidth },
        data: {
          status: node.data.status,
        },
      };

      const outgoing = {
        id: `edge-${node.id}-out`,
        type: 'workflowEdgeOut',
        source: node.id,
        target: node.data.target,
        sourceHandle: node.data.status === 'Backwards' ? 'top' : 'bottom',
        targetHandle: node.data.status === 'Backwards' ? 'left' : 'right',
        style: { stroke: newCOLORS.purple2, strokeWidth },
        data: {
          status: node.data.status,
        },
      };

      return [incoming, outgoing];
    })
    .flat();
};

/**
 * Main function to calculate graph nodes and edges based on the provided data and mapping.
 * This version doesn't depend on specific stage wording but uses the mapping keys directly.
 *
 * @param {GraphData | undefined} data - The graph data
 * @param {ProcessMapping | null} mapping - The stages mapping for the graph
 * @param {string[]} activeStages - Array of active stages
 * @param {ViewType} viewType - The view type
 * @param {boolean} useGroups - Whether to use buckets
 * @returns {{ nodes: ReactFlowNode[]; edges: Edge[] }} - The object containing generated nodes and edges
 */
const calculateGraphV2 = (
  data: GraphData | undefined,
  mapping: ProcessMapping | null,
  activeStages: string[],
  viewType: ViewType,
  useGroups: boolean,
): { nodes: ReactFlowNode[]; edges: Edge[] } => {
  if (!data || !mapping) {
    return {
      nodes: [],
      edges: [],
    };
  }

  // Get stage names in correct order from graph data
  const stageNames = getOrderedStageNames(data, mapping, useGroups);

  // Calculate grid Y positions
  const gridY = calculateGridY(data, mapping, useGroups);

  // Generate stage nodes and edges
  const stageNodes = generateStageNodes(stageNames, mapping, gridY, useGroups);
  const stageEdges = generateStageEdges(stageNames, data, mapping, viewType);

  // Generate workflow nodes and edges
  const workflowNodes = generateWorkflowNodes(data, activeStages, mapping, gridY, viewType, useGroups);
  const workflowEdges = generateWorkflowEdges(workflowNodes, data);

  return {
    nodes: [...stageNodes, ...workflowNodes],
    edges: [...stageEdges, ...workflowEdges],
  };
};

export { calculateGraphV2, getOrderedStageNames, getStageIndexOrdered };
