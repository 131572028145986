import { styled } from '@linaria/react';
import { Select } from '@mantine/core';
import { UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useInitiativeContributions,
  useInitiativeFocus,
} from '../../../../api/initiative-client/initiative-client.hooks';
import { ExtendedInitiative, FocusData, Metric } from '../../../../api/initiative-client/initiative-client.type';
import { Board, ProjectsResponse as Team } from '../../../../api/projects-client/projects-client.type';
import { icons } from '../../../../assets/icons/icons';
import { getHealthScoreGrade } from '../../../../helpers/project-metrics-helper/project-metrics-helper';
import { titleCased } from '../../../../helpers/string-helpers/string-helpers';
import { toLocalDate } from '../../../../helpers/timezone/timezone';
import {
  applyTeamsFilter,
  setActiveTab,
} from '../../../../store/process-analysis-store/process-analysis-store.actions';
import { newCOLORS } from '../../../../styles/colors';
import { Tab as ProcessAnalysisTab } from '../../../process-analysis/process-analysis.type';
import { CollapsibleSection, SelectContainer, SelectLabel } from '../collapsible-section';
import { CollapsibleTile } from '../collapsible-tile';
import { getColoredEntities } from '../initiative-performance.helpers';
import { ColoredEntity, InitiativeMetricType, InitiativeScope } from '../initiative-performance.type';
import { Legend } from '../legend';
import { ContributorsBarChart } from './contributors-bar-chart';
import { FocusLineChart } from './focus-line-chart';
import { getCost, sortContributors } from './section-contributors.helpers';

type Props = {
  initiative: ExtendedInitiative;
  portfolioId?: string;
};

const getLegendItems = (entities: ColoredEntity[]) =>
  entities.map(({ name, color }: { name: string; color: string }) => ({ title: name, color }));

export function SectionContributors({
  initiative: {
    id: initiativeId,
    projects: teams = [],
    subprojects: boards = [],
    epics = [],
    initial_contributions,
    costs_to_date,
  },
  portfolioId,
}: Props) {
  const [metric, setMetric] = useState<InitiativeMetricType>(InitiativeMetricType.Tasks);
  const [scope, setScope] = useState<InitiativeScope>(InitiativeScope.Teams);
  const { contributions } = useInitiativeContributions(
    portfolioId,
    initiativeId,
    scope === InitiativeScope.Teams ? 'project' : 'subproject',
    { enabled: Boolean(portfolioId && initiativeId) } as UseQueryOptions<Record<string, Metric>>,
  );

  const { focus, query: focusQuery } = useInitiativeFocus(
    portfolioId,
    initiativeId,
    scope === InitiativeScope.Teams ? 'project' : 'subproject',
    { enabled: Boolean(portfolioId && initiativeId) } as UseQueryOptions<FocusData>,
  );

  const coloredContributors = useMemo(
    () =>
      getColoredEntities(
        (scope === InitiativeScope.Teams ? teams : boards)
          .filter((contributor) => Object.keys(contributions || {}).includes(contributor.id))
          .map(({ id, name }) => ({ id, name })),
      ),
    [teams, boards, contributions, scope],
  );

  const sortedContributors = sortContributors(
    (scope === InitiativeScope.Teams ? teams : boards).filter((contributor) =>
      Object.keys(contributions || {}).includes(contributor.id),
    ),
    epics,
  );

  const titleText = `Contributing ${titleCased(scope)} (${Object.keys(contributions || {}).length})`;

  return (
    <CollapsibleSection
      titleElement={<Title title={titleText} />}
      title={`Contributing ${scope}`}
      metric={metric}
      setMetric={setMetric}
      additionalControls={
        <SelectContainer>
          <SelectLabel>Scope</SelectLabel>
          <Select
            label=""
            size="xs"
            style={{ width: 140 }}
            value={scope}
            data={[
              { value: InitiativeScope.Teams, label: 'Teams' },
              { value: InitiativeScope.Boards, label: 'Boards' },
            ]}
            onChange={(value: string | null) => {
              if (value) {
                setScope(value as InitiativeScope);
              }
            }}
          />
        </SelectContainer>
      }
    >
      <SummaryContainer>
        {(sortedContributors || []).map((contributor) => {
          const cost = getCost(contributor.id, costs_to_date);
          const initialContribution = Object.keys(
            initial_contributions[scope === InitiativeScope.Teams ? 'team' : 'board'] || {},
          ).includes(contributor.id)
            ? initial_contributions[scope === InitiativeScope.Teams ? 'team' : 'board'][contributor.id]
            : undefined;
          return (
            <Score
              key={contributor.id}
              contributor={contributor}
              scope={scope}
              cost={cost}
              initialContribution={initialContribution}
              isOpened={false}
            />
          );
        })}
      </SummaryContainer>
      <ChartsContainer>
        <ChartContainer>
          <ContributorsBarChart
            metric={metric}
            scope={scope}
            contributions={contributions || {}}
            coloredContributors={coloredContributors}
          />
          <Legend items={getLegendItems(coloredContributors)} />
        </ChartContainer>
        <ChartContainer>
          {focusQuery.isSuccess && focus && (
            <Fragment>
              <FocusLineChart metric={metric} focusData={focus} coloredContributors={coloredContributors} />
              <Legend items={getLegendItems(coloredContributors)} />
            </Fragment>
          )}
        </ChartContainer>
      </ChartsContainer>
    </CollapsibleSection>
  );
}

const Title = ({ title }: { title: string }) => {
  return (
    <TitleContainer>
      <img src={icons.iconContributingTeams} width={20} height={20} />
      {title}
    </TitleContainer>
  );
};

const Score = ({
  contributor,
  scope,
  cost,
  initialContribution,
  isOpened,
}: {
  contributor: Team | Board;
  scope: InitiativeScope;
  cost?: number;
  initialContribution?: string | null;
  isOpened: boolean;
}) => {
  const navigate = useNavigate();
  const onContributorClick = (contributor: Team | Board) => {
    setActiveTab(ProcessAnalysisTab.Teams);
    applyTeamsFilter({ ids: [contributor.id] });

    navigate('/application/process-analysis');
  };
  const score = (
    <Fragment>
      <ScoreLabel>{scope === InitiativeScope.Teams ? 'Team' : 'Board'} performance score (current)</ScoreLabel>
      <ScoreValue
        style={{ color: contributor.health && contributor.health >= 87 ? newCOLORS.green : newCOLORS.orange }}
      >
        {getHealthScoreGrade(contributor.health)}
      </ScoreValue>
    </Fragment>
  );

  const data = [
    {
      label: 'Cost to date (work for this initiative)',
      value: cost ? `$${cost.toLocaleString()}` : '-',
    },
    {
      label: 'Contributing since (date of first task)',
      value: initialContribution ? toLocalDate(dayjs(initialContribution).toISOString()).format('MM/DD/YYYY') : '-',
    },
  ];

  return (
    <CollapsibleTile
      title={contributor.name}
      onClick={() => onContributorClick(contributor)}
      score={score}
      data={data}
      isOpened={isOpened}
    />
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  min-width: 300px;
`;

const ScoreLabel = styled.div`
  color: ${newCOLORS.darkGray};
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
`;

const ScoreValue = styled.div`
  font-family: Figtree;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ChartsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 32px;
`;
