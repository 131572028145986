import { Stack } from '@mantine/core';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Divider } from '../../ui-library/divider/divider';
import { Select } from '../../ui-library/select/select';
import { FilterPanelGroup } from './filter-panel-group';
import { FilterPanelGroupSelect } from './filter-panel-group-select';
import { FilterPanelHeader } from './filter-panel-header';
import { FilterGroup } from './filter-panel.types';

type Props<T extends string> = {
  selectedOption?: T;
  headerSection?: ReactNode;
  options?: { value: T; label: string }[];
  onOptionChange?: (value: T) => void;
  filterGroups: FilterGroup[];
  onResetFilters: () => void;
  useSelectFilters?: boolean;
};

export function FilterPanel<T extends string>({
  selectedOption,
  headerSection,
  options,
  onOptionChange,
  filterGroups,
  onResetFilters,
  useSelectFilters = false,
}: Props<T>) {
  const collapsedGroupsRef = useRef<Record<string, boolean>>({});
  const [, forceUpdate] = useState({});
  const [allSelected, setAllSelected] = useState<Record<string, boolean>>({});
  const initializedRef = useRef<Record<string, boolean>>({});
  const initializationDoneRef = useRef(false);

  // Initialize collapsed state for new groups
  useEffect(() => {
    filterGroups.forEach((group) => {
      if (!(group.name in collapsedGroupsRef.current)) {
        collapsedGroupsRef.current[group.name] = true;
      }
    });
  }, [filterGroups]);

  const toggleGroup = (groupName: string) => {
    collapsedGroupsRef.current[groupName] = !collapsedGroupsRef.current[groupName];
    forceUpdate({}); // Force a re-render
  };

  // Run initialization only once
  useEffect(() => {
    if (initializationDoneRef.current) {
      return;
    }

    const newInitializations: Record<string, boolean> = {};
    let hasNewInitializations = false;

    filterGroups.forEach((group) => {
      // Skip initialization for optional filters
      if (
        !initializedRef.current[group.name] &&
        group.showAll !== false &&
        group.options.length > 0 &&
        !group.optional
      ) {
        newInitializations[group.name] = true;
        hasNewInitializations = true;
        initializedRef.current[group.name] = true;

        // For select filters, only initialize with the first option
        if (useSelectFilters) {
          group.onSelectionChange([group.options[0].value]);
        } else {
          group.onSelectionChange(group.options.map((opt) => opt.value));
        }
      }
    });

    if (hasNewInitializations) {
      setAllSelected((prev) => ({
        ...prev,
        ...newInitializations,
      }));

      initializationDoneRef.current = true;
    }
  }, [filterGroups, useSelectFilters]);

  const handleAllChange = (group: FilterGroup, isChecked: boolean) => {
    setAllSelected((prev) => ({
      ...prev,
      [group.name]: isChecked,
    }));

    // When unchecking "All", clear all selections
    // When checking "All", select all options
    group.onSelectionChange(isChecked ? group.options.map((opt) => opt.value) : []);
  };

  const handleIndividualChange = (group: FilterGroup, value: string, isChecked: boolean) => {
    if (group.showAll === false && group.options.length === 2) {
      // For single-select groups (like radio buttons), always set the clicked value
      group.onSelectionChange([value]);
      return;
    }

    // Multi-select behavior for all other groups
    const newValues = isChecked ? [...group.selectedValues, value] : group.selectedValues.filter((v) => v !== value);

    // Update allSelected state based on whether all options are selected
    setAllSelected((prev) => ({
      ...prev,
      [group.name]: newValues.length === group.options.length,
    }));

    group.onSelectionChange(newValues);
  };

  const handleSelectChange = (group: FilterGroup, value: string) => {
    group.onSelectionChange([value]);
  };

  return (
    <Stack gap="md">
      {options && selectedOption && onOptionChange && (
        <div>
          <Select value={selectedOption} options={options} onChange={(value) => onOptionChange(value as T)} />
        </div>
      )}
      <FilterPanelHeader onResetFilters={onResetFilters} />
      <Divider />
      {headerSection && <div>{headerSection}</div>}
      {headerSection && <Divider />}

      {filterGroups.map((group) =>
        useSelectFilters ? (
          <FilterPanelGroupSelect
            key={group.name}
            group={group}
            handleSelectChange={handleSelectChange}
            hasDivider={group !== filterGroups[filterGroups.length - 1]}
          />
        ) : (
          <FilterPanelGroup
            key={group.name}
            group={group}
            toggleCollapsed={() => toggleGroup(group.name)}
            isCollapsed={collapsedGroupsRef.current[group.name]}
            isAllSelected={allSelected[group.name]}
            handleAllChange={handleAllChange}
            handleIndividualChange={handleIndividualChange}
            hasDivider={group !== filterGroups[filterGroups.length - 1]}
          />
        ),
      )}
    </Stack>
  );
}
