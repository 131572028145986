import { Icon } from '@iconify/react/dist/iconify.js';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import { FlowByPhaseDataOverTime } from '../../../../api/work-periods-client/work-periods-client.type';
import i18n from '../../../../base-dictionary';
import { newCOLORS } from '../../../../styles/colors';
import { PhaseCards } from './phase-cards';

type InitiativePhaseChartProps = {
  initiative: ExtendedInitiative;
  data: { flow_by_phase: FlowByPhaseDataOverTime };
  metric?: 'tasks' | 'points';
  selectedDate?: string | null;
  selectedEpic?: string | null | undefined;
};

type ChartDataPoint = {
  date: string;
} & {
  [K in `${'tasks' | 'points'}_${string}`]?: number | null;
};

export const InitiativePhaseChart = ({
  initiative,
  data,
  metric = 'tasks',
  selectedDate,
  selectedEpic,
}: InitiativePhaseChartProps) => {
  const phaseMetadata = useMemo(
    () => [
      {
        id: 'backlog',
        label: i18n.t('process_analysis.assessment.phase.ready'),
        description: 'not started',
        color: newCOLORS.indigo,
      },
      {
        id: 'blocked',
        label: i18n.t('process_analysis.assessment.phase.blocked'),
        description: 'held up',
        color: newCOLORS.coral,
      },
      {
        id: 'wip',
        label: i18n.t('process_analysis.assessment.phase.wip'),
        description: 'started',
        color: newCOLORS.darkYellow,
      },
      {
        id: 'review',
        label: i18n.t('process_analysis.assessment.phase.review'),
        description: 'Review',
        color: newCOLORS.lightYellow,
      },
      {
        id: 'test',
        label: i18n.t('process_analysis.assessment.phase.test'),
        description: 'QA or Acceptance',
        color: newCOLORS.pink,
      },
      {
        id: 'deployable',
        label: i18n.t('process_analysis.assessment.phase.deployable'),
        description: 'Ready for Deployment',
        color: newCOLORS.aqua,
      },
      {
        id: 'done',
        label: i18n.t('process_analysis.assessment.phase.done'),
        description: 'work finished',
        color: newCOLORS.green,
      },
    ],
    [],
  );

  // Initialize state to track visible phases - all visible by default
  const [visiblePhases, setVisiblePhases] = useState<Record<string, boolean>>(() => {
    const initialVisibility: Record<string, boolean> = {};
    phaseMetadata.forEach((phase) => {
      initialVisibility[phase.id] = true;
    });
    return initialVisibility;
  });

  // Toggle phase visibility
  const togglePhaseVisibility = (phaseId: string) => {
    setVisiblePhases((prev) => ({
      ...prev,
      [phaseId]: !prev[phaseId],
    }));
  };

  const { chartData, currentDate } = useMemo(() => {
    if (!data?.flow_by_phase) {
      return { chartData: [], currentDate: null };
    }

    // Find dates that have actual data (non-null values)
    const allChartData = Object.entries(data.flow_by_phase)
      .map(([date, values]) => ({
        date,
        ...values,
      }))
      .filter((item) => {
        // Check if any phase has a non-null value
        return Object.entries(item).some(([key, value]) => {
          if (key === 'date') return false;
          return value !== null && typeof value === 'number' && value > 0;
        });
      })
      // Sort dates in ascending order for the chart
      .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

    // Keep all dates but null out values after selected date
    const processedChartData = allChartData.map((item) => {
      if (selectedDate && dayjs(item.date).diff(selectedDate) > 0) {
        // Keep the date but null out all phase values
        const nulledItem: ChartDataPoint = { date: item.date };
        phaseMetadata.forEach((phase) => {
          nulledItem[`tasks_${phase.id}`] = null;
          nulledItem[`points_${phase.id}`] = null;
        });
        return nulledItem;
      }
      return item;
    });

    // Get the last date with data (most recent)
    const lastDate = selectedDate || allChartData[allChartData.length - 1]?.date;

    return { chartData: processedChartData, currentDate: lastDate };
  }, [data?.flow_by_phase, selectedDate, phaseMetadata]);

  if (!currentDate || !chartData.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon icon="material-symbols:info-outline" width={24} height={24} color={newCOLORS.darkGray} />
          <span style={{ fontSize: '16px' }}>No Flow Data Found</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <PhaseCards
        data={data.flow_by_phase[currentDate]}
        metric={metric}
        date={currentDate}
        phaseMetadata={phaseMetadata}
        initiativeId={initiative.id}
        epicId={selectedEpic || null}
        initiative={initiative}
        visiblePhases={visiblePhases}
        togglePhaseVisibility={togglePhaseVisibility}
      />
      <ResponsiveContainer width="100%" height="70%">
        <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(date) => dayjs(date).format('MM/DD')} />
          <YAxis />
          <Tooltip
            labelFormatter={(date) => dayjs(date).format('YYYY-MM-DD')}
            formatter={(value: any, name: string) => {
              const phase = phaseMetadata.find((p) => p.label === name);
              if (!phase) return [value, name];
              return [`${Math.round(value || 0)} ${metric}`, `${name} (${phase.description})`];
            }}
            contentStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '8px 12px',
            }}
            itemStyle={{
              padding: '2px 0',
            }}
          />
          {phaseMetadata
            .filter((phase) => visiblePhases[phase.id])
            .map((phase) => {
              return (
                <Area
                  key={phase.id}
                  type="monotone"
                  dataKey={metric === 'tasks' ? `tasks_${phase.id}` : `points_${phase.id}`}
                  stackId="1"
                  stroke={phase.color}
                  fill={phase.color}
                  name={phase.label}
                  connectNulls={false}
                />
              );
            })}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
