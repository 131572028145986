import { styled } from '@linaria/react';
import { Card, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { newCOLORS } from '../../../../styles/colors';
import { useTaskSelection } from '../../tasks.context';
import { useWorkPeriodsContext } from '../../work-periods.context';
import { WorkPeriod } from '../../work-periods.type';
import { formatKeyMeasuresData } from './helpers/key-measures-section.helpers';
type KeyMeasuresSectionProps = { selectedWorkPeriod: WorkPeriod | null };

export function KeyMeasuresSection({ selectedWorkPeriod }: KeyMeasuresSectionProps) {
  const { keyMeasuresData, dateRange } = useWorkPeriodsContext();
  const { selectMeasureByDateRange, selectMeasureByWorkPeriod } = useTaskSelection();

  // Determine the date range to use based on selected work period or date range
  const startDate = selectedWorkPeriod
    ? dayjs(selectedWorkPeriod.start_date).format('YYYY-MM-DD')
    : dateRange.startDate
      ? dayjs(dateRange.startDate).format('YYYY-MM-DD')
      : null;

  const endDate = selectedWorkPeriod
    ? dayjs(selectedWorkPeriod.end_date).format('YYYY-MM-DD')
    : dateRange.endDate
      ? dayjs(dateRange.endDate).format('YYYY-MM-DD')
      : null;

  const measures = formatKeyMeasuresData(keyMeasuresData);

  const handleMeasureClick = (measureName: string) => {
    // Both start and end dates must be available
    if (!startDate || !endDate) return;

    if (selectedWorkPeriod) {
      // If we have a work period, use work period ID and date range
      selectMeasureByWorkPeriod(measureName, selectedWorkPeriod.id, startDate, endDate);
    } else {
      // Otherwise use just the date range
      selectMeasureByDateRange(measureName, startDate, endDate);
    }
  };

  return keyMeasuresData ? (
    <SectionContainer>
      <SectionHeader>
        <Text fw={500} size="lg">
          Key Process Measures
        </Text>
        <Text size="sm" color="dimmed">
          Performance metrics for the selected work period
        </Text>
      </SectionHeader>

      <MeasuresGrid>
        {measures.map((measure) => {
          // Card is clickable if we have both start and end dates
          const isClickable = !!(startDate && endDate);

          return (
            <MeasureCard
              key={measure.id}
              $color={measure.color}
              $clickable={isClickable}
              onClick={() => isClickable && handleMeasureClick(measure.id)}
            >
              <MeasureHeader>
                <MeasureTitle>
                  {measure.name}
                  {isClickable && <ClickableIndicator>🔍</ClickableIndicator>}
                </MeasureTitle>
              </MeasureHeader>

              <MeasureValue
                $color={
                  measure.id === 'readiness' && keyMeasuresData.readiness?.value
                    ? keyMeasuresData.readiness.value < 60
                      ? 'red'
                      : 'green'
                    : measure.id === 'review_time' && keyMeasuresData.review_time?.value
                      ? keyMeasuresData.review_time.value > 60
                        ? 'red'
                        : 'green'
                      : 'inherit'
                }
              >
                {measure.value}
              </MeasureValue>

              <MeasureDescription>{measure.description}</MeasureDescription>
            </MeasureCard>
          );
        })}
      </MeasuresGrid>
    </SectionContainer>
  ) : (
    <Card p="md" withBorder>
      <Text fw={500} size="lg" mb="xs">
        Key Process Measures
      </Text>
      <Text color="dimmed">Select a work period or date range to view key measures.</Text>
    </Card>
  );
}

// Styled components remain the same
const SectionContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
`;

const MeasuresGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
`;

const MeasureCard = styled.div<{ $color: string; $clickable: boolean }>`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;
  border: 2px solid ${(props) => (props.$clickable ? props.$color : newCOLORS.lightGray)};
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
  transition: all 0.2s ease-in-out;
  
  &:hover {
    border-color: ${(props) => (props.$clickable ? props.$color : newCOLORS.lightGray)};
    box-shadow: ${(props) => (props.$clickable ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none')};
    transform: ${(props) => (props.$clickable ? 'translateY(-2px)' : 'none')};
  }
`;

const MeasureHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MeasureTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${newCOLORS.darkGray};
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MeasureValue = styled.div<{ $color: string }>`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => (props.$color !== 'inherit' ? props.$color : newCOLORS.darkGray)};
  margin-top: auto;
`;

const MeasureDescription = styled.div`
  font-size: 12px;
  color: ${newCOLORS.gray};
  margin-top: 2px;
`;

const ClickableIndicator = styled.span`
  font-size: 14px;
  vertical-align: middle;
  margin-left: 6px;
  opacity: 0.8;
`;
