import { DotProps } from 'recharts';

type Props = DotProps & {
  color: string;
};

export const LineChartDot = ({ color, ...props }: Props) => {
  const { cx, cy } = props;

  return (
    <g>
      {/* Large semi-transparent circle */}
      <circle cx={cx} cy={cy} r={8} fill={color} fillOpacity={0.2} />
      {/* Inner colored circle */}
      <circle cx={cx} cy={cy} r={3.5} stroke="none" fill={color} />
    </g>
  );
};
