import { Select } from '@mantine/core';
import { Fragment, forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { Team } from '../../api/projects-client/projects-client.type';
import { TeamContext } from '../../contexts';
import { useGlobalStore } from '../../store/global-store/global-store';
import { StandardText } from '../../styles/new-shared-styled-components/new-shared-styled-components';

export type TeamSelectRef = {
  getTeams: () => Team[];
};

export type TeamSelectProps = {
  handleTeamSelected?: (team: Team) => void;
  hideLabel?: boolean;
  includeAggregate?: boolean;
};

export const TeamSelect = forwardRef<TeamSelectRef, TeamSelectProps>(
  ({ hideLabel, handleTeamSelected, includeAggregate }, ref) => {
    const { team, setTeam } = useContext(TeamContext);

    const teams = useGlobalStore((state) =>
      includeAggregate
        ? [{ id: 'aggregate', name: 'Portfolio (All Teams)', subprojects: [] }, ...state.teams]
        : state.teams,
    );

    useEffect(() => {
      if (teams.length > 0 && (!team || !teams.find((tm) => tm.id == team.id))) {
        setTeam(teams[0]);
      }
    }, [teams, team, setTeam]);

    useImperativeHandle(ref, () => ({
      getTeams: () => {
        return teams;
      },
    }));

    const teamSelected = (team: Team) => {
      handleTeamSelected?.(team);
    };

    const refactorDropdownData = (teams: Team[]) => {
      const refactoredPortfolios = teams.map((tm) => {
        return { value: tm.id, label: tm.name };
      });
      return refactoredPortfolios;
    };

    return (
      <Fragment>
        {!hideLabel && <StandardText style={{ marginLeft: 16, marginRight: 8 }}>Team</StandardText>}
        <Select
          data={refactorDropdownData(teams)}
          value={team?.id}
          onChange={(value) => {
            teamSelected(teams.find((tm) => tm.id == value) as Team);
          }}
          allowDeselect={false}
          styles={{
            input: {
              paddingRight: 30,
            },
          }}
        />
      </Fragment>
    );
  },
);
