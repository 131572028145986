enum PerformanceTab {
  Throughput = 'throughput',
  Focus = 'focus',
  FlowByPhase = 'flow_by_phase',
  Targets = 'targets',
  Cost = 'cost',
  RelatedTasks = 'related_tasks',
}

enum ThroughputMode {
  InitiativeProgress = 'initiative_progress',
  ContributingEpics = 'contributing_epics',
  ContributingTeams = 'contributing_teams',
}

export { PerformanceTab, ThroughputMode };
