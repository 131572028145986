import AxiosInstance from '../../helpers/axios-instance/axios-instance';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Get the Velt JWT token for the given organization ID
 *
 * @param {string} organizationId - The ID of the organization to get the Velt JWT token for
 * @returns {Promise<string>} The Velt JWT token for the given organization ID
 */
const getVeltJwtToken = async (organizationId: string): Promise<string> => {
  try {
    const apiUrl = baseURL + '/api/comments/velt/jwt/';
    const response = await axiosInstance.get(apiUrl, {
      params: { organization_id: organizationId },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    return data.token;
  } catch (error) {
    console.error('Error getting Velt JWT token:', error);
    throw error;
  }
};

export { getVeltJwtToken };
