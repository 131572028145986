import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  CustomMeasureDataResponse,
  Measure,
  MeasureDataResponse,
  MeasureMetadata,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useOrganizationId } from '../../../helpers/auth-helpers/auth.hooks';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { useAvailableTargets } from '../../adherence/targets/targets-client.hooks';
import { GetAvailableTargetsPayload, TransformedTarget } from '../../adherence/targets/targets-client.type';
import { splitMeasures } from '../comparison-view/comparison-view.helpers';
import { useMeasureFilter } from '../comparison-view/comparison-view.hooks';
import { getEntityType } from '../process-analysis.helpers';
import { Tab } from '../process-analysis.type';
import { MeasureComparisonType } from './measure-comparison.type';

/**
 * Creates a payload for fetching work period measures.
 *
 * @param {UseWorkPeriodsMeasurePayloadMultiple} basePayload - The base payload for the request.
 * @param {Measure[]} measures - The list of measures to be fetched.
 * @param {MeasureComparisonType} type - The type of measure comparison to fetch.
 * @returns {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const makePayload = (
  basePayload: UseWorkPeriodsMeasurePayloadMultiple,
  measures: Measure[],
  type: MeasureComparisonType,
): UseWorkPeriodsMeasurePayloadMultiple => ({
  ...basePayload,
  measure_name: measures,
  ...(type === MeasureComparisonType.Transformer && { transformer: Transformer.TimeAllocationValuesOnly }),
});

/**
 * Custom hook that fetches measure comparison data.
 *
 * @param {MeasureComparisonType} type - The type of measure comparison to fetch.
 * @returns {{ data: MeasureDataResponse | null; isFetching: boolean }}
 */
const useMeasureComparisonData = (
  type: MeasureComparisonType,
): { data: MeasureDataResponse | null; isFetching: boolean } => {
  const entities = useEntities();
  const entity = entities[0];

  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const measures = type === MeasureComparisonType.Default ? defaultMeasures : transformerMeasures;

  const basePayload = usePayload(measures);
  const options = {
    enabled: measures.length > 0,
  };

  const { data, isFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, measures, type),
    options,
  });

  const parsedData = (data: any) => (data && entity ? data[entity] : null);

  return {
    data: parsedData(data),
    isFetching: isFetching,
  };
};

/**
 * Custom hook that fetches measure comparison data in multiple groups.
 *
 * @param {MeasureComparisonType} type - The type of measure comparison to fetch.
 * @returns {{ data: MeasureDataResponse | null; isFetching: boolean }}
 */
const useMeasureComparisonDataGroupCalls = (
  type: MeasureComparisonType,
): { data: MeasureDataResponse | null; isFetching: boolean } => {
  const entities = useEntities();
  const entity = entities[0];

  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const measures = type === MeasureComparisonType.Default ? defaultMeasures : transformerMeasures;
  const groups = splitMeasures(measures, 6);

  const basePayload = usePayload(measures);
  const options = { enabled: measures.length > 0 };

  const { data: firstData, isFetching: isFirstFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[0], type),
    options,
  });
  const { data: secondData, isFetching: isSecondFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[1], type),
    options,
  });
  const { data: thirdData, isFetching: isThirdFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[2], type),
    options,
  });
  const { data: fourthData, isFetching: isFourthFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[3], type),
    options,
  });
  const { data: fifthData, isFetching: isFifthFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[4], type),
    options,
  });
  const { data: sixthData, isFetching: isSixthFetching } = useWorkPeriodsMeasureMultiple({
    payload: makePayload(basePayload, groups[5], type),
    options,
  });

  const parsedData = (data: any) => (data && entity ? data[entity] : null);
  const allParsedData = [
    parsedData(firstData),
    parsedData(secondData),
    parsedData(thirdData),
    parsedData(fourthData),
    parsedData(fifthData),
    parsedData(sixthData),
  ].filter(Boolean);

  const combinedData = allParsedData.length ? Object.assign({}, ...allParsedData) : null;

  return {
    data: combinedData,
    isFetching:
      isFirstFetching || isSecondFetching || isThirdFetching || isFourthFetching || isFifthFetching || isSixthFetching,
  };
};

/**
 * Custom hook that returns a payload for fetching measure comparison data.
 *
 * @param {Measure[]} measures - The list of measures for the payload
 * @param {boolean} [isCustom] - Whether these are custom measures
 * @return {UseWorkPeriodsMeasurePayloadMultiple} - The payload for fetching work period measures.
 */
const usePayload = (measures: Measure[], isCustom?: boolean): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const timeAllocation = useTimeAllocation();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
    is_custom: isCustom,
  };
};

/**
 * Custom hook that fetches and combines measure data for multiple groups of custom measures.
 *
 * @param {Measure[]} measures - The list of measures to fetch data for
 * @return {{ data: CustomMeasureDataResponse | undefined; isFetching: boolean }} Object containing:
 *   - data: Combined measure data response or null if no data
 *   - isFetching: Boolean indicating if any data is still being fetched
 */

const useMeasureComparisonCustomMeasureData = (
  measures: MeasureMetadata[],
): { data: CustomMeasureDataResponse | undefined; isFetching: boolean } => {
  const customMeasures = measures.map((measure) => measure.measure_name);
  const entities = useEntities();
  const entity = entities[0];

  const payload = usePayload(customMeasures, true);
  payload.transformer = Transformer.ReturnFirstMetricOnly;

  const { data, isFetching } = useWorkPeriodsMeasureMultiple({
    payload,
    options: {
      enabled: measures.length > 0,
    },
  });
  const parsedData = (data: any) => (data && entity ? data[entity] : null);
  return {
    data: parsedData(data),
    isFetching,
  };
};

/**
 * Custom hook that fetches available targets for measure comparison.
 *
 * @returns {TransformedTarget[]} The available targets
 */
const useMeasureComparisonTargets = (): TransformedTarget[] => {
  const organizationId = useOrganizationId();
  const { startDate, endDate } = useDateRange();
  const timeAllocation = useTimeAllocation();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  let payload: GetAvailableTargetsPayload = {
    organization_id: organizationId || '',
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    time_allocation_type: timeAllocation,
  };
  const entities = useEntities();

  switch (activeTab) {
    case Tab.Portfolios:
      payload.portfolio_ids = entities.join(',');
      break;
    case Tab.Teams:
      payload.project_ids = entities.join(',');
      break;
    case Tab.Boards:
      payload.subproject_ids = entities.join(',');
      break;
  }

  const { data: availableTargets } = useAvailableTargets({
    payload,
  });

  return availableTargets?.[entities[0]] ?? [];
};

export {
  useMeasureComparisonCustomMeasureData,
  useMeasureComparisonData,
  useMeasureComparisonDataGroupCalls,
  useMeasureComparisonTargets,
};
