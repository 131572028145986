import { styled } from '@linaria/react';
import { Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DownloadPNGButton } from '../../../../components/download-png-button';
import { newCOLORS } from '../../../../styles/colors';
import { MeasureMetadata, WorkPeriod } from '../../work-periods.type';
import { forecastNextValue, getLastValue, handleDownload } from '../helpers/measure-comparison-chart.helpers';

// Define chart colors
const CHART_COLORS = [
  newCOLORS.blue,
  newCOLORS.orangeRed,
  newCOLORS.green,
  newCOLORS.darkYellow,
  newCOLORS.pinkishPurple,
  newCOLORS.tangerine,
  newCOLORS.skyLighter,
  newCOLORS.orange,
];

interface MeasureComparisonChartProps {
  customMeasures: MeasureMetadata[];
  measureData: Record<string, any>;
  workPeriods: WorkPeriod[];
  onBarClick?: (measureName: string, workPeriodName: string) => void;
}

interface ChartDataPoint {
  name: string;
  isForecast?: boolean;
  [key: string]: any;
}

export const MeasureComparisonChart = ({
  customMeasures,
  measureData,
  workPeriods,
  onBarClick,
  externalSelectedMeasures,
  externalShowLabels,
}: MeasureComparisonChartProps & {
  externalSelectedMeasures?: string[];
  externalShowLabels?: boolean;
}) => {
  const downloadRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  const [internalSelectedMeasures, setInternalSelectedMeasures] = useState<string[]>(
    customMeasures.length > 0 ? [customMeasures[0].measure_name] : [],
  );
  const [internalShowForecast] = useState<boolean>(false);
  const [internalShowLabels, setInternalShowLabels] = useState<boolean>(true);

  const selectedMeasures = externalSelectedMeasures !== undefined ? externalSelectedMeasures : internalSelectedMeasures;
  const showLabels = externalShowLabels !== undefined ? externalShowLabels : internalShowLabels;
  const showForecast = internalShowForecast;

  useEffect(() => {
    if (externalSelectedMeasures !== undefined) {
      setInternalSelectedMeasures(externalSelectedMeasures);
    }
  }, [externalSelectedMeasures]);

  useEffect(() => {
    if (externalShowLabels !== undefined) {
      setInternalShowLabels(externalShowLabels);
    }
  }, [externalShowLabels]);

  const chartData: ChartDataPoint[] = [];

  // Group measures by work period
  workPeriods.forEach((workPeriod) => {
    const dataPoint: ChartDataPoint = {
      name: workPeriod.name,
      workPeriodId: workPeriod.id,
    };

    // Add values for each selected measure
    selectedMeasures.forEach((measureName) => {
      const measureValues = measureData[workPeriod.id]?.[measureName];
      if (measureValues) {
        const lastValue = getLastValue(measureValues);
        dataPoint[measureName] = lastValue !== null ? lastValue : null;

        // Add forecast if enabled
        if (showForecast) {
          const values = Object.values(measureValues).map(Number);
          const forecastValue = forecastNextValue(values);
          if (forecastValue !== null) {
            dataPoint[`${measureName}_forecast`] = forecastValue;
          }
        }
      } else {
        dataPoint[measureName] = null;
      }
    });

    chartData.push(dataPoint);
  });

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipHeader>{label}</TooltipHeader>
          {payload.map((entry: any, index: number) => {
            // Skip target entries in the tooltip display
            if (entry.dataKey.includes('_target')) return null;

            const measure = customMeasures.find((m) => m.measure_name === entry.dataKey);
            return (
              <TooltipRow key={`tooltip-${index}`}>
                <TooltipLabel style={{ color: entry.color }}>{measure?.measure_title || entry.dataKey}:</TooltipLabel>
                <TooltipValue>
                  {entry.value.toFixed(measure?.measure_units === 'Percentage' ? 1 : 0)}
                  {measure?.measure_units === 'Percentage' ? '%' : measure?.measure_units === 'Points' ? ' pts' : ''}
                </TooltipValue>
              </TooltipRow>
            );
          })}
        </TooltipContainer>
      );
    }
    return null;
  };

  const renderCustomBarLabel = (props: any) => {
    const { x, y, width, /* height is unused */ value, fill } = props;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 6}
          fill={fill}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={12}
          fontWeight="bold"
        >
          {value.toFixed(0)}
        </text>
      </g>
    );
  };

  return (
    <DropShadowContainer>
      <TitleContainer>
        <Title order={2}>Measure Comparison</Title>
        <DownloadButtonsContainer>
          <DownloadPNGButton
            handleDownload={() => handleDownload(selectedMeasures, chartData)}
            exceptionRef={downloadRef}
          />
        </DownloadButtonsContainer>
      </TitleContainer>

      <ChartContainer ref={chartRef}>
        {chartData.length > 0 && selectedMeasures.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
              barGap={10}
              barCategoryGap={30}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} tick={{ fontSize: 12 }} interval={0} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />

              {selectedMeasures.map((measureName, index) => {
                const measure = customMeasures.find((m) => m.measure_name === measureName);
                const color = CHART_COLORS[index % CHART_COLORS.length];

                return (
                  <Bar
                    key={measureName}
                    dataKey={measureName}
                    name={measure?.measure_title || measureName}
                    fill={color}
                    onClick={(data) => {
                      if (onBarClick) {
                        onBarClick(measureName, data.name);
                      }
                    }}
                  >
                    {showLabels && <LabelList dataKey={measureName} position="top" content={renderCustomBarLabel} />}
                  </Bar>
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <EmptyState>
            <Text>Select at least one measure and work period to view the chart.</Text>
          </EmptyState>
        )}
      </ChartContainer>
    </DropShadowContainer>
  );
};

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;

const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TooltipHeader = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 1px solid #eee;
  padding-bottom: 4px;
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const TooltipLabel = styled.span`
  font-weight: 500;
  margin-right: 8px;
`;

const TooltipValue = styled.span`
  font-weight: normal;
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
`;
