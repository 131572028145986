import { ActionIcon, Box, Card, Flex, Group, Select, Stack, Tooltip } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { Component, ComponentData } from '../../../api/pages-client/pages-client.type';
import { newCOLORS } from '../../../styles/colors';
import { secondaryBase, skyDark } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { TargetModal } from '../../adherence/targets/target-modal';
import { useCreateTarget } from '../../adherence/targets/targets-client.hooks';
import { TargetPayload, TransformedTarget } from '../../adherence/targets/targets-client.type';
import { getTargetComparisonSymbol, getTimeAllocationTypeLabel } from '../../adherence/targets/targets.helpers';
import { DeleteConfirmationModal } from '../../data-management/components/delete-confirmation-modal';
import { getWidgetIcon } from '../dashboards.helpers';
import { ChartType, DATE_RANGES } from '../dashboards.types';
import { useComponentDataAsTarget, useWidgetData, useWidgetTargets } from '../hooks/dashboards.hooks';
import { AddWidgetModal } from './add-widget-modal';
import { ChartWidget } from './chart-widget';
import { TableWidget } from './table-widget';
import { TileWidget } from './tile-widget';

interface DashboardWidgetProps {
  component: Component;
  onEdit: (updatedWidget: Component) => void;
  onDelete: () => void;
}

const defaultComponentData: ComponentData = {
  id: 'default',
  measure: '',
  range_type: 'last_30_days',
  object_type: 'project',
  object_ids: [],
};

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({ component, onEdit, onDelete }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<TransformedTarget | null>(null);
  const [isAddTargetModalOpen, setIsAddTargetModalOpen] = useState(false);
  const { chartData, tableData, tileData, isLoading, error } = useWidgetData(
    component.component_data?.[0] ?? defaultComponentData,
  );
  const { data: targets } = useWidgetTargets(component.component_data?.[0] ?? defaultComponentData);
  const { createTarget, isCreating } = useCreateTarget();
  const targetPayload = useComponentDataAsTarget(component.component_data?.[0] ?? defaultComponentData);

  const transformedTargets = useMemo(() => {
    if (!targets) return [];

    const flattenedTargets = Object.values(targets).flat();
    const uniqueTargets = flattenedTargets.filter(
      (target, index, self) => index === self.findIndex((t) => t.id === target.id),
    );
    return uniqueTargets;
  }, [targets]);

  if (isLoading) {
    return (
      <Card shadow="sm" radius="md" withBorder style={{ height: '100%', maxWidth: '98%' }}>
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Text textKey="common.dashboard.loading">Loading...</Text>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card shadow="sm" radius="md" withBorder style={{ height: '95%', maxWidth: '98%' }}>
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Text color={newCOLORS.red} textKey="common.dashboard.error_loading">
            Error loading data
          </Text>
        </Flex>
      </Card>
    );
  }

  const renderWidgetContent = () => {
    switch (component.type) {
      case 'chart':
        return (
          <ChartWidget
            type={(component.sub_type as ChartType) || 'line'}
            data={chartData}
            selectedTarget={selectedTarget}
          />
        );
      case 'table':
        return <TableWidget data={tableData} selectedTarget={selectedTarget} />;
      case 'tile':
        return <TileWidget data={tileData} selectedTarget={selectedTarget} />;
      case 'metric':
        return (
          <Box>
            <Text size="regular">{tileData?.average?.toFixed(2) ?? 'N/A'}</Text>
            <Text size="small">Total: {tileData?.total?.toFixed(2) ?? 'N/A'}</Text>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleSaveEdit = (updatedWidget: Component) => {
    onEdit(updatedWidget);
    setIsEditModalOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setIsDeleteModalOpen(false);
  };

  const handleCreateTarget = async (data: Partial<TargetPayload>) => {
    try {
      await createTarget(data);
      setIsAddTargetModalOpen(false);
    } catch (error) {
      console.error('Error creating target', error);
    }
  };

  const editAndDeleteActions = (
    <Group gap={4}>
      <Tooltip label="Edit Widget">
        <ActionIcon variant="subtle" onClick={() => setIsEditModalOpen(true)} size="sm" radius="xl" color={skyDark}>
          <Icon name="edit" size={16} />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Delete Widget">
        <ActionIcon variant="subtle" onClick={() => setIsDeleteModalOpen(true)} size="sm" radius="xl" color="red">
          <Icon name="delete" size={16} />
        </ActionIcon>
      </Tooltip>
    </Group>
  );

  return (
    <>
      <Card shadow="md" radius="md" withBorder style={{ height: '95%', maxWidth: '99%' }}>
        <Card.Section inheritPadding py="sm">
          {component.show_title ? (
            <Stack gap="xs">
              <Flex justify="space-between" align="center">
                <Flex align="center" gap={6}>
                  <Icon name={getWidgetIcon(component.type, component.sub_type)} size={18} color={skyDark} />
                  <Text>{component.name}</Text>
                  <Tooltip label={component.description || 'No Description Provided'} position="right">
                    <ActionIcon variant="subtle" size="sm" radius="xl">
                      <Icon name="help_outline" size={16} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Total of all values">
                    <Flex align="center" gap={4}>
                      <Icon name="functions" size={14} />
                      <Text size="tiny" color="dimmed">
                        Total: {tileData.total.toLocaleString()}
                      </Text>
                    </Flex>
                  </Tooltip>
                  <Tooltip label="Average value">
                    <Flex align="center" gap={4}>
                      <Icon name="timeline" size={14} />
                      <Text size="tiny" color="dimmed">
                        Avg: {tileData.average.toLocaleString()}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Flex>
                {editAndDeleteActions}
              </Flex>

              <Flex justify="space-between" align="center">
                <Text size="small" color="dimmed">
                  {DATE_RANGES.find((range) => range.value === component.component_data?.[0]?.range_type)?.label}
                </Text>
                <Group gap="sm">
                  <Select
                    data={transformedTargets.map((target) => ({
                      value: target.id,
                      label: target.name || `Target for ${target.measure}`,
                    }))}
                    value={selectedTarget?.id || null}
                    placeholder="Select Target"
                    onChange={(value) => {
                      if (value) {
                        const target = transformedTargets.find((t) => t.id === value) || null;
                        setSelectedTarget(target);
                      } else {
                        setSelectedTarget(null);
                      }
                    }}
                    radius="lg"
                    w="175px"
                    size="xs"
                    leftSection={
                      selectedTarget ? (
                        <Tooltip
                          label={
                            <Stack gap={0}>
                              <Text size="small" weight="bold">
                                {selectedTarget.name}
                              </Text>
                              <Text size="tiny">
                                {`${selectedTarget.measure}: 
                      ${getTargetComparisonSymbol(
                        selectedTarget.target_comparison,
                        selectedTarget.target_value,
                        selectedTarget.target_value_upper,
                      )} 
                    ${getTimeAllocationTypeLabel(selectedTarget.time_allocation_type)}`}
                              </Text>
                              <Text size="tiny">
                                {`${new Date(selectedTarget.start_date).toLocaleDateString()} - ${
                                  selectedTarget.end_date
                                    ? new Date(selectedTarget.end_date).toLocaleDateString()
                                    : 'Present'
                                }`}
                              </Text>
                            </Stack>
                          }
                        >
                          <Icon name="info" size={14} />
                        </Tooltip>
                      ) : null
                    }
                  />
                  <Tooltip label="Add Target">
                    <Icon
                      name="add_circle_outline"
                      size={20}
                      color={secondaryBase}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIsAddTargetModalOpen(true)}
                    />
                  </Tooltip>
                </Group>
              </Flex>
            </Stack>
          ) : (
            <Flex justify="flex-end" align="center">
              {editAndDeleteActions}
            </Flex>
          )}
        </Card.Section>
        <Card.Section inheritPadding py="md">
          {renderWidgetContent()}
        </Card.Section>
      </Card>

      <AddWidgetModal
        opened={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleSaveEdit}
        initialData={component}
        isEditing
      />

      <DeleteConfirmationModal
        itemName={component.name}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        title="Delete Widget"
        isDeleting={false}
      />

      <TargetModal
        opened={isAddTargetModalOpen}
        onClose={() => setIsAddTargetModalOpen(false)}
        onSave={handleCreateTarget}
        initialData={targetPayload}
        isCreating={true}
        isLoading={isCreating}
      />
    </>
  );
};
