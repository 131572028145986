import { TaskType } from '../../containers/tasks-table/tasks-table.type';
import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import { GraphData, GraphType, ProcessMapping, ProcessTasksData } from './process-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL + '/api/process/';

/**
 * Fetches the process graph data
 *
 * @param {string} project_id - The ID of the project
 * @param {string} subproject_id - The ID of the subproject (optional)
 * @param {TaskType} task_type - The type of task (optional)
 * @param {string} epic_id - The ID of the epic (optional)
 * @param {GraphType} graph_type - The type of graph (optional)
 * @param {string} start_date - The start date (optional)
 * @param {string} end_date - The end date (optional)
 * @return {Promise<GraphData>} - A Promise that resolves to the process graph data
 */
const getProcessGraph = async ({
  project_id,
  workflow_id,
  subproject_id,
  task_type,
  epic_id,
  graph_type,
  start_date,
  end_date,
  bloomfilter_sections,
}: {
  project_id?: string;
  workflow_id?: string;
  subproject_id?: string;
  task_type?: TaskType;
  epic_id?: string;
  graph_type?: GraphType;
  start_date?: string;
  end_date?: string;
  bloomfilter_sections?: boolean;
}): Promise<GraphData> => {
  const url = `${baseURL}graph/`;

  // Only require project_id if workflow_id is not provided
  if (!project_id && !workflow_id) {
    return Promise.reject('Either project_id or workflow_id is required');
  }

  const params = {
    project_id,
    workflow_id,
    subproject_id,
    task_type,
    epic_id,
    graph_type,
    start_date,
    end_date,
    bloomfilter_sections,
  };
  return await axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching process graph'));
};

/**
 * Fetches tasks for the process map
 *
 * @param {string} project_id - The ID of the project
 * @param {string} subproject_id - The ID of the subproject (null if not specified)
 * @param {TaskType} task_type - The type of task (null if not specified)
 * @param {string} epic_id - The ID of the epic (optional)
 * @param {string} start_date - The start date (optional)
 * @param {string} end_date - The end date (optional)
 * @param {string} subgraph_path - The subgraph path (optional)
 * @return {Promise<ProcessTasksData>} - A Promise that resolves to the process tasks data
 */
const getProcessTasks = async (
  project_id: string,
  subproject_id: string | null,
  task_type: TaskType | null,
  epic_id: string | null,
  start_date?: string,
  end_date?: string,
  subgraph_path?: string,
): Promise<ProcessTasksData> => {
  const url = `${baseURL}tasks/`;
  const params = { project_id, subproject_id, task_type, epic_id, start_date, end_date, subgraph_path };
  return await axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching process tasks'));
};

/**
 * Fetch the process stages mapping for a process map
 *
 * @param {string} id - the ID of the project or workflow
 * @param {boolean} isWorkflow - whether the ID is a workflow ID (optional, default is false)
 * @return {Promise<ProcessMapping>} - A Promise that resolves to the process mapping data
 */
const getProcessMapping = async (id: string, isWorkflow = false): Promise<ProcessMapping> => {
  const url = `${import.meta.env.VITE_APP_API_URL}/api/${isWorkflow ? 'workflows' : 'projects'}/${id}/external-section-map/`;

  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching process mapping'));
};

export { getProcessGraph, getProcessMapping, getProcessTasks };
