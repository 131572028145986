import { Group, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { EmptyFilterResult } from '../../../components/filters/empty-filter-result';
import { baseWhite, inkLight } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { Table } from '../../data-management/components/table';
import { Target } from './targets-client.type';
import { sortData, TargetsTableColumns } from './targets-table.helpers';

const ITEMS_PER_PAGE = 20;

type Props = {
  targets: Target[];
  onTargetClick: (target: Target) => void;
  onCreateTarget: () => void;
};

export function TargetsTable({ targets, onTargetClick, onCreateTarget }: Props) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [filteredTargets, setFilteredTargets] = useState(targets);
  const [searchTerm, setSearchTerm] = useState('');

  // Reset search and filtered targets when targets changes
  useEffect(() => {
    setSearchTerm('');
    setFilteredTargets(targets);
    setSelectedPage(1);
  }, [targets]);

  return (
    <Stack>
      <Group justify="space-between">
        <Button
          variant="primary"
          radius="xl"
          size="sm"
          leftSection={<Icon name="add" color={baseWhite} variant="filled" size={16} />}
          onClick={onCreateTarget}
        >
          Create Target
        </Button>
        <Group>
          <TextInput
            placeholder="Search targets..."
            radius="xl"
            size="sm"
            value={searchTerm}
            leftSection={<Icon name="search" variant="filled" size={16} color={inkLight} />}
            style={{ width: 300 }}
            onChange={(event) => {
              const newSearchTerm = event.currentTarget.value.toLowerCase();
              setSearchTerm(newSearchTerm);
              const filtered = targets.filter(
                (target) =>
                  target.name?.toLowerCase().includes(newSearchTerm) ||
                  target.measure.toLowerCase().includes(newSearchTerm) ||
                  (target.portfolios.length > 0 &&
                    target.portfolios.some((p) => p.name.toLowerCase().includes(newSearchTerm))) ||
                  (target.projects.length > 0 &&
                    target.projects.some((p) => p.name.toLowerCase().includes(newSearchTerm))) ||
                  (target.subprojects.length > 0 &&
                    target.subprojects.some((p) => p.name.toLowerCase().includes(newSearchTerm))),
              );
              setFilteredTargets(filtered);
              setSelectedPage(1);
            }}
          />
        </Group>
      </Group>
      {filteredTargets.length > 0 ? (
        <Table<Target>
          data={filteredTargets}
          columns={TargetsTableColumns}
          getRowId={(target: Target) => target.id}
          onRowClick={(target: Target) => {
            onTargetClick(target);
          }}
          stickyHeader
          pageSize={ITEMS_PER_PAGE}
          currentPage={selectedPage}
          totalItems={filteredTargets.length}
          onPageChange={(page: number) => {
            setSelectedPage(page);
          }}
          customSort={sortData}
        />
      ) : (
        <EmptyFilterResult objectName="targets" />
      )}
    </Stack>
  );
}
