import dayjs from 'dayjs';
import { useWorkPeriodsMeasureMultiple } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  Measure,
  MeasureDataByEntity,
  TimeAllocationType,
  Transformer,
  UseWorkPeriodsMeasurePayloadMultiple,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterCustomMeasures,
  useAvailableMeasureNames,
  useDateRange,
  useEntities,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { getEntityType } from '../process-analysis.helpers';
import { splitMeasures } from './comparison-view.helpers';

const timeAllocationTransformerMeasures = [Measure.DeclinedChangeRequests, Measure.ReviewTime, Measure.Readiness];

/**
 * Custom hook to fetch key measures data over time.
 *
 * This hook fetches multiple sets of measure data:
 * - Default measures split into 6 groups to avoid query size limits
 * - Custom measures defined by the user
 * - Measures that require special transformers
 *
 * The data from all queries is merged into a single MeasureDataByEntity object.
 *
 * @param {TimeAllocationType} [overwriteTimeAllocation] - Optional time allocation type to override the default
 * @returns {Object} An object containing:
 * @property {MeasureDataByEntity | undefined} data - The merged measure data from all queries
 * @property {boolean} isFetching - True if any of the queries are still fetching
 */
const useKeyMeasuresOverTimeData = (
  overwriteTimeAllocation?: TimeAllocationType,
): { data: MeasureDataByEntity | undefined; isFetching: boolean } => {
  const { defaultMeasures, transformerMeasures } = useMeasureFilter();
  const timeAllocation = useTimeAllocation();
  const timeAllocationToUse = overwriteTimeAllocation || timeAllocation;
  const customMeasures = useProcessAnalysisStore((state) => state.availableMeasures)
    .filter(filterCustomMeasures)
    .map((measure) => measure.measure_name);

  const groups = splitMeasures(defaultMeasures, 6);

  const { data: defaultDataFirst, isFetching: isFetchingDefaultDataFirst } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[0], timeAllocationToUse),
    options: {
      enabled: !!groups[0]?.length,
    },
  });

  const { data: defaultDataSecond, isFetching: isFetchingDefaultDataSecond } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[1], timeAllocationToUse),
    options: {
      enabled: !!groups[1]?.length,
    },
  });

  const { data: defaultDataThird, isFetching: isFetchingDefaultDataThird } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[2], timeAllocationToUse),
    options: {
      enabled: !!groups[2]?.length,
    },
  });

  const { data: defaultDataFourth, isFetching: isFetchingDefaultDataFourth } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[3], timeAllocationToUse),
    options: {
      enabled: !!groups[3]?.length,
    },
  });

  const { data: defaultDataFifth, isFetching: isFetchingDefaultDataFifth } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[4], timeAllocationToUse),
    options: {
      enabled: !!groups[4]?.length,
    },
  });

  const { data: defaultDataSixth, isFetching: isFetchingDefaultDataSixth } = useWorkPeriodsMeasureMultiple({
    payload: usePayload(groups[5], timeAllocationToUse),
    options: {
      enabled: !!groups[5]?.length,
    },
  });

  const { data: customData, isFetching: isFetchingCustomData } = useWorkPeriodsMeasureMultiple({
    payload: useCustomPayload(customMeasures, timeAllocationToUse),
    options: {
      enabled: !!customMeasures.length,
    },
  });

  const transformerPayload = useTransformerPayload(transformerMeasures, timeAllocationToUse);
  const { data: transformerData, isFetching: isFetchingTransformerData } = useWorkPeriodsMeasureMultiple({
    payload: transformerPayload,
    options: {
      enabled: !!transformerMeasures.length,
    },
  });

  const data = Object.entries(defaultDataFirst || {})
    .concat(Object.entries(defaultDataSecond || {}))
    .concat(Object.entries(defaultDataThird || {}))
    .concat(Object.entries(defaultDataFourth || {}))
    .concat(Object.entries(defaultDataFifth || {}))
    .concat(Object.entries(defaultDataSixth || {}))
    .concat(Object.entries(transformerData || {}))
    .concat(Object.entries(customData || {}))
    .reduce((acc, [key, value]) => {
      acc[key] = { ...acc[key], ...value };
      return acc;
    }, {} as MeasureDataByEntity);

  const isFetching =
    isFetchingDefaultDataFirst ||
    isFetchingDefaultDataSecond ||
    isFetchingDefaultDataThird ||
    isFetchingDefaultDataFourth ||
    isFetchingDefaultDataFifth ||
    isFetchingDefaultDataSixth ||
    isFetchingTransformerData ||
    isFetchingCustomData;

  return { data, isFetching };
};

/**
 * Custom hook that returns a payload for fetching work period measures.
 * The payload includes entity type, ids, date range, measure names and time allocation type.
 *
 * @param {Measure[]} measures - Array of measure names to fetch
 * @param {TimeAllocationType} timeAllocation - The time allocation type for the payload
 * @returns {UseWorkPeriodsMeasurePayloadMultiple} The payload for fetching work period measures
 */
const usePayload = (measures: Measure[], timeAllocation: TimeAllocationType): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
  };
};

/**
 * Custom hook that returns a payload for fetching transformer work period measures.
 * Similar to usePayload but includes the transformer parameter.
 *
 * @param {Measure[]} measures - Array of measure names to fetch
 * @param {TimeAllocationType} timeAllocation - The time allocation type for the payload
 * @returns {UseWorkPeriodsMeasurePayloadMultiple} The payload for fetching transformer work period measures
 */
const useTransformerPayload = (
  measures: Measure[],
  timeAllocation: TimeAllocationType,
): UseWorkPeriodsMeasurePayloadMultiple => {
  const basePayload = usePayload(measures, timeAllocation);
  return {
    ...basePayload,
    transformer: Transformer.TimeAllocationValuesOnly,
  };
};

/**
 * Custom hook that returns a payload for fetching custom work period measures.
 * Similar to usePayload but includes additional parameters for custom measures.
 *
 * @param {string[]} measures - Array of custom measure names to fetch
 * @param {TimeAllocationType} timeAllocation - The time allocation type for the payload
 * @returns {UseWorkPeriodsMeasurePayloadMultiple} The payload for fetching custom work period measures
 */
const useCustomPayload = (
  measures: string[],
  timeAllocation: TimeAllocationType,
): UseWorkPeriodsMeasurePayloadMultiple => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  return {
    entity: getEntityType(activeTab),
    ids: entities,
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    measure_name: measures,
    time_allocation_type: timeAllocation,
    transformer: Transformer.ReturnFirstMetricOnly,
    is_custom: true,
  };
};

/**
 * Custom hook that filters measures into two categories:
 *   - `defaultMeasures`: measures that don't have a transformer
 *   - `transformerMeasures`: measures that have a transformer
 *
 * @returns {{ defaultMeasures: Measure[]; transformerMeasures: Measure[] }} - An object containing both types of measures.
 */
const useMeasureFilter = (): { defaultMeasures: Measure[]; transformerMeasures: Measure[] } => {
  const measures = useAvailableMeasureNames();

  return measures.reduce(
    (acc, measure) => {
      const isTransformerMeasure = timeAllocationTransformerMeasures.includes(measure);

      if (isTransformerMeasure) {
        return { ...acc, transformerMeasures: [...acc.transformerMeasures, measure] };
      }

      return { ...acc, defaultMeasures: [...acc.defaultMeasures, measure] };
    },
    { defaultMeasures: [] as Measure[], transformerMeasures: [] as Measure[] },
  );
};

export { timeAllocationTransformerMeasures, useKeyMeasuresOverTimeData, useMeasureFilter };
