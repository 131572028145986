import { Flex } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { baseWhite } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Icon } from '../../../ui-library/icon/icon';
import { H3 } from '../../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../../menu-driven-container/menu-driven-container';
import styles from '../views/views.module.css';

export const AddNewIntegrationSuccess = () => {
  const navigate = useNavigate();

  const header = (
    <Flex align="center" gap={16}>
      <Button
        variant="secondary"
        radius="xl"
        leftSection={<Icon name="arrow_back" size={18} color={baseWhite} />}
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Flex align="center" gap={8}>
        <Icon name="hub" size={32} />
        <H3>Add New Integrations</H3>
      </Flex>
    </Flex>
  );

  return (
    <MenuDrivenContainer header={header}>
      <div id={styles.viewContainer}>
        <p>Your integration has been started! You will receive an email once the integration is complete.</p>
        <p>You can close this tab now</p>
      </div>
    </MenuDrivenContainer>
  );
};
