import { Icon } from '@iconify/react/dist/iconify.js';
import { styled } from '@linaria/react';
import { Stack } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { ExtendedInitiative } from '../../../../api/initiative-client/initiative-client.type';
import {
  Measure,
  MeasureValuesOverTime,
  TimeAllocationType,
} from '../../../../api/work-periods-client/work-periods-client.type';
import { ComboboxSelect } from '../../../../components/combobox-select/combobox-select';
import { newCOLORS } from '../../../../styles/colors';
import { baseWhite, skyDark } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon as UiIcon } from '../../../../ui-library/icon/icon';
import { Loader } from '../../../../ui-library/loader/loader';
import { Text } from '../../../../ui-library/typography/typography';
import { TargetModal } from '../../../adherence/targets/target-modal';
import { useCreateTarget } from '../../../adherence/targets/targets-client.hooks';
import { TargetComparison, TargetPayload } from '../../../adherence/targets/targets-client.type';
import { TH } from '../../../process-analysis/key-measures/key-measures-over-time';
import {
  calculateAverage,
  calculateTrend,
  forecastNextValue,
  mergeMeasureMetaDataAndTargets,
} from '../../../process-analysis/measure-comparison/measure-comparison.helpers';
import { SelectedTarget } from '../../../process-analysis/measure-comparison/measure-comparison.type';
import { MeasureRow } from '../../../process-analysis/measure-comparison/measure-row';
import { getMeasureColor, toDisplayText } from '../../../process-analysis/process-analysis.helpers';
import { CollapsibleSection } from '../collapsible-section';
import { InitiativeTargetChart } from './initiative-target-chart';
import {
  useInitiativeAvailableMeasures,
  useInitiativeAvailableTargets,
  useInitiativeMeasureData,
} from './initiative-targets.hooks';

export function SectionTargets({ initiative }: { initiative: ExtendedInitiative }) {
  const { data: availableTargets, query } = useInitiativeAvailableTargets({ initiative });
  const { data: availableMeasures } = useInitiativeAvailableMeasures({ initiative });
  const { createTarget, isCreating: isCreatingTarget } = useCreateTarget();

  const [isTargetModalOpen, setIsTargetModalOpen] = useState(false);

  const [selectedMeasures, setSelectedMeasures] = useState<string[]>([]);
  const [selectedTrends, setSelectedTrends] = useState<string[]>([]);
  const [selectedTargets, setSelectedTargets] = useState<SelectedTarget[]>([]);
  const [displayedMeasures, setDisplayedMeasures] = useState<string[]>([]);

  const mergedMeasureMetaData = useMemo(
    () => mergeMeasureMetaDataAndTargets(availableMeasures || [], availableTargets || [], true),
    [availableMeasures, availableTargets],
  );

  const { data, isLoading } = useInitiativeMeasureData({ initiative, measureMetadata: mergedMeasureMetaData });

  const availableMeasureNames = useMemo(() => {
    if (!data) return [];

    const measureNames = new Set<string>();
    data.forEach((measureData) => {
      if (measureData) {
        Object.keys(measureData).forEach((measureName) => {
          measureNames.add(measureName);
        });
      }
    });

    return Array.from(measureNames);
  }, [data]);

  const removedMeasureOptions = useMemo(() => {
    return availableMeasureNames
      .filter((measure) => !displayedMeasures.includes(measure))
      .map((measure) => {
        const metadata = mergedMeasureMetaData.find((m) => m.measure_name === measure);
        return {
          value: measure,
          label: metadata?.measure_title || toDisplayText(measure),
        };
      });
  }, [availableMeasureNames, displayedMeasures, mergedMeasureMetaData]);

  const removeSelection = (measure: string) => {
    setDisplayedMeasures(displayedMeasures.filter((m) => m !== measure));
    setSelectedMeasures(selectedMeasures.filter((m) => m !== measure));
    setSelectedTrends(selectedTrends.filter((m) => m !== measure));
    setSelectedTargets(selectedTargets.filter((t) => t.measureId !== measure));
  };

  const addMeasure = (measure: string) => {
    setDisplayedMeasures([...displayedMeasures, measure]);
    setSelectedMeasures([...selectedMeasures, measure]);
  };

  useEffect(() => {
    if (mergedMeasureMetaData.length > 0) {
      const initialMeasures = mergedMeasureMetaData.map((measure) => measure.measure_name);
      setDisplayedMeasures(initialMeasures);
      setSelectedMeasures(initialMeasures);
    }
  }, [mergedMeasureMetaData]);

  const handleCreateTarget = async (target: Partial<TargetPayload>) => {
    try {
      await createTarget(target);
      setIsTargetModalOpen(false);
    } catch (error) {
      console.error('Error creating target', error);
    }
  };

  return (
    <CollapsibleSection
      titleElement={
        <TitleContainer>
          <Icon icon="material-symbols:target" width={20} height={20} color={newCOLORS.darkBlue} />
          <TitleText>{'Measures & Targets'}</TitleText>
        </TitleContainer>
      }
      title="Targets"
      additionalControls={
        <Button
          size="sm"
          radius="xl"
          variant="primary"
          leftSection={<UiIcon name="add" color={baseWhite} size={20} />}
          onClick={() => setIsTargetModalOpen(true)}
        >
          Add Target
        </Button>
      }
    >
      <div style={{ width: '100%', height: '100%' }}>
        {query.isLoading || isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <Loader size="md" variant="oval" />
          </div>
        ) : mergedMeasureMetaData.length == 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <Stack align="center" justify="center" style={{ height: '100%' }}>
              <UiIcon name="info" color={skyDark} size={40} />
              <Text color={skyDark} weight="medium">
                Add a target to start tracking your initiative performance
              </Text>
            </Stack>
          </div>
        ) : (
          <ContentContainer>
            <LegendContainer>
              <LegendScrollContainer>
                <LegendTable>
                  <thead>
                    <tr>
                      <StickyTH>Measures</StickyTH>
                      <StickyTH>Line</StickyTH>
                      <StickyTH>Trend</StickyTH>
                      <StickyTH></StickyTH>
                    </tr>
                    <tr style={{ height: '16px' }}></tr>
                  </thead>
                  <tbody>
                    {displayedMeasures.map((measureName) => {
                      // Create a properly typed MeasureValuesOverTime object for this measure
                      const measureValues: MeasureValuesOverTime = {};

                      // Collect all non-null values for this measure from all data items
                      data?.forEach((dataItem) => {
                        if (dataItem && measureName in dataItem) {
                          // Safely access the date values using type assertion
                          const dateValues = dataItem[measureName as keyof typeof dataItem] as Record<
                            string,
                            number | null
                          >;

                          // Add each non-null date value to our measureValues
                          Object.entries(dateValues || {}).forEach(([date, value]) => {
                            if (value !== null) {
                              measureValues[date] = value;
                            }
                          });
                        }
                      });

                      const color = getMeasureColor(measureName as Measure);
                      const measureMetadata = mergedMeasureMetaData.find((m) => m.measure_name === measureName);
                      const trendLine = calculateTrend(measureValues, measureMetadata?.is_zero_valid ?? true);
                      const forecast = forecastNextValue(trendLine, measureValues);

                      return (
                        <MeasureRow
                          key={measureName}
                          measure={measureName}
                          title={measureMetadata?.measure_title || toDisplayText(measureName)}
                          description={measureMetadata?.measure_description}
                          color={color}
                          isZeroValid={measureMetadata?.is_zero_valid ?? true}
                          targets={measureMetadata?.targets}
                          average={calculateAverage(measureValues, measureMetadata?.is_zero_valid ?? true)}
                          trend={trendLine?.slope ?? null}
                          forecast={forecast}
                          selectedMeasures={selectedMeasures}
                          selectedTrends={selectedTrends}
                          selectedTargets={selectedTargets}
                          setSelectedMeasures={(event) => {
                            const { checked } = event.currentTarget;
                            if (checked) {
                              setSelectedMeasures([...selectedMeasures, measureName]);
                            } else {
                              setSelectedMeasures(selectedMeasures.filter((val) => val !== measureName));
                              setSelectedTargets(selectedTargets.filter((t) => t.measureId !== measureName));
                            }
                          }}
                          setSelectedTrends={(event) => {
                            const { checked } = event.currentTarget;
                            if (checked) {
                              setSelectedTrends([...selectedTrends, measureName]);
                            } else {
                              setSelectedTrends(selectedTrends.filter((val) => val !== measureName));
                            }
                          }}
                          setSelectedTargets={setSelectedTargets}
                          removeSelection={removeSelection}
                        />
                      );
                    })}
                  </tbody>
                </LegendTable>
              </LegendScrollContainer>
              {removedMeasureOptions.length > 0 && (
                <DropdownContainer>
                  <ComboboxSelect
                    options={removedMeasureOptions}
                    onChange={(value: string) => {
                      addMeasure(value);
                    }}
                    placeholder="Select Measure"
                  />
                </DropdownContainer>
              )}
            </LegendContainer>
            <ChartContainer>
              <InitiativeTargetChart
                measureData={data}
                measureMetadata={mergedMeasureMetaData}
                selectedMeasures={selectedMeasures}
                selectedTrends={selectedTrends}
                selectedTargets={selectedTargets}
              />
            </ChartContainer>
          </ContentContainer>
        )}
      </div>
      <TargetModal
        opened={isTargetModalOpen}
        onClose={() => {
          setIsTargetModalOpen(false);
        }}
        onSave={handleCreateTarget}
        initialData={{
          initiatives: [
            {
              id: initiative.id,
              name: initiative.name,
              start_date: initiative.start_date ? new Date(initiative.start_date).toISOString() : '',
              end_date: initiative.end_date ? new Date(initiative.end_date).toISOString() : '',
            },
          ],
          start_date: initiative.start_date,
          end_date: initiative.end_date,
          time_allocation_type: TimeAllocationType.BiWeekly,
          target_comparison: TargetComparison.EQ,
          use_transform: true,
        }}
        isCreating={true}
        isLoading={isCreatingTarget}
      />
    </CollapsibleSection>
  );
}

const TitleText = styled.div`
  font-size: 16px;
  font-weight: 1000;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 400px;
  transition: width 300ms ease;
`;

const LegendTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 300px;
  table-layout: fixed;
`;

const ChartContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

const StickyTH = styled(TH)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  &:nth-child(1) {
    width: 160px;
  }
  &:nth-child(2) {
    width: 40px;
  }
  &:nth-child(3) {
    width: 40px;
  }
  &:nth-child(4) {
    width: 40px;
  }
`;

const LegendContainer = styled.div`
  flex: 0 0 auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LegendScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding-right: 16px;
`;
const DropdownContainer = styled.div`
  background-color: white;
  padding: 8px 0;
  border-top: 1px solid ${newCOLORS.lightGray};
`;
