import { ComboboxItemGroup } from '@mantine/core';
import { useQueries, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useDeepCompareEffect } from 'react-use';
import { getBoardsByTeam } from '../../../api/projects-client/projects-client';
import { ProjectsResponse } from '../../../api/projects-client/projects-client.type';
import { MinifiedTask } from '../../../api/tasks-client/task-client.type';
import {
  getWorkPeriods,
  getWorkPeriodsMeasure,
  getWorkPeriodsTasks,
} from '../../../api/work-periods-client/work-periods-client';
import {
  useAvailableMeasures,
  useWorkPeriodsMeasureSingular,
} from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  HealthScoreData,
  Measure,
  MeasureMetadata,
  ModuleType,
  Transformer,
  UseWorkPeriodsMeasurePayloadSingular,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { getLastNotNullValue } from '../../process-analysis/sprint-comparison-view/sprint-comparison-view.helpers';
import { FlowData, KeyProcessMeasuresData, ReadinessData, WorkPeriod, WorkPeriodType } from '../work-periods.type';

/**
 * Custom hook to fetch work periods for a given board
 *
 * @param {string | null} boardId - The ID of the board
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useWorkPeriodsData = (boardId: string | null) => {
  const query = useQuery({
    queryKey: ['work-periods', boardId],
    queryFn: () => getWorkPeriods({ subproject_id: boardId || '' }),
    enabled: !!boardId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return { data: query.data, isLoading: query.isLoading, isFetching: query.isFetching };
};

/**
 * Custom hook to create the payload for fetching measures data
 *
 * @param {Measure[]} measures - An array of measures to be fetched
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {{ startDate: Date | null; endDate: Date | null }} dateRange - The date range for ad-hoc work periods
 * @param {WorkPeriodType} workPeriodType - The type of work period (predefined or ad-hoc)
 * @returns {UseWorkPeriodsMeasurePayloadSingular} - The payload for fetching measures data
 */
const usePayload = (
  measures: Measure[],
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  dateRange: { startDate: Date | null; endDate: Date | null },
  workPeriodType: WorkPeriodType,
): UseWorkPeriodsMeasurePayloadSingular => {
  const defaultPayload = {
    subproject_id: boardId || '',
    measure_name: measures,
  };

  const customPayload =
    workPeriodType === WorkPeriodType.Defined && selectedWorkPeriods.length > 0
      ? {
          work_period_id: selectedWorkPeriods[0].id,
          start_date: dayjs(selectedWorkPeriods[0].start_date).format('YYYY-MM-DD'),
          end_date: dayjs(selectedWorkPeriods[0].end_date).format('YYYY-MM-DD'),
        }
      : {
          start_date: dateRange.startDate ? dayjs(dateRange.startDate).format('YYYY-MM-DD') : '',
          end_date: dateRange.endDate ? dayjs(dateRange.endDate).format('YYYY-MM-DD') : '',
        };

  return {
    ...defaultPayload,
    ...customPayload,
  } as UseWorkPeriodsMeasurePayloadSingular;
};

/**
 * Custom hook to fetch the health score data
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {{ startDate: Date | null; endDate: Date | null }} dateRange - The date range for ad-hoc work periods
 * @param {WorkPeriodType} workPeriodType - The type of work period (predefined or ad-hoc)
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useHealthScoreData = (
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  dateRange: { startDate: Date | null; endDate: Date | null },
  workPeriodType: WorkPeriodType,
) => {
  // Ensure selectedWorkPeriods is an array
  const safeSelectedWorkPeriods = Array.isArray(selectedWorkPeriods) ? selectedWorkPeriods : [];

  const payload = usePayload([Measure.HealthScores], boardId, safeSelectedWorkPeriods, dateRange, workPeriodType);
  const { data, query } = useWorkPeriodsMeasureSingular<HealthScoreData>({
    payload: {
      ...payload,
      transformer: Transformer.SingleLastNonNull,
    },
    options: {
      enabled:
        !!boardId &&
        ((workPeriodType === WorkPeriodType.Defined && safeSelectedWorkPeriods.length > 0) ||
          (workPeriodType === WorkPeriodType.Custom && !!dateRange.startDate && !!dateRange.endDate)),
    },
  });

  // Ensure we always return a properly structured object even if data is missing
  const safeData: HealthScoreData = {
    health_scores: {
      score_by_points: data?.health_scores?.score_by_points ?? 0,
      score_by_tasks: data?.health_scores?.score_by_tasks ?? 0,
      ...(data?.health_scores || {}),
    },
    ...(data || {}),
  };

  return { data: safeData, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch the key process measures data
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {{ startDate: Date | null; endDate: Date | null }} dateRange - The date range for ad-hoc work periods
 * @param {WorkPeriodType} workPeriodType - The type of work period (predefined or ad-hoc)
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useKeyProcessMeasuresData = (
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  dateRange: { startDate: Date | null; endDate: Date | null },
  workPeriodType: WorkPeriodType,
) => {
  const enabled =
    !!boardId &&
    ((workPeriodType === WorkPeriodType.Defined && selectedWorkPeriods.length > 0) ||
      (workPeriodType === WorkPeriodType.Custom && !!dateRange.startDate && !!dateRange.endDate));

  const options = { enabled };

  // Strategy measure
  const strategyPayload = usePayload([Measure.Strategy], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: strategyData, query: strategyQuery } = useWorkPeriodsMeasureSingular<Partial<KeyProcessMeasuresData>>({
    payload: {
      ...strategyPayload,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  // Complexity measure
  const complexityPayload = usePayload([Measure.Complexity], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: complexityData, query: complexityQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...complexityPayload,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  // Quality measure
  const qualityPayload = usePayload([Measure.Quality], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: qualityData, query: qualityQuery } = useWorkPeriodsMeasureSingular<Partial<KeyProcessMeasuresData>>({
    payload: {
      ...qualityPayload,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  // Scope measure
  const scopePayload = usePayload([Measure.ScopeCreep], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: scopeData, query: scopeQuery } = useWorkPeriodsMeasureSingular<Partial<KeyProcessMeasuresData>>({
    payload: {
      ...scopePayload,
      transformer: Transformer.CumulativeSingularValue,
    },
    options,
  });

  // Readiness measure
  // USES CUSTOM MEASURE ENDPOINT - DIFFERENT FROM OTHER MEASURES
  const readinessPayload = usePayload([Measure.Readiness], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: rawReadinessData, query: readinessQuery } = useWorkPeriodsMeasureSingular<ReadinessData>({
    payload: {
      ...readinessPayload,
      is_custom: true,
      module_type: ModuleType.WorkPeriod,
      transformer: Transformer.ReturnFirstMetricOnly,
    },
    options,
  });
  const lastReadinessValue = getLastNotNullValue(rawReadinessData?.readiness);
  const readinessData = {
    readiness: lastReadinessValue as Partial<KeyProcessMeasuresData>,
  };

  // Independence measure
  const independencePayload = usePayload(
    [Measure.Independence],
    boardId,
    selectedWorkPeriods,
    dateRange,
    workPeriodType,
  );
  const { data: independenceData, query: independenceQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...independencePayload,
      transformer: Transformer.LastValueIndexed,
    },
    options,
  });

  // Review time measure
  const reviewTimePayload = usePayload([Measure.ReviewTime], boardId, selectedWorkPeriods, dateRange, workPeriodType);
  const { data: reviewTimeData, query: reviewTimeQuery } = useWorkPeriodsMeasureSingular<
    Partial<KeyProcessMeasuresData>
  >({
    payload: {
      ...reviewTimePayload,
      transformer: Transformer.SingleFractionalValue,
    },
    options,
  });

  // PR closed measure
  const prClosedPayload = usePayload(
    [Measure.DeclinedChangeRequests],
    boardId,
    selectedWorkPeriods,
    dateRange,
    workPeriodType,
  );
  const { data: prClosedData, query: prClosedQuery } = useWorkPeriodsMeasureSingular<Partial<KeyProcessMeasuresData>>({
    payload: {
      ...prClosedPayload,
      transformer: Transformer.SingleFractionalValue,
    },
    options,
  });

  const isFetching =
    strategyQuery.isFetching ||
    complexityQuery.isFetching ||
    qualityQuery.isFetching ||
    scopeQuery.isFetching ||
    readinessQuery.isFetching ||
    independenceQuery.isFetching ||
    reviewTimeQuery.isFetching ||
    prClosedQuery.isFetching;

  const data = {
    ...strategyData,
    ...complexityData,
    ...qualityData,
    ...scopeData,
    ...readinessData,
    ...independenceData,
    ...reviewTimeData,
    ...prClosedData,
  } as KeyProcessMeasuresData;

  return { data, isFetching };
};

/**
 * Custom hook to fetch the flow data
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {{ startDate: Date | null; endDate: Date | null }} dateRange - The date range for ad-hoc work periods
 * @param {WorkPeriodType} workPeriodType - The type of work period (predefined or ad-hoc)
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useFlowData = (
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  dateRange: { startDate: Date | null; endDate: Date | null },
  workPeriodType: WorkPeriodType,
) => {
  // Ensure selectedWorkPeriods is an array
  const safeSelectedWorkPeriods = Array.isArray(selectedWorkPeriods) ? selectedWorkPeriods : [];

  const enabled =
    !!boardId &&
    ((workPeriodType === WorkPeriodType.Defined && safeSelectedWorkPeriods.length > 0) ||
      (workPeriodType === WorkPeriodType.Custom && !!dateRange.startDate && !!dateRange.endDate));

  // Flow by phase
  const flowByPhasePayload = usePayload(
    [Measure.FlowByPhase],
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );
  const { data: flowByPhaseData, query: flowByPhaseQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...flowByPhasePayload,
    },
    options: {
      enabled,
    },
  });

  // Flow by pace
  const flowByPacePayload = usePayload(
    [Measure.FlowByPace],
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );
  const { data: flowByPaceData, query: flowByPaceQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...flowByPacePayload,
    },
    options: {
      enabled,
    },
  });

  // Burns
  const burnsPayload = usePayload([Measure.Burns], boardId, safeSelectedWorkPeriods, dateRange, workPeriodType);
  const { data: burnsData, query: burnsQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...burnsPayload,
    },
    options: {
      enabled,
    },
  });

  // Historical burns
  const historicalBurnsPayload = usePayload(
    [Measure.HistoricalBurns],
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );
  const { data: historicalBurnsData, query: historicalBurnsQuery } = useWorkPeriodsMeasureSingular<FlowData>({
    payload: {
      ...historicalBurnsPayload,
    },
    options: {
      enabled,
    },
  });

  // Merge all data with defaults to ensure we have a complete structure
  const data = {
    ...(flowByPhaseData || {}),
    ...(flowByPaceData || {}),
    ...(burnsData || {}),
    ...(historicalBurnsData || {}),
  };

  const isFetching =
    flowByPhaseQuery.isFetching ||
    flowByPaceQuery.isFetching ||
    burnsQuery.isFetching ||
    historicalBurnsQuery.isFetching;

  return { data, isFetching };
};

/**
 * Custom hook to fetch available custom measures
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useCustomMeasures = (boardId: string | null, selectedWorkPeriods: WorkPeriod[]) => {
  const workPeriodId = selectedWorkPeriods.length > 0 ? selectedWorkPeriods[0].id : '';

  const { data: availableMeasuresResponse, query } = useAvailableMeasures({
    payload: {
      scope: ModuleType.WorkPeriod,
      work_period_id: workPeriodId,
    },
    options: {
      enabled: !!boardId && !!workPeriodId,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
  });

  const availableMeasures = availableMeasuresResponse?.work_period?.measures || [];
  const customMeasures = availableMeasures.filter((measure: MeasureMetadata) => measure.is_custom);

  return { data: customMeasures, isLoading: query.isLoading, isFetching: query.isFetching };
};

/**
 * Custom hook to fetch measure data for multiple work periods
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {MeasureMetadata[]} customMeasures - The custom measures to fetch data for
 * @returns {object} - An object containing the fetched data and an isLoading flag
 */
const useMeasureData = (
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  customMeasures: MeasureMetadata[],
) => {
  // Ensure inputs are valid arrays
  const safeWorkPeriods = Array.isArray(selectedWorkPeriods) ? selectedWorkPeriods : [];
  const safeMeasures = Array.isArray(customMeasures) ? customMeasures : [];

  // Extract measure names
  const measureNames = safeMeasures.map((measure) => measure.measure_name);

  // Use useQueries instead of mapping over useQuery
  const workPeriodQueries = useQueries({
    queries: safeWorkPeriods.map((workPeriod) => {
      const workPeriodId = workPeriod?.id;
      const startDate = workPeriod?.start_date ? dayjs(workPeriod.start_date).format('YYYY-MM-DD') : undefined;
      const endDate = workPeriod?.end_date ? dayjs(workPeriod.end_date).format('YYYY-MM-DD') : undefined;

      // Determine if query should be enabled
      const isQueryEnabled = !!boardId && !!workPeriodId && Array.isArray(measureNames) && measureNames.length > 0;

      return {
        queryKey: ['measure-data', workPeriodId, measureNames],
        queryFn: async () => {
          // Prepare payload for the API call
          const payload = {
            subproject_id: boardId || '',
            work_period_id: workPeriodId,
            start_date: startDate,
            end_date: endDate,
            measure_name: measureNames,
            is_custom: true,
            transformer: Transformer.ReturnFirstMetricOnly,
            module_type: ModuleType.WorkPeriod,
          };

          // Use the getWorkPeriodsMeasure function to fetch data
          return await getWorkPeriodsMeasure(payload);
        },
        enabled: isQueryEnabled,
      };
    }),
  });

  // Check if any queries are still fetching
  const isFetching = workPeriodQueries.some((query) => query.isFetching);

  // Combine results from all queries into a single object
  const measureData = workPeriodQueries.reduce(
    (acc, query, index) => {
      const workPeriodId = safeWorkPeriods[index]?.id;
      if (query.data && workPeriodId) {
        acc[workPeriodId] = query.data;
      }
      return acc;
    },
    {} as Record<string, any>,
  );

  return { data: measureData, isFetching };
};

/**
 * Custom hook to fetch all data for the work periods view
 *
 * @param {string | null} boardId - The ID of the board
 * @param {WorkPeriod[]} selectedWorkPeriods - The selected work periods
 * @param {{ startDate: Date | null; endDate: Date | null }} dateRange - The date range for ad-hoc work periods
 * @param {WorkPeriodType} workPeriodType - The type of work period (predefined or ad-hoc)
 * @returns {object} - An object containing all the fetched data and an isLoading flag
 */
const useWorkPeriodsViewData = (
  boardId: string | null,
  selectedWorkPeriods: WorkPeriod[],
  dateRange: { startDate: Date | null; endDate: Date | null },
  workPeriodType: WorkPeriodType,
) => {
  // Ensure selectedWorkPeriods is always an array
  const safeSelectedWorkPeriods = Array.isArray(selectedWorkPeriods) ? selectedWorkPeriods : [];

  // Health score data
  const { data: scoreData, isFetching: isFetchingScoreData } = useHealthScoreData(
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );

  // Key process measures data
  const { data: keyMeasuresData, isFetching: isFetchingKeyMeasuresData } = useKeyProcessMeasuresData(
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );

  // Flow data
  const { data: flowData, isFetching: isFetchingFlowData } = useFlowData(
    boardId,
    safeSelectedWorkPeriods,
    dateRange,
    workPeriodType,
  );

  // Custom measures
  const { data: customMeasures, isFetching: isFetchingCustomMeasures } = useCustomMeasures(
    boardId,
    safeSelectedWorkPeriods,
  );

  // Measure data
  const { data: measureData, isFetching: isFetchingMeasureData } = useMeasureData(
    boardId,
    safeSelectedWorkPeriods,
    customMeasures || [],
  );

  const isLoading =
    isFetchingScoreData ||
    isFetchingKeyMeasuresData ||
    isFetchingFlowData ||
    isFetchingCustomMeasures ||
    isFetchingMeasureData;

  return {
    scoreData,
    keyMeasuresData,
    flowData,
    customMeasures,
    measureData,
    isLoading,
  };
};

/**
 * Custom hook to fetch boards for a team
 *
 * @param {string | null} teamId - The ID of the team
 * @returns {object} - An object containing the boards data and an isLoading flag
 */
const useBoards = (teamId: string | null) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['boards', teamId],
    queryFn: () => (teamId && teamId !== '1' ? getBoardsByTeam(teamId) : Promise.resolve([])),
    enabled: !!teamId && teamId !== '1',
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    boards: data || [],
    isLoading,
    error,
  };
};

/**
 * Generates an array of ComboboxItemGroup objects representing the board options.
 *
 * @param {ProjectsResponse[]} teams - The array of Team objects to generate the ComboboxItemGroup objects from.
 * @return {ComboboxItemGroup[]} An array of ComboboxItemGroup objects representing the board options.
 */
const getBoardOptions = (teams: ProjectsResponse[]): ComboboxItemGroup[] => {
  return teams.map((team, index) => ({
    group: `${index + 1}-${team.name}`,
    items: team.subprojects
      .filter((subproject) => subproject.id && subproject.name)
      .map((subproject) => ({
        value: subproject.id,
        label: subproject.name,
      })),
  }));
};

/**
 * Extracts board IDs from the boards data
 *
 * @param {any[]} boards - Array of board data
 * @returns {string[]} - Array of board IDs
 */
const getBoardIds = (boards: any[]): string[] => {
  return boards.map((board) => board.id);
};

/**
 * Hook to access teams data from the global store
 * @returns {ProjectsResponse[]} Teams data
 */
const useTeams = (): ProjectsResponse[] => {
  return useGlobalStore((state) => state.teams);
};

/**
 * Manages board selection and synchronization with teams data.
 * Monitors teams changes, validates selected board ID, and sets default board if needed.
 *
 * @param {string | null} selectedBoardId - Currently selected board ID
 * @param {(boardId: string | null) => void} setSelectedBoardId - Function to update selected board ID
 * @returns {void}
 */
const useBoardManagement = (
  selectedBoardId: string | null,
  setSelectedBoardId: (boardId: string | null) => void,
): void => {
  const teams = useTeams();

  useDeepCompareEffect(() => {
    if (!teams.length) {
      setSelectedBoardId(null);
      return;
    }

    const boardIds = getBoardIds(teams);
    const isValidBoard = selectedBoardId && boardIds.includes(selectedBoardId);

    if (!isValidBoard || !selectedBoardId) {
      const defaultBoardId = boardIds[0] || null;
      setSelectedBoardId(defaultBoardId);
    }
  }, [teams]);
};

/**
 * Type for task query response
 */
type TaskQueryResponse<T> = {
  data: T;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: unknown;
};

/**
 * Custom hook to fetch tasks by phase for the selected work periods or date range
 */
const useTasksByPhase = (
  workPeriodIds: string[] = [],
  dateRange: { startDate: Date | null; endDate: Date | null } = { startDate: null, endDate: null },
): TaskQueryResponse<Record<string, MinifiedTask[]>> => {
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['tasksByPhase', workPeriodIds, dateRange],
    queryFn: async () => {
      // Return empty object if no work periods or date range is provided
      const hasWorkPeriods = Array.isArray(workPeriodIds) && workPeriodIds.length > 0;
      const hasDateRange = !!dateRange?.startDate && !!dateRange?.endDate;

      if (!hasWorkPeriods && !hasDateRange) {
        return {};
      }

      try {
        // Use the existing getWorkPeriodsTasks function with appropriate parameters
        const tasks = await getWorkPeriodsTasks({
          work_period_id: hasWorkPeriods ? workPeriodIds[0] : undefined,
          start_date: dateRange?.startDate ? dayjs(dateRange.startDate).format('YYYY-MM-DD') : undefined,
          end_date: dateRange?.endDate ? dayjs(dateRange.endDate).format('YYYY-MM-DD') : undefined,
          measure_name: [Measure.FlowByPhase], // Use FlowByPhase measure to get phase-related tasks
        });

        // Group tasks by phase
        const tasksByPhase: Record<string, MinifiedTask[]> = {};

        // Ensure tasks is an array before processing
        if (!Array.isArray(tasks)) {
          return {};
        }

        tasks.forEach((task) => {
          if (!task) return; // Skip if task is null or undefined

          // Use the latest transition status as the phase identifier
          // If no transitions, use the type as a fallback
          let phaseId = 'unknown';

          // Ensure transitions is an array before accessing it
          const transitions = Array.isArray(task.transitions) ? task.transitions : [];

          if (transitions.length > 0) {
            // Sort transitions by date and get the latest one
            const sortedTransitions = [...transitions].sort((a, b) => {
              // Ensure we have valid dates before comparing
              const dateA = a?.changed_at ? new Date(a.changed_at).getTime() : 0;
              const dateB = b?.changed_at ? new Date(b.changed_at).getTime() : 0;
              return dateB - dateA;
            });

            // Get the status from the first transition, fallback to unknown if status is missing
            phaseId = sortedTransitions[0]?.status || 'unknown';
          } else {
            // Fallback to task type or unknown if type is missing
            phaseId = task.type || 'unknown';
          }

          // Initialize the array for this phase if it doesn't exist
          if (!tasksByPhase[phaseId]) {
            tasksByPhase[phaseId] = [];
          }

          tasksByPhase[phaseId].push(task);
        });

        return tasksByPhase;
      } catch (error) {
        console.error('Error fetching tasks by phase:', error);
        return {};
      }
    },
    enabled:
      (Array.isArray(workPeriodIds) && workPeriodIds.length > 0) || (!!dateRange?.startDate && !!dateRange?.endDate),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data: data || {},
    isLoading,
    isFetching,
    isError,
    error,
  };
};

/**
 * Custom hook to fetch tasks by measure for the selected work periods or date range
 */
const useTasksByMeasure = (
  workPeriodIds: string[] = [],
  dateRange: { startDate: Date | null; endDate: Date | null } = { startDate: null, endDate: null },
): TaskQueryResponse<Record<string, MinifiedTask[]>> => {
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['tasksByMeasure', workPeriodIds, dateRange],
    queryFn: async () => {
      // Return empty object if no work periods or date range is provided
      const hasWorkPeriods = Array.isArray(workPeriodIds) && workPeriodIds.length > 0;
      const hasDateRange = !!dateRange?.startDate && !!dateRange?.endDate;

      if (!hasWorkPeriods && !hasDateRange) {
        return {};
      }

      try {
        // Use the existing getWorkPeriodsTasks function with appropriate parameters
        const tasks = await getWorkPeriodsTasks({
          work_period_id: hasWorkPeriods ? workPeriodIds[0] : undefined,
          start_date: dateRange?.startDate ? dayjs(dateRange.startDate).format('YYYY-MM-DD') : undefined,
          end_date: dateRange?.endDate ? dayjs(dateRange.endDate).format('YYYY-MM-DD') : undefined,
          measure_name: [Measure.Strategy], // Use Strategy measure as a representative measure
        });

        // Group tasks by measure
        const tasksByMeasure: Record<string, MinifiedTask[]> = {};

        // Ensure tasks is an array before processing
        if (!Array.isArray(tasks)) {
          return {};
        }

        tasks.forEach((task) => {
          if (!task) return; // Skip if task is null or undefined

          // Use the type as the identifier
          const measureId = task.type || 'unknown';

          // Initialize the array for this measure if it doesn't exist
          if (!tasksByMeasure[measureId]) {
            tasksByMeasure[measureId] = [];
          }

          tasksByMeasure[measureId].push(task);
        });

        return tasksByMeasure;
      } catch (error) {
        console.error('Error fetching tasks by measure:', error);
        return {};
      }
    },
    enabled:
      (Array.isArray(workPeriodIds) && workPeriodIds.length > 0) || (!!dateRange?.startDate && !!dateRange?.endDate),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data: data || {},
    isLoading,
    isFetching,
    isError,
    error,
  };
};

/**
 * Custom hook to fetch tasks by date for the selected work periods or date range
 */
const useTasksByDate = (
  workPeriodIds: string[] = [],
  dateRange: { startDate: Date | null; endDate: Date | null } = { startDate: null, endDate: null },
): TaskQueryResponse<Record<string, MinifiedTask[]>> => {
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['tasksByDate', workPeriodIds, dateRange],
    queryFn: async () => {
      // Return empty object if no work periods or date range is provided
      const hasWorkPeriods = Array.isArray(workPeriodIds) && workPeriodIds.length > 0;
      const hasDateRange = !!dateRange?.startDate && !!dateRange?.endDate;

      if (!hasWorkPeriods && !hasDateRange) {
        return {};
      }

      try {
        // Use the existing getWorkPeriodsTasks function with appropriate parameters
        const tasks = await getWorkPeriodsTasks({
          work_period_id: hasWorkPeriods ? workPeriodIds[0] : undefined,
          start_date: dateRange?.startDate ? dayjs(dateRange.startDate).format('YYYY-MM-DD') : undefined,
          end_date: dateRange?.endDate ? dayjs(dateRange.endDate).format('YYYY-MM-DD') : undefined,
          measure_name: [Measure.FlowByPace], // Use FlowByPace measure to get date-related tasks
        });

        // Group tasks by date
        const tasksByDate: Record<string, MinifiedTask[]> = {};

        // Ensure tasks is an array before processing
        if (!Array.isArray(tasks)) {
          return {};
        }

        tasks.forEach((task) => {
          if (!task) return; // Skip if task is null or undefined

          // Use the created date as the identifier
          // If no created date, use unknown
          const dateStr = task.created_date || 'unknown';
          const dateId = dateStr !== 'unknown' ? dayjs(dateStr).format('YYYY-MM-DD') : 'unknown';

          // Initialize the array for this date if it doesn't exist
          if (!tasksByDate[dateId]) {
            tasksByDate[dateId] = [];
          }

          tasksByDate[dateId].push(task);
        });

        return tasksByDate;
      } catch (error) {
        console.error('Error fetching tasks by date:', error);
        return {};
      }
    },
    enabled:
      (Array.isArray(workPeriodIds) && workPeriodIds.length > 0) || (!!dateRange?.startDate && !!dateRange?.endDate),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data: data || {},
    isLoading,
    isFetching,
    isError,
    error,
  };
};

export {
  getBoardIds,
  getBoardOptions,
  useBoardManagement,
  useBoards,
  useCustomMeasures,
  useFlowData,
  useHealthScoreData,
  useKeyProcessMeasuresData,
  useMeasureData,
  usePayload,
  useTasksByDate,
  useTasksByMeasure,
  useTasksByPhase,
  useTeams,
  useWorkPeriodsData,
  useWorkPeriodsViewData,
};
