import { Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { OverviewCard } from '../../../../components/overview-card/overview-card';
import { skyDark } from '../../../../styles/design-tokens';
import { Icon } from '../../../../ui-library/icon/icon';
import {
  getAverageRiskScoreChart,
  getEpicCompletionChart,
  getRiskCategoryBreakdownChart,
  getTaskTypeAverageRiskChart,
} from '../risk-analysis-chart.helpers';
import { RiskAnalysisTask } from '../risk-analysis.client.type';

type Props = {
  tasks: RiskAnalysisTask[];
  isEpic?: boolean;
};

export function RiskAnalysisTaskOverview({ tasks, isEpic = false }: Props) {
  const [averageRiskScoreChart, setAverageRiskScoreChart] = useState<JSX.Element | null>(null);
  const [riskCategoryBreakdownChart, setRiskCategoryBreakdownChart] = useState<JSX.Element | null>(null);
  const [taskTypeAverageRiskChart, setTaskTypeAverageRiskChart] = useState<JSX.Element | null>(null);
  const [epicCompletionChart, setEpicCompletionChart] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const averageRiskScoreChart = getAverageRiskScoreChart(tasks);
    setAverageRiskScoreChart(averageRiskScoreChart);

    const riskCategoryBreakdownChart = getRiskCategoryBreakdownChart(tasks);
    setRiskCategoryBreakdownChart(riskCategoryBreakdownChart);

    if (isEpic) {
      const epicCompletionChart = getEpicCompletionChart(tasks);
      setEpicCompletionChart(epicCompletionChart);
    } else {
      const taskTypeAverageRiskChart = getTaskTypeAverageRiskChart(tasks);
      setTaskTypeAverageRiskChart(taskTypeAverageRiskChart);
    }
  }, [tasks, isEpic]);

  return (
    <Group>
      <OverviewCard
        title="Average Risk Score"
        icon={<Icon name="show_chart" size={20} color={skyDark} />}
        content={averageRiskScoreChart}
      />
      <OverviewCard
        title="Risk Category Breakdown"
        icon={<Icon name="bar_chart" size={20} color={skyDark} />}
        content={riskCategoryBreakdownChart}
      />

      {!isEpic && (
        <OverviewCard
          title="Average Risk by Type"
          icon={<Icon name="pie_chart" size={20} color={skyDark} />}
          content={taskTypeAverageRiskChart}
        />
      )}
      {isEpic && (
        <OverviewCard
          title="Average Completion"
          icon={<Icon name="check_circle" size={20} color={skyDark} />}
          content={epicCompletionChart}
        />
      )}
    </Group>
  );
}
