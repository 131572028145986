import { styled } from '@linaria/react';
import { ChartData, Chart as ChartJS, TooltipItem, TooltipModel } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import {
  CustomMeasureDataResponse,
  Measure,
  MeasureDataResponse,
  MeasureMetadata,
} from '../../../api/work-periods-client/work-periods-client.type';
import { round } from '../../../helpers/math-helpers/math-helpers';
import { snakeCaseToTitleCase } from '../../../helpers/string-helpers/string-helpers';
import { convertAndRoundMeasureValues } from '../../../helpers/work-period-measures/convert-metrics-helpers';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import {
  filterUnwantedMeasures,
  useAvailableMeasureUnits,
  useTimeAllocation,
} from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { getTargetLineDatasets } from '../../adherence/targets/targets.helpers';
import { createLine } from '../process-analysis-chart.helpers';
import { measureComparisonTooltipHandler } from './measure-comparison-tooltip';
import { calculateTrend, getChartLabels, y1AxisInUse, y1AxisLabel, yAxisInUse } from './measure-comparison.helpers';
import { MeasuresWithColor, SelectedTarget } from './measure-comparison.type';

type ProcessAnalysisMeasuresComparisonChartProps = {
  measureData: MeasureDataResponse | CustomMeasureDataResponse;
  measuresWithColor: MeasuresWithColor;
  trendsWithColor: MeasuresWithColor;
  selectedTargets: SelectedTarget[];
  mergedMeasureMetaData: MeasureMetadata[];
};

export const ProcessAnalysisMeasuresComparisonChart = ({
  measureData,
  measuresWithColor,
  trendsWithColor,
  selectedTargets,
  mergedMeasureMetaData,
}: ProcessAnalysisMeasuresComparisonChartProps) => {
  const measureUnitMap = useAvailableMeasureUnits();
  const availableMeasures = useProcessAnalysisStore((state) => state.availableMeasures).filter(filterUnwantedMeasures);
  const customMeasures = useProcessAnalysisStore((state) => state.availableMeasures);
  const allMeasureMetadata = availableMeasures.concat(customMeasures);

  const navigate = useNavigate();

  const measureNames = Object.keys(measuresWithColor) as Measure[];
  const trendNames = Object.keys(trendsWithColor) as Measure[];

  const timeBasedMeasures: Measure[] = [Measure.LeadTime, Measure.ReactionTime, Measure.CycleTime];

  const timeAllocation = useTimeAllocation();

  const labels = getChartLabels(measureData, timeAllocation);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    pointStyle: true,
    aspectRatio: 2.5,
    spanGaps: true,
    layout: {
      padding: {
        top: 20,
        right: 0,
      },
    },
    scales: {
      x: {
        title: {
          display: labels.length > 0,
          text: 'Dates',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        id: 'y',
        beginAtZero: true,
        position: 'left' as const,
        suggestedMax: 100,
        title: {
          display: true,
          text: '%',
          color: yAxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: yAxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
        },
      },
      y1: {
        id: 'y1',
        beginAtZero: true,
        position: 'right' as const,
        title: {
          display: true,
          text: y1AxisLabel(measureNames, trendNames, measureUnitMap),
          color: y1AxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
          color: y1AxisInUse(measureNames, trendNames, measureUnitMap) ? undefined : newCOLORS.gray,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        common: {
          drawTime: 'afterDraw',
        },
      },
      filler: {
        propagate: true,
        drawTime: 'beforeDatasetsDraw' as const,
      },
      tooltip: {
        enabled: false,
        external: (context: { chart: ChartJS; tooltip: TooltipModel<'line'> }) =>
          measureComparisonTooltipHandler(context, measureData, measureUnitMap, navigate),
      },
    },
  };

  const formatData = (): ChartData<'line'> => {
    const chartData: ChartData<'line'> = {
      labels,
      datasets: [],
    };

    // Add measure lines
    for (const measure of Object.keys(measuresWithColor) as Measure[]) {
      if (!measureData[measure]) {
        continue;
      }

      const convertedValues = convertAndRoundMeasureValues(measureData, measure, timeBasedMeasures, 1);

      chartData.datasets.push(
        createLine(
          measure,
          convertedValues,
          measuresWithColor[measure] ?? newCOLORS.black,
          false,
          Object.entries(measureUnitMap)
            .filter(([_, unit]) => unit === 'Percentage')
            .map(([name]) => name)
            .includes(measure)
            ? 'y'
            : 'y1',
        ),
      );
    }

    // Add trend lines
    for (const trend of Object.keys(trendsWithColor) as Measure[]) {
      if (!measureData[trend]) {
        continue;
      }

      const convertedValues = convertAndRoundMeasureValues(measureData, trend, timeBasedMeasures, 1);

      const xAxis = Array.from({ length: convertedValues.length }, (_value, index) => index);
      const line = calculateTrend(
        measureData[trend],
        allMeasureMetadata.find((measure) => measure.measure_name === trend)?.is_zero_valid ?? true,
      );
      if (line) {
        const { slope, intercept } = line;
        const trendData = xAxis.map((x) => round(slope * x + intercept, 1));
        chartData.datasets.push(
          createLine(
            trend,
            trendData,
            trendsWithColor[trend] ?? newCOLORS.black,
            true,
            Object.entries(measureUnitMap)
              .filter(([_, unit]) => unit === 'Percentage')
              .map(([name]) => name)
              .includes(trend)
              ? 'y'
              : 'y1',
          ),
        );
      }
    }

    // Add target lines
    for (const target of selectedTargets) {
      const measureMetadata = mergedMeasureMetaData.find((m) => m.measure_name === target.measureId);
      if (!measureMetadata || !measureMetadata.targets) continue;

      const targetData = measureMetadata.targets.find((t) => t.id === target.targetId);
      if (!targetData) continue;

      const yAxisId = Object.entries(measureUnitMap)
        .filter(([_, unit]) => unit === 'Percentage')
        .map(([name]) => name)
        .includes(target.measureId as string)
        ? 'y'
        : 'y1';

      const color = measuresWithColor[target.measureId as Measure] ?? newCOLORS.black;

      const targetLines = getTargetLineDatasets(targetData, measureMetadata, color, yAxisId);
      chartData.datasets.push(...targetLines);
    }

    return chartData;
  };

  return (
    <ChartContainer>
      <Line data={formatData()} options={options} />
    </ChartContainer>
  );
};

/**
 * Custom tooltip title function that concatenates the dataset labels from the tooltip items.
 *
 * @param {TooltipItem<'line'>[]} tooltipItems - The tooltip items containing the dataset labels.
 * @returns {string} The concatenated title string.
 */
export function title(tooltipItems: TooltipItem<'line'>[]): string {
  return tooltipItems
    .map((item) => {
      const measureName = snakeCaseToTitleCase(item.dataset.label || '');
      // trend lines have a borderDash
      const isTrend = item.dataset.borderDash && item.dataset.borderDash.length;
      return isTrend ? `${measureName} (Trend)` : measureName;
    })
    .join('\n');
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 100%;
`;
