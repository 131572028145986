enum InitiativeMetricType {
  Tasks = 'tasks',
  Points = 'points',
}

enum InitiativeScope {
  Teams = 'teams',
  Boards = 'boards',
}

type ColoredEntity = {
  id: string;
  name: string;
  color: string;
};

type LegendItem = {
  title: string;
  description?: string;
  color: string;
};

export { InitiativeMetricType, InitiativeScope };
export type { ColoredEntity, LegendItem };
