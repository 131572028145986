import { Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { checkValidEmail } from '../../../../helpers';
import { useIntegrationsStore } from '../../../../store/integrations-store/integrations-store';
import { baseWhite } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Divider } from '../../../../ui-library/divider/divider';
import { Icon } from '../../../../ui-library/icon/icon';
import { ValidationError } from '../../../onboarding/sign-up/sign-up.type';
import { AddNewIntegrationConsent } from '../add-new-integration-consent';
import { StyledForm } from '../add-new-integration.styled';
import { AuthFormType, TokenAuthForm as TokenAuthFormType } from '../add-new-integration.types';

interface Props {
  onSubmit: (values: TokenAuthFormType) => void;
}

export const TokenAuthForm = ({ onSubmit }: Props) => {
  const integration = useIntegrationsStore((state) => state.integration);

  const form = useForm<TokenAuthFormType>({
    initialValues: {
      email: '',
      apiToken: '',
    },
    validate: {
      email: (value) => (checkValidEmail(value) ? null : ValidationError.InvalidEmail),
    },
  });

  if (!integration) {
    return null;
  }

  return (
    <StyledForm onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap={24}>
        <Divider />
        <TextInput
          autoFocus
          required
          mb="md"
          label="Email"
          placeholder="Enter your email address"
          {...form.getInputProps('email')}
        />
        <TextInput
          required
          mb="md"
          label="API Key"
          placeholder="Enter your API key"
          {...form.getInputProps('apiToken')}
        />
        <Divider />
        <AddNewIntegrationConsent type={AuthFormType.Token} />
        <Divider />
        <Button
          type="submit"
          fullWidth
          radius="xl"
          rightSection={<Icon name="arrow_forward" color={baseWhite} size={18} />}
        >
          Continue
        </Button>
      </Flex>
    </StyledForm>
  );
};
