import { QueryObserverResult } from '@tanstack/react-query';

import { WorkflowPayload } from '../../../../../../api/workflows-client/workflows-client.type';

import { UseMutateFunction } from '@tanstack/react-query';
import { OrganizationStatusesByProjectsAndBoards } from '../../../../../../api/organization-client/organization-client.type';
import { Workflow } from '../../../../../../api/workflows-client/workflows-client.type';
import { DataManagementDropShadowContainer } from '../../../data-management-header';
import { WorkflowAction } from '../../shared-components/reducer';
import { EditWorkflowContent } from './edit-workflow-content';
import { EditWorkflowHeader } from './edit-workflow-header';

export function EditWorkflow({
  state,
  dispatch,
  setPageState,
  isDirty,
  handleSelectWorkflow,
  statusesByProjectsAndBoards,
  workflows,
  updateWorkflow,
  openDeleteWorkflowModal,
}: {
  state: Workflow;
  dispatch: React.Dispatch<WorkflowAction>;
  setPageState: (pageState: 'update' | 'create' | 'idle') => void;
  isDirty: boolean;
  handleSelectWorkflow: (
    workflow: Workflow,
    statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined,
    dispatch: React.Dispatch<WorkflowAction>,
  ) => void;
  statusesByProjectsAndBoards: OrganizationStatusesByProjectsAndBoards | undefined;
  workflows: Workflow[];
  updateWorkflow: UseMutateFunction<
    Workflow,
    Error,
    {
      workflowId: string | null;
      payload: WorkflowPayload;
    },
    unknown
  >;
  workflowsQuery: QueryObserverResult<Workflow[], Error>;
  openDeleteWorkflowModal: () => void;
}) {
  return (
    <DataManagementDropShadowContainer>
      <EditWorkflowHeader
        state={state}
        dispatch={dispatch}
        setPageState={setPageState}
        isDirty={isDirty}
        handleSelectWorkflow={handleSelectWorkflow}
        statusesByProjectsAndBoards={statusesByProjectsAndBoards}
        workflows={workflows}
        updateWorkflow={updateWorkflow}
        openDeleteWorkflowModal={openDeleteWorkflowModal}
      />
      <EditWorkflowContent
        state={state}
        dispatch={dispatch}
        statusesByProjectsAndBoards={statusesByProjectsAndBoards}
      />
    </DataManagementDropShadowContainer>
  );
}
