import { Group, Stack, TextInput } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { EmptyFilterResult } from '../../../../components/filters/empty-filter-result';
import { baseWhite, inkLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Table } from '../../../data-management/components/table';
import { RiskAnalysisInitiative } from '../risk-analysis.client.type';
import { areDataArraysDifferent, sortRiskAnalysisData } from '../risk-analysis.helpers';
import { getInitiativeTableColumns, handleDownloadInitiativeTableCSV } from './risk-analysis-initiative-table.hooks';

const ITEMS_PER_PAGE = 20;

export function RiskAnalysisInitiativeTable({
  initiatives,
  onInitiativeClick,
}: {
  initiatives: RiskAnalysisInitiative[];
  onInitiativeClick: (initiative: RiskAnalysisInitiative) => void;
}) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [filteredInitiatives, setFilteredInitiatives] = useState(initiatives);
  const [searchTerm, setSearchTerm] = useState('');
  const prevInitiativesRef = useRef<RiskAnalysisInitiative[]>(initiatives);
  const prevSearchTermRef = useRef<string>(searchTerm);

  // Update filtered initiatives only when initiatives content or search term actually changes
  useEffect(() => {
    const initiativesChanged = areDataArraysDifferent(prevInitiativesRef.current, initiatives);
    const searchTermChanged = prevSearchTermRef.current !== searchTerm;

    if (initiativesChanged || searchTermChanged) {
      const filtered = initiatives.filter(
        (initiative) =>
          initiative.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          initiative.description.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      setFilteredInitiatives(filtered);

      // Only reset the page if initiatives content actually changed, not on search
      if (initiativesChanged) {
        setSelectedPage(1);
      }

      // Update refs to current values
      prevInitiativesRef.current = initiatives;
      prevSearchTermRef.current = searchTerm;
    }
  }, [initiatives, searchTerm]);

  const sortData = (
    data: RiskAnalysisInitiative[],
    sortBy: keyof RiskAnalysisInitiative,
    direction: 'asc' | 'desc',
  ): RiskAnalysisInitiative[] => {
    return sortRiskAnalysisData(data, sortBy, direction);
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Button
          variant="secondary"
          radius="xl"
          size="sm"
          leftSection={<Icon name="format_list_bulleted" color={baseWhite} variant="filled" size={16} />}
        >
          Table View
        </Button>
        <Group>
          <TextInput
            placeholder="Search initiatives..."
            radius="xl"
            size="sm"
            value={searchTerm}
            leftSection={<Icon name="search" variant="filled" size={16} color={inkLight} />}
            style={{ width: 300 }}
            onChange={(event) => {
              setSearchTerm(event.currentTarget.value);
            }}
          />
          <Button
            variant="secondary"
            radius="xl"
            size="sm"
            leftSection={<Icon name="download" color={baseWhite} variant="filled" size={16} />}
            onClick={() => handleDownloadInitiativeTableCSV(initiatives)}
          >
            Export
          </Button>
        </Group>
      </Group>
      {filteredInitiatives.length > 0 ? (
        <Table<RiskAnalysisInitiative>
          data={filteredInitiatives}
          columns={getInitiativeTableColumns()}
          getRowId={(initiative: RiskAnalysisInitiative) => initiative.id}
          stickyHeader
          onRowClick={(initiative: RiskAnalysisInitiative) => onInitiativeClick(initiative)}
          pageSize={ITEMS_PER_PAGE}
          currentPage={selectedPage}
          totalItems={filteredInitiatives.length}
          onPageChange={(page: number) => {
            setSelectedPage(page);
          }}
          customSort={sortData}
        />
      ) : (
        <EmptyFilterResult objectName="initiatives" />
      )}
    </Stack>
  );
}
