import { faCircleArrowUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';
import i18n from '../../base-dictionary';
import { Button } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { uploadFile } from '../../api/files-client/files-client';
import { PopUpWindow } from '../../containers/pop-up-window/pop-up-window';
import { newCOLORS } from '../../styles/colors';
import { FileUploadButton } from '../file-upload-button/file-upload-button';

type FileUploadPopupProps = {
  show: boolean;
  closePopUp: () => void;
};

export function FileUploadPopup({ show, closePopUp }: FileUploadPopupProps) {
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [message, setMessage] = useState('Select an Excel file to upload.');
  const [uploadStatus, setStatus] = useState<string>('waiting');

  function UploadIcon() {
    if (uploadStatus === 'waiting' || uploadStatus === 'uploading') {
      return <FontAwesomeIcon style={{ color: newCOLORS.blue }} size="2x" icon={faCircleArrowUp} />;
    } else if (uploadStatus === 'success') {
      return <FontAwesomeIcon style={{ color: newCOLORS.green }} size="2x" icon={faCircleCheck} />;
    } else if (uploadStatus === 'error') {
      return <FontAwesomeIcon style={{ color: newCOLORS.red }} size="2x" icon={faCircleXmark} />;
    } else {
      return <FontAwesomeIcon style={{ color: newCOLORS.blue }} size="2x" icon={faCircleArrowUp} />;
    }
  }

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
  };

  const fileUpload = useMutation({
    mutationKey: ['fileUpload', currentFile] as const,
    mutationFn: async () => {
      if (!currentFile) {
        throw new Error('No file selected');
      }
      return uploadFile(currentFile);
    },
    onSuccess: () => {
      setStatus('success');
      setMessage('Upload successful! You can close this window now.');
    },
    onError: (error: any) => {
      setStatus('error');
      setMessage('Upload failed: ' + error.data.Error);
    },
    onSettled: () => {
      setCurrentFile(undefined);
    },
  });

  return (
    <PopUpWindow
      closePopUp={closePopUp}
      title={i18n.t('common.upload.xlsx')}
      show={show}
      content={
        <>
          <p>{message}</p>
          <PopUpItemContainer>
            <UploadIcon />
            <FileUploadInputContainer>
              <FileUploadButton onChange={selectFile} />
            </FileUploadInputContainer>
            <ActionButtonContainer>
              {uploadStatus === 'success' ? (
                <Button onClick={closePopUp} tt={'uppercase'}>
                  Close
                </Button>
              ) : (
                <Button onClick={() => fileUpload.mutate()} disabled={!currentFile} tt={'uppercase'}>
                  Upload
                </Button>
              )}
            </ActionButtonContainer>
          </PopUpItemContainer>
        </>
      }
    />
  );
}

const PopUpItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 10px;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  margin-left: auto;
`;

const FileUploadInputContainer = styled.div`
  max-width: 80%;
`;
