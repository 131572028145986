import { styled } from '@linaria/react';
import { Divider, PasswordInput, Stack, TextInput } from '@mantine/core';
import i18n from '../../../../base-dictionary';
import { UseFormReturnType } from '@mantine/form';
import { HEIGHT_BREAKPOINTS } from '../../../../styles/breakpoints';
import { CreateAccountForm as CreateAccountFormType } from './create-account.type';

type Props = {
  form: UseFormReturnType<CreateAccountFormType>;
};

export const CreateAccountForm = ({ form }: Props) => (
  <FormStack role="form" aria-label={i18n.t('common.form.create_account_form')}>
    <Divider />
    <StyledInput
      label={i18n.t('common.form.email')}
      placeholder={i18n.t('common.placeholders.email')}
      value={form.values.email}
      onChange={(event) => form.setFieldValue('email', event.currentTarget.value.trim())}
      error={form.errors.email}
      w="100%"
      autoComplete="email"
      required
    />
    <StyledInput
      label={i18n.t('common.form.name')}
      placeholder={i18n.t('common.placeholders.name')}
      value={form.values.name}
      onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
      error={form.errors.name}
      w="100%"
      required
    />
    <StyledPasswordInput
      label={i18n.t('common.form.password')}
      placeholder={i18n.t('common.placeholders.password')}
      value={form.values.password}
      onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
      error={form.errors.password}
      w="100%"
      autoComplete="new-password"
      required
    />
    <StyledPasswordInput
      label={i18n.t('common.form.confirm_password')}
      placeholder={i18n.t('common.placeholders.confirm_password')}
      value={form.values.confirmPassword}
      onChange={(event) => form.setFieldValue('confirmPassword', event.currentTarget.value)}
      error={form.errors.confirmPassword}
      w="100%"
      autoComplete="new-password"
      required
    />
  </FormStack>
);

const FormStack = styled(Stack)`
  gap: 24px;

  @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
    gap: 16px;
  }

  @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
    gap: 12px;
  }
`;

const baseInputStyles = `
  input {
    @media (max-height: ${HEIGHT_BREAKPOINTS.md}) {
      font-size: 14px;
      height: 36px;
    }

    @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
      font-size: 13px;
      height: 32px;
    }
  }

  label {
    @media (max-height: ${HEIGHT_BREAKPOINTS.sm}) {
      font-size: 13px;
      margin-bottom: 4px;
    }
  }
`;

const StyledInput = styled(TextInput)`
  ${baseInputStyles}
`;

const StyledPasswordInput = styled(PasswordInput)`
  ${baseInputStyles}
`;
