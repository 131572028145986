import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useMeasureDetail,
  useWorkPeriodsTasksSingular,
} from '../../../../api/work-periods-client/work-periods-client.hooks';
import { Measure, TimeAllocationType } from '../../../../api/work-periods-client/work-periods-client.type';
import i18n from '../../../../base-dictionary';
import { BrandedLoadingOverlay } from '../../../../components/loader/branded-loader';
import { MeasureInfoBox } from '../../../../components/measure-info-box';
import { PageHeaderMessage } from '../../../../components/page-header-message/page-header-message';
import { Title } from '../../../../components/title/title';
import { MenuDrivenContainer } from '../../../menu-driven-container/menu-driven-container';
import { TasksTable } from '../../../tasks-table/tasks-table';
import { convertToTaskRecord } from './initiative-phase.helpers';

export function InitiativePhaseTasksView() {
  const [searchParams] = useSearchParams();
  const measure = searchParams.get('measure') || '';
  const currentDate = searchParams.get('current_date') || '';
  const startDate = searchParams.get('start_date') || '';
  const endDate = searchParams.get('end_date') || '';
  const factor = searchParams.get('factor') || '';
  const initiativeId = searchParams.get('initiative_id') || '';
  const epicId = searchParams.get('epic_id') || '';
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: tasksQueryData = [], query } = useWorkPeriodsTasksSingular({
    payload: {
      measure_name: [measure as Measure],
      initiative_id: !epicId ? initiativeId : undefined,
      epic_id: epicId ? epicId : undefined,
      start_date: startDate,
      end_date: endDate,
      measure_filter: `${factor},${currentDate}`,
      time_allocation_type: TimeAllocationType.BiWeekly,
    },
    options: {
      enabled: Boolean(measure && startDate && endDate && initiativeId),
    },
  });

  const { data: measureDetailsData } = useMeasureDetail({
    measureName: measure,
    options: {
      enabled: Boolean(measure),
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    if (!query.isPending && !tasksQueryData.length) {
      setErrorMessage('No tasks found for the selected criteria');
    }
  }, [tasksQueryData, query.isPending]);

  const header = (
    <Title
      title={`${i18n.t('common.work_units')} for ${factor || ''}  through ${dayjs(endDate).format('MM/DD/YYYY')}`}
    />
  );

  const formattedTasks = tasksQueryData.map(convertToTaskRecord);

  return (
    <MenuDrivenContainer>
      <Container>
        <BrandedLoadingOverlay visible={query.isPending} />
        <>
          <Flex direction="column" gap="md">
            {header}
            {!query.isPending && errorMessage && <PageHeaderMessage message={errorMessage} color="red" />}
            {measureDetailsData && <MeasureInfoBox measure={measureDetailsData} />}
          </Flex>
          <TasksTable tasks={formattedTasks} />
        </>
      </Container>
    </MenuDrivenContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 100%;
`;
