import { HistoricalSprintDataKind } from '../../api/sprints-client/sprints-client.type';

const measuredBy = (kind: HistoricalSprintDataKind, itemCount: number) => {
  switch (kind) {
    case HistoricalSprintDataKind.velocity:
      return itemCount === 1.0 ? 'pt' : 'pts';
    case HistoricalSprintDataKind.leadTime:
      return itemCount === 1.0 ? 'day' : 'days';
    case HistoricalSprintDataKind.reactionTime:
      return itemCount === 1.0 ? 'day' : 'days';
    case HistoricalSprintDataKind.throughput:
      return itemCount === 1.0 ? 'task' : 'tasks';
    case HistoricalSprintDataKind.cycleTime:
      return itemCount === 1.0 ? 'day' : 'days';
    default:
      return '';
  }
};

const historicalSprintDataKindFromEndpointDescription = (desc: string): HistoricalSprintDataKind => {
  switch (desc.toLowerCase()) {
    case 'velocity':
      return HistoricalSprintDataKind.velocity;
    case 'throughput':
      return HistoricalSprintDataKind.throughput;
    case 'lead':
      return HistoricalSprintDataKind.leadTime;
    case 'reaction':
      return HistoricalSprintDataKind.reactionTime;
    default:
      return HistoricalSprintDataKind.cycleTime;
  }
};

/**
 * Returns the health score grade for a given health score
 * @param score: number - the health score
 * @returns string - the health score grade
 */
function getHealthScoreGrade(score: number | undefined): string {
  if (!score) {
    return '-';
  }

  switch (true) {
    case score === -1:
      return '-';
    case score < 50:
      return 'F';
    case score < 64:
      return 'D-';
    case score < 67:
      return 'D';
    case score < 70:
      return 'D+';
    case score < 74:
      return 'C-';
    case score < 77:
      return 'C';
    case score < 80:
      return 'C+';
    case score < 84:
      return 'B-';
    case score < 87:
      return 'B';
    case score < 90:
      return 'B+';
    case score < 94:
      return 'A-';
    case score < 100:
      return 'A';
    default:
      return 'A+';
  }
}

export { getHealthScoreGrade, historicalSprintDataKindFromEndpointDescription, measuredBy };
