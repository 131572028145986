import { useCallback, useEffect, useMemo, useState } from 'react';
import { Initiative } from '../../../api/initiative-client/initiative-client.type';
import { useAvailableMeasures } from '../../../api/work-periods-client/work-periods-client.hooks';
import {
  AvailableMeasuresResponse,
  ModuleType,
  TimeAllocationType,
} from '../../../api/work-periods-client/work-periods-client.type';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { Target, TargetComparison, TargetPayload } from './targets-client.type';
import { convertFormToTargetPayload, convertTargetToForm } from './targets.helpers';
import { TargetForm, TargetFormObject } from './targets.types';

/**
 * Interface for form validation errors
 */
interface FormErrors {
  name?: string;
  object_type?: string;
  objects?: string;
  measure?: string;
  target_value?: string;
  target_value_upper?: string;
  start_date?: string;
  end_date?: string;
  time_allocation_type?: string;
}

/**
 * Base form state for a new target
 */
const baseForm: TargetForm = {
  id: undefined,
  name: '',
  measure: '',
  target_value: 0,
  target_value_upper: null,
  target_comparison: TargetComparison.GTE,
  is_active: true,
  use_transform: true,
  start_date: null,
  end_date: null,
  time_allocation_type: null,
  is_work_period_target: false,
  object_type: null,
  objects: [],
};

/**
 * Hook to fetch available measures for a target based on its type and scope
 *
 * @param {boolean} isWorkPeriodTarget - Whether the target is for a work period
 * @param {TargetForm} formData - The target form data containing object type and objects
 * @returns {Object} Object containing:
 *   - data: The available measures response data
 */
const useTargetAvailableMeasures = (
  isWorkPeriodTarget: boolean,
  formData: TargetForm,
): { data: AvailableMeasuresResponse | undefined } => {
  const { data } = useAvailableMeasures({
    payload: {
      scope: isWorkPeriodTarget ? ModuleType.WorkPeriod : ModuleType.ProcessAnalysis,
      portfolio_id: formData.object_type === 'portfolio' ? formData.objects?.[0]?.id : undefined,
      project_id: formData.object_type === 'project' ? formData.objects?.[0]?.id : undefined,
      subproject_id: formData.object_type === 'board' ? formData.objects?.[0]?.id : undefined,
    },
    options: {
      enabled: !!formData.object_type && formData.objects.length === 1,
      staleTime: Infinity,
    },
  });

  return {
    data,
  };
};
/**
 * Hook to manage target form state and validation
 *
 * @param {boolean} opened - Whether the modal is open
 * @param {Target | undefined} initialData - Initial target data for editing
 * @param {(data: Partial<TargetPayload>) => void} onSave - Callback function when saving the form
 * @param {() => void} onClose - Callback function when closing the modal
 * @returns {Object} Object containing form state and handlers
 */
const useTargetForm = (
  opened: boolean,
  initialData: Target | undefined,
  onSave: (data: Partial<TargetPayload>) => void,
  onClose: () => void,
) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<TargetForm>(baseForm);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (opened && initialData) {
      setFormData(convertTargetToForm(initialData));
    } else if (opened) {
      setFormData(baseForm);
    }
  }, [opened, initialData]);

  const handleClose = useCallback(() => {
    setFormData(baseForm);
    setStep(1);
    onClose();
  }, [onClose]);

  const validateStep1 = useCallback((): boolean => {
    const errors: FormErrors = {};

    if (!formData.object_type) {
      errors.object_type = 'Object type is required';
    }
    if (!formData.measure) {
      errors.measure = 'Measure is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formData]);

  const validateStep2 = useCallback((): boolean => {
    const errors: FormErrors = {};

    if (formData.target_value === undefined || formData.target_value === null) {
      errors.target_value = 'Target value is required';
    }
    if (
      (formData.target_comparison === TargetComparison.RANGE_IN ||
        formData.target_comparison === TargetComparison.RANGE_EX) &&
      (formData.target_value_upper === undefined || formData.target_value_upper === null)
    ) {
      errors.target_value_upper = 'Upper target value is required for range comparison';
    }
    if (!formData.is_work_period_target && formData.use_transform && !formData.time_allocation_type) {
      errors.time_allocation_type = 'Repeat frequency is required';
    }

    if (!formData.start_date) {
      errors.start_date = 'Start date is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formData]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (step === 1) {
        if (validateStep1()) {
          setStep(2);
        }
        return;
      }

      if (validateStep2()) {
        onSave(convertFormToTargetPayload(formData));
        handleClose();
      }
    },
    [step, validateStep1, validateStep2, formData, onSave, handleClose],
  );

  const handleObjectTypeChange = useCallback(
    (value: string | null) => {
      setFormData({
        ...formData,
        object_type: value as 'portfolio' | 'project' | 'board' | 'initiative' | null,
        objects: [],
        measure: '',
        is_work_period_target: false,
        time_allocation_type: value === 'initiative' ? TimeAllocationType.BiWeekly : null,
      });
      setFormErrors({});
    },
    [formData],
  );

  const isWorkPeriodTarget = useMemo(() => {
    return formData.object_type === 'board' && formData.is_work_period_target;
  }, [formData.object_type, formData.is_work_period_target]);

  return {
    step,
    setStep,
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    handleClose,
    handleSubmit,
    handleObjectTypeChange,
    isWorkPeriodTarget,
    validateStep1,
    validateStep2,
  };
};

/**
 * Hook to provide available objects for selection based on the object type
 *
 * @param {string | null} objectType - The type of object (portfolio, project, board, initiative)
 * @param {Initiative[]} initiatives - Array of initiatives to include when objectType is 'initiative'
 * @returns {TargetFormObject[]} Array of objects with id, name and optional start/end dates for selection
 */
const useAvailableObjects = (objectType: string | null, initiatives: Initiative[]): TargetFormObject[] => {
  const portfolios = useGlobalStore((state) => state.portfolios);
  const organizationId = useGlobalStore((state) => state.organization?.id);
  const teams = useGlobalStore((state) => state.teams);

  return useMemo(() => {
    if (objectType === 'portfolio') {
      return portfolios
        .filter((p) => p.organization?.id === organizationId)
        .map((p) => ({
          id: p.id,
          name: p.name,
        }));
    } else if (objectType === 'project') {
      return teams.map((team) => ({
        id: team.id,
        name: team.name,
      }));
    } else if (objectType === 'board') {
      return teams.flatMap((team) =>
        team.subprojects.map((board) => ({
          id: board.id,
          name: `${team.name} - ${board.name}`,
        })),
      );
    } else if (objectType === 'initiative') {
      return initiatives.map((initiative) => ({
        id: initiative.id,
        name: initiative.name,
        start_date: initiative.start_date,
        end_date: initiative.end_date,
      }));
    }
    return [];
  }, [objectType, portfolios, teams, organizationId, initiatives]);
};

/**
 * Hook to process available measures data for display
 *
 * @param {AvailableMeasuresResponse | undefined} rawData - The raw measures data
 * @param {boolean} isWorkPeriodTarget - Whether the target is for a work period
 * @returns {Array | null} Processed measures data for display or null if no data
 */
const useProcessedMeasures = (rawData: AvailableMeasuresResponse | undefined, isWorkPeriodTarget: boolean) => {
  return useMemo(() => {
    if (rawData) {
      const measures = rawData[isWorkPeriodTarget ? ModuleType.WorkPeriod : ModuleType.ProcessAnalysis];
      if (isWorkPeriodTarget) {
        return measures.measures.filter((measure) => measure.is_custom);
      }
      return measures.measures;
    }
    return null;
  }, [rawData, isWorkPeriodTarget]);
};

export { baseForm, useAvailableObjects, useProcessedMeasures, useTargetAvailableMeasures, useTargetForm };
