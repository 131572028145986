import {
  ActiveElement,
  BarElement,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Metric } from '../../../../api/initiative-client/initiative-client.type';
import { extractYearMonth } from '../../../../helpers/string-helpers/string-helpers';
import { getDateLabels } from '../initiative-performance.helpers';
import { ColoredEntity, InitiativeMetricType, InitiativeScope } from '../initiative-performance.type';
import { getChartTitle, getYAxisLabel } from './section-contributors.helpers';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const getData = (
  metric: InitiativeMetricType,
  rawData: Record<string, Metric>,
  coloredContributors: ColoredEntity[],
) => {
  const firstContributingTeamData = Object.values(rawData)[0] || {};
  const labels = getDateLabels(Object.keys(firstContributingTeamData.tasks || {}));

  return {
    labels,
    datasets: Object.keys(rawData).map((id) => {
      const color = coloredContributors.find((entity) => entity.id === id)?.color;
      return {
        label: id,
        data: Object.values(rawData[id]?.[metric] || {}),
        borderColor: color,
        backgroundColor: color,
      };
    }),
  };
};

const getOptions = (metric: InitiativeMetricType, scope: InitiativeScope): any => ({
  responsive: true,
  maintainAspectRatio: false,
  pointStyle: true,
  cubicInterpolationMode: 'default',
  borderWidth: 1,
  barPercentage: 0.9,
  categoryPercentage: 0.9,
  onHover: (event: ChartEvent, elements: ActiveElement[]) => {
    let cursorStyle = 'default';

    const isOnElement = elements.length > 0;
    if (isOnElement && [InitiativeMetricType.Tasks, InitiativeMetricType.Points].includes(metric)) {
      cursorStyle = 'pointer';
    }
    if (event?.native?.target) {
      (event.native.target as HTMLElement).style.cursor = cursorStyle;
    }
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: 'Month',
        font: {
          size: 12,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      position: 'left' as const,
      title: {
        display: true,
        text: getYAxisLabel(metric),
        font: {
          size: 12,
        },
        padding: {
          bottom: 0,
        },
      },
      ticks: {
        font: {
          size: 12,
        },
        padding: 10,
      },
      border: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: getChartTitle(metric, scope),
      font: {
        size: 16,
        weight: '500',
      },
      padding: {
        bottom: 20,
      },
    },
    annotation: {
      common: {
        drawTime: 'afterDraw',
      },
    },
    tooltip: {
      enabled: false,
    },
    filler: {
      propagate: true,
      drawTime: 'beforeDatasetsDraw' as const,
    },
  },
});

type Props = {
  metric: InitiativeMetricType;
  scope: InitiativeScope;
  contributions: Record<string, Metric>;
  coloredContributors: ColoredEntity[];
};

export function ContributorsBarChart({ metric, scope, contributions, coloredContributors }: Props) {
  const chartRef = useRef<ChartJS<'bar'>>(null);
  const navigate = useNavigate();
  const data = getData(metric, contributions, coloredContributors);

  const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!chartRef.current) {
      return;
    }

    const barElement = getElementAtEvent(chartRef.current, event)[0];

    if (!barElement) {
      return;
    }

    const { year, month } = extractYearMonth(data.labels[barElement.index]);
    const { label: id } = data.datasets[barElement.datasetIndex];

    if (id && year && month) {
      scope === InitiativeScope.Teams
        ? navigate(`tasks/?filter=project&date=${year}-${month}&projectId=${id}`)
        : navigate(`tasks/?filter=subproject&date=${year}-${month}&subprojectId=${id}`);
    }
  };

  return (
    <div style={{ width: '75%', flex: '0 0 auto', height: 352 }}>
      <Bar ref={chartRef} options={getOptions(metric, scope)} data={data} onClick={onClick} />
    </div>
  );
}
