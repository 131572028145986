import { Stack } from '@mantine/core';
import { inkLight } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';

export function EmptyFilterResult({ objectName }: { objectName: string }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Stack align="center">
        <Icon name="info" color={inkLight} size={40} />
        <Text size="large" weight="medium" color={inkLight}>
          No {objectName} found.
        </Text>
        <Text size="medium" weight="medium" color={inkLight}>
          Please try a different filter.
        </Text>
      </Stack>
    </div>
  );
}
