import { SelectProps } from '@mantine/core';

/**
 * Common props for select components to ensure consistent styling and behavior.
 *
 * @type {Partial<SelectProps>}
 */
const commonSelectProps: Partial<SelectProps> = {
  styles: {
    input: {
      paddingRight: 30,
    },
  },
};

export { commonSelectProps };
