import { styled } from '@linaria/react';
import { LoadingOverlay, Title } from '@mantine/core';
import { Fragment } from 'react';
import i18n from '../../../base-dictionary';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../../store/process-analysis-store/process-analysis-store';
import { useDateRange, useEntities } from '../../../store/process-analysis-store/process-analysis-store.hooks';
import { newCOLORS } from '../../../styles/colors';
import { Text } from '../../../ui-library/typography/typography';
import { MeasureLabel } from '../measure-label';
import { Pills } from '../pills/pills';
import { getPills } from '../process-analysis.helpers';
import { Tab } from '../process-analysis.type';
import { Table } from '../table/table';
import { isWorkloadDistributionData } from './workload-distribution.helpers';
import { useWorkloadDistributionData } from './workload-distribution.hooks';

// NOTE: only shown on Portfolio Singular view and Team Multiple view
export function WorkloadDistribution() {
  const { portfolio, portfolios = [], teams = [] } = useGlobalStore();
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();

  const pills = getPills(entities || [], activeTab, portfolios, teams, startDate, endDate);

  const projectIds =
    activeTab === Tab.Portfolios
      ? portfolio?.projects.map((project) => project.id)
      : activeTab === Tab.Teams
        ? entities
        : [];
  const projectIdToName = portfolio?.projects.map((project) => ({ id: project.id, name: project.name }));
  const { portfolioData, projectData, isFetching } = useWorkloadDistributionData(
    portfolio?.id || '',
    projectIds?.filter((id): id is string => typeof id === 'string') || [],
    !!portfolio,
  );

  const formattedPortfolioData = isWorkloadDistributionData(portfolioData) ? portfolioData : null;

  const rows = projectData
    ? Object.entries(projectData)
        .map(([projectId, projectMeasures]) => {
          const project = projectIdToName?.find((p) => p.id === projectId);
          if (!project) {
            return null;
          }
          const formattedProjectMeasures = isWorkloadDistributionData(projectMeasures) ? projectMeasures : null;

          const totalCapacityTasks = formattedPortfolioData?.workload_distribution_capacity.capacity_tasks;
          const projectCapacityTasks = formattedProjectMeasures?.workload_distribution_capacity.capacity_tasks;
          const distribution =
            totalCapacityTasks && projectCapacityTasks && totalCapacityTasks > 0
              ? projectCapacityTasks / totalCapacityTasks
              : 0;

          return [
            <GridCell key={`name-${projectId}`}>
              <MeasureLabel label={project.name} tooltip={project.name} />
            </GridCell>,
            <GridCell key={`tasks-${projectId}`}>
              {formattedProjectMeasures?.workload_distribution_capacity.capacity_tasks}
              <UnitLabel>
                <Text>{i18n.t('common.work_units')}</Text>
              </UnitLabel>
            </GridCell>,
            <GridCell key={`points-${projectId}`}>
              {formattedProjectMeasures?.workload_distribution_capacity.capacity_points}
              <UnitLabel>
                <Text>{i18n.t('common.estimation')}</Text>
              </UnitLabel>
            </GridCell>,
            <GridCell key={`distribution-${projectId}`}>
              {`${(distribution * 100).toFixed(1)}`}
              <UnitLabel>%</UnitLabel>
            </GridCell>,
          ];
        })
        .filter((row): row is JSX.Element[] => row !== null)
    : [];

  return (
    <Fragment>
      {projectData && Object.keys(projectData).length > 0 ? (
        <DropShadowContainer>
          <LoadingOverlay visible={isFetching} overlayProps={{ blur: 2 }} style={{ zIndex: 200 }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '12px' }}>
            <Pills pillText={pills} />
            <Title>Capacity</Title>
          </div>
          <Table
            label={i18n.t('common.teams')}
            columnHeaders={[
              i18n.t('process_analysis.workload_distribution.capacity_work_units', {
                work_units: i18n.t('common.work_units'),
              }),
              i18n.t('process_analysis.workload_distribution.capacity_estimation', {
                estimation: i18n.t('common.estimation'),
              }),
              i18n.t('process_analysis.workload_distribution.workload_distribution'),
            ]}
            columnTooltips={[
              i18n.t('process_analysis.workload_distribution.tooltips.capacity', {
                work_units: i18n.t('common.work_units'),
              }),
              i18n.t('process_analysis.workload_distribution.tooltips.estimation', {
                estimation: i18n.t('common.estimation'),
              }),
              i18n.t('process_analysis.workload_distribution.tooltips.distribution'),
            ]}
            rows={rows || []}
          />
        </DropShadowContainer>
      ) : null}
    </Fragment>
  );
}

const DropShadowContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${newCOLORS.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
`;

const GridCell = styled.div`
  display: flex;
  align-items: end;
  gap: 4px;
  color: ${newCOLORS.darkGray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
`;

const UnitLabel = styled.div`
  color: ${newCOLORS.gray};
  background-color: ${newCOLORS.white};
  text-align: left;
  font-family: Figtree, sans-serif;
  font-size: 12px;
  font-weight: 500;
`;
