import { downloadCSV } from '../../../../helpers/csv-download';
import { secondaryBase } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { TableColumn } from '../../../data-management/components/table';
import { RiskBadge } from '../risk-analysis';
import { RiskAnalysisPullRequest } from '../risk-analysis.client.type';
import { getRiskCategory } from '../risk-analysis.helpers';
import { TruncatedText } from '../truncated-text';

const handleDownloadPullRequestTableCSV = (pullRequests: RiskAnalysisPullRequest[]) => {
  try {
    const headers = ['Title', 'Repository', 'Changed Files', 'Additions', 'Deletions', 'Risk Category', 'Risk Score'];

    const rows = pullRequests.map((pr) => [
      pr.title,
      pr.repository.name,
      pr.changed_files,
      pr.additions,
      pr.deletions,
      getRiskCategory(pr.flora_annotations?.risk_score).label,
      pr.flora_annotations?.risk_score?.toFixed(0) || 'N/A',
    ]);

    downloadCSV([headers, ...rows], 'pull-requests.csv');
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

const getPullRequestTableColumns = (): TableColumn<RiskAnalysisPullRequest>[] => {
  return [
    {
      key: 'title',
      label: 'Title',
      width: '25%',
      sortable: true,
      render: (_, pr) => (
        <a
          href={pr.url}
          onClick={(e) => {
            e.preventDefault();
            window.open(pr.url, '_blank');
          }}
          style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          <Text size="small" color={secondaryBase}>
            {pr.title}
          </Text>
        </a>
      ),
    },
    {
      key: 'body',
      label: 'Description',
      width: '25%',
      sortable: true,
      render: (_, pr) => <TruncatedText text={pr.body || 'No description'} onToggle={() => {}} />,
    },
    {
      key: 'changed_files',
      label: 'Changed Files',
      width: '10%',
      sortable: true,
      render: (_, pr) => pr.changed_files,
    },
    {
      key: 'additions',
      label: 'Additions',
      width: '10%',
      sortable: true,
      render: (_, pr) => pr.additions,
    },
    {
      key: 'deletions',
      label: 'Deletions',
      width: '10%',
      sortable: true,
      render: (_, pr) => pr.deletions,
    },
    {
      key: 'flora_annotations.risk_category' as keyof RiskAnalysisPullRequest,
      label: 'Risk Category',
      width: '10%',
      sortable: true,
      render: (_, pr) => {
        const riskCategory = getRiskCategory(pr.flora_annotations?.risk_score);
        return <RiskBadge color={riskCategory.color}>{riskCategory.label}</RiskBadge>;
      },
    },
    {
      key: 'flora_annotations.risk_score' as keyof RiskAnalysisPullRequest,
      label: 'Risk Score',
      width: '10%',
      sortable: true,
      render: (_, pr) => pr.flora_annotations?.risk_score?.toFixed(0) || 'N/A',
    },
  ];
};

export { getPullRequestTableColumns, handleDownloadPullRequestTableCSV };
