import i18n from '../../base-dictionary';
import { useGlobalStore } from '../../store/global-store/global-store';
import { GeneralViewNames, NavItem } from './side-bar.type';

const showDashboards: boolean = import.meta.env.VITE_FEATURE_FLAG_DASHBOARDS === 'true';
const showBreakOutWorkPeriods: boolean = import.meta.env.VITE_FEATURE_FLAG_BREAK_OUT_WORK_PERIODS === 'true';
const isAutomationEnabled: boolean = import.meta.env.VITE_FEATURE_FLAG_AUTOMATION === 'true';

export function useNavItems(): NavItem[] {
  const useAI = useGlobalStore((state) => state.useAI);
  const navItems: NavItem[] = [
    {
      id: GeneralViewNames.Dashboards,
      label: i18n.t('navigation.dashboards'),
      icon: 'dashboard',
      path: '/application/dashboards',
      isBeta: true,
      show: showDashboards,
    },
    {
      id: GeneralViewNames.Strategy,
      label: i18n.t('navigation.strategy'),
      icon: 'ads_click',
      path: '/application/strategy',
      show: true,
    },
    {
      id: GeneralViewNames.Financials,
      label: i18n.t('navigation.financials'),
      icon: 'payments',
      path: '/application/financials/portfolio',
      show: true,
    },
    {
      id: GeneralViewNames.Process,
      label: i18n.t('navigation.process'),
      icon: 'map',
      path: `/application/process/`,
      show: true,
    },
    {
      id: GeneralViewNames.ProcessAnalysis,
      label: i18n.t('navigation.process_analysis'),
      icon: 'auto_graph',
      path: '/application/process-analysis',
      show: true,
    },
    {
      id: GeneralViewNames.WorkPeriods,
      label: i18n.t('navigation.work_periods'),
      icon: 'calendar_month',
      path: '/application/work-periods',
      show: showBreakOutWorkPeriods,
    },
    {
      id: GeneralViewNames.Adherence,
      label: i18n.t('navigation.adherence'),
      icon: 'emergency',
      path: '/application/adherence',
      isBeta: true,
      show: useAI,
    },
    {
      id: GeneralViewNames.Integrations,
      label: i18n.t('navigation.integrations'),
      icon: 'hub',
      path: '/application/integrations-list',
      show: true,
    },
    {
      id: GeneralViewNames.Automation,
      label: i18n.t('navigation.automation'),
      icon: 'smart_toy',
      path: '/application/automation',
      show: isAutomationEnabled,
    },
  ];

  return navItems;
}
