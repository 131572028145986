import dayjs from 'dayjs';
import { useEffect } from 'react';
import { MinifiedTask } from '../../api/tasks-client/task-client.type';
import {
  useAvailableMeasures,
  useTimeAllocations as useTimeAllocationsHook,
  useWorkPeriodsTasksSingular,
} from '../../api/work-periods-client/work-periods-client.hooks';
import {
  EntityType,
  Measure,
  ModuleType,
  UseWorkPeriodsMeasurePayloadSingular,
} from '../../api/work-periods-client/work-periods-client.type';
import { useOrganizationId } from '../../helpers/auth-helpers/auth.hooks';
import { useGlobalStore } from '../../store/global-store/global-store';
import { useProcessAnalysisStore } from '../../store/process-analysis-store/process-analysis-store';
import {
  setAvailableMeasures,
  setTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.actions';
import {
  useDateRange,
  useEntities,
  useTimeAllocation,
  useTimeAllocations,
} from '../../store/process-analysis-store/process-analysis-store.hooks';
import { useAvailableTargets } from '../adherence/targets/targets-client.hooks';
import { GetAvailableTargetsPayload, GetAvailableTargetsResponse } from '../adherence/targets/targets-client.type';
import { useFlowFilters } from './assessment-view/assessment-view.hooks';
import { getEntityType, getTimeAllocationEndDate } from './process-analysis.helpers';
import { Tab, WorkPeriodType } from './process-analysis.type';

/**
 * Custom hook for fetching a list of available measures for the process analysis page.
 * Stores the result in the process analysis store.
 *
 * @returns {{ isFetching: boolean }} - An object containing an isFetching flag.
 */
const useAvailableMeasuresData = (): { isFetching: boolean } => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const entities = useEntities();
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);

  const getEntityPayload = () => {
    if (activeTab === Tab.WorkPeriods) {
      if (workPeriodType === WorkPeriodType.Defined && workPeriod) {
        return { work_period_id: workPeriod.id };
      }
      return workPeriodBoardId ? { subproject_id: workPeriodBoardId } : {};
    }

    switch (activeTab) {
      case Tab.Portfolios:
        return { portfolio_id: entities.length > 0 ? entities[0] : portfolio?.id };
      case Tab.Teams:
        return entities.length > 0 ? { project_id: entities[0] } : {};
      case Tab.Boards:
        return entities.length > 0 ? { subproject_id: entities[0] } : {};
      default:
        return {};
    }
  };

  const scope = activeTab === Tab.WorkPeriods ? ModuleType.WorkPeriod : ModuleType.ProcessAnalysis;

  const { data, query } = useAvailableMeasures({
    payload: {
      scope,
      ...getEntityPayload(),
    },
    options: {
      staleTime: 0,
      gcTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    if (data) {
      const measures = data[scope]?.measures || [];
      setAvailableMeasures(measures);
    }
  }, [data, scope]);

  return { isFetching: query.isFetching };
};

const useTimeAllocationsData = (): { isFetching: boolean } => {
  const { startDate, endDate } = useDateRange();
  const timeAllocationType = useTimeAllocation();

  const { data, query } = useTimeAllocationsHook({
    payload: {
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
      time_allocation_type: timeAllocationType,
    },
    options: {
      enabled: !!startDate && !!endDate,
      staleTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    if (data) {
      setTimeAllocations(data);
    }
  }, [data]);
  return { isFetching: query.isFetching };
};

/**
 * Custom hook to fetch tasks data for a specific measure for Portfolio/Team/Board level.
 *
 * @param {Measure} measure - The measure for which to fetch tasks data.
 * @param {string | null} taskFilter - The filter for the tasks data. Either contains a date or date and factor.
 * @param {boolean | null} isCustom - A boolean indicating whether the measure is custom.
 * @param {string | null} workPeriodId - The ID of the work period for which to fetch tasks data.
 * @returns {MinifiedTask[] | undefined} data - The array of fetched tasks, or undefined if not yet loaded.
 * @returns {boolean} isFetching - A boolean indicating whether the data is currently being fetched.
 */
const useTasksData = (
  measure: Measure,
  taskFilter?: string | null,
  isCustom?: boolean | null,
  workPeriodId?: string | null,
): { data: MinifiedTask[] | undefined; isFetching: boolean } => {
  const activeTab = useProcessAnalysisStore((state) => state.activeTab);
  const timeAllocations = useTimeAllocations();
  const measures = [measure];
  const filters = useFlowFilters(measures);

  const payload = useTasksPayload(measures, activeTab, workPeriodId);
  if (isCustom) {
    payload.is_custom = true;
    payload.module_type = activeTab === Tab.WorkPeriods ? ModuleType.WorkPeriod : ModuleType.ProcessAnalysis;
  }

  if (measure === Measure.BulkStatusChanges && taskFilter) {
    payload.measure_filter = taskFilter;
  } else if (activeTab === Tab.WorkPeriods && (measure === Measure.FlowByPhase || measure === Measure.FlowByPace)) {
    if (filters !== null) {
      Object.assign(payload, filters);
    }
  } else if (activeTab === Tab.WorkPeriods && isCustom && taskFilter) {
    payload.measure_filter = taskFilter;
  } else if (taskFilter != null) {
    const matchingAllocation = timeAllocations.find((allocation) => allocation.id === taskFilter);
    if (activeTab === Tab.WorkPeriods) {
      payload.start_date = taskFilter;
      payload.end_date = taskFilter;
    } else if (matchingAllocation) {
      payload.start_date = matchingAllocation.start.split('T')[0];
      // in some cases, we need to subtract one day from the end date to get the correct date range
      // otherwise the end date may overlap with the start date of the next allocation
      payload.end_date = getTimeAllocationEndDate(payload.start_date, matchingAllocation.end.split('T')[0]);
    }
  }
  const { data, query } = useWorkPeriodsTasksSingular({
    payload,
    options: {
      enabled: !!payload.subproject_id || !!payload.portfolio_id || !!payload.project_id || !!payload.work_period_id,
    },
  });
  return { data, isFetching: query.isFetching };
};

/**
 * Custom hook to generate a payload for fetching tasks data.
 *
 * @param {Measure[]} measures - The list of measures for the payload.
 * @param {Tab} activeTab - The active tab for the payload.
 * @param {string | null} workPeriodId - The ID of the work period for which to fetch tasks data.
 * @returns {UseWorkPeriodsMeasurePayloadSingular} The payload object for fetching tasks data.
 */
const useTasksPayload = (
  measures: Measure[],
  activeTab: Tab,
  workPeriodId?: string | null,
): UseWorkPeriodsMeasurePayloadSingular => {
  const { startDate, endDate } = useDateRange();
  const entities = useEntities();
  const timeAllocation = useTimeAllocation();

  // if work period
  const workPeriodBoardId = useProcessAnalysisStore((state) => state.workPeriodBoardId);
  const workPeriodType = useProcessAnalysisStore((state) => state.workPeriodType);
  const workPeriod = useProcessAnalysisStore((state) => state.workPeriod);

  const entityParams = {
    [EntityType.Portfolio]: 'portfolio_id',
    [EntityType.Project]: 'project_id',
    [EntityType.Subproject]: 'subproject_id',
  };
  if (workPeriodId) {
    return {
      work_period_id: workPeriodId,
      measure_name: measures,
    };
  } else if (activeTab !== Tab.WorkPeriods) {
    return {
      [entityParams[getEntityType(activeTab)]]: entities.length > 0 ? entities[0] : null,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      end_date: dayjs(endDate).format('YYYY-MM-DD'),
      measure_name: measures,
      time_allocation_type: timeAllocation,
    };
  } else {
    const defaultPayload = {
      subproject_id: workPeriodBoardId,
      measure_name: measures,
    };

    const customPayload =
      workPeriodType === WorkPeriodType.Defined && workPeriod
        ? {
            work_period_id: workPeriod?.id,
            start_date: dayjs(workPeriod?.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(workPeriod?.end_date).format('YYYY-MM-DD'),
          }
        : {
            start_date: dayjs(startDate).format('YYYY-MM-DD'),
            end_date: dayjs(endDate).format('YYYY-MM-DD'),
          };

    return {
      ...defaultPayload,
      ...customPayload,
    } as UseWorkPeriodsMeasurePayloadSingular;
  }
};

/**
 * Custom hook that fetches available targets for process analysis based on the active tab and entities.
 *
 * @returns {GetAvailableTargetsResponse} Object containing available targets data organized by entity ID.
 * The response is empty if no targets are available.
 *
 * The hook handles different entity types (portfolios, teams, boards) and constructs the appropriate
 * payload based on the active tab. It uses the organization ID, date range, and time allocation
 * settings from the global store.
 */
const useProcessAnalysisTargets = (): GetAvailableTargetsResponse => {
  const organizationId = useOrganizationId();
  const { startDate, endDate } = useDateRange();
  const timeAllocation = useTimeAllocation();

  const activeTab = useProcessAnalysisStore((state) => state.activeTab);

  let payload: GetAvailableTargetsPayload = {
    organization_id: organizationId || '',
    start_date: dayjs(startDate).format('YYYY-MM-DD'),
    end_date: dayjs(endDate).format('YYYY-MM-DD'),
    time_allocation_type: timeAllocation,
  };
  const entities = useEntities();

  switch (activeTab) {
    case Tab.Portfolios:
      payload.portfolio_ids = entities.join(',');
      break;
    case Tab.Teams:
      payload.project_ids = entities.join(',');
      break;
    case Tab.Boards:
      payload.subproject_ids = entities.join(',');
      break;
  }

  const { data: availableTargets } = useAvailableTargets({
    payload,
  });

  return availableTargets ?? {};
};

export { useAvailableMeasuresData, useProcessAnalysisTargets, useTasksData, useTimeAllocationsData };
