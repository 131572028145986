enum ChangeLogStatus {
  Created = 'created',
  InProgress = 'in-progress',
  NotActive = 'not-active',
  NotStarted = 'not-started',
  Completed = 'completed',
}

type ChangeLogEntry = {
  status: ChangeLogStatus;
  date: Date;
};

export { ChangeLogStatus };
export type { ChangeLogEntry };
