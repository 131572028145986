import { Icon } from '@iconify/react/dist/iconify.js';
import { Flex, Grid, Select } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { ProcessMapping } from '../../../api/process-client/process-client.type';
import i18n from '../../../base-dictionary';
import { snakeCaseToTitleCase } from '../../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../../styles/colors';
import { TaskType } from '../../tasks-table/tasks-table.type';
import { getViewTypeTitle } from '../process.helpers';
import { useProcessScope } from '../process.hooks';
import { Text } from '../process.styled';
import { ViewType } from '../process.type';
import { BoardSelect } from './board-select';
import { EpicSelect } from './epic-select';
import { commonSelectProps } from './scope.helpers';
import { ScopeChange } from './scope.type';
import { TeamSelect } from './team-select';
import { ViewToggle } from './view-toggle';

type Props = {
  mapping: ProcessMapping | null;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
  isComparisonView?: boolean;
  onScopeChange?: (scopeChange: ScopeChange) => void;
};

export function Scope({ mapping, viewType, setViewType, isComparisonView = false, onScopeChange }: Props) {
  const { startDate, endDate, taskType, setScope } = useProcessScope();

  const handleScopeChange = (scopeChange: ScopeChange) => {
    setScope(scopeChange);

    if (onScopeChange) {
      onScopeChange(scopeChange);
    }
  };
  return (
    <Flex direction="column" gap={isComparisonView ? 16 : 24}>
      <Text fontSize={19}>Scope</Text>

      {!isComparisonView && (
        <>
          <ScopeInput label="Team">
            <TeamSelect hideLabel handleTeamSelected={(team) => handleScopeChange({ team })} />
          </ScopeInput>

          <ScopeInput label="Board">
            <BoardSelect hideLabel handleBoardSelected={(board) => handleScopeChange({ board })} />
          </ScopeInput>
        </>
      )}

      <ScopeInput label="Start Date">
        <MonthPickerInput
          {...{ placeholder: 'Pick start date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick start date"
          value={dayjs(startDate).toDate()}
          onChange={(value) => handleScopeChange({ startDate: dayjs(value).toISOString() })}
          maxDate={dayjs(endDate).toDate() || dayjs().startOf('month').toDate()}
          style={{ width: '100%' }}
        />
      </ScopeInput>

      <ScopeInput label="End Date">
        <MonthPickerInput
          {...{ placeholder: 'Pick end date' }}
          leftSection={<Icon icon="ion:calendar-outline" color={newCOLORS.blue} width={16} height={16} />}
          leftSectionPointerEvents="none"
          placeholder="Pick end date"
          value={dayjs(endDate).toDate()}
          minDate={dayjs(startDate).toDate()}
          onChange={(value) => {
            const isCurrentMonth = dayjs(value).isSame(dayjs(), 'month');
            const newDate = isCurrentMonth ? dayjs() : dayjs(value).endOf('month');
            handleScopeChange({ endDate: newDate.toISOString() });
          }}
          maxDate={dayjs().toDate()}
          style={{ width: '100%' }}
        />
      </ScopeInput>

      <ScopeInput label={i18n.t('process.scope.task_type', { work_units: i18n.t('common.work_units') })}>
        <Select
          placeholder={i18n.t('process.scope.task_type_placeholder', { work_units: i18n.t('common.work_units') })}
          data={Object.values(TaskType).map((taskType) => ({
            value: taskType,
            label: snakeCaseToTitleCase(taskType),
          }))}
          value={taskType}
          onChange={(value) => handleScopeChange({ taskType: (value as TaskType) || null })}
          clearable
          allowDeselect={false}
          {...commonSelectProps}
        />
      </ScopeInput>

      {!isComparisonView && (
        <ScopeInput label={i18n.t('common.parent_task')}>
          <EpicSelect hideLabel handleEpicSelected={(epic) => handleScopeChange({ epic })} />
        </ScopeInput>
      )}

      <ScopeInput label="Display Options">
        <Select
          placeholder="Display Options"
          data={Object.values(ViewType).map((viewType) => ({
            value: viewType,
            label: getViewTypeTitle(viewType),
          }))}
          value={viewType}
          onChange={(value) => setViewType(value as ViewType)}
          allowDeselect={false}
          {...commonSelectProps}
        />
      </ScopeInput>

      {mapping && (
        <ScopeInput label="Full View">
          <ViewToggle mapping={mapping} />
        </ScopeInput>
      )}
    </Flex>
  );
}

function ScopeInput({ label, children }: { label: string; children: JSX.Element }) {
  return (
    <Grid>
      <Grid.Col span={3.5} style={{ display: 'flex', alignItems: 'center' }}>
        {label}
      </Grid.Col>
      <Grid.Col span={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {children}
      </Grid.Col>
    </Grid>
  );
}
