import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
import { Line, LineChart, ReferenceDot, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Metric } from '../../../../api/initiative-client/initiative-client.type';
import i18n from '../../../../base-dictionary';
import { extractYearMonth } from '../../../../helpers/string-helpers/string-helpers';
import { newCOLORS } from '../../../../styles/colors';
import { Text } from '../../../../ui-library/typography/typography';
import { getDateLabels } from '../initiative-performance.helpers';
import { InitiativeMetricType } from '../initiative-performance.type';

dayjs.extend(customParseFormat);

const formatData = (metric: InitiativeMetricType, total_tasks: Metric, completed_tasks: Metric) => {
  const hasTasks = Object.keys(total_tasks).length > 0;
  const labels = hasTasks ? getDateLabels(Object.keys(total_tasks.tasks)) : [];
  const currentDate = new Date();

  const getTotalTasksData = () => {
    if (!hasTasks) {
      return [];
    }

    const dataset = metric === InitiativeMetricType.Points ? total_tasks.points : total_tasks.tasks;
    return Object.values(dataset);
  };

  const getCompletedTasksData = () => {
    if (!hasTasks) {
      return [];
    }
    return Object.values(completed_tasks[metric]);
  };

  const totalData = getTotalTasksData();
  const completedData = getCompletedTasksData();

  // Split the data into current and future segments
  let lastCurrentIndex = -1;
  const points = labels.map((label, index) => {
    const [month, year] = label.split(' ');
    const yearForPoint = parseInt(year.replace("'", ''));
    const dateString = `${month} ${yearForPoint}`;
    const date = dayjs(dateString, 'MMM YY');
    const isFuturePoint = date.isAfter(currentDate);

    if (!isFuturePoint) {
      lastCurrentIndex = index;
    }

    return {
      date: label,
      total_tasks: totalData[index],
      completed_tasks: completedData[index],
      isFuturePoint,
    };
  });

  // Create separate datasets for current and future points
  return points.map((point, index) => ({
    ...point,
    // For the transition point (first future point), include both current and future values
    total_tasks_current:
      index <= lastCurrentIndex ? point.total_tasks : index === lastCurrentIndex + 1 ? point.total_tasks : null,
    total_tasks_future: index > lastCurrentIndex ? point.total_tasks : null,
    completed_tasks_current:
      index <= lastCurrentIndex ? point.completed_tasks : index === lastCurrentIndex + 1 ? point.completed_tasks : null,
    completed_tasks_future: index > lastCurrentIndex ? point.completed_tasks : null,
  }));
};

const formatTooltipLabel = (value: string) => {
  if (value.includes('total_tasks')) return `Total ${i18n.t('common.work_units')}`;
  if (value.includes('completed_tasks')) return `Completed ${i18n.t('common.work_units')}`;
  return value;
};

const formatTooltipValue = (value: any, name: string, props: any) => {
  // If this is a future point, only show future values
  if (props.payload.isFuturePoint) {
    if (props.dataKey.includes('current')) return [null, null];
  }
  // If this is a current point, only show current values
  else {
    if (props.dataKey.includes('future')) return [null, null];
  }
  return [value, formatTooltipLabel(name)];
};

type Props = {
  metric: InitiativeMetricType;
  total_tasks: Metric;
  completed_tasks: Metric;
};

export function SectionStatusChart({ metric, total_tasks, completed_tasks }: Props) {
  const navigate = useNavigate();
  const data = formatData(metric, total_tasks, completed_tasks);
  const handleClick = (point: any) => {
    if (!point || !point.activePayload) return;

    const payload = point.activePayload[0].payload;
    // Ignore clicks on future dates
    if (payload.isFuturePoint) return;

    const { year, month } = extractYearMonth(payload.date);
    const dataKey = point.activePayload[0].dataKey.replace(/_current|_future$/, '');

    if (year && month) {
      const filter = dataKey === 'total_tasks' ? 'total' : 'completed';
      navigate(`tasks/?filter=${filter}&date=${year}-${month}`);
    }
  };

  const isClickDisabled = (dataKey: string) => {
    return dataKey.startsWith('completed_tasks');
  };

  return (
    <div style={{ width: '75%', flex: '0 0 auto', height: 352 }}>
      <div style={{ textAlign: 'center' }}>
        <Text size="small" color={newCOLORS.darkGray}>
          Progress (Cumulative)
        </Text>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 10, right: 30, left: 20, bottom: 40 }}>
          <XAxis dataKey="date" label={{ value: 'Month', position: 'bottom', offset: 0 }} tick={{ fontSize: 12 }} />
          <YAxis
            label={{
              value: metric === InitiativeMetricType.Points ? 'Points' : 'Tasks',
              angle: -90,
              position: 'left',
              offset: 0,
              style: { fontSize: 12 },
            }}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            wrapperStyle={{ pointerEvents: 'none' }}
            contentStyle={{ background: 'white', border: '1px solid #ccc', borderRadius: 4 }}
            formatter={formatTooltipValue}
            isAnimationActive={false}
          />
          {/* Current data lines */}
          <Line
            key="total-tasks-current"
            type="monotone"
            dataKey="total_tasks_current"
            name="total_tasks"
            stroke={newCOLORS.blue}
            strokeWidth={1}
            dot={false}
            connectNulls
            isAnimationActive={false}
          />
          {data.map((entry, index) => {
            if (!entry.isFuturePoint && entry.total_tasks_current != null) {
              return (
                <ReferenceDot
                  key={`total-current-${index}`}
                  x={entry.date}
                  y={entry.total_tasks_current}
                  r={3}
                  fill={newCOLORS.blue}
                  stroke="none"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleClick({ activePayload: [{ payload: entry, dataKey: 'total_tasks_current' }] });
                  }}
                />
              );
            }
            return null;
          })}
          <Line
            key="completed_tasks_current"
            type="monotone"
            dataKey="completed_tasks_current"
            name="completed_tasks"
            stroke={newCOLORS.green}
            strokeWidth={1}
            dot={false}
            connectNulls
            isAnimationActive={false}
          />
          {data.map((entry, index) => {
            if (!entry.isFuturePoint && entry.completed_tasks_current != null) {
              return (
                <ReferenceDot
                  key={`completed-current-${index}`}
                  x={entry.date}
                  y={entry.completed_tasks_current}
                  r={3}
                  fill={newCOLORS.green}
                  stroke="none"
                  style={{ cursor: isClickDisabled('completed_tasks') ? 'default' : 'pointer' }}
                  onClick={() => {
                    handleClick({ activePayload: [{ payload: entry, dataKey: 'completed_tasks_current' }] });
                  }}
                />
              );
            }
            return null;
          })}
          {/* Future data lines */}
          <Line
            key="total-tasks-future"
            type="monotone"
            dataKey="total_tasks_future"
            name="total_tasks"
            stroke={newCOLORS.darkerGray2}
            strokeWidth={1}
            strokeDasharray="3 3"
            strokeOpacity={0.9}
            dot={(props: any) =>
              props.payload.isFuturePoint && props.payload.total_tasks_future != null ? (
                <circle
                  key={`total-future-${props.cx}-${props.cy}`}
                  cx={props.cx}
                  cy={props.cy}
                  r={3}
                  stroke={newCOLORS.darkerGray2}
                  fill="white"
                  strokeDasharray="1 1"
                />
              ) : (
                <circle
                  key={`total-future-empty-${props.cx}-${props.cy}`}
                  cx={props.cx}
                  cy={props.cy}
                  r={0}
                  fill="none"
                />
              )
            }
            connectNulls
            isAnimationActive={false}
          />
          <Line
            key="completed-tasks-future"
            type="monotone"
            dataKey="completed_tasks_future"
            name="completed_tasks"
            stroke={newCOLORS.darkerGray2}
            strokeWidth={1}
            strokeDasharray="3 3"
            strokeOpacity={0.7}
            dot={(props: any) =>
              props.payload.isFuturePoint && props.payload.completed_tasks_future != null ? (
                <circle
                  key={`completed-future-${props.cx}-${props.cy}`}
                  cx={props.cx}
                  cy={props.cy}
                  r={3}
                  stroke={newCOLORS.darkerGray2}
                  fill="white"
                  strokeDasharray="1 1"
                />
              ) : (
                <circle
                  key={`completed-future-empty-${props.cx}-${props.cy}`}
                  cx={props.cx}
                  cy={props.cy}
                  r={0}
                  fill="none"
                />
              )
            }
            connectNulls
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
