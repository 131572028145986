import { downloadCSV } from '../../../../helpers/csv-download';
import { secondaryBase } from '../../../../styles/design-tokens';
import { Text } from '../../../../ui-library/typography/typography';
import { TableColumn } from '../../../data-management/components/table';
import { CompletionBar } from '../initiatives/risk-analysis-initiative-table.hooks';
import { RiskBadge } from '../risk-analysis';
import { RiskAnalysisTask } from '../risk-analysis.client.type';
import { calculateCompletionPercentage, getRiskCategory } from '../risk-analysis.helpers';
import { TruncatedText } from '../truncated-text';

const handleTaskTableDownloadCSV = (isEpic: boolean, filteredTasks: RiskAnalysisTask[]) => {
  try {
    const headers = [
      'Key',
      'Task Name',
      ...(isEpic ? ['Completion %', 'Total Subtasks'] : ['Description', 'Points']),
      'Risk Category',
      'Risk Score',
    ];

    const rows = filteredTasks.map((task) => [
      task.code,
      task.name || 'No name',
      ...(isEpic
        ? [
            task.not_done_subtasks !== undefined && task.total_subtasks !== undefined
              ? calculateCompletionPercentage(task)
              : 0,
            task.total_subtasks || '-',
          ]
        : [task.description || 'No description', task.points || '-']),
      getRiskCategory(task.flora_annotations?.risk_score).label,
      task.flora_annotations?.risk_score?.toFixed(0) || 'N/A',
    ]);

    downloadCSV([headers, ...rows], isEpic ? 'epics.csv' : 'tasks.csv');
  } catch (error) {
    console.error('Error downloading CSV', error);
  }
};

const getTaskTableColumns = (isEpic: boolean): TableColumn<RiskAnalysisTask>[] => {
  return [
    {
      key: 'code',
      label: 'Key',
      width: '7.5%',
      sortable: true,
      render: (_, task) => (
        <a
          href={task.url}
          onClick={(e) => {
            e.preventDefault();
            window.open(task.url, '_blank');
          }}
          style={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          <Text size="small" color={secondaryBase}>
            {task.code}
          </Text>
        </a>
      ),
    },
    {
      key: 'name',
      label: isEpic ? 'Epic Name' : 'Task Name',
      width: '15%',
      sortable: true,
      render: (_, task) => <TruncatedText text={task.name || 'No name'} onToggle={() => {}} />,
    },
    !isEpic
      ? {
          key: 'description',
          label: 'Description',
          width: '25%',
          sortable: true,
          render: (_, task) => <TruncatedText text={task.description || 'No description'} onToggle={() => {}} />,
        }
      : {
          key: 'not_done_subtasks',
          label: 'Completion',
          width: '10%',
          sortable: true,
          render: (_, task) =>
            task.not_done_subtasks !== undefined && task.total_subtasks !== undefined ? (
              <CompletionBar percentage={calculateCompletionPercentage(task)} />
            ) : (
              <CompletionBar percentage={0} />
            ),
        },
    !isEpic
      ? {
          key: 'points',
          label: 'Points',
          width: '10%',
          sortable: true,
          render: (_, task) => task.points || '-',
        }
      : {
          key: 'total_subtasks',
          label: 'Total Subtasks',
          width: '10%',
          sortable: true,
          render: (_, task) => task.total_subtasks || '-',
        },
    {
      key: 'flora_annotations.risk_category' as keyof RiskAnalysisTask,
      label: 'Risk Category',
      width: '15%',
      sortable: true,
      render: (_, task) => {
        const riskCategory = getRiskCategory(task.flora_annotations?.risk_score);
        return <RiskBadge color={riskCategory.color}>{riskCategory.label}</RiskBadge>;
      },
    },
    {
      key: 'flora_annotations.risk_score' as keyof RiskAnalysisTask,
      label: 'Risk Score',
      width: '10%',
      sortable: true,
      render: (_, task) => task.flora_annotations?.risk_score?.toFixed(0) || 'N/A',
    },
  ];
};

export { getTaskTableColumns, handleTaskTableDownloadCSV };
