import { styled } from '@linaria/react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Spending } from '../../api/financials-client/financials-client.type';
import { FinancialsScope } from '../../components/financials-scope/financials-scope';
import { FinancialsScope as Scope } from '../../components/financials-scope/financials-scope.type';
import { TeamContext } from '../../contexts';
import { setFinancialsEndDate, setFinancialsStartDate } from '../../helpers/storage/storage';
import { useGlobalStore } from '../../store/global-store/global-store';
import { AllocationOfCosts } from '../allocation-of-costs/allocation-of-costs';
import { CostByInitiative } from '../cost-by-initiative/cost-by-initiative';
import { CostsVsOutput } from '../costs-vs-output/costs-vs-output';

type FinancialsChartsProps = {
  spendingQuery: QueryObserverResult<Spending[]>;
  startDate: Date | null;
  setStartDate: (date: Date) => void;
  endDate: Date | null;
  setEndDate: (date: Date) => void;
};

export const FinancialsCharts = ({
  spendingQuery,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: FinancialsChartsProps) => {
  const portfolio = useGlobalStore((state) => state.portfolio);
  const { team } = useContext(TeamContext);
  const [costsVsOutputQueryFinished, setCostsVsOutputQueryFinished] = useState<boolean>(false);
  const [allocationOfCostsQueryFinished, setAllocationOfCostsQueryFinished] = useState<boolean>(false);

  const updateStartDate = (date: Date) => {
    setFinancialsStartDate(date);
    setStartDate(date);
    spendingQuery.refetch();
  };

  const updateEndDate = (date: Date) => {
    setFinancialsEndDate(date);
    setEndDate(date);
    spendingQuery.refetch();
  };

  const [scope, setScope] = useState<Scope>({
    portfolio,
    team,
    startDate,
    endDate,
  });

  useEffect(() => {
    setScope({ portfolio, team, startDate, endDate });
  }, [startDate, endDate, portfolio, team]);

  return (
    <Fragment>
      <FinancialsContainer>
        <div>
          <Fragment>
            <FinancialsScope
              startDate={startDate}
              setStartDate={updateStartDate}
              endDate={endDate}
              setEndDate={updateEndDate}
            />
            <div>
              {portfolio && startDate && endDate ? (
                <CostsVsOutput {...scope} setQueryFinished={setCostsVsOutputQueryFinished} />
              ) : null}
            </div>
            <div>
              {portfolio && startDate && endDate ? (
                <AllocationOfCosts
                  {...scope}
                  setQueryFinished={setAllocationOfCostsQueryFinished}
                  queryEnabled={costsVsOutputQueryFinished}
                />
              ) : null}
            </div>
            <div>
              {portfolio && startDate && endDate ? (
                <CostByInitiative {...scope} queryEnabled={allocationOfCostsQueryFinished} />
              ) : null}
            </div>
          </Fragment>
        </div>
      </FinancialsContainer>
    </Fragment>
  );
};

const FinancialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-width: 99%;

  > div {
    max-width: 99%;
    overflow-x: hidden;
  }
`;
