import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
  BurnsData,
  BurnsDataOverTime,
  HistoricalBurnsDataOverTime,
} from '../../../../../api/work-periods-client/work-periods-client.type';
import {
  DeliveryConfidenceChartDataPoint,
  DeliveryDataPoint,
  HistoricalBurnsDataPoint,
  Metric,
} from '../../../work-periods.type';

/**
 * Filters the historical burns data to only include the viewBy metric
 * @param historicalBurnsData - The historical burns data to filter
 * @param viewBy - The metric to filter by
 * @returns The filtered historical burns data
 */
function filterHistoricalBurnsData(
  historicalBurnsData: HistoricalBurnsDataOverTime | undefined,
  viewBy: Metric,
): HistoricalBurnsDataOverTime {
  if (!historicalBurnsData) return {};

  return Object.fromEntries(
    Object.entries(historicalBurnsData).map(([dateKey, sourceEntry]) => {
      return [
        dateKey,
        Object.fromEntries(Object.entries(sourceEntry).filter(([key]) => key.startsWith(viewBy.toLowerCase()))),
      ];
    }),
  ) as HistoricalBurnsDataOverTime;
}

/**
 * Transforms the historical burns data into an array of points
 * @param historicalBurnsData - The historical burns data to transform
 * @param viewBy - The metric to transform by
 * @returns The transformed historical burns data
 */
function transformHistoricalBurnsData(
  historicalBurnsData: HistoricalBurnsDataOverTime,
  viewBy: Metric,
): HistoricalBurnsDataPoint[] {
  const viewByKey = viewBy.toLowerCase() as 'points' | 'tasks';
  return Object.entries(historicalBurnsData).map(([dateKey, sourceEntry]) => {
    const entry = sourceEntry[viewByKey];
    return { date: dateKey, key: dateKey, average: entry.average, band: [entry.y0, entry.y] };
  });
}

/**
 * Filters the delivery data to only include the viewBy metric
 * @param burnsData - The delivery data to filter
 * @param viewBy - The metric to filter by
 * @returns The filtered delivery data
 */
function filterDeliveryData(burnsData: BurnsDataOverTime | undefined, viewBy: Metric): BurnsDataOverTime {
  if (!burnsData) return {};

  return Object.fromEntries(
    Object.entries(burnsData).map(([dateKey, sourceEntry]) => {
      const filteredEntries = Object.entries(sourceEntry).filter(
        ([key]) => key.startsWith(viewBy.toLowerCase()) || ['date', 'weekend_or_holiday'].includes(key),
      );

      return [dateKey, Object.fromEntries(filteredEntries) as BurnsData];
    }),
  ) as BurnsDataOverTime;
}

/**
 * Transforms the filtered delivery data into an array of points
 * @param filteredData - The filtered delivery data to transform
 * @param viewBy - The metric to transform by
 * @returns The transformed delivery data
 */
function transformForDeliveryChart(filteredData: BurnsDataOverTime, viewBy: Metric): DeliveryDataPoint[] {
  const result: DeliveryDataPoint[] = [];

  const earliestDateKey = Object.keys(filteredData).sort()[0];
  const earliestDataPoint = filteredData[earliestDateKey];
  const earliestDateScope = viewBy === Metric.Points ? earliestDataPoint.points_total : earliestDataPoint.tasks_total;

  for (const dateKey of Object.keys(filteredData)) {
    const dataPoint = { date: dateKey, key: dateKey, scopeChange: [] as number[] } as DeliveryDataPoint;

    const sourceEntry = filteredData[dateKey];
    const keys = Object.keys(sourceEntry) as Array<keyof BurnsData>;

    for (const key of keys) {
      const keyStr = key.toString();
      // Remove 'tasks_' or 'points_' prefix from the key
      const cleanKey = keyStr.replace(/^(tasks_|points_)/, '');
      switch (cleanKey) {
        case 'complete':
          dataPoint.completed = Math.round(Number(sourceEntry[key]));
          break;
        case 'total':
          dataPoint.scopeChange = dataPoint.scopeChange.concat(earliestDateScope, Math.round(Number(sourceEntry[key])));
          dataPoint.scope = Math.round(Number(sourceEntry[key]));
          break;
      }
    }

    result.push(dataPoint);
  }

  return result;
}

/**
 * Merges the delivery data with the historical burns data
 * @param deliveryData - The delivery data to merge
 * @param historicalBurnsData - The historical burns data to merge
 * @returns The merged data
 */
function mergeDeliveryDataWithHistoricalBurnsData(
  deliveryData: DeliveryDataPoint[],
  historicalBurnsData: HistoricalBurnsDataPoint[],
): DeliveryConfidenceChartDataPoint[] {
  return deliveryData.map((deliveryPoint) => {
    const historicalBurnsPoint = historicalBurnsData.find(
      (historicalBurnsPoint) => historicalBurnsPoint.date === deliveryPoint.date,
    );
    return historicalBurnsPoint ? { ...deliveryPoint, ...historicalBurnsPoint } : deliveryPoint;
  });
}

/**
 * Builds the tooltip for the delivery chart
 * @param value - The value to build the tooltip for
 * @param name - The name of the value
 * @param metric - The metric of the value
 * @returns An array of strings containing the value and name
 */
function buildDeliveryChartTooltip(value: ValueType, name: NameType, metric: Metric): string[] {
  if (value === undefined || value === null) {
    return ['-', ''];
  }

  // Hide tooltip information for the Confidence Band
  if (name === 'Confidence Band' && Array.isArray(value)) {
    return [];
  }

  // Area type data is always an array
  if (name === 'Scope Change' && Array.isArray(value)) {
    const diff = Number(value[1]) - Number(value[0]);
    if (diff === 0) {
      return [];
    }
    return formatTooltipText(diff, name, metric);
  }

  // Handle regular numeric values for line type data
  if (!Array.isArray(value)) {
    return formatTooltipText(Number(value), name, metric);
  }

  // Fallback for any other array values (shouldn't reach here based on function usage)
  return [];
}

/**
 * Formats the tooltip text for the delivery chart
 * @param value - The value to format
 * @param name - The name of the value
 * @param metric - The metric of the value
 * @returns An array of strings containing the value and name
 */
function formatTooltipText(value: number, name: NameType, metric: Metric): string[] {
  // Determine if singular or plural form should be used
  const metricLabel = getMetricLabel(value, metric);

  // Get the user-friendly label for the name
  const nameLabel = getNameLabel(name);

  return [`${value} ${metricLabel}`, nameLabel];
}

/**
 * Gets the appropriate metric label with correct pluralization
 * @param value - The value to get the metric label for
 * @param metric - The metric to get the label for
 * @returns The metric label
 */
function getMetricLabel(value: number, metric: Metric): string {
  if (metric === Metric.Tasks) {
    return value === 1 ? 'task' : 'tasks';
  } else {
    return value === 1 ? 'point' : 'points';
  }
}

/**
 * Gets a user-friendly name label from the technical name
 * @param name - The technical name to get the label for
 * @returns The user-friendly label: 'Completed' or 'Total Scope'
 */
function getNameLabel(name: NameType): 'Completed' | 'Total Scope' {
  const labels: Record<string, 'Completed' | 'Total Scope'> = {
    completed: 'Completed',
    scope: 'Total Scope',
  };

  return labels[name.toString()] || name.toString();
}

export {
  buildDeliveryChartTooltip,
  filterDeliveryData,
  filterHistoricalBurnsData,
  mergeDeliveryDataWithHistoricalBurnsData,
  transformForDeliveryChart,
  transformHistoricalBurnsData,
};
