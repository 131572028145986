import AxiosInstance from '../../helpers/axios-instance/axios-instance';
import {
  CreateOrganizationResponse,
  OrganizationPostRequest,
  OrganizationResponse,
  OrganizationStatusesByProjectsAndBoards,
} from './organization-client.type';

const axiosInstance = AxiosInstance();
const baseURL = import.meta.env.VITE_APP_API_URL;

/**
 * Method to get the list of organizations for a user
 *
 * @return {Promise<OrganizationResponse[]>} A promise that resolves to an array of OrganizationResponse objects.
 */
const getOrganizations = async (): Promise<OrganizationResponse[]> => {
  const url = baseURL + '/api/organizations/';
  return await axiosInstance
    .get(url)
    .then((response) => response.data?.organizations)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Method to create the organization with details gathered during the integration setup
 * @param body post body for the request
 * @returns a dict of the organization details
 */
async function createOrganization(body: OrganizationPostRequest): Promise<CreateOrganizationResponse> {
  const apiURL = baseURL + '/api/organizations/';

  return await axiosInstance
    .post(apiURL, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
}

/**
 * Method to get the organization details
 * @param organizationId id of the organization
 * @returns the details of the organization
 */
const getOrganizationInfo = async (organizationId: string): Promise<OrganizationResponse> => {
  const url = baseURL + `/api/organizations/${organizationId}/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response));
};

/**
 * Fetches all the statuses available for the given organization
 * arranged by a nested project & subproject hierarchy
 * @param organizationId organization id
 * @returns OrganizationStatuses object
 */
async function getOrganizationStatuses(
  organizationId: string,
): Promise<{ workflow_available_statuses_by_project_and_subproject: OrganizationStatusesByProjectsAndBoards }> {
  const url = baseURL + `/api/organizations/${organizationId}/statuses/`;
  return await axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error?.response?.data || 'Error while fetching organization statuses.'));
}

export { createOrganization, getOrganizationInfo, getOrganizations, getOrganizationStatuses };
