import { styled } from '@linaria/react';
import { ReactNode } from 'react';
import { baseWhite, inkLight, secondaryBase, skyLightest } from '../../../styles/design-tokens';
import { Icon } from '../../../ui-library/icon/icon';
import { useWorkPeriodsContext } from '../work-periods.context';
import { WorkPeriodType } from '../work-periods.type';

// Define tab values for the chart sections
export enum ChartTab {
  // Keep only the assessment tabs with names that match the process analysis sections
  PHASE = 'phase',
  PACE = 'pace',
  DELIVERY = 'delivery',
  // Keep these for backward compatibility
  FLOW_OF_WORK = 'flow_of_work',
  WORK_PACE_EXPLORER = 'work_pace_explorer',
  DELIVERY_CONFIDENCE = 'delivery_confidence',
}

interface ChartTabsProps {
  activeTab: ChartTab;
  onTabChange: (tab: ChartTab) => void;
  children: ReactNode;
}

export const ChartTabs = ({ activeTab, onTabChange, children }: ChartTabsProps) => {
  const { selectedWorkPeriodType } = useWorkPeriodsContext();
  return (
    <ChartTabsContainer>
      <ChartTabsHeader>
        <StyledChartTab active={activeTab === ChartTab.PHASE} onClick={() => onTabChange(ChartTab.PHASE)}>
          <Icon name="insights" size={16} color={activeTab === ChartTab.PHASE ? baseWhite : inkLight} />
          <TabText>Work Phase Distribution</TabText>
        </StyledChartTab>
        <StyledChartTab active={activeTab === ChartTab.PACE} onClick={() => onTabChange(ChartTab.PACE)}>
          <Icon name="speed" size={16} color={activeTab === ChartTab.PACE ? baseWhite : inkLight} />
          <TabText>Work Pace</TabText>
        </StyledChartTab>
        {selectedWorkPeriodType === WorkPeriodType.Defined ? (
          <StyledChartTab active={activeTab === ChartTab.DELIVERY} onClick={() => onTabChange(ChartTab.DELIVERY)}>
            <Icon name="trending_up" size={16} color={activeTab === ChartTab.DELIVERY ? baseWhite : inkLight} />
            <TabText>Delivery Confidence</TabText>
          </StyledChartTab>
        ) : null}
      </ChartTabsHeader>

      <ChartTabContent>{children}</ChartTabContent>
    </ChartTabsContainer>
  );
};

const ChartTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const ChartTabsHeader = styled.div`
  display: flex;
  padding: 8px 16px;
  gap: 8px;
`;

const StyledChartTab = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${(props) => (props.active ? secondaryBase : 'transparent')};
  color: ${(props) => (props.active ? baseWhite : inkLight)};
  border: ${(props) => (props.active ? 'none' : `1px solid ${inkLight}`)};

  &:hover {
    background-color: ${(props) => (props.active ? secondaryBase : skyLightest)};
    border: ${(props) => (props.active ? 'none' : `1px solid ${secondaryBase}`)};
  }
`;

const TabText = styled.span`
  font-size: 14px;
`;

const ChartTabContent = styled.div`
  padding: 16px;
`;
