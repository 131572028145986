import { useCallback, useState } from 'react';

/**
 * Available scope options for filtering targets
 */
const SCOPE_OPTIONS = [
  { value: 'Portfolio', label: 'Portfolio' },
  { value: 'Project', label: 'Project' },
  { value: 'Board', label: 'Board' },
  { value: 'Sprint', label: 'Sprint' },
  { value: 'Initiative', label: 'Initiative' },
];

/**
 * Hook for managing target filters
 *
 * @returns {Object} Object containing:
 *   - scopeFilters: Array of selected scope filter values
 *   - setScopeFilters: Function to update scope filters
 *   - activeFilter: Array of selected active status filter values
 *   - setActiveFilter: Function to update active status filters
 *   - handleResetFilters: Function to reset all filters to default values
 */
const useTargetsFilters = () => {
  const defaultScopeFilters = SCOPE_OPTIONS.map((option) => option.value);
  const [scopeFilters, setScopeFilters] = useState<string[]>(defaultScopeFilters);
  const [activeFilter, setActiveFilter] = useState<string[]>(['active', 'inactive']);

  const handleResetFilters = useCallback(() => {
    setScopeFilters(defaultScopeFilters);
    setActiveFilter(['active', 'inactive']);
  }, [defaultScopeFilters]);

  return {
    scopeFilters,
    setScopeFilters,
    activeFilter,
    setActiveFilter,
    handleResetFilters,
  };
};

export { SCOPE_OPTIONS, useTargetsFilters };
